export default `
    {{{ variable }}} = hmUI.createWidget(hmUI.widget.TIME_POINTER, {
      {{ #data }}
      {{ key }}_centerX: {{ center_position.x }},
      {{ key }}_centerY: {{ center_position.y }},
      {{ key }}_posX: {{ pointer.position.x }},
      {{ key }}_posY: {{ pointer.position.y }},
      {{ #pointer.image }}
      {{ key }}_path: '{{{ pointer.image }}}',
      {{ /pointer.image }}
      {{ #cover.image }}
      {{ key }}_cover_path: '{{{ cover.image }}}',
      {{ /cover.image }}
      {{ key }}_cover_x: {{ cover.position.x }},
      {{ key }}_cover_y: {{ cover.position.y }},
      {{ /data }}
      enable: false,
      show_level: {{ showLevel }},
    });
  `
