import componentMap from './componentMap'
import IDLE_EXTRA_CONFIG from './idle/extraConfig'
import IDLE_GET_CHECK_CONFIGS from './idle/getCheckConfigs'
import IDLE_LAYER_CONFIG from './idle/layerConfig'
import IDLE_MENU from './idle/menu'
import IDLE_TOP_BAR from './idle/topBar'
import NORMAL_EXTRA_CONFIG from './normal/extraConfig'
import NORMAL_GET_CHECK_CONFIGS from './normal/getCheckConfigs'
import NORMAL_LAYER_CONFIG from './normal/layerConfig'
import NORMAL_MENU from './normal/menu'
import NORMAL_TOP_BAR from './normal/topBar'

// 天气命名规则， 格式为（文件名: 天气）
const weatherNamingMap = {
  0: 'cloudy',
  1: 'shower',
  2: 'snow_shower',
  3: 'sunny',
  4: 'overcast',
  5: 'light_rain',
  6: 'light_snow',
  7: 'moderate_rain',
  8: 'moderate_snow',
  9: 'heavy_snow',
  10: 'heavy_rain',
  11: 'sandstorm',
  12: 'sleet',
  13: 'fog',
  14: 'haze',
  15: 'thundershower',
  16: 'snowstorm',
  17: 'dust',
  18: 'extraordinary_rainstorm',
  19: 'rain_with_hail',
  20: 'thundershowers_with_hail',
  21: 'heavy_rainstom',
  22: 'sand_blowing',
  23: 'strong_sandstom',
  24: 'rainstorm',
  25: 'unknow_weather',
  26: 'cloudy_at_night',
  27: 'shower_at_night',
  28: 'clear night'
}

export default {
  weatherNamingMap,
  componentMap,
  normal: {
    layerConfig: NORMAL_LAYER_CONFIG,
    extraConfig: NORMAL_EXTRA_CONFIG,
    menu: NORMAL_MENU,
    topBar: NORMAL_TOP_BAR,
    getCheckConfigs: NORMAL_GET_CHECK_CONFIGS
  },
  idle: {
    layerConfig: IDLE_LAYER_CONFIG,
    extraConfig: IDLE_EXTRA_CONFIG,
    menu: IDLE_MENU,
    topBar: IDLE_TOP_BAR,
    getCheckConfigs: IDLE_GET_CHECK_CONFIGS
  }
}
