import { ArrowsAltOutlined, DownloadOutlined, ExportOutlined, PictureOutlined, QrcodeOutlined, RobotOutlined } from '@ant-design/icons'
import { MENU_ICON_COLOR } from '@watchface/constants'
import React from 'react'

const OPERATIONS = [
  {
    name: 'export',
    type: 'nonZeppOS',
    operation: 'export',
    icon: <ExportOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
  },
  {
    name: 'install',
    type: 'nonZeppOS',
    operation: 'install',
    icon: <QrcodeOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
  },
  {
    name: 'export',
    type: 'zeppOS',
    operation: 'zeppOS-export',
    icon: <DownloadOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
  },
  {
    name: 'install',
    type: 'zeppOS',
    operation: 'zeppOS-install',
    icon: <QrcodeOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
  },
  {
    name: 'zoom',
    type: 'zeppOS,nonZeppOS',
    operation: 'zoom',
    icon: <ArrowsAltOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
  },
  {
    name: 'export_image',
    type: 'zeppOS,nonZeppOS',
    operation: 'export-image',
    icon: <PictureOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
  },
  {
    name: 'simulator_connect',
    type: 'zeppOS',
    operation: 'simulator',
    icon: <RobotOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
  }
]
export default OPERATIONS
