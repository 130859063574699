const config = {
  battery: 'hmUI.data_type.BATTERY',
  sun_current: 'hmUI.data_type.SUN_CURRENT',
  sun_low: 'hmUI.data_type.SUN_SET',
  sun_high: 'hmUI.data_type.SUN_RISE',
  step_current: 'hmUI.data_type.STEP',
  step_target: 'hmUI.data_type.STEP_TARGET',
  calorie_current: 'hmUI.data_type.CAL',
  calorie_target: 'hmUI.data_type.CAL_TARGET',
  heart_rate: 'hmUI.data_type.HEART',
  pai_daily: 'hmUI.data_type.PAI_DAILY',
  pai_weekly: 'hmUI.data_type.PAI_WEEKLY',
  distance: 'hmUI.data_type.DISTANCE',
  standing_current: 'hmUI.data_type.STAND',
  standing_target: 'hmUI.data_type.STAND_TARGET',
  stand: 'hmUI.data_type.STAND',
  weather: 'hmUI.data_type.WEATHER_CURRENT',
  moon: 'hmUI.data_type.MOON',
  temperature_current: 'hmUI.data_type.WEATHER_CURRENT',
  temperature_low: 'hmUI.data_type.WEATHER_LOW',
  temperature_high: 'hmUI.data_type.WEATHER_HIGH',
  uvi: 'hmUI.data_type.UVI',
  aqi: 'hmUI.data_type.AQI',
  humidity: 'hmUI.data_type.HUMIDITY',
  altimeter: 'hmUI.data_type.ALTIMETER',
  wind: 'hmUI.data_type.WIND',
  spo2: 'hmUI.data_type.SPO2',
  stress: 'hmUI.data_type.STRESS',
  training_load: 'hmUI.data_type.TRAINING_LOAD',
  vo2max: 'hmUI.data_type.VO2MAX',
  activity_current: 'hmUI.data_type.ACTIVITY',
  activity_target: 'hmUI.data_type.ACTIVITY_TARGET'
  // TODO: 待补充...
}

export default config
