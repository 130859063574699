/* eslint-disable  prefer-promise-reject-errors */
import { message, Modal } from 'antd'
import type { AxiosResponse, Method } from 'axios'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import apiHost from '@watchface/request/domains/common'
import { handleBrowserLang } from '@watchface/utils'
import { getUniversalLoginInfo } from '@/utils/universalLogin'


export interface IParams {
  method?: Method
  headers?: any
  baseURL?: string
  params?: any
  data?: any | string
}

const { isCN } = handleBrowserLang()

function checkStatus(response: AxiosResponse) {
  let fail = ''
  let serviceError = ''
  if (isCN) {
    fail = '请求错误'
    serviceError = '服务器错误'
  } else {
    fail = 'Request error'
    serviceError = 'Server error'
  }

  const { status, data } = response
  if (status >= 200 && status < 300) return data || { success: true }
  // 根据status做不同处理
  let error: any = {}

  if (status >= 400 && status < 500) {
    error = new Error(fail)
  } else {
    error = new Error(serviceError)
  }

  error.name = status.toString()
  error.responseMsg = response?.data?.message || ''

  if (status !== 404) throw error
}

// 接口请求SDK
export default function request(url: string, params: IParams = {}, option = { needCatch: true, needVerifyToken: true, needVerifyExamine: true }) {
  const { needCatch } = option
  const { appToken } = getUniversalLoginInfo()

  if (!appToken) {
    return Promise.reject({ error: 'apptoken empty' }).catch(() => {
      Modal.warning({
        title: isCN ? '登录状态过期，请重新登录' : 'Login status expired, please login again',
        okText: isCN ? '去登录' : 'Login',
        onOk: () => {
          window.location.href = `${apiHost.universalLogin}index.html#/login?project_name=watchface&project_redirect_uri=${window.encodeURIComponent(
            window.location.href
          )}&platform_app=com.huami.webapp&specify_lang=en`
        }
      })
    })
  }
  const defaultHeaders = {
    apptoken: appToken,
    'X-Request-Id': uuidv4()
  }
  const headers = params.headers ? params.headers : {}
  const req = axios({
    method: params.method || 'get',
    url,
    headers: { ...defaultHeaders, ...headers },
    params: params.params,
    data: params.data,
    validateStatus() {
      return true
    }
  })
  if (needCatch) {
    return req.then(checkStatus).catch((err: any) => {
      // eslint-disable-next-line no-useless-return
      message.error(err.message)
      if (err.name === '401' || err.responseMsg === 'apptoken invalid') {
        Modal.warning({
          title: isCN ? '登录状态过期，请重新登录' : 'Login status expired, please login again',
          okText: isCN ? '去登录' : 'Login',
          onOk: () => {
            window.location.href = `${
              apiHost.universalLogin
            }index.html#/login?project_name=watchface&project_redirect_uri=${window.encodeURIComponent(
              window.location.href
            )}&platform_app=com.huami.webapp&specify_lang=en`
          }
        })
      }
      return Promise.reject(err)
    })
  }
  return req.then((response) => {
    return new Promise((resolve, reject) => {
      const { status, data } = response
      if (status >= 200 && status < 400) {
        resolve(data)
      } else {
        reject({ ...data, status })
      }
    })
  })
}
