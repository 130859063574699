import { TranslationOutlined } from '@ant-design/icons'
import { MENU_ICON_COLOR, SUPPORT_LANGS, WATCHFACE_LANG } from '@watchface/constants'
import { changeLang } from '@watchface/store/actions'
import { Dropdown, Menu } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const LangSelector: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  let currentLang: any = useSelector((state: any) => state.getIn(['watchFace', 'i18n', 'currentLang'])) || window?.navigator?.language
  currentLang = (currentLang || '').replace(/-\w+/, '')

  const handleChange = ({ key }: { key: string | number }) => {
    if (typeof key === 'string') {
      dispatch(changeLang(key))
      key && localStorage.setItem(WATCHFACE_LANG, key)
    }
  }

  const menu = (
    <Menu onClick={handleChange}>
      {SUPPORT_LANGS.map(({ label, field }) => (
        <Menu.Item key={field} disabled={field === currentLang} icon={null}>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <TranslationOutlined style={{ fontSize: '24px', cursor: 'pointer', color: MENU_ICON_COLOR }} />
    </Dropdown>
  )
}

export default LangSelector
