import { TextComponent } from '@watchface/pages/DesignTool/NonZeppOs/components'
import React, { PureComponent } from 'react'

class Text extends PureComponent {
  static removeLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const { removeLayer } = TextComponent
    removeLayer && removeLayer({ childKey, ...restArgs })
  }

  render() {
    return <TextComponent {...this.props} />
  }
}

export default Text
