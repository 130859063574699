import { getKeyOrIdFromStr } from '@watchface/components/Meta'
import { ALIGN_MAP } from '@watchface/components/ExportZip/convert/constants'

/* eslint-disable no-unused-vars */
const DIGITAL_CLOCK_WIDGET = {
  name: 'Digital Clock',
  type: 'digitalClock',
  visible: true,
  visibleOnAod: false,
  canClick: true,
  children: []
}

const DIGITAL_CLOCK_HOUR = {
  name: 'Hour',
  type: 'digitalClockHour',
  visible: false,
  visibleOnAod: false,
  canClick: true,
  categories: {
    name: {
      type: 'name',
      display: 'Name',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        name: {
          value: 'Hour'
        }
      }
    },
    imageGroup: {
      type: 'imageGroup',
      display: 'Image Group',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'all',
              value: [],
              useExistedImage: false
            }
          ],
          limit: 10
        }
      }
    },
    placement: {
      type: 'placement',
      display: 'Placement',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        x: { value: 10 },
        y: { value: 10 }
      }
    },
    alignment: {
      type: 'alignment',
      display: 'Alignment',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        alignH: { value: 'hmUI.align.LEFT', visibility: true, disabled: false },
        alignV: { value: 'hmUI.align.TOP', visibility: false, disabled: false },
        padding: { value: true, visibility: true, disabled: false },
        space: { value: 0, visibility: true, disabled: false },
        follow: { value: false, visibility: true, disabled: false }
      }
    },
    unitImage: {
      type: 'image',
      display: 'Unit Image',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'en',
              value: ''
            },
            {
              lang: 'zh',
              value: ''
            },
            {
              lang: 'zh-Hant',
              value: ''
            },
            {
              lang: 'all',
              value: ''
            }
          ]
        }
      }
    },
    previewText: {
      type: 'previewText',
      display: 'Hour Preview',
      isExpanded: true,
      enabled: true,
      visibility: true,
      supportExpression: true,
      properties: {
        previewNumber: {
          value: 0,
          valueCount: 2,
          minValue: 0,
          maxValue: 23
        }
      }
    }
  }
}

const DIGITAL_CLOCK_MINUTE = {
  name: 'Minute',
  type: 'digitalClockMinute',
  visible: false,
  visibleOnAod: false,
  canClick: true,
  categories: {
    name: {
      type: 'name',
      display: 'Name',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        name: {
          value: 'Minute'
        }
      }
    },
    imageGroup: {
      type: 'imageGroup',
      display: 'Image Group',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'all',
              value: [],
              useExistedImage: false
            }
          ],
          limit: 10
        }
      }
    },
    placement: {
      type: 'placement',
      display: 'Placement',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        x: { value: 10 },
        y: { value: 10 }
      }
    },
    alignment: {
      type: 'alignment',
      display: 'Alignment',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        alignH: { value: 'hmUI.align.LEFT', visibility: true, disabled: false },
        alignV: { value: 'hmUI.align.TOP', visibility: false, disabled: false },
        padding: { value: true, visibility: true, disabled: false },
        space: { value: 0, visibility: true, disabled: false },
        follow: { value: false, visibility: true, disabled: false }
      }
    },
    unitImage: {
      type: 'image',
      display: 'Unit Image',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'en',
              value: ''
            },
            {
              lang: 'zh',
              value: ''
            },
            {
              lang: 'zh-Hant',
              value: ''
            },
            {
              lang: 'all',
              value: ''
            }
          ]
        }
      }
    },

    previewText: {
      type: 'previewText',
      display: 'Minute Preview',
      isExpanded: true,
      enabled: true,
      visibility: true,
      supportExpression: true,
      properties: {
        previewNumber: {
          value: 0,
          valueCount: 2,
          minValue: 0,
          maxValue: 59
        }
      }
    }
  }
}

const DIGITAL_CLOCK_SECOND = {
  name: 'Second',
  type: 'digitalClockSecond',
  visible: false,
  visibleOnAod: false,
  canClick: true,
  categories: {
    name: {
      type: 'name',
      display: 'Name',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        name: {
          value: 'Second'
        }
      }
    },
    imageGroup: {
      type: 'imageGroup',
      display: 'Image Group (required)',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'all',
              value: [],
              useExistedImage: false
            }
          ],
          limit: 10
        }
      }
    },
    placement: {
      type: 'placement',
      display: 'Placement',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        x: { value: 10 },
        y: { value: 10 }
      }
    },
    alignment: {
      type: 'alignment',
      display: 'Alignment',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        alignH: { value: 'hmUI.align.LEFT', visibility: true, disabled: false },
        alignV: { value: 'hmUI.align.TOP', visibility: false, disabled: false },
        padding: { value: true, visibility: true, disabled: false },
        space: { value: 0, visibility: true, disabled: false },
        follow: { value: false, visibility: true, disabled: false }
      }
    },
    unitImage: {
      type: 'image',
      display: 'Unit Image',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'en',
              value: ''
            },
            {
              lang: 'zh',
              value: ''
            },
            {
              lang: 'zh-Hant',
              value: ''
            },
            {
              lang: 'all',
              value: ''
            }
          ]
        }
      }
    },

    previewText: {
      type: 'previewText',
      display: 'Second Preview',
      isExpanded: true,
      enabled: true,
      visibility: true,
      supportExpression: true,
      properties: {
        previewNumber: {
          value: 0,
          valueCount: 2,
          minValue: 0,
          maxValue: 59
        }
      }
    }
  }
}

const DIGITAL_CLOCK_AM = {
  name: 'AM',
  type: 'digitalClockAm',
  visible: false,
  visibleOnAod: false,
  canClick: true,
  categories: {
    name: {
      type: 'name',
      display: 'Name',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        name: {
          value: 'AM'
        }
      }
    },
    image: {
      type: 'image',
      display: 'Image',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'en',
              value: ''
            },
            {
              lang: 'zh',
              value: ''
            },
            {
              lang: 'zh-Hant',
              value: ''
            },
            {
              lang: 'all',
              value: ''
            }
          ]
        }
      }
    },
    placement: {
      type: 'placement',
      display: 'Placement',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        x: { value: 10 },
        y: { value: 10 }
      }
    }
  }
}

const DIGITAL_CLOCK_PM = {
  name: 'PM',
  type: 'digitalClockPm',
  visible: false,
  visibleOnAod: false,
  canClick: true,
  categories: {
    name: {
      type: 'name',
      display: 'Name',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        name: {
          value: 'PM'
        }
      }
    },
    image: {
      type: 'image',
      display: 'Image',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'en',
              value: ''
            },
            {
              lang: 'zh',
              value: ''
            },
            {
              lang: 'zh-Hant',
              value: ''
            },
            {
              lang: 'all',
              value: ''
            }
          ]
        }
      }
    },
    placement: {
      type: 'placement',
      display: 'Placement',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        x: { value: 10 },
        y: { value: 10 }
      }
    }
  }
}

/**
 * get fabric object from widget data
 * @param {object} item widget data
 * @returns
 */
const convertDigitalClock2FabricImage = (digitalWidgetData, fabricKey) => {
  let fabricGroup = {
    key: '',
    selectable: true,
    type: 'group',
    objects: []
  }

  if (!digitalWidgetData) {
    return null
  }

  let finalFontArr = []
  let offsetLeft = 0
  let offsetTop = 0
  let finalAlign = ''
  let finalPadding = true
  let finalSpace = 0
  let finalUnit = ''

  const handleWidget = (widget, key) => {
    const { categories } = widget
    const { imageGroup, placement, alignment, unitImage, previewText } = categories
    let {
      image: {
        value: [{ value: fontArr = [] }]
      }
    } = imageGroup.properties
    const {
      x: { value: left },
      y: { value: top }
    } = placement.properties
    const {
      alignH: { value: alignH },
      padding: { value: padding },
      space: { value: space },
      follow: { value: follow }
    } = alignment.properties
    const {
      image: {
        value: [{ value: unit }]
      }
    } = unitImage.properties
    const {
      previewNumber: { value: preview, valueCount }
    } = previewText.properties

    if (follow) {
      fontArr = finalFontArr
    } else {
      // save unfollow widget data
      fabricGroup.key = key
      offsetLeft = left
      offsetTop = top
      finalFontArr = fontArr
      finalAlign = alignH
      finalPadding = padding
      finalSpace = space
      finalUnit = unit
    }

    if (!finalFontArr.length && !finalUnit) {
      fabricGroup = null
    }

    let singlePicWidth = 0
    if (finalFontArr?.[0]) {
      singlePicWidth = finalFontArr?.[0].width
    }

    let previewStr = String(preview)

    if (finalPadding) {
      previewStr = previewStr.padStart(valueCount, '0')
    }

    const fabricGroupTotalWidth = valueCount * singlePicWidth + (valueCount - 1) * finalSpace
    const fabricGroupActualWidth = previewStr.length * singlePicWidth + (previewStr.length - 1) * finalSpace

    if (finalAlign === ALIGN_MAP.right) {
      offsetLeft = offsetLeft + fabricGroupTotalWidth - fabricGroupActualWidth
    }

    if (finalAlign === ALIGN_MAP.center) {
      offsetLeft += (fabricGroupTotalWidth - fabricGroupActualWidth) / 2
    }

    previewStr.split('').forEach((v, i) => {
      const { name = '', image = '', width = 0, height = 0 } = finalFontArr[v] || {}
      const fabricImage = {
        key: `${key}_${i}`,
        left: offsetLeft,
        top: offsetTop,
        width,
        height,
        texture: image,
        url: image,
        name,
        angle: 0
      }
      if (image) {
        fabricGroup.objects.push(fabricImage)
        offsetLeft = offsetLeft + width + finalSpace
      }
    })

    if (finalUnit) {
      const { name, image, width, height } = finalUnit
      const fabricImage = {
        key: `${key}_unit`,
        left: offsetLeft,
        top: offsetTop,
        width,
        height,
        texture: image,
        url: image,
        name,
        angle: 0
      }
      fabricGroup.objects.push(fabricImage)
      offsetLeft = offsetLeft + width + finalSpace
    }
  }

  const { dialType, key } = getKeyOrIdFromStr(fabricKey)

  let widget = null
  let follow = false

  digitalWidgetData.forEach((widgetItem) => {
    if (widgetItem.type === key) {
      widget = widgetItem
    }
    if (widgetItem?.categories?.alignment?.properties?.follow?.value) {
      follow = true
    }
  })

  if (follow) {
    for (const widget of digitalWidgetData) {
      const { id, type } = widget
      const fabricKey = `${dialType}_${type}_${id}`
      handleWidget(widget, fabricKey)
    }
  } else {
    handleWidget(widget, fabricKey)
  }

  return fabricGroup
}

const getImage = (itemImage) => {
  let imageInfo = { name: '', image: '', width: 0, height: 0 }
  const {
    image: { value: imgList }
  } = itemImage.properties
  if (imgList[0].value.image) {
    imageInfo = imgList[0].value
  }
  return imageInfo
}

/**
 * get fabric object from widget data
 * @param {object} item widget data
 * @returns
 */
const convertDigitalAmPm2FabricImage = (widget) => {
  const { categories } = widget
  const { placement, image } = categories
  const {
    x: { value: left },
    y: { value: top }
  } = placement.properties
  const { image: src } = getImage(image)
  if (!src) {
    return null
  }
  return { left, top, texture: src, selectable: true }
}

/**
 * save digital clock time categories key
 */
const orderedDigitalTimeCategoriesKey = Object.keys(DIGITAL_CLOCK_HOUR.categories)

/**
 * save digital clock am and pm categories key
 */
const orderedDigitalAmPmCategoriesKey = Object.keys(DIGITAL_CLOCK_AM.categories)

export {
  DIGITAL_CLOCK_WIDGET,
  DIGITAL_CLOCK_HOUR,
  DIGITAL_CLOCK_MINUTE,
  DIGITAL_CLOCK_SECOND,
  DIGITAL_CLOCK_AM,
  DIGITAL_CLOCK_PM,
  convertDigitalClock2FabricImage,
  convertDigitalAmPm2FabricImage,
  orderedDigitalTimeCategoriesKey,
  orderedDigitalAmPmCategoriesKey
}
