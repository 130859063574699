import React from 'react'
import T from '@watchface/components/I18n'
import { IconComponent } from '../../components'

class System extends React.Component {
  static renderLayer = (...args) => {
    IconComponent.renderLayer(...args)
  }

  static removeLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const { removeLayer } = IconComponent
    removeLayer && removeLayer({ childKey, ...restArgs })
  }

  render() {
    const nameMap = {
      disconnect: <T id="bluetooth_not_connect" />,
      dnd: <T id="dnd_enable" />,
      lock: <T id="screen_locked" />,
      clock: <T id="alarn_on" />
    }
    const { childKey } = this.props

    return (
      <div className="control-container">
        <IconComponent {...this.props} name={nameMap[childKey]} key={childKey} />
      </div>
    )
  }
}

export default System
