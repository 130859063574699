import { PlusOutlined } from '@ant-design/icons'
import T from '@watchface/components/I18n'
import constants, { IMAGE_RETRY_DURATION, SERIES } from '@watchface/constants'
import { changeDesigner } from '@watchface/store/actions'
import { Button, Input, Modal, Radio, Upload, message } from 'antd'
import classNames from 'classnames'
import Immutable from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import { getUploadConfig } from '../../config'
import { processImageUrl, readFileAsUnit8Array } from '../../utils'
import ImageCropModal from './ImageCropModal'
import './upload.scss'
import UploadModal from './UploadModal'
import { isGrayScale, isPng, imgURLToBlob } from '../../utils/img'

type ListType = 'picture' | 'picture-card' | 'text'
type ImageType = '' | '_a' | '_t'

interface StrictSize {
  height: number
  width: number
}

export interface DivideFiles {
  successFiles?: any[]
  errorFiles?: any[]
  allFiles?: any[]
}

interface UploaderProps {
  name?: string
  // 用于多个上传组件同时上传时区分每个组件上传的文件
  field?: string
  className?: string
  maxWidth?: number
  maxHeight?: number
  strictSize?: StrictSize
  shape?: 'square' | 'round'
  imgSizeLimit?: number // 图片大小限制， 单位 KB
  sameImagesSize?: boolean // 上传的图片尺寸是否要一致
  help?: any
  cropImageName?: string
  defineCropImageName?: boolean
  // selectImageType?: boolean; // 可选择三种类型： 实心、渐变、实心+透明
  data?: any
  changeDesigner?: any
  directory?: boolean
  disabled?: boolean
  multiple?: boolean
  showPreviewIcon?: boolean
  showDownloadIcon?: boolean
  showRemoveIcon?: boolean
  hideSelectImageType?: boolean
  fileList?: any[]
  // 限制允许上传的文件个数
  fileCountLimit?: number
  listType?: ListType
  removeAllButton: React.ReactElement
  onChange?: (successFiles: any, field: string) => void
  onRemove?: (removedIndex: number) => void
  handleCropImageNameChange?: (name: string) => void
  [key: string]: any
  cropOption?: {
    radius: number
    targetSize: { width: number, height: number }
  }
}

interface UploaderState {
  previewImg: string
  previewVisible: boolean
  fList?: any
  isVisible: boolean
  message: string | React.ReactNode
  imageType: ImageType
  cropVisible: boolean
  cropImgUrl: string
}

let seed = 0
class Uploader extends React.Component<UploaderProps, UploaderState> {
  hasDefaultFileList = 'fileList' in this.props

  defaultUploadConfig: any = {}

  resolveCall: any = null

  imgSize: any = null

  messageKey = 'upload-message'

  constructor(props: UploaderProps) {
    super(props)
    this.defaultUploadConfig = getUploadConfig()
    this.state = {
      previewImg: '',
      previewVisible: false,
      fList: [],
      isVisible: false,
      message: '',
      imageType: '_t',
      cropVisible: false,
      cropImgUrl: ''
    }
  }

  componentDidMount() {
    const { fileList = [], allData } = this.props
    const { imageType } = this.state
    const newFileList = this.initFileList(fileList)
    const imageKey = fileList[0]?.image

    this.setState({
      fList: newFileList,
      imageType: imageKey ? allData.getIn(['pic_config', imageKey], imageType) : imageType
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { fileList = [], allData } = this.props
    const { imageType } = this.state
    const { fileList: nextFileList = [], allData: nextAllData } = nextProps

    if (allData.get('pic_config') !== nextAllData.get('pic_config')) {
      const imageKey = fileList[0]?.image

      this.setState({
        imageType: imageKey ? nextAllData.getIn(['pic_config', imageKey], imageType) : imageType
      })
    }

    if (this.hasDefaultFileList && nextFileList && fileList.length !== nextFileList.length) {
      const newFileList = this.initFileList(nextFileList)

      this.setState({ fList: newFileList })
    }
  }

  genUid = () => {
    const now = +new Date()
    seed += 1
    return `${now}-${seed}`
  }

  initFileList = (fileList: any) => {
    if (!(Array.isArray(fileList) && fileList.length)) return []

    return fileList.map((file: any, index: number) => {
      const newFile = {
        uid: this.genUid(),
        url: file.image || '',
        name: file.name || index,
        status: 'done',
        ...file
      }
      return newFile
    })
  }

  getResponse = async (fileList: any[]) => {
    const response = []

    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList[i]
      const fileObj = { ...file, ...(file.response || {}) }
      const { url: image = '', width, height, name } = fileObj
      const res: any = { image, width, height, name }

      if (file.error) {
        file.error.statusText = (file.response || {}).message
      }

      if (res.width === undefined || res.width === undefined) {
        const imageSize: any = await this.getImageSize(fileObj.url) // eslint-disable-line
        res.width = imageSize.width
        res.height = imageSize.height
      }

      res.image = image

      if (image) {
        file.image = image
      }

      response.push(res)
    }

    // 因后端接口变更，由文件上传完返回结果变为先返回文件地址，后端再上传文件，
    // 所以返回时文件还未上传完，造成页面渲染问题, 此处临时处理，重试等待文件上传完成
    await Promise.all(fileList.map((file) => this.checkFileLoaded(file)))

    return response
  }

  handleChange = (fileInfo: any) => {
    const info = fileInfo
    const { fileList } = info
    const { onChange, field = '', fileCountLimit = Number.MAX_SAFE_INTEGER } = this.props
    const allIsFinish = fileList.every((fileItem: any) => fileItem.status !== 'uploading')

    if (allIsFinish) {
      this.getResponse([...fileList])
        .then((res) => {
          const { fileCountLimit } = this.props

          if (onChange) {
            this.updatePicConfig(res)
            onChange(fileCountLimit === 1 ? res[0] : res.slice(0, fileCountLimit), field)
          }
        })
        .catch(console.log)
    }

    this.setState({
      fList: this.sortFileListByName(fileList.slice(0, fileCountLimit))
    })

    info.fileList = info.fileList.slice(0, fileCountLimit)
  }

  handlePreview = (file: any) => {
    this.setState({
      previewImg: file.url,
      previewVisible: true,
      isVisible: false,
      message: ''
    })
  }

  handleClosePreview = () => this.setState({ previewVisible: false })

  handleDownload = async (file: any) => {
    const fileName = file.name ? file.name : file.url.substring(file.url.lastIndexOf('/') + 1)
    const blob: any = await imgURLToBlob(file.url)
    blob && saveAs(blob, fileName)
  }

  handleRemove = async (file: any) => {
    const { allData = Immutable.Map(), onRemove, changeDesigner } = this.props
    const { fList } = this.state
    const newData = allData.deleteIn(['pic_config', file.image])

    changeDesigner([
      {
        path: [],
        data: newData
      }
    ])

    if (Array.isArray(fList)) {
      const removedIndex = fList.findIndex(({ uid }) => file.uid === uid)
      if (removedIndex === -1) return false
      onRemove && onRemove(removedIndex)
    }

    return true
  }

  loadImageFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = async (e: any) => {
        const src = e.target.result
        try {
          const size = await this.getImageSize(src)
          resolve(size)
        } catch (err) {
          reject(err)
        }
      }
      reader.onerror = reject
    })
  }

  getImageSize = (src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        const { width, height } = img
        resolve({ width, height })
      }
      img.onerror = reject
    })
  }

  /* eslint-disable */
  checkFileLoaded = (file: any): Promise<any> => {
    const startTime = +new Date()
    let timeId: any = null
    let retryCount = 0
    const newUploaded = !('url' in file)

    if (newUploaded) {
      file.status = 'uploading'
    }

    return new Promise((resolve) => {
      if (!newUploaded) resolve(true)

      function retry() {
        let img: any = new Image()
        retryCount += 1

        timeId && clearTimeout(timeId)
        img.crossOrigin = 'anonymous'

        const retryImageSrc = processImageUrl(file?.image || '', `action=retry&retryCount=${retryCount}`)

        img.src = retryImageSrc

        img.onload = () => {
          file.url = retryImageSrc
          file.status = 'done'
          retryCount = 0
          resolve(true)
        }
        img.onerror = () => {
          const endTime = +new Date()
          img = null
          if (endTime - startTime < IMAGE_RETRY_DURATION) {
            timeId = setTimeout(retry, 200)
          } else {
            file.url = retryImageSrc
            file.status = 'done'
            retryCount = 0
            resolve(false)
          }
        }
      }

      retry()
    })
  }
  /* eslint-enable */

  showTipModal = (message: string | React.ReactNode) => {
    this.setState({
      isVisible: true,
      message
    })
  }

  handleCropImageNameChange = (e: any) => {
    const { handleCropImageNameChange } = this.props

    handleCropImageNameChange && handleCropImageNameChange(e.target.value.trim())
  }

  sortFileListByName = (fileList: any) =>
    fileList.sort((a: any, b: any) => {
      try {
        const isInteger = (v: any) => /^\d+$/.test(v)
        const nameA = a.name.slice(0, a.name.lastIndexOf('.'))
        const nameB = b.name.slice(0, b.name.lastIndexOf('.'))

        // 数字类型名称排序
        if (isInteger(nameA) && isInteger(nameB)) return Number(nameA) - Number(nameB)

        // 字符串类型名称排序
        return nameA.localeCompare(nameB, undefined, { numeric: true })
      } catch {
        // 出现未知错误时保持原序, 确保程序正常运行
        return 0
      }
    })

  imageFileCheck = async (file: File) => {
    const { imgSizeLimit, maxWidth, maxHeight, strictSize, sameImagesSize, fileList, cropOption } = this.props
    let tip: any = ''
    const acceptImage = /^image\/png$/

    if (imgSizeLimit && file.size > imgSizeLimit * 1024) {
      const sizeTip = imgSizeLimit >= 1024 ? `${imgSizeLimit / 1024}MB` : `${imgSizeLimit}KB`
      tip = <T id="img_size_limit" values={{ size: sizeTip }} />
      this.showTipModal(tip)
      return false
    }
    // 校验是否是图片
    if (!acceptImage.test(file.type)) {
      tip = <T id="img_format_error" />
      this.showTipModal(tip)
      return false
    }

    try {
      const img: any = await this.loadImageFile(file) // eslint-disable-line
      const { width, height } = img

      if (sameImagesSize && this.imgSize && (fileList as any)?.length >= 1) {
        const { width: lw, height: lh } = this.imgSize
        if (lw - width !== 0 || lh - height !== 0) {
          tip = <T id="img_size_diff" />
          this.showTipModal(tip)
          return false
        }
      }

      if (maxWidth || maxHeight || strictSize) {
        const strictSizeLimit = cropOption ? cropOption.targetSize : strictSize

        if (strictSizeLimit) {
          const { width: w, height: h } = strictSizeLimit

          if (w - width !== 0 || h - height !== 0) {
            tip = <T id="img_size_error" />
            this.showTipModal(tip)
            return false
          }
        }

        if ((maxWidth && width - maxWidth > 0) || (maxHeight && height - maxHeight > 0)) {
          tip = <T id="img_err_w_h" values={{ name: file.name }} />
          this.showTipModal(tip)
          return false
        }
      }
      this.imgSize = { width, height }
    } catch (err) {
      return false
    }
    return true
  }

  checkImageDataStream = (file: File | Blob, checkImageMode = false) => {
    return new Promise<void>((resolve, reject) => {
      readFileAsUnit8Array(file).then((fileData) => {
        if (!fileData) resolve()

        const fileIsPng = isPng(fileData)

        if (!fileIsPng) {
          reject(new Error('Only supports PNG format.'))
          return
        }

        if (checkImageMode && isGrayScale(fileData)) {
          reject(new Error('Images in grayscale mode are not supported.'))
          return
        }

        resolve()
      })
    })
  }

  checkFile: (...args: any) => any = (file, fileList) => {
    const { cropOption, strictSize, maxWidth, maxHeight, allData } = this.props
    const { fList } = this.state
    const series = allData.getIn(['support', 'series'])
    const checkImageMode = series === SERIES.JS

    return new Promise((resolve) => {
      this.checkImageDataStream(file, checkImageMode).then(async() => {
        // eslint-disable-next-line  no-async-promise-executor
        let checkedCount = 0
        const { width, height } = fList[0] || {}

        if (width >= 0 && height >= 0) {
          this.imgSize = { width, height }
        }

        if (fileList?.length === 1 && cropOption) {
          this.resolveCall = null

          const reader = new FileReader()

          reader.addEventListener('load', async () => {
            const src = reader.result

            if (src && typeof src === 'string') {
              if (strictSize) {
                const img: any = await this.getImageSize(src)
                const targetSize = cropOption.targetSize || strictSize
                const isStrickSize = targetSize.width === img.width && targetSize.height === img.height
                const isTooSmallSize = strictSize.width > img.width || strictSize.height > img.height

                if (isStrickSize) {
                  resolve(file)
                  return
                }

                if (isTooSmallSize) {
                  this.showTipModal(<T id="crop_error" />)
                  return
                }
              }
              // for background image
              if (maxWidth && maxHeight) {
                const img: any = await this.getImageSize(src)
                if (img.width <= maxWidth && img.height <= maxHeight) {
                  resolve(file)
                  return
                }
              }
              this.setState({
                cropVisible: true,
                cropImgUrl: src
              })
              this.resolveCall = resolve
            }
          })
          reader.readAsDataURL(file)
        } else {
          for (let index = 0; index < fileList.length; index += 1) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const res = await this.imageFileCheck(fileList[index])
              if (res) {
                checkedCount += 1
              }
            } catch (error) {
              console.error(error)
              resolve(false)
            }
          }

          if (checkedCount === fileList.length) {
            resolve(file)
          }
        }
      }).catch((err: any) => {
        if (err instanceof Error) {
          message.error({ content: err.message || 'Upload failed', duration: 5, key: this.messageKey })
          Object.assign(file, {
            status: 'error',
            response: { message: err.message }
          })
        }
      })
    })
  }

  handleCropOK = async (file: File) => {
    const res = await this.imageFileCheck(file)
    if (res) {
      this.resolveCall(file)
    }
    this.setState({
      cropVisible: false
    })
  }

  handleCropCancel = () => {
    this.resolveCall = null
    this.setState({
      cropVisible: false
    })
  }

  handleCloseTip = () => {
    this.setState({
      isVisible: false
    })
  }

  handleImageTypeChange = (e: any) => {
    const { fList } = this.state

    this.setState(
      {
        imageType: e.target.value
      },
      () => this.updatePicConfig(fList)
    )
  }

  updatePicConfig = (fileList: any) => {
    const { allData = Immutable.Map(), changeDesigner } = this.props
    let newData = allData
    const { imageType } = this.state

    fileList.forEach((item: any) => {
      newData = newData.setIn(['pic_config', item.image], imageType)
    })

    changeDesigner([
      {
        path: [],
        data: newData
      }
    ])
  }

  renderUploadButton = (listType: ListType, showSelectImageType: boolean) => {
    const { directory, disabled } = this.props
    const text = directory ? <T id="upload_folder" /> : <T id="upload_image" />

    return listType === 'picture-card' ? (
      <>
        <PlusOutlined
          style={{
            color: '#999999',
            fontSize: '30px'
          }}
        />
      </>
    ) : (
      <Button
        style={showSelectImageType ? { marginTop: '14px' } : {}}
        className={disabled ? 'upload-btn-disabled' : 'upload-btn'}
        disabled={disabled}
      >
        {text}
      </Button>
    )
  }

  renderSelectType = () => {
    const { imageType } = this.state

    return (
      <div className="upload-select-imgtype">
        <p className="label">
          <T id="img_format" />:
        </p>
        <Radio.Group onChange={this.handleImageTypeChange} value={imageType}>
          <Radio value="">
            <T id="solid" />
          </Radio>
          <Radio value="_a">
            <T id="gradient" />
          </Radio>
          <Radio value="_t">
            <T id="Solid_transparent" />
          </Radio>
        </Radio.Group>
      </div>
    )
  }

  render() {
    const {
      draggable,
      directory,
      disabled,
      multiple,
      defineCropImageName,
      className,
      showPreviewIcon,
      showDownloadIcon,
      showRemoveIcon,
      // selectImageType,
      listType = 'picture',
      fileCountLimit,
      allData = Immutable.Map(),
      hideSelectImageType,
      onRemove,
      help,
      strictSize,
      maxWidth,
      maxHeight,
      shape,
      cropOption,
      cropImageName,
      removeAllButton,
      ...restProps
    } = this.props
    const series = allData.getIn(['support', 'series'])
    const { previewImg, previewVisible, fList, message, isVisible, cropVisible, cropImgUrl } = this.state
    const newListType = fileCountLimit === 1 ? 'picture-card' : listType
    const isMultiple = fileCountLimit === 1 ? false : multiple
    const showDefineCropImageName = defineCropImageName && isMultiple && fList.length > 0
    const hideUploadBtn = 'fileCountLimit' in this.props && fList.length - (fileCountLimit as number) >= 0
    const showSelectImageType = (constants as any)[series]?.enableSelectImageType && !hideSelectImageType
    const imageShape = shape === 'round' ? 'round' : 'rect'
    const imageSize = { width: 0, height: 0 }
    if (strictSize) {
      imageSize.width = strictSize.width
      imageSize.height = strictSize.height
    } else if (maxWidth && maxHeight) {
      imageSize.width = maxWidth
      imageSize.height = maxHeight
    }
    if (this.hasDefaultFileList) {
      ;(restProps as any).fileList = fList
    }
    const showRemoveAllButton = (restProps as any).fileList?.length > 1 && newListType !== 'picture-card' && showRemoveIcon

    const removeAllButtonClone = removeAllButton
      ? React.cloneElement(removeAllButton, {
          onClick: (e: any) => {
            e?.stopPropagation()
            removeAllButton?.props?.onClick()
          }
        })
      : null

    return (
      <div
        className={classNames('watch-skin-upload', 'crop-images-upload', {
          'watch-skin-upload-card': newListType === 'picture-card',
          'watch-skin-upload-fullWidth': isMultiple
        })}
      >
        {showSelectImageType ? this.renderSelectType() : null}
        {showDefineCropImageName ? (
          <div
            className={classNames('col', {
              'crop-images-name': (!hideUploadBtn || showRemoveAllButton) && !showSelectImageType,
              'crop-images-name-has-imgType': (!hideUploadBtn || showRemoveAllButton) && showSelectImageType
            })}
          >
            <div className="key">
              <T id="slice_name" />
            </div>
            <Input value={cropImageName} style={{ width: '125px', height: '28px' }} onChange={this.handleCropImageNameChange} />
          </div>
        ) : null}
        <Upload
          {...this.defaultUploadConfig}
          {...restProps}
          className={classNames(className)}
          directory={directory}
          disabled={disabled}
          listType={newListType}
          onChange={this.handleChange}
          beforeUpload={this.checkFile}
          onDownload={this.handleDownload}
          multiple={isMultiple}
          onRemove={this.handleRemove}
          onPreview={this.handlePreview}
          accept="image/*"
          showUploadList={{
            showPreviewIcon,
            showDownloadIcon,
            showRemoveIcon
          }}
        >
          {!hideUploadBtn || showRemoveAllButton ? (
            <div
              className={classNames({
                'upload-has-multiple-button': showRemoveAllButton && !hideUploadBtn
              })}
            >
              {hideUploadBtn ? null : this.renderUploadButton(newListType, showSelectImageType)}
              {showRemoveAllButton ? removeAllButtonClone : null}
            </div>
          ) : null}
        </Upload>
        <div className="upload-help">{help}</div>
        <Modal
          visible={previewVisible}
          footer={null}
          centered
          bodyStyle={{
            backgroundColor: '#808080'
          }}
          style={{
            textAlign: 'center'
          }}
          onCancel={this.handleClosePreview}
        >
          <img alt="" style={{ maxHeight: 500 }} src={previewImg} />
        </Modal>
        <UploadModal message={message} isVisible={isVisible} onOk={this.handleCloseTip} />
        <ImageCropModal
          key={JSON.stringify(imageSize)}
          src={cropImgUrl}
          isVisible={cropVisible}
          imageSize={imageSize}
          targetImageSize={cropOption?.targetSize || imageSize}
          imageShape={imageShape}
          radius={cropOption?.radius || 0}
          onOk={this.handleCropOK}
          onCancel={this.handleCropCancel}
        />
      </div>
    )
  }
}

;(Uploader as any).defaultProps = {
  draggable: false,
  directory: false,
  multiple: false,
  disabled: false,
  sameImagesSize: false,
  defineCropImageName: false,
  showPreviewIcon: true,
  showDownloadIcon: true,
  // selectImageType: false,
  showRemoveIcon: true,
  listType: 'picture',
  allData: Immutable.Map(),
  changeDesigner: (data: any) => {
    console.log(data)
  },
  onChange: console.log
}

const mapStateToProps = (state: any) => {
  return {
    allData: state.getIn(['watchFace', 'designer', 'present', 'data']) || Immutable.Map()
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeDesigner: (data: any) => {
      dispatch(changeDesigner(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Uploader)
