import { getKeyOrIdFromStr, META_COMPONENT_MAP } from '@watchface/components/Meta'
import { MOVE_IMAGE } from '@watchface/constants'
import { changeMenuName, updateCategoryProperty, updateWidgetName } from '@watchface/store/actions'
import { Collapse } from 'antd'
import PubSub from 'pubsub-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertJson2FabricText, orderedCategoriesKey } from './config'
import TextInputWithTag from './TextInputWithTag'
import './index.scss'

const { Panel } = Collapse

const TextComponent = (props) => {
  const { parentKey, dialType, onUpdateAttr } = props
  const dispatch = useDispatch()

  const [categoryList, setCategoryList] = useState([])
  const [activeKey, setActiveKey] = useState([])
  const { id: activeWidget } = getKeyOrIdFromStr(parentKey)

  const widget = useSelector((state) => {
    return state.getIn(['watchFace', 'scene', 'widgets', activeWidget], null)
  })

  useEffect(() => {
    const categories = widget.get('categories').toJS()
    const activeKey = []
    const categoryList = orderedCategoriesKey
      .map((categoryKey) => {
        const category = categories[categoryKey]

        if (!category) return

        const { type, display, isExpanded, visibility, properties } = category

        if (isExpanded) {
          activeKey.push(type)
        }

        return { type, display, visibility, properties }
      })
      .filter((category) => category?.visibility)

    setCategoryList(categoryList)
    setActiveKey(activeKey)
    const options = convertJson2FabricText(widget.toJS())
    const key = `${dialType}_text_${activeWidget}`
    onUpdateAttr(key, options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, dialType])

  const onChange = useCallback(
    (category, property, value) => {
      if (category === 'name') {
        dispatch(updateWidgetName(activeWidget, value))
        dispatch(changeMenuName(dialType, [parentKey], value))
      }
      dispatch(updateCategoryProperty(activeWidget, category, property, value))
    },
    [dispatch, dialType, parentKey, activeWidget]
  )

  const onCollapseChange = (value) => {
    setActiveKey(value)
  }

  useEffect(() => {
    PubSub.subscribe(MOVE_IMAGE, (e, { key, left, top }) => {
      // 确保移动的是当前文本组件
      if (key.includes(activeWidget)) {
        onChange('placement', 'x', left)
        onChange('placement', 'y', top)
      }
    })
  }, [onChange, activeWidget])

  return (
    <Collapse className="meta-collapse" activeKey={activeKey} bordered expandIconPosition="right" ghost onChange={onCollapseChange}>
      {categoryList.map((category) => {
        const { type, display, properties } = category
        const PropertyComponent = type === 'text' ? TextInputWithTag : META_COMPONENT_MAP[type]
        return (
          <Panel header={display} key={type}>
            <PropertyComponent onChange={onChange} properties={properties} />
          </Panel>
        )
      })}
    </Collapse>
  )
}

export default React.memo(TextComponent)
