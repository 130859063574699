const config = {
  left: 'hmUI.align.LEFT',
  right: 'hmUI.align.RIGHT',
  center: 'hmUI.align.CENTER_H',
  top: 'hmUI.align.TOP',
  bottom: 'hmUI.align.BOTTOM',
  hcenter: 'hmUI.align.CENTER_H',
  vcenter: 'hmUI.align.CENTER_V'
}

export default config
