export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.IMG_LEVEL, {
      x: {{ x }},
      y: {{ y }},
      image_array: {{{ imgArray }}},
      image_length: {{ imgLength }},
      type: {{ type }},
      show_level: {{ showLevel }},
    });
`
