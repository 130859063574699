import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { Row, Col, Radio, Spin } from 'antd'
import { NAME_LANG_MAP, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { uploadImg } from '@watchface/request/api'
import { getWFCanvasImage, isImgLoaded } from '../../../utils/img'
import './index.scss'

const PREIMGTYPE = {
  CUSTOM: 0,
  SYSTEM: 1
}

type PreviewType = 0 | 1

interface FillPreImgModalProps {
  multiLangList: any
  shape: string
  radius: number
  previewResolution: string
  screenResolution: string
  defaultPreviewMode: PreviewType // CUSTOM: 0, SYSTEM: 1
  handleChange: (data: any) => void
  initRender?: (lang?: string) => void
}

const langMap: any = {
  zh: <T id="lang_zh" />,
  'zh-Hant': <T id="lang_zh-hant" />,
  en: <T id="lang_en" />,
  all: <T id="lang_all" />
}

const FillPreImgModal: React.FC<FillPreImgModalProps> = (props) => {
  const { multiLangList: originList, shape, radius, screenResolution, previewResolution, defaultPreviewMode, handleChange, initRender } = props

  const multiLangList = useMemo(() => originList?.toJS() || [], [originList])

  const languages: any = useMemo(() => multiLangList?.map((item: any) => item.language), [multiLangList])

  const languageOptions: any = useMemo(
    () =>
      languages?.map((lang: any) => ({
        label: langMap[lang],
        value: lang
      })) || [],
    [languages]
  )
  const [imgMapper, setImgMapper] = useState<any>({})
  const [preImgType, setPreImgType] = useState(defaultPreviewMode)
  const currentPreviewImageType = useRef(defaultPreviewMode)
  const [spinning, setSpinning] = useState<boolean>(true)
  const [screenW, screenH] = screenResolution.split('*')
  const changeImgMapper = useCallback(
    (type: PreviewType, targetMapper:any = {}) => {
      setImgMapper(targetMapper || {})
      handleChange(targetMapper[type] || {})
    },
    [setImgMapper, handleChange]
  )

  const handleImage = useCallback(
    (size: string, lang: string) => {
      if (!size || typeof size !== 'string') return {}

      initRender && initRender(lang)

      const [w, h] = size.trim().split('*')

      return new Promise((resolve) => {
        setTimeout(() => {
          getWFCanvasImage(Number(screenW || w), Number(screenH || h), Number(w), Number(h), 'image/png', shape, radius)
            .then((blob: any) => {
              resolve({
                name: `${w}x${h}_${lang}.png`,
                lang,
                blob
              })
            })
            .catch((e) => {
              console.log(e)
              resolve(null)
            })
        }, 1500)
      })
    },
    [initRender, screenW, screenH, radius, shape]
  )

  const transImgToUrl = useCallback(
    async (previewResolution: any, value: any) => {
      try {
        const fileInfo: any = await handleImage(previewResolution, value)
        if (!fileInfo) return Promise.reject()
        const { blob, name, lang } = fileInfo
        const res: any = await uploadImg(new File([blob], name, { type: 'image/png' }))

        const isLoaded = await isImgLoaded(res.url)

        if (isLoaded) {
          return Promise.resolve({
            ...res,
            lang
          })
        }
        return Promise.reject()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    [handleImage]
  )

  const changePreImgType = useCallback(
    (type: any) => {
      setSpinning(false)
      setPreImgType(type)
      currentPreviewImageType.current = type
      const targetMapper: any = {
        ...imgMapper,
        [type]: {}
      }

      if (Object.keys(imgMapper?.[type] || {}).length > 0) {
        changeImgMapper(type, imgMapper)
        return
      }

      if (type === PREIMGTYPE.SYSTEM) {
        setSpinning(true)

        const imgList =
          languageOptions?.map(({ value }: any) => {
            return { previewResolution, value }
          }) || []
        const resList: any = []

        const redo = () => {
          if (imgList.length > 0) {
            const item = imgList.shift()
            transImgToUrl(item.previewResolution, item.value)
              .then((res) => {
                if (currentPreviewImageType.current !== PREIMGTYPE.SYSTEM) return
                resList.push(res)
                redo()
              })
              .catch((e) => {
                console.error(e)
              })
          } else {
            resList.forEach((item: any) => {
              const { url, lang } = item
              targetMapper[type][lang] = url
            })
            changeImgMapper(type, targetMapper)
            setSpinning(false)
            initRender?.()
          }
        }

        redo()
      }

      if (type === PREIMGTYPE.CUSTOM) {
        multiLangList?.forEach((item: any) => {
          const { language, image } = item || {}
          if (language) {
            targetMapper[type][language] = image || ''
          }
        })

        changeImgMapper(type, targetMapper)
      }
    },
    [changeImgMapper, initRender, transImgToUrl, imgMapper, multiLangList, languageOptions, previewResolution]
  )

  useEffect(() => {
    changePreImgType(preImgType)
  }, []) // eslint-disable-line

  const uploadCompChangeImg = (file: any, field: string) => {
    const targetMapper: any = { ...imgMapper }
    const lang = field?.split('@')[0]

    if (file) {
      targetMapper[preImgType][lang] = file.image || ''
    } else {
      targetMapper[preImgType][lang] = ''
    }
    changeImgMapper(preImgType, targetMapper)
  }

  const renderUploadByLang = (lang: string, previewResolution = '') => {
    const [width, height] = previewResolution.split('*')
    const [screenW, screenH] = screenResolution.split('*')
    return (
      <Col key={`${lang}@${preImgType}`} span={8}>
        <Upload
          name="file"
          field={`${lang}@${preImgType}`}
          fileList={
            imgMapper[preImgType]?.[lang]
              ? [
                  {
                    image: imgMapper[preImgType]?.[lang]
                  }
                ]
              : []
          }
          fileCountLimit={1}
          hideSelectImageType
          listType="picture-card"
          strictSize={{
            width: Number(screenW),
            height: Number(screenH)
          }}
          shape={shape}
          help={preImgType === PREIMGTYPE.SYSTEM ? null : <div className="thumbnail-size-tip">{screenResolution}<br/>{NAME_LANG_MAP[lang].label}</div>}
          onChange={uploadCompChangeImg}
          cropOption={{
            radius,
            targetSize: { width: Number(width), height: Number(height) }
          }}
          showRemoveIcon={preImgType !== 1}
        />
      </Col>
    )
  }

  return (
    <>
      <Row className="ant-form-item">
        <Col span="8" className="ant-form-item-label">
          <label className="form-lable">
            <div className="label-len-limit">
              <T id="preImg_type" />
            </div>
          </label>
        </Col>
        <Col span="16">
          <Radio.Group onChange={(e) => changePreImgType(e?.target?.value)} value={preImgType}>
            <Radio value={PREIMGTYPE.SYSTEM}>
              <T id="preImg_type_system" />
            </Radio>
            <Radio value={PREIMGTYPE.CUSTOM}>
              <T id="preImg_type_custom" />
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col span={8} />
        <Col span={16}>
          {preImgType !== PREIMGTYPE.SYSTEM && (<Row>{languageOptions.map(({ value }: any) => renderUploadByLang(value, previewResolution))}</Row>)}
          {preImgType === PREIMGTYPE.SYSTEM && (
            <Spin tip="Loading..." spinning={spinning} wrapperClassName="gen-img-spain">
              <Row>{languageOptions.map(({ value }: any) => renderUploadByLang(value, previewResolution))}</Row>
            </Spin>
          )}
        </Col>
      </Row>
    </>
  )
}

export default FillPreImgModal
