import { Col, Divider, InputNumber, Row, Switch } from 'antd'
import * as React from 'react'

interface Props {
  properties: {
    fps: { value: number }
    count: { value: number }
    repeat: { value: boolean }
  }
  onChange: (category: string, property: string, value: any) => void
}

const Animation: React.FC<Props> = (props) => {
  const {
    properties: {
      fps: { value: fps },
      count: { value: count },
      repeat: { value: repeat }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-animation">
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={6}>帧率</Col>
        <Col span={18}>
          <InputNumber
            min={0}
            max={60}
            defaultValue={fps}
            onChange={(value) => {
              onChange('dimension', 'fps', value)
            }}
          />
        </Col>
      </Row>
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={6}>循环次数</Col>
        <Col span={18}>
          <InputNumber
            min={0}
            max={255}
            defaultValue={count}
            onChange={(value) => {
              onChange('dimension', 'count', value)
            }}
          />
        </Col>
      </Row>
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={6}>是否重复</Col>
        <Col span={18}>
          <Switch
            defaultChecked={repeat}
            onChange={(value) => {
              onChange('alignment', 'repeat', value)
            }}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Animation
