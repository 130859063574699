import Immutable from 'immutable'

const AMPM = Immutable.fromJS({
  position: {
    x: 0,
    y: 0
  },
  images: [
    {
      language: 'zh',
      image: {
        url: '',
        width: 0,
        height: 0
      }
    },
    {
      language: 'en',
      image: {
        url: '',
        width: 0,
        height: 0
      }
    }
  ]
})

export default AMPM
