// doc: huami.feishu.cn/docs/doccn5BOo9hIVNKf7gFvIbDrxLh#CoY43K
export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.IMG_ANIM, {
      x: {{ position.x }},
      y: {{ position.y }},
      anim_path: '',
      anim_prefix: '{{prefix}}',
      anim_ext: '{{ext}}',
      anim_fps: {{fps}},
      anim_size: {{size}},
      display_on_restart: {{replayOnRestart}},
      repeat_count: {{count}},
      anim_status: {{status}},
      show_level: {{ showLevel }},
    });
`
