const LUNAR = {
  rectangle: {
    position: {
      x: 0,
      y: 0,
    },
    width: 100,
    height: 100,
  },
  rect_align: {
    horizontal: 'left',
    vertical: 'top',
  },
  color: '0xFFFFFFFF',
  font_size: 17,
  // show_festival_Term: false, // 废弃字段
  display_type: 1, // 0: 不单独配置区域坐标，1：单独配置区域坐标
}

export default LUNAR
