import ALIGN_MAP from '@watchface/components/ExportZip/convert/constants/ALIGN_MAP'
import { VERTEX_DOT_LINE_STYLE } from '@watchface/constants/graph'
import { TAGS } from '@watchface/constants'

/**
 * text widget data
 */
const TEXT = {
  name: 'Text',
  type: 'text',
  visible: true,
  visibleOnAod: false,
  canClick: true,
  categories: {
    name: {
      type: 'name',
      display: 'Name',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        name: {
          value: 'Text'
        }
      }
    },
    placement: {
      type: 'placement',
      display: 'Placement',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        x: { value: 0 },
        y: { value: 0 }
      }
    },
    dimension: {
      type: 'dimension',
      display: 'Dimension',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        width: { value: 100, visibility: true, disabled: false },
        height: { value: 40, visibility: true, disabled: false }
      }
    },
    alignment: {
      type: 'alignment',
      display: 'Alignment',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        alignH: { value: ALIGN_MAP.left, visibility: true, disabled: false },
        alignV: { value: ALIGN_MAP.top, visibility: true, disabled: false }
      }
    },
    text: {
      type: 'text',
      display: 'Text Field',
      isExpanded: true,
      enabled: true,
      visibility: true,
      supportExpression: true,
      properties: {
        text: {
          value: 'Text'
        }
      }
    },
    color: {
      type: 'color',
      display: 'Color',
      isExpanded: true,
      enabled: true,
      visibility: true,
      enableHsl: false,
      properties: {
        color: {
          value: '0xFFffffff'
        }
      }
    },
    textAppearance: {
      type: 'textAppearance',
      display: 'Text Appearance',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        textSize: { value: 17, visibility: true, disabled: false },
        textStyle: { value: '', visibility: false, disabled: false },
        lineSpace: { value: 2, visibility: false, disabled: false },
        charSpace: { value: 12, visibility: false, disabled: false }
      }
    }
  }
}

const orderedCategoriesKey = Object.keys(TEXT.categories)

const fillDefaultValue2TagExpression = (textValue) => {
  return textValue.replace(/\[.*?\]/g, (tag) => {
    const hitTagItem = TAGS.find(tagItem => tagItem.experssion === tag)

    return hitTagItem ? (hitTagItem?.defaultValue || tag) : tag
  })
}

/**
 * get fabric object from widget data
 * @param {object} item widget data
 * @returns
 */
const convertJson2FabricText = (item, renderLayerByLang) => {
  const { categories } = item
  const { placement, dimension, text, color, textAppearance, alignment } = categories
  const {
    x: { value: left },
    y: { value: top }
  } = placement.properties
  const {
    width: { value: width },
    height: { value: height }
  } = dimension.properties
  const {
    text: { value: newText }
  } = text.properties
  const {
    color: { value: newColor }
  } = color.properties
  // 兼容无 alignment 属性的旧数据
  const horizontal = alignment?.properties?.alignH?.value || ALIGN_MAP.left
  const vertical = alignment?.properties?.alignV?.value || ALIGN_MAP.top
  const fill = newColor ? `#${newColor.slice(4)}` : ''
  const {
    textSize: { value: fontSize }
  } = textAppearance.properties

  const calcLeftDistance = (cellWidth) => {
    if (width < cellWidth) return left

    if (horizontal === ALIGN_MAP.left) {
      return left
    }
    if (horizontal === ALIGN_MAP.hcenter) {
      return (width - cellWidth) / 2 + left
    }
    if (horizontal === ALIGN_MAP.right) {
      return width - cellWidth + left
    }
    return left
  }

  const calcTopDistance = (cellHeight) => {
    if (height < cellHeight) return top

    if (vertical === ALIGN_MAP.top) {
      return top
    }
    if (vertical === ALIGN_MAP.vcenter) {
      return (height - cellHeight) / 2 + top
    }
    if (vertical === ALIGN_MAP.bottom) {
      return height - cellHeight + top
    }

    return top
  }

  const callback = (cellInstance, canvasPage, cell) => {
    const { width, height } = cellInstance
    const left = calcLeftDistance(width)
    const top = calcTopDistance(height)

    if (cell) {
      const index = cell.getObjects().indexOf(cellInstance)

      if (index === 0) {
        cellInstance.set({ left, top })
      } else {
        cellInstance.set({
          left: left + (cell.item(0).left || 0) - cell.left,
          top: top + (cell.item(0).top || 0) - cell.top
        })
      }
    } else {
      cellInstance.set({ left, top })
    }
  }

  const textOption = { fill, text: fillDefaultValue2TagExpression(newText), lineHeight: 1, fontSize, textAlign: 'center', selectable: true, type: 'text', callback }

  const groupOption = {
    type: 'group',
    objects: [
      {
        left,
        top,
        width,
        height,
        type: 'rect',
        fill: 'transparent',
        visible: !renderLayerByLang, // 起区域占位作用
        ...VERTEX_DOT_LINE_STYLE
      },
      textOption
    ]
  }

  // 兼容无 alignment 属性的旧数据
  return alignment ? groupOption : textOption
}

export { TEXT as default, convertJson2FabricText, orderedCategoriesKey }
