import { fabric } from 'fabric'
import { useCallback, useRef } from 'react'

/**
 *
 * @param { fabric.Canvas } fabricCanvas
 * @returns
 */
export default function useGrid(fabricCanvas: fabric.Canvas, uniqueKey: string): any[] {
  const gridRef = useRef<fabric.Path | null>(null)

  const removeGrid = useCallback(() => {
    if (gridRef.current) fabricCanvas.remove(gridRef.current)
  }, [fabricCanvas])

  const drawGrid = useCallback((style?: Partial<fabric.Path> | undefined) => {

    if (!fabricCanvas) {
      return () => {} // eslint-disable-line
    }

    const { gridW = 10, gridH = 10 } = (style as any) || {}

    let path = ''
    const canvasW = fabricCanvas.width || 0
    const canvasH = fabricCanvas.height || 0

    // vertical lines
    for (let i = 0; i < canvasW / gridW; i += 1) {
      const distanceX = i * gridW

      path = `${path} M${distanceX} 0 L${distanceX} ${canvasH} Z`
    }

    // horiZontal lines
    for (let i = 0; i < canvasH / gridH; i += 1) {
      const distanceY = i * gridH

      path = `${path} M0 ${distanceY} L${canvasW} ${distanceY} Z`
    }

    // add grid to canvas
    const gridPathObject = new fabric.Path(path)
    const defaultStyle = {
      key: uniqueKey,
      fill: '',
      stroke: 'red',
      strokeWidth: 1,
      opacity: 0.5,
      selectable: false,
      evented: false
    }

    gridPathObject.set({
      ...defaultStyle,
      ...(style || {})
    })

    gridRef.current = gridPathObject


    if (!fabricCanvas.getObjects('path').find((item: any) => item.key === uniqueKey)) {
      fabricCanvas.add(gridPathObject)
    } else {
      fabricCanvas.moveTo(gridPathObject, fabricCanvas.getObjects().length)
    }

  }, [fabricCanvas, uniqueKey])

  return [drawGrid, removeGrid]
}
