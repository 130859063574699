import React from 'react'
import { PicComponent, PointerProgressComponent, ProgressComponent } from '../../components'
import './index.scss'


const componentMap = {
  text: PicComponent,
  pointer_progress: PointerProgressComponent,
  progress: ProgressComponent
}

class Week extends React.Component {
  static renderLayer = ({ childKey, ...restArgs }) => {
    const Component = componentMap[childKey]
    Component.renderLayer({ childKey, ...restArgs })
  }

  static removeLayer = ({ childKey, ...restArgs }) => {
    const Component = componentMap[childKey]

    if (Component && Component.removeLayer) {
      Component.removeLayer({ childKey, ...restArgs })
    }
  }

  render() {
    const { childKey, propMap } = this.props
    const prop = propMap[childKey] || {}
    const Component = componentMap[childKey]
    const allProps = { ...this.props, ...prop }

    return (
      <div className="week-container control-container">
        <Component {...allProps} />
      </div>
    )
  }
}

export default Week
