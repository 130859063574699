const PIC = {
  text: {
    image: {
      position: {
        x: 0,
        y: 0
      },
      font_images: []
    },
    is_character: false // 默认文字展示
  }
}

export default PIC
