let env = process.env.NODE_ENV || 'production'
let pro = true

if (env === 'production') {
  pro = true
}

// if(location.hostname === 'user.huami.com'){
if (window.location.hostname === 'localhost') {
  pro = false
  env = 'development'
}

if (window.location.hostname.includes('fe-testing-cdn') || window.location.hostname.includes('user-testing')) {
  pro = false
  env = 'test'
}

if (localStorage?.getItem('huami-debug') === 'pro') {
  pro = true
  env = 'production'
} else if (localStorage?.getItem('huami-debug') === 'test') {
  pro = false
  env = 'test'
}

export const Env = env
export const Pro = pro
