import Immutable from 'immutable'

// 数字模块结构

const IMAGE = Immutable.fromJS({
  position: {
    x: 0,
    y: 0
  },
  font_images: [],
  unit_images: [
    {
      language: 'all',
      image: {}
    }
  ],
  invalid_image: '',
  point_image: {
    url: '',
    width: 0,
    height: 0
  },
  negative_image: {
    url: '',
    width: 0,
    height: 0
  }
})

export const SYSTEM_FONT = Immutable.fromJS({
  line: {
    position: {
      x: 0,
      y: 0
    },
    angle: 0
  },
  font_size: 16,
  color: '0xFFFFFFFF'
})

const COMMON_TEXT = Immutable.fromJS({
  interval: 0
})

const SEPARATOR = Immutable.fromJS({
  position: {
    x: 0,
    y: 0
  },
  image: {
    url: '',
    width: 0,
    height: 0
  }
})

const INIT_ALIGN = 'left'
const INIT_RECTALIGIN = Immutable.fromJS({
  horizontal: 'left',
  vertical: 'top'
})
const INIT_COMBIN = 'single'

const RECTANGLE = Immutable.fromJS({
  position: {
    x: 0,
    y: 0
  },
  width: 1,
  height: 1
})

// 根据是否支持系统字体和是否支持上下居中的对齐方式来生成初始化数据
export default function createData({ hasReact = false, isPaddingZero = false, isChar = false }) {
  let text = COMMON_TEXT
  let image = IMAGE

  // if (hasSystemFont) {
  //     image = image.set('system_font', SYSTEM_FONT)
  // }
  // console.log(hasSystemFont)

  if (hasReact) {
    text = text.set('rect_align', INIT_RECTALIGIN)
    image = image.set('rectangle', RECTANGLE)
    image = image.merge(
      Immutable.fromJS({
        imperial_unit_images: [
          {
            language: 'all',
            image: {}
          }
        ]
      })
    )
  } else {
    text = text.set('align', INIT_ALIGN)
  }
  text = text.set('image', image).set('padding_zero', isPaddingZero).set('is_character', isChar)
  return {
    combin: INIT_COMBIN,
    text: text.toJS(),
    separator: SEPARATOR
  }
}
