export default `
    {{{ variable }}} = hmUI.createWidget(hmUI.widget.TEXT_FONT, {
      x: {{ x }},
      y: {{ y }},
      {{ #unitSC }}
      {{ key }}_unit_sc: '{{{ unitSC }}}',
      {{ /unitSC }}
      {{ #unitTC }}
      {{ key }}_unit_tc: '{{{ unitTC }}}',
      {{ /unitTC }}
      {{ #unitEN }}
      {{ key }}_unit_en: '{{{ unitEN }}}',
      {{ /unitEN }}
      {{ #imperialUnitSC }}
      imperial_unit_sc: '{{{ imperialUnitSC }}}',//英制单位
      {{ /imperialUnitSC }}
      {{ #imperialUnitTC }}
      imperial_unit_tc: '{{{ imperialUnitTC }}}',//英制单位
      {{ /imperialUnitTC }}
      {{ #imperialUnitEN }}
      imperial_unit_en: '{{{ imperialUnitEN }}}',//英制单位
      {{ /imperialUnitEN }}
      {{ #negativeImage }}
      negative_image: '{{{ negativeImage }}}', //负号图片
      {{ /negativeImage }}
      {{ #dotImage }}
      dot_image: '{{{ dotImage }}}', //小数点图片
      {{ /dotImage }}
      {{ #invalidImage }}
      invalid_image: '{{{ invalidImage }}}',// 无数据时显示的图片
      {{ /invalidImage }}
      padding: {{ padding_zero }},
      h_space: {{ interval }},
      align_h: {{ align }},
      color: {{ color }},
      font_size: {{ fontSize }},
      type: {{ type }},
      unit_type: 1,
      // imperialUnit_type: //1 是英制单位,
      isCharacter: {{ is_character }},
      show_level: {{ showLevel }},
    });
  `
