const getProgress = (defaultParams = {}) => ({
  circle: {
    position: {
      x: 0,
      y: 0
    },
    radius: 0,
    angle: {
      start: 0,
      end: 360
    }
  },
  progress_render_type: 'image',
  progress_image: '',
  progress_color: '0xFFFFFFFF',
  width: 0,
  bg: '',
  cap: 0, // 0 圆弧 90 三角形 180 平角,
  ...defaultParams
})

export default getProgress
