/* eslint-disable import/order */
import { immutableSelector } from '@/utils/index/'
import { InputNumber, PIC_LANG_MAPPER as LANG_MAPPER, Upload, BackupOfLangImage } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE } from '@watchface/constants'
import { SELECT_STYLE } from '@watchface/constants/style'
import { getOriginLang, isHitKey } from '@watchface/utils'
import { removeCells, renderCells } from '@watchface/utils/render'
import { Select, Button } from 'antd'
import Immutable from 'immutable'
import { compact } from 'lodash'
import PubSub from 'pubsub-js'
import React from 'react'

const { Option } = Select

class Pic extends React.Component {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: Pic,
      ...param
    })
    Pic.keyMap = Object.assign(Pic.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: Pic,
      ...param
    })
    Pic.keyMap = keyMap
  }

  static getCellDatas = ({ data, assets, renderLayerByLang, metas }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const image = Pic.getImage({ data, assets, renderLayerByLang, metas })
    if (image) {
      cells.imageEntity[image.key] = image
    }
    return cells
  }

  static getImage = ({ data, assets, renderLayerByLang, metas }) => {
    const imagesPath = ['text', 'image', 'font_images']
    const posPath = ['text', 'image', 'position']
    const languages = getOriginLang(metas)
    const previewLang = renderLayerByLang || assets.get('previewLang') || languages.split(',')[0]
    const index = assets.getIn(['num', 'value']) || 0
    const position = data.getIn(posPath) || Immutable.Map()
    const { x = 0, y = 0 } = immutableSelector(position)
    const fontImages = data.getIn(imagesPath) || Immutable.List()
    const fontImage = fontImages.find((item) => item.get('language') === previewLang)
    const imgCellKey = languages

    if (!Immutable.Map.isMap(fontImage)) return null

    const images = fontImage.get('images') || Immutable.List()
    const image = images.getIn([index, 'url'])

    if (!image) return null
    if (!languages || !previewLang) return null

    return {
      key: imgCellKey.replace('-', '@'),
      texture: image,
      left: x,
      top: y
    }
  }

  componentDidMount() {
    const { parentKey, childKey, editableComponentKey = '', dialType } = this.props

    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      this.handlePosChange('', {
        x: left,
        y: top
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props
    if (prevProps !== this.props) {
      if (!hidden) {
        Pic.renderLayer(this.props)
      } else {
        Pic.removeLayer(this.props)
      }
    }
  }

  divideImages = (images) => {
    return images.reduce((pre, curr) => {
      const { language, images: imgs = Immutable.List() } = immutableSelector(curr)

      return pre.set(
        language,
        imgs.map((item) =>
          Immutable.Map({
            image: item.get('url')
          })
        )
      )
    }, Immutable.Map())
  }

  handleChange = (lang, images, field) => {
    const { parentKey, childKey, data = Immutable.Map(), onChange, dialType, onAssetsChange, prefixPath } = this.props

    const image = data.getIn(['text', 'image'], Immutable.Map())
    const { font_images = Immutable.List() } = immutableSelector(image)
    const originLangImagesIndex = font_images.findIndex((item) => item.get('language') === field)
    const path = ['text', 'image', 'font_images']
    const langImage = Immutable.fromJS({
      language: field,
      images: images.map(({ image, width, height }) => ({
        url: image,
        width,
        height
      }))
    })
    const newImgsNames = images.map((img) => img.name)
    let newData = data

    if (originLangImagesIndex > -1) {
      path.push(originLangImagesIndex)
      newData = images.length ? data.setIn(path, langImage) : data.deleteIn(path)
    } else {
      newData = images.length ? data.setIn(path, Immutable.fromJS([...font_images, langImage])) : Immutable.fromJS([...font_images])
    }

    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'images_name_map', lang], Immutable.List(newImgsNames))
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleLangChange = (key, value) => {
    const { parentKey, childKey, dialType, data = Immutable.Map(), assets = Immutable.Map(), onChange, onAssetsChange, prefixPath } = this.props
    const imagesPath = ['text', 'image', 'font_images']
    const rootPath = [parentKey, 'children', childKey]
    let newAssets = assets.set(key, value)

    if (key === 'languages') {
      const newData = data.setIn(imagesPath, Immutable.List())
      newAssets = newAssets.set('previewLang', '')
      onChange([...prefixPath, ...rootPath], newData)
    }

    onAssetsChange([dialType, ...prefixPath, ...rootPath], newAssets)
  }

  handlePosChange = (name, value) => {
    const { onChange, parentKey, childKey, data, prefixPath } = this.props
    const path = compact(['text', 'image', 'position', name])
    const newData = data.setIn(path, value)

    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handlePreviewValueChange = (value) => {
    const { parentKey, childKey, dialType, prefixPath, assets = Immutable.Map(), onAssetsChange } = this.props

    const rootPath = [parentKey, 'children', childKey]
    const newAssets = assets.setIn(['num', 'value'], value || 0)

    onAssetsChange([dialType, ...prefixPath, ...rootPath], newAssets)
  }


  renderRemoveAllButton = (lang) => (
    <Button className="upload-btn" onClick={() => this.handleChange(false, [], lang)}>
      Remove All
    </Button>
  )


  renderUploadByLang = (languages, dividedImages, images_name_map) => {
    const { watchConfig } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)

    return languages.split(',').map((lang) => {
      const { label, field } = LANG_MAPPER[lang] || {}
      const fieldList = dividedImages.get(field) || Immutable.List()
      const images_names = (images_name_map || Immutable.Map()).get(lang) || Immutable.Map()

      return (
        <div key={field}>
          <div className="name">{label}</div>
          <div className="content">
            <div className="item">
              <Upload
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                key={languages}
                fileList={fieldList.toJS().map((item, index) => ({
                  ...item,
                  name: images_names.get(index) || `${index}.png`
                }))}
                field={field}
                multiple
                fileCountLimit={7}
                removeAllButton={this.renderRemoveAllButton(lang)}
                onChange={(image, field) => this.handleChange(lang, image, field)}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  getImagesExceptCurrLang = (images, currLang) => {
    return images.filter((image) => image.get('language') !== currLang)
  }

  render() {
    const { data = Immutable.Map(), assets = Immutable.Map(), watchConfig = Immutable.Map(), metas, renderLayerByLang } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const originLang = getOriginLang(metas)
    const { images_name_map = Immutable.List() } = immutableSelector(assets)
    const previewLang = renderLayerByLang || assets.get('previewLang') || originLang.split(',')[0]
    const value = assets.getIn(['num', 'value']) || 0
    const image = data.getIn(['text', 'image'], Immutable.Map())
    const { position = Immutable.Map(), font_images = Immutable.List() } = immutableSelector(image)
    const { x, y } = immutableSelector(position)
    const dividedImages = this.divideImages(font_images)
    const weeks = [<T id="Mon" />, <T id="Tues" />, <T id="Wed" />, <T id="Thur" />, <T id="Fri" />, <T id="Sat" />, <T id="Sunday" />]

    return (
      <div className="control-container">
        <BackupOfLangImage fontImages={font_images} metas={metas} />
        {/* {originLang === languages ? null : (
          <div>
            <div className="name">
              <T id="image" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  value={languages}
                  style={SELECT_STYLE}
                  disabled
                  onChange={(image) => this.handleLangChange('languages', image)}
                >
                  <Option value="all">
                    <T id="lang_all" />
                  </Option>
                  <Option value="zh,zh-Hant,en">
                    <T id="lang_zh" />、<T id="lang_zh-hant" />、<T id="lang_en" />
                  </Option>
                  <Option value="sc,en">
                    <T id="lang_zh&zh-hant" />、<T id="lang_en" />
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )} */}
        {this.renderUploadByLang(originLang, dividedImages, images_name_map)}
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber min={0} max={screenWidth} value={x} onChange={(value) => this.handlePosChange('x', value)} />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber min={0} max={screenHeight} value={y} onChange={(value) => this.handlePosChange('y', value)} />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="item">
            <T id="preview_lang" />
          </div>
          <Select
            dropdownClassName="watch-skin-select-dropdown"
            style={SELECT_STYLE}
            value={previewLang}
            onChange={(value) => this.handleLangChange('previewLang', value)}
          >
            {originLang.split(',').map((lang) => {
              const { label, field } = LANG_MAPPER[lang] || {}

              return (
                <Option key={field} value={field}>
                  {label}
                </Option>
              )
            })}
          </Select>
        </div>
        <div className="content">
          <div className="item">
            <T id="preview_with_name" values={{ name: <T id="week" /> }} />
          </div>
          <Select dropdownClassName="watch-skin-select-dropdown" style={SELECT_STYLE} value={value} onChange={this.handlePreviewValueChange}>
            {weeks.map((week, index) => (
              <Option key={week} value={index}>
                {week}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    )
  }
}

Pic.defaultProps = {
  prefixPath: []
}

export default Pic
