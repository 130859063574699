import { useEffect, useState } from 'react'
import { getUserRegisterInfo } from '@/api/user'

const STORAGE_USER_REGISTER_INFO = 'storage_user_register_info'

export function useUserRegisterInfo(): { [key: string]: any } {
  const storageRegisterInfo = sessionStorage.getItem(STORAGE_USER_REGISTER_INFO)
  const [registerInfo, setRegisterInfo] = useState(JSON.parse(storageRegisterInfo || '{}'))

  useEffect(() => {
    !storageRegisterInfo && getUserRegisterInfo().then((registerInfo: any) => {

      if (!registerInfo?.userId) return

      sessionStorage.setItem(STORAGE_USER_REGISTER_INFO, JSON.stringify(registerInfo))

      setRegisterInfo(registerInfo)
    }).catch((e: any) => {
      console.log(e)
    })
  }, [storageRegisterInfo])

  return registerInfo
}
