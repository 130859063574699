import { Col, Divider, Row, Select } from 'antd'
import * as React from 'react'

const { Option } = Select

interface Props {
  properties: {
    barType: { value: string }
  }
  onChange: (category: string, property: string, value: any) => void
}

const ProgressBarType: React.FC<Props> = (props) => {
  const {
    properties: {
      barType: { value: barType }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-progressBarType">
      <Row justify="space-around" align="middle">
        <Col span={6}>进度类型</Col>
        <Col span={18}>
          <Select
            defaultValue={barType}
            style={{ width: 120 }}
            onChange={(value) => {
              onChange('progressBarType', 'barType', value)
            }}
          >
            <Option value="pointerProgressBar">指针进度</Option>
            <Option value="independentProgressBar">图片独立进度</Option>
            <Option value="continuousProgressBar">图片连续进度</Option>
            <Option value="arcProgressBar">弧形进度</Option>
          </Select>
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default ProgressBarType
