import React from 'react'
import { Select } from 'antd'
import Immutable from 'immutable'
import { InputNumber } from '@watchface/components'
import { SELECT_STYLE } from '@watchface/constants/style'
import T from '@watchface/components/I18n'

interface SelectListProps {
  data: Immutable.Map<string, any>,
  onChange: (path: string[], value: any) => void
}

const { Option } = Select
const SelectList = (props: SelectListProps) => {
  const { data = Immutable.Map(), onChange } = props

  return <>
    <div className="item">
      <T id="title_font_size" />
    </div>
    <div className="content">
      <div className="item">
        <div className="col">
          <InputNumber
            min={0}
            max={150}
            value={data.get('title_font_size')}
            onChange={(value: number) => {
              onChange(['select_list', 'title_font_size'], value)
            }}
          />
        </div>
      </div>
    </div>
    <div className="name">
      <T id="title_align_h" />
    </div>
    <div className="content">
      <div className="item">
        <Select
          dropdownClassName="watch-skin-select-dropdown"
          value={data.get('title_align_h')}
          style={SELECT_STYLE}
          onChange={(value: string) => onChange(['select_list', 'title_align_h'], value)}
        >
          <Option value="center">
            <T id="align_center" />
          </Option>
          <Option value="left">
            <T id="align_left" />
          </Option>
          <Option value="right">
            <T id="align_right" />
          </Option>
        </Select>
      </div>
    </div>
    <div className="item">
      <T id="list_item_vspace" />
    </div>
    <div className="content">
      <div className="item">
        <div className="col">
          <InputNumber
            min={0}
            value={data.get('list_item_vspace')}
            onChange={(value: number) => {
              onChange(['select_list', 'list_item_vspace'], value)
            }}
          />
        </div>
      </div>
    </div>
    <div className="item">
      <T id="list_tips_text_font_size" />
    </div>
    <div className="content">
      <div className="item">
        <div className="col">
          <InputNumber
            min={0}
            max={150}
            value={data.get('list_tips_text_font_size')}
            onChange={(value: number) => {
              onChange(['select_list', 'list_tips_text_font_size'], value)
            }}
          />
        </div>
      </div>
    </div>
    <div className="name">
      <T id="list_tips_text_align_h" />
    </div>
    <div className="content">
      <div className="item">
        <Select
          dropdownClassName="watch-skin-select-dropdown"
          value={data.get('list_tips_text_align_h')}
          style={SELECT_STYLE}
          onChange={(value: string) => onChange(['select_list', 'list_tips_text_align_h'], value)}
        >
          <Option value="center">
            <T id="align_center" />
          </Option>
          <Option value="left">
            <T id="align_left" />
          </Option>
          <Option value="right">
            <T id="align_right" />
          </Option>
        </Select>
      </div>
    </div>
  </>
}

export default SelectList
