const TOP_BAR = [
  {
    name: 'basic_element',
    icon: 'icon-tuceng-kuaijiegongneng',
    items: ['text', 'image']
  },
  {
    name: 'background',
    icon: 'icon-tuceng-beijing',
    items: ['idle_background']
  },
  {
    name: 'time',
    icon: 'icon-tuceng-zhizhenshijian',
    items: ['analog_clock', 'digital_clock' /* 'progress_clock' */]
  },
  {
    name: 'date',
    icon: 'icon-tuceng-riqi',
    items: ['date', /* 'lunar', */ 'week']
  },
  {
    name: 'workout_health',
    icon: 'icon-tuceng-xinshuai',
    items: ['heart_rate', 'calorie', 'pai', 'pai_daily', 'stand', 'step', 'distance']
  },
  {
    name: 'weather',
    icon: 'icon-tuceng-tianqi',
    items: ['weather', 'temperature', 'aqi', 'sun', 'humidity', 'uvi', 'moon', 'altimeter']
  },
  {
    name: 'system',
    icon: 'icon-tuceng-xitong',
    items: ['battery']
  }
  // {
  //     name: 'communication',
  //     icon: 'icon-caidan-tongxun',
  //     items: ['message', 'phone'],
  // },
]

export default TOP_BAR
