/* eslint-disable import/order */
import { isHitKey } from '@watchface/utils'
import { immutableSelector } from '@/utils'
import { InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE } from '@watchface/constants'
import { removeCells, renderCells } from '@watchface/utils/render'
import Immutable from 'immutable'
import { compact } from 'lodash'
import PubSub from 'pubsub-js'
import React, { PureComponent } from 'react'

const noop = () => {} // eslint-disable-line
class IconComponent extends PureComponent {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: IconComponent,
      ...param
    })
    IconComponent.keyMap = Object.assign(IconComponent.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: IconComponent,
      ...param
    })
    IconComponent.keyMap = keyMap
  }

  componentDidMount() {
    const { parentKey, childKey, editableComponentKey = '', dialType } = this.props

    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      this.handleChange('', {
        x: left,
        y: top
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props
    if (prevProps !== this.props) {
      if (!hidden) {
        IconComponent.renderLayer(this.props)
      } else {
        IconComponent.removeLayer(this.props)
      }
    }
  }

  static getCellDatas = ({ data }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const image = IconComponent.getImage({ data })
    if (image) {
      cells.imageEntity[image.key] = image
    }
    return cells
  }

  static getImage = ({ data }) => {
    const { position = Immutable.Map(), image } = immutableSelector(data)
    const { x, y } = immutableSelector(position)
    if (image) {
      return {
        key: 'image',
        texture: image,
        left: x,
        top: y
      }
    }
    return null
  }

  handleInsertImage = (img) => {
    const { data, parentKey, childKey, onChange, prefixPath } = this.props
    let newData = data
    if (img && img.image) {
      newData = data.set('image', img.image)
    } else {
      newData = data.set('image', '')
    }
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleChange = (option, value) => {
    const { data, prefixPath, parentKey, childKey, onChange } = this.props
    const newData = data.setIn(compact(['position', option]), value)
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  render() {
    const { data = Immutable.Map(), watchConfig, name } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { position = Immutable.Map(), image } = immutableSelector(data)
    const { x, y } = immutableSelector(position)
    return (
      <>
        <div className="name is-required">
          <T id="icon" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={image ? [{ image }] : []}
                onChange={this.handleInsertImage}
              />
              {name || <T id="uplod_icon" />}
            </div>
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={x}
                onChange={(value) => {
                  this.handleChange('x', value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={y}
                onChange={(value) => {
                  this.handleChange('y', value)
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

IconComponent.defaultProps = {
  prefixPath: [],
  onChange: noop
}

export default IconComponent
