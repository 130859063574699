import { Col, Divider, InputNumber, Row, Select } from 'antd'
import * as React from 'react'

const { Option } = Select

interface Props {
  properties: {
    textSize: { value: number; visibility: boolean }
    textStyle: { value: string; visibility: boolean }
    lineSpace: { value: number; visibility: boolean }
    charSpace: { value: number; visibility: boolean }
  }
  onChange: (category: string, property: string, value: any) => void
}

const TextAppearance: React.FC<Props> = (props) => {
  const {
    properties: {
      textSize: { value: textSize, visibility: showTextSize } = { value: 0, visibility: true },
      textStyle: { value: textStyle, visibility: showTextStyle } = { value: '', visibility: true },
      lineSpace: { value: lineSpace, visibility: showLineSpace } = { value: 0, visibility: true },
      charSpace: { value: charSpace, visibility: showCharSpace } = { value: 0, visibility: true }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-textAppearance">
      {showTextSize ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={6}>Font Size</Col>
          <Col span={18}>
            <InputNumber
              min={14}
              max={60} // 固件建议限制 60
              defaultValue={textSize}
              onChange={(value) => {
                onChange('textAppearance', 'textSize', value)
              }}
            />
          </Col>
        </Row>
      ) : null}
      {showTextStyle ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={6}>Font Style</Col>
          <Col span={18}>
            <Select
              allowClear
              value={textStyle}
              style={{ width: 120 }}
              onChange={(value) => {
                onChange('textAppearance', 'textStyle', value)
              }}
            >
              <Option value="hmUI.text_style.WRAP">wrap</Option>
              <Option value="hmUI.text_style.CHAR_WRAP">char wrap</Option>
              <Option value="hmUI.text_style.ELLIPSIS">ellipsis</Option>
              <Option value="hmUI.text_style.NONE">none</Option>
            </Select>
          </Col>
        </Row>
      ) : null}
      {showLineSpace ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={6}>Line Space</Col>
          <Col span={18}>
            <InputNumber
              min={0}
              max={20}
              value={lineSpace}
              onChange={(value) => {
                onChange('textAppearance', 'lineSpace', value)
              }}
            />
          </Col>
        </Row>
      ) : null}
      {showCharSpace ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={6}>Char Space</Col>
          <Col span={18}>
            <InputNumber
              min={0}
              max={20}
              value={charSpace}
              onChange={(value) => {
                onChange('textAppearance', 'charSpace', value)
              }}
            />
          </Col>
        </Row>
      ) : null}
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default TextAppearance
