const TOP_BAR = [
  {
    name: 'time',
    icon: 'icon-tuceng-zhizhenshijian',
    items: ['digital_clock', 'analog_clock']
  },
  {
    name: 'date',
    icon: 'icon-tuceng-riqi',
    items: ['date', 'week']
  },
  {
    name: 'workout_health',
    icon: 'icon-tuceng-xinshuai',
    items: ['step']
  }
]

export default TOP_BAR
