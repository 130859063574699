import T from '@watchface/components/I18n'
import Classnames from 'classnames'
import React, { PureComponent } from 'react'
import { BackgroundGroupComponent, IconComponent } from '../../components'

const map = () => ({
  bg: BackgroundGroupComponent,
  tips: IconComponent
})

class EditableBackground extends PureComponent {
  static renderLayer = ({ childKey, ...restArgs }) => {
    const Component = map()[childKey]
    if (Component && Component.renderLayer) {
      Component.renderLayer({ childKey, ...restArgs })
    }
  }

  static removeLayer = ({ childKey, ...restArgs }) => {
    const Component = map()[childKey]

    if (Component && Component.removeLayer) {
      Component.removeLayer({ childKey, ...restArgs })
    }
  }

  renderIcon = () => {
    return <IconComponent {...this.props} name={<T id="editable_bg_tips_bg" />} />
  }

  renderImageProgress = (allProps) => {
    return <BackgroundGroupComponent {...allProps} />
  }

  render() {
    const cls = Classnames(['battery-container', 'control-container'])
    const { parentKey, propMap = {}, childKey } = this.props
    const props = propMap[childKey] || {}
    const allProps = { ...this.props, ...props }
    const map = {
      bg: this.renderImageProgress,
      tips: this.renderIcon
    }
    return (
      <div key={parentKey} className={cls}>
        {map[childKey](allProps)}
      </div>
    )
  }
}

export default EditableBackground
