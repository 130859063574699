import apiHost from '@watchface/request/domains/common'
import Cookies from 'js-cookie'

function getUploadConfig() {
  return {
    action: `${apiHost.apiWatch()}custom/diy/dial/upload?userid=${Cookies.get('userid')}`,
    headers: {
      apptoken: Cookies.get('apptoken') || ''
    },
    data: {
      business_type: 'dial_assets'
    }
  }
}

export default getUploadConfig
