import React from 'react'
import {
  IconComponent, JumpHotAreaComponent, NumberComponent, NumberComponentWithRect, PictureProgress, PointerProgressComponent, ProgressComponent
} from '../../components'

const componentMap = (hasRectArea) => ({
  weekly: hasRectArea ? NumberComponentWithRect : NumberComponent,
  daily: hasRectArea ? NumberComponentWithRect : NumberComponent,
  pointer_progress: PointerProgressComponent,
  icon: IconComponent,
  progress: ProgressComponent,
  image_progress: PictureProgress,
  jumpable: JumpHotAreaComponent
})

class Pai extends React.Component {
  static renderLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const Component = componentMap(hasRectArea)[childKey]
    Component.renderLayer({ childKey, ...restArgs })
  }

  static removeLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    // Object.keys(componentMap).forEach((childKey) => {
    //     const { removeLayer } = componentMap(hasRectArea)[childKey]
    //     removeLayer && removeLayer({ childKey, ...restArgs })
    // })
    const Component = componentMap(hasRectArea)[childKey]

    if (Component && Component.removeLayer) {
      Component.removeLayer({ childKey, ...restArgs })
    }
  }

  render() {
    const { childKey, propMap } = this.props
    const prop = propMap[childKey] || {}
    const { hasRectArea } = prop
    const Component = componentMap(hasRectArea)[childKey]
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent
    const allProps = { ...this.props, ...prop }

    if (childKey === 'weekly') {
      return (
        <div className="control-container">
          <NumComponent {...allProps} />
        </div>
      )
    }
    return <div className="control-container">{Component && <Component {...allProps} />}</div>
  }
}

export default Pai
