import React, { createContext, useState } from 'react'
import { fabric } from 'fabric'
import { noop } from 'lodash'

type FabContext = [fabric.Canvas | null, (c: fabric.Canvas) => void]

export const FabricContext = createContext<FabContext>([null, noop])

const FabricProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)

  const initCanvas = (c: fabric.Canvas): void => {
    if (!canvas) setCanvas(c)
  }

  return <FabricContext.Provider value={[canvas, initCanvas]}>{children}</FabricContext.Provider>
}

export default FabricProvider
