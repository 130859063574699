/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE, SERIES } from '@watchface/constants'
import { renderCells } from '@watchface/utils/render'
import { isHitKey } from '@watchface/utils'
import { Button, Select } from 'antd'
import Immutable from 'immutable'
import { compact } from 'lodash'
import PubSub from 'pubsub-js'
import React from 'react'

const { Option } = Select

class Animation extends React.Component {
  static keyMap = {}

  componentDidMount() {
    const { parentKey, childKey, editableComponentKey = '', dialType } = this.props

    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      this.handlePositionChange('', {
        x: left,
        y: top
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      Animation.renderLayer(this.props)
    }
  }

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: Animation,
      ...param
    })
    Animation.keyMap = Object.assign(Animation.keyMap, keyMap)
  }

  static getCellDatas = ({ data }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const image = Animation.getImage({ data })
    if (image) {
      cells.imageEntity[image.key] = image
    }
    return cells
  }

  static getImage = ({ data }) => {
    const { images = Immutable.List(), position = Immutable.Map() } = immutableSelector(data)
    const { x = 0, y = 0 } = immutableSelector(position)
    const firstImage = images.get(0)
    if (firstImage)
      return {
        key: 'animation',
        texture: firstImage,
        left: x,
        top: y
      }
  }

  handlePositionChange = (key, value) => {
    const { parentKey, childKey, data = Immutable.Map(), onChange } = this.props
    const newData = data.setIn(compact(['position', key]), value)

    onChange([parentKey, 'children', childKey], newData)
  }

  handleChange = (key, value) => {
    const { parentKey, childKey, data, onChange } = this.props
    const newData = data.set(key, value)

    onChange([parentKey, 'children', childKey], newData)
  }

  handleUpload = (images) => {
    if (!Array.isArray(images)) return

    const newImages = images.map((item) => item.image)
    const { parentKey, childKey, dialType, data, onChange, onAssetsChange } = this.props
    const newData = data.set('images', Immutable.fromJS(newImages))
    const imgNames = images.map((item) => item.name)
    onChange([parentKey, 'children', childKey], newData)
    onAssetsChange([dialType, parentKey, 'children', childKey, 'images_names'], Immutable.List(imgNames))
  }

  changeAssets = (key, value) => {
    const { parentKey, childKey, dialType, onAssetsChange } = this.props

    onAssetsChange([dialType, parentKey, 'children', childKey, key], value)
  }

  handleUpload = (images) => {
    const { parentKey, childKey, dialType, data, onChange, onAssetsChange } = this.props
    if (!Array.isArray(images)) return
    const newImages = images.map((item) => item.image)
    const imgNames = images.map((item) => item.name)
    const newData = data.set('images', Immutable.fromJS(newImages))
    onChange([parentKey, 'children', childKey], newData)
    onAssetsChange([dialType, parentKey, 'children', childKey, 'images_names'], Immutable.List(imgNames))
  }

  renderRemoveAllButton = () => (
    <Button className="upload-btn" onClick={() => this.handleUpload([])}>
      Remove All
    </Button>
  )

  render() {
    const { series, data = Immutable.Map(), assets = Immutable.Map(), watchConfig = Immutable.Map() } = this.props
    const isZeppOS = series === SERIES.JS
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { play_times = 0, images = Immutable.List(), position = Immutable.Map(), play_mode = 1, frame_interval } = immutableSelector(data)
    const { images_names = Immutable.List() } = immutableSelector(assets)
    const fileList = (images || Immutable.List()).toJS().map((image, index) => ({
      image,
      name: images_names.get(index)
    }))
    const { x = 0, y = 0 } = immutableSelector(position)
    const showCountInput = play_times !== 255 && !isZeppOS
    let value = isZeppOS ? 1 : 0

    if (play_times === 255) {
      value = 255
    }

    return (
      <div className="control-container">
        <div className="name">
          <T id="upload_image" />
        </div>
        <div className="content">
          <div className="item">
            <Upload
              key="new"
              multiple
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              fileList={images_names.size ? fileList : []}
              field="all"
              onChange={(images) => this.handleUpload(images)}
              sameImagesSize
              showRemoveIcon
              removeAllButton={this.renderRemoveAllButton()}
            />
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={x}
                onChange={(value) => {
                  this.handlePositionChange('x', value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={y}
                onChange={(value) => {
                  this.handlePositionChange('y', value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="play_count" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={{ width: '304px' }}
              onChange={(value) => {
                this.handleChange('play_times', value)
              }}
              value={value}
            >
              <Option value={255}>
                <T id="unlimited" />
              </Option>
              <Option value={isZeppOS ? 1 : 0}>
                {isZeppOS ? <T id="Once" /> : <T id="limited" />}
              </Option>
            </Select>
          </div>
        </div>
        {showCountInput ? (
          <div className="content">
            <div className="item">
              <InputNumber
                min={0}
                max={254}
                value={play_times}
                placeholder={`${(<T id="enter" />)}...`}
                onChange={(value) => {
                  this.handleChange('play_times', value)
                }}
              />
            </div>
          </div>
        ) : null}
        {isZeppOS ? null : <>
          <div className="name">
            <T id="paly_mode" />
          </div>
          <div className="content">
            <div className="item">
              <Select
                dropdownClassName="watch-skin-select-dropdown"
                style={{ width: '304px' }}
                onChange={(value) => {
                  this.handleChange('play_mode', value)
                }}
                value={play_mode}
              >
                <Option value={1}>
                  <T id="loop" />
                </Option>
              </Select>
            </div>
          </div>
        </>}
        <div className="name">
          <T id="frame_interval" />
          （ms）
        </div>
        <div className="content">
          <div className="item">
            <InputNumber
              min={0}
              value={frame_interval}
              onChange={(value) => {
                this.handleChange('frame_interval', value)
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Animation
