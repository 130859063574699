import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  id: string
  values?: { [key: string]: any }
}

const T: React.FunctionComponent<Props> = (props) => {
  const { id, values } = props
  const { formatMessage, messages } = useIntl()

  if (typeof id !== 'string') return id

  const [name, appendix = ''] = id.split('~~')
  if (!messages?.[name])
    return (
      <>
        {name}
        {appendix}
      </>
    )

  return (
    <>
      {formatMessage({ id: name }, values)}
      {appendix}
    </>
  )
}

export default T
