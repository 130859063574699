module.exports = (function () {
  return `
    {{ variable }} = hmUI.createWidget(hmUI.widget.ARC_PROGRESS, {
      center_x: {{ centerX }},  // 圆弧中心位置x坐标
      center_y: {{ centerY }},  // 圆弧中心位置y坐标
      radius: {{ radius }},    // 圆弧内半径
      start_angle: {{{ startAngle }}}, // 开始角度
      end_angle: {{{ endAngle }}},    // 结束角度 (小于'开始角度'时为逆时针)
      color: {{{ color }}},  // 填充色
      line_width: {{{ lineWidth }}},   // 弧形进度条宽度
      {{ #bg }}
      src_bg: '{{{ bg }}}', // optional,背景图片, 显示在与圆弧内切的正方形区域
      {{ /bg }}
      {{ #level }}
      level: {{{ level }}}, // optional, 进度控制[0,100]
      {{ /level }}
      {{ #type }}
      type: {{{ type }}}, // 设置数据类型来驱动进度,不设置的时候靠'level'驱动
      {{ /type }}
      show_level: {{ showLevel }},
    });
`
})()
