import {
  JsonRpcRequestBuilder,
  JsonRpcSerialize,
  IJsonRpcRequest,
  IJsonRpcResponse,
} from '@huamios-js-framework/json-rpc'

export function encodeMessage(id: number, method: string, params: Record<string, unknown>): string {
  const request: IJsonRpcRequest = new JsonRpcRequestBuilder()
    .id(id)
    .method(method)
    .params(params)
    .build()
  return JsonRpcSerialize.encode(request)
}

export function decodeMessage(data: string): IJsonRpcResponse {
  return JsonRpcSerialize.decode<IJsonRpcResponse>(data)
}

export function uint8ToArray(source: Uint8Array): Array<number> {
  return Array.prototype.slice.call(source)
}
