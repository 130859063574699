/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { ColorPicker, InputNumber, Upload, PIC_LANG_MAPPER as LANG_MAPPER, BackupOfLangImage } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE, SERIES } from '@watchface/constants'
import { RADIO_STYLE, SELECT_STYLE } from '@watchface/constants/style'
import { DesignContext } from '@watchface/pages/DesignTool/NonZeppOs'
import { getOriginLang, hex2rgb, isHitKey } from '@watchface/utils'
import { removeCells, renderCells } from '@watchface/utils/render'
import { Button, Image as AntImage, Radio, Select, Modal } from 'antd'
import Immutable from 'immutable'
import { compact } from 'lodash'
import PubSub from 'pubsub-js'
import React, { Component } from 'react'
import { SYSTEM_FONT } from './config'

const { Option, OptGroup } = Select
const noop = () => {} // eslint-disable-line

// 获取当前跟随的 key
function getFollowedKey(allData, before, combin, parentKey, prefixPath) {
  if (Immutable.List.isList(before) && combin === 'follow') {
    return before.find((beforeKey) => {
      const beforeCombin = allData.getIn([...prefixPath, parentKey, 'children', beforeKey, 'combin'])
      if (beforeCombin !== 'follow') {
        return true
      }
      return false
    })
  }
}

class NumberComponent extends Component {
  static previewLang = 'all'

  static keyMap = {}

  static getImages = ({
    parentKey,
    childKey,
    dialType,
    allData,
    series,
    metas,
    allAssets,
    constMap,
    systemFonts,
    prefixPath = [],
    isPreviewTime,
    unitImagePreviewSource,
    renderLayerByLang,
  }) => {
    const data = allData.getIn([...prefixPath, parentKey, 'children', childKey])
    const after = constMap.getIn([childKey, 'after']) || Immutable.List()
    const before = constMap.getIn([childKey, 'before']) || Immutable.List()
    const { combin } = immutableSelector(data)

    let updateKey = childKey

    for (let i = 0; i < after.size; i += 1) {
      const afterKey = after.get(i)
      const combin = allData.getIn([...prefixPath, parentKey, 'children', afterKey, 'combin'])

      if (combin !== 'follow') break

      updateKey = afterKey
    }
    const groupImages = NumberComponent.getRenderNewOrUploadedImage({
      allData,
      metas,
      allAssets,
      key: updateKey,
      parentKey,
      series,
      constMap,
      dialType,
      systemFonts,
      prefixPath,
      isPreviewTime,
      unitImagePreviewSource,
      renderLayerByLang
    })

    const images = []
    const followedKey = getFollowedKey(allData, before, combin, parentKey, prefixPath)
    const useImage = allAssets.getIn([dialType, parentKey, 'children', childKey, 'useImage'], 'new')

    if (groupImages.length) {
      images.push({
        key: `${followedKey || childKey}-group-images`,
        type: 'group',
        selectable: !followedKey && useImage !== 'system',
        objects: groupImages,
      })
    }

    const sepImage = NumberComponent.getSepImage({ parentKey, childKey, data })

    if (sepImage) {
      images.push(sepImage)
    }

    return images
  }

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: NumberComponent,
      canFollowed: true,
      ...param
    })
    NumberComponent.keyMap = Object.assign(NumberComponent.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: NumberComponent,
      canFollowed: true,
      ...param
    })

    NumberComponent.keyMap = keyMap
  }

  static replaceSystemFontColor = (color, src, fontSize) => {

    const rgbObj = hex2rgb(color)

    let image = new Image()

    image.crossOrigin = ''
    image.setAttribute('crossOrigin', 'anonymous')

    return new Promise((resolve) => {
      if (typeof fontSize === 'undefined') {
        resolve(src)
        return
      }

      image.onload = () => {
        let systemFontCanvas = document.createElement('canvas')

        systemFontCanvas.width = fontSize
        systemFontCanvas.height = fontSize

        const ctx = systemFontCanvas.getContext('2d')

        ctx.drawImage(image, 0, 0, fontSize, fontSize)

        if (color !== '#FFFFFF' && rgbObj) {
          const imageData = ctx.getImageData(0, 0, fontSize, fontSize)

          for (let i = 0; i < imageData.data.length; i += 4) {
            imageData.data[i] = imageData.data[i] !== 0 ? rgbObj.r : imageData.data[i]
            imageData.data[i + 1] = imageData.data[i + 1] !== 0 ? rgbObj.g : imageData.data[i + 1]
            imageData.data[i + 2] = imageData.data[i + 2] !== 0 ? rgbObj.b : imageData.data[i + 2]
          }

          ctx.putImageData(imageData, 0, 0)
        }

        const dataUrl = systemFontCanvas.toDataURL('image/png')

        systemFontCanvas = null
        image = null

        resolve(dataUrl)
      }

      image.src = src

      image.onerror = () => {
        resolve(src)
      }
    }).catch(() => Promise.resolve(src))
  }

  static getCellDatas = ({
    parentKey,
    childKey,
    allData,
    metas,
    dialType,
    series,
    constMap,
    allAssets,
    paddingX,
    systemFonts,
    prefixPath,
    isPreviewTime,
    unitImagePreviewSource,
    renderLayerByLang
  }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }

    const images = NumberComponent.getImages({
      parentKey,
      childKey,
      constMap,
      allData,
      metas,
      series,
      allAssets,
      dialType,
      paddingX,
      systemFonts,
      prefixPath,
      isPreviewTime,
      unitImagePreviewSource,
      renderLayerByLang
    })
    images.forEach((image) => {
      cells.imageEntity[image.key] = image
    })

    return cells
  }

  /* eslint-disable */
  static getIndexFromNumber = (value, stepCount, num, padding_zero) => {
    const time = String(value).split(':')
    const isPreviewTime = time.length === 2
    if (!isPreviewTime && (Number.isNaN(Number(value)) || typeof Number(value) !== 'number')) return []
    if (isPreviewTime) {
      const [hour, minute] = time
      if (padding_zero) {
        // 需要先将分钟补全 再补全前面
        const newHour = `${hour}`.padStart(2, '0')
        const newMinute = `${minute}`.padStart(2, '0')

        return `${newHour}:${newMinute}`.split('')
      }
      // 不需要补零的时候不用管整数部分
      return `${hour}:${minute}`.split('')
    }
    const [integer, decimal] = String(value).split('.')

    if (padding_zero) {
      // 需要先将小数补全 再补全前面
      return value === 0 && stepCount
        ? `${value}.${'0'.repeat(stepCount)}`.padStart(num, '0').split('')
        : decimal
        ? (`${integer}`.padStart(num - stepCount - 1, '0') + '.' + `${decimal}`.padEnd(stepCount, '0')).split('')
        : `${integer}`.padStart(num, '0').split('')
    } else {
      // 不需要补零的时候不用管整数部分
      return value === 0 && stepCount
        ? `${value}.${'0'.repeat(stepCount)}`.split('')
        : decimal
        ? (`${integer}.` + `${decimal || ''}`.padEnd(stepCount, '0')).split('')
        : `${integer}`.split('')
    }
  }

  // 获得需要展示的数组 因为组件有跟随的效果所以需要将整体的数据传入并且根据是否跟随进行计算
  static getRenderNewOrUploadedImage = (param) => {
    let { images: imageArr, hideValue } = getImageArr(param)
    imageArr = calImagePosition({
      ...param,
      images: imageArr,
      hideValue
    })

    function calImagePosition({
      allData,
      allAssets = Immutable.Map(),
      key,
      parentKey,
      constMap = Immutable.Map(),
      dialType,
      images,
      prefixPath,
      hideValue
    }) {
      const before = constMap.getIn([key, 'before'])
      const basePath = [...prefixPath, parentKey, 'children', key]
      const nowAssets = allAssets.getIn([dialType, ...basePath]) || Immutable.Map()
      const nowData = allData.getIn(basePath) || Immutable.Map()
      let followedAssets = null
      let followedData = null
      const { combin, text: currentText = Immutable.Map() } = immutableSelector(nowData)
      const followedKey = getFollowedKey(allData, before, combin, parentKey, prefixPath)

      let text = Immutable.Map()
      if (followedKey) {
        followedAssets = allAssets.getIn([dialType, ...prefixPath, parentKey, 'children', followedKey]) || Immutable.Map()
        followedData = allData.getIn([...prefixPath, parentKey, 'children', followedKey]) || Immutable.Map()
      }
      if (followedKey) {
        text = followedData.get('text')
      } else {
        text = nowData.get('text')
      }

      const { image, interval = 0, align } = immutableSelector(text) || Immutable.Map()

      const { position = Immutable.Map(), system_font } = immutableSelector(image)
      let startX = 0
      let startY = 0
      let startAngle = 0
      let useImage = 'new'
      let systemFontStyle = 'line'

      const { line = Immutable.Map(), circle = Immutable.Map() } = immutableSelector(system_font)
      const { position: linePosition, angle: lineAngle = 0 } = immutableSelector(line)
      const { position: circlePosition, angle: circleAngle = 0, radius = 0, is_clockwise = 0 } = immutableSelector(circle)
      const { x: lineX = 0, y: lineY = 0 } = immutableSelector(linePosition)
      const { x: circleX = 0, y: circleY = 0 } = immutableSelector(circlePosition)
      const { width, height } = immutableSelector(images.get(0))
      let result = Immutable.List()

      if (followedAssets) {
        const followedUseImage = followedAssets.get('useImage')
        followedUseImage && (useImage = followedUseImage)
        systemFontStyle = followedAssets.get('systemFontStyle') || 'line'
      } else {
        const nowUseImage = nowAssets.get('useImage')
        nowUseImage && (useImage = nowUseImage)
        systemFontStyle = nowAssets.get('systemFontStyle') || 'line'
      }

      if (useImage === 'new' || useImage === 'already') {
        startX = position.get('x')
        startY = position.get('y')
      } else {
        startX = lineX
        startY = lineY
        startAngle = lineAngle
      }
      if (useImage === 'new' || useImage === 'already') {
        result = calLinePosition({ images: images, startX, startY, startAngle, interval })
        result = addLinePaddingLeft({ images: result, hideValue, align, interval })
      } else if (useImage === 'system') {
        if (systemFontStyle === 'line') {
          result = calLinePosition({ images: images, startX, startY, startAngle, interval })
          // result = addLinePaddingLeft({ images: result, hideValue, align })
        } else if (systemFontStyle === 'circle') {
          result = calCirclePosition({
            images: images,
            cx: circleX,
            cy: circleY,
            radius,
            width,
            height,
            startAngle: circleAngle,
            interval,
            direction: is_clockwise
          })
        }
      }

      // 计算直线型展示位置
      function calLinePosition({ images, startX, startY, interval, startAngle }) {
        if (startAngle) {
          // 三角形斜边长度
          let preL = 0

          return images.map((image, index) => {
            if (index > 0) {
              const preImage = images.getIn([index - 1]) || Immutable.Map()
              const width = preImage.get('width') || 0
              preL += width + interval
            }
            const angle = (startAngle / 180) * Math.PI
            return image.merge({
              left: startX + preL * Math.cos(angle),
              top: startY + preL * Math.sin(angle),
              texture: image.get('url'),
              angle
            })
          })
        } else {
          // 前面相邻图片的横坐标
          let preX = startX

          return images.map((image, index) => {
            if (index > 0) {
              const preImage = images.getIn([index - 1]) || Immutable.Map()
              const width = preImage.get('width') || 0
              preX += width + interval
            }

            return image.merge({
              left: preX,
              top: startY,
              texture: image.get('url'),
              angle: startAngle
            })
          })
        }
      }

      // 计算弧形展示位置
      function calCirclePosition({ images, cx, cy, radius, width, height, startAngle, interval, direction }) {
        if (direction) {
          let a = (Math.atan((width + interval) / 4 / radius) * 180) / Math.PI
          return images.map((image, i) => {
            const angle = 2 * a * i + startAngle
            let x = Math.cos((Math.PI * angle) / 180) * radius
            let y = Math.sin((Math.PI * angle) / 180) * radius

            return image.merge(
              Immutable.fromJS({
                left: x * 2 + cx,
                top: y * 2 + cy,
                angle: angle + 90,
                texture: image.get('url')
              })
            )
          })
        } else {
          let a = (Math.atan((width + interval) / 4 / radius) * 180) / Math.PI
          return images.map((image, i) => {
            const angle = startAngle + 180 - 2 * a * i
            let x = Math.cos((Math.PI * angle) / 180) * radius
            let y = Math.sin((Math.PI * angle) / 180) * radius
            return image.merge(
              Immutable.fromJS({
                left: x * 2 + cx,
                top: y * 2 + cy,
                angle: angle + 90,
                originX: 'center',
                originY: 'top',
                scaleX: -1,
                scaleY: -1,
                texture: image.get('url')
              })
            )
          })
        }
      }

      // 计算直线型展示的 padding left
      function addLinePaddingLeft({ images, hideValue, align, interval }) {
        const fontImages = images.filter((item) => item.get('key').indexOf('text-pointImage') === -1)

        if (!fontImages.size) return images

        const width = fontImages.getIn([0, 'width'])
        const newHideValue = hideValue
        const restWidth = newHideValue * width + interval * newHideValue
        let paddingLeft = 0

        if (align === 'right') paddingLeft = restWidth
        if (align === 'center') paddingLeft = restWidth / 2

        return images.map((item) => {
          if (item.has('left') && paddingLeft > 0) {
            return item.set('left', item.get('left') + paddingLeft)
          }
          return item
        })
      }
      return result
    }

    function getImageArr({
      allData,
      metas,
      allAssets = Immutable.Map(),
      key,
      series,
      parentKey,
      constMap = Immutable.Map(),
      dialType,
      images = Immutable.List(),
      hideValue = 0,
      prefixPath,
      systemFonts,
      isPreviewTime,
      unitImagePreviewSource,
      renderLayerByLang,
    }) {
      const isYear = key === 'year'
      const basePath = [...prefixPath, parentKey, 'children', key]
      const nowAssets = allAssets.getIn([dialType, ...basePath]) || Immutable.Map()
      const nowData = allData.getIn(basePath) || Immutable.Map()
      let followedAssets = null
      let followedData = null
      const { combin } = immutableSelector(nowData)
      const padding_zero = nowData.getIn(['text', 'padding_zero'])
      const { supportImage, valueCount: vc } = immutableSelector(constMap.get(key))

      let valueCount = vc

      if (isYear) {
        // 是否简写 2023 -> 23, gt2 与 zepp os 正好相反
        if ((!padding_zero && series === SERIES.JS) || (padding_zero && series === SERIES.GT2)) {
          valueCount = 2
        } else {
          valueCount = 4
        }
      }

      const before = constMap.getIn([key, 'before'])
      const stepCount = constMap.getIn([key, 'stepCount'])
      const followedKey = getFollowedKey(allData, before, combin, parentKey, prefixPath)
      const beforeLastKey = getBeforeKey(before)
      let nowImages = Immutable.List()
      let nowHideValue = 0

      if (followedKey) {
        followedAssets = allAssets.getIn([dialType, ...prefixPath, parentKey, 'children', followedKey]) || Immutable.Map()
        followedData = allData.getIn([...prefixPath, parentKey, 'children', followedKey]) || Immutable.Map()
        const { images: beforeImages, hideValue: beforeHideValue } = getImageArr({
          allData,
          metas,
          series,
          allAssets,
          key: beforeLastKey,
          parentKey,
          constMap,
          dialType,
          images: nowImages,
          hideValue: nowHideValue,
          systemFonts,
          prefixPath,
          isPreviewTime,
          unitImagePreviewSource
        })
        nowImages = nowImages.concat(beforeImages)
        nowHideValue = hideValue + beforeHideValue
      }

      // 获取当前前一个的 key
      function getBeforeKey(before) {
        if (Immutable.List.isList(before)) {
          return before.first()
        }
        return ''
      }

      function dealShowImageValue(assets, stepCount, isImage, isPreviewTime, previewRecommendValue) {
        let value = isPreviewTime ? '0:0' : 0
        if (previewRecommendValue && isPreviewTime) value = '10:09'
        if (isPreviewTime) {
          const hourAssetVal = assets.getIn(['num', 'hour'])
          const minuteAssetVal = assets.getIn(['num', 'minute'])
          const hour = previewRecommendValue ? constMap.getIn(['hour', 'recommendPreviewValue']) || hourAssetVal : hourAssetVal || 0
          const minute = previewRecommendValue ? constMap.getIn(['minute', 'recommendPreviewValue']) || minuteAssetVal : minuteAssetVal || 0
          value = `${hour}:${minute}`

          return value
        }

        value = assets.getIn(['num', 'value']) || (isImage ? 1 : 0)

        if (previewRecommendValue) {
          value = constMap.getIn([key, 'recommendPreviewValue']) || value
        }

        const dotIndex = String(value).indexOf('.')
        if (dotIndex > -1) {
          value = String(value).slice(0, dotIndex + stepCount + 1)
          if (previewRecommendValue) {
            value = constMap.getIn([key, 'recommendPreviewValue']) || value
          }
        }

        return value
      }

      function getImageMeta({ nowData, followedData, nowAssets, followedAssets, stepCount, renderLayerByLang }) {
        // 如果存在 followedAssets 和 followedData 说明当前的组件是跟随状态
        // 考虑有一些值会从这两个 followed 的上面取
        // 预览值 需要从 nowAssets 上获取
        let text = Immutable.Map()
        if (followedData) {
          text = followedData.get('text')
        } else {
          text = nowData.get('text')
        }
        const { image = Immutable.Map(), is_character = false } = immutableSelector(text)
        const padding_zero = nowData.getIn(['text', 'padding_zero'])
        const isImage = supportImage && is_character
        const value = dealShowImageValue(nowAssets, stepCount, isImage, isPreviewTime, renderLayerByLang)
        const indexes = NumberComponent.getIndexFromNumber(value, stepCount, valueCount, padding_zero)
        const numCount = indexes.length > valueCount ? valueCount : indexes.length
        const { position = Immutable.Map(), point_image = Immutable.Map(), system_font = Immutable.Map() } = immutableSelector(image)
        const pointImage = point_image || Immutable.Map()
        const font_images = image.get('font_images') || Immutable.List()
        const defaultLang = getOriginLang(metas).split(',')[0]
        const previewLang = renderLayerByLang || (isImage ? (nowAssets.get('previewLang') || defaultLang) : NumberComponent.previewLang)
        let fontImages = Immutable.List()
        let useImage = 'new'
        if (followedAssets) {
          followedAssets.get('useImage') && (useImage = followedAssets.get('useImage'))
        } else {
          nowAssets.get('useImage') && (useImage = nowAssets.get('useImage'))
        }

        if (useImage === 'new' || useImage === 'already') {
          fontImages = NumberComponent.getNewOrUploadedImages({ isImage, font_images, previewLang })
        } else {
          fontImages = NumberComponent.getSystemImages({ data: followedData || nowData, systemFonts, previewLang })
        }

        // 根据当前的预览值 从图片数组中选择图片
        let result = Immutable.List()
        if (isImage) {
          const img = fontImages.get(value - 1)
          if (img) {
            result = result.push(
              img.merge(
                Immutable.Map({
                  key: `${key}-fontImage${value - 1}`
                })
              )
            )
          }
        } else {
          indexes.forEach((i, index) => {
            if (i === '-') {
              const negativeImage = getNegativeImage({ nowData })
              if (negativeImage) {
                result = result.push(negativeImage)
              }
            } else if (i === '.' || i === ':') {
              if (pointImage.size && pointImage.get('url')) {
                result = result.push(
                  pointImage.merge({
                    key: `${key}-pointImage${index}`
                  })
                )
              }
            } else {
              const item = fontImages.get(i)
              if (item) {
                result = result.push(
                  item.merge({
                    key: `${key}-fontimage${index}`
                  })
                )
              }
            }
          })
        }
        return { imageMeta: result, hideValueCount: isImage ? 0 : (nowHideValue + valueCount - numCount) }
      }

      function getUnitImage({ nowData, nowAssets }) {
        const previewUnit = nowAssets.get('previewUnit')
        const isPreviewImperialUnit = (previewUnit || '').indexOf('imperial_unit_images') > -1
        const text = nowData.get('text') || Immutable.Map()
        const { image = Immutable.Map() } = immutableSelector(text)
        let { unit_images: unitImages = Immutable.List(), imperial_unit_images: imperialUnitImages } = immutableSelector(image)

        if (isPreviewImperialUnit) {
          const previewImperialUnitLang = previewUnit.split('@_@')[1]
          const imperialUnitImage = imperialUnitImages?.find((unitImage) => unitImage && unitImage.get('language') === previewImperialUnitLang) || Immutable.Map()
          let img = imperialUnitImage.get('image')
          if (img && img.get('url')) {
            return img.merge({
              key: `${key}-unitImage`
            })
          }
          return null
        }

        let unit = nowAssets.get('unit')

        if (!unit) {
          unit = unitImagePreviewSource ? unitImagePreviewSource[0].language : 'all'
        }

        if (!unitImages) unitImages = Immutable.List()

        const unitImage = unitImages.find((unitImage) => unitImage && unitImage.get('language') === unit) || Immutable.Map()
        let img = unitImage.get('image') || Immutable.Map()
        if (img && img.get('url')) {
          return img.merge({
            key: `${key}-unitImage`
          })
        }

        return null
      }

      function getNegativeImage({ nowData }) {
        const text = nowData.get('text') || Immutable.Map()
        const { image = Immutable.Map() } = immutableSelector(text)
        const { negative_image: negativeImage = Immutable.Map() } = immutableSelector(image)

        if (negativeImage && negativeImage.get('url')) {
          return negativeImage.merge({
            key: `${key}-negativeimage`
          })
        }
        return null
      }
      const { imageMeta, hideValueCount } = getImageMeta({ nowData, followedData, nowAssets, followedAssets, stepCount, renderLayerByLang })
      const unitImage = getUnitImage({ nowData, nowAssets })
      nowImages = nowImages.concat(imageMeta)
      if (unitImage) {
        nowImages = nowImages.push(unitImage)
      }

      return { images: images.concat(nowImages), hideValue: hideValueCount + hideValue }
    }
    return imageArr.toJS()
  }

  static getNewOrUploadedImages = ({ isImage, font_images, previewLang }) => {
    let fontImagesIndex = 0
    if (isImage) {
      fontImagesIndex = font_images.findIndex((item) => item.get('language') === previewLang)
    }
    let { images: fontImages = Immutable.List() } = immutableSelector(fontImagesIndex === -1 ? Immutable.Map() : font_images.get(fontImagesIndex))
    return fontImages || Immutable.List()
  }
  static getSystemImages = ({ systemFonts, data, previewLang }) => {
    const images = systemFonts.getIn([previewLang, 'images']) || Immutable.List()
    const system_font = data.getIn(['text', 'image', 'system_font']) || Immutable.Map()
    const { color, font_size = 18 } = immutableSelector(system_font)

    return images.map((image) => {
      return Immutable.fromJS({
        url: NumberComponent.replaceSystemFontColor(color.replace('0xFF', '#'), image, font_size),
        width: font_size,
        height: font_size,
      })
    })
  }

  static getSepImage = ({ childKey, data }) => {
    const { separator = Immutable.Map() } = immutableSelector(data)
    const { position = Immutable.Map(), image = Immutable.Map() } = immutableSelector(separator)
    const { x, y } = immutableSelector(position)
    const { url } = immutableSelector(image)
    if (url) {
      return {
        key: `${childKey}-separator`,
        texture: url,
        left: x,
        top: y
      }
    }
    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      systemFontImages: []
    }
  }

  componentDidMount() {
    const { assets, parentKey, childKey, editableComponentKey = '', dialType } = this.props
    const useImage = assets.get('useImage') || 'new'

    useImage === 'system' && this.getCurrentColorSystemFontImages()
    this.timerIds = []

    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      const isSeparator = key.indexOf('separator') > -1

      const useImage = assets.get('useImage') || 'new'
      // 系统图片坐标计算比较复杂，计算逻辑后续梳理完成后支持
      if (useImage === 'system') return
      // const systemFontStyle = assets.get('systemFontStyle') || 'line'
      // const positionDataPath = useImage === 'system' ? ['text', 'image', 'system_font', systemFontStyle, 'position'] : ['text', 'image', 'position']

      const positionDataPath = isSeparator ? ['separator', 'position'] : ['text', 'image', 'position']

      const position = Immutable.Map({
        x: left,
        y: top,
      })

      if (isSeparator) {
        this.handleSepChange(positionDataPath, '', position)
      } else {
        this.handleChange(positionDataPath, '', false, position)
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden, prefixPath } = this.props

    if (prevProps !== this.props) {
      const { allData: preAllData, data: preData } = prevProps
      const { allData, data, parentKey, childKey, assets } = this.props
      const font_images_path = ['text', 'image', 'font_images']
      const combin_path = [...prefixPath, parentKey, 'children', childKey, 'combin']
      const sys_font_color_path = ['text', 'image', 'system_font', 'color']
      const fontImages = data.getIn(font_images_path) || Immutable.List()
      const preFontImages = preData.getIn(font_images_path) || Immutable.List()
      const combin = allData.getIn(combin_path)
      const preCombin = preAllData.getIn(combin_path)
      const follow = preCombin !== combin && combin === 'follow'

      if (follow) {
        const direct = combin === 'follow' ? 'before' : 'after'
        this.syncCombinFontImages(data, direct)
      }
      if (!hidden) {
        // 更新时对当前的 childkey 进行重新渲染
        NumberComponent.renderLayer(this.props)
      } else {
        NumberComponent.removeLayer(this.props)
      }

      const useImage = assets.get('useImage') || 'new'
      const oldColor = preData.getIn(sys_font_color_path)
      const newColor = data.getIn(sys_font_color_path)

      if (useImage === 'system' && oldColor !== newColor) {
        this.getCurrentColorSystemFontImages()
      }
    }
  }

  componentWillUnmount() {
    this.timerIds.forEach(clearTimeout)
  }

  /**
   *  同步跟随和被跟随者的图片 List 数据
   *
   *  @param {Immutable.Map} data
   *  @param {string} direction
   */
  syncCombinFontImages = (data, direction = 'after') => {
    const { allData, parentKey, childKey, constMap = Immutable.Map(), onChange, prefixPath } = this.props
    const arr = constMap.getIn([childKey, direction]) || Immutable.List()

    if (!arr.size) return

    const fontImagePath = ['text', 'image', 'font_images']
    const systemFontPath = ['text', 'image', 'system_font']

    if (direction === 'after') {
      try {
        const baseImages = data.getIn(fontImagePath)
        const baseSystemFont = data.getIn(systemFontPath)

        for (let i = 0; i < arr.size; i++) {
          const key = arr.get(i)
          const crtData = allData.getIn([...prefixPath, parentKey, 'children', key])
          const combin = crtData.get('combin')

          if (combin === 'follow') {
            const newData = crtData.setIn(fontImagePath, baseImages).setIn(systemFontPath, baseSystemFont)

            onChange([...prefixPath, parentKey, 'children', key], newData)
          } else {
            break
          }
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        let i = 0
        for (; i < arr.size; i++) {
          const key = arr.get(i)
          const crtData = allData.getIn([...prefixPath, parentKey, 'children', key])
          const combin = crtData.get('combin')

          if (combin !== 'follow') {
            break
          }
        }

        if (i < arr.size) {
          const baseData = allData.getIn([...prefixPath, parentKey, 'children', arr.get(i)])
          const baseImages = baseData.getIn(fontImagePath)
          const baseSystemFont = baseData.getIn(systemFontPath)

          for (let j = i - 1; j >= 0; j--) {
            const crtData = allData.getIn([...prefixPath, parentKey, 'children', arr.get(j)])
            const newData = crtData.setIn(fontImagePath, baseImages).setIn(systemFontPath, baseSystemFont)

            onChange([...prefixPath, parentKey, 'children', arr.get(j)], newData)
          }

          const crtData = allData.getIn([...prefixPath, parentKey, 'children', childKey])
          const newData = crtData.setIn(fontImagePath, baseImages).setIn(systemFontPath, baseSystemFont)

          onChange([...prefixPath, parentKey, 'children', childKey], newData)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  handleSepChange = (path, prop, value) => {
    const { onChange = noop, data, childKey, parentKey, prefixPath } = this.props
    const newData = data.setIn(compact([...path, prop]), value)
    const rootPath = [parentKey, 'children']

    onChange([...prefixPath, ...rootPath, childKey], newData)
  }

  handleChange = (path, prop, isAssetsValue, val) => {
    let value = val
    const {
      data,
      onChange = noop,
      assets = Immutable.Map(),
      onAssetsChange = noop,
      childKey,
      dialType,
      allData,
      parentKey,
      prefixPath,
      allAssets,
      changeCallback
    } = this.props
    let newData = data
    let newAssets = assets
    let newAllAssets = allAssets

    if (prop === 'is_character') {
      value = !!value
      newAssets = newAssets.setIn(['num', 'value'], value ? 1 : 0).set('alreadyImageKey', '')
      // 图片类型 和 （补零 和 间距 和 图片）互斥 清除数据
      newData = newData
        .setIn([...path, 'padding_zero'], false)
        .setIn([...path, 'interval'], 0)
        .setIn([...path, 'image', 'font_images'], Immutable.List())
    }
    newData = newData.setIn(compact([...path, prop]), value)
    const rootPath = [parentKey, 'children']

    if (isAssetsValue) {
      newAssets = newAssets.setIn(compact([...path, prop]), value)
    }

    if (prop === 'combin') {
      this.changeAssets('alreadyImageKey', '')

      const paths = [
        {
          path: ['text', 'image', 'position'],
          defaultValue: Immutable.Map({ x: 0, y: 0 })
        },
        {
          path: ['text', 'interval'],
          defaultValue: 0
        },
        {
          path: ['text', 'align'],
          defaultValue: 'left'
        },
        {
          path: ['text', 'is_character'],
          defaultValue: false
        }
      ]

      if (value === 'follow') {
        // 跟随时删除部分参数
        paths.forEach(({ path }) => {
          newData = newData.deleteIn(path)
        })
      } else {
        // 独立时恢复默认值
        paths.forEach(({ path, defaultValue }) => {
          newData = newData.setIn(path, defaultValue)
        })

        newData = newData.setIn(['text', 'image', 'font_images'], Immutable.List())
      }
    }

    newAllAssets = allAssets.setIn([dialType, ...prefixPath, ...rootPath, childKey], newAssets)

    onAssetsChange([dialType, ...prefixPath, ...rootPath, childKey], newAssets)
    onChange([...prefixPath, ...rootPath, childKey], newData)
    const newAllData = allData.setIn([...rootPath, childKey], newData)
    changeCallback && changeCallback({ allData: newAllData, allAssets: newAllAssets })
  }

  handleRadioChange = (e) => {
    const { prefixPath, data, parentKey, childKey, onChange } = this.props
    const { value } = e.target
    const sys_font_path = ['text', 'image', 'system_font']
    const font_images_path = ['text', 'image', 'font_images']

    let newData = data

    if (value === 'system') {
      newData = newData.deleteIn(font_images_path).setIn(sys_font_path, SYSTEM_FONT)
    } else {
      newData = newData.deleteIn(sys_font_path).setIn(font_images_path, Immutable.List())
    }
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
    this.changeAssets('useImage', value)
    this.changeAssets('alreadyImageKey', '')
  }

  handleCheckChange = (path, prop, isAssetsValue, value, e) => {
    e.preventDefault()
    this.handleChange(path, prop, isAssetsValue, !value)
  }

  changeAssets = (key, value) => {
    const { parentKey, childKey, dialType, onAssetsChange, prefixPath } = this.props

    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, key], value)
  }

  //   resetFontImages = () => {
  //     const { allData, allAssets, parentKey, childKey, data, onChange, constMap, dialType, prefixPath } = this.props
  //     const font_images_path = ['text', 'image', 'font_images']
  //     const newData = data.setIn(font_images_path, Immutable.List())
  //     const newAllData = allData.setIn([parentKey, 'children', childKey], newData)

  //     onChange([...prefixPath, parentKey, 'children', childKey], newData)
  //   }

  getCurrentColorSystemFontImages = () => {
    const { data, systemFonts } = this.props
    const sys_font_color_path = ['text', 'image', 'system_font', 'color']
    const { images } = immutableSelector(systemFonts.get('all'))
    const color = data.getIn(sys_font_color_path)

    if (!color || color === '0xFFFFFFFF') {
      this.setState({
        systemFontImages: images.toJS()
      })
      return
    }

    Promise.all(images.toJS().map((image) => NumberComponent.replaceSystemFontColor(color.replace('0xFF', '#'), image)))
      .then((sysFontImages) => {
        this.setState({
          systemFontImages: sysFontImages
        })
      })
      .catch(() => {
        this.setState({
          systemFontImages: images
        })
      })
  }

  getFontImageArr = (fontImages) => {
    return fontImages
      .reduce((pre, fontImage) => {
        const { images = Immutable.List() } = immutableSelector(fontImage)
        return pre.concat(
          images.map((image) => {
            const { url, width, height, name } = immutableSelector(image)
            return {
              image: url,
              width,
              height,
              name
            }
          })
        )
      }, Immutable.List())
      .toJS()
  }

  handleUnitImageRemove = (unit) => {
    const { childKey, data = Immutable.Map(), onChange = noop, parentKey, prefixPath = [] } = this.props
    const path = ['text', 'image', 'unit_images']
    const language = unit === '℃' ? 'zh' : 'en'
    let images = data.getIn(path) || Immutable.List()
    const removeIndex = images.findIndex((image = Immutable.Map) => image.get('language') === language)

    images = images.splice(removeIndex, 1)

    const newData = data.setIn(path, images)

    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleChangeUnitImage = (path, language, img) => {
    if (!img) return
    const { data = Immutable.Map(), unitImageSource = [] } = this.props
    let imgData = { url: img.image, width: img.width, height: img.height }
    const unitImages = data.getIn(path) || Immutable.List()
    const newUnitImages = unitImages.filter((unitImage) => unitImageSource.find(({ language: lang }) => lang === unitImage.get('language')))
    const unitImageIndex = newUnitImages.findIndex((item) => item.get('language') === language)

    if (unitImageIndex > -1) {
      imgData = newUnitImages.set(
        unitImageIndex,
        Immutable.fromJS({
          language,
          image: imgData
        })
      )
    } else {
      imgData = newUnitImages.push(
        Immutable.fromJS({
          language,
          image: imgData
        })
      )
    }

    this.handleChange(path, undefined, false, imgData)
  }

  handleChangeImage = (path, prop, isAssetsValue, img) => {
    if (img) {
      this.handleChange(path, prop, isAssetsValue, Immutable.fromJS({ url: img.image, width: img.width, height: img.height }))
    } else {
      this.handleChange(path, prop, isAssetsValue, Immutable.fromJS({ url: '', width: 0, height: 0 }))
    }
  }

  handleChangeInvalidImage = (path, prop, isAssetsValue, img) => {
    if (img) {
      this.handleChange(path, prop, isAssetsValue, img.image)
    } else {
      this.handleChange(path, prop, isAssetsValue, '')
    }
  }

  handleChangeSepImage = (key, path, img) => {
    const { childKey, data, onChange = noop, parentKey, prefixPath } = this.props
    let newData = data
    if (img) {
      const { image = '', width = 0, height = 0 } = img
      newData = data.setIn([...path], Immutable.fromJS({ url: image, height, width }))
    } else {
      newData = data.setIn([...path], Immutable.fromJS({ url: '', height: 0, width: 0 }))
    }
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handlePunchChangeImage = (updateUploadedImages = true, image, lang = 'all') => {
    const {
      childKey,
      dialType,
      data,
      onChange = noop,
      parentKey,
      namePath,
      onAssetsChange,
      assets,
      allAssets,
      allData,
      constMap,
      editableComponentKey,
      prefixPath
    } = this.props
    const alreadyImageKey = assets.get('alreadyImageKey') || ''
    const font_images_path = ['text', 'image', 'font_images']
    const font_images = data.getIn(font_images_path) || Immutable.List()
    const imgArr = Immutable.fromJS(
      image.map((i) => {
        const { width, height, image, name } = i
        return {
          url: image,
          width,
          height,
          name
        }
      })
    )
    const originLangImagesIndex = font_images.findIndex((item) => item.get('language') === lang)
    let newData = data
    let newAllAssets = allAssets

    if (imgArr.size === 0) {
      newAllAssets = newAllAssets.setIn([dialType, parentKey, 'children', childKey, 'alreadyImageKey'], '')
    }

    if (updateUploadedImages) {
      const defaultImageKey = editableComponentKey
        ? `${dialType}@_@${editableComponentKey}-${parentKey}-${childKey}`
        : `${dialType}@_@${parentKey}-${childKey}`
      const keyPath = (alreadyImageKey || defaultImageKey).split('@_@')
      const basePath = ['uploadedImages', ...keyPath]
      let imageName = newAllAssets.getIn([...prefixPath, ...basePath, 'name']) || namePath

      if (imgArr.size === 0) imageName = namePath

      const newUploadData = Immutable.fromJS({
        name: imageName,
        images: imgArr
      })

      newAllAssets = newAllAssets.setIn(basePath, newUploadData)
    }

    if (originLangImagesIndex > -1) {
      font_images_path.push(originLangImagesIndex)
      newData = data.setIn(
        font_images_path,
        Immutable.fromJS({
          language: lang,
          images: imgArr
        })
      )
    } else {
      newData = data.setIn(
        font_images_path,
        Immutable.fromJS([
          ...font_images,
          {
            language: lang,
            images: imgArr
          }
        ])
      )
    }

    onChange([...prefixPath, parentKey, 'children', childKey], newData)

    const newAllData = allData.setIn([parentKey, 'children', childKey], newData)
    onAssetsChange([], newAllAssets)
  }

  handleAlreadyImageChange = (key) => {
    const { allAssets, data, onChange, childKey, dialType, parentKey, prefixPath, onAssetsChange } = this.props
    const { uploadedImages = Immutable.Map() } = immutableSelector(allAssets)
    const keyPath = (key || '').split('@_@')
    const images = key ? uploadedImages.getIn([...keyPath, 'images']) : Immutable.List()
    const newData = data.setIn(
      ['text', 'image', 'font_images'],
      Immutable.fromJS([
        {
          language: 'all',
          images
        }
      ])
    )
    let newAllAssets = allAssets

    newAllAssets = newAllAssets.setIn([dialType, ...prefixPath, parentKey, 'children', childKey, 'alreadyImageKey'], key)

    onChange([...prefixPath, parentKey, 'children', childKey], newData)
    onAssetsChange([], newAllAssets)
  }

  handleCropImageNameChange = (name) => {
    const { onAssetsChange, parentKey, childKey, assets, dialType, prefixPath } = this.props
    const defaultKey = `${dialType}@_@${parentKey}-${childKey}`
    const alreadyImageKey = assets.get('alreadyImageKey') || defaultKey
    const path = alreadyImageKey.split('@_@')

    onAssetsChange([...prefixPath, 'uploadedImages', ...path, 'name'], name)
  }

  renderNumberImage = () => {
    const { assets, series } = this.props
    const useImage = assets.get('useImage') || 'new'

    return (
      <>
        <div className="name">
          <T id="number_slice" />
        </div>
        <div className="content">
          <div className="item">
            <Radio.Group value={useImage} onChange={this.handleRadioChange}>
              {series === SERIES.JS ? null : (
                <Radio style={RADIO_STYLE} value="system">
                  <T id="use_system_font" />
                </Radio>
              )}
              <Radio style={RADIO_STYLE} value="already">
                <T id="use_uplod_img" />
              </Radio>
              <Radio style={RADIO_STYLE} value="new">
                <T id="use_new_img" />
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <>
          {useImage === 'system' ? this.renderSystemImage() : null}
          {useImage === 'already' ? this.renderAlreadyImage() : null}
          {useImage === 'new' ? this.renderNewImage() : null}
        </>
        {useImage === 'already' || useImage === 'new' ? this.renderPositions() : null}
      </>
    )
  }

  //  系统图片
  renderSystemImage = () => {
    const { data, systemFonts, assets, watchConfig } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { fontSize: fontSizes } = immutableSelector(systemFonts.get('all'))
    const system_font = data.getIn(['text', 'image', 'system_font']) || Immutable.Map()
    const { color, font_size, line, circle } = immutableSelector(system_font)
    const systemFontStyle = assets.get('systemFontStyle') || 'line'
    const { position: linePosition, angle: lineAngle } = immutableSelector(line)
    const { position: circlePosition, angle: circleAngle, radius, is_clockwise } = immutableSelector(circle)
    const { x: lineX, y: lineY } = immutableSelector(linePosition)
    const { x: circleX, y: circleY } = immutableSelector(circlePosition)
    const { systemFontImages } = this.state

    return (
      <>
        <div className="name">
          <T id="sys_font_img" />
        </div>
        <div className="content">
          <div className="item">
            <div>
              {systemFontImages.map((image) => {
                return (
                  <div className="system-image" key={image}>
                    <AntImage src={image} width={48} height={48} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="name">
          <T id="font_color" />
        </div>
        <div className="content">
          <div className="item">
            <ColorPicker
              width={200}
              color={color ? `#${color.slice(4)}` : ''}
              onInputChange={this.handleColorInputChange}
              onChangeComplete={this.handleColorChange}
            />
          </div>
        </div>
        <div className="name">
          <T id="font_size" />
        </div>
        <div className="content">
          <div className="item">
            <Select dropdownClassName="watch-skin-select-dropdown" style={SELECT_STYLE} value={font_size} onChange={this.handleFontsizeChange}>
              {fontSizes.toJS().map((item) => {
                return (
                  <Option key={item} value={item}>
                    {item} px
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
        <div className="name">
          <T id="display_method" />
        </div>
        <div className="content">
          <div className="item">
            <Radio.Group value={systemFontStyle} onChange={this.handleSystemFontStyleChange}>
              <Radio style={RADIO_STYLE} value="line">
                <T id="line" />
              </Radio>
              <Radio style={RADIO_STYLE} value="circle">
                <T id="arc" />
              </Radio>
            </Radio.Group>
          </div>
        </div>
        {systemFontStyle === 'line' ? (
          <>
            <div className="name">
              <T id="position" />
            </div>
            <div className="content">
              <div className="item">
                <div className="col">
                  <div className="key">x</div>
                  <InputNumber
                    min={0}
                    max={screenWidth}
                    value={lineX}
                    onChange={(arg) => this.handleChange(['text', 'image', 'system_font', 'line', 'position'], 'x', false, arg)}
                  />
                </div>
                <div className="col">
                  <div className="key">y</div>
                  <InputNumber
                    min={0}
                    max={screenHeight}
                    value={lineY}
                    onChange={(arg) => this.handleChange(['text', 'image', 'system_font', 'line', 'position'], 'y', false, arg)}
                  />
                </div>
              </div>
            </div>
            <div className="name">
              <T id="angle" />
            </div>
            <div className="content">
              <div className="item">
                <InputNumber
                  min={-360}
                  max={360}
                  value={lineAngle}
                  onChange={(arg) => this.handleChange(['text', 'image', 'system_font', 'line'], 'angle', false, arg)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="name">
              <T id="circle_center_pos" />
            </div>
            <div className="content">
              <div className="item">
                <div className="col">
                  <div className="key">x</div>
                  <InputNumber
                    min={0}
                    max={screenWidth}
                    value={circleX}
                    onChange={(arg) => this.handleChange(['text', 'image', 'system_font', 'circle', 'position'], 'x', false, arg)}
                  />
                </div>
                <div className="col">
                  <div className="key">y</div>
                  <InputNumber
                    min={0}
                    max={screenHeight}
                    value={circleY}
                    onChange={(arg) => this.handleChange(['text', 'image', 'system_font', 'circle', 'position'], 'y', false, arg)}
                  />
                </div>
              </div>
            </div>
            <div className="name">
              <T id="radius" />
            </div>
            <div className="content">
              <div className="item">
                <InputNumber
                  min={0}
                  max={360}
                  value={radius}
                  onChange={(arg) => this.handleChange(['text', 'image', 'system_font', 'circle'], 'radius', false, arg)}
                />
              </div>
            </div>
            <div className="name">
              <T id="angle" />
            </div>
            <div className="content">
              <div className="item">
                <InputNumber
                  min={0}
                  max={360}
                  value={circleAngle}
                  onChange={(arg) => this.handleChange(['text', 'image', 'system_font', 'circle'], 'angle', false, arg)}
                />
              </div>
            </div>
            <div className="name">
              <T id="direction" />
            </div>
            <div className="content">
              <div className="item">
                <Radio.Group value={is_clockwise} onChange={this.handleSystemFontDirectionChange}>
                  <Radio style={RADIO_STYLE} value={true}>
                    <T id="clockwise" />
                  </Radio>
                  <Radio style={RADIO_STYLE} value={false}>
                    <T id="counterclockwise" />
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </>
        )}
      </>
    )
  }

  handleSystemFontStyleChange = (e) => {
    const { parentKey, onChange = noop, data, childKey, prefixPath } = this.props
    const { value } = e.target
    const sys_font_path = ['text', 'image', 'system_font']
    let newData = data

    this.changeAssets('systemFontStyle', value)

    if (value === 'circle') {
      newData = newData
        .setIn(
          [...sys_font_path, 'circle'],
          Immutable.Map({
            position: {
              x: 0,
              y: 0
            },
            angle: 0,
            radius: 0,
            is_clockwise: true
          })
        )
        .deleteIn([...sys_font_path, 'line'])
    } else {
      newData = newData
        .setIn(
          [...sys_font_path, 'line'],
          Immutable.Map({
            position: {
              x: 0,
              y: 0
            },
            angle: 0
          })
        )
        .deleteIn([...sys_font_path, 'circle'])
    }
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleSystemFontDirectionChange = (e) => {
    const { value } = e.target
    this.handleChange(['text', 'image', 'system_font', 'circle'], 'is_clockwise', false, value)
  }
  // 新图片
  renderNewImage = () => {
    const { allAssets = Immutable.Map(), watchConfig, data, childKey, namePath, constMap, dialType, parentKey } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { uploadedImages = Immutable.Map() } = immutableSelector(allAssets)
    const supportImage = constMap.getIn([childKey, 'supportImage'])

    const font_images_path = ['text', 'image', 'font_images']
    const fontImages = data.getIn(font_images_path) || Immutable.List()

    const fontImageArr = this.getFontImageArr(fontImages)
    const cropImageName = (uploadedImages || Immutable.Map()).getIn([dialType, `${parentKey}-${childKey}`, 'name']) || namePath

    return (
      <>
        <div className="name is-required">
          <T id="upload_image" />
        </div>
        <div className="content">
          <div className="item">
            <Upload
              key="new"
              multiple
              fileCountLimit={supportImage ? Infinity : 10}
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              fileList={fontImageArr}
              field="all"
              onChange={(...args) => this.handlePunchChangeImage(true, ...args)}
              defineCropImageName
              sameImagesSize
              cropImageName={cropImageName}
              showRemoveIcon={true}
              handleCropImageNameChange={this.handleCropImageNameChange}
              removeAllButton={this.renderRemoveAllButton()}
            />
          </div>
        </div>
      </>
    )
  }

  renderAlreadyImage = () => {
    const { allAssets = Immutable.Map(), assets, dialType, parentKey, childKey, constMap, watchConfig, data, namePath } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { uploadedImages = Immutable.Map() } = immutableSelector(allAssets)
    const alreadyImageKey = assets.get('alreadyImageKey') || ''
    const supportImage = constMap.getIn([childKey, 'supportImage'])
    const path = alreadyImageKey.split('@_@')
    const alreadyImageName = (uploadedImages || Immutable.Map()).getIn([...path, 'name'])
    const className = alreadyImageKey ? 'item item-clear-padding-bottom' : 'item'
    const isNormal = dialType === 'normal'
    let hasAlreadyImageKey = false

    const font_images_path = ['text', 'image', 'font_images']
    const fontImages = data.getIn(font_images_path) || Immutable.List()
    const fontImageArr = this.getFontImageArr(fontImages)
    const showRemoveIcon = alreadyImageKey === `${dialType}@_@${parentKey}-${childKey}`
    const cropImageName = (uploadedImages || Immutable.Map()).getIn([...path, 'name']) || namePath

    const renderOptions = (dialType) => {
      const data = uploadedImages.get(dialType) || Immutable.Map()
      const options = data.filter((item) => (item.get('images') || Immutable.List()).size).keySeq()

      return options.map((key) => {
        const imagesKey = `${dialType}@_@${key}`
        if (imagesKey === alreadyImageKey) hasAlreadyImageKey = true
        const path = [dialType, key]
        const imagesName = uploadedImages.getIn([...path, 'name'])
        return (
          <Option key={imagesKey} value={imagesKey}>
            {imagesName}
          </Option>
        )
      })
    }

    const normalOptions = renderOptions('normal')
    const idleOptions = renderOptions('idle')

    const optGroup = []

    if (normalOptions.size) {
      optGroup.push(
        <OptGroup label={<T id="normal" />} key="normal">
          {normalOptions}
        </OptGroup>
      )
    }
    if (idleOptions.size) {
      optGroup.push(
        <OptGroup label={<T id="idle" />} key="idle">
          {idleOptions}
        </OptGroup>
      )
    }

    const order = isNormal ? [0, 1] : [1, 0]

    return (
      <>
        <div className="name">
          <T id="uploaded_image" />
        </div>
        <div className="content" style={{ marginBottom: '20px'}}>
          <div className={className}>
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={hasAlreadyImageKey ? alreadyImageKey : alreadyImageName}
              allowClear={!!alreadyImageKey}
              onChange={this.handleAlreadyImageChange}
            >
              {order.map((idx) => optGroup[idx])}
            </Select>
          </div>
        </div>
        {fontImageArr.length ? (
          <div className="content">
            <div className="item">
              <Upload
                key={alreadyImageKey}
                multiple
                fileCountLimit={supportImage ? Infinity : 10}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={fontImageArr}
                field="all"
                onChange={(...args) => this.handlePunchChangeImage(true, ...args)}
                defineCropImageName
                sameImagesSize
                cropImageName={cropImageName}
                showRemoveIcon={showRemoveIcon}
                handleCropImageNameChange={this.handleCropImageNameChange}
                removeAllButton={this.renderRemoveAllButton()}
              />
            </div>
          </div>
        ) : null}
      </>
    )
  }

  getShowValueMax = (valueCount, stepCount) => {
    if (valueCount) {
      return stepCount ? 10 ** (valueCount - stepCount - 1) - (0.1 ** stepCount).toFixed(stepCount) : 10 ** valueCount - 1
    }
    return Infinity
  }

  getIsCharacter = (combin = 'single') => {
    const { parentKey, childKey, data = Immutable.Map(), allData = Immutable.Map(), constMap = Immutable.Map() } = this.props
    const isCharacterPath = ['text', 'is_character']
    const is_character = data.getIn(isCharacterPath) || false

    if (combin === 'single') return is_character

    const before = constMap.getIn([childKey, 'before']) || Immutable.List()

    if (!before.size) return is_character

    let i = 0
    for (; i < before.size; i += 1) {
      const beforeKey = before.get(i)
      const beforeCombin = allData.getIn([parentKey, 'children', beforeKey, 'combin'])

      if (beforeCombin && beforeCombin !== 'follow') break
    }

    if (i < before.size) {
      const beforeKey = before.get(i)

      return allData.getIn([parentKey, 'children', beforeKey, ...isCharacterPath])
    }

    return is_character
  }

  getFollowingData = (combin = 'single', path = [], isAssets, defaultValue) => {
    const {
      parentKey,
      childKey,
      assets = Immutable.Map(),
      data = Immutable.Map(),
      allData = Immutable.Map(),
      allAssets = Immutable.Map(),
      constMap = Immutable.Map()
    } = this.props

    const dataSource = isAssets ? assets : data
    const allDataSource = isAssets ? allAssets : allData
    const pathData = dataSource.getIn(path, defaultValue)

    if (combin === 'single') return pathData

    const before = constMap.getIn([childKey, 'before']) || Immutable.List()

    if (!before.size) return pathData

    let i = 0
    for (; i < before.size; i += 1) {
      const beforeKey = before.get(i)
      const beforeCombin = allData.getIn([parentKey, 'children', beforeKey, 'combin'])

      if (beforeCombin && beforeCombin !== 'follow') break
    }

    if (i < before.size) {
      const beforeKey = before.get(i)

      return allDataSource.getIn([parentKey, 'children', beforeKey, ...path], defaultValue)
    }

    return pathData
  }

  handleLangChange = (key, value) => {
    const { parentKey, childKey, constMap, dialType, allAssets, data, allData, onChange } = this.props
    let previewLang = ''
    let newData = data

    if (key === 'languages') {
      const font_images_path = ['text', 'image', 'font_images']
      newData = data.setIn(font_images_path, Immutable.List())

      onChange([parentKey, 'children', childKey], newData)
      this.changeAssets('previewLang', '')
    }

    if (key === 'previewLang') {
      previewLang = value
    }

    // const newAllAssets = allAssets.setIn([dialType, parentKey, 'children', childKey, 'previewLang'], previewLang)
    // const newAllData = allData.setIn([parentKey, 'children', childKey], newData)
    this.changeAssets(key, value)
  }

  handleColorInputChange = (value) => {
    this.handleColorChange({ hex: value })
  }

  handleColorChange = (color) => {
    const value = color.hex
    const { parentKey, onChange = noop, data, childKey, prefixPath } = this.props
    // todo: 临时修改转为 ARGB 格式, 后续优化
    const newData = data.setIn(['text', 'image', 'system_font', 'color'], value ? `0xFF${value.slice(1)}` : '')

    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleFontsizeChange = (value) => {
    const { parentKey, onChange = noop, data, childKey, prefixPath } = this.props
    const newData = data.setIn(['text', 'image', 'system_font', 'font_size'], value)

    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  previewUnit = (unit) => {
    const { assets, onAssetsChange, parentKey, dialType, prefixPath, childKey } = this.props
    const path = [parentKey, 'children', childKey]
    const newAssets = assets.set('unit', unit)

    onAssetsChange([dialType, ...prefixPath, ...path], newAssets)
  }

  renderUploadByLang = (languages) => {
    const { watchConfig, data } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const fontImages = data.getIn(['text', 'image', 'font_images']) || Immutable.List()

    return languages.split(',').map((lang) => {
      const { label, field } = LANG_MAPPER[lang] || {}
      const imagesObj = fontImages.find((images) => images.get('language') === field) || Immutable.Map()
      const images = imagesObj
        .get('images', Immutable.List())
        .toJS()
        .map(({ url, height, width, name }) => ({ image: url, height, width, name }))
      const key = `${languages}-${lang}`

      return (
        <div key={key}>
          <div className="name">{label}</div>
          <div className="content">
            <div className="item">
              <Upload
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={images}
                field={field}
                multiple
                sameImagesSize
                onChange={(...args) => this.handlePunchChangeImage(false, ...args)}
                removeAllButton={this.renderRemoveAllButton(field)}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  renderMultipleLangPic() {
    const { metas, data } = this.props
    const originLang = getOriginLang(metas)

    return (
      <div>
        <BackupOfLangImage fontImages={data.getIn(['text', 'image', 'font_images'])} metas={metas} />
        {/* {originLang === languages ? null : (
          <div>
            <div className="name">
              <T id="image" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  style={SELECT_STYLE}
                  value={languages}
                  disabled
                  onChange={(arg) => this.handleLangChange('languages', arg)}
                >
                  <Option value="all">
                    <T id="lang_all" />
                  </Option>
                  <Option value="zh,zh-Hant,en">
                    <T id="lang_zh" />、<T id="lang_zh-hant" />、<T id="lang_en" />
                  </Option>
                  <Option value="sc,en">
                    <T id="lang_zh&zh-hant" />、<T id="lang_en" />
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )} */}
        {this.renderUploadByLang(originLang)}
        {this.renderPositions()}
      </div>
    )
  }

  renderPositions = () => {
    const { watchConfig, data } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const position = data.getIn(['text', 'image', 'position']) || Immutable.Map()
    const { x = 0, y = 0 } = immutableSelector(position)

    return (
      <>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={x}
                onChange={(arg) => this.handleChange(['text', 'image', 'position'], 'x', false, arg)}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={y}
                onChange={(arg) => this.handleChange(['text', 'image', 'position'], 'y', false, arg)}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  renderRemoveAllButton = (language = 'all') => (
    <Button
      className="upload-btn"
      onClick={() => this.handlePunchChangeImage(false, [], language)}
    >
      Remove All
    </Button>
  )

  /* eslint-enable */
  /* eslint-disable no-nested-ternary */
  renderUnitImage() {
    const { childKey, watchConfig, unitConfig, data, assets, unitImageSource, unitImagePreviewSource } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const unit = assets.get('unit')

    if (!unitConfig) {
      const unitImages = data.getIn(['text', 'image', 'unit_images']) || Immutable.List()
      const { image } = immutableSelector(unitImages.get(0) || Immutable.Map())
      const { url: unitUrl } = immutableSelector(image || Immutable.Map())
      return (
        <>
        <div className="name">
          <T id="unit_image" />
        </div>
        <div className="content">
          <div className="item">
            {unitImageSource?.length > 0 ? (
              unitImageSource.map((img) => {
                const { unit, language } = img
                const unitImage = unitImages ? unitImages.find((image) => image && image.get('language') === language) : Immutable.List()
                const { image = Immutable.Map() } = immutableSelector(unitImage)
                const { url: unitUrl } = immutableSelector(image)

                return (
                  <div className="col col-v" key={`${childKey}-${language}`}>
                    <Upload
                      key={`${childKey}-${language}`}
                      className="item"
                      field={language}
                      maxWidth={screenWidth}
                      maxHeight={screenHeight}
                      fileCountLimit={1}
                      fileList={unitUrl ? [{ image: unitUrl }] : []}
                      help={<div style={{ textAlign: 'center' }}>{unit}</div>}
                      onRemove={() => this.handleUnitImageRemove(unit)}
                      onChange={(image) => this.handleChangeUnitImage(['text', 'image', 'unit_images'], language, image)}
                    />
                  </div>
                )
              })
            ) : (
              <div className="col col-v">
                <Upload
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={unitUrl ? [{ image: unitUrl }] : []}
                  help={
                    <div style={{ textAlign: 'center' }}>
                      <T id="upload_image" />
                    </div>
                  }
                  onRemove={() => this.handleUnitImageRemove(unit)}
                  onChange={(image) => this.handleChangeUnitImage(['text', 'image', 'unit_images'], 'all', image)}
                />
              </div>
            )}
          </div>
        </div>
        {unitImagePreviewSource ? (
          <>
            <div className="name">
              <T id="unit_preview" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  value={unit || unitImagePreviewSource[0].language}
                  onChange={this.previewUnit}
                  style={SELECT_STYLE}
                >
                  {unitImagePreviewSource.map((item) => {
                    const { language, unit } = item
                    return (
                      <Option value={language} key={unit}>
                        {unit}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>
          </>) : null}
        </>
      )
    }

    const { labels, languages, fields } = unitConfig
    const previewUnit = assets.get('previewUnit', '')

    return (
      <>
        {labels.map((label, index) => (
          <div key={label}>
            <div className="name">
              <T id={label} />
            </div>
            <div className="content">
              <div className="item">
                {languages[index].map(({ label, lang }) => {
                  const unitImages = data.getIn(['text', 'image', fields[index]]) || Immutable.List()
                  const { image: unitImage } = immutableSelector(
                    unitImages.find((unitImage) => unitImage.get('language') === lang) || Immutable.Map()
                  )
                  const { url: unitUrl } = immutableSelector(unitImage || Immutable.Map())

                  return (
                    <div className="col col-v" key={lang}>
                      <Upload
                        fileCountLimit={1}
                        maxWidth={screenWidth}
                        maxHeight={screenHeight}
                        field={lang}
                        fileList={unitUrl ? [{ image: unitUrl }] : []}
                        help={<div style={{ textAlign: 'center' }}>{label}</div>}
                        onChange={(image) => {
                          this.handleChangeUnitImage(['text', 'image', fields[index]], 'all', image)
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ))}
        <div className="name">
          <T id="unit_preview" />
        </div>
        <div className="content">
          <Select
            dropdownClassName="watch-skin-select-dropdown"
            className="item"
            style={SELECT_STYLE}
            value={previewUnit}
            onChange={(value) => {
              this.changeAssets('previewUnit', value)
            }}
          >
            {labels.map((label, index) =>
              languages[index].map(({ label: langLabel, lang }) => (
                <Option key={`${fields[index]}@_@${lang}`} value={`${fields[index]}@_@${lang}`}>
                  <T id={label} />
                  {langLabel ? (
                    <>
                      (<T id={langLabel} />)
                    </>
                  ) : (
                    ''
                  )}
                </Option>
              ))
            )}
          </Select>
        </div>
      </>
    )
  }

  render() {
    const {
      allData = Immutable.Map(),
      parentKey,
      childKey,
      data,
      metas,
      series,
      constMap = Immutable.Map(),
      hiddenCombin,
      assets = Immutable.Map(),
      hasPointImage,
      pointImageText = <T id="point_image" />,
      delimiter = '.', // 分隔符符号，如 '/',':','.'等
      watchConfig,
      previewUseName = false,
      invalidImageName = <T id="invalid_data" />,
      hideUnitImage = false,
      hideSeparatorImage = false,
      separatorText,
      renderLayerByLang,
      hideNegativeImage = true,
      isPreviewTime = false,
      hidePaddingZero
    } = this.props
    const { getI18nMessage } = this.context
    const originLang = getOriginLang(metas)
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const name = constMap.getIn([childKey, 'name']) || ''
    const previewText = constMap.getIn([childKey, 'previewText']) || ''
    const previewMaxValue = constMap.getIn([childKey, 'previewMaxValue'])
    const previewMinValue = constMap.getIn([childKey, 'previewMinValue'])
    const stepCount = constMap.getIn([childKey, 'stepCount'])
    const { combin = 'single', text = Immutable.Map(), separator = Immutable.Map() } = immutableSelector(data)
    const is_character = this.getFollowingData(combin, ['text', 'is_character'], false, false)
    const supportImage = constMap.getIn([childKey, 'supportImage'])
    const isImage = is_character && supportImage
    const isYear = childKey === 'year'
    const isZeppOS = series === SERIES.JS

    let valueCount = is_character && supportImage ? 1 : constMap.getIn([childKey, 'valueCount'])

    const hasInvalidImage = constMap.getIn([childKey, 'hasInvalidImage'], true)
    const invalidImageRequired = constMap.getIn([childKey, 'invalidImageRequired'], false)
    const value = assets.getIn(['num', 'value']) || (isImage ? 1 : 0)
    const hour = assets.getIn(['num', 'hour']) || 0
    const minute = assets.getIn(['num', 'minute']) || 0
    const disableFollow = assets.get('disableFollow') || false
    const { align = 'left', image = Immutable.Map(), interval = 0, padding_zero } = immutableSelector(text)

    if (isYear) {
      // 是否简写 2023 -> 23, gt2 与 zepp os 正好相反
      if ((!padding_zero && series === SERIES.JS) || (padding_zero && series === SERIES.GT2)) {
        valueCount = 2
      } else {
        valueCount = 4
      }
    }

    const showValueMax = this.getShowValueMax(valueCount, stepCount)
    const { image: sepImage, position: sepPosition } = immutableSelector(separator)
    const { url: sepUrl } = immutableSelector(sepImage)
    const { x: sepX, y: sepY } = immutableSelector(sepPosition)
    const {
      point_image: pointImage = Immutable.Map(),
      negative_image = Immutable.Map()
    } = immutableSelector(image)
    const fontImages = image.get('font_images') || Immutable.List()
    const { url: pointUrl } = immutableSelector(pointImage)
    const firstKey = constMap.keySeq().get(0)
    const invalidImagePath = ['text', 'image', 'invalid_image']
    const invalidImage = data.getIn(invalidImagePath, '')
    const defaultLang = getOriginLang(metas).split(',')[0]
    const previewLang = renderLayerByLang || assets.get('previewLang') || defaultLang
    NumberComponent.previewLang = previewLang
    const previewSuffix = is_character && supportImage ? constMap.getIn([childKey, 'previewSuffix']) || '' : ''
    const negativeImage = (negative_image || Immutable.Map()).get('url')
    const currentLangImages = fontImages.find((images) => images.get('language') === previewLang) || Immutable.Map()
    const currentLangImagesNames = previewLang ? (currentLangImages.get('images') || Immutable.List()).map((item) => item.get('name')).toJS() : []
    const beforeKey = constMap.getIn([childKey, 'before', 0])
    const beforeIsCharacter = beforeKey ? allData.getIn([parentKey, 'children', beforeKey, 'text', 'is_character']) : false
    // const unitImageMap = [{
    //     language: 'zh',
    //     unit: '℃'
    //   }, {
    //     language: 'en',
    //     unit: '℉'
    //   }]
    return (
      <>
        {hiddenCombin ? null : (
          <>
            <div className="name">
              <T id="primary_secondary_relationship" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  value={combin}
                  style={SELECT_STYLE}
                  onChange={(arg) => this.handleChange([], 'combin', false, arg)}
                >
                  <Option value="single">
                    <T id="independent" />
                  </Option>
                  <Option value="follow" disabled={disableFollow || firstKey === childKey || beforeIsCharacter}>
                    <T id="follow" />
                  </Option>
                </Select>
              </div>
            </div>
          </>
        )}
        {invalidImageRequired || hasInvalidImage ? (
          <>
            <div className={invalidImageRequired ? 'name is-required' : 'name'}>{invalidImageName}</div>
            <div className="content">
              <div className="item">
                <div className="col col-v">
                  <Upload
                    className="item"
                    fileCountLimit={1}
                    maxWidth={screenWidth}
                    maxHeight={screenHeight}
                    fileList={invalidImage ? [{ image: invalidImage }] : []}
                    onChange={(arg) => this.handleChangeInvalidImage(['text', 'image'], 'invalid_image', false, arg)}
                  />
                  <T id="upload_image" />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {combin === 'single' && supportImage ? (
          <>
            <div className="name">
              <T id="display_method" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  value={is_character ? 1 : 0}
                  style={SELECT_STYLE}
                  onChange={(arg) => this.handleChange(['text'], 'is_character', false, arg)}
                >
                  <Option value={0} disabled={parentKey === 'weather'}>
                    <T id="number" />
                  </Option>
                  <Option value={1}>
                    <T id="image" />
                  </Option>
                </Select>
              </div>
            </div>
          </>
        ) : null}
        {combin === undefined || combin === 'single' ? (
          <>
            {!is_character ? this.renderNumberImage() : null}
            {isImage && this.renderMultipleLangPic()}
            {isImage ? null : (
              <>
                <div className="name">
                  <T id="alignment" />
                </div>
                <div className="content">
                  <div className="item">
                    <Select
                      dropdownClassName="watch-skin-select-dropdown"
                      value={align}
                      style={SELECT_STYLE}
                      onChange={(arg) => {
                        if (series === SERIES.GT2 && arg !== 'left') {
                          Modal.confirm({
                            icon: null,
                            className: 'watch-dial-tip-modal',
                            title: getI18nMessage('tips'),
                            content: getI18nMessage('use_alignment_tip'),
                            cancelText: getI18nMessage('cancel'),
                            okText: getI18nMessage('confirm_1'),
                            onOk: () => {
                              this.handleChange(['text'], 'align', false, arg)
                            },
                            centered: true
                          })
                        } else {
                          this.handleChange(['text'], 'align', false, arg)
                        }
                      }}
                    >
                      <Option value="left">
                        <T id="align_left" />
                      </Option>
                      <Option value="center">
                        <T id="align_center" />{series === SERIES.GT2 ? <span className="deprecate-label">Deprecated</span> : null}
                      </Option>
                      <Option value="right">
                        <T id="align_right" />{series === SERIES.GT2 ? <span className="deprecate-label">Deprecated</span> : null}
                      </Option>
                    </Select>
                  </div>
                </div>
              </>
            )}
            {isImage ? null : (
              <>
                <div className="name">
                  <T id="space" />
                </div>
                <div className="content">
                  <div className="item">
                    <InputNumber min={isZeppOS ? (-screenWidth) : 0} max={screenWidth} value={interval} onChange={(arg) => this.handleChange(['text'], 'interval', false, arg)} />
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}
        {}
        {isImage || hidePaddingZero ? null : (
          <>
            <div className="name">
              <T id="format" />
            </div>
            <div className="content">
              <div
                className="item"
                style={{
                  width: 'fit-content',
                  padding: 0,
                  marginTop: 14,
                  marginBottom: 20
                }}
                onClick={(e) => {
                  if (isYear) {
                    let newValue = value

                    if (String(value).length > 2) {
                      if ((series === SERIES.JS && padding_zero) || (series === SERIES.GT2 && !padding_zero)) {
                        newValue = String(value).slice(2)
                      }
                    }

                    if (String(value).length === 2) {
                      if ((series === SERIES.JS && !padding_zero) || (series === SERIES.GT2 && padding_zero)) {
                        newValue = String(value).padStart(4, '0')
                      }
                    }

                    this.handleChange(['num'], 'value', true, Number(newValue))

                    this.timerIds.push(setTimeout(() => this.handleCheckChange(['text'], 'padding_zero', false, padding_zero, e)))
                  } else {
                    this.handleCheckChange(['text'], 'padding_zero', false, padding_zero, e)
                  }
                }}
              >
                <Radio checked={isYear && series === SERIES.JS ? !padding_zero : padding_zero} style={RADIO_STYLE}>
                  {isYear ? <T id="abbreviate_or_not" /> : <T id="whether_padding_zero" />}
                </Radio>
              </div>
            </div>
          </>
        )}
        {hideNegativeImage ? null : (
          <>
            <div className="name is-required">
              <T id="negative_image" />
            </div>
            <div className="content">
              <div className="item">
                <Upload
                  key={negativeImage}
                  className="item"
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={negativeImage ? [{ image: negativeImage }] : []}
                  help={
                    <div style={{ textAlign: 'center' }}>
                      <T id="negative_image" />
                    </div>
                  }
                  onChange={(image) => this.handleChangeImage(['text', 'image'], 'negative_image', false, image)}
                />
              </div>
            </div>
          </>
        )}
        {hideUnitImage ? null : this.renderUnitImage()}
        {hasPointImage ? (
          <>
            <div className="name is-required">
              <T id={pointImageText} />
            </div>
            <div className="content">
              <div className="item">
                <div className="col col-v">
                  <Upload
                    fileCountLimit={1}
                    maxWidth={screenWidth}
                    maxHeight={screenHeight}
                    fileList={pointUrl ? [{ image: pointUrl }] : []}
                    help={
                      <div style={{ textAlign: 'center' }}>
                        <T id="upload_image" />
                      </div>
                    }
                    onChange={(arg) => this.handleChangeImage(['text', 'image'], 'point_image', false, arg)}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {hideSeparatorImage ? null : (
          <>
            <div className="name">
              <T id="data_type_image" />
            </div>
            <div className="content">
              <div className="item">
                <Upload
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={sepUrl ? [{ image: sepUrl }] : []}
                  help={<div style={{ textAlign: 'center' }}>{separatorText || <T id="upload_image" />}</div>}
                  onChange={(arg) => this.handleChangeSepImage('separator', ['separator', 'image'], arg)}
                />
              </div>
            </div>
            <div className="content">
              <div className="item">
                <div className="col">
                  <div className="key">x</div>
                  <InputNumber min={0} max={screenWidth} value={sepX} onChange={(arg) => this.handleSepChange(['separator', 'position'], 'x', arg)} />
                </div>
                <div className="col">
                  <div className="key">y</div>
                  <InputNumber
                    min={0}
                    max={screenHeight}
                    value={sepY}
                    onChange={(arg) => this.handleSepChange(['separator', 'position'], 'y', arg)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {isImage ? (
          <div className="content">
            <div className="item">
              <T id="preview_lang" />
            </div>
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={previewLang}
              onChange={(arg) => this.handleLangChange('previewLang', arg)}
            >
              {originLang.split(',').map((lang) => {
                const { label, field } = LANG_MAPPER[lang] || {}

                return (
                  <Option key={field} value={field}>
                    {label}
                  </Option>
                )
              })}
            </Select>
          </div>
        ) : null}
        <div className="content">
          <div className="item">{previewText || <T id="preview_with_name" values={{ name: getI18nMessage(name) }} />}</div>
          {previewUseName ? (
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              defaultValue={currentLangImagesNames[0]}
              onChange={(arg) => this.handleChange(['num'], 'value', true, arg)}
            >
              {currentLangImagesNames.map((name, index) => (
                <Option key={name} value={index + 1}>
                  {name}
                </Option>
              ))}
            </Select>
          ) : isPreviewTime ? (
            <div className="item">
              <div className="col">
                <InputNumber
                  min={0}
                  max={23}
                  value={hour}
                  onChange={(value) => {
                    this.handleChange(['num'], 'hour', true, value)
                  }}
                />
                <span>
                  &nbsp;
                  <T id="hr" />
                </span>
              </div>
              <div className="col">
                <InputNumber
                  min={0}
                  max={59}
                  value={minute}
                  onChange={(value) => {
                    this.handleChange(['num'], 'minute', true, value)
                  }}
                />
                <span>
                  &nbsp;
                  <T id="min" />
                </span>
              </div>
            </div>
          ) : (
            <InputNumber
              min={previewMinValue !== undefined ? previewMinValue : is_character && supportImage ? 1 : 0}
              max={previewMaxValue || showValueMax}
              value={value}
              step={stepCount ? (0.1 ** stepCount).toFixed(stepCount) : 1}
              precision={stepCount || 0}
              formatter={(value = '') => `${(hasPointImage && stepCount) ? value.replace('.', delimiter) : value}${getI18nMessage(previewSuffix)}`}
              parser={(value = '') => (hasPointImage && stepCount) ?  value.replace(previewSuffix, '').replace(delimiter, '.') : value.replace(previewSuffix, '')}
              onChange={(arg) => this.handleChange(['num'], 'value', true, arg)}
            />
          )}
        </div>
      </>
    )
  }
}

NumberComponent.defaultProps = {
  prefixPath: [],
  onChange: noop
}

NumberComponent.contextType = DesignContext

export default NumberComponent
