import { Col, Divider, InputNumber, Row } from 'antd'
import * as React from 'react'

interface Props {
  properties: {
    rotation: { value: number }
    pivot: { value: { x: number; y: number } }
  }
  onChange: (category: string, property: string, value: string | number | undefined | null) => void
}

const Rotate: React.FC<Props> = (props) => {
  const {
    properties: {
      rotation: { value: rotation },
      pivot: {
        value: { x, y }
      }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-rotate">
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={2}>X</Col>
        <Col span={10}>
          <InputNumber
            min={0}
            max={100}
            defaultValue={x}
            onChange={(value) => {
              onChange('rotate', 'x', value)
            }}
          />
        </Col>
        <Col span={2}>Y</Col>
        <Col span={10}>
          <InputNumber
            min={0}
            max={100}
            defaultValue={y}
            onChange={(value) => {
              onChange('rotate', 'y', value)
            }}
          />
        </Col>
      </Row>
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={2}>A</Col>
        <Col span={22}>
          <InputNumber
            min={0}
            max={100}
            defaultValue={rotation}
            onChange={(value) => {
              onChange('rotate', 'rotation', value)
            }}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Rotate
