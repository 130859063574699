import { Col, Divider, Input, Row } from 'antd'
import * as React from 'react'

interface Props {
  properties: {
    name: { value: string }
  }
  onChange: (category: string, property: string, value: any) => void
}

const Name: React.FC<Props> = (props) => {
  const {
    properties: {
      name: { value: name }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-name">
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={24}>
          <Input
            style={{ width: 240 }}
            maxLength={20}
            defaultValue={name}
            onChange={(e) => {
              onChange('name', 'name', e?.target?.value)
            }}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Name
