import gtFont0 from '@watchface/assets/images/systemFonts/gt/font_0.png'
import gtFont1 from '@watchface/assets/images/systemFonts/gt/font_1.png'
import gtFont2 from '@watchface/assets/images/systemFonts/gt/font_2.png'
import gtFont3 from '@watchface/assets/images/systemFonts/gt/font_3.png'
import gtFont4 from '@watchface/assets/images/systemFonts/gt/font_4.png'
import gtFont5 from '@watchface/assets/images/systemFonts/gt/font_5.png'
import gtFont6 from '@watchface/assets/images/systemFonts/gt/font_6.png'
import gtFont7 from '@watchface/assets/images/systemFonts/gt/font_7.png'
import gtFont8 from '@watchface/assets/images/systemFonts/gt/font_8.png'
import gtFont9 from '@watchface/assets/images/systemFonts/gt/font_9.png'

export const gtFont = {
  all: {
    fontSize: [16, 18, 20, 21, 22, 24, 26, 28, 30, 32, 34, 36, 40, 48, 54],
    images: [gtFont0, gtFont1, gtFont2, gtFont3, gtFont4, gtFont5, gtFont6, gtFont7, gtFont8, gtFont9]
  }
}
