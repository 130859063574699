import { SET_APPROVE_LIST, RESET_APPROVE_INFO, SET_APPROVE_INFO } from '../actionTypes'

const initialState = {
  approveListData: {
    page: 0,
    per_page: 0,
    total: 0,
    total_page: 0,
    data: []
  },
  approveInfoData: {
    id: '',
    name: '',
    meta: {
      languages: ['zh'],
      devices: [],
      support_id: [],
      description: [],
      app_name: [],
      app_platform: []
    }
  },
  LanguageType: {
    zh: {
      label: 'lang_zh',
      value: 'zh'
    },
    'zh-hant': {
      label: 'lang_zh-hant',
      value: 'zh-hant'
    },
    en: {
      label: 'lang_en',
      value: 'en'
    }
  }
}

const reducer = (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case SET_APPROVE_LIST:
      return { ...state, approveListData: data }
    case SET_APPROVE_INFO:
      return { ...state, approveInfoData: data }
    case RESET_APPROVE_INFO:
      return {
        ...state,
        approveInfoData: {
          id: '',
          name: '',
          meta: {
            languages: ['zh'],
            devices: [],
            support_id: [],
            description: [],
            app_name: [],
            app_platform: []
          }
        }
      }
    default:
      return state
  }
}

export default reducer
