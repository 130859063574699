/* eslint-disable import/order */
import { immutableSelector } from '@/utils/index'
import T from '@watchface/components/I18n'
import { Button, Modal } from 'antd'
import Immutable from 'immutable'
import React from 'react'
import './index.scss'
import Layer from './Layer'
import noDataImg from './tree-no-data.png'

const Tree = function (props) {
  const [modal, contextHolder] = Modal.useModal()

  const handleClick = (key, parentKey) => {
    const { onClick } = props

    parentKey ? onClick(Immutable.List([parentKey, key])) : onClick(Immutable.List([key]))
  }

  const handleRemove = (key, isCurrent, i) => {
    const { onRemove } = props

    onRemove(key, isCurrent, i)
  }

  const handleCopy = (key, name) => {
    const { onCopyLayer } = props

    onCopyLayer && onCopyLayer(key, name)
  }

  const handleHideClick = (key, hide, option) => {
    const { dataSource, onChange, onHide } = props
    const value = !hide
    const newDataSource = dataSource.setIn(option, value)
    onChange(newDataSource)
    onHide(key, value)
  }

  const handleCheckClick = (parentkey, key, hide, fixedChecked, checkPath) => {
    const { dataSource, onChange, onCheck, validateBeforeToggleCheck } = props
    const { value: fixedValue, reason } = immutableSelector(fixedChecked)
    const value = fixedChecked ? fixedValue : !hide
    const newDataSource = dataSource.setIn(checkPath, value)
    const keyPrefix = `${parentkey}-${key}`

    if (fixedChecked && reason) {
      modal.info({
        title: <T id="tips" />,
        icon: null,
        className: 'watch-dial-tip-modal confirm-btn-centered',
        centered: true,
        content: <T id={reason} />,
        okText: <T id="ok" />
      })
    }

    if (!validateBeforeToggleCheck(keyPrefix, value)) return

    onChange(newDataSource, keyPrefix)
    onCheck()
  }

  const handleExpandClick = (hide, option) => {
    const { dataSource, onChange } = props
    const newDataSource = dataSource.setIn(option, !hide)
    onChange(newDataSource)
  }

  const moveLayer = (sourcePath, targetPath) => {
    const { dataSource, onChange } = props
    const source = dataSource.getIn(sourcePath)
    const target = dataSource.getIn(targetPath)

    const newSource = source.set('order', target.get('order'))
    const newTarget = target.set('order', source.get('order'))

    const newDataSource = dataSource.setIn(sourcePath, newTarget).setIn(targetPath, newSource)

    onChange(newDataSource, true)
  }

  const renderLayer = (item, i) => {
    const { current } = props
    return (
      <Layer
        key={item.get('key')}
        current={current}
        layerIndex={i}
        layer={item}
        moveLayer={moveLayer}
        handleClick={handleClick}
        handleCheckClick={handleCheckClick}
        handleExpandClick={handleExpandClick}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        handleHideClick={handleHideClick}
      />
    )
  }

  const renderNoData = () => {
    return (
      <div className="tree-no-data">
        <div className="no-data-desc">
          <img src={noDataImg} alt="no data" />
          <p>
            <T id="no_layer" />
          </p>
        </div>
      </div>
    )
  }

  const { dataSource, onCopy, showCopyBtn } = props

  return (
    <div className="tree-container">
      {contextHolder}
      {dataSource.size
        ? dataSource.map((item, i) => {
            return renderLayer(item, i)
          })
        : renderNoData()}
      {showCopyBtn ? (
        <Button className="copy-layer" type="primary" onClick={onCopy}>
          <T id="copy_normal" />
        </Button>
      ) : null}
    </div>
  )
}

export default Tree
