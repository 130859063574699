import defaultPreviewImg from '@watchface/assets/images/default_watchface.png'
import T from '@watchface/components/I18n'
import Icon from '@watchface/components/Icon'
import { getDeviceType } from '@watchface/utils'
import { Form, Input, Modal, Select, Tooltip } from 'antd'
import React from 'react'
import { withRouter } from 'react-router'
import { MENU_ICON_COLOR, SERIES, TOOLTIP_COLOR } from '../../constants/index'

interface DialItemProps {
  data: any
  history: any
  supportDevices: any
  deleteDial: (dialId: number) => void
  copyDial: (dialId: number, dialName: string, copyDeviceId: number, isZeppOS: boolean) => void
}

interface DialItemState {
  showDeleteConfirmModal: boolean
  showCopyModal: boolean
  copyDeviceTypes: string[]
  copyDeviceId: number
  copyDeviceIsZeppOS: boolean
}

class DialItem extends React.Component<DialItemProps, DialItemState> {
  private copyForm: any

  constructor(props: DialItemProps) {
    super(props)

    this.state = {
      showDeleteConfirmModal: false,
      showCopyModal: false,
      copyDeviceTypes: [],
      copyDeviceId: -1,
      copyDeviceIsZeppOS: false
    }
  }

  handleCopy = () => {
    this.setState({
      showCopyModal: true
    })
    this.getCanCopyDeviceType()
  }

  handleCancelCopy = () => {
    this.setState({
      showCopyModal: false
    })
  }

  handleOkCopy = () => {
    if (!this.copyForm) return
    const { data, copyDial } = this.props
    const { support } = data || {}
    const { copyDeviceId, copyDeviceIsZeppOS } = this.state
    const isZeppOS = copyDeviceId === -1 ? support?.series === SERIES.JS : copyDeviceIsZeppOS
    const { name } = this.copyForm.getFieldsValue()
    this.setState({
      showCopyModal: false
    })
    copyDial(data.id, name, copyDeviceId === -1 ? data?.support?.id : copyDeviceId, isZeppOS)
  }

  handleDelete = () => {
    this.setState({
      showDeleteConfirmModal: true
    })
  }

  handleCopyTypeChange = (type: string) => {
    const { supportDevices, data } = this.props
    const { screen_resolution } = data?.support || {}
    const device = supportDevices.find((device: any) => device.get('screen_resolution') === screen_resolution && device.get('series') === type)

    this.setState({
      copyDeviceId: device.get('id') || -1,
      copyDeviceIsZeppOS: device.get('series') === SERIES.JS
    })
  }

  go2Detail = (series: string) => {
    const { history, data } = this.props
    const isZeppOs = series === 'js'
    const useNewZeppOs = localStorage.getItem('useNewZeppOs')

    history.push(isZeppOs && useNewZeppOs ? `/designer-zeppos/${data.id}` : `/designer/${data.id}`)
  }

  getCanCopyDeviceType = () => {
    const { supportDevices, data } = this.props
    const devices = supportDevices.toJS()
    const { screen_resolution, series } = data?.support || {}
    const sameResolutionDevices = devices.filter((device: any) => {
      const isComoNess = (series: string) => series === SERIES.COMO || series === SERIES.NESS

      if (isComoNess(series) && !isComoNess(device?.series)) return false
      if (series === 'js' && isComoNess(device?.series)) return false

      return device.screen_resolution === screen_resolution
    })
    const deviceTypes: string[] = Array.from(new Set(sameResolutionDevices.map((item: any) => item?.series)))
    const device = devices?.find((device: any) => device?.series === deviceTypes?.[0] && device?.screen_resolution === screen_resolution) || {}
    this.setState({
      copyDeviceTypes: deviceTypes,
      copyDeviceId: device.id || -1,
      copyDeviceIsZeppOS: device.series === SERIES.JS
    })
  }

  render() {
    const { data, deleteDial } = this.props
    const { copyDeviceTypes, showDeleteConfirmModal, showCopyModal } = this.state
    const { name, support, preview, appid } = data || {}

    return (
      <>
        <div className="dial-item" onClick={() => this.go2Detail(support?.series)}>
          <img className="preview" src={preview || defaultPreviewImg} alt={(<T id="dial_preview" />) as any} />
          <Tooltip title={name} color={TOOLTIP_COLOR} mouseEnterDelay={1}>
            <div className="dial-item-title">{name || <T id="no_name" />}</div>
          </Tooltip>
          <div className="device-name">{support.screen_resolution}</div>
          <div className="app-id">{appid}</div>
          <div className="device-options" onClick={(e) => e.stopPropagation()}>
            <div className="option-item" onClick={this.handleCopy}>
              <Icon type="icon-tuceng-fuzhi" size={24} color={MENU_ICON_COLOR} />
              <div className="option-name">
                <T id="copy" />
              </div>
            </div>
            <div className="option-item" onClick={this.handleDelete}>
              <Icon type="icon-tuceng-shanchu" size={24} color={MENU_ICON_COLOR} />
              <div className="option-name">
                <T id="delete" />
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={<T id="delete_dial_confirm" />}
          visible={showDeleteConfirmModal}
          className="watch-dial-modal"
          closable={false}
          cancelText={<T id="cancel" />}
          okText={<T id="confirm_1" />}
          onOk={() => {
            this.setState({
              showDeleteConfirmModal: false
            })
            deleteDial(data.id)
          }}
          onCancel={() => {
            this.setState({
              showDeleteConfirmModal: false
            })
          }}
        >
          <div className="confirm-btn-centered">
            <p className="text-center">
              <T id="delete_dial_warning" />
            </p>
          </div>
        </Modal>
        <Modal
          title={<T id="copy_dial" />}
          visible={showCopyModal}
          className="watch-dial-modal"
          closable={false}
          cancelText={<T id="cancel" />}
          okText={<T id="copy" />}
          onOk={this.handleOkCopy}
          onCancel={this.handleCancelCopy}
        >
          <Form
            ref={(form) => {
              this.copyForm = form
            }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="basic"
            initialValues={{
              name: `${data.name}_副本`,
              dialType: copyDeviceTypes?.[0]
            }}
          >
            <Form.Item label={<T id="dial_name" />} name="name">
              <Input />
            </Form.Item>
            <Form.Item label={<T id="dial_type" />} name="dialType">
              <Select
                dropdownClassName="watch-skin-select-dropdown"
                onChange={this.handleCopyTypeChange}
                options={copyDeviceTypes.map((type) => ({
                  label: getDeviceType(type).toLocaleUpperCase(),
                  value: type
                }))}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}

export default withRouter(DialItem as any) as any
