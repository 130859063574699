import { getCroppedImg } from '../components/Upload/canvasUtils'
import { processImageUrl } from './index'

export const loadImage = async (src: string): Promise<any> => {
  const image = new Image()

  image.src = src.startsWith('http') ? `${src}?timestamp=${Date.now()}` : src
  image.crossOrigin = 'anonymous'

  return new Promise((resolve: any) => {
    image.onload = () => {
      resolve(image)
    }
    image.onerror = () => {
      resolve(null)
    }
  })
}

export const getWFCanvasImage = (width: number, height: number, targetWidth: number, targetHeight: number, imageType = 'image/png', shape: string, radius: number) => {
  const c = document.createElement('canvas')
  c.width = width
  c.height = height

  const ctx = c.getContext('2d')
  const dataUrl = (document.querySelector('#design-tool-canvas') as HTMLCanvasElement)?.toDataURL(imageType)

  return new Promise((resolve: any, reject: any) => {
    ;(async () => {
      try {
        const previewImage = await loadImage(dataUrl)

        if (!previewImage) return reject(new Error('Load preview image error'))

        ctx?.drawImage(previewImage, 0, 0, width, height)

        const newDataUrl = c.toDataURL()

        getCroppedImg(
          newDataUrl,
          { width, height, x: 0, y: 0 },
          { width: targetWidth, height: targetHeight },
          shape,
          radius
        ).then((blob: Blob) => {
          resolve(blob)
        })
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const isImgLoaded = (url = '') => {
  if (!url) return Promise.resolve(false)

  const startTime = +new Date()
  let timeId: any = null
  let retryCount = 0

  return new Promise((resolve) => {
    function retry() {
      let img: any = new Image()
      retryCount += 1

      timeId && clearTimeout(timeId)
      img.crossOrigin = 'anonymous'

      const retryImageSrc = processImageUrl(url || '', `action=retry&retryCount=${retryCount}`)

      img.src = retryImageSrc
      img.onload = () => {
        retryCount = 0
        resolve(true)
      }
      img.onerror = () => {
        const endTime = +new Date()
        img = null
        if (endTime - startTime < 1000 * 10) {
          timeId = setTimeout(retry, 200)
        } else {
          retryCount = 0
          resolve(false)
        }
      }
    }

    retry()
  })
}

export const isPng = (fileData: Uint8Array) => {
  // https://www.w3.org/TR/png/#3PNGsignature
  const signature = [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A]
  const header = fileData.slice(0, 8)
  return !header.some((item: number, index: number) => item !== signature[index])
}

export const isGrayScale = (fileData: Uint8Array) => {
  // https://www.w3.org/TR/png/#11IHDR
  const headerLen = 8
  const chunkLen = 4
  const idhrTypeLen = 4
  const idhrDateStartIdx = headerLen + chunkLen + idhrTypeLen - 1
  const colorType = fileData[idhrDateStartIdx + 10]
  /*
    https://www.w3.org/TR/png/#6Colour-values
    Greyscale	0
    Truecolour	2
    Indexed-colour	3
    Greyscale with alpha	4
    Truecolour with alpha	6
  */
  return colorType === 0 || colorType === 4
}


export const imgURLToBlob = (url: string, type = 'image/png') => {
  const canvas = document.createElement('canvas')
  const ctx: any = canvas.getContext('2d')
  const img = new Image()
  img.crossOrigin = 'anonymous'
  img.src = processImageUrl(url)
  return new Promise((resolve) => {
    img.onload = () => {
      const { width, height } = img
      canvas.width = width
      canvas.height = height
      ctx.clearRect(0, 0, width, height)
      ctx.drawImage(img, 0, 0)

      canvas.toBlob(function (blob) {
        resolve(blob)
      }, type)
    }
    img.onerror = () => resolve(null)
  })
}

// 灰度图片使用 canvas 导出 blob 可能会自动转为 rbg 模式，此情况使用该方法处理
export const imgUrlToBlob = (url: string) => {
  return fetch(url).then((res) => res.blob()).catch(() => {
    return null
  })
}
