/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE, SERIES } from '@watchface/constants'
import { VERTEX_DOT_LINE_STYLE } from '@watchface/constants/graph'
import { removeCells, renderCells } from '@watchface/utils/render'
import { isHitKey } from '@watchface/utils'
import classNames from 'classnames'
import Immutable from 'immutable'
import PubSub from 'pubsub-js'
import React from 'react'

const noop = () => {} // eslint-disable-line
class JumpHotAreaComponent extends React.Component {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: JumpHotAreaComponent,
      ...param
    })
    JumpHotAreaComponent.keyMap = Object.assign(JumpHotAreaComponent.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: JumpHotAreaComponent,
      ...param
    })
    JumpHotAreaComponent.keyMap = keyMap
  }

  static getCellDatas = ({ data }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const image = JumpHotAreaComponent.getImage({ data })
    if (image) {
      cells.imageEntity[image.key] = image
    }

    const vertex = JumpHotAreaComponent.getVertex({ data })
    if (vertex) {
      cells.vertexEntity[vertex.key] = vertex
    }
    return cells
  }

  static getImage = ({ data }) => {
    const { position = Immutable.Map(), image = '', image_pos } = immutableSelector(data)
    if (!image) return
    if (image_pos) {
      const { x: posX = 0, y: posY = 0 } = immutableSelector(image_pos)
      return { key: 'image', texture: image, left: posX, top: posY }
    }
    const { x = 0, y = 0 } = immutableSelector(position)
    return { key: 'image', texture: image, left: x, top: y }
  }

  static getVertex = ({ data }) => {
    const { width = 1, height = 1, position = Immutable.Map() } = immutableSelector(data)
    const { x = 0, y = 0 } = immutableSelector(position)

    return {
      key: 'vertex',
      left: x,
      top: y,
      width,
      height,
      type: 'rect',
      fill: 'transparent',
      // visible: false, // 起区域占位作用
      ...VERTEX_DOT_LINE_STYLE
    }
  }

  componentDidMount() {
    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      const { parentKey, childKey, editableComponentKey = '', dialType } = this.props

      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      this.handleChange(['position'], Immutable.Map({
        x: left,
        y: top,
      }))
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props
    if (prevProps !== this.props) {
      if (!hidden) {
        JumpHotAreaComponent.renderLayer(this.props)
      } else {
        JumpHotAreaComponent.removeLayer(this.props)
      }
    }
  }

  get imgCellKey() {
    const { parentKey, childKey } = this.props

    return `${parentKey}-${childKey}-image`
  }

  get jumpAreaKey() {
    const { parentKey, childKey } = this.props
    const jumpAreaKey = `${parentKey}-${childKey}-jumpArea`

    return jumpAreaKey
  }

  handleChange = (path = [], value) => {
    const { parentKey, childKey, data, onChange, prefixPath } = this.props
    const newData = data.setIn(path, value)

    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleUpload = (image = {}) => {
    const { parentKey, childKey, data, onChange, prefixPath } = this.props
    let newData = data

    if (image.image) {
      newData = data.set('image', image.image)
    } else {
      newData = data.set('image', '')
    }
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  render() {
    const { data = Immutable.Map(), watchConfig, childKey, showClickImage = true, showImagePosition = false, series } = this.props
    const isZeppOS = series === SERIES.JS
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { width = 1, height = 1, image = '', position = Immutable.Map(), image_pos = Immutable.Map() } = immutableSelector(data)
    const { x = 0, y = 0 } = immutableSelector(position)
    const { x: imgPosX = 0, y: imgPosY = 0 } = immutableSelector(image_pos)
    return (
      <div className="control-container">
        <div className="name">
          <T id="hot_area_size" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">W</div>
              <InputNumber
                min={1}
                max={screenWidth}
                value={width}
                onChange={(value) => {
                  this.handleChange(['width'], value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">H</div>
              <InputNumber
                min={1}
                max={screenHeight}
                value={height}
                onChange={(value) => {
                  this.handleChange(['height'], value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={x}
                onChange={(value) => {
                  this.handleChange(['position', 'x'], value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={y}
                onChange={(value) => {
                  this.handleChange(['position', 'y'], value)
                }}
              />
            </div>
          </div>
        </div>
        {showClickImage && (
          <>
            <div className={classNames('name', {
              'is-required': !isZeppOS
            })}>
              <T id="clicked_image" />
            </div>
            <div className="content">
              <div className="item">
                <Upload
                  key={childKey}
                  fileList={image ? [{ image }] : []}
                  field="image"
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  onChange={this.handleUpload}
                />
              </div>
            </div>
          </>
        )}
        {showImagePosition ? (
          <>
            <div className="name">
              <T id="position" />
            </div>
            <div className="content">
              <div className="item">
                <div className="col">
                  <div className="key">x</div>
                  <InputNumber
                    min={0}
                    max={screenWidth}
                    value={imgPosX}
                    onChange={(value) => {
                      this.handleChange(['image_pos', 'x'], value)
                    }}
                  />
                </div>
                <div className="col">
                  <div className="key">y</div>
                  <InputNumber
                    min={0}
                    max={screenHeight}
                    value={imgPosY}
                    onChange={(value) => {
                      this.handleChange(['image_pos', 'y'], value)
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    )
  }
}

JumpHotAreaComponent.defaultProps = {
  prefixPath: [],
  onChange: noop
}

export default JumpHotAreaComponent
