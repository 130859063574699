// 使用 asset 存储服务端未支持的 zepp os 协议. https://huami.feishu.cn/wiki/wikcntJoIdX5BkgYsiQXfE5XYre#
import ICON from '@watchface/pages/DesignTool/NonZeppOs/components/IconComponent/config'
import JUMPABLE from '@watchface/pages/DesignTool/NonZeppOs/components/JumpHotAreaComponent/config'
import LUNAR from '@watchface/pages/DesignTool/NonZeppOs/components/LunarComponent/config'
import createNumberData from '@watchface/pages/DesignTool/NonZeppOs/components/NumberComponent/config'
import IMAGE_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PictureProgress/config'
import POINTER_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PointerProgressComponent/config'
import getProgress from '@watchface/pages/DesignTool/NonZeppOs/components/ProgressComponent/config'
import Immutable from 'immutable'

const PAI_DAY = {
  children: {
    daily: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const MOON = {
  children: {
    image_progress: IMAGE_PROGRESS
  }
}

const extraConfig = {
  // 相较于 layerConfig 新增的模块或者新增已有模块中字段的路径, 二维数组
  newFieldOrModulePaths: [
    // 如 background 新增 radius 字段，可配置为 ['background', 'radius']
    // 新增新的模块如站立，可配置为 ['stand']
    ['pai_daily'],
    ['lunar'],
    ['moon'],
  ],
  pai_daily: PAI_DAY,
  lunar: LUNAR,
  moon: MOON,
}

export default Immutable.fromJS(extraConfig)
