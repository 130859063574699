// 虚线框样式
export const VERTEX_DOT_LINE_STYLE = {
  strokeDashArray: [2, 3],
  stroke: '#808080'
}

export const VERTEX_GROUP_STYLE = {
  left: 0,
  top: 0,
  selectable: false
}
