import { Col, Divider, InputNumber, Row } from 'antd'
import * as React from 'react'

interface Props {
  properties: {
    previewNumber: { value: number; minValue: number; maxValue: number }
  }
  onChange: (category: string, property: string, value: any) => void
}

const PreviewText: React.FC<Props> = (props) => {
  const {
    properties: {
      previewNumber: { value: preview, minValue, maxValue }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-previewText">
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={2}>T</Col>
        <Col span={22}>
          <InputNumber
            min={minValue}
            max={maxValue}
            style={{ width: 120 }}
            defaultValue={preview}
            onChange={(value) => {
              onChange('previewText', 'previewNumber', value)
            }}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default PreviewText
