/* eslint-disable import/order */
import React from 'react'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import T from '@watchface/components/I18n'
import { immutableSelector } from '@/utils/index'
import { fetchUserDial, fetchSupportDevices, setExquisiteDial, deleteDial, DialData, copyDial } from '@watchface/store/actions'
import Dial from './Dial'

import './open.scss'

interface OpenDialProps {
  fetchSupportDevices: () => void
  fetchUserDial: (pageNum: number, pageSize: number, keyword?: string, support_ids?: string) => void
  setExquisiteDial: (data: DialData) => void
  deleteDial: (dialId: number, cb: unknown) => void
  copyDial: (dialId: number, dialName: string, copyDeviceId: number, isZeppOS: boolean, cb: unknown) => void
  supportDevices: any
  userDial: DialData & Immutable.Map<string, any>
  exquisiteDial: DialData & Immutable.Map<string, any>
}

class OpenDial extends React.PureComponent<OpenDialProps> {
  componentDidMount() {
    const { supportDevices = Immutable.List(), fetchSupportDevices } = this.props
    supportDevices.size === 0 && fetchSupportDevices && fetchSupportDevices()
  }

  render() {
    const { fetchUserDial, userDial, supportDevices, deleteDial, copyDial } = this.props
    const dialInfo: any[] = [
      {
        title: <T id="my_dial" />,
        dialData: userDial,
        supportDevices,
        fetchDial: fetchUserDial,
        deleteDial,
        copyDial
      } /* {
      title: '精品表盘',
      setDial: setExquisiteDial,
      dialData: exquisiteDial,
      fetchDial: getExquisiteDial,
    } */
    ]

    return (
      <div className="watch-skin-open">
        <div className="title">
          <T id="my_dial" />
        </div>
        {dialInfo.map((dial: any) => (
          <Dial key={dial.title} {...dial} />
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  const { watchFace = Immutable.Map() } = immutableSelector(state)
  const { dialList = Immutable.Map(), devices = Immutable.Map() } = immutableSelector(watchFace)
  const { userDial, exquisiteDial } = immutableSelector(dialList)
  const { supportDevices } = immutableSelector(devices)
  return {
    userDial,
    exquisiteDial,
    supportDevices
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchUserDial: (pageNum: number, pageSize: number, keyword?: string, support_ids?: string) =>
      dispatch(fetchUserDial({ pageNum, pageSize, keyword, support_ids })),
    fetchSupportDevices: () => dispatch(fetchSupportDevices()),
    setExquisiteDial: (data: DialData) => dispatch(setExquisiteDial(data)),
    deleteDial: (dialId: number, cb: unknown) => {
      dispatch(deleteDial({ dialId, cb }))
    },
    copyDial: (dialId: number, dialName: string, copyDeviceId: number, isZeppOS: boolean, cb: unknown) => {
      dispatch(copyDial({ dialId, dialName, copyDeviceId, isZeppOS, cb }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenDial)
