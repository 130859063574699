import React, { useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Checkbox, Button, message, Modal, Popover } from 'antd'
import classNames from 'classnames'
import type { Map as ImmutableMap } from 'immutable'
import Immutable from 'immutable'
import { throttle } from 'lodash'
import PubSub from 'pubsub-js'
import { QRCodeSVG } from 'qrcode.react'
import { MenuOutlined, RedoOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons'
import { Export, Icon } from '@watchface/components'
import T from '@watchface/components/I18n'
import { OPERATIONS } from '@watchface/config'
import { EXPORT_POLL_INTERVAL, EXPORT_WATCHSKIN, INITIAL_VERSION, MENU_ICON_COLOR, SAVE_TYPE, SERIES, SIMULATOR, ZOOM_WATCHSKIN } from '@watchface/constants'
import size from '@watchface/constants/size'
import { checkOsAppIdIsValid, getAppid, getExportStatus, uploadDialZip } from '@watchface/request/api'
import apiHost from '@watchface/request/domains/common'
import {
  download,
  getConfigJson,
  getFileName,
  getZeppDocLink,
  hasEditComponentInConfig,
  includeNonLeftAlignment,
  osAppIdIsLtStartingAppId,
  readFileAsUnit8Array,
  versionPlusOne,
  zipRemoteFiles
} from '@watchface/utils'
import analytics from '@watchface/utils/analytics'
import { immutableSelector } from '@/utils'
import BridgeButton from './BridgeButton'
import ExportImageModal from './ExportImageModal'
import FillPreImgModal from './FillPreImgModal'
import './index.scss'
import { imgUrlToBlob, isGrayScale } from '../../utils/img'
import tipForSpecialDeviceSingleton from '../TipForSpecialDevice'

const baseURL = apiHost.apiWatch
const noChildrenArr = ['background', 'idle_background', 'scale', 'mask', 'component']
const noop = () => {} // eslint-disable-line
const preImageToggle = true

const sortMenu = (menu: any, orderBy = 'ASC') => {
  return menu.sortBy(
    (item: any) => item.get('order'),
    (a: number, b: number) => (orderBy === 'ASC' ? a - b : b - a)
  )
}

interface OperationMenuProps {
  allData: ImmutableMap<string, any>
  metas: ImmutableMap<string, any>
  asset: ImmutableMap<string, any>
  support: ImmutableMap<string, any>
  dialType: string
  intl: any
  currentConfig: any
  canUndo: boolean
  canRedo: boolean
  undo: () => void
  redo: () => void
  handleInitRender: (data: any, renderLayerByLang?: any) => void
  changeAsset: (data: any) => void
  saveDesigner: (data?: any) => void
  startBackendExport: any
  changeDesigner: (data?: any, cb?: any) => void
}

// enum AdaptType {
//   ONLY,
//   AUTO_ADAPT
// }

const OperationMenu = (props: OperationMenuProps): JSX.Element => {
  const {
    saveDesigner,
    changeDesigner,
    changeAsset,
    undo,
    redo,
    handleInitRender,
    metas,
    support,
    asset = Immutable.Map(),
    allData = Immutable.Map(),
    currentConfig,
    canUndo,
    canRedo,
    dialType
  } = props

  const { screen_resolution = '', preview_resolution = '', series, shape, radius } = immutableSelector(support)
  const dialName = (metas || Immutable.Map()).getIn(['multi_language', 0, 'name']) as string
  const multiLangList = metas?.get('multi_language')
  const languages = metas
    ?.get('multi_language')
    ?.map((item: any) => item.get('language'))
    .toJS()
  const scanCodeLink = getZeppDocLink('scanCode')
  const { dialId } = useParams<{ dialId: string }>()
  const history = useHistory()
  const { deviceInfo } = getConfigJson()
  const supportDevices = deviceInfo?.map((item: any) => `[${item.deviceSource}]${item.deviceNameForUser}`)
  const [checkedDevices, setCheckedDevices] = useState<any[]>(supportDevices)
  const [dialNameEditable, setDialNameEditable] = useState<boolean>(false)
  const [exportImageModalVisible, setExportImageModalVisible] = useState(false)
  const [confirmExportModalVisible, setConfirmExportModalVisible] = useState(false)
  const [exportModalVisible, setExportModalVisible] = useState(false)
  const [exportType, setExportType] = useState<string>('export')
  const [exportFilename, setExportFilename] = useState<string>(dialName)
  const [imgMapper, setImgMapper] = useState<any>({})
  const [exportConfirmLoading, setExportConfirmLoading] = useState(false)
  const [showFillPreviewImageModal, setShowFillPreviewImageModal] = useState(false)
  const cbAfterFillPreviewImgCollect = useRef<any[]>([])

  const [state, _setState] = useState<any>({
    current: Immutable.List(),
    // 控制修改表盘信息模态框的显示和隐藏
    checkImgContent: null,
    // adaptType: AdaptType.ONLY
  })
  const stateRef: any = useRef(state)
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)
  const [showNewFeatQr, setShowNewFeatQr] = useState<boolean>(false)
  const [qrUrl, setQrUrl] = useState<string>('')

  const { checkImgContent } = state
  // const autoAdapt = adaptType === AdaptType.AUTO_ADAPT
  const autoAdapt = false
  // const isAutoScale = asset.get('isAutoScale')

  const setState = (state: any) => {
    stateRef.current = state
    _setState(state)
  }

  const langMap: any = {
    zh: <T id="lang_zh" />,
    'zh-Hant': <T id="lang_zh-hant" />,
    en: <T id="lang_en" />,
    all: <T id="lang_all" />
  }

  const languageOptions: any =
    languages?.map((lang: any) => ({
      label: langMap[lang],
      value: lang
    })) || []

  const throttleSave = useRef(
    throttle(
      () => {
        saveDesigner({ saveType: SAVE_TYPE.MANUAL })
      },
      2000,
      { leading: true }
    )
  )

  useEffect(() => {
    setExportFilename(dialName)
  }, [dialName])

  useEffect(() => {
    !exportModalVisible && setExportConfirmLoading(false)
  }, [exportModalVisible])

  // useEffect(() => {
  //   setState({
  //     adaptType: isAutoScale ? AdaptType.AUTO_ADAPT : AdaptType.ONLY
  //   })
  // }, [isAutoScale])

  const getI18nMessage = (id: string, values?: any) => {
    const { intl } = props
    return intl?.messages[id]?.replace(/{(\w+)}/g, (match: any, name: any) => values[name]) || id
  }

  const executeCheckConfig = (checkConfig: any, config: any, parentName: string, childName: string, prefix = '') => {
    if (!checkConfig) return
    const msg = (
      <T
        id="img_check_required_err"
        values={{
          path: childName ? (
            <>
              &apos;{prefix}
              <T id={parentName} />-<T id={childName} />
              &apos;
            </>
          ) : (
            <>
              &apos;{prefix}
              <T id={parentName} />
              &apos;
            </>
          )
        }}
      />
    )
    let msgEl = null
    const getMsgEl = (msg: any) => (
      <div key={msg} className="tip-item">
        <Icon
          className="tip-item-warning-icon"
          type="icon-tuceng-jinggao"
          size={24}
          style={{
            color: '#fea224'
          }}
        />
        <span>{msg}</span>
      </div>
    )
    // 只要确定需要校验的图片中有一张没上传即可给出提示
    checkConfig.some((item: any) => {
      const { isList, listSize, fieldPath, when } = item
      if (when) {
        // workaround: 临时处理，优化数据结构以适应多条件场景
        if (Array.isArray(when)) {
          const [first, second] = when
          if (config.getIn(first.fieldPath) !== first.value || config.getIn(second.fieldPath) !== second.value) return false
        } else {
          const { fieldPath, value } = when
          if (config.getIn(fieldPath) !== value) return false
        }
      }
      const image = config.getIn(fieldPath)
      if (isList) {
        if (!(Immutable.List.isList(image) && image.size && image.get(0))) {
          msgEl = getMsgEl(msg)
          return true
        }
        if (listSize) {
          if (image.size !== listSize) {
            msgEl = getMsgEl(<T id="img_check_count_err" values={{ path: `${getI18nMessage(parentName)}-${getI18nMessage(childName)}` }} />)
            return true
          }
        }
      } else if (!image) {
        msgEl = getMsgEl(msg)
        return true
      }
      return false
    })

    return msgEl
  }

  // 导出之前校验已选择各个组件的字段
  const checkValue = () => {
    const { support, allData, dialType, asset } = props
    const { series } = immutableSelector(support)
    const content: any[] = []
    if (!series) return false
    const getErrorMessage = (errMsg: any, menu: any, CHECK_CONFIGS: any, config: any, componentMenu: any, prefix: any = '') => {
      /* eslint no-continue: 0 */
      for (let i = 0; i < menu.size; i += 1) {
        const m = menu.get(i)
        const { key, hide } = immutableSelector(m)
        const configKey = key.replace(/_\d+/, '')
        const crtConfig = config.get(configKey)
        if (hide) continue
        if (!crtConfig) continue
        if (configKey === 'component') {
          const configIndex = componentMenu.findIndex((item: any) => item.get('key') === key)
          if (configIndex > -1) {
            const newMenu = componentMenu.getIn([configIndex, 'children']) || Immutable.List()
            const newConfig = config.getIn([configKey, String(configIndex), 'props']) || Immutable.Map()
            getErrorMessage(
              errMsg,
              newMenu,
              CHECK_CONFIGS,
              newConfig,
              componentMenu,
              m.get('name') ? (
                <>
                  <T id={m.get('name')} />-
                </>
              ) : null
            )
            continue
          }
        }
        const children = crtConfig.get('children')
        const childrenMenu = m.get('children')
        const parentName = m.get('name') || ''
        if (noChildrenArr.indexOf(key) > -1) {
          const checkConfig = CHECK_CONFIGS[key]
          if (!checkConfig) continue
          const msgEl = executeCheckConfig(checkConfig, crtConfig, parentName, prefix)
          msgEl && errMsg.set(parentName, msgEl)
          continue
        }
        children.forEach((child: any, childKey: string) => {
          const checkConfigMap = { ...(CHECK_CONFIGS.common || {}), ...(CHECK_CONFIGS[key] || {}) }
          const checkConfig = checkConfigMap[childKey]
          const childMenu = childrenMenu.filter((item: any) => {
            if (['am', 'pm'].indexOf(childKey) > -1) return item.get('key') === 'ampm'
            return item.get('key') === childKey
          })
          const childName = childMenu.getIn([0, 'name']) || ''
          const checked = childMenu.getIn([0, 'checked'])
          if (!checkConfig || !checked) return
          if (['am', 'pm'].indexOf(childKey) > -1) {
            const ampmLang = asset.getIn([dialType, key, 'children', 'ampm', 'ampmLang']) || 'all'
            if (ampmLang === 'en') {
              checkConfig.splice(0, 1)
            }
            if (ampmLang === 'zh') {
              checkConfig.splice(1, 1)
            }
          }
          // 图片校验 List | String
          const msgEl = executeCheckConfig(checkConfig, child, parentName, childName, prefix)
          msgEl && errMsg.set(`${parentName}-${childName}`, msgEl)
        })
      }
    }
    const check = (dialType: string) => {
      const data = allData.get(dialType) || Immutable.Map()
      const { menu = Immutable.List(), config = Immutable.Map() } = immutableSelector(data)
      const getCheckConfigs = currentConfig[dialType].getCheckConfigs || noop
      const CHECK_CONFIGS = getCheckConfigs() || {}
      const errMsg: any = new Map()
      const componentMenu = sortMenu(
        menu.filter((item: any) => item.get('key').startsWith('component_')),
        'ASC'
      )
      const title = getI18nMessage(dialType)
      getErrorMessage(errMsg, menu, CHECK_CONFIGS, config, componentMenu)
      if (errMsg.size) {
        content.push(
          <div className="tip-category" key={title}>
            <div className="tip-title">{title}:</div>
            {[...errMsg.keys()].map((key) => (
              <div key={key}>{errMsg.get(key)}</div>
            ))}
          </div>
        )
      }
    }
    const otherDialType: any = {
      normal: 'idle',
      idle: 'normal'
    }
    // 校验表盘
    check(dialType)
    check(otherDialType[dialType])
    if (content.length) {
      setState({
        ...state,
        checkImgContent: content
      })
    }
    return !content.length
  }

  const handleExportCheckChange = (value: any[]) => {
    let newValue = value
    !newValue.length && (newValue = [supportDevices[0]])
    setCheckedDevices(newValue)
  }

  const handleNewDownloadAndInstall = async (type: string) => {
    const { startBackendExport, allData } = props
    const { deviceInfo } = allData.toJS() as any
    const typeConf: any = {
      export: {
        gtagStart: () => analytics.gtagEvent('export', '开始执行导出操作', {
          status: 'start',
          os_type: SERIES.JS
        }),
        gtagEnd: (startTime: any) => analytics.gtagEvent('export', '成功导出表盘包', {
          os_type: series,
          status: 'success',
          time_spend: (Date.now() - startTime) / 1000
        }),
        handleZab: (url: any) => download(url)
      },
      install: {
        gtagStart: () => analytics.gtagEvent('scan_qrcode', '开始生成表盘包', {
          status: 'start',
          os_type: SERIES.JS
        }),
        gtagEnd: (startTime: any) =>  analytics.gtagEvent('scan_qrcode', '成功生成二维码', {
          status: 'success',
          os_type: SERIES.JS,
          time_spend: (Date.now() - startTime) / 1000
        }),
        handleZab: async (url: any) => {
          fetch(url).then(res => res.blob()).then(async (blobData) => {
            const res = await uploadDialZip(new File([blobData], getFileName(url)))
            const { protocol, code } = res as any

            if (!protocol || !code) {
              message.error('An error occurred, please try again')
              return
            }

            const qrcode = `${baseURL().replace('https', protocol)}custom/tools/app-dial/download/${code}`

            setQrUrl(qrcode)

            setShowNewFeatQr(true)
          })
        }
      },
    }

    try {
      const startTime = Date.now()
      const rmDownloadAndInstallLoading = message.loading('action in progress..', 0)
      const checkedDeviceSources = checkedDevices?.map((checkedDevice: any) => {
        return checkedDevice.replace(/\[(\d+)\].*/, '$1') * 1
      })
      const exportDeviceInfo = deviceInfo.filter((device: any) => checkedDeviceSources?.includes(device.deviceSource))

      typeConf[type]?.gtagStart()

      changeAsset({ key: 'exportDeviceInfo', value: type === 'export' ? exportDeviceInfo : deviceInfo })
      // changeAsset({ key: 'isAutoScale', value: type !== 'export' || autoAdapt })
      changeAsset({ key: 'isAutoScale', value: false })

      saveDesigner({ callback: () => {
        startBackendExport &&
        startBackendExport(
          dialId,
          'js',
          false,
          exportFilename,
          EXPORT_POLL_INTERVAL,
          (value: any) => {
            const { url } = value.data || {}

            // changeAsset({ key: 'isAutoScale', value: autoAdapt })
            saveDesigner({})

            rmDownloadAndInstallLoading()

            if (!url) return

            typeConf[type]?.gtagEnd(startTime)
            typeConf[type]?.handleZab(url)
          },
          (value: any) => {
            rmDownloadAndInstallLoading()
            message.error(value?.message, 5)
          }
        )
      } })
    } catch (error) {
      console.error('handleNewDownloadAndInstall', error)
    }
  }

  const checkPreImage = (type = '') => {
    let imgCheck = true

    if (type === 'multiLangList') {
      multiLangList.forEach((item: any) => {
        const image = item.get('image')
        if (!image) {
          imgCheck = false
        }
      })
    } else if (type === 'imgMapper') {
      languageOptions.forEach((item: any) => {
        if (!imgMapper?.[item?.value]) {
          imgCheck = false
        }
      })
    }
    return imgCheck
  }

  const checkPreviewImgIsReady = (cb: any) =>  {
    const imgValid = checkPreImage('multiLangList')
    if (!imgValid)  {
      setShowFillPreviewImageModal(true)
      cb && cbAfterFillPreviewImgCollect.current.push(cb)
    } else {
      cb?.()
    }
  }

  const executeCbAfterFillPreviewImg = () => {
    while(cbAfterFillPreviewImgCollect.current.length) {
      cbAfterFillPreviewImgCollect.current.shift()?.()
    }
  }

  const handleSimulatorPreview = () => {
    checkPreviewImgIsReady(() => {
      PubSub.publish(SIMULATOR)
    })
  }

  const registerAppIdAndExport = async () => {
    const { appVersion = INITIAL_VERSION } = immutableSelector(asset)
    getAppid(dialId).then((res) => {
      const { appid: newAppId } = res as any
      changeAsset({ key: 'appId', value: newAppId })

      if (appVersion) {
        const newAppVersion = versionPlusOne(appVersion)
        changeAsset({ key: 'appVersion', value: newAppVersion })
      }

      handleNewDownloadAndInstall('export')
    })
  }

  const handleExport = async () => {
    setExportModalVisible(false)
    const { appId, appVersion = INITIAL_VERSION } = immutableSelector(asset)

    if (osAppIdIsLtStartingAppId(appId)) {
      // appId < 100W 申请新的 appId 并导出
      registerAppIdAndExport()
      return
    }

    // appId > 100W 判断 appId 有效性，无效则弹窗告知用户，有效直接导出
    const { valid } = await checkOsAppIdIsValid(appId, Number(dialId))

    if (!valid) {
      setConfirmExportModalVisible(true)
    } else {
      if (appVersion) {
        const newAppVersion = versionPlusOne(appVersion)
        changeAsset({ key: 'appVersion', value: newAppVersion })
      }
      handleNewDownloadAndInstall('export')
    }
  }

  const handleChange = (targetMapper = {}) => {
    setImgMapper(targetMapper)
  }

  const preImgRender = (lang?: string) => {
    const crtTypeData = allData.get(dialType)
    handleInitRender(crtTypeData, lang)
  }

  const savePreImg = (imgObject: any, cb: () => void) => {
    const targetLangList: any[] =
      multiLangList?.toJS()?.map((item: any) => {
        const { language } = item
        const image = imgObject?.[language] || ''
        return {
          ...item,
          image
        }
      }) || []

    const targetMetas = metas?.set('multi_language', Immutable.fromJS(targetLangList)) || metas || Immutable.Map()
    const changeData: any = [{ path: ['metas'], data: targetMetas }]

    changeDesigner(changeData, () => cb && cb())
  }

  const checkImageColorMode = async () => {
    let normalConfig: any = allData.getIn(['normal', 'config'], Immutable.Map())
    let idleConfig: any = allData.getIn(['idle', 'config'], Immutable.Map())

    const regex = /https:\/\/([^"]+\.(?:png))/g

    const hideWidgets = normalConfig?.get('hide')?.toJS()
    const idleHideWidgets = idleConfig?.get('hide')?.toJS()

    const rmHideWidgets = (hideWidgets: string[], config: any) => {
      if (!config || !Array.isArray(hideWidgets)) return config
      let newConfig = config

      hideWidgets.forEach((hideWidget: string) => {
        newConfig = newConfig.delete(hideWidget)
      })

      return newConfig
    }

    // 去除校验正常表盘配置中的隐藏组件下的图片
    normalConfig = rmHideWidgets(hideWidgets, normalConfig)
    // 去除校验息屏表盘配置中的隐藏组件下的图片
    idleConfig = rmHideWidgets(idleHideWidgets, idleConfig)

    const configOsWidgetsPath = ['config_os', 'scene', 'widgets']

    let configOsWidgets = normalConfig?.getIn(configOsWidgetsPath)

    if (configOsWidgets) {
      configOsWidgets.forEach((widget: any, key: string) => {
        if (widget.get('visible') === false) {
          configOsWidgets = configOsWidgets.delete(key)
        }
      })
    }

    normalConfig = normalConfig.setIn(configOsWidgetsPath, configOsWidgets)

    const imageResources = JSON.stringify({ normalConfig, idleConfig }).match(regex)

    if (!Array.isArray(imageResources)) return true

    const grayscaleUrls = []
    const grayscaleNames = []
    let idx = 0

    for (const img of imageResources) {
      const blob = await imgUrlToBlob(img) // eslint-disable-line
      const fileData = await readFileAsUnit8Array(blob) // eslint-disable-line

      if (!fileData) continue

      const fileIsGrayScale = isGrayScale(fileData)

      if (fileIsGrayScale) {
        grayscaleUrls.push(img)
        grayscaleNames.push(`grayscale_img_${idx}.png`)
        idx += 1
      }
    }

    if (grayscaleUrls.length) {
      await zipRemoteFiles('grayscale_images.zip', grayscaleUrls, grayscaleNames)
      Modal.error({
        title: 'Image Color Type Error',
        content: 'Your image resources contain images in grayscale mode. Please adjust the image mode and upload them again.The grayscale mode images have been automatically downloaded for you. Please verify. ',
      })
      setExportModalVisible(false)
      return false
    }

    return true
  }

  // 导出弹框内的各项校验
  const checkExportModalValid = () => {
    if (preImageToggle) {
      const imgValid = checkPreImage('imgMapper')

      if (!imgValid) {
        message.error('Please upload preview images for all languages')
        return
      }
    }

    if (preImageToggle) {
      savePreImg(imgMapper, () => {
        saveDesigner({
          callback: async () => {
            let checked = false
            try {
              checked = await checkImageColorMode()
            } catch(e) {
              console.error('checkImageColorMode Error', e)
              checked = true
            }
            checked && handleExport()
          }
        })
      })
      return
    }

    handleExport()
  }

  const handleOperate = async (operation: string) => {
    setPopoverVisible(false)
    switch (operation) {
      case 'undo':
        undo()
        break
      case 'redo':
        redo()
        break
      case 'save':
        throttleSave.current()
        break

      case 'zoom': {
        changeAsset({ key: 'isAutoScale', value: false })
        saveDesigner({
          callback: () => {
            PubSub.publish(ZOOM_WATCHSKIN)
          }
        })
        break
      }
      case 'export-image': {
        setExportImageModalVisible(true)
        break
      }
      case 'install':
        checkPreviewImgIsReady(() => {
          getExportStatus(dialId).then((res: any) => {
            if (res?.status === 1) {
              setDialNameEditable(true)
            } else {
              setDialNameEditable(false)
            }
            if (res?.app_name) {
              const { app_name = '' } = res
              setExportFilename(app_name)
            }
            setExportType('install')
            checkValue() && saveDesigner({
              callback: () => {
                PubSub.publish(EXPORT_WATCHSKIN)
              }
            })
          })
        })

        break
      case 'export': {
        const handleOk = () => {
          getExportStatus(dialId).then((res: any) => {
            if (res?.status === 1) {
              setDialNameEditable(true)
            } else {
              setDialNameEditable(false)
            }
            if (res?.app_name) {
              const { app_name = '' } = res
              setExportFilename(app_name)
            }
            setExportType('export')
            checkValue() && PubSub.publish(EXPORT_WATCHSKIN)
          })
        }
        if (includeNonLeftAlignment(allData)) {
          Modal.confirm({
            icon: null,
            className: 'watch-dial-tip-modal',
            title: getI18nMessage('tips'),
            content: getI18nMessage('use_alignment_tip'),
            cancelText: getI18nMessage('cancel'),
            okText: getI18nMessage('confirm_1'),
            onOk: handleOk,
            centered: true
          })
        } else {
          handleOk()
        }

        break
      }
      case 'zeppOS-install':
        checkValue() && checkPreviewImgIsReady(() => {
          handleNewDownloadAndInstall('install')
        })

        break
      case 'zeppOS-export':
        setCheckedDevices(supportDevices)
        if (preImageToggle) {
          const initImgMapper: any = {}
          multiLangList?.forEach((item: any) => {
            const lang = item.get('language')
            const image = item.get('image')
            if (lang) {
              initImgMapper[lang] = image || ''
            }
          })
          setImgMapper(initImgMapper)

          const tip = tipForSpecialDeviceSingleton(screen_resolution)

          tip.show().then(() => {
            checkValue() && setExportModalVisible(true)
          })
        } else {
          checkValue() && handleExport()
        }
        break
      case 'simulator':
        saveDesigner({
          callback: () => {
            checkValue() && handleSimulatorPreview()
          }
        })
        break
      default:
        break
    }
  }

  useHotkeys('ctrl+s,command+s', (e: any) => {
    e?.preventDefault()
    handleOperate('save')
  })
  useHotkeys('ctrl+z,command+z', () => {
    handleOperate('undo')
  })
  useHotkeys('ctrl+shift+z,command+shift+z', () => {
    handleOperate('redo')
  })

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible)
  }

  // const handleAdaptTypeChange = (e: any) => {
  //   setState({
  //     adaptType: e.target.value,
  //   })
  // }

  const content = (
    <div className="menu-nav">
      {OPERATIONS.filter(({ type }) => {
        if (series === 'js') {
          return type.includes('zeppOS')
        }
        return type.includes('nonZeppOS')
      }).map(({ name, icon, operation }) => {
        const operationBtn = (
          <div key={operation} className="menu-btn" onClick={() => handleOperate(operation)}>
            {icon}
            <span className="menu-text">
              <T id={name} />
            </span>
          </div>
        )
        return operationBtn
      })}
    </div>
  )

  const getSizes = (): string[] => {
    const sizes = []

    if (screen_resolution) {
      sizes.push(screen_resolution)
      shape === 'round' && sizes.push('360*360')
    }

    return sizes
  }

  return (
    <div className="operation-menu">
      <div
        className={classNames('menu-btn', 'menu-btn-save', {
          'menu-btn-disabled': !canUndo
        })}
        onClick={() => canUndo && handleOperate('undo')}
      >
        <UndoOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
        <span className="menu-text">
          <T id="undo" />
        </span>
      </div>
      <div
        className={classNames('menu-btn', 'menu-btn-save', {
          'menu-btn-disabled': !canRedo
        })}
        onClick={() => canRedo && handleOperate('redo')}
      >
        <RedoOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
        <span className="menu-text">
          <T id="redo" />
        </span>
      </div>
      {series === SERIES.JS ? <BridgeButton checkPreviewImgIsReady={checkPreviewImgIsReady} saveDesigner={saveDesigner} checkValue={checkValue} /> : null}
      <div className="menu-btn menu-btn-save" onClick={() => handleOperate('save')}>
        <SaveOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
        <span className="menu-text">
          <T id="save" />
        </span>
      </div>
      <Popover placement="bottomRight" content={content} trigger="click" visible={popoverVisible} onVisibleChange={handleVisibleChange}>
        <MenuOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
      </Popover>
      <Export
        dialId={dialId}
        resolution={screen_resolution}
        size={size}
        series={series}
        history={history}
        getI18nMessage={getI18nMessage}
        filename={exportFilename}
        dialNameEditable={dialNameEditable}
        exportType={exportType}
        multiLangList={multiLangList}
        initRender={preImgRender}
        savePreImg={savePreImg}
      />
      <Modal
        title={<T id="tips" />}
        closeIcon={null}
        centered
        closable={false}
        visible={checkImgContent}
        footer={
          <Button
            onClick={() => {
              setState({ ...state, checkImgContent: null })
            }}
          >
            <T id="ok" />
          </Button>
        }
        className="watch-dial-modal watch-dial-tip-modal"
      >
        {checkImgContent}
      </Modal>
      <Modal
        wrapClassName="install-modal"
        title={null}
        footer={null}
        width={310}
        centered
        visible={showNewFeatQr}
        onCancel={() => setShowNewFeatQr(false)}
      >
        <div className="qrcode-main">
          <QRCodeSVG size={256} level="Q" includeMargin value={qrUrl} />
          <div className="qrcode-tips">
            <span className="qrcode-tips-gap">
              <T id="install_tips1" />
            </span>
            <a target="_blank" rel="noreferrer" href={scanCodeLink}>
              <T id="install_tips2" />
            </a>
          </div>
        </div>
      </Modal>
      <ExportImageModal
        visible={exportImageModalVisible}
        sizes={getSizes()}
        languages={languages}
        dialType={dialType}
        allData={allData}
        onClose={() => setExportImageModalVisible(false)}
        handleInitRender={handleInitRender}
        shape={shape}
        radius={radius}
        getI18nMessage={getI18nMessage}
      />
      <Modal
        title={<T id="tips" />}
        closeIcon={null}
        centered
        closable={false}
        visible={confirmExportModalVisible}
        footer={[
          <Button
            key="cancel-btn"
            onClick={() => {
              setConfirmExportModalVisible(false)
            }}
          >
            <T id="cancel_export" />
          </Button>,
          <Button
            key="confirm-btn"
            onClick={() => {
              setConfirmExportModalVisible(false)
              registerAppIdAndExport()
            }}
          >
            <T id="confirm_export" />
          </Button>
        ]}
        className="watch-dial-modal"
      >
        <T id="invalid_token_export_tip" />
      </Modal>
      {/* os export-modal */}
      <Modal
        className="watch-dial-modal"
        destroyOnClose
        title={<T id="export" />}
        visible={exportModalVisible}
        cancelText={<T id="cancel" />}
        okText={<T id="do_export" />}
        confirmLoading={exportConfirmLoading}
        onCancel={() => {
          setExportModalVisible(false)
          setExportConfirmLoading(false)
          if (preImageToggle) {
            setImgMapper({})
          }
        }}
        onOk={() => {
          setExportConfirmLoading(true)
          checkExportModalValid()
        }}
      >
        <div className="watch-dial-tip-modal confirm-btn-centered">
          <div className="warch-dial-zoom-content">
            {/* {shape === 'round' ? <Row className="ant-form-item">
              <Col span="8" className="ant-form-item-label">
                <label className="form-lable">
                  <div className="label-len-limit">
                    <T id="automatic_adaptation" />
                  </div>
                </label>
              </Col>
              <Col span="16">
                <Radio.Group
                  onChange={handleAdaptTypeChange}
                  options={[{
                    label: <T id="yes" />,
                    value: 1
                  }, {
                    label: <T id="no" />,
                    value: 0
                  }]}
                  value={adaptType}
               />
              </Col>
            </Row> : null} */}
            {autoAdapt ? null : <Row className="ant-form-item">
              <Col span="8" className="ant-form-item-label">
                <label className="form-lable">
                  <div className="label-len-limit">
                    <T id="support_devices" />
                  </div>
                </label>
              </Col>
              <Col span="16">
                {Array.isArray(checkedDevices) ? (
                  <Checkbox.Group options={supportDevices} value={checkedDevices} onChange={handleExportCheckChange} />
                ) : null}
              </Col>
            </Row>}
            {preImageToggle && (
              <FillPreImgModal
                multiLangList={multiLangList}
                shape={shape}
                radius={radius}
                screenResolution={screen_resolution}
                previewResolution={preview_resolution}
                handleChange={handleChange}
                initRender={preImgRender}
                defaultPreviewMode={hasEditComponentInConfig(allData?.get('normal')) ? 0 : 1}
              />
            )}
          </div>
        </div>
      </Modal>
      <Modal
        className="watch-dial-modal"
        destroyOnClose
        title={<T id="add_preview_tip" />}
        visible={showFillPreviewImageModal}
        cancelText={<T id="cancel" />}
        okText={<T id="confirm" />}
        onCancel={() => {
          setShowFillPreviewImageModal(false)
          setImgMapper({})
        }}
        onOk={() => {
          const imgValid = checkPreImage('imgMapper')

          if (!imgValid) {
            message.error('Please upload preview images for all languages')
            return
          }

          savePreImg(imgMapper, () => {
            saveDesigner({
              callback: () => {
                executeCbAfterFillPreviewImg()
                setShowFillPreviewImageModal(false)
                setImgMapper({})
              }
            })
          })
        }}
      >
        <div className="watch-dial-tip-modal confirm-btn-centered">
          <div className="warch-dial-zoom-content">
            <FillPreImgModal
              multiLangList={multiLangList}
              shape={shape}
              radius={radius}
              screenResolution={screen_resolution}
              previewResolution={preview_resolution}
              handleChange={handleChange}
              initRender={preImgRender}
              defaultPreviewMode={hasEditComponentInConfig(allData?.get('normal')) ? 0 : 1}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default OperationMenu
