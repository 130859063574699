/* eslint-disable import/no-mutable-exports */
import { getSLD } from '@watchface/utils'

// 二级域名是否是 zepp
const sldIsZepp = getSLD() === 'zepp'

const apiProductionHost = {
  apiUser: 'https://api-user.huami.com/',
  account: 'https://account.huami.com/',
  zuul: 'https://auth-cn2.huami.com/',
  contUrl: 'https://auth.huami.com/',
  apiWatch: () => `https://${localStorage.getItem('wf_baseUrl')}/`,
  apiWatchAduit: 'https://api-mifit-cn2.huami.com/',
  getImageHost: 'https://circle-prod.cdn.bcebos.com/',
  workerLink: 'https://api.huami.com/t/lightapp.bundle.worker',
  userInfoHost: 'https://api-mifit.huami.com/',
  openplatform: 'https://console.zepp.com/',
  universalLogin: sldIsZepp ? 'https://user.zepp.com/universalLogin/' : 'https://user.huami.com/universalLogin/',
  zeppOsDoc: 'https://docs.zepp.com/'
}

const apiTestingHost = {
  apiUser: 'https://api-user-staging.huami.com/',
  account: 'https://account-staging.huami.com/',
  zuul: 'https://auth-staging-cn2.huami.com/',
  contUrl: 'https://auth-staging-cn2.huami.com/',
  apiWatch: () => `https://${localStorage.getItem('wf_baseUrl')}/`,
  apiWatchAduit: 'https://api-aos-testing-cn.huami.com/',
  getImageHost: 'https://circle-staging.cdn.bcebos.com/',
  workerLink: 'https://api-mifit-staging.huami.com/t/lightapp.bundle.worker',
  userInfoHost: 'https://api-mifit-staging.huami.com/',
  openplatform: 'https://console-staging.zepp.com/',
  universalLogin: sldIsZepp ? 'https://user-testing.zepp.com/universalLogin/' : 'https://user-testing.huami.com/universalLogin/',
  zeppOsDoc: 'https://docs-testing.zepp.com/'
}

let apiHost = apiProductionHost
const env = process.env.NODE_ENV

switch (env) {
  case 'production':
    apiHost = apiProductionHost
    break
  case 'development':
    apiHost = apiTestingHost
    break
  case 'test':
    apiHost = apiTestingHost
    break
  default:
    apiHost = apiProductionHost
    break
}

export default apiHost
