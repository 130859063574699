import Immutable from 'immutable'

const COMMON_BACKGROUND = Immutable.fromJS({
  type: 'image',
  image: '',
  color: '0xFFFFFFFF'
})

const IDLE_BACKGROUND = Immutable.fromJS({
  image: ''
})

const POSITION = Immutable.fromJS({
  x: 0,
  y: 0
})

const FLOAT = Immutable.fromJS({
  position: {
    x: 0,
    y: 0
  },
  image: ''
})

export const EDITABLE_BG = Immutable.fromJS({
  bg_images: [],
  preview_images: [],
  fg_image: '',
  count: 2,
  tip_image: '',
  tip_position: {
    x: 0,
    y: 0
  }
})

// 是否是息屏表盘
// 能否设置背景图片坐标
// 是否包含浮层图片
export default function createData({ isIdle, canSetPosition, hasFloat, hasRadius, hasEditableBg }) {
  if (isIdle) {
    return IDLE_BACKGROUND
  }
  let background = COMMON_BACKGROUND
  if (canSetPosition) {
    background = background.set('position', POSITION)
  }
  if (hasFloat) {
    background = background.set('float', FLOAT)
  }
  if (hasRadius) {
    background = background.set('radius', 0)
  }
  if (hasEditableBg) {
    background = background.set('editable_bg', EDITABLE_BG)
    background = background.set('bg_type', '')
  }
  return background.toJS()
}
