export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.WATCHFACE_EDIT_BG,{
      edit_id: {{ editId }},
      x: {{ bg.x }},
      y: {{ bg.y }},
      bg_config: {{{ config }}},
      count: {{ bg.count }},
      default_id: 1,
      {{ #bg.fg }}
      fg: '{{ bg.fg }}',
      {{ /bg.fg }}
      tips_x: {{ tips.x }},
      tips_y: {{ tips.y }},
      {{ #tips.image }}
      tips_bg: '{{{ tips.image }}}',
      {{ /tips.image }}
      show_level: hmUI.show_level.ONLY_NORMAL | hmUI.show_level.ONLY_EDIT
    });
  `
