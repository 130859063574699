export default `
    {{{ variable }}} = hmUI.createWidget(hmUI.widget.IMG_DATE, {
      {{ #data }}
      {{ ^follow }}
      {{ key }}_startX: {{ position.x }},
      {{ key }}_startY: {{ position.y }},
      {{ /follow }}
      {{ #imageArraySC }}
      {{ key }}_sc_array: {{{ imageArraySC }}},
      {{ /imageArraySC }}
      {{ #imageArrayTC }}
      {{ key }}_tc_array: {{{ imageArrayTC }}},
      {{ /imageArrayTC }}
      {{ #imageArrayEN }}
      {{ key }}_en_array: {{{ imageArrayEN }}},
      {{ /imageArrayEN }}
      {{ #unitSC }}
      {{ key }}_unit_sc: '{{{ unitSC }}}',
      {{ /unitSC }}
      {{ #unitTC }}
      {{ key }}_unit_tc: '{{{ unitTC }}}',
      {{ /unitTC }}
      {{ #unitEN }}
      {{ key }}_unit_en: '{{{ unitEN }}}',
      {{ /unitEN }}
      {{ ^follow }}
      {{ key }}_align: {{ align }},
      {{ /follow }}
      {{ key }}_zero: {{ padding_zero }},
      {{ #showFollow }}
      {{ key }}_follow: {{ follow }},
      {{ /showFollow }}
      {{ ^follow }}
      {{ key }}_space: {{ interval }},
      {{ /follow }}
      {{ #system_font }}
      {{ key }}_color: {{ system_font.color }},
      {{ key }}_font_size: {{ system_font.font_size }},
      {{ /system_font }}
      {{ key }}_is_character: {{ is_character }},
      {{ /data }}
      enable: false,
      show_level: {{ showLevel }},
    });
  `
