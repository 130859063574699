import T from '@watchface/components/I18n'
import { Col, Radio, Row, Space } from 'antd'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React from 'react'

export interface Client {
  clientId: string
  type: 'development' | 'debug'
  name: string
  ip: string
  status: string
  own: boolean
}

interface Props {
  clients: Client[]
  action: string
  connectedClient: Client | undefined
  connectClient: (client: Client) => void
  handleActionChange: (e: any) => void
}

export const actionMap = {
  INSTALL_PKG: 'installPkg', // 安装表盘包
  SCREENSHOT: 'screenshot' // 截取手表设备屏幕
}

const BridgePage: React.FunctionComponent<Props> = (props) => {
  const { action, clients, connectedClient, handleActionChange, connectClient } = props
  const clientsFilter = clients.filter((client: Client) => client.type === 'debug')

  return (
    <div className="bridge-page">
      <Row className="bridge-page-row">
        <Col>
          <T id="device_or_simulator" />：
        </Col>
        <Col>
          {clientsFilter.length > 0 ? (
            clientsFilter.map((client: Client) => {
              const { clientId, name } = client
              const connected = connectedClient?.clientId === clientId

              return (
                <div key={clientId} className="device-item">
                  <span>{name}</span>
                  <span
                    className={classNames('device', {
                      'device-connected': connected
                    })}
                    onClick={debounce(() => !connected && connectClient?.(client), 300)}
                  >
                    {connected ? <T id="connected" /> : <T id="connect" />}
                  </span>
                </div>
              )
            })
          ) : (
            <T id="no_device_connected_tip" />
          )}
        </Col>
      </Row>
      <Row className="bridge-page-row">
        <Col>
          <T id="bridge_action" />：
        </Col>
        <Col>
          <Radio.Group value={action} onChange={handleActionChange}>
            <Space direction="vertical">
              <Radio value={actionMap.INSTALL_PKG}>
                <T id="install_watch_face" />
              </Radio>
              {process.env.NODE_ENV === 'production' ? null : (
                <Radio value={actionMap.SCREENSHOT}>
                  <T id="watch_screenshot" />
                </Radio>
              )}
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  )
}

export default BridgePage
