import { ColorPicker } from '@watchface/components'
import { Col, Divider, Row } from 'antd'
import * as React from 'react'

interface Props {
  properties: {
    color: { value: string }
  }
  onChange: (category: string, property: string, value: any) => void
}

const Color: React.FC<Props> = (props) => {
  const {
    properties: {
      color: { value: color }
    },
    onChange
  } = props

  const handleColorChange = (color: { hex: string }) => {
    const value = color.hex
    onChange('color', 'color', value ? `0xFF${value.slice(1)}` : '')
  }

  const handleColorInputChange = (value: string) => {
    handleColorChange({ hex: value })
  }

  return (
    <div className="meta-form meta-color">
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={12}>
          <ColorPicker
            width={120}
            color={color ? `#${color.slice(4)}` : ''}
            onInputChange={handleColorInputChange}
            onChangeComplete={handleColorChange}
          />
        </Col>
        <Col span={12} />
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Color
