/* eslint-disable */
import { Checkbox, Col, Divider, Radio, Row } from 'antd'
import React, { useState, useRef, useEffect } from 'react'
import T from '../../I18n'

enum SPAN {
  COL1 = 6,
  COL2 = 18
}

interface IProps {
  sizes: string[]
  deviceSources: string[]
  onChange: (selectedSizes: string[], showStrap: boolean, device: string) => void
}

const SizeCheckBoxGroup: React.FC<IProps> = (props) => {
  const { sizes = [], deviceSources, onChange } = props
  const [selectedSizes, setSelectedSizes] = useState<string[]>([])
  const [showWatchStrap, setShowWatchStrap] = useState<boolean>(false)
  const [selectedDeviceSource, setSelectedDeviceSource] = useState<string>('')
  const selectedSizeIndexes = useRef<number[]>([0])

  const watchStrapOptions: any = [
    { label: <T id="no" />, value: false },
    { label: <T id="yes" />, value: true }
  ]
  const deviceSourceOptions: any = deviceSources.map((deviceSource: any) => ({
    label: deviceSource,
    value: deviceSource
  }))

  const [screenResolution, ...restSizes] = sizes
  const defaultDeviceSource = deviceSources?.[0]
  // 暂时隐藏选择是否带外框图
  // const showStrapRadio = selectedSizes.includes(screenResolution)
  const showStrapRadio = false

  useEffect(() => {
    setSelectedSizes([screenResolution])
    selectedSizeIndexes.current = [0]
  }, [screenResolution])

  useEffect(() => {
    defaultDeviceSource && setSelectedDeviceSource(defaultDeviceSource)
  }, [defaultDeviceSource])

  const handleChange = (e: any, index: number) => {
    const checked = e?.target?.checked

    if (checked) {
      selectedSizeIndexes.current.push(index)
      selectedSizeIndexes.current = Array.from(new Set(selectedSizeIndexes.current))
    } else {
      selectedSizeIndexes.current = selectedSizeIndexes.current.filter((item: number) => item !== index)
    }

    const result = selectedSizeIndexes.current.reduce((pre: string[], currIndex: number) => {
      pre.push(sizes[currIndex])
      return pre
    }, [])

    setSelectedSizes(result)

    onChange(result, showWatchStrap, selectedDeviceSource)
  }

  return (
    <>
      <Checkbox
        defaultChecked
        onChange={(e: any) => {
          setShowWatchStrap(false)
          handleChange(e, 0)
        }}
      >
        {screenResolution}
      </Checkbox>
      {showStrapRadio ? (
        <>
          <Row style={{ margin: '5px 0' }}>
            <Col className="conf-item-name" span={SPAN.COL1}>
              <T id="show_the_strap" />
            </Col>
            <Col span={SPAN.COL2}>
              <Radio.Group
                options={watchStrapOptions}
                defaultValue={false}
                onChange={(e: any) => {
                  const value = Boolean(e?.target?.value)
                  setShowWatchStrap(value)
                  onChange(selectedSizes, value, selectedDeviceSource)
                }}
              />
            </Col>
          </Row>
          {showWatchStrap ? (
            <Row>
              <Col className="conf-item-name" span={SPAN.COL1}>
                <T id="devices" />
              </Col>
              <Col span={SPAN.COL2}>
                <Radio.Group
                  key={deviceSourceOptions.size}
                  defaultValue={deviceSources[0]}
                  options={deviceSourceOptions}
                  onChange={(e: any) => {
                    const value = e?.target?.value
                    setSelectedDeviceSource(value)
                    onChange(selectedSizes, showWatchStrap, value)
                  }}
                />
              </Col>
            </Row>
          ) : null}
          <Divider />
        </>
      ) : null}
      {restSizes.map((size: string, index: number) => (
        <Checkbox key={size} onChange={(e: any) => handleChange(e, index + 1)}>
          {size}
        </Checkbox>
      ))}
    </>
  )
}

export default SizeCheckBoxGroup
