const TOP_BAR = [
  {
    name: 'background',
    icon: 'icon-tuceng-beijing',
    items: ['background']
  },
  {
    name: 'time',
    icon: 'icon-tuceng-zhizhenshijian',
    items: ['analog_clock', 'digital_clock', 'alarm_clock', 'date', 'week']
  },
  {
    name: 'workout_health',
    icon: 'icon-tuceng-xinshuai',
    items: ['heart_rate', 'calorie', 'pai', 'stand', 'step', 'distance']
  },
  {
    name: 'weather',
    icon: 'icon-tuceng-tianqi',
    items: ['weather', 'temperature', 'aqi', 'sunrise', 'sunset', 'humidity', 'uvi']
  },
  {
    name: 'system',
    icon: 'icon-tuceng-xitong',
    items: ['system', 'battery', 'shortcut']
  },
  {
    name: 'animation',
    icon: 'icon-tuceng-xitong',
    items: ['animation']
  }
]

export default TOP_BAR
