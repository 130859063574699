/**
 * @name 多语言平台接入hooks
 * @description 通过
 * @example
 * 需要在页面引入多语言sdk
 * https://fe-cdn.huami.com/locale/main.js?timestamp=new Date().getTime()
 * https://fe-testing-cdn.huami.com/locale/main.js?timestamp=new Date().getTime()
 */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

interface LocalInfoT {
  currentLang: string // 当前语言
  lang: string // 语言简写
  countryLang: string // 语言和国家
  defaulconstng: string // 默认语言
  projectDefault: string // 项目指定语言，权重最高
}

const defaultLang = 'zh'
const { localeLang, getLocaleLanguage } = window // 多语言

// json 数据平铺兼容性处理
const flattenMessages = (nestedMessages: any, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    let newKey = key
    if (!Number.isNaN(key) && !Number.isNaN(parseFloat(key))) {
      newKey = `[${key}]`
    }
    const prefixedKey = prefix ? `${prefix}.${newKey}` : newKey

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }
    return messages
  }, {})
}

export default function useLocale(project: string, specifyDefalut?: string, projectDefault?: string, isFlatten?: boolean) {
  const dispatch = useDispatch()
  const [langInfo] = useState(() => {
    if (getLocaleLanguage) {
      return getLocaleLanguage(window.navigator, defaultLang)
    }
  })
  // TODO 页面多语言数据首屏数据展示，由于多语言数据通过异步请求获取，页面首次展示存在重绘情况
  const [localeData, setLocaleData] = useState({})

  useEffect(() => {
    const { currentLang = window.navigator.language } = langInfo ?? {}
    const currentDefaultLang = specifyDefalut || defaultLang
    const targetLang = projectDefault || currentLang
    console.log('useLocale', currentLang, currentDefaultLang, targetLang, project)
    if (localeLang) {
      localeLang(window.navigator, project, currentDefaultLang, targetLang)
        .then((data: any) => {
          if (data && data.code === 'NoSuchKey') {
            setLocaleData({})
            return
          }
          let newData = data
          if (!isFlatten) {
            newData = flattenMessages(data)
          }
          setLocaleData(newData)
          dispatch({
            type: 'UPDATE_INTLDATA',
            payload: newData
          })
        })
        .catch((err: any) => console.log(err.message))
    }
  }, [dispatch, langInfo, project, specifyDefalut, projectDefault, isFlatten])

  return [localeData, langInfo]
}
