import { fromJS } from 'immutable'
import {
  UPDATE_APP_ID,
  UPDATE_AUTHOR_ID,
  UPDATE_DEVICE_INFO,
  UPDATE_DEVICE_SOURCE,
  UPDATE_WATCH_FACE_INFO,
  UPDATE_WATCH_FACE_LANGUAGE,
  UPDATE_WATCH_FACE_NAME
} from '../actionTypes/index'

const initialState: any = fromJS({
  name: '',
  appId: '',
  osType: 'zeppOS',
  appType: 'watchface',
  deviceSource: [],
  deviceInfo: {
    name: '',
    width: null,
    height: null,
    shape: '',
    radius: null
  },
  language: [],
  authorId: null,
  description: '',
  createTime: null,
  updateTime: null
})

const reducer = (state = initialState, action: { type: string; payload: any }) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_WATCH_FACE_NAME: {
      return state.set('name', payload)
    }
    case UPDATE_APP_ID: {
      return state.set('appId', payload)
    }
    case UPDATE_AUTHOR_ID: {
      return state.set('authorId')
    }
    case UPDATE_DEVICE_SOURCE: {
      return state.set('deviceSource', payload)
    }
    case UPDATE_DEVICE_INFO: {
      return state.get('deviceInfo').merge(payload)
    }
    case UPDATE_WATCH_FACE_LANGUAGE: {
      return state.set('language', payload)
    }
    case UPDATE_WATCH_FACE_INFO: {
      return state.merge(payload)
    }
    default:
      return state
  }
}

export default reducer
