import { convertJson2FabricText } from '@watchface/pages/DesignTool/NonZeppOs/components/TextComponent/config'
import {
  convertDigitalAmPm2FabricImage,
  convertDigitalClock2FabricImage
} from '@watchface/pages/DesignTool/NonZeppOs/ZeppOsComponents/DigitalClock/config'
import { convertJson2FabricImage } from '@watchface/pages/DesignTool/NonZeppOs/ZeppOsComponents/ImageComponent/config'
import Action from './Action'
import Alignment from './Alignment'
import Animation from './Animation'
import BackgroundType from './BackgroundType'
import Color from './Color'
import ComplicationPreview from './ComplicationPreview'
import Dimension from './Dimension'
import Image from './Image'
import ImageGroup from './ImageGroup'
import Name from './Name'
import Placement from './Placement'
import PreviewText from './PreviewText'
import ProgressBarType from './ProgressBarType'
import Range from './Range'
import Rotate from './Rotate'
import Text from './Text'
import TextAppearance from './TextAppearance'

const META_COMPONENT_MAP = {
  action: Action,
  alignment: Alignment,
  animation: Animation,
  backgroundType: BackgroundType,
  color: Color,
  complicationPreview: ComplicationPreview,
  dimension: Dimension,
  image: Image,
  imageGroup: ImageGroup,
  name: Name,
  placement: Placement,
  progressBarType: ProgressBarType,
  range: Range,
  rotate: Rotate,
  text: Text,
  textAppearance: TextAppearance,
  previewText: PreviewText
}

const CONVERT_TO_FABRIC_OBJECT_MAP: any = {
  text: convertJson2FabricText,
  image: convertJson2FabricImage,
  digitalClockHour: convertDigitalClock2FabricImage,
  digitalClockMinute: convertDigitalClock2FabricImage,
  digitalClockSecond: convertDigitalClock2FabricImage,
  digitalClockAm: convertDigitalAmPm2FabricImage,
  digitalClockPm: convertDigitalAmPm2FabricImage
}

export { CATEGORY_ORDER, DATA_TYPE, DIAL_TYPE, DIGITAL_CLOCK_SUPPORTED_FOLLOW_KEY_LIST, JSON2_SUPPORTED_KEY, TYPE_TO_ICON } from './constants'
export {
  filterWidgetCategoryImageByLang,
  getKeyOrIdFromStr,
  getKeyPathFromMenu,
  getMenuFromWidget,
  isWidgetFabricKey,
  isWidgetMenuKey
} from './utils'
export { META_COMPONENT_MAP, CONVERT_TO_FABRIC_OBJECT_MAP }
