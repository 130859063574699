// import { FormOutlined } from '@ant-design/icons'
import T from '@watchface/components/I18n'
import { Button, Input, message } from 'antd'
import type { Map as ImmutableMap } from 'immutable'
import Immutable from 'immutable'
import React, { useState } from 'react'
import { osAppIdIsLtStartingAppId } from '../../utils'

import './index.scss'

interface WatchInfoProps {
  asset: ImmutableMap<string, any>
  // isZeppOS: boolean
  handleChangeAssets: (path: string[], data: any) => void
}

const AppIdEditor = (props: WatchInfoProps): JSX.Element | null => {
  const { asset = Immutable.Map(), /* isZeppOS = false, */ handleChangeAssets } = props
  const appId = osAppIdIsLtStartingAppId(asset.get('appId')) ? '' : asset.get('appId')
  const [state, setState] = useState<any>({
    editAppId: false,
    appIdInState: appId
  })
  const { editAppId, appIdInState } = state
  // const editable = isZeppOS
  const toggleAppIdEditable = () => {
    setState({
      ...state,
      editAppId: !editAppId
    })
  }

  // const editPenIcon = editable ? <FormOutlined onClick={toggleAppIdEditable} /> : null
  const editPenIcon = null
  const changeAppIdInput = (e: any) => {
    const { value } = e.target

    setState({
      ...state,
      appIdInState: value?.trim()
    })
  }

  const changeAppId = () => {
    const newAppId = appIdInState ?? appId
    const isNumber = /^\d+$/.test(newAppId)

    if (!isNumber) {
      message.error('appId must be a number', 5)

      return
    }

    handleChangeAssets([], asset.set('appId', newAppId))
    toggleAppIdEditable()
  }

  const handleCancel = () => {
    setState({
      ...state,
      appIdInState: undefined,
      editAppId: !editAppId
    })
  }

  return (
    <div className="info-name-wrapper">
      <span className="info-name">
        <T id="appId" />：
        {editAppId ? (
          <Input value={appIdInState ?? appId} onChange={changeAppIdInput} style={{ width: 100, height: 22 }} />
        ) : (
          appId || <T id="no_data" />
        )}
      </span>
      {editAppId ? (
        <>
          <Button className="appid-edit-btn" type="default" size="small" onClick={changeAppId}>
            <T id="confirm" />
          </Button>
          <Button className="appid-edit-btn" type="default" size="small" onClick={handleCancel}>
            <T id="cancel" />
          </Button>
        </>
      ) : (
        editPenIcon
      )}
    </div>
  )
}

export default AppIdEditor
