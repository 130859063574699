import { Animation } from '@watchface/pages/DesignTool/NonZeppOs/components'
import React from 'react'

class FrameAnimation extends React.Component<any> {
  static renderLayer(...args: any) {
    Animation.renderLayer(...args)
  }

  render() {
    const { childKey } = this.props
    return <Animation key={childKey} {...this.props} />
  }
}

export default FrameAnimation
