const hrMap = {
  sensorId: 'HEART',
  eventName: 'LAST',
  dataField: 'last',
  valueCount: 3
}

const monthMap = {
  eventName: 'DAYCHANGE',
  sensorId: 'TIME',
  dataField: 'month',
  valueCount: 2,
  eventType: 'jstime'
}

const dayMap = {
  eventName: 'DAYCHANGE',
  sensorId: 'TIME',
  dataField: 'day',
  valueCount: 2,
  eventType: 'jstime'
}

const weekMap = {
  eventName: 'DAYCHANGE',
  sensorId: 'TIME',
  dataField: 'week',
  valueCount: 1,
  eventType: 'jstime'
}

const formatterWeekENFull = function (val) {
  const valueMap = {
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
    '7': 'Sunday'
  }
  return valueMap[val]
}

const formatterWeekENShort = function (val) {
  const valueMap = {
    '1': 'Mon',
    '2': 'Tue',
    '3': 'Wed',
    '4': 'Thu',
    '5': 'Fri',
    '6': 'Sat',
    '7': 'Sun'
  }
  return valueMap[val]
}

// 函数配置先按照 { name, funcStr } 格式配置，解决产线 webpack 打包丢失方法名问题，待各个 repo 拆分后，可更改 babel 处理方式解决
const SENSOR_TAG_MAP = {
  '[HR_Z]': { ...hrMap, leadingZero: true },
  '[HR]': hrMap,
  '[CAL]': { eventName: 'CHANGE', sensorId: 'CALORIE', dataField: 'current', isCommonEventSensor: true },
  '[SC]': { eventName: 'CHANGE', sensorId: 'STEP', dataField: 'current', isCommonEventSensor: true },
  '[BATT_PER]': { eventName: 'CHANGE', sensorId: 'BATTERY', dataField: 'current', isCommonEventSensor: true },
  '[YEAR]': { eventName: 'DAYCHANGE', sensorId: 'TIME', dataField: 'year' },
  '[MON]': monthMap,
  '[MON_Z]': { ...monthMap, leadingZero: true },
  '[DAY]': dayMap,
  '[DAY_Z]': { ...dayMap, leadingZero: true },
  '[HOUR_24]': { eventName: 'MINUTEEND', eventType: 'jstime', sensorId: 'TIME', dataField: 'hour' },
  '[HOUR_24_Z]': { eventName: 'MINUTEEND', eventType: 'jstime', sensorId: 'TIME', dataField: 'hour', valueCount: 2, leadingZero: true },
  '[MIN]': { eventName: 'SECONDCHANGE', eventType: 'jstime', sensorId: 'TIME', dataField: 'minute' },
  '[MIN_Z]': { eventName: 'SECONDCHANGE', eventType: 'jstime', sensorId: 'TIME', dataField: 'minute', valueCount: 2, leadingZero: true },
  '[SEC]': { eventName: 'SECONDCHANGE', eventType: 'jstime', sensorId: 'TIME', dataField: 'second' },
  '[SEC_Z]': { eventName: 'SECONDCHANGE', eventType: 'jstime', sensorId: 'TIME', dataField: 'second', valueCount: 2, leadingZero: true },
  '[WEEK_EN_F]': { ...weekMap, formatter: formatterWeekENFull },
  '[WEEK_EN_S]': { ...weekMap, formatter: formatterWeekENShort},
}

export default SENSOR_TAG_MAP
