import React from 'react'
import Immutable from 'immutable'
import classNames from 'classnames'
import { Button, Form, Input, Pagination } from 'antd'
import { FormInstance } from 'antd/es/form'
import { debounce } from 'lodash'
import T from '@watchface/components/I18n'
import CascaderPicker from '@watchface/components/CascaderPicker'
import { DIAL_PAGE_SIZE } from '@watchface/constants'
import { DialData } from '@watchface/store/actions'
import { immutableSelector } from '@/utils/index'
import DialItem from './DialItem'

interface DialProps {
  title: string
  dialData: DialData & Immutable.Map<string, any>
  supportDevices: any
  fetchDial: (...args: any) => void
  deleteDial: (dialId: number, cb: unknown) => void
  copyDial: (dialId: number, dialName: string, copyDeviceId: number, isZeppOS: boolean, cb: unknown) => void
}

interface DialState {
  searchWord: string
  support_ids: string
  current: number
}

interface Item {
  label: any
  value: any
}
class Dial extends React.Component<DialProps, DialState> {
  formRef = React.createRef<FormInstance>()

  constructor(props: DialProps) {
    super(props)
    this.state = {
      searchWord: '',
      support_ids: '',
      current: 1
    }
  }

  componentDidMount() {
    this.fetchData(1)
  }

  onFinish = debounce((values?: any) => {
    const { supportDevices } = this.props
    const { keyword, resolution } = values || this.formRef.current?.getFieldsValue(true) || {}
    const [targetOsType, targetResolution] = resolution || []
    const support_ids_arr = supportDevices
      .toJS()
      .filter((device: any) => {
        const bool1 = targetResolution ? targetResolution === device.screen_resolution : true
        const bool2 = targetOsType ? targetOsType.includes(device.series) : true
        return bool1 && bool2
      })
      .map((device: any) => device.id)
    const support_ids = support_ids_arr.length ? support_ids_arr.join(',') : '-1'
    this.setState(
      {
        searchWord: keyword?.trim(),
        support_ids
      },
      () => {
        this.fetchData(1)
      }
    )
  }, 700)

  fetchData = (page: number) => {
    const { fetchDial } = this.props
    const { searchWord, support_ids } = this.state
    this.setState({ current: page })
    fetchDial(page, DIAL_PAGE_SIZE, searchWord, support_ids)
  }

  handleKeyWordChange = (e: any) => {
    const value = e?.target.value
    this.formRef.current?.setFieldsValue({
      value
    })
    this.onFinish()
  }

  handleResolutionChange = (value: any) => {
    this.formRef.current?.setFieldsValue({
      resolution: value
    })
    this.onFinish()
  }

  reset = () => {
    this.formRef.current?.resetFields()
    this.onFinish()
  }

  getOptions = (deviceList: any[]) => {
    const options = [
      {
        value: 'js',
        label: 'Zepp OS',
        children: [
          {
            label: 'All',
            value: ''
          }
        ]
      },
      {
        value: 'gt2,como,ness',
        label: 'Non Zepp OS',
        children: [
          {
            label: 'All',
            value: ''
          }
        ]
      }
    ]
    options.forEach((item: any) => {
      const arr = deviceList.filter((i) => item.value.indexOf(i?.series) > -1)
      if (arr.length > 0) {
        const list: Item[] = arr
          .map((t) => {
            return {
              value: t.screen_resolution,
              label: t.screen_resolution
            }
          })
          .sort((a: Item, b: Item) => (a.label < b.label ? 1 : -1))

        item.children.push(...list)
      }
    })

    return options
  }

  render() {
    const { title, dialData, supportDevices, deleteDial, copyDial } = this.props
    const { current } = this.state
    const deviceList = supportDevices.toJS()
    const options = this.getOptions(deviceList)
    const { data } = immutableSelector(dialData)
    const total: any = dialData?.getIn(['metas', 'pagination', 'total']) || 0
    const noDataText =
      data?.size === 0 ? (
        <div className="no-data">
          <T id="no_data" />
        </div>
      ) : null
    const pageCount = Math.ceil(total / DIAL_PAGE_SIZE)

    return (
      <div className="dial">
        <div className="dial-header">
          <div className="dial-title">{title}</div>
          <div className="dial-search">
            <Form layout="inline" ref={this.formRef} name="search_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
              <Form.Item name="resolution" label={<T id="watch_resolution" />}>
                <CascaderPicker options={options} onChangeComplete={this.handleResolutionChange} allowClear />
              </Form.Item>
              <Form.Item name="keyword" label={<T id="watch_keyword" />}>
                <Input allowClear onChange={this.handleKeyWordChange} />
              </Form.Item>
              <Form.Item>
                <Button type="default" danger onClick={this.reset}>
                  <T id="reset" />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div
          className={classNames('dial-items', {
            'dial-items-more-page': pageCount > 1
          })}
        >
          {data?.toJS()?.map((dial: any) => (
            <DialItem
              key={dial.id}
              data={dial}
              supportDevices={supportDevices}
              copyDial={(dialId: number, dialName: string, copyDeviceId: number, isZeppOS: boolean) => {
                copyDial(dialId, dialName, copyDeviceId, isZeppOS, this.fetchData.bind(this, 1))
              }}
              deleteDial={(dialId: number) => {
                deleteDial(dialId, this.fetchData.bind(this, 1))
              }}
            />
          ))}
        </div>
        {noDataText}
        {total > 0 && (
          <Pagination
            className="dial-list-pagination"
            total={total}
            current={current}
            pageSize={DIAL_PAGE_SIZE}
            showSizeChanger={false}
            onChange={(page) => this.fetchData(page)}
          />
        )}
      </div>
    )
  }
}

export default Dial
