import React from 'react'
import { NumberComponent, NumberComponentWithRect, IconComponent, JumpHotAreaComponent } from '../../components'

const componentMap = (hasRectArea) => ({
  text: hasRectArea ? NumberComponentWithRect : NumberComponent,
  icon: IconComponent,
  jumpable: JumpHotAreaComponent
})

class Distance extends React.Component {
  static renderLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const Component = componentMap(hasRectArea)[childKey]
    Component.renderLayer({ childKey, ...restArgs })
  }

  static removeLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const Component = componentMap(hasRectArea)[childKey]

    if (Component && Component.removeLayer) {
      Component.removeLayer({ childKey, ...restArgs })
    }
  }

  render() {
    const { childKey, propMap } = this.props
    const prop = propMap[childKey] || {}
    const { hasRectArea } = prop
    const Component = componentMap(hasRectArea)[childKey]
    const allProps = { ...this.props, ...prop }

    if (childKey === 'text') {
      const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent

      return (
        <div className="control-container">
          <NumComponent {...allProps} />
        </div>
      )
    }

    return <div className="control-container">{Component && <Component {...allProps} />}</div>
  }
}

export default Distance
