/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
/**
 * @param {string} url
 * @returns {Object}
 */
// eslint-disable-next-line import/prefer-default-export
export function param2Obj(url: string): any {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(`{"${decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ')}"}`)
}

export function isImmutable(value: { toJS: any; get: any }) {
  return value && typeof value.toJS === 'function' && typeof value.get === 'function'
}

export function immutableSelector(data: any, ...arr: string[]) {
  const obj: any = {}
  if (!isImmutable(data)) {
    // 兼容object格式数据，原样返回
    return data && typeof data === 'object' ? data : obj
  }
  const keys: string[] = Array.isArray(arr) && arr[0] ? arr : data.keySeq().toArray()
  keys.forEach((key) => {
    obj[key] = data.get(key)
  })
  return obj
}

interface UserInfo {
  userid: string
  apptoken: string
  nickName: string
  login_token: string
  contact: string
  iconUrl: string
  country?: string
  userIntroduction?: string
  third_id?: string
  examineType?: string
}

export function setUserInfo(params: UserInfo) {
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      localStorage.setItem(key, value)
    }
  }
}

export function getUserInfo() {
  const userid = localStorage.getItem('userid')
  const apptoken = localStorage.getItem('apptoken')
  const nickName = localStorage.getItem('nickName')
  const third_id = localStorage.getItem('third_id')
  const contact = localStorage.getItem('contact') || ''
  const iconUrl = localStorage.getItem('iconUrl') || ''
  const country = localStorage.getItem('country') || ''
  const userIntroduction = localStorage.getItem('userIntroduction') || ''
  return { userid, apptoken, nickName, third_id, contact, iconUrl, country, userIntroduction }
}

export function throttle(func: any, wait: number, options?: { leading?: boolean; trailing?: boolean }) {
  let context: any
  let args: any
  let startTime = 0
  let timeId: any
  options = options || {}
  const { leading } = options
  const { trailing } = options

  const throttled = function (this: any) {
    const now = +new Date()
    if (!startTime && !leading) startTime = now
    const remaining = wait - (now - startTime)

    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeId) {
        clearTimeout(timeId)
        timeId = null
      }
      startTime = now
      func.apply(context, args)
    } else if (!timeId && trailing) {
      timeId = setTimeout(function () {
        startTime = leading ? 0 : +new Date()
        timeId = null
        func.apply(context, args)
      }, remaining)
    }
  }

  return throttled
}

// 生成 Iframe， old-auth 用到
export const makeIframe = (url: string) => {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.setAttribute('src', url)
  iframe.setAttribute('frameBorder', '0')
  iframe.setAttribute('scrolling', 'yes')
  document.getElementsByTagName('body')[0].appendChild(iframe)
}

export const clearHMDomainCookie = () => {
  const keys = document.cookie.match(/[^ =;]+(?==)/g)
  if (keys) {
    for (let i = 0; i < keys.length; i += 1) {
      document.cookie = `${keys[i]}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `${keys[i]}=;path=/;domain=huami.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `${keys[i]}=;path=/;domain=fe-cdn.huami.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    }
  }
}

export const flattenMessages = (nestedMessages: any, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    let newKey = key
    if (!Number.isNaN(key) && !Number.isNaN(parseFloat(key))) {
      newKey = `[${key}]`
    }
    const prefixedKey = prefix ? `${prefix}.${newKey}` : newKey

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }
    return messages
  }, {})
}
