import Immutable from 'immutable'
import { SET_SUPPORT_DEVICES } from '../actionTypes'

const initialState: any = Immutable.fromJS({
  supportDevices: []
})

const reducer = (state = initialState, action: any) => {
  const { type, data } = action

  switch (type) {
    case SET_SUPPORT_DEVICES:
      return state.set('supportDevices', data)
    default:
      return state
  }
}

export default reducer
