import { TableOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'


const StatusBar = (props: any) => {
  const { className, support, activeObject, gridOptions = {} } = props
  const {
    gridW,
    gridH,
    showGrid,
    onGridWChange,
    onGridHChange,
    toggleShowGrid
  } = gridOptions
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const canvasContainer: HTMLElement | null = document.querySelector('.container-m')

    const handleMouseMove = (e: any) => {
      setMousePos({
        x: e.offsetX,
        y: e.offsetY
      })
    }

    if (canvasContainer) {
      canvasContainer.addEventListener('mousemove', handleMouseMove)
    }

    return () => {
      if (canvasContainer) {
        canvasContainer.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [])

  const getInputValue = (e: any) => {
    const val = e.target.value
    if (!/\d+/.test(val)) return
    return val
  }

  return <div className={className}>
    <div className="left">
      <div className="device-name">{support?.get('name')}</div>
    </div>
    <div className="right">
      <div className="position">x: {mousePos.x}, y: {mousePos.y}</div>
      {activeObject ? <div className="area-size">width: {activeObject.width}, height: {activeObject.height}</div> : null}
      <div className="screen-resolution">{support?.get('screen_resolution')}</div>
      <div className="grid-toggle">
        {
          showGrid ? <><Input value={gridW} onChange={(e) => onGridWChange(getInputValue(e))} />
            <span className="divide-input">x</span>
            <Input value={gridH} onChange={(e) => onGridHChange(getInputValue(e))} />
          </> : null
        }
        <TableOutlined
          className={classNames('grid-icon', {
            'grid-icon-active': showGrid
          })}
          onClick={toggleShowGrid}
        />
      </div>
    </div>
  </div>
}

export default StatusBar
