/* eslint-disable import/order */
import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import { Select } from 'antd'
import { immutableSelector } from '@/utils'
import { Upload } from '@watchface/components'
import { renderCells } from '@watchface/utils/render'
import { SELECT_STYLE } from '@watchface/constants/style'
import T from '@watchface/components/I18n'

class MaskComponent extends PureComponent {
  static keyMap = {}

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      MaskComponent.renderLayer(this.props)
    }
  }

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: MaskComponent,
      ...param
    })
    MaskComponent.keyMap = Object.assign(MaskComponent.keyMap, keyMap)
  }

  static getCellDatas = ({ data, allAssets, parentKey, dialType }) => {
    const assets = allAssets.getIn([dialType, parentKey]) || Immutable.Map()
    const { showCover = 0, showMask = 0 } = immutableSelector(assets)
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const cover = MaskComponent.getCoverImage({ data })

    if (cover && showCover === 1) {
      cells.imageEntity[cover.key] = cover
    }

    const mask = MaskComponent.getMaskImage({ data })
    if (mask && showMask === 1) {
      cells.imageEntity[mask.key] = mask
    }
    return cells
  }

  static getCoverImage = ({ data }) => {
    const { cover } = immutableSelector(data)
    if (cover) {
      return {
        key: 'cover',
        texture: cover,
        left: 0,
        top: 0
      }
    }

    return null
  }

  static getMaskImage = ({ data }) => {
    const { mask } = immutableSelector(data)
    if (mask) {
      return {
        key: 'mask',
        texture: mask,
        left: 0,
        top: 0,
        zIndex: 10000
      }
    }
    return null
  }

  handleInsertImage = (img, option) => {
    const { onChange, data, parentKey } = this.props
    let newData = data
    if (img && img.image) {
      newData = newData.setIn([...option], img.image)
    } else {
      newData = newData.setIn([...option], '')
    }
    onChange([parentKey], newData)
  }

  toggleShowMask = (val) => {
    const { onAssetsChange, parentKey, dialType, allAssets } = this.props
    const newAllAssets = allAssets.setIn([dialType, parentKey, 'showCover'], val).setIn([dialType, parentKey, 'showMask'], val)

    onAssetsChange([], newAllAssets)
  }

  render() {
    const { watchConfig, data, assets } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { cover, mask } = immutableSelector(data)
    const { showMask = 0 } = immutableSelector(assets)

    return (
      <>
        <div className="name is-required">
          100% <T id="mask" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                field={['cover']}
                fileList={cover ? [{ image: cover }] : []}
                onChange={this.handleInsertImage}
              />
            </div>
          </div>
        </div>
        <div className="name is-required">
          70% <T id="mask" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                field={['mask']}
                fileList={mask ? [{ image: mask }] : []}
                onChange={this.handleInsertImage}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="preview" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Select
                dropdownClassName="watch-skin-select-dropdown"
                style={SELECT_STYLE}
                onChange={this.toggleShowMask}
                value={showMask}
                options={[
                  {
                    label: <T id="display" />,
                    value: 1
                  },
                  {
                    label: <T id="hide" />,
                    value: 0
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default MaskComponent
