import { getZeppDocLink } from '@watchface/utils'

const toolbarConfig = [
  {
    name: 'new',
    operation: 'new',
    icon: 'icon-tuceng-xinjian'
  },
  {
    name: 'my_dial',
    operation: 'open',
    icon: 'icon-tuceng-tuichubeifen'
  },
  {
    name: 'audit',
    operation: 'upload',
    hide: () => false,
    icon: 'icon-shangchuanshenhe'
  },
  {
    name: 'help',
    operation: 'go2help',
    hide: () => false,
    icon: 'icon-tuceng-bangzhu',
    link: {
      genUrl: () => getZeppDocLink('overview'),
      needReq: false
    }
  },
  {
    name: 'feedback',
    operation: 'feedback',
    icon: ''
  }
]

export default toolbarConfig
