import { SERIES } from '@watchface/constants'
import T from '@watchface/components/I18n'
import { BackgroundComponent } from '@watchface/pages/DesignTool/NonZeppOs/components'
import { getZeppDocLink } from '@watchface/utils'
import Classnames from 'classnames'
import React, { PureComponent } from 'react'

const noop = () => {} // eslint-disable-line

class Background extends PureComponent {
  static renderLayer = (param) => {
    BackgroundComponent.renderLayer(param)
  }

  render() {
    const cls = Classnames(['background-container', 'control-container'])
    const isZeppOs = this.props?.series === SERIES.JS
    return (
      <div className={cls}>
        {isZeppOs ? (
          <div className="tutorial">
            <a href={getZeppDocLink('background')} target="_blank" rel="noreferrer">
              <T id="background_tutorial" />
            </a>
          </div>
        ) : null}
        <BackgroundComponent {...this.props} />
      </div>
    )
  }
}

export default Background
