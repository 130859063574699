import { WATCHFACE_LANG } from '@watchface/constants'
import Immutable from 'immutable'
import { CHANGE_LANG, UPDATE_I18N } from '../actionTypes'

const initialState: any = Immutable.fromJS({
  currentLang: localStorage.getItem(WATCHFACE_LANG),
  locale: {}
})

const reducer = (state = initialState, action: any): any => {
  const { type, data } = action

  switch (type) {
    case UPDATE_I18N:
      return state.set('locale', Immutable.fromJS(data))
    case CHANGE_LANG:
      return state.set('currentLang', Immutable.fromJS(data))
    default:
      return state
  }
}

export default reducer
