import { Col, Divider, InputNumber, Row } from 'antd'
import * as React from 'react'

interface Props {
  properties: {
    width: { value: number; visibility: boolean; disabled: boolean }
    height: { value: number; visibility: boolean; disabled: boolean }
  }
  onChange: (category: string, property: string, value: string | number | undefined | null) => void
}

const Dimension: React.FC<Props> = (props) => {
  const {
    properties: {
      width: { value: width, visibility: showWidth, disabled: isWidthDisabled } = { value: 0, visibility: true, disabled: false },
      height: { value: height, visibility: showHeight, disabled: isHeightDisabled } = { value: 0, visibility: true, disabled: false }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-dimension">
      <Row justify="space-around" align="middle">
        {showWidth ? (
          <>
            <Col span={2}>W</Col>
            <Col span={10}>
              <InputNumber
                min={0}
                max={480}
                value={width}
                disabled={isWidthDisabled}
                onChange={(value) => {
                  onChange('dimension', 'width', value)
                }}
              />
            </Col>
          </>
        ) : null}
        {showHeight ? (
          <>
            <Col span={2}>H</Col>
            <Col span={10}>
              <InputNumber
                min={0}
                max={480}
                value={height}
                disabled={isHeightDisabled}
                onChange={(value) => {
                  onChange('dimension', 'height', value)
                }}
              />
            </Col>
          </>
        ) : (
          <Col span={12} />
        )}
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Dimension
