/* eslint-disable import/order */
import { getLunarText } from '@watchface/utils/lunarUntil'
import { immutableSelector } from '@/utils'
import { ColorPicker, InputNumber } from '@watchface/components'
import T from '@watchface/components/I18n'
import { ALIGN_H, ALIGN_V, MOVE_IMAGE } from '@watchface/constants'
import { VERTEX_DOT_LINE_STYLE } from '@watchface/constants/graph'
import { SELECT_STYLE } from '@watchface/constants/style'
import { isHitKey } from '@watchface/utils'
import { removeCells, renderCells } from '@watchface/utils/render'
import { Alert, DatePicker, message, Radio, Select } from 'antd'
import dayjs from 'dayjs'
import Immutable from 'immutable'
import PubSub from 'pubsub-js'
import React from 'react'
import './index.scss'

const { Option } = Select

class LunarComponent extends React.PureComponent {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: LunarComponent,
      ...param
    })
    LunarComponent.keyMap = Object.assign(LunarComponent.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: LunarComponent,
      ...param
    })
    LunarComponent.keyMap = keyMap
  }

  componentDidMount() {
    LunarComponent.renderLayer(this.props)

    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      const { parentKey, childKey, dialType } = this.props

      if (!isHitKey(key, parentKey, childKey, '', dialType)) return

      const posPath = ['rectangle', 'position']

      this.handleChange(posPath, Immutable.Map({
        x: left,
        y: top,
      }))
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      LunarComponent.renderLayer(this.props)
    }
  }

  static getLunarTextVertex = ({ data, allData, assets, isFestivalOrSolarTerm }) => {
    const { color, font_size, rectangle, rect_align, display_type } = immutableSelector(data)
    const { width, height, position } = immutableSelector(rectangle)
    const { horizontal, vertical } = immutableSelector(rect_align)
    const { x = 0, y = 0 } = immutableSelector(position)
    const previewDate = assets.get('previewDate' , dayjs(new Date()))
    const festivalDisplayType = Number(isFestivalOrSolarTerm ? display_type : LunarComponent.getLunarChildData(allData, 'festival_or_solar_term').get('display_type', 1))
    let lunarText = getLunarText(previewDate, 0)

    if (isFestivalOrSolarTerm) {
      lunarText = festivalDisplayType === 1 ? getLunarText(previewDate, 2) : ''
    } else if (festivalDisplayType === 0) {
      lunarText = getLunarText(previewDate, 1)
    }

    const newColor = color ? `#${color.slice(4)}` : ''

    const calcLeftDistance = (cellWidth) => {
      if (width < cellWidth) return x

      if (horizontal === ALIGN_H.LEFT) {
        return x
      }
      if (horizontal === ALIGN_H.CENTER) {
        return (width - cellWidth) / 2 + x
      }
      if (horizontal === ALIGN_H.RIGHT) {
        return (width - cellWidth) + x
      }
      return x
    }

    const calcTopDistance = (cellHeight) => {
      if (height < cellHeight) return y

      if (vertical === ALIGN_V.TOP) {
        return y
      }
      if (vertical === ALIGN_V.CENTER) {
        return (height - cellHeight) / 2 + y
      }
      if (vertical === ALIGN_V.BOTTOM) {
        return (height - cellHeight) + y
      }

      return y
    }

    const cell = {
      left: x,
      height: y,
      fill: newColor,
      type: 'text',
      fontSize: font_size || 17, // 固件默认字体大小
      text: lunarText,
      selectable: false,
      callback: (cellInstance, canvasPage, cell) => {
        const { width, height } = cellInstance
        const left = calcLeftDistance(width)
        const top = calcTopDistance(height)

        if (cell) {
          const index = cell.getObjects().indexOf(cellInstance)

          if (index === 0) {
            cellInstance.set({ left, top })
          } else {
            cellInstance.set({
              left: left + (cell.item(0).left || 0) - cell.left,
              top: top + (cell.item(0).top || 0) - cell.top,
            })
          }
        } else {
          cellInstance.set({ left, top })
        }
      }
    }

    return cell
  }

  static getLunarChildData = (allData, key) => {
    const childrenData = allData.getIn(['lunar', 'children'], Immutable.Map())
    return key ? (childrenData?.get(key) || Immutable.Map()) : childrenData
  }

  static getCellDatas = ({ allData, data, assets, childKey }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const { rectangle, display_type  } = immutableSelector(data)
    const isFestivalOrSolarTerm = childKey === 'festival_or_solar_term'
    const { width, height, position } = immutableSelector(rectangle)
    const { x = 0, y = 0 } = immutableSelector(position)
    const rectKey = `${childKey}-lunarRect`
    const lunarTextCell = LunarComponent.getLunarTextVertex({ data, assets, allData, isFestivalOrSolarTerm })

    if (isFestivalOrSolarTerm && Number(display_type) === 0) return cells

    cells.vertexEntity[rectKey] = {
      key: rectKey,
      type: 'group',
      objects: [
        {
          left: x,
          top: y,
          width,
          height,
          type: 'rect',
          fill: 'transparent',
          // visible: false, // 起区域占位作用
          ...VERTEX_DOT_LINE_STYLE,
        },
        lunarTextCell,
      ]
    }

    return cells
  }

  handleColorChange = (color) => {
    const value = color.hex
    const { parentKey, childKey, onChange, data } = this.props
    // todo: 临时修改转为 ARGB 格式, 后续优化
    const newData = data.set('color', value ? `0xFF${value.slice(1)}` : '')
    onChange([parentKey, 'children', childKey], newData)
  }

  handleColorInputChange = (value) => {
    this.handleColorChange({ hex: value })
  }

  handlePreviewLunar = (date, dateString) => {
    const { dialType, parentKey, childKey, onAssetsChange } = this.props
    onAssetsChange([dialType, parentKey, 'children', childKey, 'previewDate'], dateString)
  }

  handleChange = (dataPath, value) => {
    const { parentKey, childKey, data, menu, onChange } = this.props
    const newData = data.setIn(dataPath, value)

    if (dataPath.indexOf('display_type') > -1) {
      const lunarDateSelected = menu.getIn([0, 'children', 0, 'checked'])

      if (!lunarDateSelected && Number(value) === 0) {
        message.warning(<T id="select_lunar_date_tip" />)
        return
      }
    }

    onChange([parentKey, 'children', childKey], newData)
  }

  limitDateRange = (currentDate, isFestivalOrSolarTerm) => {
    const dateString = dayjs(currentDate).format('YYYY-MM-DD')
    const lunarText = getLunarText(dateString, isFestivalOrSolarTerm ? 2 : 1)
    // 与 lunarUtil 固件目前支持的时间范围保存一致
    return !lunarText || dayjs(currentDate).isBefore(dayjs('2001-01-01')) || dayjs(currentDate).isAfter(dayjs('2050-12-31'))
  }

  renderDisplayType = (display_type) => {
    const Message = <pre style={{ whiteSpace: 'pre-wrap' }}>
      <T id="festival_tip" />
    </pre>
    return <>
      <Alert message={Message} type="warning" />
      <div className="item lunar-festival-radio">
        <div className="name">
          <T id="separate_coordinate"/>
        </div>
        <div className="content">
          <Radio.Group onChange={(e) => {
              this.handleChange(['display_type'], e.target.value)
            }}
            value={Number(display_type)}
          >
            <Radio value={1}><T id="yes" /></Radio>
            <Radio value={0}><T id="no" /></Radio>
          </Radio.Group>
        </div>
      </div>
    </>
  }

  render() {
    const { watchConfig = Immutable.Map(), assets = Immutable.Map(), data = Immutable.Map(), childKey } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { color, font_size = 17, display_type, rectangle, rect_align } = immutableSelector(data)
    const { width, height, position } = immutableSelector(rectangle)
    const previewDate = assets.get('previewDate' , dayjs(new Date()))
    const { horizontal, vertical } = immutableSelector(rect_align)
    const { x = 0, y = 0 } = immutableSelector(position)
    const isFestivalOrSolarTerm = childKey === 'festival_or_solar_term'

    return <>
      {isFestivalOrSolarTerm ? this.renderDisplayType(display_type) : null}
      {!isFestivalOrSolarTerm || Number(display_type) === 1 ? <>
        <div className="item">
          <div className="name">
            <T id="area_size" />
          </div>
          <div className="content">
            <div className="item">
              <div className="col">
                <div className="key">W</div>
                <InputNumber min={1} max={screenWidth} value={width} onChange={(value) => this.handleChange(['rectangle', 'width'], value)} />
              </div>
              <div className="col">
                <div className="key">H</div>
                <InputNumber min={1} max={screenHeight} value={height} onChange={(value) => this.handleChange(['rectangle', 'height'], value)} />
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="name">
            <T id="position" />
          </div>
          <div className="content">
            <div className="item">
              <div className="col">
                <div className="key">x</div>
                <InputNumber min={0} max={screenWidth} value={x} onChange={(value) => this.handleChange(['rectangle', 'position', 'x'], value)} />
              </div>
              <div className="col">
                <div className="key">y</div>
                <InputNumber min={0} max={screenHeight} value={y} onChange={(value) => this.handleChange(['rectangle', 'position', 'y'], value)} />
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="name">
            <T id="alignment" />
          </div>
          <div className="content">
            <div className="item">
              <Select
                dropdownClassName="watch-skin-select-dropdown"
                value={horizontal}
                style={SELECT_STYLE}
                onChange={(value) => this.handleChange(['rect_align', 'horizontal'], value)}
              >
                <Option value={ALIGN_H.LEFT}>
                  <T id="align_left" />
                </Option>
                <Option value={ALIGN_H.CENTER}>
                  <T id="horizontal_center" />
                </Option>
                <Option value={ALIGN_H.RIGHT}>
                  <T id="align_right" />
                </Option>
              </Select>
            </div>
            <div className="item">
              <Select
                dropdownClassName="watch-skin-select-dropdown"
                value={vertical}
                style={SELECT_STYLE}
                onChange={(value) => this.handleChange(['rect_align', 'vertical'], value)}
              >
                <Option value={ALIGN_V.TOP}>
                  <T id="align_top" />
                </Option>
                <Option value={ALIGN_V.CENTER}>
                  <T id="vertical_center" />
                </Option>
                <Option value={ALIGN_V.BOTTOM}>
                  <T id="align_bottom" />
                </Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="item lunar-layout-inline">
          <div className="name">
            <T id="font_size" />
          </div>
          <InputNumber min={10} max={60} value={font_size} onChange={(value) => this.handleChange(['font_size'], value)}/>
        </div>
        <div className="item lunar-layout-inline">
          <div className="name">
            <T id="color_val" />
          </div>
          <ColorPicker
            width={200}
            color={color ? `#${color.slice(4)}` : ''}
            onInputChange={this.handleColorInputChange}
            onChangeComplete={this.handleColorChange}
          />
        </div>
        <div className="item lunar-layout-inline preview-date-item">
          <div className="name">
            <T id="preview" />
          </div>
          <div>
            <DatePicker
              className="preview-date"
              allowClear={false}
              value={dayjs(previewDate)}
              disabledDate={(date) => this.limitDateRange(date, isFestivalOrSolarTerm)}
              onChange={this.handlePreviewLunar}
            />
          </div>
        </div>
      </> : null}
    </>
  }
}
export default LunarComponent
