// 本文件代码依照固件提供的 C 语言代码编写，为方便后续维护更新，大多数命名与 C 语言代码保持了一致
/* eslint-disable no-bitwise, no-plusplus, no-param-reassign */
import dayjs from 'dayjs'

const BEGIN_YEAR = 2001
const END_YEAR = 2050

// const SOLAR_BIG_MONTH_DAYS = 31
// const SOLAR_SMALL_MONTH_DAYS = 30
// const SOLAR_FEB_DAYS = 28
// const SOLAR_FEB_REV_DAYS = 29

const LUNAR_BIG_MONTH_DAYS = 30
const LUNAR_SMALL_MONTH_DAYS = 29

/**
  * lunar_50y 每个数组成员代表的信息说明如下：
  * | 0~4 | 5~6 | 7~19 | 20~23 | 24~31 |
  * | :--: | :--: | :--: | :--: | :--: |
  * | 春节的公历日期 | 春节的公历月份 | 农历1~13月，为1表示30天，为0表示29天 | 当年闰月月份，为0表示无闰月 | 不使用 |
*/
const lunar_50y = [
  0x4D4AB8, 0x0D4A4C, 0x0DA541, 0x25AAB6, 0x056A49, 0x7AADBD, 0x025D52, 0x092D47, 0x5C95BA, 0x0A954E, // BEGIN_YEAR-2010
  0x0B4A43, 0x4B5537, 0x0AD54A, 0x955ABF, 0x04BA53, 0x0A5B48, 0x652BBC, 0x052B50, 0x0A9345, 0x474AB9, // 2011-2020
  0x06AA4C, 0x0AD541, 0x24DAB6, 0x04B64A, 0x69573D, 0x0A4E51, 0x0D2646, 0x5E933A, 0x0D534D, 0x05AA43, // 2021-2030
  0x36B537, 0x096D4B, 0xB4AEBF, 0x04AD53, 0x0A4D48, 0x6D25BC, 0x0D254F, 0x0D5244, 0x5DAA38, 0x0B5A4C, // 2031-2040
  0x056D41, 0x24ADB6, 0x049B4A, 0x7A4BBE, 0x0A4B51, 0x0AA546, 0x5B52BA, 0x06D24E, 0x0ADA42, 0x355B37, // 2041-END_YEAR
]

// 某个月距离元旦的天数
const month_total = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365]

/**
* 二十四节气数据库（2001--2050）
* 数据格式说明:
* 每个月对应2个节气，以下表是按节气顺序排
* 小寒大寒立春雨水惊蛰春分清明谷雨立夏小满芒种夏至小暑大暑立秋处暑白露秋分寒露霜降立冬小雪大雪冬至
* 变更记录：（2021/2/24）2039年惊蛰由原3月5日变更为3月6号
*/
const termTb = [
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x05, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // BEGIN_YEAR
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x15, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2002
  0x06, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x16, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x09, 0x18, 0x08, 0x17, 0x07, 0x16, // 2003
  0x06, 0x15, 0x04, 0x13, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2004
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x05, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2005
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2006
  0x06, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x16, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x09, 0x18, 0x08, 0x17, 0x07, 0x16, // 2007
  0x06, 0x15, 0x04, 0x13, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2008
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2009
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2010
  0x06, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x16, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x08, 0x18, 0x08, 0x17, 0x07, 0x16, // 2011
  0x06, 0x15, 0x04, 0x13, 0x05, 0x14, 0x04, 0x14, 0x05, 0x14, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2012
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2013
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2014
  0x06, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x16, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x08, 0x18, 0x08, 0x16, 0x07, 0x16, // 2015
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2016
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2017
  0x05, 0x14, 0x04, 0x13, 0x05, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2018
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x15, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x08, 0x18, 0x08, 0x16, 0x07, 0x16, // 2019
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2020
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2021
  0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2022
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x15, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x08, 0x18, 0x08, 0x16, 0x07, 0x16, // 2023
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x06, 0x15, // 2024
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2025
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x05, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2026
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x15, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2027
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x06, 0x15, // 2028
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2029
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x05, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2030
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x06, 0x15, 0x06, 0x15, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2031
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x06, 0x15, // 2032
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2033
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x05, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2034
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2035
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x06, 0x15, // 2036
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2037
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x05, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2038
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2039
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x06, 0x15, // 2040
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x14, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2041
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x17, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2042
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2043
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x07, 0x17, 0x07, 0x16, 0x06, 0x15, // 2044
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2045
  0x05, 0x14, 0x04, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2046
  0x05, 0x14, 0x04, 0x13, 0x06, 0x15, 0x05, 0x14, 0x05, 0x15, 0x06, 0x15, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // 2047
  0x06, 0x14, 0x04, 0x13, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x14, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x07, 0x17, 0x07, 0x15, 0x06, 0x15, // 2048
  0x05, 0x13, 0x03, 0x12, 0x05, 0x14, 0x04, 0x13, 0x05, 0x14, 0x05, 0x15, 0x06, 0x16, 0x07, 0x16, 0x07, 0x16, 0x08, 0x17, 0x07, 0x16, 0x07, 0x15, // 2049
  0x05, 0x14, 0x03, 0x12, 0x05, 0x14, 0x04, 0x14, 0x05, 0x15, 0x05, 0x15, 0x07, 0x16, 0x07, 0x17, 0x07, 0x17, 0x08, 0x17, 0x07, 0x16, 0x07, 0x16, // END_YEAR
]

// 与固件维护的 csv 文件一致
const csvData = {
  cal_tiangan_1: '癸',
  cal_tiangan_2: '甲',
  cal_tiangan_3: '乙',
  cal_tiangan_4: '丙',
  cal_tiangan_5: '丁',
  cal_tiangan_6: '戊',
  cal_tiangan_7: '己',
  cal_tiangan_8: '庚',
  cal_tiangan_9: '辛',
  cal_tiangan_10: '壬',
  cal_dizhi_1: '亥',
  cal_dizhi_2: '子',
  cal_dizhi_3: '丑',
  cal_dizhi_4: '寅',
  cal_dizhi_5: '卯',
  cal_dizhi_6: '辰',
  cal_dizhi_7: '巳',
  cal_dizhi_8: '午',
  cal_dizhi_9: '未',
  cal_dizhi_10: '申',
  cal_dizhi_11: '酉',
  cal_dizhi_12: '戌',
  cal_year: '年',
  cal_month: '月',
  cal_day: '日',
  cal_month_1: '正月',
  cal_month_2: '二月',
  cal_month_3: '三月',
  cal_month_4: '四月',
  cal_month_5: '五月',
  cal_month_6: '六月',
  cal_month_7: '七月',
  cal_month_8: '八月',
  cal_month_9: '九月',
  cal_month_10: '十月',
  cal_month_11: '冬月',
  cal_month_12: '腊月',
  cal_run: '闰',
  cal_day_1: '初一',
  cal_day_2: '初二',
  cal_day_3: '初三',
  cal_day_4: '初四',
  cal_day_5: '初五',
  cal_day_6: '初六',
  cal_day_7: '初七',
  cal_day_8: '初八',
  cal_day_9: '初九',
  cal_day_10: '初十',
  cal_day_11: '十一',
  cal_day_12: '十二',
  cal_day_13: '十三',
  cal_day_14: '十四',
  cal_day_15: '十五',
  cal_day_16: '十六',
  cal_day_17: '十七',
  cal_day_18: '十八',
  cal_day_19: '十九',
  cal_day_20: '二十',
  cal_day_21: '廿一',
  cal_day_22: '廿二',
  cal_day_23: '廿三',
  cal_day_24: '廿四',
  cal_day_25: '廿五',
  cal_day_26: '廿六',
  cal_day_27: '廿七',
  cal_day_28: '廿八',
  cal_day_29: '廿九',
  cal_day_30: '三十',
  cal_jeiqi_1: '小寒',
  cal_jeiqi_2: '大寒',
  cal_jeiqi_3: '立春',
  cal_jeiqi_4: '雨水',
  cal_jeiqi_5: '惊蛰',
  cal_jeiqi_6: '春分',
  cal_jeiqi_7: '清明',
  cal_jeiqi_8: '谷雨',
  cal_jeiqi_9: '立夏',
  cal_jeiqi_10: '小满',
  cal_jeiqi_11: '芒种',
  cal_jeiqi_12: '夏至',
  cal_jeiqi_13: '小暑',
  cal_jeiqi_14: '大暑',
  cal_jeiqi_15: '立秋',
  cal_jeiqi_16: '处暑',
  cal_jeiqi_17: '白露',
  cal_jeiqi_18: '秋分',
  cal_jeiqi_19: '寒露',
  cal_jeiqi_20: '霜降',
  cal_jeiqi_21: '立冬',
  cal_jeiqi_22: '小雪',
  cal_jeiqi_23: '大雪',
  cal_jeiqi_24: '冬至',
  cal_date_1: '周一',
  cal_date_2: '周二',
  cal_date_3: '周三',
  cal_date_4: '周四',
  cal_date_5: '周五',
  cal_date_6: '周六',
  cal_date_7: '周日',
  cal_fes_yuandan: '元旦',
  cal_fes_laba: '腊八节',
  cal_fes_xiaonian: '小年',
  cal_fes_chunjie: '春节',
  cal_fes_qingrenjie: '情人节',
  cal_fes_yuanxiao: '元宵节',
  cal_fes_longtaitou: '龙抬头',
  cal_fes_leifeng: '学雷锋纪念日',
  cal_fes_funvjie: '三八妇女节',
  cal_fes_zhishu: '植树节',
  cal_fes_baiseqingrenjie: '白色情人节',
  cal_fes_xiaofeizhe: '消费者权益日',
  cal_fes_yurenjie: '愚人节',
  cal_fes_laodong: '国际劳动节',
  cal_fes_qingnian: '中国青年节',
  cal_fes_ertongjie: '国际儿童节',
  cal_fes_daunwu: '端午节',
  cal_fes_jiandang: '建党节',
  cal_fes_jianjun: '建军节',
  cal_fes_qixi: '七夕情人节',
  cal_fes_zhongyuan: '中元节',
  cal_fes_kangzhan: '中国人民抗日战争纪念日',
  cal_fes_jiaoshi: '教师节',
  cal_fes_zhongqiu: '中秋节',
  cal_fes_guoqing: '国庆节',
  cal_fes_chongyang: '重阳节',
  cal_fes_wansheng: '万圣节',
  cal_fes_jizhe: '中国记者日',
  cal_fes_xiaofang: '消防宣传日',
  cal_fes_guanggun: '光棍节',
  cal_fes_gongji: '南京大屠杀死难者国家公祭日',
  cal_fes_pingan: '平安夜',
  cal_fes_shengdan: '圣诞节',
  cal_fes_fuqin: '父亲节',
  cal_fes_muqin: '母亲节',
  cal_fes_ganen: '感恩节',
  cal_fes_chuxi: '除夕',
}

// 固定日期的农历节日
const lunarFestivalTb = [
  { month: 12, day: 8, festive_name_chs: 'laba' },       // 腊八节
  { month: 12, day: 23, festive_name_chs: 'xiaonian' },  // 小年
  { month: 1, day: 1, festive_name_chs: 'chunjie' },     // 春節
  { month: 1, day: 15, festive_name_chs: 'yuanxiao' },   // 元宵节
  { month: 2, day: 2, festive_name_chs: 'longtaitou' },  // 龙抬头
  { month: 5, day: 5, festive_name_chs: 'daunwu' },      // 端午节
  { month: 7, day: 7, festive_name_chs: 'qixi' },        // 七夕情人节
  { month: 7, day: 15, festive_name_chs: 'zhongyuan' },  // 中元节
  { month: 8, day: 15, festive_name_chs: 'zhongqiu' },   // 中秋节
  { month: 9, day: 9, festive_name_chs: 'chongyang' },   // 重阳节
]

// 固定日期的阳历节日
const festivalTb = [
  { month: 1, day: 1, festive_name_chs: 'yuandan' },              // 元旦
  { month: 2, day: 14, festive_name_chs: 'qingrenjie' },          // 情人节
  { month: 3, day: 5, festive_name_chs: 'leifeng' },              // 学雷锋纪念日
  { month: 3, day: 8, festive_name_chs: 'funvjie' },              // 三八妇女节
  { month: 3, day: 12, festive_name_chs: 'zhishu' },              // 植树节
  { month: 3, day: 14, festive_name_chs: 'baiseqingrenjie' },     // 白色情人節
  { month: 3, day: 15, festive_name_chs: 'xiaofeizhe' },          // 消费者权益日
  { month: 4, day: 1, festive_name_chs: 'yurenjie' },             // 愚人节
  { month: 5, day: 1, festive_name_chs: 'laodong' },              // 五一国际劳动节
  { month: 5, day: 4, festive_name_chs: 'qingnian' },             // 中国青年节
  { month: 6, day: 1, festive_name_chs: 'ertongjie' },            // 国际儿童节
  { month: 7, day: 1, festive_name_chs: 'jiandang' },             // 建党节
  { month: 8, day: 1, festive_name_chs: 'jianjun' },              // 八一建军节
  { month: 9, day: 3, festive_name_chs: 'kangzhan' },             // 中国人民抗日战争纪念日
  { month: 9, day: 10, festive_name_chs: 'jiaoshi' },             // 教师节
  { month: 10, day: 1, festive_name_chs: 'guoqing' },             // 国庆节
  { month: 11, day: 1, festive_name_chs: 'wansheng' },            // 万圣节
  { month: 11, day: 8, festive_name_chs: 'jizhe' },               // 中国记者日
  { month: 11, day: 9, festive_name_chs: 'xiaofang' },            // 消防宣传日
  { month: 11, day: 11, festive_name_chs: 'guanggun' },           // 光棍节
  { month: 12, day: 13, festive_name_chs: 'gongji' },             // 南京大屠杀死难者国家公祭日
  { month: 12, day: 24, festive_name_chs: 'pingan' },             // 平安夜
  { month: 12, day: 25, festive_name_chs: 'shengdan' },           // 圣诞节
]

const LUNAR_FESTIVE_NUM = lunarFestivalTb.length
const FESTIVE_NUM = festivalTb.length

// current date is valid or not(50 年内)
function isValidHmTimeDate(year) {
  return year > BEGIN_YEAR || year < END_YEAR
}

// /**
//  * 计算是否公历的闰年
//  * @param {number} year
//  * @returns
//  */
// function isLeapYear(year) {
//   if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
//     return true;
//   }
//   return false
// }

function solarToLunar(year, month, day) {
  const pLunar_date = {
    year: '',
    month: '',
    day: '',
    leapMonth: 0, // 是否为闰月
  }

  let bySpring = 0
  let bySolar = 0
  let daysPerMonth = 0
  let index = 0
  let flag = 0;

  if (((lunar_50y[year - BEGIN_YEAR] & 0x0060) >> 5) === 1)              // 春节在公历1月
  {
    bySpring = (lunar_50y[year - BEGIN_YEAR] & 0x001F) - 1;             // 春节距离元旦的天数（春节元旦同月）
  }
  else                                                                  // 春节不在1月
  {
    bySpring = (lunar_50y[year - BEGIN_YEAR] & 0x001F) - 1 + 31;        // 春节距离元旦天数（春节元旦不同月）
  }
  bySolar = month_total[month - 1] + day - 1;                           // 公历当日距离元旦的天数
  if ((!(year % 4)) && (month > 2)) {
    bySolar++;
  }
  if (bySolar >= bySpring)    // 春节后
  {
    bySolar -= bySpring;      // 公历当日距离春节的天数
    month = 1;                // 农历月份
    index = 1;
    flag = 0;
    if ((lunar_50y[year - BEGIN_YEAR] & (0x80000 >> (index - 1))) === 0) // 判断农历正月大小月
    {
      daysPerMonth = LUNAR_SMALL_MONTH_DAYS;                          // 正月29天
    }
    else {
      daysPerMonth = LUNAR_BIG_MONTH_DAYS;                            // 正月30天
    }
    while (bySolar >= daysPerMonth)                                   // 正月后的日期求农历月份
    {
      bySolar -= daysPerMonth;
      index++;                                                        // 农历月份(大小月索引)
      if (month === ((lunar_50y[year - BEGIN_YEAR] & 0xF00000) >> 20)) // 遇到闰月
      {
        flag = ~flag;
        if (flag === 0) {
          month++;
        }
      }
      else {
        month++;
      }
      if ((lunar_50y[year - BEGIN_YEAR] & (0x80000 >> (index - 1))) === 0) {
        daysPerMonth = LUNAR_SMALL_MONTH_DAYS;
      }
      else {
        daysPerMonth = LUNAR_BIG_MONTH_DAYS;
      }
    }
    day = bySolar + 1;
  }
  else    // 春节前
  {
    bySpring -= bySolar;
    year--;
    month = 12;
    if (((lunar_50y[year - BEGIN_YEAR] & 0xF00000) >> 20) === 0) {
      index = 12;
    }
    else {
      index = 13;
    }
    flag = 0;
    if ((lunar_50y[year - BEGIN_YEAR] & (0x80000 >> (index - 1))) === 0) {
      daysPerMonth = LUNAR_SMALL_MONTH_DAYS;
    }
    else {
      daysPerMonth = LUNAR_BIG_MONTH_DAYS;
    }
    while (bySpring > daysPerMonth) {
      bySpring -= daysPerMonth;
      index--;
      if (flag === 0) {
        month--;
      }
      if (month === ((lunar_50y[year - BEGIN_YEAR] & 0xF00000) >> 20)) {
        flag = ~flag;
      }
      if ((lunar_50y[year - BEGIN_YEAR] & (0x80000 >> (index - 1))) === 0) {
        daysPerMonth = LUNAR_SMALL_MONTH_DAYS;
      }
      else {
        daysPerMonth = LUNAR_BIG_MONTH_DAYS;
      }
    }
    day = daysPerMonth - bySpring + 1;
  }
  pLunar_date.day = day;
  pLunar_date.month = month;
  pLunar_date.year = year;
  // 闰月标识更新。比如4月和闰4月分开。
  if (month === ((lunar_50y[year - BEGIN_YEAR] & 0xF00000) >> 20) && month < index) {
    pLunar_date.leapMonth = 1; // 闰月
  }
  else {
    pLunar_date.leapMonth = 0;
  }
  return pLunar_date;
}

// get days of last lunar month
function lunarLastMonthDays(year) {
  let index = 0
  let daysPerMonth = 0;

  if (((lunar_50y[year - BEGIN_YEAR] & 0xF00000) >> 20) === 0) {
    index = 12;
  } else {
    index = 13;
  }
  if ((lunar_50y[year - BEGIN_YEAR] & (0x80000 >> (index - 1))) === 0) {
    daysPerMonth = LUNAR_SMALL_MONTH_DAYS;
  } else {
    daysPerMonth = LUNAR_BIG_MONTH_DAYS;
  }
  return daysPerMonth;
}


/**
 * Get the Festive Lunar object
 * @param {*} lunarYear  农历年
 * @param {*} lunarMonth 农历月
 * @param {*} lunarDay 农历日
 * @param {number} IS_LEAP_MONTH 是否是闰月 0 or 1
 * @returns
 */
function getFestivalLunar(lunarYear, lunarMonth, lunarDay, IS_LEAP_MONTH) {
  // 这里这样处理实际有算法缺陷：如果闰月为腊月的年份，其实除夕是闰腊月的最后一天。
  // 这里只判断腊月最后一天为除夕的这种做法实际不严谨。不过暂时可以这么计算的原因如下：
  // 农历闰腊月的年份目前已知的为208年，1574 年，3358年，3472年，3806年，3988年，4751年，4941年，4971年...
  // 在近两百年内不会出现闰腊月，所以可以当做ok来使用。
  let i = 0;
  let festive = ''

  if (lunarMonth === 12) {
    if (lunarLastMonthDays(lunarYear) === lunarDay) {
      festive = csvData.cal_fes_chuxi
      return festive;
    }
  }
  // 不是除夕，那么再去农历节日表中，查询是否是其他农历的节日, 闰月不计算农历节日
  for (i = 0; i < LUNAR_FESTIVE_NUM; i++) {
    if (IS_LEAP_MONTH === 0 && (lunarMonth === lunarFestivalTb[i].month) && (lunarDay === lunarFestivalTb[i].day)) {
      festive = csvData[`cal_fes_${lunarFestivalTb[i].festive_name_chs}`]
      return festive;
    }
  }
  return festive;
}

/**
 * Get the solar Festive object
 * @param {*} solarYear 公历年
 * @param {*} solarMonth 公历月
 * @param {*} solarDay 公历日
 * @param {*} solarDayOfWeek 本周第几天
 * @returns
 */
function getFestiveSolar(solarMonth, solarDay, solarDayOfWeek) {
  let i;
  let festive = '';

  // 计算是不是母亲节、父亲节、感恩节以及寒食这种非固定日期的节日。
  if (solarMonth === 5 && solarDayOfWeek === 0 && (solarDay >= 8 && solarDay <= 14)) {
    festive = csvData.cal_fes_muqin
    return festive;
  }
  if (solarMonth === 6 && solarDayOfWeek === 0 && (solarDay >= 15 && solarDay <= 21)) {
    festive = csvData.cal_fes_fuqin
    return festive;
  }
  if (solarMonth === 11 && solarDayOfWeek === 4 && (solarDay >= 22 && solarDay <= 28)) {
    festive = csvData.cal_fes_ganen
    return festive;
  }
  // 一般节日为固定时间，可以直接查询显示
  for (i = 0; i < FESTIVE_NUM; i++) {
    if ((solarMonth === festivalTb[i].month) && (solarDay === festivalTb[i].day)) {
      festive = csvData[`cal_fes_${festivalTb[i].festive_name_chs}`]
      return festive;
    }
  }
  return festive;
}


function getSolarTerm(solarYear = 0, solarMonth = 0, solarDay = 0) {
  let year = 0
  let month = 0
  let day = 0
  let k = 0;
  let term = ''

  if (!isValidHmTimeDate(solarYear)) {
    return term;
  }
  year = solarYear % 2000;
  month = solarMonth;
  day = solarDay;

  if (day === termTb[(year - 1) * 24 + (month - 1) * 2]) {
    k = (month - 1) * 2 + 1;
  }
  if (day === termTb[(year - 1) * 24 + (month - 1) * 2 + 1]) {
    k = (month - 1) * 2 + 2;
  }
  if (k !== 0) {
    term = csvData[`cal_jeiqi_${k}`]
    return term;
  }
  return term;
}

/**
 * 获取农历信息显示
 * @param {*} date
 * @param {*} type 0: 仅显示农历 1: 显示农历+节日节气，节日节气 > 农历 2：仅显示节日
 * @returns
 */
function getLunarText(date, type = 0) {
  const dayjsInstance = dayjs(date)
  const year = dayjsInstance.get('year')
  const month = dayjsInstance.get('month') + 1 // dayjs month 从 0 开始，需要 + 1
  const day = dayjsInstance.date()
  const dayOfWeek = dayjsInstance.day()
  const { year: lunarYear, month: lunarMonth, day: lunarDay, leapMonth } = solarToLunar(year, month, day)
  const leapMonthFirstDay = leapMonth === 1 && lunarDay === 1 ? csvData.cal_run : ''
  const lunarMonthText = csvData[`cal_month_${lunarMonth}`]
  const lunarDayText = csvData[`cal_day_${lunarDay}`]
  let lunarText = lunarDayText ? `${lunarMonthText}${lunarDayText}` : ''

  // 闰月初一显示闰几月
  if (leapMonthFirstDay && lunarMonthText) {
    lunarText = `${leapMonthFirstDay}${lunarMonthText}`
  }

  if (type === 0) {
    return lunarText
  }

  const lunarFestival = getFestivalLunar(lunarYear, lunarMonth, lunarDay, leapMonth)
  const solarFestival = getFestiveSolar(month, day, dayOfWeek)
  const term = getSolarTerm(year, month, day)
  // 优先级与固件一致 公历 > 农历 > 节气
  const festival = solarFestival || lunarFestival || term

  if (type === 1) {
    return festival || lunarText
  }

  if (type === 2) {
    return festival
  }

  return ''
}

export { getLunarText, getFestivalLunar, getFestiveSolar, getSolarTerm, solarToLunar, csvData }
