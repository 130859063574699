export function renderCells({
  com,
  parentKey,
  childKey,
  keyPrefix,
  onInsertImage,
  onInsertVertex,
  onRemove,
  onUpdateAttr,
  isExist,
  canFollowed,
  editableComponentKey,
  renderLayerByLang,
  ...restArg
}) {
  let childK = childKey
  if (childKey === 'am' || childKey === 'pm') {
    childK = 'ampm'
  }
  const { imageEntity, vertexEntity } = com.getCellDatas({ parentKey, childKey, ...restArg })
  const uniqueKey = childKey && !canFollowed ? `${keyPrefix}-${childK}` : keyPrefix
  //   const cellKey = (childKey && !canFollowed) ? `${parentKey}-${childKey}`: parentKey;
  const { imageKeys: oldImageKeys = [], vertexKeys: oldVertexKeys = [] } = com.keyMap[uniqueKey] || {}
  const imageKeys = Object.keys(imageEntity)
  const vertexKeys = Object.keys(vertexEntity)
  // remove cell
  for (const oldKey of oldImageKeys) {
    if (!imageKeys.includes(oldKey)) {
      if (canFollowed) {
        // 处理数字组件从跟随情况下切换到独立导致元素删除的情况
        oldKey.includes(childK) && onRemove(`${uniqueKey}-${oldKey}`)
      } else {
        onRemove(`${uniqueKey}-${oldKey}`)
      }
    }
  }

  for (const oldKey of oldVertexKeys) {
    if (!vertexKeys.includes(oldKey)) {
      if (canFollowed) {
        // 处理数字组件从跟随情况下切换到独立导致元素删除的情况
        oldKey.includes(childK) && onRemove(`${uniqueKey}-${oldKey}`)
      } else {
        onRemove(`${uniqueKey}-${oldKey}`)
      }
      // onRemove(`${uniqueKey}-${oldKey}`)
    }
  }
  // render cell
  for (const key of imageKeys) {
    const item = imageEntity[key]
    const isExisted = isExist(`${uniqueKey}-${key}`)

    isExisted ? onUpdateAttr(`${uniqueKey}-${key}`, item) : onInsertImage(editableComponentKey || parentKey, `${uniqueKey}-${key}`, item)
  }

  for (const key of vertexKeys) {
    const item = vertexEntity[key]

    if (item.type === 'rect' && renderLayerByLang) {
      // 生成预览图时不绘制辅助线
      onRemove(`${uniqueKey}-${key}`)
      return
    }

    if (!isExist(`${uniqueKey}-${key}`)) {
      onInsertVertex(editableComponentKey || parentKey, `${uniqueKey}-${key}`, item)
    } else {
      onUpdateAttr(`${uniqueKey}-${key}`, item)
    }
  }
  if (uniqueKey in com.keyMap) {
    return {
      [uniqueKey]: {
        imageKeys: [...com.keyMap[uniqueKey].imageKeys, ...imageKeys],
        vertexKeys: Array.from(new Set([...com.keyMap[uniqueKey].vertexKeys, ...vertexKeys]))
      }
    }
  }
  return {
    [uniqueKey]: {
      imageKeys,
      vertexKeys
    }
  }
}

export function removeCells({ com, childKey, keyPrefix, onRemove, canFollowed }) {
  const uniqueKey = childKey && !canFollowed ? `${keyPrefix}-${childKey}` : keyPrefix
  const { imageKeys: oldImageKeys = [], vertexKeys: oldVertexKeys = [] } = com.keyMap[uniqueKey] || {}
  const restImageKeys = []
  const restVertexKeys = []
  let deleteImageKeys = oldImageKeys
  let deleteVertexKeys = oldVertexKeys

  if (canFollowed) {
    deleteImageKeys = []
    deleteVertexKeys = []
    oldImageKeys.forEach((key) => {
      if (key.includes(childKey)) {
        deleteImageKeys.push(key)
      } else {
        restImageKeys.push(key)
      }
    })
    oldVertexKeys.forEach((key) => {
      if (key.includes(childKey)) {
        deleteVertexKeys.push(key)
      } else {
        restVertexKeys.push(key)
      }
    })
  }

  for (const oldKey of deleteImageKeys) {
    onRemove(`${uniqueKey}-${oldKey}`)
  }

  for (const oldKey of deleteVertexKeys) {
    onRemove(`${uniqueKey}-${oldKey}`)
  }

  // eslint-disable-next-line no-param-reassign
  com.keyMap[uniqueKey] = {
    imageKeys: restImageKeys,
    vertexKeys: restVertexKeys
  }

  return com.keyMap
}
