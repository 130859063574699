import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import watchFace from './reducer/index'

export const history = createBrowserHistory()

/**
 * 合并reducer逻辑，集成了immutable
 */
const reducer = combineReducers({
  router: connectRouter(history),
  watchFace
})

export default reducer
