const BG_GROUP = {
  count: 2, // 可编辑背景图片的数量
  bg_images: [], // 背景图片组
  preview_images: [], // 预览图片组
  fg_images: [], // 选中时的高亮图片
  positions: [
    // 图片位置
    {
      x: 0,
      y: 0
    }
  ]
}

export default BG_GROUP
