import Cookies from 'js-cookie'

const env = process.env.NODE_ENV

type ApiType = 'mifit' | 'account' | 'user' | 'auth' | string

function getHost(env: string, domain = 'huami.com') {
  return env === 'production' ? `user.${domain}` : `user-testing.${domain}`
}

export default function universalLogin({
  projectName = '',
  redirectUrl = '',
  platformAppName = 'com.huami.webapp',
  specifyLang = '',
  domain = 'huami.com'
}: {
  projectName: string
  redirectUrl: string
  platformAppName?: string
  specifyLang?: string
  domain?: string
}) {
  window.location.href = `https://${getHost(
    env,
    domain
  )}/universalLogin/index.html#/login?project_name=${projectName}&project_redirect_uri=${encodeURIComponent(
    redirectUrl
  )}&platform_app=${platformAppName}&specify_lang=${specifyLang}`
}

export function universalRegister({
  projectName = '',
  redirectUrl = '',
  platformAppName = 'com.huami.webapp',
  specifyLang = '',
  domain = 'huami.com'
}: {
  projectName: string
  redirectUrl: string
  platformAppName?: string
  specifyLang?: string
  domain?: string
}) {
  window.location.href = `https://${getHost(
    env,
    domain
  )}/universalLogin/index.html#/register?project_name=${projectName}&project_redirect_uri=${encodeURIComponent(
    redirectUrl
  )}&platform_app=${platformAppName}&specify_lang=${specifyLang}`
}

interface UserInfo {
  appToken: string
  userId: string
  domains: any[]
  [key:string]: any
}

export function getUniversalLoginInfo(): UserInfo {
  const accountLoginInfo: any = JSON.parse(Cookies.get('hm-user-login-info') || '{}')
  const appToken = Cookies.get('apptoken') || ''
  const userId = Cookies.get('userid') || ''
  const domains = accountLoginInfo.domains || JSON.parse(Cookies.get('domains') || '[]')

  return {
    token_info: {},
    regist_info: {},
    thirdparty_info: {},
    ...accountLoginInfo,
    appToken,
    userId,
    domains
  }
}

export function getApiHost(apiType: ApiType) {
  const { domains = [] } = getUniversalLoginInfo()
  const mifitDomains = domains.filter((item: any)=> item.host.includes(apiType))

  if (Array.isArray(mifitDomains)) {
    return mifitDomains[0]?.cnames?.[0] || ''
  }

  return ''
}
