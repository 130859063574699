/* eslint-disable import/order */
import React, { PureComponent } from 'react'
import { immutableSelector } from '@/utils'
import { Upload } from '@watchface/components'
import { renderCells } from '@watchface/utils/render'
import T from '@watchface/components/I18n'

import './index.scss'

const noop = () => {} // eslint-disable-line

class Scale extends PureComponent {
  static keyMap = {}

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      Scale.renderLayer(this.props)
    }
  }

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: Scale,
      ...param
    })
    Scale.keyMap = Object.assign(Scale.keyMap, keyMap)
  }

  static getCellDatas = ({ data }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const image = Scale.getImage({ data })
    if (image) {
      cells.imageEntity[image.key] = image
    }
    return cells
  }

  static getImage = ({ data }) => {
    const { image = '' } = immutableSelector(data)
    if (image) {
      return {
        key: `image`,
        texture: image,
        lefx: 0,
        top: 0
      }
    }
    return null
  }

  handleInsertImage = (img) => {
    const { data, parentKey, onChange = noop } = this.props
    let newData = data
    if (img && img.image) {
      newData = data.set('image', img.image)
    } else {
      newData = data.set('image', '')
    }
    onChange([parentKey], newData)
  }

  render() {
    const { data, watchConfig } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)

    const image = data.get('image')
    return (
      <>
        <div className="name is-required">
          <T id="scale_img" />
        </div>
        <div className="content">
          <Upload
            className="item"
            fileCountLimit={1}
            maxWidth={screenWidth}
            maxHeight={screenHeight}
            fileList={image ? [{ image }] : []}
            onChange={this.handleInsertImage}
          />
        </div>
      </>
    )
  }
}

export default Scale
