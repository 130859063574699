import { getKeyOrIdFromStr, META_COMPONENT_MAP } from '@watchface/components/Meta'
import { MOVE_IMAGE } from '@watchface/constants'
import { changeMenuName, updateCategoryProperty, updateWidgetName } from '@watchface/store/actions'
import { Collapse } from 'antd'
import Immutable from 'immutable'
import PubSub from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertJson2FabricImage, orderedCategoriesKey } from './config'
import './index.scss'

const { Panel } = Collapse

interface IProps {
  parentKey: string
  dialType: 'normal' | 'idle'
  watchConfig: Immutable.Map<string, number>
  isExist: (key: string) => boolean
  onUpdateAttr: (key: string, options: any) => void
  onRemove: (key: string) => void
  onInsertImage: (parentKey: string, key: string, options: any) => void
}

const ImageComponent: React.FC<IProps> = (props) => {
  const { parentKey, dialType, watchConfig, onUpdateAttr, onRemove, onInsertImage, isExist } = props
  const screenWidth = watchConfig?.get('screenWidth')
  const screenHeight = watchConfig?.get('screenHeight')
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState([])
  const [activeKey, setActiveKey] = useState<string[]>([])
  const preTexture = useRef('')
  const { id: activeWidget } = getKeyOrIdFromStr(parentKey)

  const widget = useSelector((state: any) => {
    return state.getIn(['watchFace', 'scene', 'widgets', activeWidget], null)
  })

  useEffect(() => {
    const categories = widget.get('categories').toJS()
    const activeKey: string[] = []
    const categoryList: any = orderedCategoriesKey
      .map((categoryKey) => {
        const category = categories[categoryKey]
        if (!category) return
        const { type, display, isExpanded, visibility, properties } = category

        if (isExpanded) {
          activeKey.push(type)
        }
        return { type, display, visibility, properties, categoryKey }
      })
      .filter((category) => category?.visibility)

    setCategoryList(categoryList)
    setActiveKey(activeKey)

    const options = convertJson2FabricImage(widget.toJS())
    const key = `${dialType}_${widget.get('type')}_${activeWidget}`

    if (preTexture.current && !options?.texture.toString()) {
      onRemove(key)
      preTexture.current = ''
    } else {
      isExist(key) ? onUpdateAttr(key, options) : onInsertImage('', key, options)
    }

    preTexture.current = options?.texture.toString()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, dialType])

  const onChange = useCallback(
    (category, property, value) => {
      if (category === 'name') {
        dispatch(updateWidgetName(activeWidget, value))
        dispatch(changeMenuName(dialType, [parentKey], value))
      }
      dispatch(updateCategoryProperty(activeWidget, category, property, value))
    },
    [dispatch, dialType, parentKey, activeWidget]
  )

  const onCollapseChange = (value: any) => {
    setActiveKey(value)
  }

  useEffect(() => {
    PubSub.subscribe(MOVE_IMAGE, (e: any, { key, left, top }: { [key: string]: any }) => {
      // 确保移动的是当前文本组件
      if (key.includes(activeWidget)) {
        onChange('placement', 'x', left)
        onChange('placement', 'y', top)
      }
    })
  }, [onChange, activeWidget])

  const propsMap = {
    image: { screenWidth, screenHeight },
    placement: { screenWidth, screenHeight }
  }

  return (
    <Collapse className="meta-collapse" activeKey={activeKey} bordered expandIconPosition="right" ghost onChange={onCollapseChange}>
      {categoryList.map((category) => {
        const { type, display, properties, categoryKey } = category
        const PropertyComponent: any = META_COMPONENT_MAP[type]

        return (
          <Panel header={display} key={type}>
            <PropertyComponent onChange={onChange} properties={properties} categoryKey={categoryKey} {...propsMap[type]} />
          </Panel>
        )
      })}
    </Collapse>
  )
}

export default ImageComponent
