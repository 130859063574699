import T from '@watchface/components/I18n'
import { connect } from 'react-redux'
import React from 'react'
import './feedback.scss'

const Feedback = () => {
  return (
    <div className="watch-skin-form-wrapper watch-skin-create">
      <div className="wf-feedback">
        <div className="title">
          <T id="feedback" />
        </div>
        <div className="pannel">
          <p><T id="feedback_tip"/></p>
          <dl className="connect-2-us">
            <dt><T id="feedback_method_1"/></dt>
            <dd>
              <a href="https://wenjuan.feishu.cn/m/cfm?t=sgJV5zv5VDBi-pvpq">https://wenjuan.feishu.cn/m/cfm?t=sgJV5zv5VDBi-pvpq</a>
            </dd>
            <dt><T id="feedback_method_2"/></dt>
            <dd>
              <div className="code-image">
                <img width={200} src="https://img-cdn.huami.com/20220421/c15a5a40f03e9465167407499815d821.png" />
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default connect()(Feedback)
