export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.WATCHFACE_EDIT_MASK,{
      x: 0,
      y: 0,
      w: {{ width }},
      h: {{ height }},
      {{ #cover }}
      src: '{{{ cover }}}',
      {{ /cover }}
      show_level:hmUI.show_level.ONLY_EDIT,
    });
  `
