/* eslint-disable */
import React from 'react'
import { Col, Radio, Row, Space } from 'antd'
import SizeCheckBoxGroup from './SizeCheckBoxGroup'
import T from '../../I18n'

enum SPAN {
  COL1 = 6,
  COL2 = 18
}

interface IProps {
  sizes: string[]
  setData: (key: string, value: any) => void
  languages: string[]
  deviceSources: string[]
  shape: string
  selectedSizes: string[]
}

const ExportImageModal: React.FC<IProps> = (props) => {
  const { sizes = [], languages = [], deviceSources, setData, shape, selectedSizes } = props

  const langMap: any = {
    zh: <T id="lang_zh" />,
    'zh-Hant': <T id="lang_zh-hant" />,
    en: <T id="lang_en" />,
    all: <T id="lang_all" />
  }

  const langOptions: any = languages.map((lang: any) => ({
    label: langMap[lang],
    value: lang
  }))

  const sizeDescMap: any = {
    round: [<T id="size_desc_round_screen_resolution" />, <T id="size_desc_round_default" />],
    square: [<T id="size_desc_square_screen_resolution" />, <T id="size_desc_square_default" />]
  }

  const sizeDesc = () => {
    return (
      <div className="size-desc">
        {sizes.map((item: string, index: number) => (
          <div key={index} className={selectedSizes.indexOf(item) > -1 ? 'active' : ''}>
            {item}：{sizeDescMap?.[shape]?.[index]}
          </div>
        ))}
      </div>
    )
  }

  return (
    <Space direction="vertical" className="canvas2image-content">
      <Row>
        <Col className="conf-item-name" span={SPAN.COL1}>
          <T id="size" />
        </Col>
        <Col span={SPAN.COL2}>
          <SizeCheckBoxGroup
            sizes={sizes}
            deviceSources={deviceSources}
            onChange={(sizes: string[], showWatchStrap: boolean, deviceSource: string) => {
              setData('selectedSizes', sizes)
              setData('showWatchStrap', showWatchStrap)
              setData('deviceSource', deviceSource)
            }}
          />
          {sizeDesc()}
        </Col>
      </Row>
      <Row>
        <Col className="conf-item-name" span={SPAN.COL1}>
          <T id="language" />
        </Col>
        <Col span={SPAN.COL2}>
          <Radio.Group
            key={languages?.length}
            options={langOptions}
            defaultValue={languages?.[0]}
            onChange={(e: any) => setData('lang', e?.target?.value)}
          />
        </Col>
      </Row>
    </Space>
  )
}

export default ExportImageModal
