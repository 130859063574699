import { ExclamationCircleFilled } from '@ant-design/icons'
import T from '@watchface/components/I18n'
import { Button, Modal } from 'antd'
import React from 'react'

interface UploadModalProps {
  message: string | React.ReactNode
  isVisible: boolean
  onOk: (...args: any) => void
}

class UploadModal extends React.PureComponent<UploadModalProps> {
  render() {
    const { message, isVisible, onOk } = this.props
    return (
      <Modal className="watch-skin-upload-modal" visible={isVisible} onCancel={onOk} footer={null}>
        <ExclamationCircleFilled
          className="export-status-icon"
          style={{
            color: '#D8D8D8',
            fontSize: '80px'
          }}
        />
        <div className="title">
          <T id="upload_fail" />
        </div>
        <div className="message">{message}</div>
        <Button type="primary" onClick={onOk}>
          <T id="confirm" />
        </Button>
      </Modal>
    )
  }
}

export default UploadModal
