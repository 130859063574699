import AMPM from '@watchface/pages/DesignTool/NonZeppOs/components/AmPm/config'
import ANALOG_CLOCK_ITEM from '@watchface/pages/DesignTool/NonZeppOs/components/AnalogClockComponent/config'
import createBackgroundData from '@watchface/pages/DesignTool/NonZeppOs/components/BackgroundComponent/config'
import EDITABLE from '@watchface/pages/DesignTool/NonZeppOs/components/EditableComponent/config'
import ICON from '@watchface/pages/DesignTool/NonZeppOs/components/IconComponent/config'
import JUMPABLE from '@watchface/pages/DesignTool/NonZeppOs/components/JumpHotAreaComponent/config'
import MASK from '@watchface/pages/DesignTool/NonZeppOs/components/MaskComponent/config'
import createNumberData from '@watchface/pages/DesignTool/NonZeppOs/components/NumberComponent/config'
import PIC from '@watchface/pages/DesignTool/NonZeppOs/components/PicComponent/config'
import IMAGE_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PictureProgress/config'
import POINTER_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PointerProgressComponent/config'
import getProgress from '@watchface/pages/DesignTool/NonZeppOs/components/ProgressComponent/config'
import SCALE from '@watchface/pages/DesignTool/NonZeppOs/components/ScaleComponent/config'
import Immutable from 'immutable'
// 配置文件按照模块拆分组合

const DIGITAL_CLOCK = {
  children: {
    hour: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    minute: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    second: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    am: AMPM,
    pm: AMPM
  },
  follow_time: false,
  align: 'left'
}

// 指针时间
const ANALOG_CLOCK = {
  children: {
    hour: ANALOG_CLOCK_ITEM,
    minute: ANALOG_CLOCK_ITEM,
    second: ANALOG_CLOCK_ITEM
  }
}

const PROGRESS_CLOCK = {
  children: {
    hour: getProgress(),
    minute: getProgress(),
    second: getProgress()
  }
}

const PAI = {
  children: {
    weekly: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const AQI = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const HUMIDITY = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const UVI = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const BATTERY = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const HEART_RATE = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const CALORIE = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const WEATHER = {
  children: {
    image_progress: IMAGE_PROGRESS
  }
}

const TEMPERATURE = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    high: createNumberData({ hasSystemFont: true }),
    low: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    jumpable: JUMPABLE
  }
}

const SUN = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    high: createNumberData({ hasSystemFont: true }),
    low: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const SYSTEM = {
  children: {
    dnd: ICON,
    disconnect: ICON,
    lock: ICON,
    clock: ICON
  }
}

const DATE = {
  children: {
    year: createNumberData({ hasSystemFont: true, isPaddingZero: false }),
    month: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    day: createNumberData({ hasSystemFont: true, isPaddingZero: true })
  }
}

const STEP = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    pointer_progress: POINTER_PROGRESS,
    target: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const DISTANCE = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    jumpable: JUMPABLE
  }
}

const WEEK = {
  children: {
    text: PIC,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress()
  }
}

const MESSAGE = {
  children: {
    icon: ICON,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const PHONE = {
  children: {
    icon: ICON,
    jumpable: JUMPABLE
  }
}

const LAYER_CONFIG = {
  background: createBackgroundData({ isIdle: false }),
  scale: SCALE,
  analog_clock: ANALOG_CLOCK,
  digital_clock: DIGITAL_CLOCK,
  progress_clock: PROGRESS_CLOCK,
  pai: PAI,
  aqi: AQI,
  humidity: HUMIDITY,
  uvi: UVI,
  battery: BATTERY,
  heart_rate: HEART_RATE,
  calorie: CALORIE,
  weather: WEATHER,
  temperature: TEMPERATURE,
  sun: SUN,
  system: SYSTEM,
  date: DATE,
  step: STEP,
  distance: DISTANCE,
  week: WEEK,
  message: MESSAGE,
  phone: PHONE,
  mask: MASK
}

const COMPONENT_KEYS = [
  'message',
  'phone',
  'battery',
  'heart_rate',
  'calorie',
  'step',
  'distance',
  'pai',
  'date',
  'week',
  'weather',
  'temperature',
  'aqi',
  'humidity',
  'uvi',
  'sun'
]

COMPONENT_KEYS.forEach((key) => {
  EDITABLE.props[key] = {
    ...LAYER_CONFIG[key],
    previews: [
      {
        language: 'all',
        image: {
          url: '',
          width: 0,
          height: 0,
          name: ''
        }
      }
    ],
    bg: {
      position: {
        x: 0,
        y: 0
      },
      image: ''
    }
  }
})

LAYER_CONFIG.component = EDITABLE

export default Immutable.fromJS(LAYER_CONFIG)
