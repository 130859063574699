/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
import apiHost from '@openplatform/request/domains/common/index'
import universalLogin, { universalRegister } from '@/utils/universalLogin'
import { getQuery } from '@/utils/query-string'

export async function getLanguage(setLang = 'zh') {
  const { localeLang } = window as any
  const { getLocaleLanguage } = window as any
  const { lang } = getLocaleLanguage(window.navigator, '')
  const project = 'open-platform'
  const defaultLang = 'zh'
  const changeLang = setLang
  // 可通过传当前需要语言方式获取对应语言文件内容
  const data = await localeLang(window.navigator, project, defaultLang, changeLang)
  return { data, currentLang: lang }
}

// export function visualTrans(langContent: any, project: string, lang: string, attr: string) {
//   // const getTransEle = (window as any).getTransEle
//   // getTransEle(langContent, project, lang, attr)
// }

export function isH5() {
  const sUserAgent = navigator.userAgent.toLowerCase()
  const regList = [/ipad/i, /iphone os/i, /midp/i, /rv:1.2.3.4/i, /ucweb/i, /android/i, /windows ce/i, /windows mobile/i]
  return regList.some((reg) => reg.test(sUserAgent))
}

export function getCookie(name: string) {
  try {
    const bikky = document.cookie
    name += '='
    let i = 0
    while (i < bikky.length) {
      const offset = i + name.length
      if (bikky.substring(i, offset) === name) {
        let endstr = bikky.indexOf(';', offset)
        if (endstr === -1) {
          endstr = bikky.length
        }
        const returnValue = decodeURI(bikky.substring(offset, endstr))
        return returnValue
      }
      i = bikky.indexOf(' ', i) + 1
      if (i === 0) {
        break
      }
    }
  } catch (e) {
    throw new Error(e)
  }
}

export function getBase64(img: Blob, callback: { (imageUrl: any): any; (arg0: string | ArrayBuffer | null): any }) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const Global: any = global

export function storeHost() {
  const accountCname = localStorage.getItem('accountCname')
  const userCname = localStorage.getItem('userCname')
  const mifitCname = localStorage.getItem('mifitCname')
  const authCname = localStorage.getItem('authCname')
  let { apiUser, account, zuul, userInfoHost } = apiHost
  apiUser = userCname ? `https://${userCname}/` : apiUser
  account = accountCname ? `https://${accountCname}/` : account
  zuul = authCname ? `https://${authCname}/` : zuul
  userInfoHost = mifitCname ? `https://${mifitCname}/` : userInfoHost
  if (accountCname && userCname && mifitCname) {
    return {
      ...apiHost,
      apiUser,
      account,
      zuul,
      userInfoHost
    }
  }
  return null
}

export function resetHost() {
  if (storeHost()) {
    Global.hostConfig = storeHost()
  } else {
    Global.hostConfig = apiHost
  }
}

resetHost()

export function handlerCnames(domains: []) {
  return new Promise((reslove) => {
    if (storeHost()) {
      Global.hostConfig = storeHost()
      reslove(Global.hostConfig)
      return
    }
    domains.forEach((domainsItem: { cnames: Array<string>; host: string }) => {
      const curHost = domainsItem.cnames[0]
      const curDefaultHost = domainsItem.host
      if (curHost.includes('account')) {
        localStorage.setItem('accountCname', curHost || curDefaultHost)
      }
      if (curHost.includes('api-user')) {
        localStorage.setItem('userCname', curHost || curDefaultHost)
      }
      if (curHost.includes('api-mifit')) {
        localStorage.setItem('mifitCname', curHost || curDefaultHost)
      }
      if (curHost.includes('auth')) {
        localStorage.setItem('authCname', curHost || curDefaultHost)
      }
    })
    Global.hostConfig = storeHost()
    reslove(Global.hostConfig)
  })
}
export function throttle(func: any, wait: number, options: { leading: boolean; trailing: boolean }) {
  let context: any
  let args: any
  let startTime = 0
  let timeId: any
  options = options || {}
  const { leading } = options
  const { trailing } = options

  const throttled = function (this: any) {
    const now = +new Date()
    if (!startTime && !leading) startTime = now
    const remaining = wait - (now - startTime)

    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeId) {
        clearTimeout(timeId)
        timeId = null
      }
      startTime = now
      func.apply(context, args)
    } else if (!timeId && trailing) {
      timeId = setTimeout(function () {
        startTime = leading ? 0 : +new Date()
        timeId = null
        func.apply(context, args)
      }, remaining)
    }
  }

  return throttled
}

// 判断表单是否有错误信息
export function isFieldsHasError(fieldsList: any[]): boolean {
  let hasError = false
  for (const fieldItem of fieldsList) {
    if (fieldItem.errors.length > 0) {
      hasError = true
      return true
    }
  }
  return hasError
}

// 根据语言获取应用显示名称
export function getAppName(currentLang = 'zh', appName: string, appNameEn: string) {
  if (appName && currentLang.includes('zh')) {
    return { key: 'appName', value: appName }
  } else if (appNameEn && currentLang.includes('en')) {
    return { key: 'appNameEn', value: appNameEn }
  } else {
    // eslint-disable-next-line
    return appName ? { key: 'appName', value: appName } : appNameEn ? { key: 'appNameEn', value: appNameEn } : { key: 'appName', value: '' }
  }
}

export function handleOpenPlatformLogin() {
  let regx = /^(https?):\/\/([0-9a-zA-Z]-*)+.huami.com/
  let stagingUrl = 'https://dev-staging.huami.com/#/home'
  let prodUrl = 'https://dev.huami.com/#/home'
  let domain = 'huami.com'
  if (window.location.host.includes('.zepp.com')) {
    // 启用zepp域名
    stagingUrl = 'https://console-staging.zepp.com/?partner=business#/'
    prodUrl = 'https://console.zepp.com/?partner=business#/'
    regx = /^(https?):\/\/([0-9a-zA-Z]-*)+.zepp.com/
    domain = 'zepp.com'
  }
  const isPro = process.env.NODE_ENV === 'production'
  let redirectUrl = isPro ? prodUrl : stagingUrl
  const curLang = localStorage.getItem('curLang') || 'en'

  const { referer } = getQuery()
  if (referer && regx.test(referer as string)) {
    redirectUrl = referer as string
  }
  universalLogin({
    projectName: 'open_platform',
    redirectUrl,
    platformAppName: 'com.huami.webapp',
    specifyLang: curLang,
    domain
  })
}

export function handleOpenPlatformRegister() {
  const isPro = process.env.NODE_ENV === 'production'
  let stagingUrl = 'https://dev-staging.huami.com/#/home'
  let prodUrl = 'https://dev.huami.com/#/home'
  let domain = 'huami.com'
  if (window.location.host.includes('.zepp.com')) {
    // 启用zepp域名
    stagingUrl = 'https://console-staging.zepp.com/?partner=business#/'
    prodUrl = 'https://console.zepp.com/?partner=business#/'
    domain = 'zepp.com'
  }

  const redirectUrl = isPro ? prodUrl : stagingUrl
  const curLang = localStorage.getItem('curLang') || 'en'

  universalRegister({
    projectName: 'open_platform',
    redirectUrl,
    platformAppName: 'com.huami.webapp',
    specifyLang: curLang,
    domain
  })
}

export function handleConsoleRedirect(path = '/') {
  const isPro = process.env.NODE_ENV === 'production'
  window.location.href = isPro ? `https://console.zepp.com/?partner=business#${path}` : `https://console-staging.zepp.com/?partner=business#${path}`
}

// 获取二级域名 second-level domain
export function getSLD() {
  const domain = window.location.hostname
  const sld = domain.split('.').reverse()[1]

  return sld || 'zepp'
}
