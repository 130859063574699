import React, { PureComponent } from 'react'
import Classnames from 'classnames'
import Immutable from 'immutable'
import { immutableSelector } from '@/utils'
import { NumberComponent, NumberComponentWithRect } from '../../components'
import AmPm from '../../components/AmPm'
import './index.scss'

const noop = () => {} // eslint-disable-line
const TIME_ARR = ['hour', 'minute', 'second']
class DigitalClock extends PureComponent {
  static renderLayer = ({ parentKey, childKey, hasRectArea, ...restArg }) => {
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent
    const paddingX = DigitalClock.getNumberComponentPaddingX({ parentKey, childKey, hasRectArea, ...restArg })
    if (childKey === 'am' || childKey === 'pm') {
      AmPm.renderLayer({ parentKey, childKey, hasRectArea, paddingX, ...restArg })
    } else {
      NumComponent.renderLayer({ parentKey, childKey, paddingX, ...restArg })
    }
  }

  static getWidthHeight = ({ allData, parentKey }) => {
    const imagePathArr = Immutable.fromJS([
      [parentKey, 'children', 'am', 'images', 0, 'image'],
      [parentKey, 'children', 'am', 'images', 1, 'image'],
      [parentKey, 'children', 'pm', 'images', 0, 'image'],
      [parentKey, 'children', 'pm', 'images', 1, 'image']
    ])

    let width = 0
    let height = 0
    for (let i = 0; i < imagePathArr.size; i += 1) {
      const path = imagePathArr.get(i)
      const item = allData.getIn(path)
      const { width: w, height: h } = immutableSelector(item)
      if (w && h) {
        width = w
        height = h
        break
      }
    }

    return {
      width,
      height
    }
  }

  static getNumberComponentPaddingX = ({ parentKey, allData, allAssets, dialType }) => {
    const { width } = DigitalClock.getWidthHeight({ parentKey, allData })
    const show = allAssets.getIn([dialType, parentKey, 'children', 'ampm', 'show'])
    const align = allData.getIn([parentKey, 'align'])
    if (align === 'left') {
      return 0
    }
    if (show !== 'none') {
      return 0
    }
    if (align === 'right') {
      return width
    }
    if (align === 'center') {
      return width / 2
    }
    return 0
  }

  renderTime = (hasRectArea) => {
    const { parentKey, childKey, assets, allData, allAssets, dialType, propMap = {} } = this.props
    const prop = propMap[childKey] || {}
    const allProps = { ...this.props, ...prop }
    const cls = Classnames(['digitalClock-container', 'control-container'])
    const paddingX = DigitalClock.getNumberComponentPaddingX({ parentKey, assets, allData, allAssets, dialType })
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent
    return (
      <div className={cls} key={childKey}>
        <NumComponent {...allProps} parentKey={parentKey} paddingX={paddingX} />
      </div>
    )
  }

  renderAmPm = () => {
    const cls = Classnames(['digitalClock-container', 'control-container'])
    return (
      <div className={cls}>
        <AmPm {...this.props} />
      </div>
    )
  }

  render() {
    const { childKey, propMap = {} } = this.props
    const { hasRectArea } = propMap[childKey] || {}

    if (TIME_ARR.includes(childKey)) {
      return this.renderTime(hasRectArea)
    }
    return this.renderAmPm()
  }
}

export default DigitalClock
