/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE } from '@watchface/constants'
import { SELECT_STYLE } from '@watchface/constants/style'
import { getDefaultLangField, getOriginLang, getRotatedPoint, isHitKey } from '@watchface/utils'
import { removeCells, renderCells } from '@watchface/utils/render'
import { Select } from 'antd'
import Immutable from 'immutable'
import { compact, debounce } from 'lodash'
import PubSub from 'pubsub-js'
import React, { PureComponent } from 'react'

const LANG_MAPPER = {
  all: {
    label: <T id="thumbnail_all" />,
    field: 'all'
  },
  zh: {
    label: <T id="thumbnail_zh" />,
    field: 'zh'
  },
  'zh-Hant': {
    label: <T id="thumbnail_zh-hant" />,
    field: 'zh-Hant'
  },
  sc: {
    label: <T id="thumbnail_zh&zh-hant" />,
    field: 'zh'
  },
  en: {
    label: <T id="thumbnail_en" />,
    field: 'en'
  }
}
const { Option } = Select
const zIndexMap = {
  scale: 0,
  pointer: 1,
  cover: 2,
  rotationCenter: 21 // 保持旋转中心 zIndex 比 cover 高即可
}

// Math.abs(startAngle - endAngle) <= 360
const limitAngleRange = (startAngle, endAngle, changeKey = 'start') => {
  const isStartLarge = startAngle - endAngle > 0

  if (Math.abs(startAngle - endAngle) > 360) {
    if (changeKey === 'start') {
      return isStartLarge ? [startAngle, startAngle - 360] : [startAngle, startAngle + 360]
    }
    return isStartLarge ? [endAngle + 360, endAngle] : [endAngle - 360, endAngle]
  }
  return [startAngle, endAngle]
}

class PointerProgressComponent extends PureComponent {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: PointerProgressComponent,
      ...param
    })
    PointerProgressComponent.keyMap = Object.assign(PointerProgressComponent.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: PointerProgressComponent,
      ...param
    })
    PointerProgressComponent.keyMap = keyMap
  }

  componentDidMount() {
    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      const { parentKey, childKey, editableComponentKey = '', assets, data, dialType } = this.props

      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      const { center_position = Immutable.Map(), pointer = Immutable.Map(), angle = Immutable.Map() } = immutableSelector(data)
      const { previewAngle = 0 } = immutableSelector(assets || Immutable.Map())
      const { x: centerX, y: centerY } = immutableSelector(center_position)
      const { position: pointerPosition } = immutableSelector(pointer)
      const { x: pointerX, y: pointerY } = immutableSelector(pointerPosition)
      const { start: originStart, end: originEnd } = immutableSelector(angle)
      const [start, end] = limitAngleRange(originStart, originEnd)
      // 逆时针旋转
      const isCounterclockwise = start > end
      const rotate = isCounterclockwise ? start - previewAngle : start + previewAngle
      const rotatedPoint = getRotatedPoint({ x: centerX - pointerX, y: centerY - pointerY }, { x: centerX, y: centerY }, rotate)
      const distX = left - Math.round(rotatedPoint.x)
      const distY = top - Math.round(rotatedPoint.y)
      const newCenterX = centerX + distX
      const newCenterY = centerY + distY

      this.handleChange(['center_position'], 'pointer', '', false, {
        x: newCenterX,
        y: newCenterY
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props
    if (prevProps !== this.props) {
      if (!hidden) {
        PointerProgressComponent.renderLayer(this.props)
      } else {
        PointerProgressComponent.removeLayer(this.props)
      }
    }
  }

  static getCellDatas = ({ parentKey, childKey, data, assets, renderLayerByLang }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }

    const images = PointerProgressComponent.getImageArr({ childKey, parentKey, data, assets, renderLayerByLang })
    images.forEach((image) => {
      cells.imageEntity[image.key] = image
    })

    const rotationCenter = PointerProgressComponent.getRotationCenter({ childKey, data })
    if (rotationCenter) {
      cells.vertexEntity[rotationCenter.key] = rotationCenter
    }
    return cells
  }

  static getImageArr = ({ data, assets, renderLayerByLang }) => {
    const {
      center_position = Immutable.Map(),
      scale = Immutable.Map(),
      pointer = Immutable.Map(),
      cover = Immutable.Map(),
      angle = Immutable.Map()
    } = immutableSelector(data)
    const { languages = 'all', previewAngle = 0 } = immutableSelector(assets || Immutable.Map())
    const defaultLang = getDefaultLangField(languages, LANG_MAPPER)
    const previewLang = renderLayerByLang || assets.get('previewLang') || defaultLang
    const { x: centerX, y: centerY } = immutableSelector(center_position)
    const { position: scalePosition = Immutable.Map(), images = Immutable.List() } = immutableSelector(scale)
    const { x: scaleX, y: scaleY } = immutableSelector(scalePosition)
    const scaleImage = (images || Immutable.List()).find((item) => item.get('language') === previewLang) || Immutable.Map()
    const scaleImageUrl = scaleImage.getIn(['image', 'url']) || ''

    const { position: pointerPosition, image: pointerImage } = immutableSelector(pointer)
    const { x: pointerX, y: pointerY } = immutableSelector(pointerPosition)
    const { position: coverPosition, image: coverImage } = immutableSelector(cover)
    const { x: coverX, y: coverY } = immutableSelector(coverPosition)
    const { start: originStart, end: originEnd } = immutableSelector(angle)
    const [start, end] = limitAngleRange(originStart, originEnd)
    // 逆时针旋转
    const isCounterclockwise = start > end

    const imageArr = []
    if (coverImage) {
      const key = 'cover'
      imageArr.push({
        key,
        texture: coverImage,
        left: coverX,
        top: coverY,
        zIndex: zIndexMap[key]
      })
    }
    if (pointerImage) {
      const key = 'pointer'
      const x = centerX - pointerX
      const y = centerY - pointerY
      const rotate = isCounterclockwise ? start - previewAngle : start + previewAngle
      const rotatedPoint = getRotatedPoint({ x, y }, { x: centerX, y: centerY }, rotate)

      imageArr.push({
        left: rotatedPoint.x,
        top: rotatedPoint.y,
        key,
        zIndex: zIndexMap[key],
        texture: pointerImage,
        angle: rotate
      })
    }

    if (scaleImageUrl) {
      const key = `${languages}scale`
      imageArr.push({
        key,
        texture: scaleImageUrl,
        left: scaleX,
        top: scaleY,
        zIndex: zIndexMap.scale
      })
    }

    return imageArr
  }

  static getRotationCenter = ({ data }) => {
    const { x: left = 0, y: top = 0 } = immutableSelector(data.get('center_position'))
    const key = 'rotationCenter'
    return {
      key,
      type: 'circle',
      visible: false,
      radius: 1,
      fill: '#1977e2',
      left: left - 1,
      top: top - 1,
      selectable: false,
      zIndex: zIndexMap[key]
    }
  }

  handleLangChange = (key, value) => {
    const { parentKey, childKey, dialType, data = Immutable.Map(), assets = Immutable.Map(), onChange, onAssetsChange, prefixPath } = this.props
    const imagesPath = ['scale', 'images']
    const rootPath = [parentKey, 'children', childKey]
    let newAssets = assets.set(key, value)

    if (key === 'languages') {
      const newData = data.setIn(imagesPath, Immutable.List())
      newAssets = newAssets.set('previewLang', '')
      onChange([...prefixPath, ...rootPath], newData)
    } else if (key === 'previewLang') {
      // const key = `${parentKey}-${childKey}-${languages}-scale`
      // const scaleImages = data.getIn(imagesPath) || Immutable.List()
      // const scaleImage = scaleImages.find(item => item.get('language') === value) || Immutable.Map()
      // const url = scaleImage.getIn(['image', 'url'])
      // const position =  data.getIn(['scale', 'position']) || Immutable.Map()
      // const { x, y } = immutableSelector(position)
      // if (url) {
      //   if (isExist(imgKey)) {
      //     onUpdateAttr(key, { texture: url })
      //   } else {
      //     onInsertImage(
      //       parentKey,
      //       imgKey,
      //       {
      //         texture: url,
      //         x,
      //         y
      //       }
      //     )
      //   }
      // }
    }

    onAssetsChange([dialType, ...prefixPath, ...rootPath], newAssets)
  }

  handleChange = (path, option, prop, isAssets, val, field) => {
    let value = val
    const { childKey, dialType, data, parentKey, onChange, assets, prefixPath, onAssetsChange } = this.props

    let newData = data
    let newAssets = assets

    if (isAssets) {
      newAssets = newAssets.setIn(compact([...path, prop]), value || 0)
      onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey], newAssets)
    } else if (prop === 'transformOriginX' || prop === 'transformOriginY') {
      if (prop === 'transformOriginX') {
        newData = newData.setIn([...path, 'x'], value)
      } else {
        newData = newData.setIn([...path, 'y'], value)
      }
    } else {
      if (prop === 'image') {
        value = value && value.image ? value.image : ''
      }
      if (option === 'scale' && prop === 'images') {
        let scaleImages = newData.getIn(compact([...path, prop])) || Immutable.List()
        const index = scaleImages.findIndex((item) => item.get('language') === field)

        if (index === -1) {
          scaleImages = scaleImages.push(
            Immutable.fromJS({
              language: field,
              image: {
                url: value && value.image ? value.image : ''
              }
            })
          )
        } else {
          scaleImages = scaleImages.set(
            index,
            Immutable.fromJS({
              language: field,
              image: {
                url: value && value.image ? value.image : ''
              }
            })
          )
        }

        newData = newData.setIn(compact([...path, prop]), scaleImages)
      } else {
        newData = newData.setIn(compact([...path, prop]), value)
      }
    }

    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleAngleChange = (value, key, angle) => {
    let newVal = value

    if (value === '') newVal = 0

    const { start: originStart, end: originEnd } = immutableSelector(angle)
    const args = key === 'start' ? [newVal, originEnd, key] : [originStart, newVal, key]
    const [start, end] = limitAngleRange(...args)
    this.handleChange(
      ['angle'],
      '',
      '',
      false,
      Immutable.fromJS({
        start,
        end
      })
    )
  }

  render() {
    const { data = Immutable.Map(), renderLayerByLang, watchConfig, assets = Immutable.Map(), metas } = this.props
    const {
      center_position = Immutable.Map(),
      scale = Immutable.Map(),
      pointer = Immutable.Map(),
      cover = Immutable.Map(),
      angle = Immutable.Map()
    } = immutableSelector(data)
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { x: centerX, y: centerY } = immutableSelector(center_position)
    const originLang = getOriginLang(metas)
    const { previewAngle = 0 } = immutableSelector(assets)
    const defaultLang = originLang.split(',')[0]
    const previewLang = renderLayerByLang || assets.get('previewLang') || defaultLang
    const posXPath = ['position', 'x']
    const posYPath = ['position', 'y']
    const scaleImages = scale.get('images') || Immutable.List()
    const { position: pointerPosition, image: pointerImage } = immutableSelector(pointer)
    const { x: pointerX, y: pointerY } = immutableSelector(pointerPosition)
    const { position: coverPosition, image: coverImage } = immutableSelector(cover)
    const { x: coverX, y: coverY } = immutableSelector(coverPosition)
    const { start, end } = immutableSelector(angle)

    return (
      <>
        <div className="name">
          <T id="pointer_progress" />（<T id="pointer_direction_tip" />）
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={pointerImage ? [{ image: pointerImage }] : []}
                onChange={(image) => {
                  this.handleChange(['pointer'], 'pointer', 'image', false, image)
                }}
              />
              <div className="key is-required">
                <T id="pointer_image" />
              </div>
            </div>
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={coverImage ? [{ image: coverImage }] : []}
                onChange={(image) => {
                  this.handleChange(['cover'], 'cover', 'image', false, image)
                }}
              />
              <div className="key">
                <T id="center_img" />
              </div>
            </div>
          </div>
        </div>
        <div className="name">
          <T id="watch_face_center" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={centerX}
                onChange={(value) => {
                  this.handleChange(['center_position'], 'pointer', 'x', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={centerY}
                onChange={(value) => {
                  this.handleChange(['center_position'], 'pointer', 'y', false, value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="pointer_rotation_center" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                value={pointerX}
                onChange={(value) => {
                  this.handleChange(['pointer', 'position'], 'pointer', 'transformOriginX', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                value={pointerY}
                onChange={(value) => {
                  this.handleChange(['pointer', 'position'], 'pointer', 'transformOriginY', false, value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="center_img_pos" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={coverX}
                onChange={(value) => {
                  this.handleChange(['cover', 'position'], 'cover', 'x', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={coverY}
                onChange={(value) => {
                  this.handleChange(['cover', 'position'], 'cover', 'y', false, value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="pointer_range" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <T id="from" />
              &nbsp;&nbsp;
              <InputNumber value={start} style={{ width: 110 }} onChange={debounce((value) => this.handleAngleChange(value, 'start', angle), 300)} />
              &nbsp;
              <T id="to" />
              &nbsp;
              <InputNumber value={end} style={{ width: 110 }} onChange={debounce((value) => this.handleAngleChange(value, 'end', angle), 300)} />
              &nbsp;
              <T id="degree" />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="bottom_image" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={scale.getIn(posXPath, 0)}
                onChange={(value) => {
                  this.handleChange(['scale', 'position'], 'scale', 'x', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={scale.getIn(posYPath, 0)}
                onChange={(value) => {
                  this.handleChange(['scale', 'position'], 'scale', 'y', false, value)
                }}
              />
            </div>
          </div>
          {/* {originLang === languages ? null : (
            <div>
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  value={languages}
                  style={SELECT_STYLE}
                  disabled
                  onChange={(value) => {
                    this.handleLangChange('languages', value)
                  }}
                >
                  <Option value="all">
                    <T id="lang_all" />
                  </Option>
                  <Option value="zh,zh-Hant,en">
                    <T id="lang_zh" />、<T id="lang_zh-hant" />、<T id="lang_en" />
                  </Option>
                  <Option value="sc,en">
                    <T id="lang_zh&zh-hant" />、<T id="lang_en" />
                  </Option>
                </Select>
              </div>
            </div>
          )} */}
          <div className="item">
            {originLang.split(',').map((lang) => {
              const { field } = LANG_MAPPER[lang] || {}
              const labelMap = {
                all: <T id="all_lang" />,
                zh: <T id="lang_zh" />,
                'zh-Hant': <T id="lang_zh-hant" />,
                sc: <T id="lang_zh&zh-hant" />,
                en: <T id="lang_en" />
              }
              const scaleImage = scaleImages.find((item) => item.get('language') === field) || Immutable.Map()
              const scaleImg = scaleImage.getIn(['image', 'url'])

              return (
                <Upload
                  key={lang}
                  field={field}
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={scaleImg ? [{ image: scaleImg }] : []}
                  help={
                    <div style={{ textAlign: 'center' }}>
                      <T id="bottom_image" />({labelMap[lang]})
                    </div>
                  }
                  onChange={(image, filed) => {
                    this.handleChange(['scale'], 'scale', 'images', false, image, filed)
                  }}
                />
              )
            })}
          </div>
          <div className="item">
            <T id="preview_lang" />
          </div>
          <Select
            dropdownClassName="watch-skin-select-dropdown"
            style={SELECT_STYLE}
            value={previewLang}
            onChange={(value) => {
              this.handleLangChange('previewLang', value)
            }}
          >
            {originLang.split(',').map((lang) => {
              const { label, field } = LANG_MAPPER[lang] || {}

              return (
                <Option key={field} value={field}>
                  {label}
                </Option>
              )
            })}
          </Select>
          <div className="item">
            <T id="preview_pointer_progress" />
          </div>
          <div>
            <InputNumber
              min={0}
              max={Math.abs(start - end)}
              style={{ width: 325 }}
              value={previewAngle}
              onChange={(value) => {
                this.handleChange([], 'previewAngle', 'previewAngle', true, value)
              }}
            />
          </div>
        </div>
      </>
    )
  }
}

PointerProgressComponent.defaultProps = {
  prefixPath: []
}
export default PointerProgressComponent
