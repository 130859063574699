import { message } from 'antd'
import Cookies from 'js-cookie'
import qs from 'query-string'
import { useEffect } from 'react'

enum ErrorCode {
  NOT_REGISTER = '0117'
}

export default function useLoginRedirectErrorHandler() {
  const search = qs.parse(window.location.search)
  const { error } = search
  const errorFromCookie = Cookies.get('error')

  useEffect(() => {
    const errCode = error || errorFromCookie
    // 未注册或者三分账号未与 huami 账号绑定
    if (errCode === ErrorCode.NOT_REGISTER) {
      message.error('Invalid account or the account is not linked to the Zepp account.', 4)
    }
  }, [error, errorFromCookie])
}
