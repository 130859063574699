const JUMPABLE = {
  position: {
    x: 0,
    y: 0
  },
  width: 0,
  height: 0,
  image: ''
}

export default JUMPABLE
