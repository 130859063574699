module.exports = (function () {
  return `
    {{#eventName}}
    {{sensorVariable}}.addEventListener({{eventType}}.event.{{eventName}}, function() {
      {{#sensorHandle}}
        {{{.}}}
      {{/sensorHandle}}
    })
    {{/eventName}}
  `
})()
