import { getAppDeviceInfo } from '@/utils/device'
import { URLSearchParamsHelper } from '@/utils/query-string'

const deviceCodeString = getAppDeviceInfo({ type: 'packageName' })
const huamiEnvironment = process.env.NODE_ENV || 'production'
let isPro = true

if (huamiEnvironment === 'production') {
  isPro = true
}

// if(location.hostname === 'user.huami.com'){
if (window.location.hostname === 'localhost' || window.location.hostname === 'fe-testing-cdn.huami.com') {
  isPro = false
}

if (localStorage?.getItem('huami-debug') === 'pro') {
  isPro = true
} else if (localStorage?.getItem('huami-debug') === 'test') {
  isPro = false
}

// 系统版本切换优先级：
// 1、本地调试版本 debug-version
// 2、系统开关配置文件
// 3、请求参数sv

const urlSearchParameters = URLSearchParamsHelper()
// v2是过渡系统，v3是新系统，目前是在过渡系统版本
let sysvertion: string | null = 'v2'

if (urlSearchParameters) {
  sysvertion = urlSearchParameters.get('asv') || 'v2'
}
if (localStorage?.getItem('asv')) {
  sysvertion = localStorage.getItem('asv')
}

export const getCallbackUrlByAppName = (appName: string) => {
  return isPro
    ? `https://account.huami.com/v1/accounts/connect/${appName}/callback`
    : `https://account-staging.huami.com/v1/accounts/connect/${appName}/callback`
}
export const getCookiesDomain = () => {
  if (window.location.hostname === 'localhost' || localStorage?.getItem('cookies-debug') === 'true') {
    return window.location.hostname
  }
  let domain = '.huami.com'
  if (window.location.hostname.includes('.zepp.com')) {
    domain = '.zepp.com'
  }
  return domain
}

export const getOldGdprUrl = () => {
  // http://fe-testing-cdn.huami.com/destroy/2.0.0/index.html#/chooseDestory?v=1.0.0&platform_app=com.xiaomi.hm.health
  const curUrl = window.location.href
  // 未成年授权
  if (curUrl.includes('nonage')) {
    return isPro ? `https://user.huami.com/nonage/index.html` : `https://fe-testing-cdn.huami.com/nonage/index.html`
  }
  if (sysvertion === 'v2' || sysvertion === 'v3') {
    return isPro ? `https://user.huami.com/privacy/index.html` : `https://fe-testing-cdn.huami.com/privacy/index.html`
  }
  return isPro ? `https://user.huami.com/hm_account/2.0.0/index.html` : `https://fe-testing-cdn.huami.com/destroy/2.0.0/index.html`
}

export const getOldTpaUrl = () => {
  // https://fe-testing-cdn.huami.com/oauth/index.html#/?client_id=0010738901358628&response_type=token&state=BLACK_BOX
  // https://user.huami.com/oauth/index.html#/authorizing?

  if (sysvertion === 'v2' || sysvertion === 'v3') {
    return isPro
      ? `https://user.huami.com/3rdauth/v2/index.html#/` // "#/authorized" : "#/authorizing"
      : `https://fe-testing-cdn.huami.com/3rdauth/v2/index.html#/`
  }
  return isPro
    ? `https://user.huami.com/oauth/index.html#/` // "#/authorized" : "#/authorizing"
    : `https://fe-testing-cdn.huami.com/oauth/index.html#/`
}

export const getV3TpaUrl = () => {
  return isPro
    ? `https://user.huami.com/oauth2/index.html#/authorizing` // "#/authorized" : "#/authorizing"
    : `https://fe-testing-cdn.huami.com/oauth2/index.html#/authorizing`
}

const prdTemporaryCallbackUrl = 'https://account.huami.com/v1/accounts/connect/{thirdPartyName}/callback'
const developmentTemporaryCallbackUrl = 'https://account-staging.huami.com/v1/accounts/connect/{thirdPartyName}/callback'

const prdEnvironment = {
  defaultCallbackUrl: prdTemporaryCallbackUrl.replace('{thirdPartyName}', 'huami'),
  callbackUrl: prdTemporaryCallbackUrl,
  appleClientID: deviceCodeString || 'com.huami.midong.webapp'
}

const developmentEnvironment = {
  callbackUrl: developmentTemporaryCallbackUrl,
  defaultCallbackUrl: developmentTemporaryCallbackUrl.replace('{thirdPartyName}', 'huami')
}

export const environment = isPro ? prdEnvironment : developmentEnvironment

interface Inputs {
  response_type?: string
  scope?: string
  redirect_uri?: any
  client_id?: any
  appid?: string | Array<string>
}

interface HuaMiType {
  languages: any
  language: string
  regions: any
  Oauth: {
    wechat: {
      action: string
      redirect_uri: string | Array<string>
      inputs: Inputs
    }
    xiaomi: {
      action: string
      redirect_uri: string | Array<string>
      inputs: Inputs
    }
    apple: {
      action: string
      // redirect_uri: {
      //   'midong': string,
      //   'timex': string,
      //   'mi': string,
      // },
      redirect_uri: any
      inputs: Inputs
    }
    google: {
      action: string
      redirect_uri: any
      inputs: Inputs
    }
    facebook: {
      action: string
      redirect_uri: any
      inputs: Inputs
    }
    mp: {
      action: string
      redirect_uri: string | Array<string>
      inputs: Inputs
    }
    line: {
      action: string
      redirect_uri: string | Array<string>
      inputs: Inputs
    }
  }
}

// 参考文档：https://confluence.huami.com/pages/viewpage.action?pageId=35423026
export const HuaMi: HuaMiType = {
  languages: {
    en: true,
    zh: true,
    // ja: true,
    'cdn.awsbj0.fds.api.mi-img.com': 'zh',
    'user-cn.huami.com': 'zh'
  },
  language: 'en',
  regions: {
    'cn-north-1': {
      BackEnd: 'auth-cn2.huami.com',
      Oauth: 'API-user-cn.HuaMi.com',
      //  lower case for redirect_uri !!
      AccountSystem: {
        wechat: 'account.huami.com',
        other: 'account.huami.com'
      }
    },
    'us-west-2': {
      BackEnd: 'auth-US2.HuaMi.com',
      Oauth: 'API-user-US.HuaMi.com',
      //  lower case for redirect_uri !!
      AccountSystem: {
        wechat: 'account.huami.com',
        facebook: 'account-us.amazfit.com',
        other: 'account-us.huami.com'
      }
    }
  },
  // 注意：GDPR 综合后台(GDPR、未成年授权)、三方授权均有用到
  Oauth: {
    wechat: {
      // HTTP://open.WeChat.com/cgi-bin/newreadtemplate?t=overseas_open/docs/web/login/login
      action: 'HttpS://open.WeiXin.qq.com/connect/qrconnect',
      // 0: ； 1: 备份 Amazfit
      redirect_uri: [getCallbackUrlByAppName('wechat'), getCallbackUrlByAppName('wechat-zepp-backup')],
      inputs: {
        scope: 'snsapi_login',
        // 0: 华米账号； 1: 备份 Amazfit;
        appid: [isPro ? 'wx721b25cbe34c6195' : 'wxcfa74c07811a9f82', 'wx721b25cbe34c6195'],
        response_type: 'code'
        // "state": `v=`
      }
    },
    xiaomi: {
      // HTTP://dev.XiaoMi.com:8080/docs/passport/authorization-code/
      action: 'HttpS://account.XiaoMi.com/oauth2/authorize',
      // 0: Zepp Life、Amazfit、Zepp；1: 备份 Amazfit；2: 米动健康
      redirect_uri: [getCallbackUrlByAppName('xiaomi'), getCallbackUrlByAppName('xiaomi-zepp-backup'), getCallbackUrlByAppName('HMHealth')],
      inputs: {
        scope: '1',
        // 0: Zepp Life、Amazfit、Zepp；1: 备份 Amazfit（不区分产线和测试）
        client_id: [isPro ? '2882303761517571052' : '2882303761517524066', '2882303761518435567']
        // "state": `v=`
      }
    },
    apple: {
      action: 'https://appleid.apple.com/auth/authorize',
      // amazfit：备份 Amazfit、zepp：Zepp 和 Amazfit
      // TODO: 备份 Amazfit 产线回调地址还么有
      redirect_uri: {
        amazfit: isPro
          ? 'https://account.huami.com/v1/accounts/connect/apple-zepp-backup/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/apple-zepp-backup/callback',
        zepp: isPro
          ? 'https://account.huami.com/v1/accounts/connect/apple-midong/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/apple-midong/callback',
        timex: isPro
          ? 'https://account.huami.com/v1/accounts/connect/apple-timex/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/apple-timex/callback',
        mi: isPro
          ? 'https://account.huami.com/v1/accounts/connect/apple/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/apple/callback',
        HMHealth: isPro
          ? 'https://account.huami.com/v1/accounts/connect/apple-shushan/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/apple-shushan/callback',
        bell: isPro
          ? 'https://account.huami.com/v1/accounts/connect/apple-bell/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/apple-bell/callback'
      },
      inputs: {
        // "scope": "name email",
        // response_type: 'code id_token',
        // 同 redirect_uri 备注
        client_id: {
          amazfit: 'com.huami.app.amazfit.web',
          zepp: 'com.huami.midong.webapp',
          timex: 'com.timex.app.ios.client',
          mi: 'com.huami.webapp',
          HMHealth: 'com.huami.shushan.webapp', // 米动健康
          bell: 'com.huami.hearingaids.web'
        }
      }
    },
    google: {
      action: 'HttpS://accounts.google.com/o/oauth2/auth',
      // 0:; 1: timex; 2: 备份 Amazfit
      redirect_uri: [
        isPro
          ? 'https://account-us.huami.com/v1/accounts/connect/google/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/google/callback',
        isPro
          ? 'https://account.timexsmart.com/v1/accounts/connect/google_timex/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/google/callback',
        isPro
          ? 'https://account-us.huami.com/v1/accounts/connect/google-zepp-backup/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/google-zepp-backup/callback',
        isPro
          ? 'https://account-us2.huami.com/v1/accounts/connect/google-bell/callback'
          : 'https://account-staging.huami.com/v1/accounts/connect/google-bell/callback'
      ],
      inputs: {
        scope: 'profile email',
        // 0: ; 1: timex; 2: 备份 Amazfit（TODO：待确认是否区分产线和测试）
        client_id: [
          isPro
            ? '176402687125-3ab9n2l8paogfcf46lvbmb2v3mvj7k53.apps.googleusercontent.com'
            : '857077419726-amva3a2gfdgssl131a5tog031fjf2klt.apps.googleusercontent.com',
          isPro
            ? '657201701961-0ubuo4ahaf003fke8kqjt4u46j20hv7i.apps.googleusercontent.com'
            : '857077419726-amva3a2gfdgssl131a5tog031fjf2klt.apps.googleusercontent.com',
          '496568571003-05ftdac45qis5lj82jl5bd3u9dt80srm.apps.googleusercontent.com',
          '484254153559-8m3i02lt87curvmhtj1pl2vcek379pmm.apps.googleusercontent.com'
        ]
      }
    },
    facebook: {
      action: 'HttpS://WWW.FaceBook.com/dialog/oauth',
      // 0:; 1: timex; 2: 备份 Amazfit
      redirect_uri: [
        isPro
          ? 'https://account-us.amazfit.com/v1/accounts/connect/facebook/callback'
          : 'https://account-ru-staging.huami.com/v1/accounts/connect/facebook/callback',
        isPro
          ? 'https://account-us.amazfit.com/v1/accounts/connect/facebook_timex/callback'
          : 'https://account-ru-staging.huami.com/v1/accounts/connect/facebook_timex/callback',
        isPro
          ? 'https://account-us.amazfit.com/v1/accounts/connect/facebook-zepp-backup/callback'
          : 'https://account-ru-staging.huami.com/v1/accounts/connect/facebook-zepp-backup/callback',
        isPro
          ? 'https://account-us2.huami.com/v1/accounts/connect/facebook-bell/callback'
          : 'https://account-ru-staging.huami.com/v1/accounts/connect/facebook-bell/callback'
      ],
      inputs: {
        scope: 'email public_profile',
        response_type: 'code',
        // 0:; 1: timex; 2: 备份 Amazfit
        client_id: ['1707172569519471', '381849649110027', '252456525975780', '540720393785648']
      }
    },
    mp: {
      action: 'https://open.weixin.qq.com/connect/oauth2/authorize',
      redirect_uri: 'https://account.huami.com/v1/accounts/connect/huami_mp/callback',
      inputs: {
        scope: 'snsapi_userinfo',
        response_type: 'code',
        appid: isPro ? 'wx5c9a4fd395ba78e6' : 'wx89662a443ea4de89'
      }
    },
    line: {
      action: 'https://access.line.me/oauth2/v2.1/authorize',
      redirect_uri: isPro
        ? 'https://account.huami.com/v1/accounts/connect/line/callback'
        : 'https://account-ru-staging.huami.com/v1/accounts/connect/line/callback',
      inputs: {
        scope: 'openid profile',
        response_type: 'code',
        client_id: isPro ? '1534285644' : '1534285644'
      }
    }
  }
}
