// 跟随处理代码，如温度最高温跟随最低温
// moduleBeFollowed 被跟随模块， module 主动跟随模块
export default `
    {{ moduleBeFollowed }}.layoutChange(function (obj) {
      const end_X = {{ moduleBeFollowed }}.getProperty(hmUI.prop.END_X);
      const y = {{ moduleBeFollowed }}.getProperty(hmUI.prop.Y);

      {{ moduleFollowing }}.setProperty(hmUI.prop.X, end_X);
      {{ moduleFollowing }}.setProperty(hmUI.prop.Y, y);
    });
  `
