import { Col, Divider, Row, Select } from 'antd'
import * as React from 'react'
import { DATA_TYPE } from '../constants'

const { Option } = Select

interface Props {
  properties: {
    action: { value: string }
  }
  onChange: (category: string, property: string, value: any) => void
}

const Action: React.FC<Props> = (props) => {
  const {
    properties: {
      action: { value: action }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-action">
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={6}>跳转</Col>
        <Col span={18}>
          <Select
            defaultValue={action}
            style={{ width: 120 }}
            onChange={(value) => {
              onChange('action', 'action', value)
            }}
          >
            {DATA_TYPE.map((item: any) => {
              return (
                <Option key={item.key} value={item.value}>
                  {item.key}
                </Option>
              )
            })}
          </Select>
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Action
