import { getKeyOrIdFromStr, META_COMPONENT_MAP } from '@watchface/components/Meta'
import { changeMenuName, updateWidgetName } from '@watchface/store/actions'
import { Collapse } from 'antd'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './index.scss'

const { Panel } = Collapse

const DigitalComponent = (props) => {
  const { dialType, parentKey } = props
  const dispatch = useDispatch()
  const [activeKey, setActiveKey] = useState(['name'])
  const { id: activeWidget } = getKeyOrIdFromStr(parentKey)
  const name = useSelector((state) => {
    return state.getIn(['watchFace', 'scene', 'widgets', activeWidget, 'name'], null)
  })

  const onChange = useCallback(
    (category, property, value) => {
      dispatch(updateWidgetName(activeWidget, value))
      dispatch(changeMenuName(dialType, [parentKey], value))
    },
    [dispatch, dialType, parentKey, activeWidget]
  )

  const onCollapseChange = (value) => {
    setActiveKey(value)
  }

  const PropertyComponent = META_COMPONENT_MAP.name
  return (
    <Collapse className="meta-collapse" activeKey={activeKey} bordered expandIconPosition="right" ghost onChange={onCollapseChange}>
      <Panel header="Name" key="name">
        <PropertyComponent onChange={onChange} properties={{ name: { value: name } }} />
      </Panel>
    </Collapse>
  )
}

export default React.memo(DigitalComponent)
