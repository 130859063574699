import { getKeyOrIdFromStr, META_COMPONENT_MAP } from '@watchface/components/Meta'
import { MOVE_IMAGE } from '@watchface/constants'
import { changeMenuName, updateCategoryProperty, updateWidgetName } from '@watchface/store/actions'
import { Collapse } from 'antd'
import PubSub from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDigitalAmPm2FabricImage, orderedDigitalAmPmCategoriesKey } from './config'
import './index.scss'

const { Panel } = Collapse

const DigitalAmPmComponent = (props) => {
  const { parentKey, childKey, dialType, watchConfig, onUpdateAttr, onInsertImage, isExist, onRemove } = props
  const screenWidth = watchConfig?.get('screenWidth')
  const screenHeight = watchConfig?.get('screenHeight')
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState([])
  const [activeKey, setActiveKey] = useState([])
  const preTexture = useRef('')
  const { id: activeWidget } = getKeyOrIdFromStr(childKey)
  const widget = useSelector((state) => {
    return state.getIn(['watchFace', 'scene', 'widgets', activeWidget], null)
  })

  useEffect(() => {
    const categories = widget.get('categories').toJS()
    const activeKey = []
    const categoryList = orderedDigitalAmPmCategoriesKey
      .map((categoryKey) => {
        const category = categories[categoryKey]
        if (!category) return
        const { type, display, isExpanded, visibility, properties } = category
        if (isExpanded) {
          activeKey.push(type)
        }
        return { type, display, visibility, properties, categoryKey }
      })
      .filter((category) => category?.visibility)
    setCategoryList(categoryList)
    setActiveKey(activeKey)
    const key = `${dialType}_${widget.get('type')}_${activeWidget}`
    const options = convertDigitalAmPm2FabricImage(widget.toJS())
    if (preTexture.current && !options?.texture.toString()) {
      onRemove(key)
      preTexture.current = ''
    } else {
      isExist(key) ? onUpdateAttr(key, options) : onInsertImage(parentKey, key, options)
    }
    preTexture.current = options?.texture.toString()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, dialType])

  const onChange = useCallback(
    (category, property, value) => {
      if (category === 'name') {
        dispatch(updateWidgetName(activeWidget, value))
        dispatch(changeMenuName(dialType, [parentKey, childKey], value))
      }
      dispatch(updateCategoryProperty(activeWidget, category, property, value))
    },
    [dispatch, dialType, parentKey, childKey, activeWidget]
  )

  const onCollapseChange = (value) => {
    setActiveKey(value)
  }

  useEffect(() => {
    PubSub.subscribe(MOVE_IMAGE, (e, { key, left, top }) => {
      if (key.includes(activeWidget)) {
        onChange('placement', 'x', left)
        onChange('placement', 'y', top)
      }
    })
  }, [onChange, activeWidget])

  const propsMap = {
    image: { screenWidth, screenHeight },
    placement: { screenWidth, screenHeight }
  }

  return (
    <Collapse className="meta-collapse" activeKey={activeKey} bordered expandIconPosition="right" ghost onChange={onCollapseChange}>
      {categoryList.map((category) => {
        const { type, display, properties, categoryKey } = category
        const PropertyComponent = META_COMPONENT_MAP[type]

        return (
          <Panel header={display} key={type}>
            <PropertyComponent onChange={onChange} properties={properties} categoryKey={categoryKey} {...propsMap[type]} />
          </Panel>
        )
      })}
    </Collapse>
  )
}

export default DigitalAmPmComponent
