/* eslint-disable import/order */
import * as CONFIG_MAP from '@watchface/pages/DesignTool/NonZeppOs/devices'
import { immutableSelector } from '@/utils/index'
import { getMenuFromWidget } from '@watchface/components/Meta'
import { SERIES } from '@watchface/constants'
import {
  createUserDialApi,
  deleteDialRequest,
  fetchDesignerRequest,
  getDeviceInfo,
  getSupportDevicesApi,
  getUserDialRequest,
  saveDesignerRequest,
} from '@watchface/request/api'
import {
  CHANGE_DESIGNER,
  CHANGE_DESIGNER_ACTION,
  CHANGE_DESIGNER_AUTO_SAVE,
  CHANGE_ASSET,
  UPDATE_CATEGORY_PROPERTY,
  UPDATE_CATEGORY_PROPERTY_ACTION,
  COPY_DIAL,
  DELETE_DIAL,
  FETCH_DESIGNER,
  FETCH_DESIGNER_ERROR,
  FETCH_DESIGNER_START,
  FETCH_DESIGNER_SUCCESS,
  FETCH_SUPPORT_DEVICES,
  FETCH_USER_DIAL,
  INIT_THE_WIDGET_BY_API,
  SAVE_DESIGNER,
  SAVE_STATUS,
  SET_SUPPORT_DEVICES,
  SET_USER_DIAL,
  STOP_AUTO_SAVE,
} from '@watchface/store/actionTypes'
import { compatibleWithOldData, dealConfigForApi, handleOldEditableBg, pathAffectZoomFlag } from '@watchface/utils'
import { message } from 'antd'
import Immutable from 'immutable'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { SAVE_TYPE } from '../constants/index'

// 拿到数据之后需要进行处理才能使用
function dealData(data) {
  const { support = Immutable.Map(), config = Immutable.Map() } = immutableSelector(data)
  const { series } = immutableSelector(support)
  const isComoNess = series === SERIES.COMO || SERIES.NESS
  const noChildrenArr = ['background', 'idle_background', 'scale']
  const assets =
    config.get('assets') ||
    Immutable.fromJS({
      uploadedImages: {
        normal: {},
        idle: {}
      },
      normal: {},
      idle: {}
    })
  const metas = config.get('metas') || Immutable.Map()
  const idleConfig = config.get('idle_config') || Immutable.Map()
  const normalConfig = config.delete('idle_config').delete('pic_config').delete('assets').delete('metas')
  // json 2.0 get normal and idle menu with order
  const widgets = config.getIn(['config_os', 'scene', 'widgets'])
  const getWidgetMenu = (config) => {
    let widgetMenu = Immutable.List()
    const orderList = config.get('order') || Immutable.List()
    if (!widgets || !orderList.size) return widgetMenu
    Object.values(widgets.toJS()).forEach((widget) => {
      const index = orderList.findIndex((order) => order.includes(widget.id))
      if (index <= -1) {
        return
      }
      let newMenu = Immutable.Map()
      if (Object.hasOwnProperty.call(widget, 'children')) {
        const newChildren = widget.children.map((id) => widgets.get(id).toJS())
        const newWidget = { ...widget, children: newChildren }
        newMenu = Immutable.fromJS(getMenuFromWidget(newWidget.type, newWidget))
      } else {
        newMenu = Immutable.fromJS(getMenuFromWidget(widget.type, widget))
      }
      newMenu = newMenu.set('order', index)
      widgetMenu = widgetMenu.push(newMenu)
    })
    return widgetMenu
  }

  const dealConfig = (config, dialType) => {
    let menu = getWidgetMenu(config)

    const { layerConfig } = CONFIG_MAP[series][dialType]

    const order = config.get('order') || Immutable.List()
    const hideKeys = config.get('hide') || Immutable.List()
    const ignoreKeys = ['order', 'hide']
    let maskConfig = null
    let newConfig = config.map((v, key) => {
      if (ignoreKeys.indexOf(key) > -1) {
        return v
      }

      let m = CONFIG_MAP[series][dialType].menu.get(key) || Immutable.Map()

      // json2.0
      if (key === 'config_os') return v

      if (m.size === 0) return v

      let value = Immutable.Map()
      const hideParent = hideKeys.includes(key)

      m = m.set('hide', hideParent)

      if (noChildrenArr.includes(key)) {
        // 没有子菜单因为不需要补全默认值直接返回
        const keyOrder = order.indexOf(key) > -1 ? order.indexOf(key) : menu.size
        m = m.set('order', keyOrder)
        menu = menu.push(m)
        return v
      }
      if (key === 'digital_clock') {
        value = value.setIn(['follow_time'], v.get('follow_time')).setIn(['align'], v.get('align'))
      }
      // 需要补全默认值和修改菜单的checked状态
      if (key === 'component') {
        const { cover, mask, items } = immutableSelector(v)
        maskConfig = Immutable.fromJS({
          cover,
          mask
        })

        let componentConfig = Immutable.Map()

        items.forEach((configItem, index) => {
          let componentMenu = m
          let propsObj = layerConfig.getIn(['component', 'props']) || Immutable.Map()
          const { props } = immutableSelector(configItem)

          props.forEach((prop) => {
            const { bg, previews, ...res } = immutableSelector(prop)

            Object.keys(res).forEach((moduleKey) => {
              propsObj = propsObj.set(
                moduleKey,
                Immutable.fromJS({
                  bg,
                  previews
                })
              )
              const subChildConfig = res[moduleKey].toJS()
              propsObj = propsObj.setIn(
                [moduleKey, 'children'],
                Immutable.fromJS({
                  ...subChildConfig
                })
              )
              const index = componentMenu.get('children').findIndex((item) => item.get('key') === moduleKey)

              componentMenu = componentMenu.setIn(['children', index, 'checked'], true)
              const subChildren = m.getIn(['children', index, 'children'])
              if (subChildren) {
                subChildren.forEach((subChild, subIndex) => {
                  const { key: subChildKey } = immutableSelector(subChild)
                  if (subChildConfig[subChildKey]) {
                    componentMenu = componentMenu.setIn(['children', index, 'children', subIndex, 'checked'], true)
                  } else {
                    propsObj = propsObj.setIn([moduleKey, 'children', subChildKey], layerConfig.getIn([moduleKey, 'children', subChildKey]))
                  }
                })
              }
            })
          })

          const key = `component_${index}`
          const hideParent = hideKeys.includes(key)
          const accNum = assets.getIn([dialType, 'accNum', key]) || index + 1
          const name = `${componentMenu.get('name')}~~${accNum}`
          const multiName = assets.getIn([dialType, 'multiName']) || Immutable.List()
          const keyOrder = order.indexOf(key) > -1 ? order.indexOf(key) : menu.size
          componentMenu = componentMenu.set('order', keyOrder)
          menu = menu.push(
            componentMenu
              .set('hide', hideParent)
              .set('key', key)
              .set('name', multiName.get(index) || multiName.get(String(index)) || name)
          )
          componentConfig = componentConfig.set(String(index), configItem.set('props', propsObj))
        })

        const maskMenu = CONFIG_MAP[series][dialType].menu.get('mask') || Immutable.Map()
        const maskKeyOrder = order.indexOf('mask') > -1 ? order.indexOf('mask') : menu.size

        menu = menu.push(maskMenu.set('order', maskKeyOrder))

        return componentConfig
      }
      layerConfig.getIn([key, 'children'], Immutable.Map()).forEach((configValue, k) => {
        const checked = !!v.get(k)

        if (checked) {
          value = value.setIn(['children', k], v.get(k))
        } else {
          value = value.setIn(['children', k], configValue)
        }
        const newChildren = m.get('children')

        if (!newChildren) return

        const index = newChildren.findIndex((item) => {
          const key = item.get('key')

          if (key === 'ampm') {
            return k === 'am' || k === 'pm'
          }
          return key === k
        })

        if (index > -1) {
          m = m.setIn(['children', index, 'checked'], checked)
        }
      })

      const keyOrder = order.indexOf(key) > -1 ? order.indexOf(key) : menu.size
      m = m.set('order', keyOrder)

      if (m.has('children')) {
        menu = menu.push(m)
      }

      return value
    })

    if (maskConfig) {
      newConfig = newConfig.set('mask', maskConfig)
    }

    const sortedMenu = menu.sort((a, b) => b.get('order') - a.get('order'))
    return Immutable.fromJS({
      config: newConfig,
      menu: sortedMenu
    })
  }

  let newData = data

  if (isComoNess) {
    newData = newData.set('pic_config', config.has('pic_config') ? config.get('pic_config') : Immutable.Map())
  }

  return newData
    .delete('config')
    .set('asset', assets)
    .set('metas', metas)
    .set('normal', dealConfig(normalConfig, 'normal'))
    .set('idle', dealConfig(idleConfig, 'idle'))
}

function mergeExtraConfig(data, dialType) {
  try {
    const isZeppOS = data.getIn(['support', 'series']) === SERIES.JS
    const extraConfigPath = ['config', 'assets', dialType, 'extraConfig']

    if (!isZeppOS) {
      return data.deleteIn(extraConfigPath)
    }

    const extraConfig = data.getIn(extraConfigPath)

    // 如果没有额外配置，直接返回服务端配置
    if (typeof extraConfig === 'undefined') return data

    const isNormal = dialType === 'normal'
    const basePath = isNormal ? ['config'] : ['config', 'idle_config']

    const extraConfigWrapper = isNormal
      ? Immutable.fromJS({
          config: extraConfig
        })
      : Immutable.fromJS({
          config: {
            idle_config: extraConfig
          }
        })

    const componentExtraDataPath = [...basePath, 'assets', dialType, 'extraConfig', 'component']
    const componentDataPath = [...basePath, 'component']
    const componentExtraData = data.getIn(componentExtraDataPath)
    let componentData = data.getIn(componentDataPath)
    let mergedData = extraConfigWrapper.deleteIn(componentExtraDataPath).mergeDeep(data.deleteIn(componentDataPath))

    // 可编辑组件数据处理
    if (componentData) {
      if (componentExtraData) {
        componentExtraData.get('items').forEach((item, index) => {
          const props = item.get('props')
          props.forEach((prop, propIndex) => {
            const propsPath = ['items', index, 'props']
            const propsItemPath = [...propsPath, propIndex]
            const propsItemData = componentData.getIn(propsItemPath, Immutable.Map())
            const propsItemDataKey = propsItemData.deleteAll(['bg', 'previews']).keySeq().get(0)
            const extraConfigDataKey = componentExtraData.deleteAll(['bg', 'previews']).getIn(propsItemPath, Immutable.Map()).keySeq().get(0)

            if (propsItemDataKey === extraConfigDataKey) {
              componentData = componentData.setIn(propsItemPath, propsItemData.merge(prop))
            } else {
              componentData = componentData.updateIn(propsPath, (list => {
                return list.push(prop)
              }))
            }
          })
        })
      }
      mergedData = mergedData.setIn(componentDataPath, componentData)
    }

    return mergedData
  } catch (e) {
    console.log('mergeExtraConfig', e)
    return data
  }
}

function separateExtraConfig(config, series, dialType) {
  try {
    const extraConfig = CONFIG_MAP[series][dialType]?.extraConfig

    if (typeof extraConfig === 'undefined') return config

    let newConfig = config
    const extraKeyPaths = extraConfig.get('newFieldOrModulePaths')
    const isNormal = dialType === 'normal'
    // 可编辑组件
    const editComponentItems = newConfig.getIn(['component', 'items'])
    const assetPathToSaveExtraConfig = ['assets', dialType, 'extraConfig']
    const checkValidValue = (value) => {
      if (Immutable.Map.isMap(value)) {
        return value.size > 0
      }
      if (typeof value === 'undefined') {
        return false
      }
      return true
    }

    newConfig = newConfig.setIn(assetPathToSaveExtraConfig, Immutable.Map())

    extraKeyPaths.forEach((path) => {
      const configPath = path.delete('children')

      const existExtraComponentKey = path.get(0) === 'component' && path.get(1) === 'props'

      // 处理可编辑组件
      if (editComponentItems && existExtraComponentKey) {
        const itemsStruct = []
        editComponentItems.forEach((item, index) => {
          const componentKey = path.get(2)
          const props = item.get('props')
          const propsStruct = []

          props.forEach((component, componentKeyIndex) => {
            if (!component.has(componentKey)) return

            const componentValue = component.get(componentKey)

            if (!checkValidValue(componentValue)) return

            propsStruct.push(component)

            newConfig = newConfig.deleteIn(['component', 'items', index, 'props', componentKeyIndex])
          })
          itemsStruct.push({
            props: propsStruct
          })
        })
        if (itemsStruct.length > 0) {
          newConfig = newConfig.setIn([...assetPathToSaveExtraConfig, 'component', 'items'], Immutable.fromJS(itemsStruct))
        }
      } else {
        const absolutePath = isNormal ? configPath : ['idle_config', ...configPath]
        const extraKeyConfigValue = newConfig.getIn(absolutePath)

        if (!checkValidValue(extraKeyConfigValue)) return

        newConfig = newConfig.deleteIn(absolutePath).setIn([...assetPathToSaveExtraConfig, ...configPath], extraKeyConfigValue)
      }
    })

    return newConfig
  } catch (e) {
    console.log('separateExtraConfig', e)
    return config
  }
}

function* fetchDesigner(action) {
  const state = yield select()
  const i18n = state.getIn(['watchFace', 'i18n', 'locale'], Immutable.Map())
  const dialType = state.getIn(['watchFace', 'designer', 'present', 'data', 'dialType']) || 'normal'
  const { data = {} } = action
  const { id, callback } = data
  yield put({ type: FETCH_DESIGNER_START })
  message.loading({ content: 'Loading...', key: 'fetchDesigner' })

  try {
    const serverData = yield call(fetchDesignerRequest, id)
    // 服务端未支持的额外配置 https://huami.feishu.cn/wiki/wikcntJoIdX5BkgYsiQXfE5XYre#
    const immutableServerData = Immutable.fromJS(serverData)
    // json2.0 fetch widget
    yield put({
      type: INIT_THE_WIDGET_BY_API,
      payload: {
        activeWidget: immutableServerData.getIn(['config', 'config_os', 'scene', 'activeWidget']) || '',
        widgets: immutableServerData.getIn(['config', 'config_os', 'scene', 'widgets']) || Immutable.Map()
      }
    })
    // 可编辑背景兼容性处理
    const handleData = handleOldEditableBg(immutableServerData)
    let data = mergeExtraConfig(handleData, 'normal')
    data = mergeExtraConfig(data, 'idle')

    // 兼容旧数据处理
    data = compatibleWithOldData(data)

    const deviceSources = data.getIn(['support', 'device_source'], []).join()
    const deviceInfo = yield call(getDeviceInfo, deviceSources)

    const wfRawData = data.set('deviceInfo', deviceInfo)
    window.sessionStorage.setItem('wf_raw_data', JSON.stringify(wfRawData))

    const newData = dealData(data).set('deviceInfo', deviceInfo)

    yield put({ type: FETCH_DESIGNER_SUCCESS })
    yield put({ type: CHANGE_DESIGNER_ACTION, data: [{ path: [], data: newData }] })
    message.success({ content: i18n.get('load_success'), key: 'fetchDesigner', duration: 2 })
    callback && callback(newData.get(dialType))
  } catch (e) {
    window.sessionStorage.removeItem('wf_raw_data')
    if (e.status === 404) {
      message.warn({
        content: i18n.get('watch_face_not_exist_tip'),
        key: 'fetchDesigner',
        duration: 2
      })
    } else if (e.status === 424) {
      // 上游依赖接口超时
      message.error({
        content: (e.data && e.data.message) || '',
        key: 'fetchDesigner',
        duration: 2
      })
    } else {
      console.log(e)
      message.error({ content: i18n.get('load_fail'), key: 'fetchDesigner', duration: 2 })
    }
    yield put({ type: FETCH_DESIGNER_ERROR })
  }
}

function* saveDesigner(action) {
  const state = yield select()
  const i18n = state.getIn(['watchFace', 'i18n', 'locale'], Immutable.Map())
  const { data = {} } = action
  const { saveType, callback } = data
  const designer = state.getIn(['watchFace', 'designer', 'present']) || Immutable.Map()
  const widget = state.getIn(['watchFace', 'scene']) || Immutable.Map()
  const { data: designerData, saveStatus } = immutableSelector(designer)
  const { normal, idle, id, metas, asset, pic_config, support = Immutable.Map() } = immutableSelector(designerData || Immutable.Map())
  const { series = {} } = immutableSelector(support)
  const isComoNess = series === SERIES.COMO || series === SERIES.NESS
  const hasSaveType = !!saveType
  let msgPrefix = ''

  if (id === -1) return

  switch (saveType) {
    case SAVE_TYPE.MODIFY:
      msgPrefix = i18n.get('modify')
      break
    case SAVE_TYPE.MANUAL:
      msgPrefix = i18n.get('save')
      if (saveStatus === 'saving') {
        yield put({ type: STOP_AUTO_SAVE })
      }
      yield put({ type: SAVE_STATUS, data: 'saving' })
      break
    case SAVE_TYPE.AUTO:
      msgPrefix = i18n.get('auto_save')
      break
    default:
      msgPrefix = i18n.get('save')
      break
  }

  hasSaveType && message.loading({ content: 'Loading...', key: 'saveDesigner' })

  const [config, newAssetAfterNormal] = dealConfigForApi(normal, 'normal', asset)
  const [idleConfig, newAssetAfterNormalIdle] = dealConfigForApi(idle, 'idle', newAssetAfterNormal)
  let targetConfig = config.set('idle_config', idleConfig).set('assets', newAssetAfterNormalIdle).set('metas', metas)

  // 添加图片类型 map
  if (isComoNess) {
    targetConfig = targetConfig.set('pic_config', pic_config)
  }

  targetConfig = separateExtraConfig(targetConfig, series, 'normal')
  targetConfig = separateExtraConfig(targetConfig, series, 'idle')
  // json2.0 save widget
  targetConfig = targetConfig.set('config_os', { scene: { ...widget.toJS(), activeWidget: '' } })

  try {
    const res = yield call(saveDesignerRequest, id, { config: targetConfig.toJS() })

    if (res.error) throw new Error()

    const immutableServerData = Immutable.fromJS(res)
    let data = mergeExtraConfig(immutableServerData, 'normal')
    data = mergeExtraConfig(data, 'idle')
    const deviceSources = data.getIn(['support', 'device_source'], []).join()
    const deviceInfo = yield call(getDeviceInfo, deviceSources)

    const wfRawData = data.set('deviceInfo', deviceInfo)
    window.sessionStorage.setItem('wf_raw_data', JSON.stringify(wfRawData))
    callback && callback()
    yield put({ type: SAVE_STATUS, data: 'saved' })
    hasSaveType && message.success({ content: `${msgPrefix} ${i18n.get('success')}`, key: 'saveDesigner', duration: 2 })
  } catch (e) {
    hasSaveType && message.error({ content: `${msgPrefix} ${i18n.get('fail')}`, key: 'saveDesigner', duration: 2 })
    window.sessionStorage.removeItem('wf_raw_data')
    yield put({ type: SAVE_STATUS, data: 'error' })
  }
}

// 获取设备列表
function* fetchSupportDevices() {
  const devices = yield getSupportDevicesApi()

  try {
    const devicesSources = devices.flatMap((device) => {
      const deviceSources = device.device_source
      return deviceSources
    })

    const devicesDictInfo = yield call(getDeviceInfo, devicesSources.join(','))

    const devicesInfo = devices.map((device) => {
      const { device_source } = device
      const names = device_source.reduce((nameStr, deviceSource) => {
        const deiceDictInfo = devicesDictInfo.find((device) => device.deviceSource === deviceSource)
        if (deiceDictInfo?.deviceNameForUser) {
          nameStr.add(deiceDictInfo.deviceNameForUser)
        }
        return nameStr
      }, new Set([]))
      return { ...device, name: Array.from(names).join(',') || device.name }
    })

    if (Array.isArray(devicesInfo)) {
      yield put({ type: SET_SUPPORT_DEVICES, data: Immutable.fromJS(devicesInfo) })
    }
  } catch(e) {
    console.error('fetchSupportDevices func exec error', e)
    if (Array.isArray(devices)) {
      yield put({ type: SET_SUPPORT_DEVICES, data: Immutable.fromJS(devices) })
    }
  }
}

function* interceptChangeDesigner(action) {
  const { data } = action

  if (pathAffectZoomFlag(data)) {
    const state = yield select()
    const asset = state.getIn(['watchFace', 'designer', 'present', 'data', 'asset'])

    if (asset?.has('fromZoom')) {
      yield put({ type: CHANGE_ASSET, data: { key: 'fromZoom', value: false } })
    }
  }

  yield put({ type: CHANGE_DESIGNER_ACTION, data })
}

function* interceptChangeScene(action) {
  const { payload } = action

  const state = yield select()
  const asset = state.getIn(['watchFace', 'designer', 'present', 'data', 'asset'])

  if (asset?.has('fromZoom')) {
    yield put({ type: CHANGE_ASSET, data: { key: 'fromZoom', value: false } })
  }

  yield put({ type: UPDATE_CATEGORY_PROPERTY_ACTION, payload })
}

function* changeDesignerWithAutoSave(action) {
  const { data } = action
  const { designerData, delayTime, canceled } = data

  if (canceled) {
    yield put({ type: SAVE_STATUS, data: 'canceled' })
    return
  }

  if (designerData) {
    // 更新 state
    yield put({ type: CHANGE_DESIGNER, data: designerData })
  }

  yield put({ type: SAVE_STATUS, data: 'saving' })

  // 延迟 delayTime ms 进行保存操作, 若 delayTime ms 内有新的请求到达，则再次延迟 delayTime ms执行保存
  if (typeof delayTime === 'number') {
    yield delay(delayTime)
    yield put({
      type: SAVE_DESIGNER,
      data: {
        saveType: SAVE_TYPE.AUTO
      }
    })
  }
}

function* stopAutoSave() {
  yield put({ type: CHANGE_DESIGNER_AUTO_SAVE, data: { canceled: true } })
}

function* deleteDial(action) {
  const {
    data: { dialId, cb }
  } = action

  try {
    yield call(deleteDialRequest, dialId)
    cb && cb()
    // message.success({
    //     key: 'deleteDial',
    //     content: '删除成功',
    //     duration: 2,
    // })
  } catch (e) {
    console.log(e)
    message.error({
      key: 'deleteDial',
      content: e.message,
      duration: 2
    })
  }
}

function* copyDial(action) {
  const { data } = action
  const { dialId, dialName, copyDeviceId, isZeppOS, cb } = data

  try {
    const detailData = yield call(fetchDesignerRequest, dialId)
    const { config = {} } = detailData
    const {
      metas: { multi_language: multiLanguage, ...restMetas },
      assets
    } = config

    delete assets.isAutoScale

    yield call(
      createUserDialApi,
      copyDeviceId,
      Immutable.fromJS({
        ...config,
        metas: {
          ...restMetas,
          multi_language:
            multiLanguage?.map((item) => ({
              ...item,
              name: dialName
            })) || []
        },
        assets
      }),
      isZeppOS
    )
    cb && cb()
    // message.success({
    //     key: 'copyDial',
    //     content: '复制成功',
    //     duration: 2,
    // })
  } catch (e) {
    console.log(e)
    message.error({
      key: 'copyDial',
      content: e.message,
      duration: 2
    })
  }
}

function* fetchUserDial(action) {
  const { data } = action
  const { pageNum, pageSize, keyword, support_ids } = data

  try {
    const data = yield call(getUserDialRequest, pageNum, pageSize, keyword, support_ids)
    yield put({ type: SET_USER_DIAL, data })
  } catch (e) {
    console.log(e)
    message.error({
      key: 'fetchUserDial',
      content: e.message,
      duration: 2
    })
  }
}

export default function* watchWatchSkin() {
  yield takeLatest(FETCH_DESIGNER, fetchDesigner)
  yield takeLatest(FETCH_SUPPORT_DEVICES, fetchSupportDevices)
  yield takeLatest(SAVE_DESIGNER, saveDesigner)
  yield takeLatest(CHANGE_DESIGNER_AUTO_SAVE, changeDesignerWithAutoSave)
  yield takeLatest(STOP_AUTO_SAVE, stopAutoSave)
  yield takeLatest(DELETE_DIAL, deleteDial)
  yield takeLatest(COPY_DIAL, copyDial)
  yield takeLatest(FETCH_USER_DIAL, fetchUserDial)
  // 拦截 action, 进行特殊逻辑处理
  yield takeLatest(CHANGE_DESIGNER, interceptChangeDesigner)
  yield takeLatest(UPDATE_CATEGORY_PROPERTY, interceptChangeScene)
}
