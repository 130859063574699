/* eslint-disable */
import { immutableSelector } from '@/utils'
import exportZip from '@watchface/components/ExportZip'
import T from '@watchface/components/I18n'
import apiHost from '@watchface/request/domains/common'
import { genZabFileName, getConfigJson, getDialNameLenByLang, getGroupedDevices, getZeppDocLink, hasEditComponentInConfig } from '@watchface/utils'
import { connect } from 'react-redux'
import { Checkbox, Input, message, Modal, Radio, Spin } from 'antd'
import { saveAs } from 'file-saver'
import Immutable from 'immutable'
import Cookies from 'js-cookie'
import PubSub from 'pubsub-js'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import io from 'socket.io-client'
import FillPreImgModal from '@watchface/components/OperationMenu/FillPreImgModal'
import {
  APP_NAME_EXIST,
  EXPORT_POLL_INTERVAL,
  EXPORT_WATCHSKIN,
  SERIES,
  SIMULATOR,
  ZOOM_STATUS_DOING,
  ZOOM_STATUS_SUCCESS,
  ZOOM_WATCHSKIN
} from '../../constants'
import ExportFail from './ExportFail'
import { resetZoomState, startExport, startZoom, stopExport, stopZoom } from '../../store/actions'
import './export.scss'
import analytics from '../../utils/analytics'

interface ExportProps {
  dialId: any
  series: string
  data?: Immutable.Map<any, any>
  asset?: Immutable.Map<any, any>
  allData?: any
  getI18nMessage?: any
  history?: any
  filename: string
  exportResult?: any
  zoomResult?: any
  zoomStatus?: number
  startExport: (dialId: any, dialType: string, no_simulator: boolean, app_name: string, pollInterval: number, resolve: any, reject: any) => void
  stopExport: () => void
  startZoom: (dialId: any, supportIds: string[]) => void
  stopZoom: () => void
  resetZoomState: () => void
  supportDevices: any
  resolution: string
  size: any
  dialNameEditable: boolean
  exportType: string
  multiLangList?: Immutable.Map<any, any>
  support?: Immutable.Map<any, any>
  initRender?: (lang?: string) => void
  savePreImg?: (imgObject: any, callback: () => void) => void
}

interface ExportState {
  msg: string
  exportFilename: string
  checkedData: any[]
  isExportModalVisible: boolean
  isZoomModalVisible: boolean
  installModalVisible: boolean
  isSimulatorVisible: boolean
  installQrcode: string
  loading: boolean
  showErrorMsg: boolean
  deviceType: string
  resolution: string
  host: string
  port: number
  connectFail: boolean
  imgMapper: any
}

class Export extends React.Component<ExportProps, ExportState> {
  input: any

  constructor(props: ExportProps) {
    super(props)
    this.state = {
      msg: '',
      exportFilename: '',
      checkedData: [],
      isExportModalVisible: false,
      isZoomModalVisible: false,
      installModalVisible: false,
      isSimulatorVisible: false,
      installQrcode: '',
      loading: false,
      showErrorMsg: false,
      deviceType: '',
      resolution: '',
      host: 'http://127.0.0.1',
      port: 7650,
      connectFail: false,
      imgMapper: {}
    }
    this.input = React.createRef()
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any): any {
    const { preFileName } = prevState
    const { filename, resolution } = nextProps

    if (preFileName !== filename) {
      return {
        ...prevState,
        preFileName: filename,
        exportFilename: filename,
        resolution
      }
    }
    return null
  }

  componentDidMount() {
    PubSub.subscribe(EXPORT_WATCHSKIN, this.showConfirmExport)
    PubSub.subscribe(ZOOM_WATCHSKIN, this.showConfirmZoom)
    PubSub.subscribe(SIMULATOR, this.showSimulator)
  }

  componentDidUpdate() {
    const { zoomResult, history, getI18nMessage, resetZoomState } = this.props
    if (zoomResult?.zoomStatus === ZOOM_STATUS_SUCCESS) {
      history?.push('/open')
      message.success(getI18nMessage('zoom_success'))
      resetZoomState()
    }
  }

  componentWillUnmount() {
    const { stopExport, stopZoom } = this.props

    PubSub.unsubscribe(EXPORT_WATCHSKIN)
    PubSub.unsubscribe(ZOOM_WATCHSKIN)
    stopExport()
    stopZoom()
  }

  get groupedZoomDevices() {
    const { supportDevices, resolution, series } = this.props
    let needDelType: any[] = []

    if (series === SERIES.COMO || series === SERIES.NESS) {
      needDelType = ['js', 'gt2']
    }
    if (series === SERIES.JS) {
      needDelType = ['como', 'ness']
    }

    const zoomDevicesMap = supportDevices.reduce((pre: any, curr: any) => {
      let newMap = pre
      const screen_resolution = curr.get('screen_resolution')
      const series = curr.get('series')
      if (needDelType.indexOf(series) > -1) return newMap
      const [w, h] = screen_resolution.split('*')

      if (!newMap.has('circle')) {
        newMap = newMap.set('circle', Immutable.List())
      }

      if (!newMap.has('square')) {
        newMap = newMap.set('square', Immutable.List())
      }

      return newMap.update(w === h ? 'circle' : 'square', (list: Immutable.List<string>) => list.push(curr))
    }, Immutable.Map() as any)
    const [w, h] = (resolution || '').split('*')
    const type = w === h ? 'circle' : 'square'
    const canZoomDevices = zoomDevicesMap
      .get(type, Immutable.List())
      .filter((item: any) => item.get('screen_resolution') !== resolution)
      .sort((a: any) => {
        if (a.get('series') === SERIES.JS) {
          return -1
        }
        return 1
      })
      .toJS()
    const groupedZoomDevices = getGroupedDevices(canZoomDevices) || {}

    return groupedZoomDevices
  }

  download = (url: string, filename: string | undefined) => {
    if (!url) return
    const { asset } = this.props
    try {
      const defaultFileName = url.replace(/.*\/(.*)?\..*/g, '$1')
      const { appId, appVersion } = immutableSelector(asset)

      saveAs(url, genZabFileName(filename || defaultFileName, appId, appVersion))
    } catch (e) {
      console.log(e)
    }
  }

  closeModal = () => {
    const { resetZoomState } = this.props
    this.setState({
      msg: ''
    })
    resetZoomState?.()
  }

  showConfirmExport = () => {
    const { exportType, multiLangList } = this.props
    const initImgMapper: any = {}

    if (exportType === 'export') {
      multiLangList?.forEach((item: any) => {
        const lang = item.get('language')
        const image = item.get('image')
        if (lang) {
          // 导出时需要系统生成预览图或者重新上传预览图
          initImgMapper[lang] = image || ''
        }
      })
    }

    this.setState({
      imgMapper: initImgMapper,
      isExportModalVisible: true
    })
  }

  handleCheckChange = (value: any[]) => {
    this.setState({
      checkedData: value
    })
  }

  showConfirmZoom = () => {
    this.setState({
      isZoomModalVisible: true
    })
  }

  handleCancelExport = () => {
    const { stopExport } = this.props
    stopExport()
    this.setState({
      isExportModalVisible: false,
      loading: false,
      showErrorMsg: false,
      installModalVisible: false,
      isSimulatorVisible: false,
      installQrcode: '',
      imgMapper: {}
    })
  }

  handleCancelZoom = () => {
    const { stopZoom, resetZoomState } = this.props

    stopZoom?.()
    resetZoomState?.()
    this.setState({
      isZoomModalVisible: false
    })
  }

  handleOkZoom = () => {
    const { dialId, series, startZoom } = this.props

    analytics.gtagEvent('zoom', '缩放表盘', {
      os_type: series
    })

    this.setState({
      loading: true
    })

    const { checkedData, deviceType } = this.state
    const defaultDeviceType = Object.keys(this.groupedZoomDevices)[0]
    const currentDeviceType = deviceType || defaultDeviceType
    const resolutions = this.groupedZoomDevices[currentDeviceType]?.map((item) => item?.screen_resolution) || []
    const newCheckedData = checkedData?.length ? checkedData : [resolutions?.[0]]

    const supportIds = newCheckedData.map((size: string) => {
      const targetDevice =
        this.groupedZoomDevices[currentDeviceType]?.find((device: any) => {
          return device?.screen_resolution === size
        }) || {}
      return targetDevice.id
    })

    startZoom?.(dialId, supportIds)
  }

  checkPreImage = () => {
    const { imgMapper } = this.state
    const { multiLangList } = this.props
    let imgCheck = true

    multiLangList?.forEach((item: any) => {
      const lang = item.get('language')
      if (!imgMapper?.[lang]) {
        imgCheck = false
      }
    })
    return imgCheck
  }

  handleOk = () => {
    const { exportType, savePreImg } = this.props
    if (exportType === 'export') {
      const imgValid = this.checkPreImage()

      if (!imgValid) {
        message.error('Please upload preview images for all languages')
        return
      }

      const { imgMapper } = this.state
      savePreImg &&
        savePreImg(imgMapper, () => {
          this.handleOkExport()
        })
    } else {
      this.handleInstallModalVisible()
    }
  }

  handleOkExport = () => {
    const { exportFilename } = this.state
    const { startExport, dialId, series } = this.props
    this.setState({
      loading: true,
      showErrorMsg: false
    })

    analytics.gtagEvent('export', '开始导出表盘包', {
      os_type: series,
      status: 'start'
    })

    const exportStartTime = Date.now()

    return new Promise((resolve, reject) => {
      startExport &&
        startExport(
          dialId,
          series === SERIES.JS ? 'js' : 'dial',
          false,
          exportFilename,
          EXPORT_POLL_INTERVAL,
          (value: any) => {
            const { url } = value.data || {}

            this.download(url, exportFilename)
            this.setState({
              msg: ''
            })

            if (url) {
              analytics.gtagEvent('export', '开始导出表盘包', {
                os_type: series,
                status: 'success',
                time_spend: (Date.now() - exportStartTime) / 1000
              })
            }

            setTimeout(() => {
              resolve(value)
              this.setState({
                isExportModalVisible: false,
                loading: false
              })
            }, 2000)
          },
          (value: any) => {
            if (value.message === APP_NAME_EXIST) {
              this.setState({
                loading: false,
                showErrorMsg: true
              })
            } else {
              this.setState({
                msg: value.message,
                isExportModalVisible: false,
                loading: false
              })
            }
            reject(value)
          }
        )
    })
      .catch((err) => console.log('export error', err))
      .finally(() => {
        // this.setState({
        //   isExportModalVisible: false,
        //   loading: false
        // })
      })
  }

  showSimulator = () => {
    this.setState({
      isSimulatorVisible: true,
      connectFail: false,
      loading: false
    })
  }

  // 模拟器 确认
  handleOkSimulator = () => {
    analytics.gtagEvent('simulator_connect', '模拟器连接', {
      os_type: SERIES.JS
    })

    const { host, port } = this.state
    const { getI18nMessage } = this.props
    if (!host || !port) {
      message.info(getI18nMessage('fill_notice_of_host_and_port'))
      return
    }

    this.setState({
      loading: true
    })

    const socket = io(`${host}:${port}`)
    socket.on('connect', async () => {
      this.handleCancelSimulator()
      try {
        const newDownloadAndInstallLoading = message.loading(getI18nMessage('simulator_loading'), 0)
        const { config, support, appId, appVersion, deviceInfo } = getConfigJson()
        await exportZip({ config, support, appId, appVersion, type: 'simulator', socket, deviceInfo, png2vg: false })
        newDownloadAndInstallLoading()
      } catch (error) {
        console.error('handleNewDownloadAndInstall', error)
        message.error(getI18nMessage('simulator_preview_fail'))
      }
    })

    socket.on('disconnect', () => {
      message.info(getI18nMessage('disconnect'))
    })

    socket.on('connect_error', () => {
      socket.close()
      this.setState({
        connectFail: true,
        loading: false
      })
    })
  }

  // 模拟器 取消
  handleCancelSimulator = () => {
    this.setState({
      isSimulatorVisible: false
    })
  }

  handleInputChange = (e: any, prop: string) => {
    this.setState({
      [prop]: e.target.value
    } as any)
  }

  handleDeviceTypeChange = (e: any) => {
    this.setState({
      checkedData: [],
      deviceType: e.target.value
    })
  }

  handleInstallModalVisible = () => {
    const { startExport, dialId, series } = this.props
    const { exportFilename } = this.state
    // 开始表盘导出操作
    this.setState({
      loading: true
    })
    analytics.gtagEvent('scan_qrcode', '开始生成表盘包', {
      os_type: series,
      status: 'start'
    })
    const startTime = Date.now()
    return new Promise((resolve, reject) => {
      startExport(
        dialId,
        series === SERIES.JS ? 'js' : 'dial',
        true,
        exportFilename,
        EXPORT_POLL_INTERVAL,
        (value: any) => {
          const { id } = value
          const userid = Cookies.get('userid')
          const qrcode = `${apiHost.apiWatch().replace('https', 'watchface')}custom/diy/qrcode/install/${id}?userid=${userid}`

          if (qrcode) {
            analytics.gtagEvent('scan_qrcode', '成功生成二维码', {
              os_type: series,
              status: 'success',
              time_spend: (Date.now() - startTime) / 1000
            })
          }

          this.setState({
            isExportModalVisible: false,
            installModalVisible: true,
            installQrcode: qrcode,
            loading: false
          })
          resolve(value)
        },
        (value: any) => {
          if (value.message === APP_NAME_EXIST) {
            this.setState({
              loading: false,
              showErrorMsg: true,
              installQrcode: '',
              installModalVisible: false
            })
          }
          reject(value)
        }
      )
    }).catch((err) => console.log('install error', err))
  }

  handlePreImgChange = (targetMapper = {}) => {
    this.setState({
      imgMapper: targetMapper
    })
  }

  render() {
    const { zoomResult = {}, allData } = this.props

    const {
      msg,
      isExportModalVisible,
      isZoomModalVisible,
      installModalVisible,
      isSimulatorVisible,
      installQrcode,
      exportFilename,
      loading,
      showErrorMsg,
      checkedData,
      deviceType,
      resolution,
      host,
      port,
      connectFail
    } = this.state
    const { zoomStatus, errorMessage } = zoomResult
    const zoomOkBtnloading = zoomStatus === ZOOM_STATUS_DOING
    const scanCodeLink = getZeppDocLink('scanCode')
    const simulatorLink = getZeppDocLink('simulator')
    // simulatorDownloadLink 模拟器下载文档地址
    const simulatorDownloadLink = getZeppDocLink('simulatorDownload')

    const { dialNameEditable, exportType } = this.props

    const defaultDeviceType = Object.keys(this.groupedZoomDevices)[0]
    const finalDeviceType = deviceType || defaultDeviceType

    const resolutions = this.groupedZoomDevices[finalDeviceType]?.map((item) => item?.screen_resolution) || []
    const maxLength = getDialNameLenByLang()

    const deviceNames: any = []
    checkedData.length
      ? this.groupedZoomDevices[finalDeviceType]?.forEach((item) => {
          if (item && checkedData.indexOf(item.screen_resolution) > -1)
            deviceNames.push({ screen_resolution: item.screen_resolution, name: item.name })
        })
      : deviceNames.push({
          screen_resolution: this.groupedZoomDevices[finalDeviceType]?.[0].screen_resolution,
          name: this.groupedZoomDevices[finalDeviceType]?.[0].name
        })

    const { multiLangList, support, initRender } = this.props
    const { shape, radius, preview_resolution } = immutableSelector(support)

    return (
      <div>
        <Modal
          style={{
            width: 500,
            height: 319
          }}
          className="watch-dial-export-modal"
          visible={!!errorMessage || !!msg}
          footer={null}
          onCancel={this.closeModal}
        >
          <ExportFail isZoom={!!errorMessage} message={errorMessage || msg} onClose={this.closeModal} />
        </Modal>
        {/* no-os export */}
        <Modal
          className="watch-dial-modal watch-export-modal"
          title={exportType === 'export' ? <T id="export" /> : <T id="install" />}
          visible={isExportModalVisible}
          cancelText={<T id="cancel" />}
          okText={exportType === 'export' ? <T id="do_export" /> : <T id="do_install" />}
          okButtonProps={{ disabled: loading, loading }}
          onCancel={this.handleCancelExport}
          onOk={this.handleOk}
          destroyOnClose
        >
          <div className="confirm-btn-centered">
            <div className="text-center ant-row export-ant-row">
              <span className="ant-col-8 modal-label">
                <T id="export_reg_name" />:
              </span>
              <Input
                className="ant-col-16"
                maxLength={maxLength}
                value={exportFilename}
                onChange={(value) => this.handleInputChange(value, 'exportFilename')}
                disabled={!dialNameEditable}
              />
            </div>
            {showErrorMsg && (
              <div className="ant-row export-ant-row error-message">
                <span className="ant-col-8 modal-label" />
                <span className="ant-col-16">
                  <T id="export_err_msg" />
                </span>
              </div>
            )}
            <div className="text-center ant-row export-ant-row">
              <span className="ant-col-8 modal-label">
                <T id="screen_resolution" />:
              </span>
              <span>{resolution}</span>
            </div>
          </div>
          {exportType === 'export' && (
            <FillPreImgModal
              multiLangList={multiLangList}
              shape={shape}
              radius={radius}
              previewResolution={preview_resolution}
              screenResolution={resolution}
              handleChange={this.handlePreImgChange}
              initRender={initRender}
              defaultPreviewMode={hasEditComponentInConfig(allData?.get('normal')) ? 0 : 1}
            />
          )}
        </Modal>
        <Modal
          wrapClassName="install-modal"
          title={null}
          footer={null}
          width={310}
          centered
          visible={installModalVisible}
          onCancel={() => this.handleCancelExport()}
        >
          {installQrcode ? (
            <div className="qrcode-main">
              <QRCodeSVG size={256} level="Q" includeMargin value={installQrcode} />
              <div className="qrcode-tips">
                <span className="qrcode-tips-gap">
                  <T id="install_tips1" />
                </span>
                <a target="_blank" rel="noreferrer" href={scanCodeLink}>
                  <T id="install_tips2" />
                </a>
              </div>
            </div>
          ) : (
            <div className="qrcode-loading">
              <Spin size="large" tip="Loading..." />
            </div>
          )}
        </Modal>
        <Modal
          className="watch-dial-modal"
          title={<T id="zoom" />}
          visible={isZoomModalVisible}
          cancelText={<T id="cancel" />}
          okText={<T id="do_zoom" />}
          okButtonProps={{ disabled: zoomOkBtnloading, loading: zoomOkBtnloading }}
          onCancel={this.handleCancelZoom}
          onOk={this.handleOkZoom}
        >
          <div className="watch-dial-tip-modal confirm-btn-centered">
            <p className="text-center export-ant-row">
              <span>
                <T id="zoom_tip" />
              </span>
            </p>
            <div className="warch-dial-zoom-content">
              <div className="export-ant-row">
                <span className="label">
                  <T id="dial_type" />:
                </span>
                <Radio.Group
                  style={{ width: '200px' }}
                  onChange={this.handleDeviceTypeChange}
                  value={finalDeviceType}
                  options={Object.keys(this.groupedZoomDevices).map((key) => ({
                    label: key.toLocaleUpperCase(),
                    value: key
                  }))}
                />
              </div>
              <div className="export-ant-row">
                <span className="label">
                  <T id="screen_resolution" />:
                </span>
                <Checkbox.Group
                  options={resolutions}
                  value={checkedData.length ? checkedData : [resolutions?.[0]]}
                  onChange={this.handleCheckChange}
                />
              </div>
              <div className="export-ant-row">
                <div className="tip">
                  {deviceNames.map((item: any) => (
                    <div key={`${item.name}${item.screen_resolution}`}>
                      {item.screen_resolution}: {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* 模拟器连接弹框 */}
        <Modal
          className="watch-dial-modal"
          title={<T id="simulator_connect" />}
          visible={isSimulatorVisible}
          cancelText={<T id="cancel" />}
          okText={<T id="connect" />}
          okButtonProps={{ disabled: loading, loading }}
          onCancel={this.handleCancelSimulator}
          onOk={this.handleOkSimulator}
        >
          <div className="watch-dial-tip-modal confirm-btn-centered">
            <div className="warch-dial-zoom-content">
              <div className="export-ant-row">
                <span className="ant-col-offset-6">
                  <a href={simulatorDownloadLink} target="_blank" rel="noreferrer">
                    <T id="simulator_download" />
                  </a>
                </span>
              </div>
              <div className="export-ant-row">
                <span className="label">Host</span>
                <Input className="ant-col-16" value={host} onChange={(value) => this.handleInputChange(value, 'host')} />
              </div>
              <div className="export-ant-row">
                <span className="label">Port</span>
                <Input
                  className="ant-col-16"
                  value={port}
                  // max={65535}
                  // precision={0}
                  onChange={(value) => this.handleInputChange(value, 'port')}
                />
              </div>
              {connectFail ? (
                <div className="export-ant-row">
                  <span className="warning-text">
                    <T id="connect_fail" />
                  </span>
                  <span>
                    <T id="simulator_notice" />
                  </span>
                  <a href={simulatorLink} target="_blank" rel="noreferrer">
                    <T id="view_document" />
                  </a>
                  <span>
                    <T id="get_more_info" />
                  </span>
                </div>
              ) : undefined}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  const { watchFace = Immutable.Map() } = immutableSelector(state)
  const { exportModule = Immutable.Map(), zoom = Immutable.Map(), devices = Immutable.Map() } = immutableSelector(watchFace)
  const { supportDevices = Immutable.List() } = immutableSelector(devices)
  const { exportResult = Immutable.Map() } = immutableSelector(exportModule)
  const zoomResult = immutableSelector(zoom)
  const { designer = Immutable.Map() } = immutableSelector(watchFace)
  const { data = Immutable.Map(), saveStatus } = immutableSelector(designer.present)
  const { support = Immutable.Map(), asset = Immutable.Map() } = immutableSelector(data)

  return {
    allData: data,
    asset,
    support,
    supportDevices,
    saveStatus,
    exportResult,
    zoomResult
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    startExport: (dialId: any, dialType: any, no_simulator: boolean, app_name: string, pollInterval: number, resolve: any, reject: any) =>
      dispatch(
        startExport({
          dialId,
          dialType,
          no_simulator,
          app_name,
          pollInterval,
          resolve,
          reject
        })
      ),
    stopExport: () => dispatch(stopExport()),
    startZoom: (dialId: any, supportIds: string[]) =>
      dispatch(
        startZoom({
          dialId,
          supportIds
        })
      ),
    stopZoom: () => dispatch(stopZoom()),
    resetZoomState: () => dispatch(resetZoomState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Export)
