/* eslint-disable import/order */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { getUniversalLoginInfo } from '@/utils/universalLogin'
import request from '@/projects/openplatform/request'
import Icon from '@watchface/components/Icon'
import { immutableSelector } from '@/utils/index'
import { UserOutlined } from '@ant-design/icons'
import logo from '@watchface/assets/images/top_bar_logo.png'
import { LangSelector, T } from '@watchface/components'
import apiHost from '@watchface/request/domains/common'
import { getUserDetail } from '@watchface/request/api'
import { getSLD, handleBrowserLang } from '@watchface/utils'
import { Avatar, Modal, Popover } from 'antd'
import Immutable from 'immutable'
import Cookies from 'js-cookie'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toolbarConfig } from '../../config/index'
import { MENU_ICON_COLOR } from '../../constants/index'
import './header.scss'

class Header extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      userData: {
        userId: '',
        nickName: '',
        email: '',
        icon: ''
      }
    }
  }

  componentDidMount() {
    this.handleUserDetail()
  }

  handleClickToolItem = (operation) => {
    const { history, isChinaZone } = this.props
    const examineType = Number(localStorage.getItem('wf_examineType') || -1)
    const { isCN } = handleBrowserLang()
    const submitPageUrl = `${apiHost.openplatform}#/service/app/audit/list/watchface`

    switch (operation) {
      case 'new':
        history.push(`/create`)
        break
      case 'open':
        history.push(`/open`)
        break
      case 'preview':
        break
      case 'upload':
        if (isChinaZone) {
          // 已认证
          if (examineType === 2) {
            window.location.href = submitPageUrl
          } else {
            Modal.warning({
              title: isCN ? '未完成华米开发者认证' : 'Need To Sign Up As a Developer',
              okText: isCN ? '去认证' : 'To Sign Up',
              onOk: () => {
                window.location.href = `${apiHost.openplatform}#/user/register/auth/enterprise?lang=${isCN ? 'zh' : 'en'}`
              }
            })
          }
        } else {
          window.location.href = submitPageUrl
        }

        break
      case 'feedback':
        history.push('/feedback')
        break
      default:
        break
    }
  }

  logOut = () => {
    const domain = getSLD()
    window.localStorage.clear()
    window.sessionStorage.clear()
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName, { path: '/', domain: `.${domain}.com` })
    })
    window.location.href = `${apiHost.universalLogin}index.html#/login?project_name=watchface&project_redirect_uri=${window.encodeURIComponent(
      window.location.href
    )}&platform_app=com.huami.webapp&specify_lang=en`
  }

  renderToolBar = () => {
    const { loading } = this.props

    return (
      <div className="tool-container">
        {toolbarConfig.map(({ name, icon, link, hide, operation }) => {
          const disabled = loading

          if (typeof hide === 'function' && hide()) return null

          return (
            <div
              key={name}
              className={disabled ? 'tool-item-disable tool-item' : 'tool-item'}
              onClick={() => {
                if (disabled) return

                if (link) {
                  const url = link.url || link.genUrl()

                  if (link.needReq) {
                    request(url).then((resp) => {
                      if (resp?.target) {
                        window.open(resp?.target)
                      }
                    })
                  } else {
                    window.open(url)
                  }

                  return
                }
                this.handleClickToolItem(operation)
              }}
            >
              <Icon type={icon} size={24} color={MENU_ICON_COLOR} />
              <span className="tool-title">
                <T id={name} />
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  handleUserDetail = () => {
    const { userData } = this.state
    const targetData = JSON.parse(JSON.stringify(userData))
    const { userId } = getUniversalLoginInfo()

    Object.assign(targetData, {
      userId,
      nickName: '',
      icon: ''
    })

    getUserDetail(userId).then(res => {
      const { nickName, iconUrl } = res || {}

      if (nickName) {
        targetData.nickName = nickName
      }
      if (iconUrl) {
        targetData.icon = iconUrl
      }

      this.setState({
        userData: targetData
      })
    }).catch(e => {
      console.log('getUserDetail err', e)
      this.setState({
        userData: targetData
      })
    })
  }

  renderUserMessage = (userData) => {
    return (
      <div className="header-right">
        <LangSelector />

        <Popover
          placement="bottomRight"
          title={
            <>
              {userData.userId && (
                <>
                  <T id="account_id" /> : <p>{userData.userId}</p>
                </>
              )}
              {userData.nickName && (
                <>
                  <T id="nickname" /> : <p>{userData.nickName}</p>
                </>
              )}
              {userData.email && (
                <>
                  <T id={userData.email?.includes('@') ? 'account_email': 'account_phone'} /> : <p>{userData.email}</p>
                </>
              )}
            </>
          }
          content={
            <>
              <p className="account-menu" onClick={this.logOut}>
                <T id="account-logout" />
              </p>
            </>
          }
          trigger="click"
          overlayClassName="header-account"
        >
          {userData.icon ? (
            <Avatar style={{ cursor: 'pointer' }} src={userData.icon} />
          ) : (
            <Avatar style={{ backgroundColor: '#595959', cursor: 'pointer' }} icon={<UserOutlined />} />
          )}
        </Popover>
      </div>
    )
  }

  render() {
    const { userData } = this.state

    return (
      <div className="header">
        <img alt="" src={logo} />
        {this.renderToolBar()}
        {this.renderUserMessage(userData)}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { watchFace = Immutable.Map() } = immutableSelector(state)
  const { designer = Immutable.Map() } = immutableSelector(watchFace)
  const { loading } = immutableSelector(designer)

  return {
    loading
  }
}
const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
