import { Button, Checkbox, Col, Form, Input, InputNumber, message, Radio, Row, Select, Tooltip } from 'antd'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import Immutable from 'immutable'
import { debounce, noop } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { escapeHtml } from 'xss'
import { NAME_LANG_MAP, PIC_LANG_MAPPER,  Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { createUserDialApi, checkOsAppIdIsValid } from '@watchface/request/api'
import { changeDesigner, Device, fetchSupportDevices, saveDesigner } from '@watchface/store/actions'
import { getDeviceType, getDialNameLenByLang, getZeppDocLink, osAppIdIsLtStartingAppId, analytics } from '@watchface/utils'
import { immutableSelector } from '@/utils/index'
import { DEFAULT_LANGUAGE_SELECT, INITIAL_VERSION, SAVE_TYPE, SERIES, TOOLTIP_COLOR } from '../../constants'
import './create.scss'
import watchfaceExampleOne from './template/example_one.json'
import watchfaceExampleTwo from './template/example_two.json'
import watchfaceExampleThree from './template/example_three.json'
import watchfaceExampleFour from './template/example_four.json'

const { Option } = Select
const { Item } = Form
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

const handleZeppOSType = (series = ''): boolean => {
  const zeppOS = ['js']
  const nonZeppOS = ['gt2', 'como', 'ness']
  if (zeppOS.includes(series)) {
    return true
  }
  if (nonZeppOS.includes(series)) {
    return false
  }
  return false
}

interface CreatePageProps {
  history?: any
  isEdit: boolean
  device?: any
  metas?: any
  intl: any
  appId?: number
  asset: any
  data: any
  supportDevices: Immutable.List<Device>
  handleCancel?: () => void
  handleClose?: () => void
  fetchSupportDevices?: () => void
  changeDesigner: (data: any) => void
  saveDesigner: (data: any) => void
}

interface languageItem {
  language: string
  name: string
}

interface DeviceItem {
  id: number
  name: string
  screen_resolution: string
  preview_resolution: string
  series: string
  shape: string
  radius: number
}

const filterDeviceList = (list: DeviceItem[], isZeppOS: boolean, resolution: string): DeviceItem[] => {
  return list.filter((item) => handleZeppOSType(item.series) === isZeppOS && (resolution ? resolution === item.screen_resolution : true))
}

const CreatePage = (props: CreatePageProps) => {
  const lastSelectedLang = useRef<any[]>([DEFAULT_LANGUAGE_SELECT])
  const { metas = Immutable.Map() } = props
  const [form] = Form.useForm()
  const { device = Immutable.Map(), appId, isEdit, supportDevices } = props
  const devices = supportDevices.toJS() as DeviceItem[]
  const initialZeppOs = isEdit ? handleZeppOSType(device.get('series')) : true
  const initialResolution = isEdit ? device.get('screen_resolution') : ''
  const initState: any = {
    selectedDevice: Immutable.Map(),
    languages: [{ language: 'en', name: '' }],
    disabledBtn: false,
    imgMapper: {},
    isZeppOS: initialZeppOs,
    isHighCost: isEdit ? Boolean(metas.get('high_cost', 0)) : false,
    resolution: initialResolution
  }
  const [state, setState] = useState(initState)

  const [languageOptions, setLanguageOptions] = useState<languageItem[]>([])

  const { isZeppOS, resolution } = state
  const resolutionList = useMemo(() => filterDeviceList(devices, isZeppOS, ''), [devices, isZeppOS])
  const defaultResolution = resolution || resolutionList[0]?.screen_resolution
  const { deviceList, supportIdList, previewResolutionList, shape, radius } = useMemo(() => {
    const list = filterDeviceList(devices, isZeppOS, defaultResolution)
    const deviceList = list.map(({ name }) => name).join(', ')
    const supportIdList = list.map(({ id }) => id)
    const previewResolutionList = list.map(({ preview_resolution }) => preview_resolution)
    let shape = 'square'
    let radius = 0
    if (list?.length) {
      shape = list[0].shape || 'square'
      radius = list[0].radius
    }
    return { deviceList, supportIdList, previewResolutionList, shape, radius }
  }, [devices, isZeppOS, defaultResolution])

  useEffect(() => {
    form.setFields([{ name: 'resolution', value: defaultResolution }])
  }, [defaultResolution, form])

  const previewResolution = device?.get('preview_resolution') || previewResolutionList[0]
  const [msg, messageCtxHolder] = message.useMessage()

  useEffect(() => {
    const { fetchSupportDevices, isEdit } = props
    !isEdit && fetchSupportDevices && fetchSupportDevices()
  }, []) // eslint-disable-line

  useEffect(() => {
    const { isEdit, device, metas = Immutable.Map() } = props
    const { selectedDevice } = state
    const newState = { ...state }

    if (isEdit) {
      const { multi_language } = immutableSelector(metas)

      if (Immutable.List.isList(multi_language)) {
        const imgMapper = { ...state.imgMapper }
        const langOptions: languageItem[] = []
        const langList: string[] = []

        multi_language.forEach((item: any) => {
          const language = item.get('language')
          const image = item.get('image')
          const name = item.get('name')
          langList.push(language)
          imgMapper[language] = image
          langOptions.push({ language, name })
        }, '')
        setLanguageOptions(langOptions)
        form.setFieldsValue({ language: langList, defaultLanguage: langList[0] })
        newState.imgMapper = imgMapper
        lastSelectedLang.current = [langList[0]]
      }
    } else {
      setLanguageOptions([{ language: DEFAULT_LANGUAGE_SELECT, name: '' }])
      form.setFieldsValue({ language: [DEFAULT_LANGUAGE_SELECT] })
    }

    if (!Immutable.is(device, selectedDevice)) {
      newState.selectedDevice = device
    }

    setState(newState)
  }, [device]) // eslint-disable-line

  const handleCreate = () => {
    const { history, isEdit, asset = Immutable.Map(), changeDesigner, handleClose, saveDesigner } = props
    // 目前 supportIdList 只有一个设备，后续可能需要支持多个
    const support_id = supportIdList[0]
    let imgCheck = true
    const hasSupportDevice = Boolean(supportIdList.length)

    form
      .validateFields()
      .then(async (values: any) => {
        const { defaultLanguage = '', appId = '' } = values
        const assets: any = {}
        if (isZeppOS) {
          assets.appVersion = INITIAL_VERSION // https://huami.feishu.cn/wiki/wikcnwmgKCOAVqJ2iIpkuCpNuBA
        }
        const multi_language = values.language
          .sort((a: string, b: string) => {
            if (a === defaultLanguage && b !== defaultLanguage) {
              return -1
            }
            return 1
          })
          .map((language: string) => {
            let lang = language

            lang = lang === 'sc' ? 'zh' : lang

            if (!state.imgMapper[lang]) {
              imgCheck = false
              console.log('imgCheck false', imgCheck, lang)
            }
            return {
              name: escapeHtml(values[`${language}_name`] || ''),
              language: lang,
              desc: '',
              image: state.imgMapper[lang] || ''
            }
          })
        const config = Immutable.fromJS({
          metas: {
            high_cost: 0,
            multi_language
          },
          assets
        })

        if (!isEdit && !hasSupportDevice) {
          msg.error(<T id="has_support_device" />)
          return
        }
        // if (!isZeppOS && !imgCheck) {
        //   msg.error(<T id="upload_thumbnail" />)
        //   return
        // }

        if (isEdit) {
          const changeData = [{ path: ['metas'], data: config.get('metas') }]

          appId && changeData.push({ path: ['asset'], data: asset.set('appId', appId) })

          changeDesigner(changeData)
          saveDesigner({ saveType: SAVE_TYPE.MODIFY })
          handleClose && handleClose()
        } else {
          setState({
            ...state,
            disabledBtn: true
          })

          createUserDialApi(support_id, config, isZeppOS)
            .then((resp: any = {}) => {
              if (resp.id) {
                analytics.gtagEvent('create', '创建表盘成功', {
                  os_type: resp?.support?.series,
                  watch_face_id: resp.id
                })

                const useNewZeppOs = localStorage.getItem('useNewZeppOs')
                history.push(isZeppOS && useNewZeppOs ? `/designer-zeppos/${resp.id}` : `/designer/${resp.id}`)
              }
            })
            .catch((e: any) => {
              console.log('createUserDialApi err', e)
              setState({
                ...state,
                disabledBtn: false
              })
            })
        }
      })
      .catch((err: any) => {
        if (err) {
          imgCheck = false
        }
      })
  }

  const handleUpload = (file: any, field: string) => {
    const imgMapper: any = { ...state.imgMapper }
    if (file) {
      imgMapper[field] = file.image || ''
    } else {
      delete imgMapper[field]
    }

    setState({
      ...state,
      imgMapper
    })
  }

  const handleLangSelectChange = (value: any) => {
    const genLangOptions = (val: any[]): languageItem[] => val.map((lang: string) => ({ language: lang, name: '' }))
    const newValue = [...value]

    form.setFieldsValue({ language: newValue })
    setLanguageOptions(genLangOptions(newValue))
    lastSelectedLang.current = newValue
  }

  const renderUploadByLang = (lang: string, previewResolution = '') => {
    const { labelCol, wrapperCol } = layout
    const [width, height] = previewResolution.split('*')
    const screen_resolution = device.get('screen_resolution', '')
    const [screenW, screenH] = screen_resolution.split('*')

    return (
      <Row className="ant-form-item" key={lang}>
        <Col span={labelCol.span} className="ant-form-item-label save-label">
          <label className="form-lable">
            <div className="label-len-limit">{PIC_LANG_MAPPER[lang].label}</div>
          </label>
        </Col>
        <Col span={wrapperCol.span}>
          <Upload
            name="file"
            field={lang}
            fileList={
              state.imgMapper[lang]
                ? [
                    {
                      image: state.imgMapper[lang]
                    }
                  ]
                : []
            }
            fileCountLimit={1}
            hideSelectImageType
            listType="picture-card"
            strictSize={{
              width: Number(screenW),
              height: Number(screenH)
            }}
            shape={shape}
            help={<div className="thumbnail-size-tip">{screen_resolution}</div>}
            onChange={handleUpload}
            cropOption={{
              targetSize: { width: Number(width), height: Number(height) },
              radius,
            }}
          />
        </Col>
      </Row>
    )
  }

  // const handleLangSelectBlur = () => {
  //   const index = languageOptions.findIndex(({ language }) => language === DEFAULT_LANGUAGE_SELECT)
  //   if (index > -1) {
  //     setLanguageOptions([{ language: DEFAULT_LANGUAGE_SELECT, name: '' }])
  //     form.setFieldsValue({ language: [DEFAULT_LANGUAGE_SELECT] })
  //   }
  // }

  const handleCancel = () => {
    const { handleCancel } = props

    handleCancel && handleCancel()
  }

  const handleOsTypeChange = (e: any) => {
    const bool = e.target.value
    setState({
      ...state,
      ...{
        isZeppOS: bool,
        resolution: '' // 切换 OS 类型时，需要重置表单屏幕分辨率字段
      }
    })
  }

  const handleResolutionChange = (resolution: string) => {
    setState({
      ...state,
      resolution
    })
  }

  const handleUseTemp = (supportId: number, isZeppOS: boolean, config: any) => {
    const { history } = props

    createUserDialApi(supportId, config, isZeppOS)
      .then((resp: any = {}) => {
        if (resp.id) {
          analytics.gtagEvent('use_temp', '使用模板创建表盘成功', {
            os_type: resp?.support?.series,
            watch_face_id: resp.id
          })
          history.push(`/designer/${resp.id}`)
        }
      })
      .catch(() => {
        setState({
          ...state,
          disabledBtn: false
        })
      })
  }

  const renderTemplate = () => {
    const data: any = Immutable.fromJS([watchfaceExampleOne, watchfaceExampleTwo, watchfaceExampleThree, watchfaceExampleFour])

    return (
      <div className="watchface-template">
        <h3>
          <T id="watchface_template" />
        </h3>
        <div className="example-list">
          {data.map((item: any) => {
            const support = item.get('support') || Immutable.Map()
            const { id: supportId, series, screen_resolution } = immutableSelector(support)
            const config = item.get('config') || Immutable.Map()
            const meta = item.getIn(['config', 'metas', 'multi_language', 0]) || Immutable.Map()
            const { name, image: preview } = immutableSelector(meta)
            const isZeppOS = series === SERIES.JS
            return (
              <div className="example-item" key={name}>
                <div
                  className="use-template-tip"
                  onClick={debounce(() => handleUseTemp(supportId, isZeppOS, config), 300, {
                    leading: true,
                    trailing: false
                  })}
                >
                  <T id="use_template" />
                </div>
                <img className="preview" src={preview} alt={(<T id="dial_preview" />) as any} />
                <Tooltip title={name} color={TOOLTIP_COLOR} mouseEnterDelay={1}>
                  <div className="dial-item-title">{name}</div>
                </Tooltip>
                <div className="device-name">{screen_resolution}</div>
                <div className="device-type">{getDeviceType(series)?.toLocaleUpperCase()}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderDialForm = () => {
    const { data = Immutable.Map(), intl, isEdit } = props
    const { isZeppOS } = state
    const { disabledBtn } = state
    const maxLength = getDialNameLenByLang()
    const renderAppIdItem = () => {
      if (!appId) return null
      if (!isZeppOS) {
        return <Item label={<T id="appId" />}>{appId}</Item>
      }
      return (
        <Item
          label={<T id="appId" />}
          name="appId"
          rules={[
            {
              validateTrigger: 'onblur',
              validator: (rule, val, callback) => {
                if (!val && val !== 0) {
                  callback()
                  return
                }

                const errMessage = intl?.messages?.['form_item_appid_message'] || 'appId is invalid'
                if (osAppIdIsLtStartingAppId(val)) {
                  callback(errMessage)
                  return
                }
                checkOsAppIdIsValid(val, data.get('id'))
                  .then(({ valid }) => {
                    if (!valid) {
                      callback(errMessage)
                    } else {
                      callback()
                    }
                  })
                  .catch((err) => {
                    callback(err?.message)
                  })
              }
            }
          ]}
          initialValue={osAppIdIsLtStartingAppId(appId) ? '' : appId}
        >
          <InputNumber maxLength={maxLength} />
        </Item>
      )
    }
    const langOptionsOfCheckbox = Object.values(NAME_LANG_MAP).map((lang: any) => {
      const { label, field } = lang
      return {
        label,
        value: field
      }
    })

    return (
      <Form
        form={form}
        {...layout}
        name="watchCreate"
        className={classNames('watch-skin-create-wrapper', {
          'watch-skin-modify': isEdit
        })}
      >
        {messageCtxHolder}
        {renderAppIdItem()}
        <Item label={<T id="support_lang" />} name="language" initialValue={languageOptions.map(({ language }) => language)}>
          <Checkbox.Group onChange={handleLangSelectChange} disabled={!isZeppOS && isEdit} options={langOptionsOfCheckbox} />
        </Item>
        {languageOptions.length <= 1 ? null : (
          <Item label={<T id="default_language" />} name="defaultLanguage" initialValue={languageOptions[0].language}>
            <Radio.Group>
              {languageOptions.map(({ language }) => (
                <Radio key={language} value={language}>
                  {NAME_LANG_MAP[language].label}
                </Radio>
              ))}
            </Radio.Group>
          </Item>
        )}
        {languageOptions.map(({ language, name }) => (
          <Item
            key={`${name}_${language}`}
            label={
              <>
                {NAME_LANG_MAP[language].label}
                <T id="dial_name" />
              </>
            }
            name={`${language}_name`}
            initialValue={name || ''}
            rules={[
              {
                required: true,
                message: (
                  <>
                    {NAME_LANG_MAP[language].label}
                    <T id="dial_name" /> is required
                  </>
                )
              }
            ]}
          >
            <Input maxLength={maxLength} />
          </Item>
        ))}
        <Item label={<T id="is_zepp_os" />}>
          <Item name="isZeppOS" noStyle initialValue={isZeppOS}>
            <Radio.Group onChange={handleOsTypeChange} disabled={isEdit}>
              <Radio value>
                <T id="yes" />
              </Radio>
              <Radio value={false}>
                <T id="no" />
              </Radio>
            </Radio.Group>
          </Item>
          <a href={getZeppDocLink('deviceList')} target="_blank" rel="noreferrer">
            <T id="device_resolution_table" />
          </a>
        </Item>
        <Item label={<T id="screen_resolution" />} name="resolution" initialValue={defaultResolution}>
          <Select dropdownClassName="watch-skin-select-dropdown" onChange={handleResolutionChange} disabled={isEdit}>
            {resolutionList.map(({ id, screen_resolution }: any) => (
              <Option key={id} value={screen_resolution}>
                {screen_resolution}
              </Option>
            ))}
          </Select>
        </Item>
        <Item className="ant-form-item__support" label={<T id="support_device" />}>
          {deviceList.split(',').map((deviceName: string) => (
            <p className="support-p" key={deviceName}>
              {deviceName}
            </p>
          ))}
        </Item>
        {isEdit ? languageOptions.map(({ language }) => renderUploadByLang(language, previewResolution)) : null}
        <div className="btn modal-footer-btns">
          {isEdit ? (
            <Button onClick={handleCancel}>
              <T id="cancel" />
            </Button>
          ) : null}
          <Button type="primary" disabled={disabledBtn} onClick={handleCreate}>
            {isEdit ? <T id="modify" /> : <T id="create" />}
          </Button>
        </div>
      </Form>
    )
  }

  if (isEdit) return renderDialForm()

  return (
    <div className="watch-skin-form-wrapper watch-skin-create">
      <div
        className={classNames('form-inner', {
          'form-inner-with-temp': !isEdit
        })}
      >
        <div className="title">
          <T id="new" />
        </div>
        <div className="pannel">
          <div className="pannel-title">
            <T id="welcome_use" />
          </div>
          <div className="pannel-subtitle" />
          <div className="create-with-temp">
            {renderDialForm()}
            {renderTemplate()}
          </div>
        </div>
      </div>
    </div>
  )
}

;(CreatePage as any).defaultProps = {
  isEdit: false,
  device: Immutable.Map(),
  metas: Immutable.Map()
}

const mapStateToProps = (state: any) => {
  const { watchFace = Immutable.Map() } = immutableSelector(state)
  const { devices = Immutable.Map() } = immutableSelector(watchFace)
  const { designer = Immutable.Map() } = immutableSelector(watchFace)
  const { data = Immutable.Map() } = immutableSelector(designer.present)
  const { asset = Immutable.Map() } = immutableSelector(data)
  const appId = data.getIn(['asset', 'appId'], '')
  const { supportDevices } = immutableSelector(devices)

  return {
    supportDevices,
    appId,
    asset,
    data
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  fetchSupportDevices: () => dispatch(fetchSupportDevices()),
  changeDesigner: (data: any) => {
    dispatch(changeDesigner(data))
  },
  saveDesigner: (data: any) => {
    dispatch(saveDesigner(data))
  }
})

CreatePage.defaultProps = {
  metas: Immutable.Map(),
  handleCancel: noop,
  handleClose: noop,
  history: null,
  device: Immutable.Map(),
  fetchSupportDevices: noop
}

export default (injectIntl as any)(connect(mapStateToProps, mapDispatchToProps)(CreatePage as any))
