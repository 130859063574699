export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.IMG_CLICK, {
      x: {{ position.x }},
      y: {{ position.y }},
      w: {{ width }},
      h: {{ height }},
      {{ #image }}
      src: '{{{ image }}}',
      {{ /image }}
      type: {{ type }},
      show_level: {{ showLevel }},
    });
`
