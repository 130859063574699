export const INPUT_STYLE = {
  width: 125
}

export const SELECT_STYLE = {
  width: 320
}

export const RADIO_STYLE = {
  display: 'block',
  height: '44px',
  lineHeight: '44px',
  color: 'rgba(204, 204, 204, 1)'
}
