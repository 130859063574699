import { Col, Divider, InputNumber, Row } from 'antd'
import * as React from 'react'

interface Props {
  screenWidth: number
  screenHeight: number
  properties: {
    x: { value: number }
    y: { value: number }
  }
  onChange: (category: string, property: string, value: any) => void
}

const Placement: React.FC<Props> = (props) => {
  const {
    screenWidth,
    screenHeight,
    properties: {
      x: { value: x },
      y: { value: y }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-placement">
      <Row justify="space-around" align="middle">
        <Col span={2}>X</Col>
        <Col span={10}>
          <InputNumber
            min={0}
            max={screenWidth}
            value={x}
            onChange={(value) => {
              onChange('placement', 'x', value)
            }}
          />
        </Col>
        <Col span={2}>Y</Col>
        <Col span={10}>
          <InputNumber
            min={0}
            max={screenHeight}
            value={y}
            onChange={(value) => {
              onChange('placement', 'y', value)
            }}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Placement
