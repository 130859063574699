import { appJs, bufferJs, devicePolyfillJs, globalJs, jsModuleJs, loggerJs, setTimeoutJs } from './templates'

const sharedData = { bufferJs, devicePolyfillJs, globalJs, jsModuleJs, loggerJs, setTimeoutJs }

const generateFolderStructure = (appJs: string, sharedData: any) => {
  const { bufferJs, devicePolyfillJs, globalJs, jsModuleJs, loggerJs, setTimeoutJs } = sharedData
  return (targetName: string, imgArr: any, appJson: string, indexJs: string) => {
    const imageList: any = []
    for (const [key, value] of Object.entries(imgArr)) {
      if (typeof value === 'string' && value.includes('.png')) {
        const item = {
          name: value,
          directory: false,
          mimeType: 'image/png',
          data: key,
          options: {},
          children: []
        }
        imageList.push(item)
      } else {
        console.warn('the image format must be png')
      }
    }

    return [
      {
        name: 'assets',
        directory: true,
        mimeType: '',
        data: null,
        options: {},
        children: [
          {
            name: targetName,
            directory: true,
            mimeType: '',
            data: null,
            options: {},
            children: imageList
          }
        ]
      },
      {
        name: 'shared',
        directory: true,
        mimeType: '',
        data: null,
        options: {},
        children: [
          {
            name: 'buffer.js',
            directory: false,
            mimeType: 'text/javascript',
            data: bufferJs,
            options: {},
            children: []
          },
          {
            name: 'device-polyfill.js',
            directory: false,
            mimeType: 'text/javascript',
            data: devicePolyfillJs,
            options: {},
            children: []
          },
          {
            name: 'global.js',
            directory: false,
            mimeType: 'text/javascript',
            data: globalJs,
            options: {},
            children: []
          },
          {
            name: 'js-module.js',
            directory: false,
            mimeType: 'text/javascript',
            data: jsModuleJs,
            options: {},
            children: []
          },
          {
            name: 'logger.js',
            directory: false,
            mimeType: 'text/javascript',
            data: loggerJs,
            options: {},
            children: []
          },
          {
            name: 'setTimeout.js',
            directory: false,
            mimeType: 'text/javascript',
            data: setTimeoutJs,
            options: {},
            children: []
          }
        ]
      },
      {
        name: 'watchface',
        directory: true,
        mimeType: '',
        data: null,
        options: {},
        children: [
          {
            name: targetName,
            directory: true,
            mimeType: '',
            data: null,
            options: {},
            children: [
              {
                name: 'index.js',
                directory: false,
                mimeType: 'text/javascript',
                data: indexJs,
                options: {},
                children: []
              }
            ]
          }
        ]
      },
      {
        name: 'app.js',
        directory: false,
        mimeType: 'text/javascript',
        data: appJs,
        options: {},
        children: []
      },
      {
        name: 'app.json',
        directory: false,
        mimeType: 'application/json',
        data: appJson,
        options: {},
        children: []
      }
    ]
  }
}

const generateFolder = generateFolderStructure(appJs, sharedData)

export default generateFolder
