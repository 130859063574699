import { DIAL_PAGE_SIZE, INITIAL_VERSION } from '@watchface/constants'
import apiHost from '@watchface/request/domains/common'
import Cookies from 'js-cookie'
import Immutable from 'immutable'
import { deviceIsPublished } from '@/utils/device'
import { getApiHost } from '@/utils/universalLogin'
import { getRandomAppid } from '../../utils'
import { getUploadConfig } from '../../config'
import request from './request'

const baseURL = apiHost.apiWatch

export function getSupportDevicesApi() {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/dial/supports`, {
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

export function createUserDialApi(supportId: number, config: any, withRandomAppId = false) {
  const userid = Cookies.get('userid')
  let immuableConfig = Immutable.fromJS(config)
  let assets = immuableConfig.get('assets') || Immutable.Map()

  assets = assets.set('appVersion', INITIAL_VERSION)

  if (withRandomAppId) {
    assets = assets.set('appId', getRandomAppid())
  } else {
    assets = assets.set('appId', '')
    immuableConfig = immuableConfig.delete('config_os')
  }
  immuableConfig = immuableConfig.set('assets', assets)

  return request(`${baseURL()}custom/diy/user-dial`, {
    method: 'post',
    baseURL: baseURL(),
    params: {
      userid
    },
    data: {
      support_id: supportId,
      config: immuableConfig
    }
  })
}

export function getUserDialRequest(page = 1, per_page = DIAL_PAGE_SIZE, keyword?: string, support_ids?: string) {
  const userid = Cookies.get('userid')
  const params: any = {
    userid,
    page,
    per_page
  }

  keyword && (params.keyword = keyword)
  support_ids && (params.support_ids = support_ids)

  return request(`${baseURL()}custom/diy/user-dial`, {
    baseURL: baseURL(),
    params
  })
}

export function getExquisiteDial(page = 1, per_page = 5) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/dial`, {
    baseURL: baseURL(),
    params: {
      userid,
      page,
      per_page
    }
  })
}

// eslint-disable-next-line
export function createExportTask(dialId: string, dialType: string = 'dial', no_simulator: boolean, app_name: string) {
  const userid = Cookies.get('userid')
  const formData = new FormData()
  formData.append('type', dialType)
  formData.append('data_id', dialId)
  formData.append('app_name', app_name)

  if (no_simulator) {
    formData.append('no_simulator', String(no_simulator))
  }

  return request(`${baseURL()}custom/diy/tasks`, {
    method: 'post',
    baseURL: baseURL(),
    params: {
      userid
    },
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  })
}

export function getTaskDetail(taskId: number) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/tasks/${taskId}`, {
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

export function fetchDesignerRequest(dialId: number) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/user-dial/${dialId}`, {
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

export function saveDesignerRequest(dialId: number, data: any) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/user-dial/${dialId}`, {
    method: 'post',
    baseURL: baseURL(),
    params: {
      userid
    },
    data
  })
}

export function zoomDesignerRequest(dialId: number, support_ids: string[]) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/user-dial/zoom/${dialId}`, {
    method: 'POST',
    baseURL: baseURL(),
    params: {
      userid
    },
    data: {
      support_ids
    }
  })
}

export function getZoomResultRequest(code: string) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/user-dial/zoom/${code}`, {
    method: 'GET',
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

export function deleteDialRequest(dialId: number) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/user-dial/${dialId}`, {
    method: 'delete',
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

/**
 *
 * @param dialId 表盘 id
 * @returns Promise<any>
 */
export function getExportStatus(dialId: string) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/user-dial/${dialId}/status`, {
    method: 'get',
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

/**
 *
 * @param dialId 表盘 id
 * @returns Promise<any>
 */
export function getAppid(dialId: string) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}custom/diy/user-dial/${dialId}/appid`, {
    method: 'get',
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

/**
 *
 * @param file // 经过 bundle 处理的 file 文件
 * @returns
 */
export function uploadDialZip(file: File) {
  const userid = Cookies.get('userid')
  const form = new FormData()
  form.append('app_type', '1')
  form.append('file', file)
  return request(`${baseURL()}custom/tools/app-dial/upload?userid=${userid}`, {
    baseURL: baseURL(),
    method: 'POST',
    data: form
  })
}

export function downloadDialZip(code: string) {
  const userid = Cookies.get('userid')

  return request(`${baseURL()}custom/tools/app-dial/download/${code}?userid=${userid}`, {
    baseURL: baseURL(),
    method: 'GET'
  })
}

// 获取认证信息
export function getAuthInfo(): Promise<any> {
  const authUrl = getApiHost('auth')
  return request(`https://${authUrl}/v2/developer`)
}

// 获取用户基本信息
export function getUserDetail(userID: string): Promise<any> {
  return request(`${apiHost.apiWatch()}users/${userID}`, {
    headers: {
      appname: 'com.xiaomi.hm.health',
      Accept: '*/*'
    }
  })
}

export function getBridgeWebSocketUrl(host: string): Promise<any> {
  return request(`https://${host}/developerbridge/client/relay`, {
    method: 'GET'
  })
}

/**
 * 获取指定设备的外框图
 * @param deviceSource
 * @returns
 */
export function getMarketStrapImage(deviceSource: number) {
  const userid = Cookies.get('userid')
  return request(`${baseURL()}market/devices/${deviceSource}/setting`, {
    baseURL: baseURL(),
    params: {
      userid
    }
  })
}

/**
 * 获取设备信息
 * @param deviceSources
 * @returns
 */
export function getDeviceInfo(deviceSources: string) {
  return request(`${baseURL()}device/settings/meta?deviceSources=${deviceSources}`, {
    baseURL: baseURL(),
    method: 'GET'
  }).then(devicesInfo => {
    if (Array.isArray(devicesInfo)) {
      return devicesInfo.map(deviceInfo => {
        // 添加 deviceNameForUser 字段，已发售使用产品名称（productNameEN）, 未发售使用代号（code）
        const { productNameEN, productName, code, releaseDate = '' } = deviceInfo
        const isPublished = deviceIsPublished(releaseDate)

        return {...deviceInfo, deviceNameForUser: isPublished ? (productNameEN || productName) : code}
      })
    }

    return devicesInfo
  })
}

export function checkOsAppIdIsValid(appId: number, watchfaceId: number) {
  const userid = Cookies.get('userid')

  if (!appId || !watchfaceId) return Promise.resolve({ valid: false })

  return request(`${baseURL()}custom/diy/user-dial/${watchfaceId}/appid/${appId}`, {
    baseURL: baseURL(),
    method: 'PUT',
    params: {
      userid
    }
  })
}

export function uploadImg(file: any) {
  const config = getUploadConfig() || {}
  const { action, headers } = config
  const targetData: any = config.data || {}
  const form = new FormData()
  Object.keys(targetData).forEach((item) => {
    form.append(item, targetData?.[item])
  })
  form.append('file', file)

  return request(action, {
    baseURL: baseURL(),
    method: 'POST',
    headers: {
      ...headers
    },
    data: form
  })
}
