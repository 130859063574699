import {
  ADD_NEW_WIDGET,
  ADD_WIDGET_CHILD,
  CHANGE_ASSET,
  CHANGE_DESIGNER,
  CHANGE_DESIGNER_AUTO_SAVE,
  CHANGE_DIAL_TYPE,
  CHANGE_LANG,
  CHANGE_MENU_NAME,
  COPY_DIAL,
  DELETE_DIAL,
  EXPORT_START,
  EXPORT_STOP,
  FETCH_DESIGNER,
  FETCH_SUPPORT_DEVICES,
  FETCH_USER_DIAL,
  INIT_THE_WIDGET_BY_API,
  REMOVE_THE_WIDGET,
  RESET_APPROVE_INFO,
  SAVE_DESIGNER,
  SET_ACTIVE_WIDGET,
  SET_APPROVE_INFO,
  SET_APPROVE_LIST,
  SET_EXQUISITE_DIAL,
  SET_SUPPORT_DEVICES,
  SET_USER_DIAL,
  STOP_AUTO_SAVE,
  UPDATE_APP_ID,
  UPDATE_AUTHOR_ID,
  UPDATE_CATEGORY_ENABLE,
  UPDATE_CATEGORY_EXPAND,
  UPDATE_CATEGORY_PROPERTY,
  UPDATE_CATEGORY_VISIBLE,
  UPDATE_DEVICE_INFO,
  UPDATE_DEVICE_SOURCE,
  UPDATE_I18N,
  UPDATE_WATCH_FACE_INFO,
  UPDATE_WATCH_FACE_LANGUAGE,
  UPDATE_WATCH_FACE_NAME,
  UPDATE_WIDGET_NAME,
  UPDATE_WIDGET_VISIBLE,
  ZOOM_DESIGNER,
  ZOOM_START,
  ZOOM_STOP,
  ZOOM_RESET_STATE
} from '../actionTypes'

export interface Device {
  id: number
  name: string
  screen_resolution: string
  [key: string]: any
}

export interface Pagination {
  total: number
  per_page: number
  page: number
  pages: number
}

export interface DialData {
  metas: {
    pagination: Pagination
  }
  data: any[]
}

export type DialType = 'normal' | 'idle'

export interface DeviceInfo {
  name: string
  width: number
  height: number
  shape: string
  radius: number
}

export interface WatchFaceInfo {
  description: string
  createTime: number
  updateTime: number
}

export const updateI18n = (langData: any) => ({
  type: UPDATE_I18N,
  data: langData
})

export const changeLang = (lang: string) => ({
  type: CHANGE_LANG,
  data: lang
})

export const setSupportDevices = (data: Device[]) => ({
  type: SET_SUPPORT_DEVICES,
  data
})

export const fetchSupportDevices = () => ({
  type: FETCH_SUPPORT_DEVICES
})

export const setUserDial = (data: any) => ({
  type: SET_USER_DIAL,
  data
})

export const setExquisiteDial = (data: DialData) => ({
  type: SET_EXQUISITE_DIAL,
  data
})

export const deleteDial = (data: any) => ({
  type: DELETE_DIAL,
  data
})

export const copyDial = (data: any) => ({
  type: COPY_DIAL,
  data
})

export const fetchUserDial = (data: any) => ({
  type: FETCH_USER_DIAL,
  data
})

export const startExport = (data: {
  dialId: string
  dialType: string
  no_simulator: boolean
  app_name: string
  pollInterval: number
  resolve: any
  reject: any
}) => ({
  type: EXPORT_START,
  data
})

export const stopExport = () => ({
  type: EXPORT_STOP
})

export const startZoom = (data: {
  dialId: string
  supportIds: string[]
}) => ({
  type: ZOOM_START,
  data
})

export const stopZoom = () => ({
  type: ZOOM_STOP
})

export const resetZoomState = () => ({
  type: ZOOM_RESET_STATE,
})

export const fetchDesigner = (data: { id: number }) => ({
  type: FETCH_DESIGNER,
  data
})

export const changeDesigner = (data: [{ path: []; data: any }]) => ({
  type: CHANGE_DESIGNER,
  data
})

export const changeAsset = (data: { key: string; value: string }) => ({
  type: CHANGE_ASSET,
  data
})

export const changeMenuName = (dialType: string, path: string[], name: string) => ({
  type: CHANGE_MENU_NAME,
  data: {
    dialType,
    path,
    name
  }
})

export const changeDesignerWithAutoSave = (designerData: any, delayTime: number, canceled?: boolean) => ({
  type: CHANGE_DESIGNER_AUTO_SAVE,
  data: {
    designerData,
    delayTime,
    canceled
  }
})

export const stopAutoSave = () => ({
  type: STOP_AUTO_SAVE
})

export const saveDesigner = (data: any) => ({
  type: SAVE_DESIGNER,
  data
})

export const zoomDesigner = (data: any) => ({
  type: ZOOM_DESIGNER,
  data
})

export const changeDialType = (dialType: DialType) => ({
  type: CHANGE_DIAL_TYPE,
  data: dialType
})

export interface IuploadMeta {
  devices: any[]
  languages: any[]
  app_platform: any[]
  app_name: any[]
  description: any[]
  preview_pic: any[]
  app_files_lang: string
  app_file_name: string
  app_files_name: any[]
  os: number
}

/**
 *
 * @param dialType
 */
export const setUploadMeta = (uploadMeta: IuploadMeta) => ({
  type: CHANGE_DIAL_TYPE,
  data: uploadMeta
})

/**
 *
 * @param dialType
 */
export const setApproveListData = (approveListData: any) => ({
  type: SET_APPROVE_LIST,
  data: approveListData
})

/**
 *
 * @param dialType
 */
export const setApproveInfoData = (approveInfoData: any) => ({
  type: SET_APPROVE_INFO,
  data: approveInfoData
})

/**
 *
 * @param dialType
 */
export const resetApproveInfoData = () => ({
  type: RESET_APPROVE_INFO
})

export const updateWatchFaceName = (name: string) => ({
  type: UPDATE_WATCH_FACE_NAME,
  payload: name
})

export const updateAppId = (appId: number) => ({
  type: UPDATE_APP_ID,
  payload: appId
})

export const updateDeviceSource = (deviceSource: number[]) => ({
  type: UPDATE_DEVICE_SOURCE,
  payload: deviceSource
})

export const updateDeviceInfo = (deviceInfo: DeviceInfo) => ({
  type: UPDATE_DEVICE_INFO,
  payload: deviceInfo
})

export const updateWatchFaceLanguage = (language: string[]) => ({
  type: UPDATE_WATCH_FACE_LANGUAGE,
  payload: language
})

export const updateAuthorId = (authorId: number) => ({
  type: UPDATE_AUTHOR_ID,
  payload: authorId
})

export const updateWatchFaceInfo = (watchFaceInfo: WatchFaceInfo) => ({
  type: UPDATE_WATCH_FACE_INFO,
  payload: watchFaceInfo
})

export const initTheWidgetByApi = (activeWidget: string, id: string, widgets: any) => ({
  type: INIT_THE_WIDGET_BY_API,
  payload: {
    activeWidget,
    id,
    widgets
  }
})

export const addNewWidget = (widget: any) => ({
  type: ADD_NEW_WIDGET,
  payload: widget
})

export const removeTheWidget = (id: string) => ({
  type: REMOVE_THE_WIDGET,
  payload: id
})

export const setActiveWidget = (activeWidget: string) => ({
  type: SET_ACTIVE_WIDGET,
  payload: activeWidget
})

export const addWidgetChild = (parentId: string, children: any) => ({
  type: ADD_WIDGET_CHILD,
  payload: { parentId, children }
})

export const updateWidgetName = (id: string, name: string) => ({
  type: UPDATE_WIDGET_NAME,
  payload: { id, name }
})

export const updateWidgetVisible = (id: string, visible: boolean) => ({
  type: UPDATE_WIDGET_VISIBLE,
  payload: { id, visible }
})

export const updateCategoryExpand = (id: string, categoryKey: string, value: boolean) => ({
  type: UPDATE_CATEGORY_EXPAND,
  payload: { id, categoryKey, value }
})

export const updateCategoryEnable = (id: string, categoryKey: string, value: boolean) => ({
  type: UPDATE_CATEGORY_ENABLE,
  payload: { id, categoryKey, value }
})

export const updateCategoryVisible = (id: string, categoryKey: string, value: boolean) => ({
  type: UPDATE_CATEGORY_VISIBLE,
  payload: { id, categoryKey, value }
})

export const updateCategoryProperty = (id: string, categoryKey: string, propertyKey: string, value: any) => ({
  type: UPDATE_CATEGORY_PROPERTY,
  payload: { id, categoryKey, propertyKey, value }
})
