const ANALOG_CLOCK_ITEM = {
  center_position: {
    x: 0,
    y: 0
  },
  pointer: {
    position: {
      x: 0,
      y: 0
    },
    image: ''
  },
  cover: {
    position: {
      x: 0,
      y: 0
    },
    image: ''
  }
}

export default ANALOG_CLOCK_ITEM
