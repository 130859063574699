export default `{{#variableDeclaration}}
{{{ . }}}
{{/variableDeclaration}}

const logger = Logger.getLogger("watchface6");

WatchFace({
  init_view() {
    {{#widgetCode}}
    {{{ . }}}
    {{/widgetCode}}
    {{#showOnPageShowWidget}}
    hmUI.createWidget(hmUI.widget.WIDGET_DELEGATE, {
      resume_call: (function () {
        {{#onPageShowCode}}
        {{{.}}}
        {{/onPageShowCode}}
      })
    })
    {{/showOnPageShowWidget}}
  },

  onInit() {
    logger.log('index page.js on init invoke')
  },

  build() {
    this.init_view()
    logger.log('index page.js on ready invoke')
  },

  onDestroy() {
    logger.log('index page.js on destroy invoke')
  },
});`
