import T from '@watchface/components/I18n'
import * as CONFIG_MAP from '@watchface/pages/DesignTool/NonZeppOs/devices'
import { Modal } from 'antd'
import React from 'react'
import { PictureProgress } from '../../components'

const componentMap = {
  image_progress: PictureProgress
}

const Weather = (props) => {
  const [modal, modalCtxHolder] = Modal.useModal()
  const handleWatchNamingRule = () => {
    const { series } = props
    const { weatherNamingMap } = CONFIG_MAP[series] || {}
    if (!weatherNamingMap) return

    modal.info({
      title: <T id="weather_naming_tip" />,
      icon: null,
      className: 'watch-dial-tip-modal confirm-btn-centered',
      centered: true,
      content: (
        <div style={{ columnCount: 3 }}>
          {Object.keys(weatherNamingMap).map((fileName) => (
            <div key={fileName}>
              {fileName}: <T id={weatherNamingMap[fileName]} />
            </div>
          ))}
        </div>
      ),
      okText: <T id="ok" />
    })
  }

  const { childKey, propMap = {} } = props
  const Component = componentMap[childKey]
  const prop = propMap[childKey] || {}
  const allProps = { ...props, ...prop }

  if (childKey === 'image_progress') {
    return (
      <>
        {modalCtxHolder}
        <PictureProgress {...allProps} handleWatchNamingRule={handleWatchNamingRule} />
      </>
    )
  }

  return (
    <>
      {modalCtxHolder}
      <div className="control-container">{Component && <Component {...allProps} />}</div>
    </>
  )
}

Weather.renderLayer = ({ childKey, ...restArgs }) => {
  const Component = componentMap[childKey]
  Component.renderLayer({ childKey, ...restArgs })
}

Weather.removeLayer = ({ childKey, ...restArgs }) => {
  const Component = componentMap[childKey]

  if (Component && Component.removeLayer) {
    Component.removeLayer({ childKey, ...restArgs })
  }
}

export default Weather
