import { AnalogClockComponent } from '@watchface/pages/DesignTool/NonZeppOs/components'
import Classnames from 'classnames'
import React, { PureComponent } from 'react'
import './index.scss'

const noop = () => {} // eslint-disable-line

class AnalogClock extends PureComponent {
  static renderLayer = (param) => {
    AnalogClockComponent.renderLayer(param)
  }

  static removeLayer = ({ childKey, ...restArgs }) => {
    const { removeLayer } = AnalogClockComponent
    removeLayer && removeLayer({ childKey, ...restArgs })
  }

  render() {
    const cls = Classnames(['analogClock-container', 'control-container'])
    return (
      <div className={cls}>
        <AnalogClockComponent {...this.props} />
      </div>
    )
  }
}

export default AnalogClock
