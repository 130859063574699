import React, { PureComponent } from 'react'
import ClassName from 'classnames'

interface IconType {
  size?: number
  color?: string
  style?: any
  isStopPropagation?: boolean
  option?: any
  type: string
  className?: string
  img?: string
  onClick?: (option: any) => void
}
class Icon extends PureComponent<IconType> {
  handleClick = (e: any) => {
    const { option, onClick, isStopPropagation } = this.props
    if (isStopPropagation) {
      e.stopPropagation()
    }
    onClick && onClick(option)
  }

  render() {
    const { type, size = 16, color = 'black', style, className, img } = this.props
    const cls = ClassName(['font_family', type, className])
    const wapperStyle = {
      fontSize: size,
      color,
      ...style
    }
    if (img) {
      const imgStyle = {
        width: size,
        height: size,
        ...style
      }
      return (
        <span onClick={this.handleClick}>
          <img className={cls} src={img} style={imgStyle} />
        </span>
      )
    }
    return <span className={cls} style={wapperStyle} onClick={this.handleClick} />
  }
}

export default Icon
