/**
 * @name 表盘action类型
 * @description 表盘的几类操作
 */

// ------------ 表盘生成 -------------
export const UPDATE_I18N = 'UPDATE_I18N'
export const CHANGE_LANG = 'CHANGE_LANG'
export const SET_SUPPORT_DEVICES = 'SET_SUPPORT_DEVICES'
export const FETCH_SUPPORT_DEVICES = 'FETCH_SUPPORT_DEVICES'
export const SET_USER_DIAL = 'SET_USER_DIAL'
export const SET_EXQUISITE_DIAL = 'SET_EXQUISITE_DIAL'
// 表盘导出
export const EXPORT_START = 'EXPORT_START'
export const EXPORT_STOP = 'EXPORT_STOP'
export const EXPORT_FAILED = 'EXPORT_FAILED'
export const EXPORT_SUCCESS = 'EXPORT_SUCCESS'
// 表盘缩放
export const ZOOM_START = 'ZOOM_START'
export const ZOOM_STOP = 'ZOOM_STOP'
export const ZOOM_FAILED = 'ZOOM_FAILED'
export const ZOOM_SUCCESS = 'ZOOM_SUCCESS'
export const ZOOM_RESET_STATE = 'ZOOM_RESET_STATE'
// 表盘更新
export const FETCH_DESIGNER_START = 'FETCH_DESIGNER_START'
export const FETCH_DESIGNER = 'FETCH_DESIGNER'
export const FETCH_DESIGNER_SUCCESS = 'FETCH_DESIGNER_SUCCESS'
export const FETCH_DESIGNER_ERROR = 'FETCH_DESIGNER_ERROR'
export const CHANGE_DESIGNER = 'CHANGE_DESIGNER'
export const CHANGE_DESIGNER_ACTION = 'CHANGE_DESIGNER_ACTION'
export const CHANGE_ASSET = 'CHANGE_ASSET'
export const CHANGE_DESIGNER_AUTO_SAVE = 'CHANGE_DESIGNER_AUTO_SAVE'
export const CHANGE_MENU_NAME = 'CHANGE_MENU_NAME'
export const SAVE_DESIGNER = 'SAVE_DESIGNER'
export const SAVE_STATUS = 'SAVE_STATUS'
export const STOP_AUTO_SAVE = 'STOP_AUTO_SAVE'
export const ZOOM_DESIGNER = 'ZOOM_DESIGNER'
// 正常表盘和息屏表盘切换
export const CHANGE_DIAL_TYPE = 'CHANGE_DIAL_TYPE'
// 表盘删除
export const DELETE_DIAL = 'DELETE_DIAL'
// 表盘复制
export const COPY_DIAL = 'COPY_DIAL'
// 获取用户表盘
export const FETCH_USER_DIAL = 'FETCH_USER_DIAL'
// 表盘审核
export const UPLOADDIAL_DRAFT = 'UPLOADDIAL_DRAFT' // 保存草稿
export const UPLOADDIAL_ADUIT = 'UPLOADDIAL_ADUIT' // 提交审核
export const UPLOADDIAL_CANCEL = 'UPLOADDIAL_CANCEL' // 取消
export const UPLOADDIAL_RECALL = 'UPLOADDIAL_RECALL' // 撤回
export const UPLOADDIAL_DELELTE = 'UPLOADDIAL_DELELTE' // 删除
export const SET_APPROVE_LIST = 'SET_APPROVE_LIST' // 保存审核列表
export const SET_APPROVE_META = 'SET_APPROVE_META' // 保存meta
export const SET_APPROVE_STATUS = 'SET_APPROVE_STATUS' // 保存status
export const SET_APPROVE_INFO = 'SET_APPROVE_INFO' // 保存Info
export const RESET_APPROVE_INFO = 'RESET_APPROVE_INFO' // 重置info
// json2.0 - meta 信息相关
export const UPDATE_WATCH_FACE_NAME = 'UPDATE_WATCH_FACE_NAME'
export const UPDATE_APP_ID = 'UPDATE_APP_ID'
export const UPDATE_DEVICE_SOURCE = 'UPDATE_DEVICE_SOURCE'
export const UPDATE_DEVICE_INFO = 'UPDATE_DEVICE_INFO'
export const UPDATE_WATCH_FACE_LANGUAGE = 'UPDATE_WATCH_FACE_LANGUAGE'
export const UPDATE_AUTHOR_ID = 'UPDATE_AUTHOR_ID'
export const UPDATE_WATCH_FACE_INFO = 'UPDATE_WATCH_FACE_INFO'
// json2.0 - widget 信息相关
export const INIT_THE_WIDGET_BY_API = 'INIT_THE_WIDGET_BY_API'
export const ADD_NEW_WIDGET = 'ADD_NEW_WIDGET'
export const REMOVE_THE_WIDGET = 'REMOVE_THE_WIDGET'
export const SET_ACTIVE_WIDGET = 'SET_ACTIVE_WIDGET'
export const ADD_WIDGET_CHILD = 'ADD_WIDGET_CHILD'
export const UPDATE_WIDGET_NAME = 'UPDATE_WIDGET_NAME'
export const UPDATE_WIDGET_VISIBLE = 'UPDATE_WIDGET_VISIBLE'
export const UPDATE_CATEGORY_EXPAND = 'UPDATE_CATEGORY_EXPAND'
export const UPDATE_CATEGORY_ENABLE = 'UPDATE_CATEGORY_ENABLE'
export const UPDATE_CATEGORY_VISIBLE = 'UPDATE_CATEGORY_VISIBLE'
export const UPDATE_CATEGORY_PROPERTY = 'UPDATE_CATEGORY_PROPERTY'
export const UPDATE_CATEGORY_PROPERTY_ACTION = 'UPDATE_CATEGORY_PROPERTY_ACTION'
