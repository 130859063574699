export default `
    {{ variable }} = {{ editGroupVariable }}.getProperty(hmUI.prop.CURRENT_TYPE);

    switch ({{ variable }}) {
      {{ #drawFuncs }}
      case hmUI.edit_type.{{ editType }}:
        {{ #funcCodes }}
        {{{ . }}}
        {{ /funcCodes }}
        break;
      {{ /drawFuncs }}
      default:
        break
    }
  `
