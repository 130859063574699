/**
 * data type: https://docs.zepp.com/zh-cn/docs/watchface/api/hmUI/widget/data_type/
 */
const DATA_TYPE = [
  {
    key: 'battery',
    value: 'hmUI.data_type.BATTERY'
  },
  {
    key: 'step',
    value: 'hmUI.data_type.STEP'
  },
  {
    key: 'stepTarget',
    value: 'hmUI.data_type.STEP_TARGET'
  },
  {
    key: 'cal',
    value: 'hmUI.data_type.CAL'
  },
  {
    key: 'calTarget',
    value: 'hmUI.data_type.CAL_TARGET'
  },
  {
    key: 'heart',
    value: 'hmUI.data_type.HEART'
  },
  {
    key: 'paiDaily',
    value: 'hmUI.data_type.PAI_DAILY'
  },
  {
    key: 'paiWeekly',
    value: 'hmUI.data_type.PAI_WEEKLY'
  },
  {
    key: 'distance',
    value: 'hmUI.data_type.DISTANCE'
  },
  {
    key: 'stand',
    value: 'hmUI.data_type.STAND'
  },
  {
    key: 'standTarget',
    value: 'hmUI.data_type.STAND_TARGET'
  },
  {
    key: 'weatherCurrent',
    value: 'hmUI.data_type.WEATHER_CURRENT'
  },
  {
    key: 'weatherLow',
    value: 'hmUI.data_type.WEATHER_LOW'
  },
  {
    key: 'weatherHigh',
    value: 'hmUI.data_type.WEATHER_HIGH'
  },
  {
    key: 'uvi',
    value: 'hmUI.data_type.UVI'
  },
  {
    key: 'aqi',
    value: 'hmUI.data_type.AQI'
  },
  {
    key: 'humidity',
    value: 'hmUI.data_type.HUMIDITY'
  },
  {
    key: 'activity',
    value: 'hmUI.data_type.ACTIVITY'
  },
  {
    key: 'activityTarget',
    value: 'hmUI.data_type.ACTIVITY_TARGET'
  },
  {
    key: 'fatBurning',
    value: 'hmUI.data_type.FAT_BURNING'
  },
  {
    key: 'fatBurningTarget',
    value: 'hmUI.data_type.FAT_BURNING_TARGET'
  },
  {
    key: 'sunCurrent',
    value: 'hmUI.data_type.SUN_CURRENT'
  },
  {
    key: 'sunrise',
    value: 'hmUI.data_type.SUN_RISE'
  },
  {
    key: 'sunset',
    value: 'hmUI.data_type.SUN_SET'
  },
  {
    key: 'wind',
    value: 'hmUI.data_type.WIND'
  },
  {
    key: 'stress',
    value: 'hmUI.data_type.STRESS'
  },
  {
    key: 'spo2',
    value: 'hmUI.data_type.SPO2'
  },
  {
    key: 'bodyTemp',
    value: 'hmUI.data_type.BODY_TEMP'
  },
  {
    key: 'altimeter',
    value: 'hmUI.data_type.ALTIMETER'
  },
  {
    key: 'moon',
    value: 'hmUI.data_type.MOON'
  },
  {
    key: 'floor',
    value: 'hmUI.data_type.FLOOR'
  },
  {
    key: 'alarmClock',
    value: 'hmUI.data_type.ALARM_CLOCK'
  },
  {
    key: 'countDown',
    value: 'hmUI.data_type.COUNT_DOWN'
  },
  {
    key: 'stopWatch',
    value: 'hmUI.data_type.STOP_WATCH'
  },
  {
    key: 'sleep',
    value: 'hmUI.data_type.SLEEP'
  }
]

const TYPE_TO_ICON: any = {
  text: 'icon-tuceng-wenzi',
  image: 'icon-tuceng-tupian',
  digitalClock: 'icon-tuceng-shuzishijian',
  digitalClockHour: 'icon-tuceng-zhizhen',
  digitalClockMinute: 'icon-tuceng-zhizhen',
  digitalClockSecond: 'icon-tuceng-zhizhen',
  digitalClockAm: 'icon-tuceng-wenzi',
  digitalClockPm: 'icon-tuceng-wenzi'
}

/**
 * category order: category 顺序
 */
const CATEGORY_ORDER = {
  name: 0,
  placement: 1,
  dimension: 2,
  rotate: 3,
  alignment: 4,
  range: 5,
  image: 6,
  imageGroup: 7,
  animation: 8,
  color: 9,
  text: 10,
  previewText: 11,
  textAppearance: 12,
  backgroundType: 13,
  progressBarType: 14,
  complicationPreview: 15,
  action: 16
}

/**
 * support key
 */
const JSON2_SUPPORTED_KEY = [
  'text',
  'image',
  'digitalClock',
  'digitalClockHour',
  'digitalClockMinute',
  'digitalClockSecond',
  'digitalClockAm',
  'digitalClockPm'
]

const DIGITAL_CLOCK_SUPPORTED_FOLLOW_KEY_LIST = ['digitalClockHour', 'digitalClockMinute', 'digitalClockSecond']

/**
 * dial type
 */
const DIAL_TYPE = {
  NORMAL: 'normal',
  IDLE: 'idle'
}

export { DATA_TYPE, CATEGORY_ORDER, JSON2_SUPPORTED_KEY, TYPE_TO_ICON, DIAL_TYPE, DIGITAL_CLOCK_SUPPORTED_FOLLOW_KEY_LIST }
