/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-use-before-define */
import { processImageUrl } from '@watchface/utils'
import { immutableSelector } from '@/utils/index'
import { Icon, Tree } from '@watchface/components'
import useGrid from '@watchface/hooks/useGrid'
import T from '@watchface/components/I18n'
import {
  CONVERT_TO_FABRIC_OBJECT_MAP,
  DIAL_TYPE,
  DIGITAL_CLOCK_SUPPORTED_FOLLOW_KEY_LIST,
  filterWidgetCategoryImageByLang,
  getKeyOrIdFromStr,
  getKeyPathFromMenu,
  getMenuFromWidget,
  isWidgetFabricKey,
  isWidgetMenuKey,
  JSON2_SUPPORTED_KEY
} from '@watchface/components/Meta'
import { FILTER_KEYS } from '@watchface/config'
import constants, {
  AUTO_SAVE_INTERVAL,
  KEYBOARD,
  MENU_ICON_COLOR,
  MOVE_IMAGE,
  SERIES,
  DATA_CHILD_KEY_ORDER_MAP,
  TOOLTIP_COLOR,
  TO_BE_ONLINE_MENU_ITEM
} from '@watchface/constants'
import { gtFont } from '@watchface/constants/images'
import { SWITCH_NEW_MENU_ITEM } from '@watchface/constants/switch'
import { useDeepCompareEffect } from '@watchface/hooks'
import * as CONFIG_MAP from '@watchface/pages/DesignTool/NonZeppOs/devices'
import {
  addNewWidget,
  addWidgetChild,
  changeAsset,
  changeDesigner,
  changeDesignerWithAutoSave,
  changeDialType,
  fetchDesigner,
  fetchSupportDevices,
  removeTheWidget,
  saveDesigner,
  startExport,
  stopAutoSave,
  stopExport,
  stopZoom,
  startZoom,
  updateWidgetVisible
} from '@watchface/store/actions'
import { initData } from '@watchface/store/reducer/designer'
import analytics from '@watchface/utils/analytics'
import initAligningGuidelines from '@watchface/utils/guideLine'
import { InputNumber, message, Modal, Select, Tabs, Tooltip } from 'antd'
import { fabric } from 'fabric'
import type { List as ImmutableList, Map as ImmutableMap } from 'immutable'
import Immutable from 'immutable'
import { debounce, throttle } from 'lodash'
import PubSub from 'pubsub-js'
import React, { cloneElement, createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { injectIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import type { RouteComponentProps } from 'react-router'
import { Prompt } from 'react-router-dom'
import { ActionCreators } from 'redux-undo'
import { v4 as uuidv4 } from 'uuid'
import tipForSpecialDeviceSingleton from '@watchface/components/TipForSpecialDevice'
import OperationMenu from '../../../components/OperationMenu'
import './index.scss'
import WatchInfo from './WatchInfo'
import StatusBar from './status-bar/StatusBar'

const { TabPane } = Tabs
const noop = () => {} // eslint-disable-line
const { Option } = Select

const hasControls = false
export const DesignContext = createContext({})
const DESIGN_GRID_KEY = 'designer-grid'

export type Lang = 'all' | 'zh' | 'en' | 'zh-Hant'
interface WatchFaceProps {
  allData: ImmutableMap<string, any>
  metas: ImmutableMap<string, any>
  menu: ImmutableList<any>
  config: ImmutableMap<string, any>
  asset: ImmutableMap<string, any>
  support: ImmutableMap<string, any>
  supportDevices: ImmutableList<any>
  watchConfig: ImmutableMap<string, any>
  dialType: string
  loading: boolean
  success: boolean
  canUndo: boolean
  canRedo: boolean
  saveStatus: string
  exportResult: any
  intl: any
  fetchDesigner: (data: any) => void
  changeDesigner: (data: any) => void
  changeAsset: (data: any) => void
  changeDesignerWithAutoSave: (data: any, delayTime: number) => void
  saveDesigner: (data?: any) => void
  undo: () => void
  redo: () => void
  clearActionHistory: () => void
  fetchSupportDevices: () => void
  startExport: (dialId: number, dialType: string, no_simulator: boolean, app_name: string, pollInterval: number, resolve: any, reject: any) => void
  stopExport: () => void
  stopAutoSave: () => void
  startBackendExport: any
  changeDialType: (dialType: string) => void
}
let a = Immutable.List()

const WatchFace: React.FunctionComponent<WatchFaceProps & RouteComponentProps> = (props) => {
  const {
    saveDesigner,
    startBackendExport,
    changeDesigner,
    changeAsset,
    undo,
    redo,
    exportResult = Immutable.Map(),
    success,
    metas,
    dialType,
    support,
    allData = Immutable.Map(),
    menu = Immutable.Map(),
    supportDevices,
    saveStatus,
    intl,
    asset,
    watchConfig,
    loading,
    canUndo,
    canRedo
  } = props
  const dispatch = useDispatch()
  const widgets = useSelector((state: any) => {
    return state.getIn(['watchFace', 'scene', 'widgets'], Immutable.Map())
  })
  const { series } = immutableSelector(support)
  const canvasPage: any = useRef(null)
  const [drawGrid, removeGrid] = useGrid(canvasPage.current, DESIGN_GRID_KEY)
  const isMovingStatus = useRef(false)
  // use for event listener handler
  const latestProps: any = useRef(props)
  const varToRecord: any = useRef({
    layerCells: {
      normal: {},
      idle: {}
    },
    isNormalCopied: false,
    currSaveStatus: '',
    shouldUpdateCellVisibleByMenu: false,
    shouldReRenderLayer: false,
    currentConfig: {},
    componentMap: {}
  })
  const [osFontLoaded, setOsFontLoaded] = useState(false)
  const isZeppOS = series === SERIES.JS
  const { screen_resolution = '' } = immutableSelector(support)

  const [state, _setState] = useState<any>({
    current: Immutable.List(),
    // 控制修改表盘信息模态框的显示和隐藏
    modifyModalVisible: false,
    systemFonts: Immutable.fromJS(gtFont),
    checkImgContent: null
  })

  const [gridW, setGridW] = useState(10)
  const [gridH, setGridH] = useState(10)
  const [showGrid, setShowGrid] = useState<boolean>(false)

  const stateRef: any = useRef(state)

  const { current } = state

  const setState = (state: any) => {
    stateRef.current = state

    _setState(state)
  }

  const handleRotationCenter = useCallback((componentKey = '') => {
    const rotationCenterList = getObjects().filter((item: any) => item.key.includes('rotationCenter'))
    rotationCenterList.forEach((rotationCenter: any) => {
      if (componentKey && rotationCenter.key.includes(componentKey)) {
        rotationCenter.set({ visible: true })
      } else {
        rotationCenter.set({ visible: false })
      }
    })
  }, [])

  if (series) {
    varToRecord.current.currentConfig = (CONFIG_MAP as any)[series] || {}
    varToRecord.current.componentMap = varToRecord.current.currentConfig?.componentMap?.(dialType) || {}
  }

  useEffect(() => {
    const { match, supportDevices = Immutable.List(), changeDesigner, fetchDesigner, fetchSupportDevices, clearActionHistory } = props
    const { params = {} } = match as any
    const { dialId } = params

    fetchDesigner({ id: dialId })

    supportDevices.size === 0 && fetchSupportDevices()

    bindGlobalEvent()

    analytics.setDialId(dialId)

    handleChangeDialType('normal')

    // 清除操作记录
    clearActionHistory()

    return () => {
      const { saveDesigner, stopAutoSave } = props

      analytics.setDialId('')

      // 取消之前的自动保存行为
      stopAutoSave()
      // 立即保存
      saveDesigner()
      // 重置 redux 的值
      changeDesigner([
        {
          path: [],
          data: initData
        }
      ])
      // 清除操作记录
      clearActionHistory()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const { menu, config, watchConfig } = props

    if (!series) return

    if (dialType === DIAL_TYPE.IDLE && screen_resolution) {
      const tip = tipForSpecialDeviceSingleton(screen_resolution)
      tip.show()
    }

    handleInitRender(Immutable.fromJS({ config, menu }))
    hideOppositeDialTypeCells(dialType === 'normal' ? 'idle' : 'normal')
  }, [dialType]) // eslint-disable-line

  useEffect(() => {
    const { config, menu } = props

    if (!series) return

    handleInit(Immutable.fromJS({ config, menu }))

    if (isZeppOS) {
      // 第一次加载字体比较耗时，后续 check 字体已加载完成，会直接跳过加载
      ;(document as any).fonts.load('100px OS FONT').then((fontFace: any) => {
        if (fontFace?.length) {
          setOsFontLoaded(true)
        }
      })
    }
  }, [series]) // eslint-disable-line

  useEffect(() => {
    const { config, menu } = props

    if (isZeppOS && osFontLoaded && canvasPage.current) {
      handleInitRender(Immutable.fromJS({ config, menu }))
    }
  }, [osFontLoaded, isZeppOS]) // eslint-disable-line

  useDeepCompareEffect(() => {
    if (varToRecord.current.shouldUpdateCellVisibleByMenu) {
      updateCellVisibleByMenu(menu, dialType)
      varToRecord.current.shouldUpdateCellVisibleByMenu = false
    }
  }, [menu, dialType])

  useEffect(() => {
    const current: any = document.querySelector('.children-item__current') || document.querySelector('.parent-container__current')
    try {
      current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    } catch (e) {
      console.log(e)
    }
  }, [JSON.stringify(state.current)]) // eslint-disable-line

  useEffect(() => {
    const { menu, config, saveStatus } = props

    varToRecord.current.currSaveStatus = saveStatus
    latestProps.current = props

    if (varToRecord.current.shouldReRenderLayer) {
      handleInitRender(Immutable.fromJS({ config, menu }))
      varToRecord.current.shouldReRenderLayer = false
    }

    // 复制完表盘后重新 renderLayer
    if (varToRecord.current.isNormalCopied) {
      varToRecord.current.isNormalCopied = false
      handleInitRender(Immutable.fromJS({ config, menu }))
    }
  })

  useEffect(() => {
    let key: any = current.join('-')
    if (typeof current[0] === 'string' && isWidgetMenuKey(current[0])) {
      key = current[current.length - 1]
    }

    if (canvasPage.current && key && !isMovingStatus.current) {
      const activeList = getObjects().filter((item: any) => item.key.includes(key) && item.key.includes(dialType))
      if (activeList.length) {
        handleRotationCenter(key)
        if (activeList[0]?.selectable) {
          canvasPage.current.setActiveObject(activeList[0])
        }
        canvasPage.current.requestRenderAll()
      }
    }
  }, [current, dialType, handleRotationCenter])

  useEffect(() => {
    showGrid
      ? drawGrid({
          gridW,
          gridH,
          stroke: '#fff'
        })
      : removeGrid()
    return () => removeGrid()
  }, [drawGrid, removeGrid, gridW, gridH, showGrid])

  const throttleKeyboardMoving = throttle(handleKeyboardMoving, 300, { trailing: true })
  const debounceOrderObjectsByMenu = debounce(orderObjectsByMenu, 20, { trailing: true })

  useHotkeys(KEYBOARD.LEFT, (e: any) => {
    e?.preventDefault()
    throttleKeyboardMoving(KEYBOARD.LEFT)
  })

  useHotkeys(KEYBOARD.UP, (e: any) => {
    e?.preventDefault()
    throttleKeyboardMoving(KEYBOARD.UP)
  })

  useHotkeys(KEYBOARD.RIGHT, (e: any) => {
    e?.preventDefault()
    throttleKeyboardMoving(KEYBOARD.RIGHT)
  })

  useHotkeys(KEYBOARD.DOWN, (e: any) => {
    e?.preventDefault()
    throttleKeyboardMoving(KEYBOARD.DOWN)
  })

  function getObjects() {
    return canvasPage?.current?.getObjects().filter((item: any) => item.key !== DESIGN_GRID_KEY) || []
  }

  function handleKeyboardMoving(direction: string) {
    if (!canvasPage?.current) return
    const activeObject = canvasPage.current.getActiveObject()
    if (!activeObject) return
    isMovingStatus.current = true
    const STEP = 1
    switch (direction) {
      case KEYBOARD.LEFT:
        activeObject.set('left', activeObject.get('left') - STEP)
        break
      case KEYBOARD.UP:
        activeObject.set('top', activeObject.get('top') - STEP)
        break
      case KEYBOARD.RIGHT:
        activeObject.set('left', activeObject.get('left') + STEP)
        break
      case KEYBOARD.DOWN:
        activeObject.set('top', activeObject.get('top') + STEP)
        break
      default:
        console.warn('wrong direction', direction)
    }
    const { left, top } = activeObject
    const x = +Number(left || 0).toFixed(0)
    const y = +Number(top || 0).toFixed(0)
    const movedImageInfo = {
      ...activeObject,
      left: x < 0 ? 0 : x,
      top: y < 0 ? 0 : y
    }
    isMovingStatus.current = false
    PubSub.publish(MOVE_IMAGE, movedImageInfo)
    activeObject.setCoords()
    canvasPage.current.requestRenderAll()
  }

  function handleInit(data: any) {
    const { watchConfig, support } = props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { shape, radius } = immutableSelector(support)
    initGraph({ width: screenWidth, height: screenHeight, supportShape: shape, supportRadius: radius })

    handleInitRender(data)
  }

  function sortMenu(menu: any, orderBy = 'ASC') {
    return menu.sortBy(
      (item: any) => item.get('order'),
      (a: number, b: number) => (orderBy === 'ASC' ? a - b : b - a)
    )
  }

  function orderObjectsByMenu(menu?: any) {
    if (!canvasPage?.current) return

    const menuKeys = menu.reverse().map((item: any) => item.get('key'))
    const reservedSpace = 1000

    const getZIndex = (layerKey: string) => {
      const [, parentKey, childKey] = layerKey.split('-')
      let startIndex = menuKeys.indexOf(parentKey)
      // json2.0 get real key
      if (isWidgetFabricKey(layerKey)) {
        // 找到 children key 和 parent key 的对应关系
        startIndex = menuKeys.findIndex((menuKey: any) => layerKey.includes(menuKey))
        if (startIndex === -1) {
          startIndex = menuKeys.size
        }
      }

      const cell = getObject(dialType, layerKey)

      const baseZIndex = startIndex * reservedSpace + ((DATA_CHILD_KEY_ORDER_MAP as any)[childKey] || 0)

      if (typeof cell?.zIndex !== 'number') {
        return baseZIndex
      }

      return cell.zIndex + baseZIndex
    }

    const orderedObjects = getObjects().sort((a: any, b: any) => {
      const aKey = a.key
      const bKey = b.key
      const aZIndex = getZIndex(aKey)
      const bZIndex = getZIndex(bKey)

      return aZIndex - bZIndex
    })

    orderedObjects.forEach((obj: any, index: number) => obj.moveTo(index))
  }

  // 判断图层是否存在 用来控制 新增 更新 删除 逻辑
  function isExist(key: string) {
    const { dialType } = props
    return !!getObject(dialType, key)
  }

  function locateModule(e: any) {
    if (!e.target?.key) return

    const { menu, changeDesignerWithAutoSave } = latestProps.current

    let curr = Immutable.List()
    let newMenu = menu

    // json2.0 locate widget location
    if (isWidgetFabricKey(e.target.key)) {
      const { dialType, key, id } = getKeyOrIdFromStr(e.target.key)
      const keyPath = getKeyPathFromMenu(`${key}_${id}`, menu.toJS())
      curr = curr.push(...keyPath)
      const menuIndex = menu.findIndex((m: any) => m.get('key') === keyPath[0])
      if (menuIndex > -1) {
        newMenu = newMenu.setIn([menuIndex, 'expand'], true)
      }
      changeDesignerWithAutoSave(
        [
          {
            path: [dialType, 'menu'],
            data: newMenu
          }
        ],
        AUTO_SAVE_INTERVAL
      )
      setState({
        ...state,
        current: curr
      })
      return
    }

    const matched: string[] = e.target.key.split('-')

    // 父子之间使用 - 分隔
    // 如果 matched length 为 3 说明只有一个层级
    // 如果 matched length 为 5 说明是可编辑组件
    const [dialType, parentKey] = matched

    if (matched.length === 3) {
      curr = Immutable.List([parentKey])
    } else if (matched[1].indexOf('component_') > -1) {
      curr = curr.push(parentKey, `${matched[2]}-${matched[3]}`)
    } else {
      curr = curr.push(parentKey, matched[2])
    }
    // 背景图可点击区域过大，易造成误跳转影响定位模块及拖拽体验，此处禁用点击背景定位背景模块
    if (parentKey === 'background') return
    if (stateRef.current.current.join() === curr.join()) return

    const menuIndex = menu.findIndex((m: any) => m.get('key') === parentKey)

    if (menuIndex > -1) {
      newMenu = newMenu.setIn([menuIndex, 'expand'], true)
    }

    changeDesignerWithAutoSave(
      [
        {
          path: [dialType, 'menu'],
          data: newMenu
        }
      ],
      AUTO_SAVE_INTERVAL
    )
    a = curr
    setState({
      ...state,
      current: curr
    })
  }

  function bindCellEvent() {
    let movedImageInfo: any = null
    const imgMoveDebounceDelay = 300

    canvasPage.current?.on('mouse:down', function (e: any) {
      const clickHandler: any = {
        // left click
        1: locateModule,
        // middle click
        2: noop,
        // right click,可添加右键菜单操作
        3: noop
      }

      clickHandler[e.button]?.(e)
    })

    canvasPage.current?.on(
      'object:moving',
      debounce(
        (e: any) => {
          const { left, top } = e.target

          isMovingStatus.current = true
          const x = +Number(left || 0).toFixed(0)
          const y = +Number(top || 0).toFixed(0)
          movedImageInfo = {
            ...e.target,
            left: x < 0 ? 0 : x,
            top: y < 0 ? 0 : y
          }
        },
        imgMoveDebounceDelay,
        // options 必须加 trailing: true, 否则会造成真实位置数据和图片实际显示位置不匹配
        { trailing: true }
      )
    )
    canvasPage.current?.on('object:moved', () => {
      // 收到 moved 事件时，'object:moving' 的回调会在 <= 300 ms后执行，所以需做延迟，等待回调执行成后重置 isMovingStatus 状态
      setTimeout(() => {
        isMovingStatus.current = false

        movedImageInfo && PubSub.publish(MOVE_IMAGE, movedImageInfo)
        movedImageInfo = null

        handleRotationCenter()
      }, imgMoveDebounceDelay)
    })
  }

  function bindGlobalEvent() {
    window.onbeforeunload = () => {
      if (varToRecord.current.currSaveStatus !== 'saved') {
        return <T id="save_tip" />
      }

      window.onbeforeunload = null
    }
    window.onunload = () => {
      window.onbeforeunload = null
      window.onunload = null
    }
  }

  // 调用 type 对应组件的render方法来渲染自身
  // 不需要关心具体组件的实现逻辑
  // 只需要提供 数据 和 方法
  function renderLayer(type: string, data: any, prop?: any, renderLayerByLang = '') {
    const { systemFonts } = state
    const { config, asset, dialType, watchConfig, support, menu } = prop || props
    const { series } = immutableSelector(support)
    if (!series || !type) return null
    let realType = type.replace(/_\d+/, '')
    // json2.0 get real widget type
    if (isWidgetMenuKey(type)) {
      const { key } = getKeyOrIdFromStr(type)
      realType = key
    }
    const { cls, propMap = {}, constMap = Immutable.Map() } = varToRecord.current.componentMap[realType] || {}

    if (!cls) return null

    const { renderLayer = noop } = cls
    const { children } = immutableSelector(data)
    const { idle, normal } = (CONFIG_MAP as any)[series]
    const nowConfig = dialType === 'normal' ? normal : idle
    const { layerConfig } = nowConfig
    const parentMenu = menu.find((item: any) => item.get('key') === type)
    if (!parentMenu) return null
    const editableComponentKey = realType === 'component' ? type : ''
    // const { hide } = immutableSelector(parentMenu)
    // if (hide) return
    if (children) {
      children.keySeq().forEach((childKey: string) => {
        const child = children.get(childKey)
        const childMenu = parentMenu.get('children').find((item: any) => {
          const key = item.get('key')

          if (key === 'ampm') {
            return childKey === 'am' || childKey === 'pm'
          }

          return item.get('key') === childKey
        })

        const { checked } = immutableSelector(childMenu)
        const prop = propMap[childKey] || {}
        const assetsChild = asset.getIn([dialType, type, 'children', childKey]) || Immutable.Map()
        checked &&
          renderLayer({
            parentKey: type,
            childKey,
            editableComponentKey,
            constMap,
            series,
            data: child,
            dialType,
            allData: config,
            assets: assetsChild,
            allAssets: asset,
            watchConfig,
            support,
            keyPrefix: `${dialType}-${type}`,
            systemFonts,
            metas,
            CONFIG_MAP: varToRecord.current.currentConfig,
            layerConfig,
            renderLayerByLang,
            isExist,
            onInsertImage: handleInsertImage,
            onInsertVertex: handleInsertVertex,
            onUpdateAttr: handleUpdateAttr,
            onRemove: handleRemoveCell,
            menu,
            ...prop
          })
      })
    } else {
      const assetsChild = asset.getIn([dialType, type]) || Immutable.Map()
      renderLayer({
        data,
        parentKey: type,
        editableComponentKey,
        constMap,
        series,
        allData: config,
        assets: assetsChild,
        allAssets: asset,
        watchConfig,
        support,
        keyPrefix: `${dialType}-${type}`,
        dialType,
        systemFonts,
        menu,
        metas,
        CONFIG_MAP: varToRecord.current.currentConfig,
        layerConfig,
        renderLayerByLang,
        isExist,
        onInsertImage: handleInsertImage,
        onInsertVertex: handleInsertVertex,
        onUpdateAttr: handleUpdateAttr,
        // onDrawPolygonByPoints: drawPolygonByPoints,
        onRemove: handleRemoveCell
      })
    }
  }

  // 初始化 mxGraph 相关
  function initGraph({ width, height, supportShape, supportRadius }: any) {
    if (canvasPage.current) return

    const customCornerStyle = {
      cornerColor: '#fea500',
      cornerSize: 5,
      borderColor: '#1977e2',
      transparentCorners: false
    }

    const canvasEl = document.createElement('canvas')
    canvasEl.setAttribute('id', 'design-tool-canvas')

    // border-radius
    const borderRadius = supportShape === 'round' ? '50%' : `${supportRadius}px`
    const containerM = document.querySelector('.container-m')
    containerM?.setAttribute('style', `background-color: #161616; border-radius: ${borderRadius};`)
    containerM?.appendChild(canvasEl)

    Object.assign(fabric.Object.prototype, customCornerStyle)

    canvasPage.current = new fabric.Canvas(canvasEl, {
      width,
      height,
      fireRightClick: true,
      backgroundColor: 'transparent',
      preserveObjectStacking: true
    })

    canvasPage.current.selection = false

    initAligningGuidelines(canvasPage.current)

    bindCellEvent()
  }

  const renderTheWidget = (key: string, renderLayerByLang?: string) => {
    const { id: widgetId } = getKeyOrIdFromStr(key)
    const widgetItem = widgets.get(widgetId).toJS()

    if (Object.hasOwnProperty.call(widgetItem, 'children') && widgetItem.type === 'digitalClock') {
      const followArr: any[] = []
      const fabricObj: any = {}
      widgetItem.children.forEach((widgetChildId: string) => {
        const widgetChildItem = widgets.get(widgetChildId).toJS()
        if (widgetChildItem.visible) {
          if (DIGITAL_CLOCK_SUPPORTED_FOLLOW_KEY_LIST.includes(widgetChildItem.type)) {
            followArr.push(widgetChildItem)
          } else {
            const childKey = `${dialType}_${widgetChildItem.type}_${widgetChildItem.id}`
            const childOptions = CONVERT_TO_FABRIC_OBJECT_MAP[widgetChildItem.type](widgetChildItem, childKey)
            fabricObj[childKey] = { parentKey: key, childKey, options: childOptions }
          }
        }
      })
      followArr.forEach((widgetChildItem) => {
        const childKey = `${dialType}_${widgetChildItem.type}_${widgetChildItem.id}`
        const childOptions: any = CONVERT_TO_FABRIC_OBJECT_MAP[widgetChildItem.type](followArr, childKey)
        const childOptionsKey = childOptions?.key || childKey
        fabricObj[childOptionsKey] = { parentKey: key, childKey: childOptionsKey, options: childOptions }
      })
      Object.keys(fabricObj).forEach((key) => {
        const { parentKey, childKey, options } = fabricObj[key]
        handleInsertImage(parentKey, childKey, options)
      })

      return
    }
    if (widgetItem.type === 'text') {
      const key = `${dialType}_${widgetItem.type}_${widgetItem.id}`
      const options = CONVERT_TO_FABRIC_OBJECT_MAP[widgetItem.type](widgetItem, renderLayerByLang)

      if (isExist(key)) {
        handleUpdateAttr(key, options)
      } else {
        handleInsertVertex('', key, options)
      }
    }
    if (widgetItem.type === 'image') {
      const key = `${dialType}_${widgetItem.type}_${widgetItem.id}`
      const options = CONVERT_TO_FABRIC_OBJECT_MAP[widgetItem.type](widgetItem)
      handleInsertImage('', key, options)
    }
  }

  // 加载数据成功后首次渲染
  function handleInitRender(data: any, renderLayerByLang?: Lang) {
    if (!canvasPage?.current) return

    canvasPage.current.discardActiveObject()

    const { config = Immutable.Map(), menu } = immutableSelector(data)

    menu.forEach((item: any) => {
      const { key } = immutableSelector(item)
      const value = config.get(key, Immutable.Map())

      if (FILTER_KEYS.indexOf(key) > -1) return
      // json2.0 render new json widget
      if (isWidgetMenuKey(key)) {
        renderTheWidget(key, renderLayerByLang || '')
      } else {
        renderLayer(key, value, undefined, renderLayerByLang)
      }
    })
  }

  function getObject(dialType: string, key: string) {
    if (!key) return false
    return varToRecord.current.layerCells[dialType][key]
  }

  function hideOppositeDialTypeCells(dialType: string) {
    const { layerCells } = varToRecord.current
    const layerKeys = Object.keys(layerCells[dialType])

    // 隐藏非当前表盘类型的 cell
    layerKeys.forEach((key) => setCellVisible(key, false, dialType))

    Object.keys(varToRecord.current.componentMap).forEach((key) => {
      varToRecord.current.componentMap[key].isHidden = false
    })
  }

  function handleChangeDialType(newDialType: string) {
    const { changeDialType, dialType } = props

    if (newDialType === dialType) return

    setState({
      ...state,
      current: Immutable.List()
    })
    changeDialType(newDialType)
    varToRecord.current.shouldUpdateCellVisibleByMenu = true
  }

  // 根据 key 获取 menu 信息
  function getLayerData(key: string) {
    const { currentConfig } = varToRecord.current
    const { dialType, metas } = props
    const { menu, layerConfig } = currentConfig[dialType]
    let layerMenu = menu.get(key)
    let layerData = layerConfig.get(key)
    // json2.0，初始化 menu 和 data
    if (JSON2_SUPPORTED_KEY.includes(layerMenu.get('key')) && series === SERIES.JS) {
      const { multi_language } = metas.toJS() as any
      const langList = multi_language.map((item: any) => item.language)
      if (menu.has(key) && layerConfig.has(key)) {
        layerMenu = menu.get(key)
        layerData = layerConfig.get(key)
        // json2.0 handle widget children
        if (layerData.has('children')) {
          const newChildren = layerData.get('children').map((item: any) => {
            const newItem = filterWidgetCategoryImageByLang(item, langList)
            return newItem.set('id', uuidv4())
          })
          layerData = layerData.set('children', newChildren)
        }
      }
      layerData = filterWidgetCategoryImageByLang(layerData, langList)
      layerData = layerData.set('id', uuidv4())
      layerMenu = Immutable.fromJS(getMenuFromWidget(layerData.get('type'), layerData.toJS()))
    }
    return {
      layerMenu,
      layerData
    }
  }

  function checkAddnto1(key: string) {
    const { config } = props
    return !config.get(key)
  }

  function handleClickLayerItem(key: string, dataHandler?: any) {
    function getMaxOrder(menuData: any) {
      const order = menuData.reduce((pre: any, curr: any) => {
        const order = curr.get('order')
        if (order || order === 0) {
          pre.push(order)
        }
        return pre
      }, [])
      return Math.max(...order)
    }

    // todo 处理图层互斥逻辑
    const { config, menu, asset, dialType, changeDesigner } = props
    const { layerMenu, layerData } = getLayerData(key)
    const { key: type, multiple, nto1 } = immutableSelector(layerMenu)
    const { currentConfig } = varToRecord.current
    let realKey = type
    let newAsset = asset
    // 额外增加的模块 目前只有 n 对 1 模式
    let appendKey = ''
    if (!layerData) return

    // json2.0 addNewWidget
    if (isWidgetMenuKey(layerMenu.get('key'))) {
      const maxMenuOrder = getMaxOrder(menu)
      const newLayerMenu = layerMenu.set('order', Number.isInteger(maxMenuOrder) ? maxMenuOrder + 1 : 0)
      let newMenuData = menu.push(newLayerMenu)
      newMenuData = sortMenu(newMenuData, 'DESC')
      const current = Immutable.List([realKey])
      if (layerData.has('children')) {
        const firstChildKey = layerMenu.getIn(['children', 0, 'key'])
        const children = layerData.get('children', Immutable.Map())
        const newLayerData = layerData.set('children', Immutable.List())
        // if (firstChildKey) {
        //   current = current.push(firstChildKey)
        // }
        dispatch(addNewWidget(newLayerData))
        children.forEach((child: any) => {
          dispatch(addWidgetChild(layerData.get('id'), child))
        })
      } else {
        dispatch(addNewWidget(layerData))
        const key = `${dialType}_${layerMenu.get('key')}`
        const options = CONVERT_TO_FABRIC_OBJECT_MAP[layerData.get('type')](layerData.toJS())
        if (layerData.get('type') === 'text') {
          handleInsertVertex('', key, options)
        }
        if (layerData.get('type') === 'image') {
          handleInsertImage('', key, options)
        }
      }
      changeDesigner([
        {
          path: [dialType, 'menu'],
          data: newMenuData
        }
      ])
      setState({
        ...state,
        current
      })
      return
    }

    if (!multiple && config.has(type)) {
      // 已经添加逻辑处理
      console.log('has')
    } else {
      let newConfig = null
      let m = currentConfig[dialType].menu.get(type)

      if (multiple) {
        const len = (config.get(type) || Immutable.Map()).size || 0
        const accNum = newAsset.getIn([dialType, 'accNum', key], len) as number
        newConfig = config.setIn([type, `${len}`], layerData)
        realKey = `${type}_${len}`

        if (nto1 && checkAddnto1(nto1)) {
          appendKey = nto1
        }

        const newAccNum = accNum + 1
        const name = `editable_component~~${newAccNum}`
        const multiName = newAsset.getIn([dialType, 'multiName']) || Immutable.List()

        m = m.set('name', getI18nMessage(name))

        newAsset = newAsset.setIn([dialType, 'accNum', key], newAccNum)

        if (Immutable.List.isList(multiName)) {
          newAsset = newAsset.updateIn([dialType, 'multiName'], (list: any) => (list || Immutable.List()).push(name))
        } else if (Immutable.Map.isMap(multiName)) {
          // 兼容
          newAsset = newAsset.setIn([dialType, 'multiName'], Immutable.List())
          multiName.forEach((value, key) => {
            newAsset = newAsset.updateIn([dialType, 'multiName'], (list: any) => list.set(key, value))
          })
          newAsset = newAsset.updateIn([dialType, 'multiName'], (list: any) => list.push(name))
        }
      } else {
        newConfig = config.set(type, layerData)
      }
      // 更新菜单
      let appendm = null

      const maxMenuOrder = getMaxOrder(menu)

      m = m.set('order', Number.isInteger(maxMenuOrder) ? maxMenuOrder + 1 : 0).set('key', realKey)

      let newMenuData = menu.push(m)

      if (appendKey) {
        const { layerMenu: a, layerData: b } = getLayerData(appendKey)
        appendm = a
        appendm = appendm.set('order', m.get('order') + 1)
        newMenuData = newMenuData.push(appendm)
        newConfig = newConfig.set(appendKey, b)
        // createParentLayer(appendKey)
      } else if (nto1) {
        const nto1ItemIndex = newMenuData.findIndex((item: any) => item.get('key') === nto1)
        if (nto1ItemIndex > -1) {
          const nto1Item = newMenuData.get(nto1ItemIndex)
          newMenuData = newMenuData.splice(nto1ItemIndex, 1).push(nto1Item.set('order', m.get('order') + 1))
        }
      }

      // 根据 order 进行排序
      newMenuData = sortMenu(newMenuData, 'DESC')

      const [targetConfig, targetMenuData, targetAsset] = dataHandler?.({
        config: newConfig,
        menuData: newMenuData,
        asset: newAsset
      }) || [newConfig, newMenuData, newAsset]

      changeDesigner([
        {
          path: [dialType, 'config'],
          data: targetConfig
        },
        {
          path: [dialType, 'menu'],
          data: targetMenuData
        },
        {
          path: ['asset'],
          data: targetAsset
        }
      ])

      // 创建新的 layer
      // createParentLayer(realKey)
      // orderCellsByMenu(newMenuData)
    }

    // 将当前选中状态更新为点击的图层
    let current = Immutable.List([realKey])

    // todo multiple 情况特殊处理
    if (!multiple) {
      varToRecord.current.componentMap[type].isHidden = false
    }

    if (layerData.has('children')) {
      const firstChildKey = layerData.get('children').keySeq().get(0)
      if (firstChildKey) {
        current = current.push(firstChildKey)
      }
    }
    setState({
      ...state,
      current
    })
  }

  function handleSelectClick(key: string) {
    if (key === 'editable_bg') {
      message.warning(getI18nMessage('move_editable_bg'))
      return null
    }
    const { support, dialType } = props
    const { series } = immutableSelector(support)
    const { currentConfig } = varToRecord.current

    if (!series || !currentConfig[dialType]) return null

    if (key === 'animation') {
      Modal.confirm({
        icon: null,
        className: 'watch-dial-tip-modal',
        title: getI18nMessage('tips'),
        content: getI18nMessage('add_animation_confirm'),
        cancelText: getI18nMessage('cancel'),
        okText: getI18nMessage('confirm_1'),
        onOk: () => {
          handleClickLayerItem(key)
        },
        centered: true
      })
    } else {
      handleClickLayerItem(key)
    }
  }

  // 图层栏
  function renderLayerBar() {
    const { support, dialType } = props
    const { series } = immutableSelector(support)
    const { currentConfig } = varToRecord.current

    if (!series || !currentConfig[dialType]) return <div className="layer-container" />

    const { topBar, menu } = currentConfig[dialType]
    const showNewMenuItem = localStorage.getItem('SWITCH_NEW_MENU_ITEM') === SWITCH_NEW_MENU_ITEM
    return (
      <div className="layer-container">
        {topBar.map((t: any) => {
          const { name, items, icon } = immutableSelector(t)
          return (
            <div key={name}>
              <Icon className="select-icon" type={icon} size={24} color={MENU_ICON_COLOR} />
              <Tooltip title={<T id={name} />} color={TOOLTIP_COLOR} mouseEnterDelay={1}>
                <Select
                  key={name}
                  dropdownClassName="watch-skin-select-dropdown"
                  style={{
                    width: '130px',
                    height: '40px'
                  }}
                  bordered={false}
                  value={getI18nMessage(name)}
                  placeholder={name}
                  onSelect={handleSelectClick}
                >
                  {items.map((item: any) => {
                    if (!showNewMenuItem && TO_BE_ONLINE_MENU_ITEM.includes(item)) {
                      return null
                    }
                    return (
                      <Option key={item} value={item}>
                        <T id={menu.getIn([item, 'name'])} />
                      </Option>
                    )
                  })}
                </Select>
              </Tooltip>
            </div>
          )
        })}
      </div>
    )
  }

  // 点击左侧菜单栏切换当前选中
  function handleClickTree(current: any) {
    try {
      const key = current.get(0, '')
      const isEditComponent = key.indexOf('component') > -1

      if (isEditComponent && current.size > 1) {
        const { asset, dialType, changeDesignerWithAutoSave } = props
        const path = [dialType, ...key.split('_')]
        const newAsset = asset.setIn([...path, 'previewState'], 'previewComponent').setIn([...path, 'value'], current.get(1).split('-')[0])
        const nextProps = { ...props, asset: newAsset }

        changeDesignerWithAutoSave(
          [
            {
              path: ['asset'],
              data: newAsset
            }
          ],
          AUTO_SAVE_INTERVAL
        )

        renderLayer(key, Immutable.Map(), nextProps)
        // orderCellsByMenu(menu)
      }
    } catch (e) {
      console.log('handleClickTree', e)
    }
    setState({
      ...state,
      current
    })
  }

  // 点击左侧菜单栏隐藏
  function handleHidden() {
    canvasPage.current?.discardActiveObject()?.renderAll()
    varToRecord.current.shouldUpdateCellVisibleByMenu = true
    setState({
      ...state,
      current: Immutable.List()
    })
  }

  // 点击左侧菜单栏的check时候
  function handleCheck() {
    varToRecord.current.shouldUpdateCellVisibleByMenu = true
  }

  // 跟随及被跟随模块禁止隐藏
  function validateBeforeToggleCheck(keyPrefix: string, check: any) {
    const { config, asset, dialType } = props
    let newAsset = asset
    let parentKey = ''
    let childKey = ''
    let componentPath = ''
    let combin = ''
    let Component = null
    let after: any = null
    let constMap = Immutable.Map()
    if (keyPrefix.startsWith('component')) {
      ;[componentPath, parentKey, childKey] = keyPrefix.split('-')
      combin = config.getIn([...componentPath.split('_'), 'props', parentKey, 'children', childKey, 'combin']) as string
    } else {
      ;[parentKey, childKey] = keyPrefix.split('-')
      combin = config.getIn([parentKey, 'children', childKey, 'combin']) as string
    }
    Component = varToRecord.current.componentMap[parentKey] || {}
    constMap = Component.constMap || Immutable.List()
    after = constMap.getIn([childKey, 'after']) || Immutable.List()
    const afterKey = after.get(0)
    let afterCombin = ''
    if (keyPrefix.startsWith('component')) {
      afterCombin = config.getIn([...componentPath.split('_'), 'props', parentKey, 'children', afterKey, 'combin']) as string
    } else {
      afterCombin = config.getIn([parentKey, 'children', afterKey, 'combin']) as string
    }
    if (combin === 'follow') {
      const namePath = componentPath ? getNamePath(componentPath, parentKey, childKey) : getNamePath(parentKey, childKey)
      const namePathArr = namePath.split('-')
      const path = namePathArr.reduce((pre: string, curr: string, index: number) => {
        const isLast = namePathArr.length - 1 === index

        return isLast ? `${pre}${getI18nMessage(curr)}` : `${pre}${getI18nMessage(curr)}-`
      }, '')

      namePath && message.warning(getI18nMessage('change_relation_tip', { path }))

      return false
    }
    if (!after.size) return true

    if (!check) {
      if (afterCombin === 'follow') {
        const namePath = componentPath ? getNamePath(componentPath, parentKey, afterKey) : getNamePath(parentKey, afterKey)
        const namePathArr = namePath.split('-')
        const path = namePathArr.reduce((pre: string, curr: string, index: number) => {
          const isLast = namePathArr.length - 1 === index

          return isLast ? `${pre}${curr}` : `${pre}${curr}-`
        }, '')

        namePath && message.warning(getI18nMessage('change_relation_tip', { path }))

        return false
      }
    }

    // 所有条件校验通过后，改变 disableFollow 的值
    newAsset = newAsset.setIn([dialType, parentKey, 'children', afterKey, 'disableFollow'], !check)
    handleChangeAssets([], newAsset)

    return true
  }

  // 处理 Modal, Message api 调用方式获取不到 context 的情况及只想取到 message string 的情况
  function getI18nMessage(id: string, values?: any) {
    const { intl } = props

    return intl?.messages[id]?.replace(/{(\w+)}/g, (match: any, name: any) => values[name]) || id
  }

  function getNamePath(...paths: any) {
    const { menu } = props
    const namePath: any[] = []
    let currentMenus = menu
    paths.forEach((path: any) => {
      const currentMenu = currentMenus.find((item: any) => item.get('key') === path) || Immutable.Map()
      currentMenus = currentMenu.get('children') || Immutable.List()
      const currentName = currentMenu.get('name')
      if (currentName) {
        namePath.push(currentName)
      }
    })
    return namePath.join('-')
  }

  function handleRemove(key: string, i: number, newData?: any) {
    const { menu: menuParam, config: configParam, asset: assetParam } = newData || {}
    const { menu: m, config, asset, changeDesignerWithAutoSave, dialType } = props
    const menu = menuParam || m
    let realKey = key.replace(/_\d+/, '')
    if (isWidgetMenuKey(key)) {
      realKey = getKeyOrIdFromStr(key).key
    }
    const { layerMenu } = getLayerData(realKey)
    const { multiple, nto1 } = immutableSelector(layerMenu)
    let newMenu = menu.splice(i, 1)
    let newConfig = configParam || config
    let newAsset = assetParam || asset

    // json2.0 handle delete widget
    if (JSON2_SUPPORTED_KEY.includes(realKey)) {
      const { id: widgetId } = getKeyOrIdFromStr(key)
      dispatch(removeTheWidget(widgetId))
      Object.keys(varToRecord.current.layerCells[dialType]).forEach((layerKey) => {
        if (layerKey === `${dialType}_${key}`) {
          handleRemoveCell(layerKey)
        }
      })
      changeDesignerWithAutoSave(
        [
          {
            path: [dialType, 'menu'],
            data: newMenu.filter((menu: any) => !menu.get('isTemporary'))
          }
        ],
        AUTO_SAVE_INTERVAL
      )
      if (current.includes(key)) {
        setState({
          ...state,
          current: Immutable.List()
        })
      }
      return
    }

    // todo multiple 情况特殊处理
    if (multiple) {
      const componentMenu = menu.filter((item: any) => item.get('key').startsWith('component_'))
      const sortedComMenu = sortMenu(componentMenu, 'ASC')
      const deleteIndex = sortedComMenu.findIndex((item: any) => item.get('key') === key)
      const path = key.replace(/\d+/, deleteIndex).split('_')

      if (nto1) {
        if (newConfig.get(path[0]).size === 1) {
          // 可编辑组件个数为 1
          newMenu = newMenu.filter((menu: any) => menu.get('key') !== nto1)
          refreshPicConfig(newConfig.get(nto1))
          newConfig = newConfig.delete(nto1)
          newAsset = newAsset.deleteIn([dialType, nto1]).setIn([dialType, 'accNum', path[0]], 0)

          setState({
            ...state,
            current: Immutable.List()
          })
        }
      }

      const multiName = newAsset.getIn([dialType, 'multiName']) || Immutable.List()

      if (Immutable.List.isList(multiName) && multiName.size) {
        newAsset = newAsset.setIn([dialType, 'multiName'], multiName.splice(Number(deleteIndex), 1))

        const accNumPath = [dialType, 'accNum', 'component']
        const latestAccNum = newAsset.getIn(accNumPath)

        if (latestAccNum) {
          newAsset = newAsset.setIn(accNumPath, latestAccNum - 1)
        }

        for (let i = deleteIndex; i < sortedComMenu.size; i += 1) {
          const item = sortedComMenu.get(i) || Immutable.Map()
          const itemKey = item.get('key') || ''
          const keyNO = Number(itemKey.split('_')[1] || '0')
          const menuIndex = newMenu.findIndex((item: any) => item.get('key') === itemKey)

          if (keyNO > 0 && menuIndex !== -1) {
            newMenu = newMenu.setIn([menuIndex, 'key'], `${path[0]}_${keyNO - 1}`)
            newConfig = newConfig.setIn([path[0], String(keyNO - 1)], newConfig.getIn([path[0], String(keyNO)]))
          }
        }
      }

      refreshPicConfig(newConfig.getIn([path[0], String(newConfig.get(path[0]).size - 1)]) as any)
      newConfig = newConfig.deleteIn([path[0], String(newConfig.get(path[0]).size - 1)])
    } else {
      refreshPicConfig(newConfig.get(key))
      newConfig = newConfig.delete(key)
      newAsset = newAsset.deleteIn([dialType, key])
      varToRecord.current.componentMap[key].isHidden = true
    }

    Object.keys(varToRecord.current.layerCells[dialType]).forEach((layerKey) => {
      if (layerKey.startsWith(`${dialType}-${key}`)) {
        handleRemoveCell(layerKey)
      }
    })

    changeDesignerWithAutoSave(
      [
        {
          path: [dialType, 'config'],
          data: newConfig
        },
        {
          path: [dialType, 'menu'],
          data: newMenu.filter((menu: any) => !menu.get('isTemporary'))
        },
        {
          path: ['asset'],
          data: newAsset
        }
      ],
      AUTO_SAVE_INTERVAL
    )
    if (current.includes(key)) {
      setState({
        ...state,
        current: Immutable.List()
      })
    }
  }

  // 设置显示隐藏
  function setCellVisible(key: string, visible: boolean, dialType: string) {
    if (key.includes('rotationCenter')) {
      return
    }
    Object.keys(varToRecord.current.layerCells[dialType]).forEach((layerKey) => {
      if (layerKey.startsWith(key)) {
        const cell = varToRecord.current.layerCells[dialType][layerKey]

        if (!cell) return

        cell.set({
          visible
        })
      }
    })
    canvasPage.current?.renderAll()
  }

  // 根据 menu 属性更新 cell 是否可见
  function updateCellVisibleByMenu(menu: any, dialType: string) {
    menu.forEach((item: any) => {
      const parentKey = item.get('key')

      if (!parentKey) return

      const hide = item.get('hide')
      // json2.0 handle widget hide
      if (isWidgetMenuKey(parentKey)) {
        setCellVisible(`${dialType}_${parentKey}`, !hide, dialType)
        const { id } = getKeyOrIdFromStr(parentKey)
        dispatch(updateWidgetVisible(id, !hide))
        const children = item.get('children')
        if (!children) return
        children.forEach((childMenu: any) => {
          const { key, checked } = immutableSelector(childMenu)
          const visible = !hide && checked
          setCellVisible(`${dialType}_${key}`, visible, dialType)
        })
        return
      }
      setCellVisible(`${dialType}-${parentKey}`, !hide, dialType)

      const children = item.get('children')
      if (!children) return
      children.forEach((childMenu: any) => {
        const { key: childKey, checked, children: subChildren = Immutable.List() } = immutableSelector(childMenu)
        if (!childKey) return

        // 父级未隐藏并且子模块 checked
        const visible = !hide && checked
        setCellVisible(`${dialType}-${parentKey}-${childKey}`, visible, dialType)
        subChildren.forEach((subChild: any) => {
          const { key: subChildKey, checked: subChecked } = immutableSelector(subChild)
          const subVisible = !hide && subChecked
          setCellVisible(`${dialType}-${parentKey}-${childKey}-${subChildKey}`, subVisible, dialType)
        })
      })
    })
  }

  // 复制图层
  function handleCopyLayer(key: string) {
    const { config, dialType, changeDesigner } = props

    // 当前仅处理复制可编辑组件的图层
    if (key.indexOf('component') > -1) {
      const { watchConfig } = props
      const { screenWidth, screenHeight } = immutableSelector(watchConfig)
      const [parentKey, num] = key.split('_')
      const sourcePath = [parentKey, num]
      const data = config.getIn(sourcePath)

      if (!data) return

      let newData = data
      let newMenu: any = null
      let newAsset: any = null
      let targetPath: any = null
      let crtConfig: any = null
      let crtComponentMenuSize = 1
      let cancelClicked = false

      // 复用已有逻辑，新增一个可编辑图层，并在回调中将被复制图层数据赋值到新的图层
      handleClickLayerItem(parentKey, ({ config, menuData, asset }: any) => {
        const componentMenus = menuData.filter((item: any) => item.get('key').startsWith('component'))
        const newIndex = componentMenus.size - 1
        const componentAsset = asset.getIn([dialType, 'component', num])

        crtComponentMenuSize = componentMenus.size

        if (newIndex < 0) return

        targetPath = [parentKey, String(newIndex)]
        const newConfig = config.setIn(targetPath, newData)

        // 设置 isTemporary，防止自动保存时将用户点击 “确定” 时复制的图层保存到表盘数据中
        newMenu = menuData
          .setIn([1, 'isTemporary'], true)
          .setIn([1, 'children'], componentMenus.find((menu: any) => menu.get('key') === key)?.get('children'))

        newAsset = asset.setIn([dialType, 'component', String(newIndex)], componentAsset)
        crtConfig = newConfig

        return [newConfig, newMenu, newAsset]
      })

      const pos: any = { x: 0, y: 0 }
      // 实时更改新复制出来的图层位置，供用户预览
      const handleChangePos = (key: string, value: number) => {
        if (typeof value !== 'number') return

        pos[key] = value

        const { x: increaseX = 0, y: increaseY = 0 } = pos
        const mapImmutableMap = (mapObj: any) =>
          mapObj.map((value: any, key: string) => {
            // 递归遍历数组或者 Map，将更改 key 为 'x' 和 'y' 的值
            if (Immutable.Map.isMap(value) || Immutable.List.isList(value)) {
              return mapImmutableMap(value)
            }

            if (key === 'x') {
              return value + (increaseX || 0)
            }

            if (key === 'y') {
              return value + (increaseY || 0)
            }

            return value
          })
        newData = mapImmutableMap(data)

        const newConfig = crtConfig.setIn(targetPath, newData)

        changeDesigner([
          {
            path: [dialType, 'config'],
            data: newConfig
          }
        ])
      }

      const content = (
        <>
          <div className="name">{getI18nMessage?.('position')}</div>
          <div className="content">
            <div className="item">
              <div className="col">
                <div className="key">x</div>
                <InputNumber
                  min={-screenWidth}
                  max={screenWidth}
                  defaultValue={0}
                  onChange={debounce((value) => {
                    !cancelClicked && handleChangePos('x', value)
                  }, 300)}
                />
              </div>
              <div className="col">
                <div className="key">y</div>
                <InputNumber
                  min={-screenHeight}
                  max={screenHeight}
                  defaultValue={0}
                  onChange={debounce((value) => !cancelClicked && handleChangePos('y', value), 300)}
                />
              </div>
            </div>
          </div>
        </>
      )

      Modal.confirm({
        icon: null,
        className: 'watch-dial-tip-modal copy-layer-item-wrap',
        title: getI18nMessage('copy_layer_item_title'),
        content,
        style: {
          top: 20
        },
        cancelText: getI18nMessage('cancel'),
        okText: getI18nMessage('confirm_1'),
        onOk: () => {
          if (!newMenu) return

          newMenu = newMenu.setIn([1, 'isTemporary'], false)

          changeDesigner([
            {
              path: [dialType, 'menu'],
              data: newMenu
            }
          ])
        },
        onCancel: () => {
          // 防止多次调用 handleRemove
          if (!cancelClicked) {
            newMenu = newMenu.setIn([1, 'isTemporary'], false)

            handleRemove(`${parentKey}_${crtComponentMenuSize - 1}`, 1, {
              menu: newMenu,
              config: crtConfig,
              asset: newAsset
            })
          }
          cancelClicked = true
        }
      })
    }
  }

  // 复制正常表盘配置
  function handleCopyNormalLayer(idleData: any) {
    const isNess = series === SERIES.NESS
    // const nessTip = isNess ? '(日期模块不可复制)' : ''
    // const content = `复制正常表盘会使当前编辑的息屏表盘被覆盖${nessTip}，确定复制吗？`
    const content = getI18nMessage('copy_dial_tip')
    Modal.confirm({
      icon: null,
      className: 'watch-dial-tip-modal',
      title: getI18nMessage('tips'),
      content,
      cancelText: getI18nMessage('cancel'),
      okText: getI18nMessage('confirm_1'),
      onOk: () => {
        const { dialType, changeDesignerWithAutoSave } = props
        const { menu, config, asset } = immutableSelector(idleData)
        const layerKeys = Object.keys(varToRecord.current.layerCells[dialType])

        varToRecord.current.isNormalCopied = true

        Object.keys(varToRecord.current.componentMap).forEach((key) => {
          varToRecord.current.componentMap[key].isHidden = false
        })
        // 去除已有的息屏表盘图层
        layerKeys.forEach((key) => {
          setCellVisible(key, false, dialType)
          handleRemoveCell(key)
          delete varToRecord.current.layerCells[dialType][key]
        })

        changeDesignerWithAutoSave(
          [
            {
              path: ['idle', 'config'],
              data: config
            },
            {
              path: ['idle', 'menu'],
              data: menu
            },
            {
              path: ['asset'],
              data: asset
            }
          ],
          AUTO_SAVE_INTERVAL
        )
      },
      centered: true
    })
  }

  // 表盘类型为息屏表盘时执行
  function getIdleDataFromNormalData() {
    const { allData, support, asset, dialType } = props

    const { normal: normalData = Immutable.Map() } = immutableSelector(allData)
    const { config: normalConfig = Immutable.Map(), menu: normalMenu = Immutable.List() } = immutableSelector(normalData)
    const { series } = immutableSelector(support)

    if (dialType !== 'idle' || !series || !varToRecord.current.currentConfig[dialType]) {
      return Immutable.fromJS({
        menu: normalMenu,
        config: normalConfig,
        asset
      })
    }

    const uploadedImages = asset.get('uploadedImages') || Immutable.Map()
    const { normal: normalUploadedImages = Immutable.Map(), idle: idleUploadedImages = Immutable.Map() } = immutableSelector(uploadedImages)
    const { menu: idleMenu } = varToRecord.current.currentConfig[dialType]
    let newIdleMenu = Immutable.List()
    let newIdleConfig = Immutable.Map()
    let newIdleAsset = asset.get('normal') || Immutable.Map()
    let newIdleUploadedImages = idleUploadedImages
    const isNess = series === SERIES.NESS

    normalMenu.forEach((item: any) => {
      const parentKey = item.get('key')

      if (!idleMenu.has(parentKey) || (isNess && parentKey === 'date')) {
        if (newIdleAsset.has(parentKey)) {
          newIdleAsset = newIdleAsset.delete(parentKey)
        }

        return
      }

      newIdleMenu = newIdleMenu.push(item)
      newIdleConfig = newIdleConfig.set(parentKey, normalConfig.get(parentKey))

      const children = item.get('children')
      const idleChildren = idleMenu.getIn([parentKey, 'children']) || Immutable.List()

      if (!Immutable.List.isList(children)) return
      const idleParentIndex = newIdleMenu.size - 1

      children.forEach((item: any) => {
        const childKey = item.get('key')
        const idleChildIndex = idleChildren.findIndex((idleChild: any) => idleChild.get('key') === childKey)
        const uploadImageKey = `${parentKey}-${childKey}`
        const normalUploadImage = normalUploadedImages.get(uploadImageKey)

        if (normalUploadImage) {
          newIdleUploadedImages = newIdleUploadedImages.set(uploadImageKey, normalUploadImage)
        }

        // 息屏中存在此字段
        if (idleChildIndex !== -1) {
          newIdleMenu = newIdleMenu.setIn(
            [idleParentIndex, 'children', idleChildIndex],
            item.merge(
              Immutable.Map({
                checked: item.get('checked')
              })
            )
          )
          return
        }

        // 删除息屏中没有的字段
        const basePath = [parentKey, 'children']

        if (childKey === 'ampm') {
          newIdleConfig = newIdleConfig.deleteIn([...basePath, 'am']).deleteIn([...basePath, 'pm'])

          newIdleAsset = newIdleAsset.deleteIn([...basePath, 'am']).deleteIn([...basePath, 'pm'])
        } else {
          newIdleConfig = newIdleConfig.deleteIn([...basePath, childKey])

          newIdleAsset = newIdleAsset.deleteIn([...basePath, childKey])
        }

        newIdleMenu = newIdleMenu.deleteIn([idleParentIndex, 'children', idleChildIndex])
      })
    })

    return Immutable.fromJS({
      menu: newIdleMenu,
      config: newIdleConfig,
      asset: asset.set('idle', newIdleAsset).setIn(['uploadedImages', 'idle'], newIdleUploadedImages)
    })
  }

  // 左侧属性结构
  function renderTreePanel() {
    const { menu, success, dialType, support = Immutable.Map() } = props
    const { series } = immutableSelector(support)
    const { supportIdleScreen = true } = (constants as any)[series] || {}
    let showCopyBtn = false
    let idleData: any = Immutable.Map()

    if (dialType === 'idle') {
      idleData = getIdleDataFromNormalData()
      showCopyBtn = idleData.get('menu').size
    }
    const tree = (
      <Tree
        dataSource={menu}
        current={current}
        onChange={handleTreeChange}
        onClick={handleClickTree}
        onHide={handleHidden}
        onCheck={handleCheck}
        onRemove={handleRemove}
        onCopyLayer={debounce(handleCopyLayer, 300)}
        onCopy={() => handleCopyNormalLayer(idleData)}
        validateBeforeToggleCheck={validateBeforeToggleCheck}
        showCopyBtn={showCopyBtn}
      />
    )

    return (
      <div className="tree-panel">
        <Tabs defaultActiveKey="normal" activeKey={dialType} onChange={success ? handleChangeDialType : noop} type="card">
          <TabPane tab={<T id="normal" />} key="normal">
            {tree}
          </TabPane>
          <TabPane tab={<T id="idle" />} key="idle" disabled={!supportIdleScreen}>
            {tree}
          </TabPane>
        </Tabs>
      </div>
    )
  }

  // 修改左侧菜单数据
  function handleTreeChange(data: any, reOrder = false) {
    const { dialType, changeDesignerWithAutoSave } = props
    // json2.0 handle tree change
    data.forEach((item: any) => {
      const parentKey = item.get('key')
      if (!parentKey) return
      if (isWidgetMenuKey(parentKey)) {
        const children = item.get('children')
        if (!children) return
        children.forEach((childMenu: any) => {
          const { key, checked } = immutableSelector(childMenu)
          const { id } = getKeyOrIdFromStr(key)
          const visible = checked
          if (!visible) {
            if (current.includes(key)) {
              setState({
                ...state,
                current: Immutable.List()
              })
            }
          }
          dispatch(updateWidgetVisible(id, visible))
        })
      }
    })
    changeDesignerWithAutoSave(
      [
        {
          path: [dialType, 'menu'],
          data
        }
      ],
      AUTO_SAVE_INTERVAL
    )

    if (reOrder) {
      debounceOrderObjectsByMenu(data)
    }
  }

  function isValidImage(width: number, height: number) {
    const { watchConfig } = props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    if (width - screenWidth > 0 || height - screenHeight > 0) {
      message.warning(getI18nMessage('img_oversize_err', { width: screenWidth, height: screenHeight }))
      return false
    }
    return true
  }

  // 创建图片
  async function createImage({ key, options }: any, cb?: (images: any) => void) {
    const isGroup = options.type === 'group'
    const newOptions = isGroup ? options?.objects : [options]

    try {
      const images = await newOptions.reduce(async (pre: any[], option: any) => {
        const newOption = { ...option, key: isGroup ? `${options.key}-${key}` : key }
        const { texture = '', width, height } = newOption
        let image = texture

        if (!isValidImage(width, height) || !texture) return pre

        if (texture instanceof Promise) {
          const newTexture = await texture

          newOption.texture = newTexture
          newOption.url = newTexture
          image = newTexture
        }

        const img = await loadImageFromUrl(image, newOption)

        return [...(await pre), img]
      }, [])

      cb?.(isGroup ? images : images?.[0])
    } catch (e) {
      console.log(e)
    }
  }

  function loadImageFromUrl(url: string, option: any) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        processImageUrl(url),
        function (image: fabric.Image) {
          if (image) {
            resolve(image)
          } else {
            reject()
          }
        },
        {
          ...option,
          crossOrigin: 'anonymous',
          hasControls
        }
      )
    })
  }

  function loadImage(url: string) {
    return new Promise((resolve, reject) => {
      fabric.util.loadImage(
        processImageUrl(url),
        function (image: HTMLImageElement) {
          if (image) {
            resolve(image)
          } else {
            reject()
          }
        },
        null,
        'anonymous'
      )
    })
  }

  // 创建vertex
  function createVertex(options: any) {
    const isGroup = options.type === 'group'

    const map: any = {
      triangle: createTriangleCell,
      // 圆弧、圆环、圆均可使用不同的设置实现
      circle: createCircleCell,
      rect: createRectCell,
      text: createTextCell
    }

    if (!isGroup) return map[options.type](options)

    const newOptions = options?.objects

    if (isZeppOS && !osFontLoaded) {
      const hasText = newOptions.some((option: any) => option.type === 'text')
      if (hasText) {
        return
      }
    }

    return newOptions.map((option: any) => map[option.type](option))
  }

  function createCircleCell(option: fabric.ICircleOptions) {
    return new fabric.Circle({ ...option, hasControls })
  }

  function createRectCell(option: fabric.IRectOptions) {
    return new fabric.Rect({ ...option, hasControls })
  }

  function createTextCell(option: fabric.ITextOptions & { text: string; callback?: any }) {
    const { text, callback, ...restOption } = option
    const textCell = new fabric.Text(text, { ...restOption, hasControls, fontFamily: 'OS FONT' })

    callback && callback(textCell, canvasPage.current)

    return textCell
  }

  function createTriangleCell(option: fabric.ITriangleOptions) {
    return new fabric.Triangle({ ...option, hasControls })
  }

  // 插入图片
  function handleInsertImage(parentKey: string, key: string, options: any) {
    if (!options) {
      return
    }
    createImage({ key, options }, (images) => {
      if (isExist(key)) return

      handleInsert({ parentKey, key, object: images, dialType, options })
      canvasPage.current?.renderAll()
    })
  }

  // 插入一个节点 比如画一个圆
  function handleInsertVertex(parentKey: string, key: string, options: any) {
    if (isExist(key) || !options) return

    const { dialType } = props
    const vertexes = createVertex(options)
    handleInsert({ parentKey, key, object: vertexes, dialType, options })
  }

  // 更新 cell 的 属性
  async function handleUpdateAttr(key: string, option: any) {
    // 图片拖拽过程中不执行更新
    if (isMovingStatus.current) return

    const { dialType } = props
    const cell = getObject(dialType, key)

    if (!cell) return

    const { texture, objects, callback, ...restOption } = option

    restOption && Object.assign(restOption, { key })

    const handleTexture = async (cellItem: any, texture: any, options?: any) => {
      if (!texture || !cellItem) return
      let newTexture = texture

      if (texture instanceof Promise) {
        newTexture = await texture
      }

      const img = await loadImage(newTexture)
      cellItem.set({ texture, ...options })
      cellItem.setElement(img)
    }

    if (Array.isArray(objects) && Array.isArray(cell.getObjects())) {
      if (!canvasPage.current || cell.isEmpty()) return

      const index = getObjects().indexOf(cell)
      const cellSize = cell.size()

      if (index === -1 || cellSize === 0) return

      const updateGroupItem = async (object: any, cell: any, cellIndex: number) => {
        const { texture, left, top, callback, ...restOpt } = object

        if (cellIndex === 0) {
          cell.set({ left, top })
        } else {
          cell.item(cellIndex)?.set({
            left: left + (cell.item(0).left || 0) - cell.left
            // top: top + (cell.item(0).top || 0) - cell.top,
          })
        }

        if (texture) {
          await handleTexture(cell.item(cellIndex), texture, restOpt)
        } else {
          cell.item(cellIndex)?.set({ texture, ...restOpt })
        }

        callback && callback(cell.item(cellIndex), canvasPage.current, cell)
      }

      /* eslint-disable no-await-in-loop */
      // 删除组内元素
      if (objects.length < cellSize) {
        let i = 0
        for (; i < objects.length; i += 1) {
          if (!objects[i]) break
          await updateGroupItem(objects[i], cell, i)
        }

        const removeItem = []

        for (let j = i; j < cellSize; j += 1) {
          removeItem.push(cell.item(j))
        }

        removeItem.forEach((item) => cell.removeWithUpdate(item))
      } else {
        for (let i = 0; i < cellSize; i += 1) {
          if (!objects[i]) break
          await updateGroupItem(objects[i], cell, i)
        }
        cell?.addWithUpdate()
        // 新增组内元素
        if (objects.length > cellSize) {
          const addItem = []
          for (let i = cellSize; i < objects.length; i += 1) {
            if (!objects[i]) break
            const { texture, left, top, ...restOpt } = objects[i]

            if (texture) {
              let newTexture = texture

              if (texture instanceof Promise) {
                newTexture = await texture
              }

              const img: any = await loadImageFromUrl(newTexture, { texture, ...restOpt })

              img.set({ left, top })

              addItem.push(img)
            }
          }
          addItem.forEach((item: any) => cell.addWithUpdate(item))
        }
      }
      /* eslint-enable no-await-in-loop */
    } else if (texture) {
      await handleTexture(cell, texture, restOption)
    } else {
      cell.set({
        ...(restOption || {})
      })
    }

    callback && callback(cell, canvasPage.current)

    canvasPage.current?.renderAll()
  }

  // 插入一个元素前需要进行的处理
  // function beforeInsert({ parentKey, key, dialType }: any) {
  //   // 判断已经存在不需要重复插入
  //   // 判断 parentKey 如果 parentKey 不为空 且不存在则需要创建一个 type 为 group 的 元素
  //   if (isExist(key)) return

  //   const parent = getObject(dialType, `${dialType}-${parentKey}`)

  //   if (!parent) {
  //     createParentLayer(parentKey)
  //   }
  // }

  // 插入一个元素后需要进行的处理
  function handleInsert({ parentKey, key, object, dialType, options }: any) {
    if (!object) return

    const isGroup = options.type === 'group'

    let targetObject = object

    if (isGroup && Array.isArray(object)) {
      const { objects, ...groupOption } = options
      const obj = getObject(dialType, key)

      if (obj) {
        canvasPage.current?.remove(obj)
      }

      targetObject = new fabric.Group(object, { ...(groupOption || {}), hasControls })
    }

    targetObject.set({ key })

    if (!isGroup && Array.isArray(targetObject)) {
      canvasPage.current?.add(...targetObject)
    } else {
      canvasPage.current?.add(targetObject)
    }

    // 将当前元素存储到 layerCells 上
    varToRecord.current.layerCells[dialType][key] = targetObject

    const m = menu.find((m: any) => m.get('key') === parentKey)

    // json2.0 init widget visible
    if (isWidgetFabricKey(key)) {
      const m = menu.find((m: any) => key.includes(m.get('key')))
      if (m) {
        const hide = m.get('hide')
        setCellVisible(key, !hide, dialType)
      }
    }
    if (m) {
      const hide = m.get('hide')
      setCellVisible(key, !hide, dialType)
    }

    debounceOrderObjectsByMenu(menu)
  }

  // 修改 config 表盘配置数据
  function handleChangeConfig(path: string[], data: any) {
    const { changeDesignerWithAutoSave, dialType } = props
    changeDesignerWithAutoSave([{ path: [dialType, 'config', ...path], data }], AUTO_SAVE_INTERVAL)
  }

  // 修改 assets 数据 asset 用来存放后端不需要传给固件而前端展示逻辑需要的信息
  function handleChangeAssets(path: string[], data: any) {
    const { changeDesignerWithAutoSave } = props
    changeDesignerWithAutoSave([{ path: ['asset', ...path], data }], AUTO_SAVE_INTERVAL)
  }

  // 移除元素
  function handleRemoveCell(layerKey: string) {
    const { dialType } = props
    const cell = varToRecord.current.layerCells[dialType][layerKey]
    // // workaround: 删除组件时避免影响可编辑组件中的同类组件
    // const parentKey = layerKey.split('-')[1] || ''
    // const parent = getObject(dialType, parentKey)
    // console.log(cell, varToRecord.current.layerCells[dialType])

    // if (Array.isArray(parent)) {
    //   const index = parent.indexOf(layerKey)
    //   index > -1 && parent.splice(index, 1)
    // }

    if (!cell) return

    delete varToRecord.current.layerCells[dialType][layerKey]
    canvasPage.current?.remove(cell)
    canvasPage.current?.renderAll()
  }

  // 清理模块删除时模块下所有图片记录在 pic_config 中的 key
  function refreshPicConfig(moduleData = {}) {
    try {
      const { allData, changeDesigner } = props
      const series = allData.getIn(['support', 'series']) as string
      const enableSelectImageType = (constants as any)[series]?.enableSelectImageType

      if (!enableSelectImageType) return

      let newData = allData
      const moduleDataStr = JSON.stringify(moduleData)
      const delKeys = moduleDataStr.match(/https?:\/\/.*?\/\d+\/.*?\.png/g)

      if (delKeys) {
        delKeys.forEach((delKey) => {
          newData = newData.deleteIn(['pic_config', delKey])
        })

        changeDesigner([
          {
            path: [],
            data: newData
          }
        ])
      }
    } catch (e) {
      console.log('refreshPicConfig', e)
    }
  }

  // 根据当前 layer 渲染组件
  function renderFormat() {
    const { current, systemFonts } = state
    const { menu, config, asset, watchConfig, support, dialType, metas } = props
    const [parentKey, childKey] = current.toJS()
    const [childMenuKey, subChildMenuKey] = (childKey || '').split('-')
    const { series } = immutableSelector(support)
    let data: any = Immutable.Map()
    let assets: any = Immutable.Map()
    const namePath = []
    // TODO: 代码优化
    // current.size > 1 说明当前选中的是二级结构的菜单
    if (current.size > 1) {
      const currentMenus = menu.find((item: any) => item.get('key') === parentKey) || Immutable.Map()
      const childrenMenu = currentMenus.get('children') || Immutable.Map()
      const childMenu = childrenMenu.find((item: any) => item.get('key') === childMenuKey)
      const parentName = currentMenus.get('name')

      parentName && namePath.push(getI18nMessage(parentName))

      if (childMenu && !isWidgetMenuKey(parentKey)) {
        const checked = childMenu.get('checked')
        const childName = childMenu.get('name')

        childName && namePath.push(getI18nMessage(childName))

        if (!checked) return null

        const subChildrenMenu = childMenu.get('children') || Immutable.Map()
        const subChildMenu = subChildrenMenu.find((item: any) => item.get('key') === subChildMenuKey)

        if (subChildMenu) {
          const checked = subChildMenu.get('checked')
          const subChildName = subChildMenu.get('name')

          subChildName && namePath.push(subChildName)

          if (!checked) return null
        }
      }

      data = config.getIn([current.get(0), 'children', childKey]) || Immutable.Map()
      assets = asset.getIn([dialType, current.get(0), 'children', childKey]) || Immutable.Map()
    } else {
      data = config.get(current.get(0)) || Immutable.Map()
      assets = asset.getIn([dialType, current.get(0)]) || Immutable.Map()
    }

    // 获得 type 对应的组件进行渲染
    let type = parentKey.replace(/_\d+/, '')
    let key = dialType

    // json2.0 handle type and key for new widget
    if (isWidgetMenuKey(parentKey)) {
      if (!childKey) {
        type = getKeyOrIdFromStr(parentKey).key
        key = parentKey
      } else {
        type = getKeyOrIdFromStr(childKey).key
        key = childKey
      }
    }

    const editableComponentKey = type === 'component' ? parentKey : ''

    if (varToRecord?.current?.componentMap[type]?.isHidden) return null

    const { instance: componentInstance, propMap = {}, constMap = Immutable.Map() } = varToRecord.current.componentMap[type] || {}

    if (!componentInstance) return

    return cloneElement(componentInstance, {
      key,
      propMap,
      constMap,
      data,
      allData: config,
      assets,
      allAssets: asset,
      dialType,
      series,
      // 组件的唯一前缀 用来区分相同组件在多个模块下使用
      keyPrefix: `${dialType}-${parentKey}`,
      childKey,
      parentKey,
      editableComponentKey,
      namePath: namePath.join('-'),
      watchConfig,
      support,
      systemFonts,
      menu,
      metas,
      CONFIG_MAP: (CONFIG_MAP as any)[series],
      isExist,
      onRemove: handleRemoveCell,
      onChange: handleChangeConfig,
      onAssetsChange: handleChangeAssets,
      onInsertImage: handleInsertImage,
      onInsertVertex: handleInsertVertex,
      onUpdateAttr: handleUpdateAttr
      // onDrawPolygonByPoints: drawPolygonByPoints
    })
  }

  const handleUndoOrRedo = (redoOrUndoFunc: () => void) => () => {
    redoOrUndoFunc()
    // 对于非受控界面数据重置后重新渲染
    varToRecord.current.shouldUpdateCellVisibleByMenu = true
    varToRecord.current.shouldReRenderLayer = true
  }

  return (
    <DesignContext.Provider
      value={{
        getI18nMessage
      }}
    >
      <div className="design-tool">
        <Prompt when={saveStatus === 'saving'} message="Changes you made may not be saved. Please save changes manually." />
        <div className="top" key={Date.now()}>
          {renderLayerBar()}
        </div>
        <div className="main">
          {renderTreePanel()}
          <div className="container">
            <div className="container-t">
              {success ? (
                <WatchInfo
                  asset={asset}
                  metas={metas}
                  support={support}
                  watchConfig={watchConfig}
                  loading={loading}
                  handleChangeAssets={handleChangeAssets}
                />
              ) : null}
              <OperationMenu
                allData={allData}
                metas={metas}
                asset={asset}
                support={support}
                dialType={dialType}
                intl={intl}
                canUndo={canUndo}
                canRedo={canRedo}
                changeAsset={changeAsset}
                saveDesigner={saveDesigner}
                changeDesigner={changeDesigner}
                undo={handleUndoOrRedo(undo)}
                redo={handleUndoOrRedo(redo)}
                handleInitRender={handleInitRender}
                startBackendExport={startBackendExport}
                currentConfig={varToRecord.current.currentConfig}
              />
            </div>
            <div className="container-m" />
            <div className="container-b">
              <div className="dial-upload-tip">
                <pre>
                  <T
                    id="upload_mail_tip"
                    values={{
                      mail: (
                        <a href={`mailto:${getI18nMessage('dial_admin_mail')}`}>
                          <T id="dial_admin_mail" />
                        </a>
                      )
                    }}
                  />
                </pre>
              </div>
            </div>
          </div>
          <div className="control-panel">{current && current.size ? renderFormat() : null}</div>
        </div>
        <StatusBar
          className="bottom-tool-bar"
          support={support}
          gridOptions={{
            gridW,
            gridH,
            showGrid,
            onGridWChange: setGridW,
            onGridHChange: setGridH,
            toggleShowGrid: () => {
              setShowGrid(!showGrid)
            }
          }}
          activeObject={canvasPage.current?.getActiveObject()}
        />
      </div>
    </DesignContext.Provider>
  )
}

const mapStateToProps = (state: any) => {
  const { watchFace = Immutable.Map() } = immutableSelector(state)
  const { exportModule = Immutable.Map(), devices = Immutable.Map() } = immutableSelector(watchFace)
  const { supportDevices = Immutable.List() } = immutableSelector(devices)
  const { exportResult = Immutable.Map() } = immutableSelector(exportModule)
  const { designer = Immutable.Map() } = immutableSelector(watchFace)
  const { data = Immutable.Map(), loading, success, dialType, saveStatus } = immutableSelector(designer.present)
  const { support = Immutable.Map(), metas = Immutable.Map(), asset = Immutable.Map() } = immutableSelector(data)
  const { screen_resolution = '' } = immutableSelector(support)
  const [screenWidth, screenHeight] = screen_resolution.split('*')
  const { config = Immutable.Map(), menu = Immutable.Map() } = immutableSelector(data.get(dialType) || Immutable.Map())
  let newAsset = asset

  if (dialType === 'idle') {
    // 息屏表盘已上传切图中移除 秒 相关切图组
    const idleImagesPath = ['uploadedImages', 'idle']
    const idleUploadedImages = asset.getIn(idleImagesPath) || Immutable.Map()
    const newIdleUploadedImages = idleUploadedImages.filter((v: any, k: string) => k.indexOf('-second') === -1)

    newAsset = newAsset.setIn(idleImagesPath, newIdleUploadedImages)
  }

  return {
    allData: data,
    metas,
    menu,
    canUndo: !!designer?.past?.length,
    canRedo: !!designer?.future?.length,
    config,
    asset: newAsset,
    support,
    supportDevices,
    watchConfig: Immutable.fromJS({
      screenWidth: Number.parseInt(screenWidth, 10),
      screenHeight: Number.parseInt(screenHeight, 10)
    }),
    dialType,
    loading,
    success,
    saveStatus,
    exportResult
  }
}

// 批处理极短时间连续更改 state 数据
const batchDesignerChangedData = (func: any, batchDelay: number) => {
  let timer: any = null
  let batchData: any[] = []

  return (data: any, ...args: any) => {
    batchData = [...batchData, ...data]

    timer && clearTimeout(timer)

    timer = setTimeout(
      (crtBatchData: any) => {
        const map = new Map()
        const uniqBatchData: any[] = []

        crtBatchData.forEach((item: any) => {
          const path = item?.path?.toString()

          if (map.has(path)) {
            const index = uniqBatchData.findIndex((item) => item?.path?.toString() === path)
            index > -1 && uniqBatchData.splice(index, 1)
          }

          uniqBatchData.push(item)

          map.set(path, true)
        })

        if (uniqBatchData.length > 0) {
          func(uniqBatchData, ...args, () => {
            batchData = []
          })
        }
        timer = null
      },
      batchDelay,
      [...batchData]
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    undo: () => {
      dispatch(ActionCreators.undo())
    },
    redo: () => {
      dispatch(ActionCreators.redo())
    },
    clearActionHistory: () => {
      dispatch(ActionCreators.clearHistory())
    },
    fetchDesigner: (data: any) => {
      dispatch(fetchDesigner(data))
    },
    startBackendExport: (dialId: any, dialType: any, no_simulator: boolean, app_name: string, pollInterval: number, resolve: any, reject: any) =>
      dispatch(
        startExport({
          dialId,
          dialType,
          no_simulator,
          app_name,
          pollInterval,
          resolve,
          reject
        })
      ),
    changeDesigner: batchDesignerChangedData((data: any, cb: any) => {
      dispatch(changeDesigner(data))
      cb && cb()
    }, 50),
    changeAsset: (data: any) => {
      dispatch(changeAsset(data))
    },
    changeDesignerWithAutoSave: batchDesignerChangedData((batchData: any, delayTime: any, cb: any) => {
      dispatch(changeDesignerWithAutoSave(batchData, delayTime))
      cb && cb()
    }, 50),
    saveDesigner: (data: any) => {
      dispatch(saveDesigner(data))
    },
    fetchSupportDevices: () => dispatch(fetchSupportDevices()),
    startExport: (dialId: any, dialType: any, no_simulator: boolean, app_name: string, pollInterval: number, resolve: any, reject: any) =>
      dispatch(
        startExport({
          dialId,
          dialType,
          no_simulator,
          app_name,
          pollInterval,
          resolve,
          reject
        })
      ),
    stopExport: () => dispatch(stopExport()),
    startZoom: (dialId: any, supportIds: string[]) =>
      dispatch(
        startZoom({
          dialId,
          supportIds
        })
      ),
    stopZoom: () => dispatch(stopZoom()),
    stopAutoSave: () => dispatch(stopAutoSave()),
    changeDialType: (dialType: any) => dispatch(changeDialType(dialType))
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WatchFace as any))
