import React, { PureComponent } from 'react'
import Classnames from 'classnames'
import { Input } from 'antd'
import { ChromePicker } from 'react-color'
import reactCSS from 'reactcss'

import './index.scss'

const noop = () => {} // eslint-disable-line

class ColorPicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  handleColorChange = (value) => {
    const { onChangeComplete } = this.props
    onChangeComplete(value)
  }

  handleClick = () => {
    const { visible } = this.state

    this.setState({
      visible: !visible
    })
  }

  handleClose = () => {
    this.setState({
      visible: false
    })
  }

  handleOnInputChange = (e) => {
    const { onInputChange } = this.props
    onInputChange(e.target.value)
  }

  render() {
    const { visible } = this.state
    const { color, className, width } = this.props
    const cls = Classnames([className, 'color-pick-container'])
    const styles = reactCSS({
      default: {
        color: {
          width: `36px`,
          height: '36px',
          borderRadius: '2px',
          background: color
        },
        swatch: {
          borderRadius: '1px',
          border: '1px solid #c0c0c0',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    })
    return (
      <div className={cls}>
        <Input value={color} maxLength={width} onChange={this.handleOnInputChange} />
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {visible ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <ChromePicker color={color} disableAlpha onChangeComplete={this.handleColorChange} />
          </div>
        ) : null}
      </div>
    )
  }
}

ColorPicker.defaultProps = {
  onChangeComplete: noop,
  onInputChange: noop,
  width: 36,
  color: '',
  className: ''
}

export default ColorPicker
