/* eslint-disable no-unused-vars */
import { Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { RADIO_STYLE } from '@watchface/constants/style'
import { Button, Col, Divider, Radio, Row, Tabs } from 'antd'
import * as React from 'react'

const { TabPane } = Tabs

interface imageItem {
  lang: 'zh' | 'zh-Hant' | 'en' | 'all'
  value: string
  useExistedImage: boolean
}

interface Props {
  screenWidth: number
  screenHeight: number
  properties: {
    image: { value: imageItem[]; limit: number }
  }
  onChange: (category: string, property: string, value: any) => void
}

const langMap = {
  zh: <T id="lang_zh" />,
  'zh-Hant': <T id="lang_zh-hant" />,
  en: <T id="lang_en" />,
  all: <T id="lang_all" />
}

const ImageGroup: React.FC<Props> = (props) => {
  const {
    screenWidth,
    screenHeight,
    properties: {
      image: { value: imageGroup = [], limit }
    },
    onChange
  } = props

  const handleImgSourceFrom = (e: any, index: number) => {
    const useExistedImage = e?.target?.value
    const newImageGroup = imageGroup.map((imageItem, imageIndex) => {
      if (imageIndex === index) {
        return { ...imageItem, useExistedImage }
      }
      return imageItem
    })
    onChange('imageGroup', 'image', newImageGroup)
  }

  const handleCropImageNameChange = (name: string) => {
    console.log('handleCropImageNameChange', name)
  }

  const handlePunchChangeImage = (updateUploadedImages = true, image: any, index: number, lang: imageItem['lang']) => {
    const newImageGroup = imageGroup.map((imageItem, imageIndex) => {
      if (imageIndex === index) {
        return { ...imageItem, value: image }
      }
      return imageItem
    })
    onChange('imageGroup', 'image', newImageGroup)
  }

  const renderRemoveAllButton = (index: number, lang: imageItem['lang']) => (
    <Button className="upload-btn" onClick={() => handlePunchChangeImage(false, [], index, lang)}>
      Remove All
    </Button>
  )

  const renderUpload = (value: string, index: number, useExistedImage: boolean, lang: imageItem['lang']) => (
    <Row gutter={[0, 24]} justify="space-around" align="middle">
      <Col span={24}>
        <Radio.Group
          value={useExistedImage}
          onChange={(e) => {
            handleImgSourceFrom(e, index)
          }}
        >
          <Radio style={RADIO_STYLE} value>
            <T id="use_uplod_img" />
          </Radio>
          <Radio style={RADIO_STYLE} value={false}>
            <T id="use_new_img" />
          </Radio>
        </Radio.Group>
      </Col>
      <Col span={24}>
        <Upload
          key="new"
          multiple
          fileCountLimit={limit}
          maxWidth={screenWidth}
          maxHeight={screenHeight}
          fileList={value || []}
          field="all"
          onChange={(imgList: any) => handlePunchChangeImage(true, imgList, index, lang)}
          defineCropImageName
          sameImagesSize
          cropImageName="image group"
          showRemoveIcon
          handleCropImageNameChange={handleCropImageNameChange}
          removeAllButton={renderRemoveAllButton(index, lang)}
        />
      </Col>
    </Row>
  )

  return (
    <div className="meta-form meta-imageGroup">
      {imageGroup.length === 1 ? (
        renderUpload(imageGroup[0]?.value, 0, imageGroup[0]?.useExistedImage, imageGroup[0]?.lang)
      ) : (
        <Tabs defaultActiveKey={imageGroup[0].lang} centered>
          {imageGroup.map(({ lang, useExistedImage, value }, index) => (
            <TabPane tab={langMap[lang]} key={lang}>
              {renderUpload(value, index, useExistedImage, lang)}
            </TabPane>
          ))}
        </Tabs>
      )}
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default ImageGroup
