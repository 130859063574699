interface EventParams {
  os_type?: string
  status?: 'start' | 'success' | 'error' | 'cancel'
  time_spend?: number
  action?: 'install'
  watch_face_id?: number
}

class Analytics {
  static instance: Analytics

  dialId: string | number = ''

  static singleton() {
    if (this.instance) {
      return this.instance
    }
    this.instance = new Analytics()

    return this.instance
  }

  // PV = () => {
  //   window.HM_Analytics?.htag?.('event', 'page_view', {
  //     event_name: '_trackPageview'
  //   })

  //   return this
  // }

  // FMP = () => {
  //   window.HM_Analytics?.htag?.('event', 'firstMeaningPaint', {
  //     event_name: '_trackFMP'
  //   })
  //   return this
  // }

  // reportException = (err: Error, fatal = false) => {
  //   window.HM_Analytics?.htag?.('event', 'exception', {
  //     event_name: '_trackErr',
  //     description: err.message,
  //     fatal,
  //     error_stack: err.stack,
  //     error_message: err.message,
  //     error_name: err.name
  //   })
  //   return this
  // }

  // report = (action: 'click' | 'exposure' | 'time', event_label: string, event_category: string, pathString?: string) => {
  //   window.HM_Analytics?.htag?.('event', action, {
  //     event_name: '_trackEvent',
  //     event_category,
  //     event_label,
  //     options: {
  //       opt_value: 1,
  //       id: this.dialId || '',
  //       pos: pathString || ''
  //     }
  //   })
  //   return this
  // }

  setDialId = (dialId: string | number) => {
    this.dialId = dialId

    return this
  }

  gtagEvent = (eventName: string, eventLabel: string, params: EventParams = {}, debugMode = false) => {
    const newParams: any = {
      debug_mode: process.env.NODE_ENV === 'production' ? false : debugMode,
      label: eventLabel,
    }

    if (this.dialId) {
      newParams.watch_face_id = this.dialId
    }

    window.gtag?.('event', eventName, { ...newParams, ...(params || {}) })
  }
}

export default Analytics.singleton()
