/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE } from '@watchface/constants'
import { getRotatedPoint, isHitKey } from '@watchface/utils'
import { renderCells } from '@watchface/utils/render'
import Immutable from 'immutable'
import { compact } from 'lodash'
import PubSub from 'pubsub-js'
import React, { PureComponent } from 'react'

const noop = () => {} // eslint-disable-line
class AnalogClockComponent extends PureComponent {
  static keyMap = {}

  componentDidMount() {
    const { parentKey, editableComponentKey = '' } = this.props

    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      const { data, childKey, assets, dialType } = this.props

      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      const { center_position = Immutable.Map(), pointer = Immutable.Map() } = immutableSelector(data)
      const { x: centerX, y: centerY } = immutableSelector(center_position)
      const { position: pointerPosition } = immutableSelector(pointer)
      const { x: pointerX, y: pointerY } = immutableSelector(pointerPosition)
      const rotationValue = assets.get('value') || 0
      const rotate = AnalogClockComponent.getAnalogClockRotation(childKey, rotationValue)
      const rotatedPoint = getRotatedPoint({ x: centerX - pointerX, y: centerY - pointerY }, { x: centerX, y: centerY }, rotate)
      const distX = left - Math.round(rotatedPoint.x)
      const distY = top - Math.round(rotatedPoint.y)
      const newCenterX = centerX + distX
      const newCenterY = centerY + distY

      this.handleChange(['center_position'], '', false, {
        x: newCenterX,
        y: newCenterY
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      AnalogClockComponent.renderLayer(this.props)
    }
  }

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: AnalogClockComponent,
      ...param
    })
    AnalogClockComponent.keyMap = Object.assign(AnalogClockComponent.keyMap, keyMap)
  }

  static getCellDatas = ({ parentKey, childKey, constMap, data, assets, renderLayerByLang }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }

    const pointerImage = AnalogClockComponent.getPointerImage({ constMap, parentKey, childKey, data, assets, renderLayerByLang })
    if (pointerImage) {
      cells.imageEntity[pointerImage.key] = pointerImage
    }

    const coverImage = AnalogClockComponent.getCoverImage({ parentKey, childKey, data })
    if (coverImage) {
      cells.imageEntity[coverImage.key] = coverImage
    }

    const rotationCenter = AnalogClockComponent.getRotationCenter({ childKey, data })
    if (rotationCenter) {
      cells.vertexEntity[rotationCenter.key] = rotationCenter
    }
    return cells
  }

  static getPointerImage = ({ constMap, data, childKey, assets, renderLayerByLang }) => {
    const { x = 0, y = 0 } = immutableSelector(data.get('center_position'))
    const { pointer = Immutable.Map() } = immutableSelector(data)
    const { position: pointerPosition } = immutableSelector(pointer)
    const { x: pointerX = 0, y: pointerY = 0 } = immutableSelector(pointerPosition)
    const { image } = immutableSelector(data.get('pointer'))
    let { value: rotationValue = 0 } = immutableSelector(assets)
    const key = 'pointer'

    if (renderLayerByLang) {
      rotationValue = constMap.getIn([childKey, 'recommendPreviewValue']) || rotationValue
    }

    if (image) {
      const newX = x - pointerX
      const newY = y - pointerY
      const rotate = AnalogClockComponent.getAnalogClockRotation(childKey, rotationValue)
      const rotatedPoint = getRotatedPoint({ x: newX, y: newY }, { x, y }, rotate)
      return {
        left: rotatedPoint.x,
        top: rotatedPoint.y,
        key,
        zIndex: AnalogClockComponent.getImgZIndex(childKey, key),
        texture: image,
        angle: rotate
      }
    }
    return null
  }

  static getCoverImage = ({ data, childKey }) => {
    const { image, position = Immutable.Map() } = immutableSelector(data.get('cover'))
    const { x = 0, y = 0 } = immutableSelector(position)
    const key = 'cover'
    if (image) {
      return {
        left: x,
        top: y,
        key,
        zIndex: AnalogClockComponent.getImgZIndex(childKey, key),
        texture: image
      }
    }
    return null
  }

  static getRotationCenter = ({ childKey, data }) => {
    const { x: left = 0, y: top = 0 } = immutableSelector(data.get('center_position'))
    const key = 'rotationCenter'
    return {
      key,
      type: 'circle',
      visible: false,
      radius: 1,
      fill: '#1977e2',
      left: left - 1,
      top: top - 1,
      selectable: false,
      zIndex: AnalogClockComponent.getImgZIndex(childKey, key)
    }
  }

  static getAnalogClockRotation = (key, value) => {
    if (key === 'hour') {
      return (value * 360) / 12
    }
    return (value * 360) / 60
  }

  static getImgZIndex = (childKey, key) => {
    const zIndexMap = {
      pointer: -1,
      cover: 0,
      rotationCenter: 21 // 保持旋转中心 zIndex 比 cover 高即可
    }

    const clockPointerZIndex = {
      hour: 0,
      minute: 10,
      second: 20
    }
    const layerZIndex = clockPointerZIndex[childKey] || 0
    const zIndex = layerZIndex + (zIndexMap[key] || 0)
    return zIndex
  }

  handleInsertImage = (path, img) => {
    const { parentKey, childKey, data, onChange = noop } = this.props
    let newData = data
    if (img && img.image) {
      newData = data.setIn(path, img.image)
    } else {
      newData = data.setIn(path, '')
    }
    onChange([parentKey, 'children', childKey], newData)
  }

  handleChange = (path, prop, isAssetsValue, value) => {
    const { parentKey, childKey, dialType, data, onChange, onAssetsChange, assets } = this.props
    let newData = data
    if (prop === 'transformOriginX' || prop === 'transformOriginY') {
      if (prop === 'transformOriginX') {
        newData = data.setIn([...path, 'x'], value)
      } else {
        newData = data.setIn([...path, 'y'], value)
      }
    } else {
      newData = data.setIn(compact([...path, prop]), value)
    }
    let newAssets = assets
    if (isAssetsValue) {
      newAssets = newAssets.set(prop, value)
    }

    if (isAssetsValue) {
      onAssetsChange([dialType, parentKey, 'children', childKey], newAssets)
    } else {
      onChange([parentKey, 'children', childKey], newData)
    }
  }

  render() {
    const { data, childKey, assets, watchConfig, constMap } = this.props
    const name = constMap.getIn([childKey, 'name']) || ''
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { center_position = Immutable.Map(), cover = Immutable.Map(), pointer = Immutable.Map() } = immutableSelector(data)
    const { x: centerX, y: centerY } = immutableSelector(center_position)
    const { position: coverPosition = Immutable.Map(), image: imageCover } = immutableSelector(cover)
    const { x: coverX, y: coverY } = immutableSelector(coverPosition)
    const { position: pointerPosition, image: pointerImage } = immutableSelector(pointer)
    const { x: pointerX, y: pointerY } = immutableSelector(pointerPosition)
    const value = assets.get('value') || 0

    return (
      <>
        <div className="name">
          <T id="watch_face_center" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={centerX}
                onChange={(value) => {
                  this.handleChange(['center_position'], 'x', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={centerY}
                onChange={(value) => {
                  this.handleChange(['center_position'], 'y', false, value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="pointer_rotation_center" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                value={pointerX}
                onChange={(value) => {
                  this.handleChange(['pointer', 'position'], 'transformOriginX', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                value={pointerY}
                onChange={(value) => {
                  this.handleChange(['pointer', 'position'], 'transformOriginY', false, value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="center_img_pos" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={coverX}
                onChange={(value) => {
                  this.handleChange(['cover', 'position'], 'x', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={coverY}
                onChange={(value) => {
                  this.handleChange(['cover', 'position'], 'y', false, value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="add_image" />（<T id="pointer_direction_tip" />）
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                key={childKey}
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={pointerImage ? [{ image: pointerImage }] : []}
                onChange={(...arg) => {
                  this.handleInsertImage(['pointer', 'image'], ...arg)
                }}
                field="pointer"
              />
              <div className="is-required">
                <T id={name} />
              </div>
            </div>
            <div className="col col-v">
              <Upload
                key={childKey}
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={imageCover ? [{ image: imageCover }] : []}
                onChange={(...arg) => {
                  this.handleInsertImage(['cover', 'image'], ...arg)
                }}
                field="cover"
              />
              <div>
                <T id="center_img" />
              </div>
            </div>
          </div>
        </div>
        <div className="name">
          <T id="preview_with_name" values={{ name: <T id={name} /> }} />
        </div>
        <div className="content">
          <div className="item">
            <InputNumber
              max={childKey === 'hour' ? 12 : 60}
              min={0}
              value={value}
              onChange={(value) => {
                this.handleChange(['pointer'], 'value', true, value)
              }}
            />
          </div>
        </div>
      </>
    )
  }
}

export default AnalogClockComponent
