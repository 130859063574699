/* eslint-disable */
/* eslint-disable no-use-before-define */
import T from '@watchface/components/I18n'
import { PartitionOutlined } from '@ant-design/icons'
import startExportZip from '@watchface/components/ExportZip'
import { MENU_ICON_COLOR, SERIES } from '@watchface/constants'
import { downloadDialZip, getBridgeWebSocketUrl } from '@watchface/request/api'
import { analytics, getCname, getConfigJson } from '@watchface/utils'
import { Button, message, Modal, notification } from 'antd'
import classNames from 'classnames'
import { saveAs } from 'file-saver'
import React, { useEffect, useRef, useState } from 'react'
import BridgePage, { actionMap, Client } from './BridgePage'
import './index.scss'

enum WSCloseType {
  NoAction,
  Error,
  PageClose
}

interface Props {
  saveDesigner: (...args: any) => void
  checkValue: () => boolean
  checkPreviewImgIsReady: (cb: any) => void
}

const BridgeButton: React.FunctionComponent<Props> = (props) => {
  const { saveDesigner, checkValue, checkPreviewImgIsReady } = props
  const [showBridgePage, setShowBridgePage] = useState<boolean>(false)
  const isConnectedWS = useRef<boolean>(false)
  const messageId = useRef<number>(0)
  const noActionCount = useRef<number>(0)
  const wsCloseType = useRef<number>(0)
  const ws = useRef<WebSocket>()
  const eventCollectors = useRef<{ [key: number]: (data: any) => void }>({})
  const [clients, setClients] = useState<Client[]>([])
  const [action, setAction] = useState<string>(actionMap.INSTALL_PKG)
  const [loading, setLoading] = useState<boolean>(false)
  const [connectedClient, setConnectedClient] = useState<Client | undefined>()
  // 15 分钟无信息传输自动关闭(服务端半分钟 ping 一次)
  const NO_ACTION_MAX_COUNT = 30

  function connectedClientsChange(clients: Client[]) {
    if (!clients.some((client: Client) => client.clientId === connectedClient?.clientId)) {
      setConnectedClient(undefined)
    }
    setClients(clients)
  }

  function sendMessage(msg: any) {
    return new Promise((resolve, reject) => {
      if (msg === 'pong') {
        ws.current?.send(msg)
        resolve({})
        return
      }

      const { id } = msg

      ws.current?.send(JSON.stringify(msg))
      noActionCount.current = 0

      if (id) {
        eventCollectors.current[id] = (data: any) => {
          const { result, error } = data

          if (result) {
            resolve(result)
          }
          if (error) {
            reject(error)
          }
          delete eventCollectors.current[id]
        }
      } else {
        resolve({})
      }
    })
  }

  async function connectWS() {
    const host = getCname('api-mifit')
    const { url } = await getBridgeWebSocketUrl(host)

    if (!url) {
      message?.error('connect websocket error')
      return
    }

    const socketUrl = `${url}?type=development&name=Watch Face Maker`

    ws.current = new WebSocket(socketUrl)
  }

  function closeWS(type: WSCloseType) {
    if (!isConnectedWS.current) return

    wsCloseType.current = type
    ws.current?.close()
    isConnectedWS.current = false
    noActionCount.current = 0
  }

  function reconnect() {
    if (isConnectedWS.current) return
    connectWS().then(socketListeners)
  }

  function socketListeners() {
    if (ws.current) {
      Object.assign(ws.current, {
        onopen() {
          isConnectedWS.current = true
        },
        onclose() {
          setConnectedClient(undefined)

          if (wsCloseType.current === WSCloseType.PageClose) return
          if (wsCloseType.current === WSCloseType.NoAction) {
            setShowBridgePage(false)
            notification.warning({
              key: 'bridge-disconnected',
              message: 'Bridge disconnected',
              description: 'Please click "Bridge" button to reconnect'
            })
            return
          }

          reconnect()
        },
        onerror(evt: any) {
          console.log('onerror', evt)
          reconnect()
        },
        onmessage(evt: any) {
          const { data } = evt

          if (data === 'ping') {
            noActionCount.current += 1

            if (noActionCount.current === NO_ACTION_MAX_COUNT) {
              // 用户无操作主动断开
              closeWS(WSCloseType.NoAction)
              return
            }
            sendMessage('pong')
            return
          }

          const dataObject = JSON.parse(data || {})
          const { method, params, id: messageId } = dataObject

          if (eventCollectors.current[messageId]) {
            eventCollectors.current[messageId](dataObject)
          }

          if (!params) return

          switch (method) {
            // 客户端变更推送
            case 'sysEventPush':
              if (params.event === 'clientChange') {
                connectedClientsChange(params.clients)
              }
              break
            // 日志推送
            case 'logPush': {
              const { level, time, message: msg } = params
              const str = `[${level?.toUpperCase() || 'DEBUG'} - ${new Date(Number(time)).toLocaleTimeString()}] ${msg}`
              console.log(str)
              break
            }
            // 系统消息推送
            case 'sysMessagePush': {
              const { title, content } = params

              if (title !== 'screenshot') {
                notification.info({
                  key: 'sysMessagePush',
                  message: 'System Info',
                  description: content
                })
              }
              break
            }
            default:
              console.log('[websocket message]nothing')
          }
        }
      })
    }
  }

  function connectClient(client: Client) {
    const param = {
      jsonrpc: '2.0',
      method: 'connectClient',
      params: {
        clientId: client.clientId
      },
      id: ++messageId.current // eslint-disable-line
    }

    sendMessage(param)
      .then(() => {
        setConnectedClient(client)
      })
      .catch((err) => {
        message?.error(err?.message)
      })
  }

  function bridgeButtonClick() {
    checkPreviewImgIsReady(() => {
      setShowBridgePage(true)

      if (!isConnectedWS.current) {
        connectWS().then(socketListeners)
      }
    })
  }

  function handleActionChange(e: any) {
    setAction(e.target.value)
  }

  function handleExecuteAction() {
    if (!connectedClient) {
      message.warning('Please connect a device or simulator')
      return
    }
    if (!checkValue?.()) return

    analytics.gtagEvent('bridge_connect', 'bridge 连接', {
      os_type: SERIES.JS,
      action: 'install',
    })

    switch (action) {
      case actionMap.INSTALL_PKG: {
        setLoading(true)

        saveDesigner?.({
          callback: async () => {
            const { config, support, appId, appVersion, deviceInfo } = getConfigJson()
            const meta = config?.metas?.multi_language?.[0] || {}
            const res = await startExportZip({ config, support, appId, appVersion, type: 'bridge', deviceInfo, png2vg: connectedClient.name !== 'Simulator' })
            const params = {
              debugger: true,
              appid: appId,
              appType: 'watchface',
              devices: support.device_source,
              name: meta.name,
              url: '',
              updatedTime: Math.floor(new Date().getTime() / 1000),
              preview: meta.image
            }
            if (res !== 'ok') {
              const { protocol, code } = res as any

              if (!protocol || !code) {
                message?.error('An error occurred, please try again')
                return
              }

              const { url } = (await downloadDialZip(code)) as any

              params.url = url
            }
            const jsonrpcMsg = {
              jsonrpc: '2.0',
              method: 'packagePush',
              params
            }
            setLoading(false)
            sendMessage(jsonrpcMsg)
            setShowBridgePage(false)
          }
        })
        break
      }
      case actionMap.SCREENSHOT: {
        setShowBridgePage(false)
        messageId.current += 1
        notification.info({
          key: 'bridge-screenshot',
          message: 'Waiting for a screenshot of the watch...',
          duration: null
        })
        const jsonrpcMsg = {
          jsonrpc: '2.0',
          method: 'sysSettingPush',
          params: {
            type: 'screenshot'
          },
          id: messageId.current
        }
        const screenshotPromise = sendMessage(jsonrpcMsg)

        Promise.race([
          screenshotPromise,
          new Promise((resolve) => {
            setTimeout(resolve, 60000, 'timeout')
          })
        ])
          .then((res: any) => {
            if (res === 'timeout') {
              notification.error({
                key: 'bridge-screenshot',
                message: 'Error taking screenshot',
                description: 'Screenshot timed out!'
              })
              return
            }

            if (!res) {
              notification.info({
                key: 'bridge-screenshot',
                message: 'An error occurred, please try again!'
              })
              return
            }

            const blob = new Blob([Buffer.from(res, 'hex')], {
              type: 'image/png'
            })

            saveAs(blob, 'watch-screenshot.png')
            notification.info({
              key: 'bridge-screenshot',
              message: 'Screenshot taken successfully'
            })
          })
          .catch((err) => {
            notification.error({
              key: 'bridge-screenshot',
              message: 'Error taking screenshot',
              description: err?.message
            })
          })

        break
      }
      default:
        console.log('default')
    }
  }

  useEffect(() => {
    return () => {
      // 断开连接
      closeWS(WSCloseType.PageClose)
    }
  }, [])

  return (
    <>
      <div className={classNames('menu-btn', 'menu-btn-save')} onClick={bridgeButtonClick}>
        <PartitionOutlined style={{ fontSize: '16px', color: MENU_ICON_COLOR }} />
        <span className="menu-text">Bridge</span>
      </div>
      <Modal
        wrapClassName="install-modal"
        className="watch-dial-modal"
        title="Bridge"
        width={500}
        closable={false}
        centered
        visible={showBridgePage}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowBridgePage(false)
              if (loading) {
                setLoading(false)
              }
            }}
          >
            <T id="cancel" />
          </Button>,
          <Button key="execute" type="primary" loading={loading} onClick={handleExecuteAction}>
            <T id="execute" />
          </Button>
        ]}
      >
        <BridgePage
          clients={clients}
          action={action}
          connectedClient={connectedClient}
          connectClient={connectClient}
          handleActionChange={handleActionChange}
        />
      </Modal>
    </>
  )
}

export default BridgeButton
