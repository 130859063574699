import Immutable from 'immutable'
import undoable, { combineFilters, includeAction } from 'redux-undo'
import {
  CHANGE_ASSET,
  CHANGE_DESIGNER_ACTION,
  CHANGE_DIAL_TYPE,
  CHANGE_MENU_NAME,
  FETCH_DESIGNER_ERROR,
  FETCH_DESIGNER_START,
  FETCH_DESIGNER_SUCCESS,
  SAVE_STATUS
} from '../actionTypes/index'

export const initData = Immutable.fromJS({
  id: -1,
  type: '',
  user_id: '',
  support: {},
  updated_at: '',
  normal: {
    config: {},
    menu: []
  },
  idle: {
    config: {},
    menu: []
  },
  asset: {
    uploadedImages: {
      normal: {},
      idle: {}
    },
    idle: {},
    normal: {}
  },
  pic_config: {},
  metas: {}
})

const initialState: any = Immutable.fromJS({
  data: initData,
  loading: false,
  success: true,
  saveStatus: 'saved', // saving | saved | error
  dialType: 'normal' // normal or idle
})

const reducer = (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case CHANGE_DESIGNER_ACTION: {
      let newState = state
      data.forEach((item: any) => {
        const { path, data: newData } = item
        newState = newState.setIn(['data', ...path], newData)
      })
      return newState
    }
    case CHANGE_ASSET: {
      const { key, value } = data
      const newState = state.setIn(['data', 'asset', key], value)
      return newState
    }
    case SAVE_STATUS: {
      const newState = state.set('saveStatus', data)
      return newState
    }
    case FETCH_DESIGNER_START: {
      return state.set('loading', true)
    }
    case FETCH_DESIGNER_SUCCESS: {
      return state.set('loading', false).set('success', true)
    }
    case FETCH_DESIGNER_ERROR: {
      return state.set('loading', false).set('success', false)
    }
    case CHANGE_DIAL_TYPE: {
      return state.set('dialType', data)
    }
    case CHANGE_MENU_NAME: {
      const { dialType, path, name } = data
      const menuPath = ['data', dialType, 'menu']
      if (path.length === 1) {
        const index = state.getIn(menuPath, []).findIndex((menu: any) => menu.get('key') === path[0])
        if (index > -1) {
          return state.setIn([...menuPath, index, 'name'], name)
        }
      }
      if (path.length === 2) {
        const index = state.getIn(menuPath, []).findIndex((menu: any) => menu.get('key') === path[0])
        const subIndex = state.getIn([...menuPath, index, 'children'], []).findIndex((menu: any) => menu.get('key') === path[1])
        if (index > -1 && subIndex > -1) {
          return state.setIn([...menuPath, index, 'children', subIndex, 'name'], name)
        }
      }
      return state
    }
    default: {
      return state
    }
  }
}

const isPro = process.env.NODE_ENV === 'production'

const filterMetaRecord = (action: any) => {
  const { data = [] } = action
  let record = true

  if (data?.length === 1) {
    const { path } = data[0]

    record = path.indexOf('metas') === -1
  }

  return record
}

export default undoable(reducer, {
  debug: !isPro,
  limit: 30, // 可回退 30 步操作
  ignoreInitialState: true,
  filter: combineFilters(includeAction([CHANGE_DESIGNER_ACTION, CHANGE_DIAL_TYPE]), filterMetaRecord)
})
