import Immutable from 'immutable'
import { ZOOM_STATUS_DOING, ZOOM_STATUS_FAILED, ZOOM_STATUS_SUCCESS } from '../../constants'
import { ZOOM_RESET_STATE, ZOOM_FAILED, ZOOM_START, ZOOM_SUCCESS } from '../actionTypes'

const initialState: any = Immutable.fromJS({
  zoomStatus: 0,
  errorMessage: '',
})

const reducer = (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case ZOOM_START:
      return state.set('zoomStatus', ZOOM_STATUS_DOING)
    case ZOOM_FAILED:
      return state.set('zoomStatus', ZOOM_STATUS_FAILED).set('errorMessage', data)
    case ZOOM_SUCCESS:
      return state.set('zoomStatus', ZOOM_STATUS_SUCCESS)
    case ZOOM_RESET_STATE:
    return initialState
    default:
      return state
  }
}

export default reducer
