import React from 'react'
import classNames from 'classnames'
import Immutable from 'immutable'
import { message, Tooltip } from 'antd'
import Icon from '@watchface/components/Icon'
import T from '@watchface/components/I18n'
import { immutableSelector } from '@/utils/index'
import { LAYER_ICON_COLOR, LAYER_ICON_HIGHLIGHT_COLOR, TOOLTIP_COLOR } from '../../constants'

import checkedImg from './checked.png'
import checkedCurrImg from './checked__cur.png'

const LayerItem = (props) => {
  const [msg, messageCtxHolder] = message.useMessage()
  const renderItem = ({
    layerItem,
    parentLayerItem,
    isParentCheck = false,
    isChildCheck = false,
    canClick = true,
    isSub,
    appendPath = [],
    prefix = ''
  }) => {
    const { current = Immutable.List(), layerIndex, layerItemIndex, layerName, layerKey, handleClick, handleCheckClick } = props

    const { name, key, icon, checked, fixedChecked } = immutableSelector(layerItem)
    const isCurrent = current.size === 2 && current.get(0) === layerKey && (prefix ? current.get(1) === `${prefix}-${key}` : current.get(1) === key)
    const className = classNames(['children-item'], {
      'children-item__current': isCurrent
    })
    const checkedPath = [layerIndex, 'children', layerItemIndex, ...appendPath, 'checked']
    const { checked: parentChecked, key: parentKey } = immutableSelector(parentLayerItem)
    return (
      <div
        className={className}
        key={key}
        style={isSub ? { paddingLeft: 55 } : {}}
        onClick={
          canClick && checked
            ? (...args) => {
                handleClick(prefix ? `${prefix}-${key}` : key, layerKey, layerName, name, ...args)
              }
            : () => ({})
        }
      >
        {checked ? (
          <Icon
            img={isCurrent ? checkedCurrImg : checkedImg}
            size={24}
            isStopPropagation
            option={checkedPath}
            onClick={(...args) => {
              if (isParentCheck) {
                if (checked) {
                  const checkedChild = layerItem.get('children').find((item) => {
                    return item.get('checked')
                  })
                  if (checkedChild) {
                    msg.warning(<T id="cancel_child_selected" />)
                    return
                  }
                }
              } else if (isChildCheck) {
                if (!checked) {
                  if (!parentChecked) {
                    msg.warning(<T id="first_selected_parent" />)
                    return
                  }
                }
              }
              handleCheckClick(parentKey ? `${layerKey}-${parentKey}` : layerKey, key, checked, fixedChecked, ...args)
            }}
          />
        ) : (
          <Icon
            type="icon-tuceng-buxuanzhong"
            size={24}
            isStopPropagation
            option={checkedPath}
            color={isCurrent ? LAYER_ICON_HIGHLIGHT_COLOR : LAYER_ICON_COLOR}
            onClick={(...args) => {
              if (isParentCheck) {
                if (checked) {
                  const checkedChild = layerItem.get('children').find((item) => {
                    return item.get('checked')
                  })
                  if (checkedChild) {
                    msg.warning(<T id="cancel_child_selected" />)
                    return
                  }
                }
              } else if (isChildCheck) {
                if (!checked) {
                  if (!parentChecked) {
                    msg.warning(<T id="first_selected_parent" />)
                    return
                  }
                }
              }
              handleCheckClick(parentKey ? `${layerKey}-${parentKey}` : layerKey, key, checked, fixedChecked, ...args)
            }}
          />
        )}
        <Icon type={icon} size={24} color={isCurrent ? LAYER_ICON_HIGHLIGHT_COLOR : LAYER_ICON_COLOR} />
        <div className="children-item-name">
          <Tooltip title={<T id={name} />} color={TOOLTIP_COLOR} mouseEnterDelay={1}>
            <span>
              <T id={name} />
            </span>
          </Tooltip>
        </div>
      </div>
    )
  }

  const renderItems = ({ layerItem }) => {
    const { key, children } = immutableSelector(layerItem)
    return (
      <div>
        {renderItem({ layerItem, canClick: false, isParentCheck: true })}
        <div>
          {children.toJS().map((child, index) => {
            return renderItem({
              layerItem: Immutable.Map(child),
              parentLayerItem: layerItem,
              isSub: true,
              isChildCheck: true,
              appendPath: ['children', index],
              prefix: key
            })
          })}
        </div>
      </div>
    )
  }

  const { layerItem = Immutable.Map() } = props
  const { children } = immutableSelector(layerItem)

  if (children && children.size) {
    return (
      <>
        {messageCtxHolder}
        {renderItems({ layerItem })}
      </>
    )
  }

  return (
    <>
      {messageCtxHolder}
      {renderItem({ layerItem })}
    </>
  )
}

export default LayerItem
