import React from 'react'
import { Button } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import T from '@watchface/components/I18n'

import './export.scss'

interface ExportFailProps {
  message: string
  isZoom: boolean
  onClose: (...args: any) => void
}

const ExportFail: React.FunctionComponent<ExportFailProps> = (props) => {
  const { onClose, message, isZoom } = props

  return (
    <div className="watch-dial-export-status">
      <ExclamationCircleFilled
        className="export-status-icon"
        style={{
          color: '#D8D8D8',
          fontSize: '80px'
        }}
      />
      <div className="title">
        {isZoom ? <T id="zoom_fail" /> : <T id="export_fail" />}
      </div>
      <div className="message">{message}</div>
      <Button type="primary" onClick={onClose}>
        <T id="confirm" />
      </Button>
    </div>
  )
}

export default ExportFail
