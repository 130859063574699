import React from 'react'
import Immutable from 'immutable'
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { zipRemoteFiles } from '@watchface/utils'
import { PIC_LANG_MAPPER as LANG_MAPPER, T } from '@watchface/components'

import './index.scss'
import { Tooltip } from 'antd'
import { TOOLTIP_COLOR } from '../../constants'

interface BackupOfLangImageProps {
  fontImages: any
  metas: any
}

const BackupOfLangImage: React.FC<BackupOfLangImageProps> = (props) => {
  const { fontImages = Immutable.List(), metas = Immutable.Map() } = props
  const watchfaceLang = metas.get('multi_language').reduce((pre: any[], item: any) => {
    pre.push(item.get('language'))
    return pre
  }, [])
  const fontImagesExceptWatchfaceLang = fontImages.toJS().filter((fontImage: any) => !watchfaceLang.includes(fontImage.language))

  if (!fontImagesExceptWatchfaceLang?.length) return null

  return (
    <div className="backup-lang-image">
      <div className="name">
        <T id="lang_image_backup" />
        <Tooltip title={<T id="lang_image_backup_tip" />} color={TOOLTIP_COLOR}>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      <div className="content">
        {fontImagesExceptWatchfaceLang.map((fontImage: any) => {
          const { language, images } = fontImage
          const urls = images.map((image: any) => image.url)
          const { label } = LANG_MAPPER[language]
          return (
            <div key={language} className="item" onClick={() => zipRemoteFiles(`backup_${language}`, urls)}>
              <div className="file">
                <DownloadOutlined />
                <span className="file-name">{label}.zip</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BackupOfLangImage
