import React, { PureComponent } from 'react'
import Classnames from 'classnames'
import T from '@watchface/components/I18n'
import { ProgressComponent } from '../../components'

const map = {
  hour: ProgressComponent,
  minute: ProgressComponent,
  second: ProgressComponent
}
class ProgressClock extends PureComponent {
  static renderLayer = ({ parentKey, childKey, ...other }) => {
    map[childKey].renderLayer({ parentKey, childKey, ...other })
  }

  static removeLayer = ({ childKey, ...restArgs }) => {
    Object.keys(map).forEach((key) => {
      const { removeLayer } = map[key]
      removeLayer && removeLayer({ childKey, ...restArgs })
    })
  }

  render() {
    const cls = Classnames(['date-container', 'control-container'])
    const { parentKey } = this.props
    return (
      <div className={cls}>
        <ProgressComponent parentKey={parentKey} {...this.props} title={<T id="progress_time" />} />
      </div>
    )
  }
}

export default ProgressClock
