export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.FILL_RECT, {
      x: {{ x }},
      y: {{ y }},
      w: {{ width }},
      h: {{ height }},
      {{ #color }}
      color: '{{{ color }}}',
      {{ /color }}
      {{ #radius }}
      radius: {{ radius }},
      {{ /radius }}
      {{ #angle }}
      angle: '{{ angle }}',
      {{ /angle }}
      show_level: {{ showLevel }},
    });
`
