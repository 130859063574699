import Immutable from 'immutable'
import React from 'react' // eslint-disable-line
import dayjs from 'dayjs'
import AnalogClock from '../../modules/AnalogClock'
import Background from '../../modules/Background'
import Date from '../../modules/Date'
import DigitalClock from '../../modules/DigitalClock'
import Distance from '../../modules/Distance'
import Editable from '../../modules/Editable'
import EditableBackground from '../../modules/EditableBackground'
import FrameAnimation from '../../modules/FrameAnimation'
import Image from '../../modules/Image'
import Lunar from '../../modules/Lunar'
import Mask from '../../modules/Mask'
import Message from '../../modules/Message'
import Moon from '../../modules/Moon'
import Pai from '../../modules/Pai'
import Phone from '../../modules/Phone'
// import ProgressClock from '../../modules/ProgressClock';
import SameAsBattery from '../../modules/SameAsBattery'
import Scale from '../../modules/Scale'
import Stand from '../../modules/Stand'
import Step from '../../modules/Step'
import Sun from '../../modules/Sun'
import System from '../../modules/System'
import Temperature from '../../modules/Temperature'
import Text from '../../modules/Text'
import Weather from '../../modules/Weather'
import Week from '../../modules/Week'
import { DigitalAmPmComponent, DigitalClockComponent, DigitalClockTimeComponent } from '../../ZeppOsComponents/DigitalClock'

const componentMap = () => ({
  idle_background: {
    cls: Background,
    instance: <Background />
  },
  background: {
    cls: Background,
    instance: <Background />
  },
  scale: {
    cls: Scale,
    instance: <Scale />
  },
  image: {
    cls: Image,
    instance: <Image />
  },
  text: {
    cls: Text,
    instance: <Text />
  },
  analog_clock: {
    cls: AnalogClock,
    constMap: Immutable.fromJS({
      hour: {
        name: 'hour_pointer',
        recommendPreviewValue: 10,
      },
      minute: { name: 'min_pointer', recommendPreviewValue: 9, },
      second: { name: 'sec_pointer', recommendPreviewValue: 36 }
    }),
    instance: <AnalogClock />
  },
  digital_clock: {
    cls: DigitalClock,
    // data组件配置
    constMap: Immutable.fromJS({
      hour: {
        name: 'hour_pointer',
        valueCount: 2,
        before: [],
        after: ['minute', 'second'],
        previewMaxValue: 23,
        recommendPreviewValue: 10,
      },
      minute: {
        name: 'min_pointer',
        valueCount: 2,
        before: ['hour'],
        after: ['second'],
        previewMaxValue: 59,
        recommendPreviewValue: 9,
      },
      second: {
        name: 'sec_pointer',
        valueCount: 2,
        before: ['minute', 'hour'],
        after: [],
        previewMaxValue: 59,
        recommendPreviewValue: 36,
      }
    }),
    // 子组件 props 配置
    propMap: {},
    instance: <DigitalClock />
  },
  digitalClock: {
    cls: DigitalClockComponent,
    instance: <DigitalClockComponent />
  },
  digitalClockHour: {
    cls: DigitalClockTimeComponent,
    constMap: Immutable.fromJS({
      type: 'digitalClockHour'
    }),
    instance: <DigitalClockTimeComponent />
  },
  digitalClockMinute: {
    cls: DigitalClockTimeComponent,
    constMap: Immutable.fromJS({
      type: 'digitalClockMinute'
    }),
    instance: <DigitalClockTimeComponent />
  },
  digitalClockSecond: {
    cls: DigitalClockTimeComponent,
    constMap: Immutable.fromJS({
      type: 'digitalClockSecond'
    }),
    instance: <DigitalClockTimeComponent />
  },
  digitalClockAm: {
    cls: DigitalAmPmComponent,
    constMap: Immutable.fromJS({
      type: 'digitalClockAm'
    }),
    instance: <DigitalAmPmComponent />
  },
  digitalClockPm: {
    cls: DigitalAmPmComponent,
    constMap: Immutable.fromJS({
      type: 'digitalClockPm'
    }),
    instance: <DigitalAmPmComponent />
  },
  // progress_clock: {
  //     cls: ProgressClock,
  //     instance: <ProgressClock />,
  // },
  battery: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        recommendPreviewValue: 75,
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      },
      image_progress: {
        fixedProps: {
          level: 10
        },
      }
    },
    instance: <SameAsBattery />
  },
  heart_rate: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 3,
        before: [],
        after: [],
        invalidImageRequired: true,
        recommendPreviewValue: 86,
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  calorie: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      current: {
        name: 'data',
        valueCount: 4,
        before: [],
        after: [],
        recommendPreviewValue: 280,
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  pai: {
    cls: Pai,
    constMap: Immutable.fromJS({
      weekly: {
        name: 'week_pai',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 75 * 7
      }
    }),
    propMap: {},
    instance: <Pai />
  },
  pai_daily: {
    cls: Pai,
    constMap: Immutable.fromJS({
      daily: {
        name: 'day_pai',
        // 最大值为 75，因固件定为 3 位显示，为不影响已制作的表盘，不能更改，工具也设置为 3 位，同固件保持一致
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 75
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <Pai />
  },
  stand: {
    cls: Stand,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 5,
        stepCount: 2,
        before: [],
        after: [],
        previewMaxValue: 12
      }
    }),
    propMap: {
      text: {
        pointImageText: '分隔符',
        hasPointImage: true,
        delimiter: '/',
        hideSeparatorImage: true,
        hiddenCombin: true
      },
      image_progress: {
        fixedProps: {
          level: 12
        }
      }
    },
    instance: <Stand />
  },
  aqi: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'air_quality',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 500,
        invalidImageRequired: true,
        recommendPreviewValue: 26,
      }
    }),
    propMap: {
      image_progress: {
        imageProgressCount: 6
      },
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  humidity: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'humidity',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        invalidImageRequired: true,
        recommendPreviewValue: 42,
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  altimeter: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'altimeter',
        valueCount: 4,
        before: [],
        after: [],
        previewMaxValue: 1200,
        invalidImageRequired: true
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  spo2: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'spo2',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        invalidImageRequired: true
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  wind: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'wind',
        valueCount: 2,
        before: [],
        after: [],
        previewMaxValue: 12,
        invalidImageRequired: true
      }
    }),
    propMap: {
      text: {
        hidePaddingZero: true,
      },
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      },
      image_progress: {
        fixedProps: {
          level: 12,
        }
      },
    },
    instance: <SameAsBattery />
  },
  stress: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'stress',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        invalidImageRequired: true
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  training_load: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'training_load',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        invalidImageRequired: true
      }
    }),
    propMap: {
      text: {
        hidePaddingZero: true,
      },
      image_progress: {
        imageProgressCount: 3
      },
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  vo2max: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'vo2max',
        valueCount: 2,
        before: [],
        after: [],
        previewMaxValue: 100,
        invalidImageRequired: true
      }
    }),
    propMap: {
      text: {
        hidePaddingZero: true,
      },
      image_progress: {
        imageProgressCount: 7
      },
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  uvi: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'uv_degree',
        valueCount: 2,
        before: [],
        after: [],
        previewMaxValue: 12,
        invalidImageRequired: true,
        recommendPreviewValue: 4,
      }
    }),
    propMap: {
      image_progress: {
        imageProgressCount: 5
      },
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <SameAsBattery />
  },
  step: {
    cls: Step,
    constMap: Immutable.fromJS({
      current: {
        name: 'data',
        valueCount: 5,
        before: [],
        after: [],
        recommendPreviewValue: 8670,
      },
      target: {
        name: 'target_steps',
        valueCount: 5,
        before: [],
        after: []
      }
    }),
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <Step />
  },
  distance: {
    cls: Distance,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 5,
        stepCount: 2,
        before: [],
        after: []
      }
    }),
    propMap: {
      text: {
        hiddenCombin: true,
        hasPointImage: true,
        hasInvalidImage: true,
        hideSeparatorImage: true,
        hidePaddingZero: true,
        unitConfig: {
          labels: ['unit_image_km', 'unit_image_mi'],
          languages: [[{ label: '', lang: 'all' }], [{ label: '', lang: 'all' }]],
          fields: ['unit_images', 'imperial_unit_images']
        }
      },
    },
    instance: <Distance />
  },
  system: {
    cls: System,
    instance: <System />
  },
  date: {
    cls: Date,
    constMap: Immutable.fromJS({
      year: {
        name: 'year',
        valueCount: 4,
        before: [],
        after: ['month', 'day'],
        recommendPreviewValue: dayjs().year(),
      },
      month: {
        name: 'month',
        valueCount: 2,
        before: ['year'],
        after: ['day'],
        supportImage: true,
        previewMaxValue: 12,
        previewSuffix: 'month',
        recommendPreviewValue: 2,
      },
      day: {
        name: 'day',
        valueCount: 2,
        before: ['month', 'year'],
        after: [],
        previewMaxValue: 31,
        previewSuffix: 'day',
        recommendPreviewValue: 8,
      }
    }),
    propMap: {},
    instance: <Date />
  },
  lunar: {
    cls: Lunar,
    propMap: {},
    instance: <Lunar />
  },
  week: {
    cls: Week,
    propMap: {
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <Week />
  },
  weather: {
    cls: Weather,
    propMap: {
      image_progress: {
        fixedProps: {
          level: 29
        },
        showNamingRule: true
      }
    },
    instance: <Weather />
  },
  moon: {
    cls: Moon,
    propMap: {
      image_progress: {
        fixedProps: {
          level: 30,
          is_continuance: false
        }
      }
    },
    instance: <Moon />
  },
  temperature: {
    cls: Temperature,
    constMap: Immutable.fromJS({
      current: {
        name: 'real_time_temp',
        valueCount: 3,
        before: [],
        after: [],
        invalidImageRequired: true,
        previewMinValue: -999,
        recommendPreviewValue: 22,
      },
      high: {
        name: 'highest_temp',
        valueCount: 3,
        before: ['low'],
        after: [],
        invalidImageRequired: true,
        previewMinValue: -999,
        recommendPreviewValue: 22,
      },
      low: {
        name: 'lowest_temp',
        valueCount: 3,
        before: [],
        after: ['high'],
        invalidImageRequired: true,
        previewMinValue: -999,
        recommendPreviewValue: 9,
      }
    }),
    propMap: {
      current: {
        hiddenCombin: true,
        hidePaddingZero: true,
        hideNegativeImage: false,
        unitImageSource: [
          {
            language: 'all',
            unit: '°'
          }
        ]
      },
      high: {
        hidePaddingZero: true,
        hideNegativeImage: false,
        unitImageSource: [
          {
            language: 'all',
            unit: '°'
          }
        ]
      },
      low: {
        hiddenCombin: true,
        hidePaddingZero: true,
        hideNegativeImage: false,
        unitImageSource: [
          {
            language: 'all',
            unit: '°'
          }
        ]
      },
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <Temperature />
  },
  sun: {
    cls: Sun,
    constMap: Immutable.fromJS({
      current: {
        valueCount: 5,
        name: 'current_time',
        invalidImageRequired: true
      },
      high: {
        valueCount: 5,
        name: 'sunrise',
        invalidImageRequired: true
      },
      low: {
        valueCount: 5,
        name: 'sunset',
        invalidImageRequired: true
      }
    }),
    propMap: {
      current: {
        hiddenCombin: true,
        hasPointImage: true,
        pointImageText: 'colon_image',
        isPreviewTime: true
      },
      high: {
        hiddenCombin: true,
        hasPointImage: true,
        pointImageText: 'colon_image',
        isPreviewTime: true
      },
      low: {
        hiddenCombin: true,
        hasPointImage: true,
        pointImageText: 'colon_image',
        isPreviewTime: true
      },
      image_progress: {
        fixedProps: {
          level: 2,
          is_continuance: true
        }
      },
      progress: {
        fixedPropsKey: ['progress_render_type', 'cap']
      }
    },
    instance: <Sun />
  },
  message: {
    cls: Message,
    instance: <Message />
  },
  phone: {
    cls: Phone,
    instance: <Phone />
  },
  component: {
    cls: Editable,
    instance: <Editable />
  },
  mask: {
    cls: Mask,
    instance: <Mask />
  },
  animation: {
    cls: FrameAnimation,
    instance: <FrameAnimation />
  },
  editable_bg: {
    cls: EditableBackground,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100
      }
    }),
    propMap: {},
    instance: <EditableBackground />
  }
})

export default componentMap
