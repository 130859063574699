/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import AmPm from '@watchface/pages/DesignTool/NonZeppOs/components/AmPm'
import Classnames from 'classnames'
import Immutable from 'immutable'
import React, { PureComponent } from 'react'
import { IconComponent, JumpHotAreaComponent, NumberComponent, NumberComponentWithRect } from '../../components'

const noop = () => {} // eslint-disable-line
const TIME_ARR = ['hour', 'minute', 'second']
class AlarmClock extends PureComponent {
  static renderLayer = ({ parentKey, childKey, hasRectArea, ...restArg }) => {
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent
    const componentMap = {
      hour: NumComponent,
      minute: NumComponent,
      second: NumComponent,
      icon: IconComponent,
      no_icon: IconComponent,
      jumpable: JumpHotAreaComponent
    }
    const paddingX = AlarmClock.getNumberComponentPaddingX({ parentKey, ...restArg })
    if (childKey === 'am') {
      AmPm.renderLayer({ parentKey, childKey, hasRectArea, paddingX, ...restArg })
    } else {
      const Component = componentMap[childKey]
      Component && Component.renderLayer({ paddingX, parentKey, childKey, ...restArg })
    }
  }

  static getWidthHeight = ({ allData, parentKey }) => {
    const imagePathArr = Immutable.fromJS([
      [parentKey, 'children', 'am', 'images', 0, 'image'],
      [parentKey, 'children', 'am', 'images', 1, 'image'],
      [parentKey, 'children', 'pm', 'images', 0, 'image'],
      [parentKey, 'children', 'pm', 'images', 1, 'image']
    ])

    let width = 0
    let height = 0
    for (let i = 0; i < imagePathArr.size; i += 1) {
      const path = imagePathArr.get(i)
      const item = allData.getIn(path)
      const { width: w, height: h } = immutableSelector(item)
      if (w && h) {
        width = w
        height = h
        break
      }
    }

    return {
      width,
      height
    }
  }

  static getNumberComponentPaddingX = ({ parentKey, allData, allAssets, dialType }) => {
    const { width } = AlarmClock.getWidthHeight({ parentKey, allData })
    const show = allAssets.getIn([dialType, parentKey, 'children', 'ampm', 'show'])
    const align = allData.getIn([parentKey, 'align'])
    if (align === 'left') {
      return 0
    }
    if (show !== 'none') {
      return 0
    }
    if (align === 'right') {
      return width
    }
    if (align === 'center') {
      return width / 2
    }
    return 0
  }

  renderTime = (hasRectArea, allProps) => {
    const { parentKey, childKey, assets, allData, allAssets, dialType } = this.props

    const cls = Classnames(['alarmclock-container', 'control-container'])
    const paddingX = AlarmClock.getNumberComponentPaddingX({ parentKey, assets, allData, allAssets, dialType })
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent

    return (
      <div className={cls} key={childKey}>
        <NumComponent {...allProps} parentKey={parentKey} paddingX={paddingX} changeCallback={this.handleChangeCallBack} />
      </div>
    )
  }

  renderAmPm = () => {
    const cls = Classnames(['alarmclock-container', 'control-container'])
    return (
      <div className={cls}>
        <AmPm {...this.props} />
      </div>
    )
  }

  render() {
    const { childKey, data, propMap = {} } = this.props
    const prop = propMap[childKey] || {}
    const { hasRectArea } = prop
    const allProps = { ...this.props, ...prop }

    const componentMap = {
      icon: <IconComponent {...allProps} key={childKey} />,
      no_icon: <IconComponent {...allProps} key={childKey} />,
      jumpable: <JumpHotAreaComponent {...allProps} />
    }

    if (TIME_ARR.includes(childKey)) {
      return this.renderTime(hasRectArea, allProps)
    }

    if (componentMap[childKey]) {
      return <div className="control-container">{componentMap[childKey]}</div>
    }

    return this.renderAmPm(data)
  }
}

export default AlarmClock
