// 使用 asset 存储服务端未支持的 zepp os 协议. https://huami.feishu.cn/wiki/wikcntJoIdX5BkgYsiQXfE5XYre#
import createBackgroundData from '@watchface/pages/DesignTool/NonZeppOs/components/BackgroundComponent/config'
import BG_GROUP from '@watchface/pages/DesignTool/NonZeppOs/components/BackgroundGroupComponent/config'
import ICON from '@watchface/pages/DesignTool/NonZeppOs/components/IconComponent/config'
import JUMPABLE from '@watchface/pages/DesignTool/NonZeppOs/components/JumpHotAreaComponent/config'
import LUNAR_CONFIG from '@watchface/pages/DesignTool/NonZeppOs/components/LunarComponent/config'
import createNumberData from '@watchface/pages/DesignTool/NonZeppOs/components/NumberComponent/config'
import IMAGE_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PictureProgress/config'
import POINTER_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PointerProgressComponent/config'
import getProgress from '@watchface/pages/DesignTool/NonZeppOs/components/ProgressComponent/config'
import Immutable from 'immutable'

const PAI_DAY = {
  children: {
    daily: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const LUNAR = {
  children: {
    lunar_date: LUNAR_CONFIG,
    festival_or_solar_term: LUNAR_CONFIG,
  },
}

const MOON = {
  children: {
    image_progress: IMAGE_PROGRESS
  }
}

const EDIT_PAI_DAILY = {
  ...PAI_DAY,
  previews: [
    {
      language: 'all',
      image: {
        url: '',
        width: 0,
        height: 0,
        name: ''
      }
    }
  ],
  bg: {
    position: {
      x: 0,
      y: 0
    },
    image: ''
  }
}

const EDITABLE_BG = {
  children: {
    bg: BG_GROUP,
    tips: ICON
  }
}

const extraConfig = {
  // 相较于 layerConfig 新增的模块或者新增已有模块中字段的路径, 二维数组
  newFieldOrModulePaths: [
    // 如 background 新增 radius 字段，可配置为 ['background', 'radius']
    // 新增新的模块如站立，可配置为 ['stand']
    ['background', 'radius'],
    ['background', 'bg_type'], // 背景类型配置，取代 type
    ['background', 'editable_bg'], // 可编辑背景配置
    ['pai_daily'],
    ['component', 'props', 'pai_daily'],
    ['editable_bg'],
    ['lunar'],
    ['moon']
  ],
  background: createBackgroundData({ isIdle: false, hasRadius: true, hasEditableBg: true }),
  pai_daily: PAI_DAY,
  component: {
    props: {
      pai_daily: EDIT_PAI_DAILY
    }
  },
  editable_bg: EDITABLE_BG,
  lunar: LUNAR,
  moon: MOON,
}

export default Immutable.fromJS(extraConfig)
