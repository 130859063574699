/* eslint-disable import/order */
import { immutableSelector } from '@/utils/index'
import { FormOutlined } from '@ant-design/icons'
import { AppIdEditor } from '@watchface/components'
import T from '@watchface/components/I18n'
import CreateForm from '@watchface/pages/Create'
import { Modal } from 'antd'
import type { Map as ImmutableMap } from 'immutable'
import Immutable from 'immutable'
import React, { useRef, useState } from 'react'
import './styles/WatchInfo.scss'

interface WatchInfoProps {
  metas: ImmutableMap<string, any>
  support: ImmutableMap<string, any>
  watchConfig: ImmutableMap<string, any>
  loading: boolean
  asset: ImmutableMap<string, any>
  handleChangeAssets: (path: string[], data: any) => void
}

const WatchInfo = (props: WatchInfoProps): JSX.Element | null => {
  const { watchConfig, loading, metas, support, asset, handleChangeAssets } = props
  const dialName = (metas || Immutable.Map()).getIn(['multi_language', 0, 'name'])
  const [state, _setState] = useState<any>({
    // 控制修改表盘信息模态框的显示和隐藏
    modifyModalVisible: false
  })
  const stateRef: any = useRef(state)
  const setState = (state: any) => {
    stateRef.current = state
    _setState(state)
  }
  const { modifyModalVisible } = state
  const { screenWidth, screenHeight } = immutableSelector(watchConfig)
  const { name = '' } = immutableSelector(support)
  const sizeText = screenWidth && screenHeight ? `${screenWidth}*${screenHeight}px` : <T id="no_data" />

  if (loading) return null

  const setEditModalVisible = (visible = false) => {
    setState({
      ...state,
      modifyModalVisible: visible
    })
  }

  return (
    <div className="watch-info">
      <div className="info-name-wrapper">
        <span className="info-name">
          <T id="name" />：{dialName || <T id="no_name" />}
        </span>
        <FormOutlined onClick={() => setEditModalVisible(true)} />
      </div>
      <AppIdEditor asset={asset} handleChangeAssets={handleChangeAssets} />
      <div>
        <T id="size" />：{sizeText}
      </div>
      <div>
        <T id="support_device" />：{name}
      </div>
      <Modal
        footer={null}
        title={<T id="modify_dial_info" />}
        visible={modifyModalVisible}
        closeIcon={<span />}
        centered
        destroyOnClose
        className="watch-dial-modal"
        width={800}
      >
        <CreateForm
          key={modifyModalVisible}
          handleCancel={() => setEditModalVisible(false)}
          handleClose={() => setEditModalVisible(false)}
          isEdit
          device={support}
          metas={metas}
        />
      </Modal>
    </div>
  )
}

export default WatchInfo
