export default `
    {{{ variable }}} = hmUI.createWidget(hmUI.widget.TEXT_IMG, {
      {{ #showPosition }}
      x: {{ x }},
      y: {{ y }},
      {{ #w }}
      w: {{ w }},
      {{ /w }}
      {{ #h }}
      h: {{ h }},
      {{ /h }}
      {{ /showPosition }}
      type: {{ type }},
      font_array: {{{ imageArray }}},
      {{ #align }}
      align_h: {{ align }},
      {{ /align }}
      h_space: {{ interval }},
      show_level: {{ showLevel }},
      {{ #unitSC }}
      unit_sc: '{{{ unitSC }}}',//单位
      {{ /unitSC }}
      {{ #unitTC }}
      unit_tc: '{{{ unitTC }}}',//单位
      {{ /unitTC }}
      {{ #unitEN }}
      unit_en: '{{{ unitEN }}}',//单位
      {{ /unitEN }}
      {{ #imperialUnitSC }}
      imperial_unit_sc: '{{{ imperialUnitSC }}}',//英制单位
      {{ /imperialUnitSC }}
      {{ #imperialUnitTC }}
      imperial_unit_tc: '{{{ imperialUnitTC }}}',//英制单位
      {{ /imperialUnitTC }}
      {{ #imperialUnitEN }}
      imperial_unit_en: '{{{ imperialUnitEN }}}',//英制单位
      {{ /imperialUnitEN }}
      {{ #negativeImage }}
      negative_image: '{{{ negativeImage }}}', //负号图片
      {{ /negativeImage }}
      {{ #dotImage }}
      dot_image: '{{{ dotImage }}}', //小数点图片
      {{ /dotImage }}
      {{ #invalidImage }}
      invalid_image: '{{{ invalidImage }}}',// 无数据时显示的图片
      {{ /invalidImage }}
      padding: {{{ padding_zero }}},
      isCharacter: {{{ is_character }}}
    });
  `
