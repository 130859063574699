export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.IMG_TIME, {
      {{ #timeData }}
      {{ #addTimeVal }}
      {{ key }}_val: {{ timeVal }},
      {{ /addTimeVal }}
      {{ key }}_zero: {{ padding_zero }},
      {{ ^follow }}
      {{ key }}_startX: {{ position.x }},
      {{ key }}_startY: {{ position.y }},
      {{ /follow }}
      {{ #imageArray }}
      {{ key }}_array: {{{ imageArray }}},
      {{ /imageArray }}
      {{ ^follow }}
      {{ key }}_space: {{ interval }},
      {{ /follow }}
      {{ #unitSC }}
      {{ key }}_unit_sc: '{{{ unitSC }}}',
      {{ /unitSC }}
      {{ #unitTC }}
      {{ key }}_unit_tc: '{{{ unitTC }}}',
      {{ /unitTC }}
      {{ #unitEN }}
      {{ key }}_unit_en: '{{{ unitEN }}}',
      {{ /unitEN }}
      {{ ^follow }}
      {{ key }}_align: {{ align }},
      {{ /follow }}
      {{ #showFollow }}
      {{ key }}_follow: {{ follow }},
      {{ /showFollow }}
      {{ /timeData }}
      {{ #ampmData }}
      {{ key }}_x: {{ position.x }},
      {{ key }}_y: {{ position.y }},
      {{ #imageSC }}
      {{ key }}_sc_path: '{{{ imageSC }}}',
      {{ /imageSC }}
      {{ #imageEN }}
      {{ key }}_en_path: '{{{ imageEN }}}',
      {{ /imageEN }}
      {{ /ampmData }}
      enable: false,
      show_level: {{ showLevel }},
    });
`
