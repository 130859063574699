/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { ColorPicker, InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE } from '@watchface/constants'
import { SELECT_STYLE } from '@watchface/constants/style'
import { convertToDegree, convertToAngle, getRotatedPoint, isHitKey } from '@watchface/utils'
import { removeCells, renderCells } from '@watchface/utils/render'
import { Select } from 'antd'
import { fabric } from 'fabric'
import Immutable from 'immutable'
import { compact, debounce } from 'lodash'
import PubSub from 'pubsub-js'
import React, { PureComponent } from 'react'
import { getEndShape } from './util'

const { Option } = Select
const noop = () => {} // eslint-disable-line

const zIndexMap = {
  bg: -13,
  ring: -12,
  endShape: -11,
  image: -10
}


// Math.abs(startAngle - endAngle) <= 360
const limitAngleRange = (startAngle, endAngle, changeKey = 'start') => {
  const isStartLarge = startAngle - endAngle > 0

  if (Math.abs(startAngle - endAngle) > 360) {
    if (changeKey === 'start') {
      return isStartLarge ? [startAngle, startAngle - 360] : [startAngle, startAngle + 360]
    }
    return isStartLarge ? [endAngle + 360, endAngle] : [endAngle - 360, endAngle]
  }
  return [startAngle, endAngle]
}

class ProgressComponent extends PureComponent {
  static keyMap = {}

  // action 触发 data 改变
  // 根据 data 的改变计算出 新的 data 和需要渲染和删除的 cell
  // 这里边需要两次 双层循环 但是因为 每个组件对应的 cell 数量不多所以不会产生性能问题
  // 仅仅属性值发生改变的话会调用更新方法 新产生的调用 create 移除的调用remove
  // 这样在属性值发生改变的时候和最开始渲染走的是一个逻辑
  // 组件一直维护一个当前渲染的 cells 状态 cells 需要有清空的逻辑 当外部针对某一个子 module 进行隐藏的时候需要 对对应的数组清空
  // actions -> data -> cellData -> cell

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: ProgressComponent,
      ...param
    })
    ProgressComponent.keyMap = Object.assign(ProgressComponent.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: ProgressComponent,
      ...param
    })
    ProgressComponent.keyMap = keyMap
  }

  componentDidMount() {
    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      const { data, parentKey, childKey, editableComponentKey = '', dialType } = this.props

      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      const { circle = Immutable.Map(), width: strokeWidth } = immutableSelector(data)
      const { radius } = immutableSelector(circle)
      const x = left + radius + Math.round(strokeWidth / 2)
      const y = top + radius + Math.round(strokeWidth / 2)
      this.handleChange(['circle', 'position'], '', Immutable.Map({
        x,
        y,
      }))
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props
    if (prevProps !== this.props) {
      if (!hidden) {
        ProgressComponent.renderLayer(this.props)
      } else {
        ProgressComponent.removeLayer(this.props)
      }
    }
  }

  static getCellDatas = ({ parentKey, assets, childKey, data }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const { progress_render_type } = immutableSelector(data)
    if (progress_render_type === 'image') {
      const bg = ProgressComponent.getBgImage(data)
      const progress = ProgressComponent.getProgressImage(data)

      if (bg) {
        cells.imageEntity[bg.key] = bg
      }
      if (progress) {
        cells.imageEntity[progress.key] = progress
      }
    } else {
      const ring = ProgressComponent.getRing({ assets, parentKey, childKey, data })
      const bgImage = ProgressComponent.getBgImage(data)

      if (ring?.key) {
        cells.vertexEntity[ring.key] = ring
      }
      if (bgImage?.key) {
        cells.imageEntity[bgImage.key] = bgImage
      }
    }
    return cells
  }

  static getBgImage = (data) => {
    const { bg } = immutableSelector(data)
    const pos = ProgressComponent.getPos(data)
    const { x = 0, y = 0 } = immutableSelector(pos)
    let image = Immutable.Map()
    const key = 'bg'
    if (bg) {
      image = {
        key,
        texture: bg,
        left: x,
        top: y,
        zIndex: zIndexMap[key]
      }
    } else {
      image = null
    }

    return image
  }

  static getRing = ({ childKey, data, assets }) => {
    const { circle = Immutable.Map(), width: strokeWidth, progress_color, cap = 'round' } = immutableSelector(data)
    if (!progress_color) return {}
    const value = assets.getIn([childKey, 'value']) ?? 100
    const { position = Immutable.Map(), radius, angle = Immutable.Map() } = immutableSelector(circle)
    const { x, y } = immutableSelector(position)
    const { start: originStart, end: originEnd } = immutableSelector(angle)
    const [start, end] = limitAngleRange(originStart, originEnd)
    const color = progress_color ? `#${progress_color.slice(4)}` : ''
    const endShape = getEndShape(cap)
    const key = `ring-end-${endShape}`
    const clockwise = start < end
    // 固件绘制以 12 点钟方向为 0 度，fabric 以 3 点钟方向为 0 度，需要校准 fabric 绘制角度
    const offsetAngle = 90
    // 夹角
    const circularAngle = end - start
    let startAngle = clockwise ? start - offsetAngle : (start - offsetAngle +  circularAngle * value / 100)
    const endAngle = clockwise ? (start - offsetAngle +  circularAngle * value / 100) : start - offsetAngle

    if (!clockwise && value === 0) {
      startAngle = - offsetAngle
    }

    let adjustDegree = Math.asin(strokeWidth / 2 / radius)

    if (endShape === 'butt' || Math.abs(endAngle - startAngle) === 360) {
      adjustDegree = 0
    }

    if (endShape === 'triangle')  {
      const startTriangle = ProgressComponent.getTriangleOption({
        angle: startAngle + convertToAngle(adjustDegree),
        rotate: startAngle + convertToAngle(adjustDegree),
        strokeWidth: adjustDegree === 0 ? 0 : strokeWidth,
        circleX: x,
        circleY: y,
        radius,
        color,
      })
      const endTriangle = ProgressComponent.getTriangleOption({
        angle: endAngle - 180 - convertToAngle(adjustDegree),
        rotate: endAngle - convertToAngle(adjustDegree),
        strokeWidth: adjustDegree === 0 ? 0 : strokeWidth,
        circleX: x,
        circleY: y,
        radius,
        color,
      })

      return {
        key,
        type: 'group',
        objects: [
          {
            left: x - radius - strokeWidth / 2,
            top: y - radius - strokeWidth / 2,
            startAngle: convertToDegree(startAngle) + adjustDegree,
            endAngle: convertToDegree(endAngle) - adjustDegree,
            strokeWidth,
            radius,
            stroke: color,
            fill: 'transparent',
            type: 'circle',
            zIndex: zIndexMap[key]
          },
          startTriangle,
          endTriangle
        ]
      }
    }

    return {
      left: x - radius - strokeWidth / 2,
      top: y - radius - strokeWidth / 2,
      startAngle: convertToDegree(startAngle) + adjustDegree,
      endAngle: convertToDegree(endAngle) - adjustDegree,
      // angle: -90,
      strokeWidth,
      radius,
      key,
      stroke: color,
      fill: 'transparent',
      type: 'circle',
      strokeLineCap: endShape,
      zIndex: zIndexMap[key]
    }
  }

  static getProgressImage = (data) => {
    const { progress_image } = immutableSelector(data)
    const pos = ProgressComponent.getPos(data)
    const { x = 0, y = 0 } = immutableSelector(pos)

    const key = 'image'
    if (progress_image) {
      return {
        key,
        texture: progress_image,
        left: x,
        top: y,
        zIndex: zIndexMap[key]
      }
    }
    return null
  }

  // 计算左上角坐标
  static getPos = (data) => {
    try {
      const { circle = Immutable.Map(), width = 0 } = immutableSelector(data)
      const { position = Immutable.Map(), radius = 0 } = immutableSelector(circle)
      const { x: centerX = 0, y: centerY = 0 } = immutableSelector(position || Immutable.Map())
      const x = parseInt(centerX - radius - width / 2, 10)
      const y = parseInt(centerY - radius - width / 2, 10)

      return Immutable.fromJS({
        x,
        y
      })
    } catch {
      return Immutable.fromJS({
        x: 0,
        y: 0
      })
    }
  }

  static getTriangleOption = ({
    angle,
    rotate,
    strokeWidth,
    circleX,
    circleY,
    radius,
    color,
    key,
  }) => {
    const getEndPos = (angle) => {
      const degree = angle / 180 * Math.PI
      const sin = radius * fabric.util.sin(degree)
      const cos = radius * fabric.util.cos(degree)
      const posX = circleX + (cos - strokeWidth / 2 )
      const posY = circleY + (sin - strokeWidth / 2)

      return {
        x: posX,
        y: posY
      }
    }
    const pos = getEndPos(rotate)
    const origin = { x: pos.x + strokeWidth / 2, y: pos.y + strokeWidth / 2 }
    const rotatedPoint = getRotatedPoint(pos, origin, angle)

    return {
      key,
      left: rotatedPoint.x,
      top: rotatedPoint.y,
      width: strokeWidth,
      height: strokeWidth / 2,
      angle,
      fill: color,
      type: 'triangle'
    }
  }

  handleInsertImage = (key, img) => {
    const { onChange, data, parentKey, childKey, prefixPath } = this.props
    let newData = data
    if (img && img.image) {
      newData = data.set(key, img.image)
    } else {
      newData = data.set(key, '')
    }
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleAngleChange = (value, key, angle) => {
    let newVal = value

    if (value === '') newVal = 0

    const { start: originStart, end: originEnd } = immutableSelector(angle)
    const args = key === 'start' ? [newVal, originEnd, key] : [originStart, newVal, key]
    const [start, end] = limitAngleRange(...args)

    this.handleChange(['circle', 'angle'], '', Immutable.fromJS({
      start,
      end,
    }))
  }

  isVaildNumber = (value) => {
    return typeof value === 'number'
  }

  handleChange = (path, prop, value) => {
    const { onChange, data, parentKey, childKey, prefixPath } = this.props
    let newData = data.setIn(compact([...path, prop]), value)

    // 选择属性为切换进度类型
    if (prop === 'progress_render_type') {
      newData = newData.set('bg', '')
      if (value === 'image') {
        newData = newData.setIn([...path, 'progress_color'], '0xFFFFFFFF')
      } else {
        newData = newData.setIn([...path, 'progress_image'], '')
      }
    }

    const rootPath = [parentKey, 'children']
    onChange([...prefixPath, ...rootPath, childKey], newData)
  }

  handleColorChange = (color) => {
    const value = color.hex
    const { onChange, data, parentKey, childKey, prefixPath } = this.props
    // todo: 临时修改转为 ARGB 格式, 后续优化
    const newData = data.set('progress_color', value ? `0xFF${value.slice(1)}` : '')

    const rootPath = [parentKey, 'children']
    onChange([...prefixPath, ...rootPath, childKey], newData)
  }

  handleColorInputChange = (value) => {
    this.handleColorChange({ hex: value })
  }

  handleChangeAssets = (value) => {
    const { onAssetsChange, parentKey, childKey, assets, dialType, prefixPath } = this.props
    const rootPath = [parentKey, 'children']
    const newAssets = assets.setIn([childKey, 'value'], value)
    onAssetsChange([dialType, ...prefixPath, ...rootPath, childKey], newAssets)
  }

  render() {
    const { data, title = 'normal_progress', childKey, watchConfig, constMap, assets = Immutable.Map(), fixedPropsKey = [] } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const name = constMap.getIn([childKey, 'name']) || ''
    const { progress_render_type, progress_image, progress_color = '', width, bg, cap, circle = Immutable.Map() } = immutableSelector(data)
    const { position = Immutable.Map(), radius, angle = Immutable.Map() } = immutableSelector(circle)
    const { x, y } = immutableSelector(position)
    const { start: originStart, end: originEnd } = immutableSelector(angle)
    const [start, end] = limitAngleRange(originStart, originEnd)
    const value = assets.getIn([childKey, 'value']) ?? 100
    const fixedKey = (key) =>  fixedPropsKey.indexOf(key) > -1

    return (
      <>
        <div className="name">
          <T id={title} />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              value={progress_render_type}
              style={SELECT_STYLE}
              disabled={fixedKey('progress_render_type')}
              onChange={(value) => {
                this.handleChange([], 'progress_render_type', value)
              }}
            >
              <Option value="image">
                <T id="upload_image" />
              </Option>
              <Option value="color">
                <T id="color_val" />
              </Option>
            </Select>
          </div>
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                key={childKey}
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={bg ? [{ image: bg }] : []}
                onChange={(image) => {
                  this.handleInsertImage('bg', image)
                }}
              />
              <T id="cir_bg" />
            </div>
            {progress_render_type === 'image' ? (
              <div className="col col-v">
                <Upload
                  key={childKey}
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={progress_image ? [{ image: progress_image }] : []}
                  onChange={(image) => {
                    this.handleInsertImage('progress_image', image)
                  }}
                />
                <div className="is-required">
                  <T id="cir_progress" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {progress_render_type === 'color' ? (
          <>
            <div className="name">
              <T id="color_val" />
            </div>
            <div className="content">
              <div className="item">
                <ColorPicker
                  width={200}
                  color={progress_color ? `#${progress_color.slice(4)}` : ''}
                  onInputChange={this.handleColorInputChange}
                  onChangeComplete={this.handleColorChange}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="key" style={{ marginRight: 20 }}>
              <T id="cir_center" />
            </div>
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                inputStyle={{ width: 100 }}
                value={x}
                onChange={(value) => {
                  this.handleChange(['circle', 'position'], 'x', value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                inputStyle={{ width: 100 }}
                value={y}
                onChange={(value) => {
                  this.handleChange(['circle', 'position'], 'y', value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="progress_angle" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <T id="from" />
              &nbsp;
              <InputNumber
                value={start}
                onChange={debounce((value) => this.handleAngleChange(value, 'start', angle), 300)}
              />
              &nbsp;
              <T id="to" />
              &nbsp;
              <InputNumber
                value={end}
                onChange={debounce((value) => this.handleAngleChange(value, 'end', angle), 300)}
              />
              &nbsp;
              <T id="degree" />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="cir_radius" />
          (px) *
        </div>
        <div className="content">
          <div className="item">
            <InputNumber
              min={0}
              value={radius}
              style={SELECT_STYLE}
              onChange={(value) => {
                this.handleChange(['circle'], 'radius', value)
              }}
            />
          </div>
        </div>
        <div className="name">
          <T id="cir_width" />
          (px) *
        </div>
        <div className="content">
          <div className="item">
            <InputNumber
              min={0}
              value={width}
              style={SELECT_STYLE}
              onChange={(value) => {
                this.handleChange([], 'width', value)
              }}
            />
          </div>
        </div>
        <div className="name">
          <T id="end_style" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              value={cap}
              style={SELECT_STYLE}
              disabled={fixedKey('cap')}
              onChange={(value) => {
                this.handleChange([], 'cap', value)
              }}
            >
              <Option value={0}>
                <T id="circle_arc" />
              </Option>
              <Option value={90}>
                <T id="triangle" />
              </Option>
              <Option value={180}>
                <T id="flat_angle" />
              </Option>
            </Select>
          </div>
        </div>
        <div className="name">
          {name} <T id="preview_percent" />
        </div>
        <div className="content">
          <div className="item">
            <InputNumber min={0} max={100} defaultValue={value} onChange={this.handleChangeAssets} />
          </div>
        </div>
      </>
    )
  }
}

ProgressComponent.defaultProps = {
  prefixPath: [],
  onChange: noop,
  onAssetsChange: noop
}

export default ProgressComponent
