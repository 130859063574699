export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.IMG, {
      x: {{ x }},
      y: {{ y }},
      {{ #width }}
      w: {{ width }},
      {{ /width }}
      {{ #height }}
      h: {{ height }},
      {{ /height }}
      {{ #src }}
      src: '{{{ src }}}',
      {{ /src }}
      {{ #type }}
      type: {{ type }},
      {{ /type }}
      show_level: {{ showLevel }},
    });
`
