import Immutable from 'immutable'
import React from 'react' // eslint-disable-line
import dayjs from 'dayjs'
import { JumpHotAreaComponent } from '../../components'
import AlarmClock from '../../modules/AlarmClock'
import AnalogClock from '../../modules/AnalogClock'
import Background from '../../modules/Background'
import Date from '../../modules/Date'
import DigitalClock from '../../modules/DigitalClock'
import Distance from '../../modules/Distance'
import FrameAnimation from '../../modules/FrameAnimation'
import GraphClock from '../../modules/GraphClock'
import Pai from '../../modules/Pai'
import ProgressClock from '../../modules/ProgressClock'
import SameAsBattery from '../../modules/SameAsBattery'
import Scale from '../../modules/Scale'
import Step from '../../modules/Step'
import Sun from '../../modules/Sun'
import System from '../../modules/System'
import Temperature from '../../modules/Temperature'
import Weather from '../../modules/Weather'
import Week from '../../modules/Week'

const componentMap = (dialType) => ({
  idle_background: {
    cls: Background,
    instance: <Background />
  },
  background: {
    cls: Background,
    instance: <Background />
  },
  scale: {
    cls: Scale,
    instance: <Scale />
  },
  analog_clock: {
    cls: AnalogClock,
    instance: <AnalogClock />,
    constMap: Immutable.fromJS({
      hour: { name: 'hour_pointer', recommendPreviewValue: 10 },
      minute: { name: 'min_pointer', recommendPreviewValue: 9 },
      second: { name: 'sec_pointer', recommendPreviewValue: 36 }
    })
  },
  graph_clock: {
    cls: GraphClock,
    instance: <GraphClock />
  },
  digital_clock: {
    cls: DigitalClock,
    // 数据组件配置
    constMap: Immutable.fromJS({
      hour: {
        name: 'hour_pointer',
        valueCount: 2,
        before: [],
        after: ['minute', 'second'],
        previewMaxValue: 23,
        recommendPreviewValue: 10,
      },
      minute: {
        name: 'min_pointer',
        valueCount: 2,
        before: ['hour'],
        after: ['second'],
        previewMaxValue: 59,
        recommendPreviewValue: 9,
      },
      second: {
        name: 'sec_pointer',
        valueCount: 2,
        before: ['minute', 'hour'],
        after: [],
        previewMaxValue: 59,
        recommendPreviewValue: 36,
      }
    }),
    // 子组件 props 配置
    propMap: {
      hour: {
        // 是否有矩形区域
        hasRectArea: true,
        hasPaddingZero: true
      },
      minute: {
        hasRectArea: true,
        hasPaddingZero: true
      },
      second: {
        hasRectArea: true,
        hasPaddingZero: true
      }
    },
    instance: <DigitalClock />
  },
  alarm_clock: {
    cls: AlarmClock,
    // 数据组件配置
    constMap: Immutable.fromJS({
      hour: {
        name: 'hour_pointer',
        valueCount: 2,
        before: [],
        after: ['minute', 'second'],
        previewMaxValue: 23
      },
      minute: {
        name: 'min_pointer',
        valueCount: 2,
        before: ['hour'],
        after: ['second'],
        previewMaxValue: 59
      }
    }),
    propMap: {
      hour: {
        // 是否有矩形区域
        hasRectArea: true,
        hasPaddingZero: true
      },
      minute: {
        hasRectArea: true,
        hasPaddingZero: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <AlarmClock />
  },
  progress_clock: {
    cls: ProgressClock,
    instance: <ProgressClock />
  },
  battery: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        recommendPreviewValue: 75,
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hasFollowIcon: true,
        hideSeparatorImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <SameAsBattery />
  },
  heart_rate: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 3,
        before: [],
        after: [],
        invalidImageRequired: true,
        recommendPreviewValue: 86,
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hasFollowIcon: true,
        hideSeparatorImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <SameAsBattery />
  },
  calorie: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      current: {
        name: 'data',
        valueCount: 4,
        before: [],
        after: [],
        recommendPreviewValue: 280,
      }
    }),
    propMap: {
      current: {
        hasRectArea: true,
        hideSeparatorImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <SameAsBattery />
  },
  stand: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 2,
        before: [],
        after: [],
        previewMaxValue: 24
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hideSeparatorImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <SameAsBattery />
  },
  pai: {
    cls: Pai,
    constMap: Immutable.fromJS({
      weekly: {
        name: 'week_pai',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 75 * 7
      }
    }),
    propMap: {
      weekly: {
        hasRectArea: true,
        hiddenCombin: true,
        hideSeparatorImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <Pai />
  },
  aqi: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'air_quality',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 500,
        invalidImageRequired: true,
        recommendPreviewValue: 26,
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hasFollowIcon: true,
        hideSeparatorImage: true
      },
      image_progress: {
        imageProgressCount: 6
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <SameAsBattery />
  },
  humidity: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'humidity',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        invalidImageRequired: true,
        recommendPreviewValue: 42,
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hasFollowIcon: true,
        hideSeparatorImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <SameAsBattery />
  },
  uvi: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'uv_degree',
        valueCount: 2,
        before: [],
        after: [],
        previewMaxValue: 10,
        invalidImageRequired: true,
        recommendPreviewValue: 4,
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hasFollowIcon: true,
        hideSeparatorImage: true
      },
      image_progress: {
        imageProgressCount: 5
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <SameAsBattery />
  },
  step: {
    cls: Step,
    constMap: Immutable.fromJS({
      current: {
        name: 'data',
        valueCount: 5,
        before: [],
        after: [],
        recommendPreviewValue: 8670,
      },
      target: {
        name: 'target_steps',
        valueCount: 5,
        before: [],
        after: []
      }
    }),
    propMap: {
      current: {
        hasRectArea: true,
        hasFollowIcon: true,
        hideSeparatorImage: true
      },
      target: {
        hasRectArea: true,
        hideSeparatorImage: true,
        hideUnitImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <Step />
  },
  distance: {
    cls: Distance,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 4,
        stepCount: 2,
        before: [],
        after: []
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hideSeparatorImage: true,
        hiddenCombin: true,
        hasPointImage: true,
        hasInvalidImage: true,
        unitConfig: {
          labels: ['unit_image_km', 'unit_image_mi'],
          languages: [[{ label: '', lang: 'all' }], [{ label: '', lang: 'all' }]],
          fields: ['unit_images', 'imperial_unit_images']
        }
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <Distance />
  },
  system: {
    cls: System,
    instance: <System />
  },
  date: {
    cls: Date,
    constMap: Immutable.fromJS({
      year: {
        name: 'year',
        valueCount: 4,
        before: [],
        after: ['month', 'day'],
        recommendPreviewValue: dayjs().year(),
      },
      month: {
        name: 'month',
        valueCount: 2,
        before: dialType === 'idle' ? [] : ['year'],
        after: ['day'],
        supportImage: dialType !== 'idle',
        previewMaxValue: 12,
        previewSuffix: 'month',
        recommendPreviewValue: 2,
      },
      day: {
        name: 'day',
        valueCount: 2,
        before: ['month', 'year'],
        after: [],
        previewMaxValue: 31,
        previewSuffix: 'day',
        recommendPreviewValue: 8,
      }
    }),
    propMap: {
      year: {
        hasRectArea: true
      },
      month: {
        hasRectArea: true,
        hasPaddingZero: true
      },
      day: {
        hasRectArea: true,
        hasPaddingZero: true
      }
    },
    instance: <Date />
  },
  week: {
    cls: Week,
    instance: <Week />
  },
  weather: {
    cls: Weather,
    propMap: {
      image_progress: {
        fixedProps: {
          level: 26
        },
        showNamingRule: true
      }
    },
    instance: <Weather />
  },
  temperature: {
    cls: Temperature,
    constMap: Immutable.fromJS({
      current: {
        name: 'real_time_temp',
        valueCount: 3,
        before: [],
        after: [],
        invalidImageRequired: true,
        recommendPreviewValue: 22,
      },
      high: {
        name: 'highest_temp',
        valueCount: 3,
        before: ['low'],
        after: [],
        invalidImageRequired: true,
        recommendPreviewValue: 22,
      },
      low: {
        name: 'lowest_temp',
        valueCount: 3,
        before: [],
        after: ['high'],
        invalidImageRequired: true,
        recommendPreviewValue: 9,
      }
    }),
    propMap: {
      current: {
        hiddenCombin: true,
        hasRectArea: true,
        hideSeparatorImage: true
      },
      high: {
        hasRectArea: true,
        hiddenCombin: true,
        hideSeparatorImage: true
      },
      low: {
        hiddenCombin: true,
        hasRectArea: true,
        hideSeparatorImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <Temperature />
  },
  sunrise: {
    cls: Sun,
    constMap: Immutable.fromJS({
      text: {
        name: 'sunrise',
        invalidImageRequired: true
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hiddenCombin: true,
        hasFollowIcon: true,
        hideSeparatorImage: true,
        hideUnitImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <Sun />
  },
  sunset: {
    cls: Sun,
    constMap: Immutable.fromJS({
      current: {
        name: 'sunset',
        invalidImageRequired: true
      }
    }),
    propMap: {
      text: {
        hasRectArea: true,
        hiddenCombin: true,
        hasFollowIcon: true,
        hideUnitImage: true
      },
      jumpable: {
        showClickImage: false
      }
    },
    instance: <Sun />
  },
  animation: {
    cls: FrameAnimation,
    instance: <FrameAnimation />
  },
  shortcut: {
    cls: JumpHotAreaComponent,
    instance: <JumpHotAreaComponent showImagePosition />
  }
})

export default componentMap
