import Immutable from 'immutable'

const LUNAR = {
  name: 'lunar',
  key: 'lunar',
  icon: 'icon-tuceng-riqi',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const BASIC_IMAGE = {
  name: 'basic_image',
  key: 'image',
  icon: 'icon-tuceng-tupian',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const BASIC_TEXT = {
  name: 'basic_text',
  key: 'text',
  icon: 'icon-tuceng-wenzi',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const MENU = Immutable.fromJS({
  image: BASIC_IMAGE,
  text: BASIC_TEXT,
  idle_background: {
    name: 'background',
    key: 'idle_background',
    icon: 'icon-tuceng-beijing',
    expand: false,
    hide: false,
    order: '',
    canClick: true
  },
  analog_clock: {
    name: 'pointer_time',
    key: 'analog_clock',
    icon: 'icon-tuceng-zhizhenshijian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'hour_pointer',
        key: 'hour',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'min_pointer',
        key: 'minute',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      }
    ]
  },
  digital_clock: {
    name: 'digital_time',
    key: 'digital_clock',
    icon: 'icon-tuceng-shuzishijian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'hr',
        key: 'hour',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'min',
        key: 'minute',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'ampm',
        key: 'ampm',
        icon: 'icon-tuceng-wenzi',
        checked: false
      }
    ]
  },
  // progress_clock: {
  //     name: 'progress_time',
  //     key: 'progress_clock',
  //     icon: 'icon-tuceng-jindushijian',
  //     expand: false,
  //     hide: false,
  //     order: '',
  //     children: [
  //         {
  //             name: 'hr',
  //             key: 'hour',
  //             icon: 'icon-tuceng-jindushijian',
  //             checked: false,
  //         },
  //         {
  //             name: 'min',
  //             key: 'minute',
  //             icon: 'icon-tuceng-jindushijian',
  //             checked: false,
  //         },
  //     ],
  // },
  pai: {
    name: 'week_pai',
    key: 'pai',
    icon: 'icon-tuceng-pai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'weekly',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  pai_daily: {
    name: 'day_pai',
    key: 'pai_daily',
    icon: 'icon-tuceng-pai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'daily',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  stand: {
    name: 'standing_goal',
    key: 'stand',
    icon: 'icon-tuceng-zhanli',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  aqi: {
    name: 'air_quality',
    key: 'aqi',
    icon: 'icon-tuceng-kongqizhiliang',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  humidity: {
    name: 'humidity',
    key: 'humidity',
    icon: 'icon-tuceng-wenjianjiakai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  uvi: {
    name: 'uv_degree',
    key: 'uvi',
    icon: 'icon-tuceng-ziwaixian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  altimeter: {
    name: 'altimeter',
    key: 'altimeter',
    icon: 'icon-tuceng-wenjianjiakai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  battery: {
    name: 'battery',
    key: 'battery',
    icon: 'icon-tuceng-dianliang',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  heart_rate: {
    name: 'heart_rate',
    key: 'heart_rate',
    icon: 'icon-tuceng-xinshuai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  calorie: {
    name: 'calorie',
    key: 'calorie',
    icon: 'icon-tuceng-xiaohao',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  step: {
    name: 'steps',
    key: 'step',
    icon: 'icon-tuceng-bushu',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'real_time_steps',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'target_steps',
        key: 'target',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  distance: {
    name: 'distance',
    key: 'distance',
    icon: 'icon-tuceng-juli',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  weather: {
    name: 'weather',
    key: 'weather',
    icon: 'icon-tuceng-tianqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  moon: {
    name: 'moon',
    key: 'moon',
    icon: 'icon-tuceng-tianqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  temperature: {
    name: 'temperature',
    key: 'temperature',
    icon: 'icon-tuceng-wendu',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'real_time_temp',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'lowest_temp',
        key: 'low',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'highest_temp',
        key: 'high',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      }
    ]
  },
  sun: {
    name: 'sunrise_sunset',
    key: 'sun',
    icon: 'icon-tuceng-wenjian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'current_time',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'sunrise',
        key: 'high',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'sunset',
        key: 'low',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  date: {
    name: 'date',
    key: 'date',
    icon: 'icon-tuceng-riqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'year',
        key: 'year',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'month',
        key: 'month',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'day',
        key: 'day',
        icon: 'icon-tuceng-shuzi',
        checked: false
      }
    ]
  },
  lunar: LUNAR,
  week: {
    name: 'week',
    key: 'week',
    icon: 'icon-tuceng-xingqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'image',
        key: 'text',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      }
      // 星期不支持弧形进度
      // {
      //     name: 'normal_progress',
      //     key: 'progress',
      //     icon: 'icon-tuceng-shiliangjindu',
      //     checked: false,
      // },
    ]
  }

  // message: {
  //     name: 'SMS',
  //     key: 'message',
  //     icon: 'icon-tuceng-duanxin',
  //     expand: false,
  //     hide: false,
  //     order: '',
  //     children: [
  //         {
  //             name: 'image',
  //             key: 'icon',
  //             icon: 'icon-tuceng-tupian',
  //             checked: false,
  //         },
  //         {
  //             name: 'image_progress',
  //             key: 'image_progress',
  //             icon: 'icon-tuceng-tupian',
  //             checked: false,
  //         },
  //         {
  //             name: 'jump_area',
  //             key: 'jumpable',
  //             icon: 'icon-tuceng-requ',
  //             checked: false,
  //         },
  //     ],
  // },
  // phone: {
  //     name: 'phone',
  //     key: 'phone',
  //     icon: 'icon-tuceng-dianhua',
  //     expand: false,
  //     hide: false,
  //     order: '',
  //     children: [
  //         {
  //             name: 'image',
  //             key: 'icon',
  //             icon: 'icon-tuceng-tupian',
  //             checked: false,
  //         },
  //         {
  //             name: 'jump_area',
  //             key: 'jumpable',
  //             icon: 'icon-tuceng-requ',
  //             checked: false,
  //         },
  //     ],
  // },
})

export default MENU
