import { JsonRpcRequestBuilder } from '@huamios-js-framework/json-rpc'
import { uploadDialZip } from '@watchface/request/api'
import apiHost from '@watchface/request/domains/common'
import { message } from 'antd'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { SERIES } from '../../constants'
import { SWITCH_EXPORT_FILE_TYPE, SWITCH_EXPORT_ORIGIN_PKG } from '../../constants/switch'
import { genZabFileName, processImageUrl } from '../../utils'
import analytics from '../../utils/analytics'
import generateFolderData from './convert'
import generateFolder from './folder'
import { encodeMessage, uint8ToArray } from './util'

enum EXPORT_FILE_TYPE {
  ZAB = 'ZAB',
  ZIP = 'ZIP'
}

const loadZipData = async (zip: any, folderObject: any) => {
  let path: string[] = []
  let canvas: any = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const img = new Image()

  img.crossOrigin = 'anonymous'

  const loadImageBlobData = (url: string) => {
    img.src = processImageUrl(url)

    return new Promise((resolve: any, reject: any) => {
      img.onload = () => {
        const { width, height } = img
        canvas.width = width
        canvas.height = height
        ctx.clearRect(0, 0, width, height)
        ctx.drawImage(img, 0, 0)

        canvas.toBlob(resolve, 'image/png')
      }

      img.onerror = (err: any) => {
        console.log(err)
        message.error('Error while processing pictures, please try again')
        reject(err)
      }
    })
  }

  const handleFolderTree = async (directory: any, flag = true) => {
    if (directory.length) {
      for (const item of directory) {
        if (item.directory && item.children.length) {
          path.push(item.name)
          // eslint-disable-next-line no-await-in-loop
          await handleFolderTree(item.children, false)
        } else {
          const { name, mimeType, data } = item
          if (mimeType === 'text/javascript' || mimeType === 'application/json') {
            const blob = new Blob([data], { type: mimeType })
            const file = new File([blob], name)
            const filePath = path ? `${path.join('/')}/${file.name}` : `${file.name}`
            zip.file(filePath, file)
          }
          if (mimeType === 'image/png') {
            // eslint-disable-next-line no-await-in-loop
            const blob: any = await loadImageBlobData(data)
            // eslint-disable-next-line no-await-in-loop
            const file = new File([blob], name)
            const filePath = path ? `${path.join('/')}/${file.name}` : `${file.name}`
            zip.file(filePath, file)
          }
        }
        if (flag) {
          path = []
        }
      }
    }
  }
  await handleFolderTree(folderObject)

  canvas = null
}

const handleZipDataWidthBundle = (arraybuffer: ArrayBuffer, targetName: string, type: string, png2vg: boolean) => {
  const workerCDN = apiHost?.workerLink
  const buildID = 1
  const workerBlob = new Blob([`importScripts('${workerCDN}')`], {
    type: 'application/javascript'
  })
  const workerURL = URL.createObjectURL(workerBlob)
  const worker = new Worker(workerURL)
  const exportOriginPkg = localStorage.getItem(SWITCH_EXPORT_ORIGIN_PKG)

  return new Promise((resolve, reject) => {
    worker.onmessage = (e: MessageEvent) => {
      const { id, result, error } = e.data
      if (id === buildID) {
        if (result !== undefined && !error) {
          return resolve(result)
        }
        if (error) {
          return reject(error)
        }
      }
      if (exportOriginPkg === SWITCH_EXPORT_ORIGIN_PKG && type !== 'bridge' && type !== 'install') {
        saveAs(new Blob([arraybuffer]), 'origin.zip')
      }
      switch (result) {
        case 'ready':
          worker.postMessage(
            new JsonRpcRequestBuilder()
              .id(buildID)
              .method('build')
              .params({
                zipFile: arraybuffer,
                isDevice: false,
                mode: 'production',
                targetName,
                // 模拟器预览不需要做此转换
                png2vg,
                showInfo: true
              })
              .build()
          )
          break
        case 'done':
          break
        default:
          break
      }
    }
  })
}

const getZipForWatch = async (zabBuffer: ArrayBuffer, fileName: string) => {
  const zip = new JSZip()
  const zabData = await JSZip.loadAsync(zabBuffer)
  const { files } = zabData
  const apkFileName = Object.keys(files).filter((filename) => filename.includes('.zpk'))[0]
  const apkBuffer = await files[apkFileName].async('arraybuffer')
  await zip.loadAsync(apkBuffer)
  const zipForWatchArrayBuffer = await zip.file('device.zip')?.async('arraybuffer')

  if (zipForWatchArrayBuffer) {
    saveAs(new Blob([zipForWatchArrayBuffer]), `${fileName}.zip`)
  }
}

const startExportZip = async ({
  config,
  support,
  appId,
  appVersion,
  type,
  deviceInfo,
  socket,
  png2vg = true
}: {
  config: any
  support: any
  appId: string
  appVersion: string
  type: string
  deviceInfo: any
  socket?: any,
  png2vg?: boolean
}) => {
  const startTime = Date.now()

  const convertRes = generateFolderData(config, support, appId, appVersion, deviceInfo)
  const { convertTargetName, convertImgMap, convertAppJson, convertAppName, convertIndexJs } = convertRes
  const folderObject = generateFolder(convertTargetName, convertImgMap, convertAppJson, convertIndexJs)
  const zip = new JSZip()
  await loadZipData(zip, folderObject)

  try {
    const arraybuffer = await zip.generateAsync({ type: 'arraybuffer' })
    const res = (await handleZipDataWidthBundle(arraybuffer, convertTargetName, type, png2vg)) as ArrayBuffer
    if (type === 'simulator') {
      const zipData = await JSZip.loadAsync(res)
      const { files } = zipData
      const zipFileName = Object.keys(files).filter((filename) => filename.includes('.zpk'))[0]
      const zipBuffer = await files[zipFileName].async('arraybuffer')

      const dataArr = uint8ToArray(new Uint8Array(zipBuffer))
      const message = encodeMessage(1, 'ide.simulator.preview', {
        size: zipBuffer.byteLength,
        data: dataArr,
        projectName: convertTargetName || 'undefined_project'
      })
      socket.send(message)
      return 'ok'
    }
    if (type === 'bridge') {
      // 执行上传操作
      const response = await uploadDialZip(new File([res], `${convertAppName}.zab`))
      return response
    }

    const exportFileType = localStorage.getItem(SWITCH_EXPORT_FILE_TYPE)

    if (exportFileType === EXPORT_FILE_TYPE.ZIP) {
      await getZipForWatch(res, convertAppName)

      return 'ok'
    }

    // 默认执行下载操作
    saveAs(new Blob([res]), genZabFileName(convertAppName, appId, appVersion))

    analytics.gtagEvent('export', '成功导出表盘包', {
      status: 'success',
      os_type: SERIES.JS,
      time_spend: (Date.now() - startTime) / 1000
    })

    return 'ok'
  } catch (error) {
    console.log('startExportZip error', error)
    return {}
  }
}

export default startExportZip
