import React from 'react' // eslint-disable-line
import Immutable from 'immutable'
import dayjs from 'dayjs'
import Background from '../../modules/Background'
import Scale from '../../modules/Scale'
import AnalogClock from '../../modules/AnalogClock'
import DigitalClock from '../../modules/DigitalClock'
import ProgressClock from '../../modules/ProgressClock'
import SameAsBattery from '../../modules/SameAsBattery'
import System from '../../modules/System'
import Date from '../../modules/Date'
import Week from '../../modules/Week'
import Step from '../../modules/Step'
import Distance from '../../modules/Distance'
import Weather from '../../modules/Weather'
import Temperature from '../../modules/Temperature'
import Sun from '../../modules/Sun'
import Pai from '../../modules/Pai'
import Phone from '../../modules/Phone'
import Message from '../../modules/Message'
import Editable from '../../modules/Editable'
import Mask from '../../modules/Mask'

const componentMap = () => ({
  idle_background: {
    cls: Background,
    instance: <Background />
  },
  background: {
    cls: Background,
    instance: <Background />
  },
  scale: {
    cls: Scale,
    instance: <Scale />
  },
  analog_clock: {
    cls: AnalogClock,
    constMap: Immutable.fromJS({
      hour: {
        name: 'hour_pointer',
        recommendPreviewValue: 10
      },
      minute: { name: 'min_pointer', recommendPreviewValue: 9 },
      second: { name: 'sec_pointer', recommendPreviewValue: 36 }
    }),
    instance: <AnalogClock />
  },
  digital_clock: {
    cls: DigitalClock,
    // data组件配置
    constMap: Immutable.fromJS({
      hour: {
        name: 'hour_pointer',
        valueCount: 2,
        before: [],
        after: ['minute', 'second'],
        previewMaxValue: 23,
        recommendPreviewValue: 10,
      },
      minute: {
        name: 'min_pointer',
        valueCount: 2,
        before: ['hour'],
        after: ['second'],
        previewMaxValue: 59,
        recommendPreviewValue: 9,
      },
      second: {
        name: 'sec_pointer',
        valueCount: 2,
        before: ['minute', 'hour'],
        after: [],
        previewMaxValue: 59,
        recommendPreviewValue: 36,
      }
    }),
    // 子组件 props 配置
    propMap: {},
    instance: <DigitalClock />
  },
  progress_clock: {
    cls: ProgressClock,
    instance: <ProgressClock />
  },
  battery: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        recommendPreviewValue: 75,
      }
    }),
    propMap: {},
    instance: <SameAsBattery />
  },
  heart_rate: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 3,
        before: [],
        after: [],
        invalidImageRequired: true,
        recommendPreviewValue: 86,
      }
    }),
    propMap: {},
    instance: <SameAsBattery />
  },
  calorie: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      current: {
        name: 'data',
        valueCount: 4,
        before: [],
        after: [],
        recommendPreviewValue: 280,
      }
    }),
    propMap: {},
    instance: <SameAsBattery />
  },
  pai: {
    cls: Pai,
    constMap: Immutable.fromJS({
      weekly: {
        name: 'week_api',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 75 * 7
      }
    }),
    propMap: {},
    instance: <Pai />
  },
  aqi: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'air_quality',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 500,
        invalidImageRequired: true,
        recommendPreviewValue: 26,
      }
    }),
    propMap: {
      image_progress: {
        imageProgressCount: 6
      }
    },
    instance: <SameAsBattery />
  },
  humidity: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'humidity',
        valueCount: 3,
        before: [],
        after: [],
        previewMaxValue: 100,
        invalidImageRequired: true,
        recommendPreviewValue: 42,
      }
    }),
    propMap: {},
    instance: <SameAsBattery />
  },
  uvi: {
    cls: SameAsBattery,
    constMap: Immutable.fromJS({
      text: {
        name: 'uv_degree',
        valueCount: 2,
        before: [],
        after: [],
        previewMaxValue: 10,
        invalidImageRequired: true,
        recommendPreviewValue: 4,
      }
    }),
    propMap: {
      image_progress: {
        imageProgressCount: 5
      }
    },
    instance: <SameAsBattery />
  },
  step: {
    cls: Step,
    constMap: Immutable.fromJS({
      current: {
        name: 'data',
        valueCount: 5,
        before: [],
        after: [],
        recommendPreviewValue: 8670,
      },
      target: {
        name: 'target_steps',
        valueCount: 5,
        before: [],
        after: []
      }
    }),
    propMap: {},
    instance: <Step />
  },
  distance: {
    cls: Distance,
    constMap: Immutable.fromJS({
      text: {
        name: 'data',
        valueCount: 5,
        stepCount: 2,
        before: [],
        after: []
      }
    }),
    propMap: {
      text: {
        hiddenCombin: true,
        hasPointImage: true,
        hasInvalidImage: true,
        hideSeparatorImage: true
      }
    },
    instance: <Distance />
  },
  system: {
    cls: System,
    instance: <System />
  },
  date: {
    cls: Date,
    constMap: Immutable.fromJS({
      year: {
        name: 'year',
        valueCount: 4,
        before: [],
        after: ['month', 'day'],
        recommendPreviewValue: dayjs().year(),
      },
      month: {
        name: 'month',
        valueCount: 2,
        before: ['year'],
        after: ['day'],
        supportImage: true,
        previewMaxValue: 12,
        previewSuffix: 'month',
        recommendPreviewValue: 2,
      },
      day: {
        name: 'day',
        valueCount: 2,
        before: ['month', 'year'],
        after: [],
        previewMaxValue: 31,
        previewSuffix: 'day',
        recommendPreviewValue: 8,
      }
    }),
    propMap: {},
    instance: <Date />
  },
  week: {
    cls: Week,
    instance: <Week />
  },
  weather: {
    cls: Weather,
    propMap: {
      image_progress: {
        fixedProps: {
          level: 29
        },
        showNamingRule: true
      }
    },
    instance: <Weather />
  },
  temperature: {
    cls: Temperature,
    constMap: Immutable.fromJS({
      current: {
        name: 'real_time_temp',
        valueCount: 3,
        before: [],
        after: [],
        invalidImageRequired: true,
        previewMinValue: -999,
        recommendPreviewValue: 22,
      },
      high: {
        name: 'highest_temp',
        valueCount: 3,
        before: ['low'],
        after: [],
        invalidImageRequired: true,
        previewMinValue: -999,
        recommendPreviewValue: 28,
      },
      low: {
        name: 'lowest_temp',
        valueCount: 3,
        before: [],
        after: ['high'],
        invalidImageRequired: true,
        previewMinValue: -999,
        recommendPreviewValue: 9,
      }
    }),
    propMap: {
      current: {
        hiddenCombin: true,
        hidePaddingZero: true,
        hideNegativeImage: false,
        unitImageSource: [
          {
            language: 'all',
            unit: '°'
          }
        ],
      },
      high: {
        hidePaddingZero: true,
        hideNegativeImage: false,
        unitImageSource: [
          {
            language: 'all',
            unit: '°',
          }
        ],
      },
      low: {
        hiddenCombin: true,
        hidePaddingZero: true,
        hideNegativeImage: false,
        unitImageSource: [
          {
            language: 'all',
            unit: '°'
          }
        ],
      }
    },
    instance: <Temperature />
  },
  sun: {
    cls: Sun,
    constMap: Immutable.fromJS({
      current: {
        valueCount: 5,
        name: 'current_time',
        invalidImageRequired: true
      },
      high: {
        valueCount: 5,
        name: 'sunrise',
        invalidImageRequired: true
      },
      low: {
        valueCount: 5,
        name: 'sunset',
        invalidImageRequired: true
      }
    }),
    propMap: {
      current: {
        hiddenCombin: true,
        hasPointImage: true,
        pointImageText: 'colon_image',
        isPreviewTime: true
      },
      high: {
        hiddenCombin: true,
        hasPointImage: true,
        pointImageText: 'colon_image',
        isPreviewTime: true
      },
      low: {
        hiddenCombin: true,
        hasPointImage: true,
        pointImageText: 'colon_image',
        isPreviewTime: true
      },
      image_progress: {
        fixedProps: {
          level: 2,
          is_continuance: true
        }
      }
    },
    instance: <Sun />
  },
  message: {
    cls: Message,
    instance: <Message />
  },
  phone: {
    cls: Phone,
    instance: <Phone />
  },
  component: {
    cls: Editable,
    instance: <Editable />
  },
  mask: {
    cls: Mask,
    instance: <Mask />
  }
})

export default componentMap
