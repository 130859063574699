import Immutable from 'immutable'

const MENU = Immutable.fromJS({
  digital_clock: {
    name: 'digital_time',
    key: 'digital_clock',
    icon: 'icon-tuceng-shuzishijian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'hr',
        key: 'hour',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'min',
        key: 'minute',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'ampm',
        key: 'ampm',
        icon: 'icon-tuceng-wenzi',
        checked: false
      }
    ]
  },
  analog_clock: {
    name: 'pointer_clock',
    key: 'analog_clock',
    icon: 'icon-tuceng-zhizhenshijian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'hour_pointer',
        key: 'hour',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'min_pointer',
        key: 'minute',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      }
    ]
  },
  date: {
    name: 'date',
    key: 'date',
    icon: 'icon-tuceng-riqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'month',
        key: 'month',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'day',
        key: 'day',
        icon: 'icon-tuceng-shuzi',
        checked: false
      }
    ]
  },
  week: {
    name: 'week',
    key: 'week',
    icon: 'icon-tuceng-xingqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'icon',
        key: 'text',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  step: {
    name: 'steps',
    key: 'step',
    icon: 'icon-tuceng-bushu',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'real_time_steps',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      }
    ]
  }
})

export default MENU
