const config = {
  step: 'hmUI.edit_type.STEP',
  battery: 'hmUI.edit_type.BATTERY',
  heart_rate: 'hmUI.edit_type.HEART',
  calorie: 'hmUI.edit_type.CAL',
  // pai: 'hmUI.edit_type.PAI_WEEKLY',
  pai_daily: 'hmUI.edit_type.PAI_DAILY',
  distance: 'hmUI.edit_type.DISTANCE',
  aqi: 'hmUI.edit_type.AQI',
  humidity: 'hmUI.edit_type.HUMIDITY',
  altimeter: 'hmUI.edit_type.ALTIMETER',
  stress: 'hmUI.data_type.STRESS',
  wind: 'hmUI.edit_type.WIND',
  spo2: 'hmUI.edit_type.SPO2',
  training_load: 'hmUI.edit_type.TRAINING_LOAD',
  vo2max: 'hmUI.edit_type.VO2MAX',
  uvi: 'hmUI.edit_type.UVI',
  date: 'hmUI.edit_type.DATE',
  week: 'hmUI.edit_type.WEEK',
  weather: 'hmUI.edit_type.WEATHER',
  temperature: 'hmUI.edit_type.TEMPERATURE',
  sun: 'hmUI.edit_type.SUN'
}

export default config
