/* eslint-disable import/order */
import React, { PureComponent } from 'react'
import { Select } from 'antd'
import Immutable from 'immutable'
import PubSub from 'pubsub-js'
import { compact } from 'lodash'
import { immutableSelector } from '@/utils'
import { SELECT_STYLE } from '@watchface/constants/style'
import { InputNumber } from '@watchface/components'
import Upload from '@watchface/components/Upload'
import { renderCells } from '@watchface/utils/render'
import T from '@watchface/components/I18n'
import { MOVE_IMAGE } from '@watchface/constants'
import { isHitKey } from '@watchface/utils'

const { Option } = Select
const noop = () => {} // eslint-disable-line

class AmPm extends PureComponent {
  static keyMap = {}

  componentDidMount() {
    const { parentKey, childKey, editableComponentKey = '', dialType, assets } = this.props

    PubSub.subscribe(MOVE_IMAGE, (eventName, { key, left, top }) => {
      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return
      const action = assets.get('show')?.startsWith('pm') ? 'pm' : 'am'
      this.handleAMPMChange('', action, {
        x: left,
        y: top
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props

    if (hidden !== true && prevProps !== this.props) {
      AmPm.renderLayer(this.props)
    }
  }

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: AmPm,
      ...param
    })
    AmPm.keyMap = Object.assign(AmPm.keyMap, keyMap)
  }

  static getCellDatas = ({ parentKey, allData, dialType, allAssets, paddingX, renderLayerByLang }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const image = AmPm.getImage({ parentKey, allData, allAssets, dialType, paddingX, renderLayerByLang })
    if (image) {
      cells.imageEntity[image.key] = image
    }
    return cells
  }

  static getImage = ({ parentKey, allData, allAssets, dialType, paddingX = 0, renderLayerByLang }) => {
    const imagePathMap = {
      am_zh: [parentKey, 'children', 'am', 'images', 0, 'image'],
      am_en: [parentKey, 'children', 'am', 'images', 1, 'image'],
      pm_zh: [parentKey, 'children', 'pm', 'images', 0, 'image'],
      pm_en: [parentKey, 'children', 'pm', 'images', 1, 'image']
    }
    const positionPathMap = {
      am_zh: [parentKey, 'children', 'am', 'position'],
      am_en: [parentKey, 'children', 'am', 'position'],
      pm_zh: [parentKey, 'children', 'pm', 'position'],
      pm_en: [parentKey, 'children', 'pm', 'position']
    }
    const assets = allAssets.getIn([dialType, parentKey, 'children', 'ampm']) || Immutable.Map()
    const ampmLang = renderLayerByLang || assets.get('ampmLang') || 'all'
    const defaultShow = (ampmLang === 'en' || ampmLang === 'all') ? 'am_en' : 'am_zh'
    const show = renderLayerByLang ? defaultShow : (assets.get('show') || defaultShow)

    if (!show || show === 'none') {
      return null
    }
    const image = allData.getIn(imagePathMap[show]) || Immutable.Map()
    const position = allData.getIn(positionPathMap[show]) || Immutable.Map()
    const { url, width, height } = immutableSelector(image)
    const { x = 0, y = 0 } = immutableSelector(position)
    // const { follow_time } = immutableSelector(allData.get(parentKey))

    // 暂不支持跟随，支持时需重新审查代码
    // if (follow_time) {
    // const allImageArr = DigitalClock.getAllImageArr({ allData, allAssets, parentKey, constMap, hasRectArea })
    // console.log('imageArrimageArrimageArr', allImageArr.toJS())
    // let lastXImage = Immutable.Map()
    // let lastX = 0
    // allImageArr.forEach((item) => {
    //   const { isDelete } = immutableSelector(item)
    //   if (!isDelete && item.get('x') > lastX) {
    //     lastXImage = item
    //   }
    // })

    // const {
    //   x: lastImageX,
    //   y: lastImageY,
    //   width: lastImageWidth,
    // } = immutableSelector(lastXImage)
    // return Immutable.fromJS({
    //   key: `${parentKey}-ampm`,
    //   url,
    //   x: lastImageX + lastImageWidth,
    //   y: lastImageY,
    //   width,
    //   height
    // })
    // }
    if (!url) return null
    return {
      key: 'ampm',
      texture: url,
      left: x + paddingX,
      top: y,
      width,
      height
    }
  }

  static getWidthHeight = ({ allData, parentKey }) => {
    const imagePathArr = Immutable.fromJS([
      [parentKey, 'children', 'am', 'images', 0, 'image'],
      [parentKey, 'children', 'am', 'images', 1, 'image'],
      [parentKey, 'children', 'pm', 'images', 0, 'image'],
      [parentKey, 'children', 'pm', 'images', 1, 'image']
    ])

    let width = 0
    let height = 0
    for (let i = 0; i < imagePathArr.size; i += 1) {
      const path = imagePathArr.get(i)
      const item = allData.getIn(path)
      const { width: w, height: h } = immutableSelector(item)
      if (w && h) {
        width = w
        height = h
        break
      }
    }

    return {
      width,
      height
    }
  }

  handlePMFollowChange = (value) => {
    const { parentKey, allData, onChange, prefixPath } = this.props
    const newData = allData.setIn([parentKey, 'follow_time'], !!value)
    onChange([...prefixPath], newData)
  }

  handleAMPMChange = (prop, action, value) => {
    const { parentKey, allData, onChange = noop, prefixPath } = this.props
    const newData = allData.setIn([parentKey, ...compact(['children', action, 'position', prop])], value)

    onChange([...prefixPath], newData)
  }

  handleInsertAmPmImage = (path, img) => {
    const { parentKey, allData, onChange = noop, prefixPath } = this.props
    let newData = allData
    if (img) {
      const { width, height, image } = img
      newData = newData.setIn([parentKey, 'children', ...path], Immutable.fromJS({ url: image, width, height }))
    } else {
      newData = newData.setIn([parentKey, 'children', ...path], Immutable.fromJS({ url: '', width: 0, height: 0 }))
    }
    onChange([...prefixPath], newData)
  }

  handleAssetsChange = (value) => {
    const { onAssetsChange, parentKey, dialType, allAssets } = this.props
    const newAllAssets = allAssets.setIn([dialType, parentKey, 'children', 'ampm', 'show'], value)

    onAssetsChange([], newAllAssets)
  }

  handleChangeCallBack = ({ allData, allAssets, constMap }) => {
    const { parentKey, dialType, isExist, onUpdateAttr, assets } = this.props
    const image = AmPm.getAMPMImage({ parentKey, allData, assets, allAssets, dialType, constMap })
    const { x, y, key } = immutableSelector(image)

    if (isExist(key)) {
      onUpdateAttr(key, { x, y })
    }
  }

  handleLangChange = (lang) => {
    const { allData, parentKey, dialType, allAssets, onChange, onAssetsChange, prefixPath, hidden } = this.props
    const amPath = (index) => [parentKey, 'children', 'am', 'images', index, 'image']
    const pmPath = (index) => [parentKey, 'children', 'pm', 'images', index, 'image']
    let newAllData = allData || Immutable.Map()

    newAllData = newAllData
      .setIn(amPath(0), Immutable.Map())
      .setIn(amPath(1), Immutable.Map())
      .setIn(pmPath(0), Immutable.Map())
      .setIn(pmPath(1), Immutable.Map())

    onChange([...prefixPath], newAllData)

    if (!hidden) {
      const path = [dialType, parentKey, 'children', 'ampm']
      const newAllAssets = allAssets.setIn([...path, 'ampmLang'], lang).setIn([...path, 'show'], '')
      onAssetsChange([], newAllAssets)
    }
  }

  render() {
    const { allData, parentKey, assets, watchConfig, renderLayerByLang } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const data = allData.get(parentKey) || Immutable.Map()
    const { follow_time } = immutableSelector(data)
    const { am, pm } = immutableSelector(data.get('children'))
    const { position: pmPosition, images: pmImages = Immutable.List() } = immutableSelector(pm)
    const { position: amPosition, images: amImages = Immutable.List() } = immutableSelector(am)
    const { x: pmX, y: pmY } = immutableSelector(pmPosition)
    const { x: amX, y: amY } = immutableSelector(amPosition)
    const zhPmImageUrl = pmImages.getIn([0, 'image', 'url'])
    const enPmImageUrl = pmImages.getIn([1, 'image', 'url'])
    const zhAmImageUrl = amImages.getIn([0, 'image', 'url'])
    const enAmImageUrl = amImages.getIn([1, 'image', 'url'])
    const ampmLang = renderLayerByLang || assets.get('ampmLang') || 'all'
    const { width, height } = AmPm.getWidthHeight({ allData, parentKey })
    const strictSize = width && height ? { width, height } : undefined
    const showZh = ampmLang === 'all' || ampmLang === 'zh'
    const showEn = ampmLang === 'all' || ampmLang === 'en'
    const defaultShow = ampmLang === 'en' ? 'am_en' : 'am_zh'
    const show = renderLayerByLang ? defaultShow : (assets.get('show') || defaultShow)

    return (
      <>
        <div className="name">
          <T id="primary_secondary_relationship" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={follow_time ? 1 : 0}
              onChange={this.handlePMFollowChange}
            >
              <Option value={0}>
                <T id="independent" />
              </Option>
              <Option value={1} disabled>
                <T id="follow" />
              </Option>
            </Select>
          </div>
        </div>
        <div className="name is-required">
          <T id="am_pm" />
        </div>
        <div className="content">
          <div className="item">
            <T id="language" />
          </div>
          <Select dropdownClassName="watch-skin-select-dropdown" style={SELECT_STYLE} value={ampmLang} onChange={this.handleLangChange}>
            <Option value="all">
              <T id="zh_en" />
            </Option>
            <Option value="zh">
              <T id="zh" />
            </Option>
            <Option value="en">
              <T id="en" />
            </Option>
          </Select>
        </div>
        <div className="content">
          {showZh && (
            <div className="item">
              <div className="col col-v">
                <Upload
                  strictSize={strictSize}
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={zhAmImageUrl ? [{ image: zhAmImageUrl }] : []}
                  onChange={(image) => {
                    this.handleInsertAmPmImage(['am', 'images', 0, 'image'], image)
                  }}
                />
                <T id="am" />
              </div>
              <div className="col col-v">
                <Upload
                  strictSize={strictSize}
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={zhPmImageUrl ? [{ image: zhPmImageUrl }] : []}
                  onChange={(image) => {
                    this.handleInsertAmPmImage(['pm', 'images', 0, 'image'], image)
                  }}
                />
                <T id="pm" />
              </div>
            </div>
          )}
          {showEn && (
            <div className="item">
              <div className="col col-v">
                <Upload
                  strictSize={strictSize}
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={enAmImageUrl ? [{ image: enAmImageUrl }] : []}
                  onChange={(image) => {
                    this.handleInsertAmPmImage(['am', 'images', 1, 'image'], image)
                  }}
                />
                AM
              </div>
              <div className="col col-v">
                <Upload
                  strictSize={strictSize}
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={enPmImageUrl ? [{ image: enPmImageUrl }] : []}
                  onChange={(image) => {
                    this.handleInsertAmPmImage(['pm', 'images', 1, 'image'], image)
                  }}
                />
                PM
              </div>
            </div>
          )}
        </div>
        {!follow_time ? (
          <>
            <div className="name">
              <T id="position" />
            </div>
            <div className="content">
              <div className="item">
                <T id="am_coordinates" />
              </div>
              <div className="item">
                <div className="col">
                  <div className="key">x</div>
                  <InputNumber
                    min={0}
                    max={screenWidth}
                    onChange={(value) => {
                      this.handleAMPMChange('x', 'am', value)
                    }}
                    value={amX}
                  />
                </div>
                <div className="col">
                  <div className="key">y</div>
                  <InputNumber
                    min={0}
                    max={screenHeight}
                    onChange={(value) => {
                      this.handleAMPMChange('y', 'am', value)
                    }}
                    value={amY}
                  />
                </div>
              </div>
              <div className="item">
                <T id="pm_coordinates" />
              </div>
              <div className="item">
                <div className="col">
                  <div className="key">x</div>
                  <InputNumber
                    min={0}
                    max={screenWidth}
                    onChange={(value) => {
                      this.handleAMPMChange('x', 'pm', value)
                    }}
                    value={pmX}
                  />
                </div>
                <div className="col">
                  <div className="key">y</div>
                  <InputNumber
                    min={0}
                    max={screenHeight}
                    onChange={(value) => {
                      this.handleAMPMChange('y', 'pm', value)
                    }}
                    value={pmY}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className="name">
          <T id="am_pm_preview" />
        </div>
        <div className="content">
          <div className="item">
            <Select dropdownClassName="watch-skin-select-dropdown" style={SELECT_STYLE} value={show} onChange={this.handleAssetsChange}>
              {showZh ? (
                <Option value="am_zh">
                  <T id="am" />
                </Option>
              ) : null}
              {showZh ? (
                <Option value="pm_zh">
                  <T id="pm" />
                </Option>
              ) : null}
              {showEn ? <Option value="am_en">AM</Option> : null}
              {showEn ? <Option value="pm_en">PM</Option> : null}
              <Option value="none">
                <T id="hide" />
              </Option>
            </Select>
          </div>
        </div>
      </>
    )
  }
}

AmPm.defaultProps = {
  prefixPath: []
}

export default AmPm
