import T from '@watchface/components/I18n'
import React from 'react'

const NAME_LANG_MAP: any = {
  en: {
    label: <T id="s_lang_en" />,
    field: 'en'
  },
  zh: {
    label: <T id="s_lang_zh" />,
    field: 'zh'
  },
  'zh-Hant': {
    label: <T id="s_lang_zh-Hant" />,
    field: 'zh-Hant'
  }
}

const PIC_LANG_MAPPER: any = {
  all: {
    label: (
      <>
        <T id="s_lang_all" />
        <T id="thumbnail" />
      </>
    ),
    field: 'all'
  },
  zh: {
    label: (
      <>
        <T id="s_lang_zh" />
        <T id="thumbnail" />
      </>
    ),
    field: 'zh'
  },
  'zh-Hant': {
    label: (
      <>
        <T id="s_lang_zh-Hant" />
        <T id="thumbnail" />
      </>
    ),
    field: 'zh-Hant'
  },
  sc: {
    label: (
      <>
        <T id="s_lang_zh" />
        <T id="thumbnail" />
      </>
    ),
    field: 'zh'
  },
  en: {
    label: (
      <>
        <T id="s_lang_en" />
        <T id="thumbnail" />
      </>
    ),
    field: 'en'
  }
}

export { NAME_LANG_MAP, PIC_LANG_MAPPER }
