import React, { useState, useEffect, useCallback } from 'react'
import { Button, Modal } from 'antd'
import Immutable from 'immutable'
import JSZip from 'jszip'
import { noop } from 'lodash'
import { saveAs } from 'file-saver'
import { getMarketStrapImage } from '@watchface/request/api'
import T from '../../I18n'
import ModalContent from './ModalContent'

import './index.scss'
import { getCroppedImg } from '../../Upload/canvasUtils'

interface IProps {
  dialType: string
  visible: boolean
  sizes: string[]
  languages: string[]
  allData: any
  shape: string
  radius: number
  handleInitRender: (data: any, renderLayerByLang?: any) => void
  onClose: () => void
  getI18nMessage?: any
}

const ExportImageModal: React.FC<IProps> = (props) => {
  const {
    visible = false,
    sizes = [],
    languages = [],
    allData = Immutable.Map(),
    dialType = 'normal',
    shape,
    radius,
    handleInitRender = noop,
    onClose = noop,
    getI18nMessage
  } = props
  const [showWatchStrap, setShowWatchStrap] = useState(false)
  const [selectedSizes, setSelectedSizes] = useState<string[]>([])
  const [deviceSource, setDeviceSource] = useState<string>('')
  const [lang, setLang] = useState('')
  const defaultSize = sizes?.[0]
  const defaultLang = languages?.[0]
  const crtTypeData = allData.get(dialType)
  const deviceSources = allData.getIn(['support', 'device_source']) || Immutable.List()

  useEffect(() => {
    defaultSize && setSelectedSizes([defaultSize])
  }, [defaultSize])

  useEffect(() => {
    defaultLang && setLang(defaultLang)
  }, [defaultLang])

  const changeData = useCallback((key: string, value: any) => {
    if (key === 'selectedSizes') {
      setSelectedSizes(value)
    }
    if (key === 'lang') {
      setLang(value)
    }
    if (key === 'showWatchStrap') {
      setShowWatchStrap(value)
    }
    if (key === 'deviceSource') {
      setDeviceSource(value)
    }
  }, [])

  const loadImage = async (src: string): Promise<any> => {
    const image = new Image()

    image.src = src.startsWith('http') ? `${src}?timestamp=${Date.now()}` : src
    image.crossOrigin = 'anonymous'

    return new Promise((resolve: any) => {
      image.onload = () => {
        resolve(image)
      }
      image.onerror = () => {
        resolve(null)
      }
    })
  }

  const getWFCanvasImage = (width: number, height: number, imageType = 'image/png') => {
    const c = document.createElement('canvas')
    c.width = width
    c.height = height

    const ctx = c.getContext('2d')

    const dataUrl = (document.querySelector('#design-tool-canvas') as HTMLCanvasElement)?.toDataURL(imageType)

    return new Promise((resolve: any, reject: any) => {
      ;(async () => {
        try {
          const previewImage = await loadImage(dataUrl)

          if (!previewImage) return reject(new Error('Load preview image error'))

          if (showWatchStrap && deviceSource) {
            const { device_image: deviceImage } = await getMarketStrapImage(deviceSource as any)
            const image = await loadImage(deviceImage)

            if (!image) return reject(new Error('Load device image error'))

            const { width: deviceImageWidth, height: deviceImageHeight } = image

            c.width = deviceImageWidth
            c.height = deviceImageHeight

            ctx?.drawImage(image, 0, 0, deviceImageWidth, deviceImageHeight)

            const x = deviceImageWidth / 2 - width / 2
            const y = deviceImageHeight / 2 - height / 2

            ctx?.drawImage(previewImage, x, y, width, height)
          } else {
            ctx?.drawImage(previewImage, 0, 0, width, height)
          }

          const newDataUrl = c.toDataURL()

          getCroppedImg(
            newDataUrl,
            { width, height, x: 0, y: 0 },
            { width, height },
            shape,
            radius
          ).then((blob: Blob) => {
            resolve(blob)
          })
        } catch (e) {
          reject(e)
        }
      })()
    })
  }

  const handleImage = (size: string) => {
    if (!size || typeof size !== 'string') return {}

    const [w, h] = size.trim().split('*')
    return getWFCanvasImage(Number(w), Number(h))
      .then((blob: any) => {
        return {
          name: `${w}x${h}_${lang}.png`,
          blob
        }
      })
      .catch(console.error)
  }

  const handleExportImage = () => {
    handleInitRender(crtTypeData, lang)

    setTimeout(() => {
      const imgList = selectedSizes.map((size: string) => handleImage(size))

      Promise.all(imgList)
        .then((res: any) => {
          const zip = new JSZip()
          res.forEach((item: any) => item.blob && zip.file(item.name, item.blob))

          zip
            .generateAsync({ type: 'blob' })
            .then((content: Blob) => {
              saveAs(content, `${getI18nMessage('preview_image') || 'preview_image'}.zip`)
              onClose()
            })
            .catch(console.error)
        })
        .catch(console.error)
        .finally(() => {
          handleInitRender(crtTypeData)
        })
    }, 1500)
  }

  return (
    <Modal
      title={<T id="export_image" />}
      closeIcon={null}
      centered
      closable={false}
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onClose}>
          <T id="cancel" />
        </Button>,
        <Button key="execute" type="primary" disabled={!selectedSizes.length} onClick={handleExportImage}>
          <T id="confirm" />
        </Button>
      ]}
      className="watch-dial-modal export-canvas2image"
    >
      <ModalContent
        setData={changeData}
        sizes={sizes}
        selectedSizes={selectedSizes}
        shape={shape}
        languages={languages}
        deviceSources={deviceSources.toJS()}
      />
    </Modal>
  )
}

export default ExportImageModal
