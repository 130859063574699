import { Col, Divider, InputNumber, Row } from 'antd'
import * as React from 'react'

interface Props {
  properties: {
    value: { value: number }
    min: { value: number }
    max: { value: number }
  }
  onChange: (category: string, property: string, value: string | number | undefined | null) => void
}

const Range: React.FC<Props> = (props) => {
  const {
    properties: {
      value: { value },
      min: { value: min },
      max: { value: max }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-range">
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={2}>m</Col>
        <Col span={10}>
          <InputNumber
            min={0}
            max={100}
            defaultValue={min}
            onChange={(value) => {
              onChange('range', 'min', value)
            }}
          />
        </Col>
        <Col span={2}>M</Col>
        <Col span={10}>
          <InputNumber
            min={0}
            max={100}
            defaultValue={max}
            onChange={(value) => {
              onChange('range', 'max', value)
            }}
          />
        </Col>
      </Row>
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={2}>R</Col>
        <Col span={22}>
          <InputNumber
            min={min}
            max={max}
            defaultValue={value}
            onChange={(value) => {
              onChange('range', 'value', value)
            }}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Range
