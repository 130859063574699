module.exports = (function () {
    return `
      {{#eventName}}
      {{#timerHandle}}
      {{{.}}}
      {{/timerHandle}}
      timer.createTimer(0, 1000, (function ({{ option }}) {
        {{#timerHandle}}
        {{{.}}}
        {{/timerHandle}}
      }), {{ option }})
      {{/eventName}}
    `
})()