import T from '@watchface/components/I18n'
import Classnames from 'classnames'
import React, { PureComponent } from 'react'
import {
  IconComponent, JumpHotAreaComponent, NumberComponent,
  NumberComponentWithRect, PictureProgress, PointerProgressComponent,
  ProgressComponent
} from '../../components'

const map = (hasRectArea) => ({
  current: hasRectArea ? NumberComponentWithRect : NumberComponent,
  text: hasRectArea ? NumberComponentWithRect : NumberComponent,
  icon: IconComponent,
  pointer_progress: PointerProgressComponent,
  progress: ProgressComponent,
  image_progress: PictureProgress,
  jumpable: JumpHotAreaComponent
})
class SameAsBattery extends PureComponent {
  static renderLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const Component = map(hasRectArea)[childKey]
    if (Component && Component.renderLayer) {
      Component.renderLayer({ childKey, ...restArgs })
    }
  }

  static removeLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const Component = map(hasRectArea)[childKey]

    if (Component && Component.removeLayer) {
      Component.removeLayer({ childKey, ...restArgs })
    }
  }

  renderNumber = (allProps) => {
    const { hasRectArea } = allProps || {}
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent

    return <NumComponent hiddenCombin {...allProps} />
  }

  renderIcon = () => {
    return <IconComponent {...this.props} />
  }

  renderPointerProgress = () => {
    return <PointerProgressComponent {...this.props} />
  }

  renderProgress = (allProps) => {
    return <ProgressComponent {...allProps} title={<T id="normal_progress" />} />
  }

  renderImageProgress = (allProps) => {
    const { imageProgressCount } = allProps
    const fixedProps = {}

    if (imageProgressCount) {
      fixedProps.level = imageProgressCount
    }

    return <PictureProgress fixedProps={fixedProps} {...allProps} />
  }

  renderJumpHotArea = (allProps) => {
    return <JumpHotAreaComponent {...allProps} />
  }

  render() {
    const cls = Classnames(['battery-container', 'control-container'])
    const { parentKey, propMap = {}, childKey } = this.props
    const props = propMap[childKey] || {}
    const allProps = { ...this.props, ...props }

    const map = {
      current: this.renderNumber,
      text: this.renderNumber,
      icon: this.renderIcon,
      pointer_progress: this.renderPointerProgress,
      progress: this.renderProgress,
      image_progress: this.renderImageProgress,
      jumpable: this.renderJumpHotArea
    }
    return (
      <div key={parentKey} className={cls}>
        {map[childKey](allProps)}
      </div>
    )
  }
}

export default SameAsBattery
