const type1 = ['454*454', '416*416', '360*360']

const type2 = ['306*354', '302*320', '348*442']

function createDeviceMap(devices) {
  const result = {}
  devices.forEach((device) => {
    result[device] = devices.filter((item) => item !== device)
  })
  return result
}

export default {
  ...createDeviceMap(type1),
  ...createDeviceMap(type2)
}
