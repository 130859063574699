import Immutable from 'immutable'

const BACKGROUND = {
  name: 'background',
  key: 'background',
  icon: 'icon-tuceng-beijing',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const BASIC_IMAGE = {
  name: 'basic_image',
  key: 'image',
  icon: 'icon-tuceng-tupian',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const BASIC_TEXT = {
  name: 'basic_text',
  key: 'text',
  icon: 'icon-tuceng-wenzi',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const SCALE = {
  name: 'scale',
  key: 'scale',
  icon: 'icon-tuceng-tupian',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const ANALOG_CLOCK = {
  name: 'pointer_time',
  key: 'analog_clock',
  icon: 'icon-tuceng-zhizhenshijian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'hour_pointer',
      key: 'hour',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'min_pointer',
      key: 'minute',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'sec_pointer',
      key: 'second',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    }
  ]
}

const DIGITAL_CLOCK = {
  name: 'digital_time',
  key: 'digital_clock',
  icon: 'icon-tuceng-shuzishijian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'hr',
      key: 'hour',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'min',
      key: 'minute',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'second',
      key: 'second',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'ampm',
      key: 'ampm',
      icon: 'icon-tuceng-wenzi',
      checked: false
    }
  ]
}

const DIGITAL_CLOCK_ZEPP_OS = {
  name: 'digital_time',
  key: 'digitalClock'
}

const PROGRESS_CLOCK = {
  name: 'progress_time',
  key: 'progress_clock',
  icon: 'icon-tuceng-jindushijian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'hr',
      key: 'hour',
      icon: 'icon-tuceng-jindushijian',
      checked: false
    },
    {
      name: 'min',
      key: 'minute',
      icon: 'icon-tuceng-jindushijian',
      checked: false
    },
    {
      name: 'second',
      key: 'second',
      icon: 'icon-tuceng-jindushijian',
      checked: false
    }
  ]
}

const PAI_WEEK = {
  name: 'week_pai',
  key: 'pai',
  icon: 'icon-tuceng-pai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'weekly',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const PAI_DAY = {
  name: 'day_pai',
  key: 'pai_daily',
  icon: 'icon-tuceng-pai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'daily',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const AQI = {
  name: 'air_quality',
  key: 'aqi',
  icon: 'icon-tuceng-kongqizhiliang',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const HUMIDITY = {
  name: 'humidity',
  key: 'humidity',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const UVI = {
  name: 'uv_degree',
  key: 'uvi',
  icon: 'icon-tuceng-ziwaixian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const ALTIMETER = {
  name: 'altimeter',
  key: 'altimeter',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const SPO2 = {
  name: 'spo2',
  key: 'spo2',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const WIND = {
  name: 'wind',
  key: 'wind',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const STRESS = {
  name: 'stress',
  key: 'stress',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const TRAINING_LOAD = {
  name: 'training_load',
  key: 'training_load',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const VO2MAX = {
  name: 'vo2max',
  key: 'vo2max',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const BATTERY = {
  name: 'battery',
  key: 'battery',
  icon: 'icon-tuceng-dianliang',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const HEART_RATE = {
  name: 'heart_rate',
  key: 'heart_rate',
  icon: 'icon-tuceng-xinshuai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const CALORIE = {
  name: 'calorie',
  key: 'calorie',
  icon: 'icon-tuceng-xiaohao',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const STEP = {
  name: 'steps',
  key: 'step',
  icon: 'icon-tuceng-bushu',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'real_time_steps',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'target_steps',
      key: 'target',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const DISTANCE = {
  name: 'distance',
  key: 'distance',
  icon: 'icon-tuceng-juli',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const WEATHER = {
  name: 'weather',
  key: 'weather',
  icon: 'icon-tuceng-tianqi',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    }
  ]
}

const MOON = {
  name: 'moon',
  key: 'moon',
  icon: '',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    }
  ]
}

const TEMPERATURE = {
  name: 'temperature',
  key: 'temperature',
  icon: 'icon-tuceng-wendu',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'real_time_temp',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'lowest_temp',
      key: 'low',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'highest_temp',
      key: 'high',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const SUN = {
  name: 'sunrise_sunset',
  key: 'sun',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'current_time',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'sunrise',
      key: 'high',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'sunset',
      key: 'low',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const SYSTEM = {
  name: 'system',
  key: 'system',
  icon: 'icon-tuceng-xitong',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'DND',
      key: 'dnd',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'bluetooth',
      key: 'disconnect',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'screen_lock',
      key: 'lock',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'alarm',
      key: 'clock',
      icon: 'icon-tuceng-tupian',
      checked: false
    }
  ]
}

const DATE = {
  name: 'date',
  key: 'date',
  icon: 'icon-tuceng-riqi',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'year',
      key: 'year',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'month',
      key: 'month',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'day',
      key: 'day',
      icon: 'icon-tuceng-shuzi',
      checked: false
    }
  ]
}

const LUNAR = {
  name: 'lunar',
  key: 'lunar',
  icon: 'icon-tuceng-riqi',
  expand: false,
  hide: false,
  canClick: true,
  order: '',
  children: [
    {
      name: 'lunar_date',
      key: 'lunar_date',
      icon: 'icon-tuceng-riqi',
      checked: false
    },
    {
      name: 'festival_or_solar_term',
      key: 'festival_or_solar_term',
      icon: 'icon-tuceng-riqi',
      checked: false
    }
  ]
}

const STAND = {
  name: 'standing_goal',
  key: 'stand',
  icon: 'icon-tuceng-zhanli',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const WEEK = {
  name: 'week',
  key: 'week',
  icon: 'icon-tuceng-xingqi',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'image',
      key: 'text',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    }
    // 星期不支持弧形进度
    // {
    //     name: 'normal_progress',
    //     key: 'progress',
    //     icon: 'icon-tuceng-shiliangjindu',
    //     checked: false,
    // },
  ]
}

// const MESSAGE = {
//     name: 'SMS',
//     key: 'message',
//     icon: 'icon-tuceng-duanxin',
//     expand: false,
//     hide: false,
//     order: '',
//     children: [
//         {
//             name: 'image',
//             key: 'icon',
//             icon: 'icon-tuceng-tupian',
//             checked: false,
//         },
//         {
//             name: 'image_progress',
//             key: 'image_progress',
//             icon: 'icon-tuceng-tupian',
//             checked: false,
//         },
//         {
//             name: 'jump_area',
//             key: 'jumpable',
//             icon: 'icon-tuceng-requ',
//             checked: false,
//         },
//     ],
// }

// const PHONE = {
//     name: 'phone',
//     key: 'phone',
//     icon: 'icon-tuceng-dianhua',
//     expand: false,
//     hide: false,
//     order: '',
//     children: [
//         {
//             name: 'image',
//             key: 'icon',
//             icon: 'icon-tuceng-tupian',
//             checked: false,
//         },
//         {
//             name: 'jump_area',
//             key: 'jumpable',
//             icon: 'icon-tuceng-requ',
//             checked: false,
//         },
//     ],
// }

const MASK = {
  name: 'component_mask',
  key: 'mask',
  icon: 'icon-tuceng-zhezhao',
  expand: false,
  canRemove: false,
  canHide: false,
  canClick: true,
  order: ''
}

const COMPONENT = {
  name: 'editable_component',
  key: 'component',
  icon: 'icon-tuceng-kebianjizujian',
  expand: false,
  hide: false,
  canClick: true,
  canCopy: true,
  order: '',
  multiple: true,
  // 建立一个 n 对 1 的关系
  // 当这个组件第一次添加时 1 也添加
  // 当这个组件全部删除时 1 也删除
  nto1: 'mask',
  children: [
    // MESSAGE,
    // PHONE,
    DATE,
    WEEK,
    BATTERY,
    HEART_RATE,
    CALORIE,
    // PAI_WEEK,
    PAI_DAY,
    STEP,
    DISTANCE,
    WEATHER,
    TEMPERATURE,
    AQI,
    SUN,
    HUMIDITY,
    UVI,
    ALTIMETER,
    WIND,
    SPO2,
    STRESS,
    VO2MAX,
    TRAINING_LOAD,
  ]
}

const EDITABLE_BG = {
  name: 'editable_bg',
  key: 'editable_bg',
  icon: 'icon-tuceng-beijing',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'editable_bg_group',
      key: 'bg',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'editable_bg_tips',
      key: 'tips',
      icon: 'icon-tuceng-tupian',
      checked: false
    }
  ]
}

const ANIMATION = {
  name: 'frame_amimation',
  key: 'animation',
  icon: 'icon-tuceng-zhendonghua',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'animation~~1',
      key: 'first',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'animation~~2',
      key: 'second',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'animation~~3',
      key: 'third',
      icon: 'icon-tuceng-tupian',
      checked: false
    }
  ]
}

const MENU = {
  background: BACKGROUND,
  image: BASIC_IMAGE,
  text: BASIC_TEXT,
  scale: SCALE,
  analog_clock: ANALOG_CLOCK,
  digital_clock: DIGITAL_CLOCK,
  digitalClock: DIGITAL_CLOCK_ZEPP_OS,
  progress_clock: PROGRESS_CLOCK,
  pai: PAI_WEEK,
  pai_daily: PAI_DAY,
  aqi: AQI,
  humidity: HUMIDITY,
  uvi: UVI,
  altimeter: ALTIMETER,
  spo2: SPO2,
  wind: WIND,
  stress: STRESS,
  training_load: TRAINING_LOAD,
  vo2max: VO2MAX,
  battery: BATTERY,
  heart_rate: HEART_RATE,
  calorie: CALORIE,
  step: STEP,
  distance: DISTANCE,
  weather: WEATHER,
  moon: MOON,
  temperature: TEMPERATURE,
  sun: SUN,
  system: SYSTEM,
  date: DATE,
  stand: STAND,
  lunar: LUNAR,
  week: WEEK,
  // message: MESSAGE,
  // phone: PHONE,
  component: COMPONENT,
  editable_bg: EDITABLE_BG,
  mask: MASK,
  animation: ANIMATION
}

export default Immutable.fromJS(MENU)
