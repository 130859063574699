// jscode
import drawEditWidget from './jscode/drawEditWidget.temp'
import layoutChange from './jscode/layoutChange.temp'
import lunarShow from './jscode/lunarShow.temp'
import WATCHFACE_INDEX from './jscode/watchfaceIndex.temp'
// json
import appJson from './json/app.json.temp'
// sensor
import createSensorTemp from './sensor/create.sensor.temp'
import sensorTemp from './sensor/sensor.temp'
import timerTemp from './sensor/timer.temp'
// widget
import ARC_PROGRESS from './widget/ARC_PROGRESS/index.temp'
import DATE_POINTER from './widget/DATE_POINTER/index.temp'
import FILL_RECT from './widget/FILL_RECT/index.temp'
import IMG from './widget/IMG/index.temp'
import IMG_ANIM from './widget/IMG_ANIM/index.temp'
import IMG_CLICK from './widget/IMG_CLICK/index.temp'
import IMG_DATE from './widget/IMG_DATE/index.temp'
import IMG_LEVEL from './widget/IMG_LEVEL/index.temp'
import IMG_POINTER from './widget/IMG_POINTER/index.temp'
import IMG_PROGRESS from './widget/IMG_PROGRESS/index.temp'
import IMG_STATUS from './widget/IMG_STATUS/index.temp'
import IMG_TIME from './widget/IMG_TIME/index.temp'
import IMG_WEEK from './widget/IMG_WEEK/index.temp'
import TEXT from './widget/TEXT/index.temp'
import TEXT_DATE from './widget/TEXT_DATE/index.temp'
import TEXT_FONT from './widget/TEXT_FONT/index.temp'
import TEXT_IMG from './widget/TEXT_IMG/index.temp'
import TEXT_TIME from './widget/TEXT_TIME/index.temp'
import TIME_POINTER from './widget/TIME_POINTER/index.temp'
import WATCHFACE_EDIT_BG from './widget/WATCHFACE_EDIT_BG/index.temp'
import WATCHFACE_EDIT_FG_MASK from './widget/WATCHFACE_EDIT_FG_MASK/index.temp'
import WATCHFACE_EDIT_GROUP from './widget/WATCHFACE_EDIT_GROUP/index.temp'
import WATCHFACE_EDIT_MASK from './widget/WATCHFACE_EDIT_MASK/index.temp'

const jsCodeMap = {
  drawEditWidget,
  layoutChange,
  lunarShow,
  WATCHFACE_INDEX
}

const sensorMap = {
  createSensorTemp,
  sensorTemp,
  timerTemp
}

const widgetMap = {
  ARC_PROGRESS,
  DATE_POINTER,
  FILL_RECT,
  IMG,
  IMG_ANIM,
  IMG_CLICK,
  IMG_DATE,
  IMG_LEVEL,
  IMG_POINTER,
  IMG_PROGRESS,
  IMG_STATUS,
  IMG_TIME,
  IMG_WEEK,
  TEXT,
  TEXT_DATE,
  TEXT_FONT,
  TEXT_IMG,
  TEXT_TIME,
  TIME_POINTER,
  WATCHFACE_EDIT_BG,
  WATCHFACE_EDIT_FG_MASK,
  WATCHFACE_EDIT_GROUP,
  WATCHFACE_EDIT_MASK
}

export { jsCodeMap, appJson, widgetMap, sensorMap }
