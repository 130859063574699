export const parseCombin = (combin) => {
  const result = {
    single: 0,
    follow: 1
  }
  return result[combin] || 0
}

export const parseBool2Number = (boolField) => {
  return +!!boolField
}

export const addIndentation = (codeStr, spaceCount = 8) => {
  if (codeStr.trim() === '') return codeStr

  return codeStr.replace(/\s*\n|\s+(.*?\n)/g, ($1) => ($1 ? `${' '.repeat(spaceCount)}${$1}` : ''))
}

export const removeIndentation = (codeStr, spaceCount = 4) => {
  if (codeStr.trim() === '') return codeStr

  return codeStr.replace(new RegExp(`\\s*\\n|\\s{${spaceCount}}(.*?\\n)`, 'g'), '$1')
}

// 获取 appId 16 进制表示形式
export const appid16 = (appId) => {
  return appId.toString(16).padStart(8, 0)
}

/**
 *
 * @param {string} prefix
 * @returns [parentKey, childKey]
 */
export const getKeyPath = (prefix) => {
  const keyArr = prefix.split('$_$')

  return [keyArr[1], keyArr[2]]
}

export const getDialType = (prefix) => {
  const keyArr = prefix.split('$_$')

  return keyArr[0] || 'normal'
}

export const getDateReplace = (currentList, dateFormat) => {
  const dateOrderMap = {
    '0' : ['[YEAR]','[MON]','[MON_Z]', '[DAY]', '[DAY_Z]'],
    '1' : ['[DAY]','[DAY_Z]', '[MON]','[MON_Z]', '[YEAR]'],
    '2' : ['[MON]','[MON_Z]', '[DAY]','[DAY_Z]', '[YEAR]']
  }
  const replaceMap = {}
  let k = 0
  dateOrderMap[dateFormat].forEach(
      (item) => {
        if (currentList.includes(item)){
          replaceMap[currentList[k]] = item
          k += 1
        }
        return item
      }
  )
  return replaceMap
}

const DateList = ['[DAY_Z]', '[DAY]', '[MON]', '[MON_Z]', '[YEAR]']

export const tagsDateCount = (tags) => {
  let count = 0
  if (!tags) return count
  DateList.forEach((item) => {
    if (tags.includes(item)) {
      count += 1
    }
  })
  return count
}

export const getSensorVariable = (sensorId) => `${sensorId.toLocaleLowerCase()}Sensor`
