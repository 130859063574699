import React from 'react'
import { PictureProgress } from '../../components'

const componentMap = () => ({
  image_progress: PictureProgress,
})

class Moon extends React.Component {
  static renderLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const Component = componentMap(hasRectArea)[childKey]
    Component.renderLayer({ childKey, ...restArgs })
  }

  static removeLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const Component = componentMap(hasRectArea)[childKey]

    if (Component && Component.removeLayer) {
      Component.removeLayer({ childKey, ...restArgs })
    }
  }

  render() {
    const { childKey, propMap } = this.props
    const prop = propMap[childKey] || {}
    const { hasRectArea } = prop
    const Component = componentMap(hasRectArea)[childKey]
    const allProps = { ...this.props, ...prop }

    if (childKey === 'image_progress') {
      return <PictureProgress {...allProps} />
    }
    return <div className="control-container">{Component && <Component {...allProps} />}</div>
  }
}

export default Moon
