const IMAGE_PROGRESS = {
  images: [],
  is_continuance: false, // 是否连续显示
  positions: [
    {
      x: 0,
      y: 0
    }
  ]
}

export default IMAGE_PROGRESS
