export default  `
  {{ variableForJsTime }} = hmSensor.createSensor(hmSensor.id.TIME)

  function isVoid(value) {
    return value === 'INVALID'
  }

  function getLunarText() {
    lunar_month = {{ variableForJsTime }}.lunar_month
    lunar_day = {{ variableForJsTime }}.lunar_day
    festival = {{ variableForJsTime }}.getShowFestival()
    festival = isVoid(festival) ? '' : festival

    if (!isVoid(lunar_month)) {
      if (lunar_month.indexOf('闰') > -1) {
        lunarText = lunar_month
      } else if (!isVoid(lunar_day)) {
        lunarText = lunar_month + lunar_day
      }
    }
    {{ #variable }}
    {{ variable }}.setProperty(hmUI.prop.MORE, {
      text: {{ showLunarWithFestival }} ? (festival || lunarText) : lunarText,
    })
    {{ /variable }}
    {{ #variableForFestival }}
    {{ variableForFestival }}.setProperty(hmUI.prop.MORE, {
      text: festival,
    })
    {{ /variableForFestival }}
  }
`
