import { LunarComponent } from '@watchface/pages/DesignTool/NonZeppOs/components'
import Classnames from 'classnames'
import React, { PureComponent } from 'react'

class Lunar extends PureComponent {
  static renderLayer = (param: any) => {
    LunarComponent.renderLayer(param)
  }

  render() {
    const cls = Classnames(['control-container'])
    return (
      <div className={cls}>
        <LunarComponent {...this.props} />
      </div>
    )
  }
}

export default Lunar
