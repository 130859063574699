import AMPM from '@watchface/pages/DesignTool/NonZeppOs/components/AmPm/config'
import ANALOG_CLOCK_ITEM from '@watchface/pages/DesignTool/NonZeppOs/components/AnalogClockComponent/config'
import createBackgroundData from '@watchface/pages/DesignTool/NonZeppOs/components/BackgroundComponent/config'
import EDITABLE from '@watchface/pages/DesignTool/NonZeppOs/components/EditableComponent/config'
import ANIMATION from '@watchface/pages/DesignTool/NonZeppOs/components/FrameAnimationComponent/config'
import ICON from '@watchface/pages/DesignTool/NonZeppOs/components/IconComponent/config'
import JUMPABLE from '@watchface/pages/DesignTool/NonZeppOs/components/JumpHotAreaComponent/config'
import MASK from '@watchface/pages/DesignTool/NonZeppOs/components/MaskComponent/config'
import createNumberData from '@watchface/pages/DesignTool/NonZeppOs/components/NumberComponent/config'
import PIC from '@watchface/pages/DesignTool/NonZeppOs/components/PicComponent/config'
import IMAGE_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PictureProgress/config'
import POINTER_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PointerProgressComponent/config'
import getProgress from '@watchface/pages/DesignTool/NonZeppOs/components/ProgressComponent/config'
import SCALE from '@watchface/pages/DesignTool/NonZeppOs/components/ScaleComponent/config'
import TEXT from '@watchface/pages/DesignTool/NonZeppOs/components/TextComponent/config'
import {
  DIGITAL_CLOCK_AM,
  DIGITAL_CLOCK_HOUR,
  DIGITAL_CLOCK_MINUTE,
  DIGITAL_CLOCK_PM,
  DIGITAL_CLOCK_SECOND,
  DIGITAL_CLOCK_WIDGET
} from '@watchface/pages/DesignTool/NonZeppOs/ZeppOsComponents/DigitalClock/config'
import IMAGE from '@watchface/pages/DesignTool/NonZeppOs/ZeppOsComponents/ImageComponent/config'
import Immutable from 'immutable'
import EXTRA_CONFIG from './extraConfig'
// 配置文件按照模块拆分组合

const DIGITAL_CLOCK = {
  children: {
    hour: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    minute: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    second: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    am: AMPM,
    pm: AMPM
  },
  follow_time: false,
  align: 'left'
}

const DIGITAL_CLOCK_ZEPP_OS = {
  ...DIGITAL_CLOCK_WIDGET,
  children: [DIGITAL_CLOCK_HOUR, DIGITAL_CLOCK_MINUTE, DIGITAL_CLOCK_SECOND, DIGITAL_CLOCK_AM, DIGITAL_CLOCK_PM]
}

// 指针时间
const ANALOG_CLOCK = {
  children: {
    hour: ANALOG_CLOCK_ITEM,
    minute: ANALOG_CLOCK_ITEM,
    second: ANALOG_CLOCK_ITEM
  }
}

// const PROGRESS_CLOCK = {
//     children: {
//         hour: getProgress({ progress_render_type: 'color', cap: 0 }),
//         minute: getProgress({ progress_render_type: 'color', cap: 0 }),
//         second: getProgress({ progress_render_type: 'color', cap: 0 }),
//     },
// }

const PAI_WEEK = {
  children: {
    weekly: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const AQI = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const HUMIDITY = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const UVI = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const ALTIMETER = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}


const SPO2 = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}


const WIND = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}


const STRESS = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const TRAINING_LOAD = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const VO2MAX = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const BATTERY = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const HEART_RATE = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const CALORIE = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const WEATHER = {
  children: {
    image_progress: IMAGE_PROGRESS
  }
}

const TEMPERATURE = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    high: createNumberData({ hasSystemFont: true }),
    low: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    jumpable: JUMPABLE
  }
}

const SUN = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    high: createNumberData({ hasSystemFont: true }),
    low: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: { ...IMAGE_PROGRESS, is_continuance: true },
    jumpable: JUMPABLE
  }
}

const SYSTEM = {
  children: {
    dnd: ICON,
    disconnect: ICON,
    lock: ICON,
    clock: ICON
  }
}

const DATE = {
  children: {
    year: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    month: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    day: createNumberData({ hasSystemFont: true, isPaddingZero: true })
  }
}

const STAND = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const STEP = {
  children: {
    current: createNumberData({ hasSystemFont: true }),
    pointer_progress: POINTER_PROGRESS,
    target: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    progress: getProgress({ progress_render_type: 'color', cap: 0 }),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const DISTANCE = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    jumpable: JUMPABLE
  }
}

const WEEK = {
  children: {
    text: PIC,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress({ progress_render_type: 'color', cap: 0 })
  }
}

const MESSAGE = {
  children: {
    icon: ICON,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const PHONE = {
  children: {
    icon: ICON,
    jumpable: JUMPABLE
  }
}

const ANIMATIONDATA = {
  children: {
    first: ANIMATION,
    second: ANIMATION,
    third: ANIMATION
  }
}

const LAYER_CONFIG = {
  background: createBackgroundData({ isIdle: false }),
  scale: SCALE,
  image: IMAGE,
  text: TEXT,
  analog_clock: ANALOG_CLOCK,
  digital_clock: DIGITAL_CLOCK,
  digitalClock: DIGITAL_CLOCK_ZEPP_OS,
  // progress_clock: PROGRESS_CLOCK,
  pai: PAI_WEEK,
  aqi: AQI,
  humidity: HUMIDITY,
  altimeter: ALTIMETER,
  spo2: SPO2,
  stress: STRESS,
  wind: WIND,
  training_load: TRAINING_LOAD,
  vo2max: VO2MAX,
  uvi: UVI,
  battery: BATTERY,
  heart_rate: HEART_RATE,
  calorie: CALORIE,
  weather: WEATHER,
  temperature: TEMPERATURE,
  sun: SUN,
  system: SYSTEM,
  date: DATE,
  step: STEP,
  distance: DISTANCE,
  week: WEEK,
  message: MESSAGE,
  phone: PHONE,
  mask: MASK,
  stand: STAND,
  animation: ANIMATIONDATA
}

const COMPONENT_KEYS = [
  'message',
  'phone',
  'battery',
  'heart_rate',
  'calorie',
  'step',
  'distance',
  'pai',
  'date',
  'week',
  'weather',
  'temperature',
  'aqi',
  'humidity',
  'uvi',
  'sun',
  'altimeter',
  'wind',
  'stress',
  'spo2',
  'vo2max',
  'training_load'
]

COMPONENT_KEYS.forEach((key) => {
  EDITABLE.props[key] = {
    ...LAYER_CONFIG[key],
    previews: [
      {
        language: 'all',
        image: {
          url: '',
          width: 0,
          height: 0,
          name: ''
        }
      }
    ],
    bg: {
      position: {
        x: 0,
        y: 0
      },
      image: ''
    }
  }
})

LAYER_CONFIG.component = EDITABLE
export default Immutable.fromJS(LAYER_CONFIG).mergeDeep(EXTRA_CONFIG.delete('newFieldOrModulePaths'))
