import Immutable from 'immutable'
import { SET_EXQUISITE_DIAL, SET_USER_DIAL } from '../actionTypes'

const initialState: any = Immutable.fromJS({
  exquisiteDial: {
    metas: {
      pagination: {}
    },
    data: []
  },
  userDial: {
    metas: {
      pagination: {}
    },
    data: []
  }
})

const reducer = (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case SET_USER_DIAL:
      return state.set('userDial', Immutable.fromJS(data))
    case SET_EXQUISITE_DIAL:
      return state.set('exquisiteDial', Immutable.fromJS(data))
    default:
      return state
  }
}

export default reducer
