import dayjs from 'dayjs'

// https://huami.feishu.cn/docs/doccnQDXxSnrqrFaR1BmFst4cdd
const platform: any = {
  mi: 'com.xiaomi.hm.health', // Zepp Life
  midong: 'com.huami.midong', // zepp应用
  watch: 'com.huami.watch', // 老手助
  timex: 'com.timex.app', // 天美时
  zepp: 'com.huami.watch.hmwatchmanager', // Zepp 和 Amazfit
  amazfit: 'com.huami.app.amazfit', // 备份 Amazfit
  HMHealth: 'com.huami.shushan', // 米动健康 包名-com.huami.midong appName-com.huami.shushan
  PaiHealth: 'com.paihealth.app',
  bell: 'com.huami.hearingaids', // Bell
  hollywood: 'com.huami.kwatchmanager', // HollyWood
  norex: 'com.huami.norex',
  open: 'com.huami.webapp',
  thirdSdk: 'com.huami.third.sdk'
}

const versionReg: any = {
  mi: /com\.xiaomi\.hm\.health\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/,
  midong: /com\.huami\.midong\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/,
  watch: /com\.huami\.watch\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/,
  timex: /com\.timex\.app\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/,
  zepp: /com\.huami\.watch\.hmwatchmanager\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/, // Amazfit zepp
  amazfit: /com\.huami\.app\.amazfit\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/,
  HMHealth: /com\.huami\.midong\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/, // 米动健康 UA 信息包名为 com.huami.midong
  PaiHealth: /com\.paihealth\.app\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/,
  bell: /com\.huami\.hearingaids\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/,
  hollywood: /com\.huami\.kwatchmanager\/(\d+)?_?((\d+)\.?(\d+)?\.?(\d+)?)/
}
const deviceInfoReg: any = {
  netType: /\snettype\/(\S+)/,
  region: /\suserregion\/(\S+)/,
  language: /\slanguage\/(\S+)/,
  country: /\scountry\/(\S+)/
}

let userAgent: any
let isIos = false
let isAndroid = false
let isPc = false
let isIpad = false
let isSafari = false
let isWeiXin = false
let appPlatform = 'android_phone'
let lang = ''
let isHuawei = false

const win = window
if (typeof win !== 'undefined') {
  userAgent = win.navigator.userAgent.toLowerCase()
  lang = win.navigator.language.toLowerCase()
  isIos = userAgent.includes('iphone') || userAgent.includes('ipad')
  isAndroid = userAgent.includes('android')
  isPc = !isAndroid && !isIos
  isIpad = userAgent.includes('ipad')
  isSafari = userAgent.includes('safari')
  isWeiXin = !!userAgent.match(/micromessenger/i)
}

if (isIos) {
  appPlatform = 'ios_phone'
} else if (isPc) {
  appPlatform = 'web'
}

if (userAgent.match(/huawei|honor/gi)) {
  isHuawei = true
}

export function getRegion() {
  const ua = window.navigator.userAgent.toLowerCase()
  let code = 7 // 默认中国区
  if (ua.includes('userregion')) {
    const value: any = ua.match(/userregion\/([a-zA-Z0-9]+)/) || []
    ;[, code] = value
  }
  return Number(code)
}

export function getDeviceInfo(_userAgent: string): string {
  const result: any = {}
  Object.keys(deviceInfoReg).forEach((item) => {
    const matched: Array<string> = (userAgent || _userAgent).match(deviceInfoReg[item]) || []
    ;[result[item]] = matched
  })
  return result
}

export const getMifitVersion = (
  platforms: string,
  _userAgent?: string
): {
  code: number
  name: string
  v: number
  int: number
  // eslint-disable-next-line consistent-return
} | void => {
  const reg = versionReg[platforms]
  const matched = (userAgent || _userAgent).match(reg)
  if (matched && matched.length === 6) {
    return {
      code: Number(matched[1]) || 0,
      name: matched[2],
      v: Number(matched[3]),
      int: Number(matched[3]) * 1000 + Number(matched[4]) * 10 + Number(matched[5])
    }
  }
  return {
    code: 0,
    name: '',
    v: 0,
    int: 0
  }
}

/**
 * 获取当前语言环境
 * curLangEnv：hans // 简体中文
 * curLangEnv：hant // 繁体中文
 * curLangEnv：notHan // 非中文
 */
export const getCurLangEnv = (lang: string) => {
  let curLangEnv = 'hans'
  if (lang.indexOf('zh') > -1) {
    if (lang.indexOf('hk') > -1 || lang.indexOf('tw') > -1 || lang.indexOf('hant') > -1) {
      curLangEnv = 'hant'
    }
  } else {
    curLangEnv = 'notHan'
  }
  return curLangEnv
}
/**
 * app信息查询
 * @description 替代 getAppDeviceString、getAppDeviceNameByQuery、getAppDeviceName、getAppDeviceType
 * 从ua解析app信息，获取 app类型、包名、app名称
 * @param platformString
 * @param type , 不写将返回全部app信息
 * @property appType packageName appName, appLogo type 枚举值
 * 注意：设计在 PC 端判断 是哪个 App 的最好把 platformString 传进来
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAppDeviceInfo = ({
  platformString = '',
  type,
  useDefaultAppType = true,
  specifyLangEnv = 'auto',
  checkUserAgent = true
}: {
  platformString?: string | string[] | null // app包名
  type?: string // app 信息类型
  useDefaultAppType?: boolean // 是否需要返回默认的appType
  specifyLangEnv?: string // 指定的语言
  checkUserAgent?: boolean // 是否检查userAgent
}): any => {
  let appType = ''
  let packageName = ''
  let appName = ''
  let appLogo = ''
  let appLogoDark = ''
  let platformStr = platformString
  if (checkUserAgent) {
    for (const key in platform) {
      if (userAgent.match(platform[key])) {
        // 米动健康的特殊处理
        // 米动健康包名为 com.huami.midong, appName 为 com.huami.shushan,
        // 米动健康 UA 信息里面包名为 com.huami.midong
        // 而获取米动健康用户信息接口必须传 com.huami.shushan 所以 platform 需要特殊处理
        if (platformStr === 'com.huami.shushan' && key === 'midong') {
          platformStr = 'com.huami.shushan'
        } else {
          ;[platformStr] = userAgent.match(platform[key])
        }
      }
    }
  }
  const curLangEnv = getCurLangEnv(lang)
  switch (platformStr) {
    case platform.mi:
      appType = 'mi'
      appName = 'Zepp Life'
      packageName = platform.mi
      appLogo = 'https://img-cdn.huami.com/appLogo/zeppLife.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/zeppLife.png'
      break
    case platform.midong:
      appType = 'midong'
      packageName = platform.midong
      appLogo = 'https://img-cdn.huami.com/appLogo/zepp.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/zepp.png'
      break
    case platform.amazfit:
      appType = 'amazfit'
      packageName = platform.amazfit
      appName = 'Amazfit'
      appLogo = 'https://img-cdn.huami.com/appLogo/amazfit.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/amazfit.png'
      break
    case platform.zepp: {
      const version: any = getMifitVersion('zepp', userAgent)
      if (version && version.name && version.name < '3.0.0') {
        appType = 'watch'
        packageName = platform.watch
        appName = 'Amazfit'
        appLogo = 'https://img-cdn.huami.com/appLogo/amazfit.png'
        appLogoDark = 'https://img-cdn.huami.com/appLogo/amazfit.png'
      } else if (version && version.name && version.name >= '3.0.0' && version.name < '5.0.0') {
        appType = 'amazfit'
        packageName = platform.zepp
        appName = 'Amazfit'
        appLogo = 'https://img-cdn.huami.com/appLogo/amazfit.png'
        appLogoDark = 'https://img-cdn.huami.com/appLogo/amazfit.png'
      } else {
        appType = 'zepp'
        packageName = platform.zepp
        appName = 'Zepp'
        appLogo = 'https://img-cdn.huami.com/appLogo/zepp.png'
        appLogoDark = 'https://img-cdn.huami.com/appLogo/zepp.png'
      }
      break
    }
    case platform.watch:
      appType = 'watch'
      packageName = platform.watch
      appName = 'Amazfit'
      appLogo = 'https://img-cdn.huami.com/appLogo/amazfit.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/amazfit.png'
      break
    case platform.timex:
      appType = 'timex'
      packageName = platform.timex
      appName = 'Timex Smart'
      appLogo = 'https://img-cdn.huami.com/appLogo/timex.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/timex.png'
      break
    case platform.HMHealth:
      appType = 'HMHealth'
      packageName = platform.HMHealth
      appLogo = 'https://img-cdn.huami.com/appLogo/midong.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/midong.png'
      break
    case platform.PaiHealth:
      appType = 'paiHealth'
      packageName = platform.PaiHealth
      appName = 'Pai'
      appLogo = 'https://img-cdn.huami.com/appLogo/pai.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/pai.png'
      break
    case platform.bell:
      appType = 'bell'
      packageName = platform.bell
      appName = 'Zepp Clarity'
      appLogo = 'https://img-cdn.huami.com/appLogo/zeppClarity.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/zeppClarity.png'
      break
    case platform.hollywood:
      appType = 'hollywood'
      packageName = platform.hollywood
      appName = 'Amazfit守护'
      appLogo = 'https://img-cdn.huami.com/appLogo/hollywood.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/hollywood.png'
      break
    case platform.open:
      appType = 'open'
      packageName = platform.open
      appName = '开放平台'
      appLogo = 'https://img-cdn.huami.com/appLogo/zepp_light.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/zepp_dark.png'
      break
    case platform.thirdSdk:
      appType = 'open'
      packageName = platform.thirdSdk
      appName = '开放平台'
      appLogo = 'https://img-cdn.huami.com/appLogo/zepp_light.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/zepp_dark.png'
      break
    case platform.norex:
      appType = 'norex'
      packageName = platform.norex
      appName = 'NorEx'
      appLogo = 'https://img-cdn.huami.com/appLogo/norex.png'
      appLogoDark = 'https://img-cdn.huami.com/appLogo/norex.png'
      break
    default:
      if (useDefaultAppType) {
        appType = 'mi'
        appName = 'Zepp Life'
        packageName = platform.mi
        appLogo = 'https://img-cdn.huami.com/appLogo/zeppLife.png'
        appLogoDark = 'https://img-cdn.huami.com/appLogo/zeppLife.png'
      }
      break
  }

  if (appType === 'HMHealth') {
    if (specifyLangEnv === 'auto') {
      appName = 'Midong Health'
      if (curLangEnv === 'hans') {
        appName = '米动健康'
      }
    } else {
      switch (specifyLangEnv) {
        case 'hans':
          appName = '米动健康'
          break
        case 'hant':
        case 'notHan':
        default:
          appName = 'Midong Health'
          break
      }
    }
  }
  if (appType === 'open') {
    if (specifyLangEnv === 'auto') {
      appName = 'Open Platform'
      if (curLangEnv === 'hans') {
        appName = '开放平台'
      }
    } else {
      switch (specifyLangEnv) {
        case 'hans':
          appName = '开放平台'
          break
        case 'hant':
        case 'notHan':
        default:
          appName = 'Open Platform'
          break
      }
    }
  }

  if (type === 'appType') {
    // app类型
    return appType
  }
  if (type === 'packageName') {
    // 包名
    return packageName
  }
  if (type === 'appName') {
    // 展示品牌名称
    return appName
  }
  if (type === 'appLogo') {
    // 展示品牌logo
    return appLogo
  }
  return {
    appType,
    packageName,
    appName,
    appLogo,
    appLogoDark
  }
}

export const supportJsBridgeApp = () => {
  const appType = getAppDeviceInfo({
    type: 'appType',
    useDefaultAppType: false
  })

  console.log('=========================current app type========================', appType)
  return ['mi', 'midong', 'watch', 'amazfit', 'zepp', 'timex', 'bell', 'HMHealth', 'PaiHealth'].includes(appType as string)
}

/**
 * 通过设备字典中 releaseDate 字段判断设备是否已发售
 * @param {*} releaseDate YYYY-MM-DD
 */
export const deviceIsPublished = (releaseDate: string | null): boolean => {
  // 如果未配置该字段，默认按照已发售处理（很多历史发售的表盘未配置 releaseDate 字段）
  if (!releaseDate) return true

  const now = dayjs().format('YYYY-MM-DD')
  const formattedReleaseDate = dayjs(releaseDate).format('YYYY-MM-DD')
  const isPublished = dayjs(now).diff(formattedReleaseDate, 'd') >= 0

  return isPublished
}

export default {
  platform,
  userAgent,
  // setUserAgent,
  isIos,
  isAndroid,
  isPc,
  lang,
  isIpad,
  isSafari,
  isWeiXin,
  appPlatform,
  isHuawei,
  deviceIsPublished,
}
