const ANIMATION = {
  position: {
    x: 0,
    y: 0
  },
  images: [],
  frame_interval: 0,
  play_times: 1,
  // 0 or 1, 折返播放或者循环播放
  play_mode: 1
}
export default ANIMATION
