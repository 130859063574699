import { INPUT_STYLE } from '@watchface/constants/style'
import { InputNumber } from 'antd'
import React, { PureComponent } from 'react'

class WatchFaceInputNumber extends PureComponent {
  handleChange = (value) => {
    const { onChange } = this.props

    if (/\d+/.test(value)) {
      onChange(Number(value))
      return
    }

    if (typeof value === 'string' && (!(value.trim()) || value.trim() === '-')) onChange(0)
  }

  render() {
    const { inputStyle, precision = 0 } = this.props
    return <InputNumber precision={precision} style={inputStyle || INPUT_STYLE} {...this.props} onChange={this.handleChange}/>
  }
}

export default WatchFaceInputNumber
