import ICON from '@watchface/pages/DesignTool/NonZeppOs/components/IconComponent/config'

const EDITABLE = {
  rectangle: {
    position: {
      x: 0,
      y: 0
    },
    width: 0,
    height: 0
  },
  select_image: '',
  unselect_image: '',
  text_tag: ICON,
  text_display_width: 0,
  text_margin: 0,
  props: {}
}
export default EDITABLE
