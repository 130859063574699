/* eslint-disable import/order */
import { immutableSelector } from '@/utils/index'
import { DIGITAL_CLOCK_SUPPORTED_FOLLOW_KEY_LIST, getKeyOrIdFromStr, META_COMPONENT_MAP } from '@watchface/components/Meta'
import { MOVE_IMAGE } from '@watchface/constants'
import { changeMenuName, updateCategoryProperty, updateWidgetName } from '@watchface/store/actions'
import { Collapse } from 'antd'
import Immutable from 'immutable'
import PubSub from 'pubsub-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDigitalClock2FabricImage, orderedDigitalTimeCategoriesKey } from './config'
import './index.scss'

const { Panel } = Collapse

const DigitalTimeComponent = (props) => {
  const { parentKey, childKey, dialType, watchConfig, isExist, onInsertImage, onUpdateAttr, onRemove } = props
  const { screenWidth, screenHeight } = immutableSelector(watchConfig)
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState([])
  const [activeKey, setActiveKey] = useState([])
  const { id: activeWidget } = getKeyOrIdFromStr(childKey)

  const widget = useSelector((state) => {
    return state.getIn(['watchFace', 'scene', 'widgets', activeWidget], null)
  })

  const widgetList = useSelector((state) => {
    return state
      .getIn(['watchFace', 'scene', 'widgets'], Immutable.Map())
      .valueSeq()
      .filter((widget) => DIGITAL_CLOCK_SUPPORTED_FOLLOW_KEY_LIST.includes(widget.get('type')) && widget.get('visible'))
  })

  useEffect(() => {
    const categories = widget.get('categories').toJS()
    const activeKey = []
    const categoryList = orderedDigitalTimeCategoriesKey
      .map((categoryKey) => {
        const { type, display, isExpanded, visibility, properties } = categories[categoryKey]
        if (isExpanded) {
          activeKey.push(type)
        }
        return { type, display, visibility, properties, categoryKey }
      })
      .filter((category) => category?.visibility)
    setCategoryList(categoryList)
    setActiveKey(activeKey)
    const key = `${dialType}_${widget.get('type')}_${activeWidget}`
    const options = convertDigitalClock2FabricImage(widgetList.toJS(), key)
    const optionsKey = options?.key || key
    if (options?.objects?.length) {
      isExist(optionsKey) ? onUpdateAttr(optionsKey, options) : onInsertImage(parentKey, optionsKey, options)
    } else {
      onRemove(optionsKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, dialType])

  const onChange = useCallback(
    (category, property, value) => {
      if (category === 'name') {
        dispatch(updateWidgetName(activeWidget, value))
        dispatch(changeMenuName(dialType, [parentKey, childKey], value))
      }
      dispatch(updateCategoryProperty(activeWidget, category, property, value))
    },
    [dispatch, dialType, parentKey, childKey, activeWidget]
  )

  const onCollapseChange = (value) => {
    setActiveKey(value)
  }

  const propsMap = {
    imageGroup: { screenWidth, screenHeight },
    image: { screenWidth, screenHeight }
  }

  useEffect(() => {
    PubSub.subscribe(MOVE_IMAGE, (e, { key, left, top }) => {
      if (key.includes(activeWidget)) {
        onChange('placement', 'x', left)
        onChange('placement', 'y', top)
      }
    })
  }, [onChange, activeWidget])

  return (
    <Collapse className="meta-collapse" activeKey={activeKey} bordered expandIconPosition="right" ghost onChange={onCollapseChange}>
      {categoryList.map((category) => {
        const { type, display, properties, categoryKey } = category
        const PropertyComponent = META_COMPONENT_MAP[type]
        return (
          <Panel header={display} key={type}>
            <PropertyComponent onChange={onChange} properties={properties} categoryKey={categoryKey} {...propsMap[type]} />
          </Panel>
        )
      })}
    </Collapse>
  )
}

export default React.memo(DigitalTimeComponent)
