export default `{
  "configVersion": "v2",
  "app": {
    "appIdType": 0,
    "appId": {{appId}},
    "appName": "{{{appName}}}",
    "appType": "watchface",
    "version": {
      "code": {{appCode}},
      "name": "{{appVersion}}"
    },
    "vender": "zepp",
    "description": "",
    "icon": "{{previewDefault}}",
    "cover": ["{{previewDefault}}"],
    "extraInfo": {
      "madeBy": 1,
      "fromZoom": {{fromZoom}}
    }
  },
  "permissions": [
    "gps"
  ],
  "runtime": {
    "apiVersion": {
      "compatible": "1.0.0",
      "target": "1.0.1",
      "minVersion": "1.0.0"
    }
  },
  "targets": {
    "{{targetName}}": {
      "module": {
        "watchface": {
          "path": "watchface/{{targetName}}/index",
          "main": 1,
          "editable": {{ editable }},
          "lockscreen": {{ idleScreen }},
          "hightCost": {{ isHighCost }}
        }
      },
      "platforms": {{{deviceSource}}},
      "designWidth": {{screenWidth}}
    }
  },
  "i18n": {
  {{ #previewEN }}
  "en-US": {
    "icon": "{{previewEN}}",
    "appName": "{{{appNameEN}}}"
  }
  {{ /previewEN }}
  {{ #previewZH }}
  {{ #previewEN }}
  ,
  {{ /previewEN }}
  "zh-CN": {
    "icon": "{{previewZH}}",
    "appName": "{{{appNameZH}}}"
  }
  {{ /previewZH }}
  {{ #previewTC }}
  {{ ^previewZH }}
  {{ #previewEN }}
  ,
  {{ /previewEN }}
  {{ /previewZH }}
  {{ ^previewEN }}
  {{ #previewZH }}
  ,
  {{ /previewZH }}
  {{ /previewEN }}
  {{ #previewEN }}
  {{ #previewZH }}
  ,
  {{ /previewZH }}
  {{ /previewEN }}
  "zh-TW": {
    "icon": "{{previewTC}}",
    "appName": "{{{appNameTC}}}"
  }
  {{ /previewTC }}
  },
  "defaultLanguage": "en-US",
  "debug": false
}
`
