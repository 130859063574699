import { ScaleComponent } from '@watchface/pages/DesignTool/NonZeppOs/components'
import Classnames from 'classnames'
import React, { PureComponent } from 'react'

const noop = () => {} // eslint-disable-line

class Background extends PureComponent {
  static renderLayer = (param) => {
    ScaleComponent.renderLayer(param)
  }

  static removeLayer = ({ childKey, hasRectArea, ...restArgs }) => {
    const { removeLayer } = ScaleComponent
    removeLayer && removeLayer({ childKey, ...restArgs })
  }

  render() {
    const cls = Classnames(['scale-container', 'control-container'])
    return (
      <div className={cls}>
        <ScaleComponent {...this.props} />
      </div>
    )
  }
}

export default Background
