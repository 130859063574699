/* eslint-disable import/no-mutable-exports */
// const apiDevHost = {
//     apiUser: '/api-user-staging/',
//     account: '/account-staging/',
//     zuul: 'https://auth-staging-cn2.huami.com/',
//     contUrl: 'https://auth-staging-cn2.huami.com/',
//     // zuul: '/auth-staging-cn2/',
//     // contUrl: '/auth-staging-cn2/',
//     apiWatch: '/api-aos-test-cn/',
//     getImageHost: 'https://circle-staging.cdn.bcebos.com/',
//     userInfoHost: '/api-mifit-staging/',
// };

import { getSLD } from '@openplatform/utils'

// 二级域名是否是 zepp
const sldIsZepp = getSLD?.() === 'zepp'

const apiProductionHost = {
    apiUser: 'https://api-user.huami.com/',
    account: 'https://account.huami.com/',
    zuul: 'https://auth-cn2.huami.com/',
    contUrl: 'https://auth.huami.com/',
    apiWatch: 'https://api-mifit-cn2.huami.com/',
    getImageHost: 'https://circle-prod.cdn.bcebos.com/',
    userInfoHost: 'https://api-mifit.huami.com/',
    lightApp: sldIsZepp ? 'https://studio.zepp.com/' : 'https://studio.huami.com/',
    watchFaceMaker: sldIsZepp ? 'https://watchface.zepp.com' : 'https://watchface.huami.com',
};

const apiTestingHost = {
    apiUser: 'https://api-user-staging.huami.com/',
    account: 'https://account-staging.huami.com/',
    zuul: 'https://auth-staging-cn2.huami.com/',
    contUrl: 'https://auth-staging-cn2.huami.com/',
    apiWatch: 'https://api-aos-testing-cn.huami.com/',
    getImageHost: 'https://circle-staging.cdn.bcebos.com/',
    userInfoHost: 'https://api-mifit-staging.huami.com/',
    lightApp: sldIsZepp ? 'https://studio-testing.zepp.com/' : 'https://studio-testing.huami.com/',
    watchFaceMaker: sldIsZepp ? 'https://watchface-testing.zepp.com' : 'https://watchface-testing.huami.com',
};

let apiHost = apiProductionHost;
const env = process.env.NODE_ENV;

switch (env) {
    case 'production':
        apiHost = apiProductionHost;
        break;
    case 'development':
        apiHost = apiTestingHost;
        break;
    case 'test':
        apiHost = apiTestingHost;
        break;
    default:
        apiHost = apiProductionHost;
        break;
}

export default apiHost;
