/* eslint-disable import/order */
import Icon from '@watchface/components/Icon'
import { immutableSelector } from '@/utils/index'
import T from '@watchface/components/I18n'
import { isWidgetMenuKey } from '@watchface/components/Meta'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import Immutable from 'immutable'
import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { DND_LAYER, LAYER_ICON_COLOR, LAYER_ICON_HIGHLIGHT_COLOR, TOOLTIP_COLOR } from '../../constants'
import LayerItem from './LayerItem'

const noop = () => {} // eslint-disable-line

const layerTarget = {
  hover(props, monitor, component) {
    if (!component) return

    const node = component.layerRef

    if (!node) return

    const dragKey = monitor.getItem().layer.get('key')
    const dropKey = props.layer.get('key')

    if (dragKey === dropKey) return

    const dragIndex = monitor.getItem().layerIndex
    const hoverIndex = props.layerIndex

    if (dragIndex === hoverIndex) return

    const hoverBoundingRect = node.getBoundingClientRect()
    // 父级菜单的高度
    const parentMenuHeight = 44
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const threshold = hoverMiddleY > parentMenuHeight ? parentMenuHeight : hoverMiddleY
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    if (dragIndex < hoverIndex && hoverClientY < threshold) return
    if (dragIndex > hoverIndex && hoverClientY > threshold) return

    props.moveLayer([dragIndex], [hoverIndex])
    // eslint-disable-next-line
    monitor.getItem().layerIndex = hoverIndex
  }
}

const layerSource = {
  beginDrag(props) {
    const { layer, layerIndex } = props

    return {
      layerIndex,
      layer
    }
  }
}

const layerTargetConnect = (connect) => ({
  connectDropTarget: connect.dropTarget()
})

const layerSourceConnect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

@DropTarget(DND_LAYER, layerTarget, layerTargetConnect)
@DragSource(DND_LAYER, layerSource, layerSourceConnect)
class Layer extends React.PureComponent {
  renderExpandIcon = (layerItems, expand, isCurrent, i) => {
    if (layerItems && layerItems.size > 0) {
      const { handleExpandClick } = this.props
      return (
        <Icon
          className="expand"
          type="icon-xiaojiantou"
          isStopPropagation
          size={24}
          option={[i, 'expand']}
          style={expand ? {} : { transform: 'rotate(-90deg)' }}
          color={isCurrent ? LAYER_ICON_HIGHLIGHT_COLOR : LAYER_ICON_COLOR}
          onClick={(path) => handleExpandClick(expand, path)}
        />
      )
    }
    return null
  }

  renderRemoveIcon = (key, i) => {
    const { handleRemove } = this.props
    return (
      <Icon
        className="parent-container-icon remove-icon"
        type="icon-tuceng-shanchu"
        size={24}
        color={LAYER_ICON_COLOR}
        isStopPropagation
        onClick={(path) => handleRemove(key, i, path)}
      />
    )
  }

  renderCopyIcon = (key, name) => {
    const { handleCopy } = this.props
    return (
      <Icon
        className="parent-container-icon copy-icon"
        type="icon-tuceng-fuzhi"
        size={24}
        color={LAYER_ICON_COLOR}
        isStopPropagation
        onClick={() => handleCopy(key, name)}
      />
    )
  }

  renderHideIcon = (key, hide, isCurrent, i) => {
    const { handleHideClick } = this.props
    return (
      <Icon
        className={hide ? 'parent-container-icon hide-icon-style' : 'parent-container-icon hide-icon hide-icon-style'}
        type="icon-tuceng-yincang1"
        isStopPropagation
        option={[i, 'hide']}
        size={24}
        color={isCurrent ? LAYER_ICON_HIGHLIGHT_COLOR : LAYER_ICON_COLOR}
        onClick={(path) => handleHideClick(key, hide, path)}
      />
    )
  }

  render() {
    const {
      layer = Immutable.Map(),
      current = Immutable.List(),
      layerIndex,
      handleClick,
      handleCheckClick,
      isDragging,
      connectDragSource,
      connectDragPreview,
      connectDropTarget
    } = this.props

    const {
      name,
      key,
      icon,
      isTemporary = false,
      children: layerItems = Immutable.List(),
      expand,
      hide,
      canClick = false,
      canHide = true,
      canRemove = true,
      canCopy = false
    } = immutableSelector(layer)

    const isJson2Key = isWidgetMenuKey(key)

    if ((!name && !isJson2Key) || isTemporary) return null

    const opacity = isDragging ? 0 : 1

    const isCurrent = current.size === 1 && current.get(0) === key

    const className = classNames(['parent-container'], {
      'parent-container__current': isCurrent
    })

    return connectDropTarget(
      connectDragPreview(
        <div
          ref={(layer) => {
            this.layerRef = layer
          }}
          className="item-container"
          style={{ opacity }}
        >
          {connectDragSource(
            <div
              className={className}
              onClick={
                !canClick
                  ? noop
                  : (e) => {
                      handleClick(key, '', e)
                    }
              }
            >
              {this.renderExpandIcon(layerItems, expand, isCurrent, layerIndex)}
              <Icon type={icon} size={24} color={isCurrent ? LAYER_ICON_HIGHLIGHT_COLOR : LAYER_ICON_COLOR} />
              <div className="parent-container-name">
                <Tooltip title={<T id={name} />} color={TOOLTIP_COLOR} mouseEnterDelay={1}>
                  <span>{isJson2Key ? name : <T id={name} />}</span>
                </Tooltip>
              </div>
              <div className="options">
                {canCopy && this.renderCopyIcon(key, name)}
                {canHide && this.renderHideIcon(key, hide, isCurrent, layerIndex)}
                {canRemove && this.renderRemoveIcon(key, layerIndex)}
              </div>
            </div>
          )}
          <div className="children-container">
            {expand &&
              layerItems.map((item, ci) => {
                const { key: childKey, name: childName } = immutableSelector(item)
                if (!childName && !isWidgetMenuKey(childKey)) return null
                return (
                  <LayerItem
                    key={childKey}
                    layerItem={item}
                    current={current}
                    layerName={name}
                    layerKey={key}
                    layerIndex={layerIndex}
                    layerItemIndex={ci}
                    handleClick={handleClick}
                    handleCheckClick={handleCheckClick}
                  />
                )
              })}
          </div>
        </div>
      )
    )
  }
}

export default Layer
