import { combineReducers } from 'redux-immutable'
import approve from './approve'
import designer from './designer'
import devices from './devices'
import dialList from './dialList'
import exportModule from './export'
import zoom from './zoom'
import i18n from './i18n'
import meta from './meta'
import scene from './scene'

export default combineReducers({
  designer,
  devices,
  dialList,
  approve,
  exportModule,
  i18n,
  meta,
  scene,
  zoom,
})
