import Immutable from 'immutable'

const MENU = Immutable.fromJS({
  background: {
    name: 'background',
    key: 'background',
    icon: 'icon-tuceng-beijing',
    expand: false,
    hide: false,
    canClick: true,
    order: ''
  },
  analog_clock: {
    name: 'pointer_clock',
    key: 'analog_clock',
    icon: 'icon-tuceng-zhizhenshijian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'hour_pointer',
        key: 'hour',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'min_pointer',
        key: 'minute',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'sec_pointer',
        key: 'second',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      }
    ]
  },
  // graph_clock: {
  //   name: '绘图指针时钟',
  //   key: 'graph_clock',
  //   icon: 'icon-tuceng-zhizhenshijian',
  //   expand: false,
  //   hide: false,
  //   order: '',
  //   children: [
  //     {
  //       name: 'hour_pointer',
  //       key: 'hour',
  //       icon: 'icon-tuceng-zhizhen',
  //       checked: false,
  //     },
  //     {
  //       name: 'min_pointer',
  //       key: 'minute',
  //       icon: 'icon-tuceng-zhizhen',
  //       checked: false,
  //     },
  //     {
  //       name: 'sec_pointer',
  //       key: 'second',
  //       icon: 'icon-tuceng-zhizhen',
  //       checked: false,
  //     }
  //   ]
  // },
  digital_clock: {
    name: 'digital_time',
    key: 'digital_clock',
    icon: 'icon-tuceng-shuzishijian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'hr',
        key: 'hour',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'min',
        key: 'minute',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'second',
        key: 'second',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'ampm',
        key: 'ampm',
        icon: 'icon-tuceng-wenzi',
        checked: false
      }
    ]
  },
  alarm_clock: {
    name: 'alarm_time',
    key: 'alarm_clock',
    icon: 'icon-tuceng-fanqiezhong',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'hr',
        key: 'hour',
        icon: 'icon-tuceng-zhizhen',
        checked: true,
        fixedChecked: {
          value: true,
          reason: 'alram_can_not_cancel_hour_tip'
        }
      },
      {
        name: 'min',
        key: 'minute',
        icon: 'icon-tuceng-zhizhen',
        checked: true,
        fixedChecked: {
          value: true,
          reason: 'alram_can_not_cancel_minute_tip'
        }
      },
      {
        name: 'ampm',
        key: 'ampm',
        icon: 'icon-tuceng-wenzi',
        checked: false
      },
      {
        name: 'alarm_image',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'no_alarm_image',
        key: 'no_icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  pai: {
    name: 'PAI',
    key: 'pai',
    icon: 'icon-tuceng-pai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'week_pai',
        key: 'weekly',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  aqi: {
    name: 'air_quality',
    key: 'aqi',
    icon: 'icon-tuceng-kongqizhiliang',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'normal_progress',
        key: 'progress',
        icon: 'icon-tuceng-shiliangjindu',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  humidity: {
    name: 'humidity',
    key: 'humidity',
    icon: 'icon-tuceng-shidu',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  uvi: {
    name: 'uv_degree',
    key: 'uvi',
    icon: 'icon-tuceng-ziwaixian',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  battery: {
    name: 'battery',
    key: 'battery',
    icon: 'icon-tuceng-dianliang',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  heart_rate: {
    name: 'heart_rate',
    key: 'heart_rate',
    icon: 'icon-tuceng-xinshuai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  calorie: {
    name: 'calorie',
    key: 'calorie',
    icon: 'icon-tuceng-xiaohao',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  step: {
    name: 'steps',
    key: 'step',
    icon: 'icon-tuceng-bushu',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'real_time_steps',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'target_steps',
        key: 'target',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  distance: {
    name: 'distance',
    key: 'distance',
    icon: 'icon-tuceng-juli',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  weather: {
    name: 'weather',
    key: 'weather',
    icon: 'icon-tuceng-tianqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  temperature: {
    name: 'temperature',
    key: 'temperature',
    icon: 'icon-tuceng-wendu',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'real_time_temp',
        key: 'current',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'lowest_temp',
        key: 'low',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'highest_temp',
        key: 'high',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  sunrise: {
    name: 'sunrise',
    key: 'sunrise',
    icon: 'icon-tuceng-richu',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'sunrise',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  sunset: {
    name: 'sunset',
    key: 'sunset',
    icon: 'icon-tuceng-wenjianjiakai',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'sunset',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  system: {
    name: 'system',
    key: 'system',
    icon: 'icon-tuceng-xitong',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'DND',
        key: 'dnd',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'bluetooth',
        key: 'disconnect',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'screen_lock',
        key: 'lock',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'alarm',
        key: 'clock',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  date: {
    name: 'date',
    key: 'date',
    icon: 'icon-tuceng-riqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'year',
        key: 'year',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'month',
        key: 'month',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'day',
        key: 'day',
        icon: 'icon-tuceng-shuzi',
        checked: false
      }
    ]
  },
  week: {
    name: 'week',
    key: 'week',
    icon: 'icon-tuceng-xingqi',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'image',
        key: 'text',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      }
    ]
  },
  animation: {
    name: 'frame_amimation',
    key: 'animation',
    icon: 'icon-tuceng-zhendonghua',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'animation~~1',
        key: 'first',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'animation~~2',
        key: 'second',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'animation~~3',
        key: 'third',
        icon: 'icon-tuceng-tupian',
        checked: false
      }
    ]
  },
  stand: {
    name: 'standing_goal',
    key: 'stand',
    icon: 'icon-tuceng-zhanli',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'data',
        key: 'text',
        icon: 'icon-tuceng-shuzi',
        checked: false
      },
      {
        name: 'icon',
        key: 'icon',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'pointer_progress',
        key: 'pointer_progress',
        icon: 'icon-tuceng-zhizhen',
        checked: false
      },
      {
        name: 'image_progress',
        key: 'image_progress',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'jump_area',
        key: 'jumpable',
        icon: 'icon-tuceng-requ',
        checked: false
      }
    ]
  },
  shortcut: {
    name: 'shortcut_features',
    key: 'shortcut',
    icon: 'icon-tuceng-kuaijiegongneng',
    expand: false,
    hide: false,
    order: '',
    children: [
      {
        name: 'music',
        key: 'music',
        icon: 'icon-tuceng-tupian',
        checked: false
      },
      {
        name: 'women_health',
        key: 'woman_health',
        icon: 'icon-tuceng-nvxingjiankang',
        checked: false
      },
      {
        name: 'countdown',
        key: 'count_down',
        icon: 'icon-tuceng-daojishi',
        checked: false
      },
      {
        name: 'stopwatch',
        key: 'stop_watch',
        icon: 'icon-tuceng-miaobiao',
        checked: false
      },
      {
        name: 'pomodoro',
        key: 'pomodoro',
        icon: 'icon-tuceng-fanqiezhong',
        checked: false
      },
      {
        name: 'sport',
        key: 'sport',
        icon: 'icon-tuceng-yundong',
        checked: false
      },
      /* {
      name: '计算器',
      key: 'calculator',
      icon: 'icon-tuceng-jisuanqi',
      checked: false,
    }, */ {
        name: 'voice',
        key: 'voice',
        icon: 'icon-tuceng-yuyin',
        checked: false
      }
    ]
  }
})

export default MENU
