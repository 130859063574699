import React from 'react'
import { Modal } from 'antd'
import tipConf from './config'

class TipForSpecialDevice {
  resolution: string

  modal: any

  static instance: TipForSpecialDevice

  static singleton(resolution: string) {
    if (TipForSpecialDevice.instance) return TipForSpecialDevice.instance

    TipForSpecialDevice.instance = new TipForSpecialDevice(resolution)

    return TipForSpecialDevice.instance
  }

  constructor(resolution: string) {
    this.resolution = resolution
  }

  show() {
    if (!tipConf[this.resolution]) return Promise.resolve('')

    return new Promise((resolve) => {
      this.modal = Modal.warning({
        content: <div>
          {tipConf[this.resolution]}
        </div>,
        closable: false,
        title: 'Warning',
        onOk: () => {
          this.modal.destroy()
          resolve('')
        }
      })
    })
  }
}

const tipForSpecialDeviceSingleton = TipForSpecialDevice.singleton

export default tipForSpecialDeviceSingleton
