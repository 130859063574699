import Immutable from 'immutable'

const BACKGROUND = {
  name: 'background',
  key: 'background',
  icon: 'icon-tuceng-beijing',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const SCALE = {
  name: 'scale',
  key: 'scale',
  icon: 'icon-tuceng-tupian',
  expand: false,
  hide: false,
  canClick: true,
  order: ''
}

const ANALOG_CLOCK = {
  name: 'pointer_time',
  key: 'analog_clock',
  icon: 'icon-tuceng-zhizhenshijian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'hour_pointer',
      key: 'hour',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'min_pointer',
      key: 'minute',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'sec_pointer',
      key: 'second',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    }
  ]
}

const DIGITAL_CLOCK = {
  name: 'digital_time',
  key: 'digital_clock',
  icon: 'icon-tuceng-shuzishijian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'hr',
      key: 'hour',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'min',
      key: 'minute',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'second',
      key: 'second',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'ampm',
      key: 'ampm',
      icon: 'icon-tuceng-wenzi',
      checked: false
    }
  ]
}

const PROGRESS_CLOCK = {
  name: 'progress_time',
  key: 'progress_clock',
  icon: 'icon-tuceng-jindushijian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'hr',
      key: 'hour',
      icon: 'icon-tuceng-jindushijian',
      checked: false
    },
    {
      name: 'min',
      key: 'minute',
      icon: 'icon-tuceng-jindushijian',
      checked: false
    },
    {
      name: 'second',
      key: 'second',
      icon: 'icon-tuceng-jindushijian',
      checked: false
    }
  ]
}

const PAI = {
  name: 'PAI',
  key: 'pai',
  icon: 'icon-tuceng-pai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'week_pai',
      key: 'weekly',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const AQI = {
  name: 'air_quality',
  key: 'aqi',
  icon: 'icon-tuceng-kongqizhiliang',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const HUMIDITY = {
  name: 'humidity',
  key: 'humidity',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const UVI = {
  name: 'uv_degree',
  key: 'uvi',
  icon: 'icon-tuceng-ziwaixian',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const BATTERY = {
  name: 'battery',
  key: 'battery',
  icon: 'icon-tuceng-dianliang',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const HEART_RATE = {
  name: 'heart_rate',
  key: 'heart_rate',
  icon: 'icon-tuceng-xinshuai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const CALORIE = {
  name: 'calorie',
  key: 'calorie',
  icon: 'icon-tuceng-xiaohao',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const STEP = {
  name: 'steps',
  key: 'step',
  icon: 'icon-tuceng-bushu',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'real_time_steps',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'target_steps',
      key: 'target',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'goal_image',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const DISTANCE = {
  name: 'distance',
  key: 'distance',
  icon: 'icon-tuceng-juli',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'data',
      key: 'text',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const WEATHER = {
  name: 'weather',
  key: 'weather',
  icon: 'icon-tuceng-tianqi',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    }
  ]
}

const TEMPERATURE = {
  name: 'temperature',
  key: 'temperature',
  icon: 'icon-tuceng-wendu',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'real_time_temp',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'lowest_temp',
      key: 'low',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'highest_temp',
      key: 'high',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const SUN = {
  name: 'sunrise_sunset',
  key: 'sun',
  icon: 'icon-tuceng-wenjianjiakai',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'current_time',
      key: 'current',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'sunrise',
      key: 'high',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'sunset',
      key: 'low',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'icon',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const SYSTEM = {
  name: 'system',
  key: 'system',
  icon: 'icon-tuceng-xitong',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'DND',
      key: 'dnd',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'bluetooth',
      key: 'disconnect',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'screen_lock',
      key: 'lock',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'alarm',
      key: 'clock',
      icon: 'icon-tuceng-tupian',
      checked: false
    }
  ]
}

const DATE = {
  name: 'date',
  key: 'date',
  icon: 'icon-tuceng-riqi',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'year',
      key: 'year',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'month',
      key: 'month',
      icon: 'icon-tuceng-shuzi',
      checked: false
    },
    {
      name: 'day',
      key: 'day',
      icon: 'icon-tuceng-shuzi',
      checked: false
    }
  ]
}

const WEEK = {
  name: 'week',
  key: 'week',
  icon: 'icon-tuceng-xingqi',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'image',
      key: 'text',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'pointer_progress',
      key: 'pointer_progress',
      icon: 'icon-tuceng-zhizhen',
      checked: false
    },
    {
      name: 'normal_progress',
      key: 'progress',
      icon: 'icon-tuceng-shiliangjindu',
      checked: false
    }
  ]
}

const MESSAGE = {
  name: 'SMS',
  key: 'message',
  icon: 'icon-tuceng-duanxin',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'image',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'image_progress',
      key: 'image_progress',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const PHONE = {
  name: 'phone',
  key: 'phone',
  icon: 'icon-tuceng-dianhua',
  expand: false,
  hide: false,
  order: '',
  children: [
    {
      name: 'image',
      key: 'icon',
      icon: 'icon-tuceng-tupian',
      checked: false
    },
    {
      name: 'jump_area',
      key: 'jumpable',
      icon: 'icon-tuceng-requ',
      checked: false
    }
  ]
}

const MASK = {
  name: 'component_mask',
  key: 'mask',
  icon: 'icon-tuceng-zhezhao',
  expand: false,
  canRemove: false,
  canHide: false,
  canClick: true,
  order: ''
}

const COMPONENT = {
  name: 'editable_component',
  key: 'component',
  icon: 'icon-tuceng-kebianjizujian',
  expand: false,
  hide: false,
  canClick: true,
  canCopy: true,
  order: '',
  multiple: true,
  // 建立一个 n 对 1 的关系
  // 当这个组件第一次添加时 1 也添加
  // 当这个组件全部删除时 1 也删除
  nto1: 'mask',
  children: [MESSAGE, PHONE, DATE, WEEK, BATTERY, HEART_RATE, CALORIE, PAI, STEP, DISTANCE, WEATHER, TEMPERATURE, AQI, SUN, HUMIDITY, UVI]
}

const MENU = {
  background: BACKGROUND,
  scale: SCALE,
  analog_clock: ANALOG_CLOCK,
  digital_clock: DIGITAL_CLOCK,
  progress_clock: PROGRESS_CLOCK,
  pai: PAI,
  aqi: AQI,
  humidity: HUMIDITY,
  uvi: UVI,
  battery: BATTERY,
  heart_rate: HEART_RATE,
  calorie: CALORIE,
  step: STEP,
  distance: DISTANCE,
  weather: WEATHER,
  temperature: TEMPERATURE,
  sun: SUN,
  system: SYSTEM,
  date: DATE,
  week: WEEK,
  message: MESSAGE,
  phone: PHONE,
  component: COMPONENT,
  mask: MASK
}

export default Immutable.fromJS(MENU)
