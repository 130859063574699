import React, { PureComponent } from 'react'
import { MaskComponent } from '../../components'

class Message extends PureComponent {
  static renderLayer = ({ childKey, ...restArgs }) => {
    MaskComponent.renderLayer({ childKey, ...restArgs })
  }

  render() {
    return (
      <div className="control-container">
        <MaskComponent {...this.props} />
      </div>
    )
  }
}

export default Message
