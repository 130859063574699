/* eslint-disable */
import useLocale from '@/hooks/basic-sdk/useLocale'
import { WATCHFACE_LANG } from '@watchface/constants'
import { changeLang, updateI18n } from '@watchface/store/actions'
import { handleApiDomain } from '@watchface/utils'
import { ConfigProvider } from 'antd'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { getUniversalLoginInfo } from '@/utils/universalLogin'
import useLoginRedirectErrorHandler from '@/hooks/huami-login/useLoginRedirectErrorHandler'
import { useUserRegisterInfo } from '@/hooks/basic-sdk/useUserRegisterInfo'
import Header from './components/Header/index.jsx'
import GuardedRoute from './GuardedRoute'
import Create from './pages/Create'
import DesignTool from './pages/DesignTool/NonZeppOs'
import ZeppOsDesignTool from './pages/DesignTool/ZeppOs'
import Open from './pages/Open'
import Feedback from './pages/Feedback'
import Unauthorized from './pages/Unauthorized'
import './watchface.scss'

const isPro = process.env.NODE_ENV === 'production'
const defaultCode = 7
const env = isPro ? 'pro' : 'testing'
const sentryConfig = new window.ApiConfig({
  env,
  defaultCode,
  sentry: {
    pro: {
      // 中国产线
      id: 'b667cd98b8004fa9a5e6567b3c302cab',
      path: '108'
    },
    testing: {
      // 测试环境
      id: '416aef6dd4014d01b13ee18bd13a8733',
      path: '109'
    },
    us: {
      // 产线 us 暂时没有
      id: '',
      path: ''
    },
    in: {
      // 产线 in 暂时没有
      id: '',
      path: ''
    },
    de: {
      // 产线 de 暂时没有
      id: '',
      path: ''
    },
    ru: {
      // 产线 ru 暂时没有
      id: '',
      path: ''
    },
    sg: {
      // 产线 sg 暂时没有
      id: '',
      path: ''
    }
  }
})

sentryConfig.sentryInstall()

let firstExec = true
export default function App(): JSX.Element | null {
  let lang = useSelector((state: any) => state.getIn(['watchFace', 'i18n', 'currentLang'], ''))
  if (lang === 'zh-tw' || lang === 'zh-hk') {
    lang = 'en'
  }
  const [localeData, langInfo] = useLocale('open-platform-watchface', 'en', lang)
  const history = useHistory()
  const { country_code = '' } = useUserRegisterInfo()
  const [isAutheticated, setisAutheticated] = useState(false)
  const isChinaZone = country_code ? country_code.toLowerCase() === 'cn' : undefined

  useLoginRedirectErrorHandler()

  const login = (pathname: string) => {
    setisAutheticated(true)
    history.replace(pathname || '/create')
  }

  useEffect(() => {
    const { domains: domainsFromCookie } = getUniversalLoginInfo()
    handleApiDomain(domainsFromCookie) // set api base url
  }, [])

  if (!lang) {
    lang = langInfo?.currentLang || 'en'
  }

  const dispatch = useDispatch()

  if (firstExec) {
    if (JSON.stringify(localeData) !== '{}') {
      dispatch(updateI18n(localeData))
      firstExec = false
    }
    dispatch(changeLang(lang))
    lang && localStorage.setItem(WATCHFACE_LANG, lang)
  }

  useEffect(() => {
    if (lang === 'zh' || lang === 'zh-cn') {
      document.title = '华米表盘制作工具'
    } else {
      document.title = 'Zepp Watchface'
    }
  }, [lang])

  useEffect(() => {
    !firstExec && dispatch(updateI18n(localeData))
  }, [JSON.stringify(localeData)]) // eslint-disable-line

  if (JSON.stringify(localeData) === '{}') {
    return null
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider
        getPopupContainer={(node: any) => {
          if (node) {
            return node.parentNode
          }
          return document.body
        }}
      >
        <div className="watch-skins-container">
          <IntlProvider locale={lang} messages={localeData}>
            <Header isChinaZone={isChinaZone} />
            <Switch>
              <Route path="/unauthorized" render={() => <Unauthorized login={login} isChinaZone={isChinaZone} />} />
              <GuardedRoute path="/create" component={Create} auth={isAutheticated} />
              <GuardedRoute path="/open" component={Open} auth={isAutheticated} />
              <GuardedRoute path="/designer/:dialId" component={DesignTool} auth={isAutheticated} />
              <GuardedRoute path="/designer-zeppos/:dialId" component={ZeppOsDesignTool} auth={isAutheticated} />
              <GuardedRoute path="/feedback" component={Feedback} auth />
              <Redirect from="*" to="/unauthorized" />
            </Switch>
          </IntlProvider>
        </div>
      </ConfigProvider>
    </DndProvider>
  )
}
