export default `
    {{{ variable }}} = hmUI.createWidget(hmUI.widget.IMG_WEEK, {
      x: {{ position.x }},
      y: {{ position.y }},
      {{ #weekArrayEN }}
      week_en: {{{ weekArrayEN }}},
      {{ /weekArrayEN }}
      {{ #weekArrayTC }}
      week_tc: {{{ weekArrayTC }}},
      {{ /weekArrayTC }}
      {{ #weekArraySC }}
      week_sc: {{{ weekArraySC }}},
      {{ /weekArraySC }}
      show_level: {{ showLevel }},
    });
  `
