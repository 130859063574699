import React, { PureComponent, cloneElement } from 'react'
import Immutable from 'immutable'
import { EditableComponent } from '../../components'

class Editable extends PureComponent {
  static renderLayer = ({ childKey, ...restArgs }) => {
    const { dialType, parentKey, allAssets, layerConfig } = restArgs
    const renderKey = childKey || Editable.getRenderComponent({ dialType, parentKey, allAssets })
    const childKeys = Object.keys((layerConfig.getIn(['component', 'props', renderKey, 'children']) || Immutable.List()).toJS())
    childKeys.forEach((child) => {
      const { data, assets } = Editable.dealData({ ...restArgs, childKey: `${renderKey}-${child}` })
      EditableComponent.renderLayer({ ...restArgs, childKey: `${renderKey}-${child}`, data, assets })
    })
  }

  static dealData = ({ dialType, allData, allAssets, parentKey, childKey }) => {
    if (!childKey) return { data: Immutable.Map(), assets: Immutable.Map() }
    const [component, subKey] = childKey.split('-')
    const path = parentKey.split('_')
    const data = subKey
      ? allData.getIn([...path, 'props', component, 'children', subKey]) || Immutable.Map()
      : allData.getIn([...path, 'props', component]) || Immutable.Map()
    const assets = subKey
      ? allAssets.getIn([dialType, ...path, 'props', component, 'children', subKey]) || Immutable.Map()
      : allAssets.getIn([dialType, ...path, 'props', component]) || Immutable.Map()
    return {
      data,
      assets
    }
  }

  static getRenderComponent = ({ dialType, parentKey, allAssets }) => {
    const previewState = allAssets.getIn([dialType, ...parentKey.split('_'), 'previewState'])
    const previewValue = allAssets.getIn([dialType, ...parentKey.split('_'), 'value'])
    if (previewState === 'previewComponent') {
      return previewValue
    }
    return ''
  }

  render() {
    const { allData, allAssets, parentKey, childKey, CONFIG_MAP, dialType } = this.props
    const { componentMap, idle, normal } = CONFIG_MAP
    const nowConfig = dialType === 'normal' ? normal : idle
    const { layerConfig } = nowConfig
    if (!childKey) {
      return (
        <div className="control-container">
          <EditableComponent layerConfig={layerConfig} {...this.props} />
        </div>
      )
    }

    const [component, subKey] = childKey.split('-')
    const { instance: componentInstance, propMap = {}, constMap = Immutable.Map() } = componentMap(dialType)[component]
    const isHidden = Editable.getRenderComponent({ dialType, parentKey, allAssets }) !== component
    const path = parentKey.split('_')
    const { data, assets } = Editable.dealData({ childKey, allData, allAssets, parentKey, dialType })

    return cloneElement(componentInstance, {
      ...this.props,
      key: dialType,
      // ref: (element) => {
      //     this[parentKey] = element
      // },
      prefixPath: [...path, 'props'],
      keyPrefix: `${dialType}-${path[0]}_${path[1]}-${component}`,
      propMap,
      constMap,
      parentKey: component,
      editableComponentKey: parentKey,
      childKey: subKey,
      allData,
      layerConfig,
      hidden: isHidden,
      data,
      assets
    })
  }
}

export default Editable
