import React from 'react'
import { IconComponent, JumpHotAreaComponent } from '../../components'

const componentMap = {
  icon: IconComponent,
  jumpable: JumpHotAreaComponent
}

class Phone extends React.Component {
  static renderLayer = ({ childKey, ...restArgs }) => {
    const Component = componentMap[childKey]
    Component.renderLayer({ childKey, ...restArgs })
  }

  static removeLayer = ({ childKey, ...restArgs }) => {
    const Component = componentMap[childKey]

    if (Component && Component.removeLayer) {
      Component.removeLayer({ childKey, ...restArgs })
    }
  }

  render() {
    const { childKey, propMap } = this.props
    const prop = propMap[childKey] || {}
    const Component = componentMap[childKey]
    const allProps = { ...this.props, ...prop }

    return <div className="control-container">{Component && <Component {...allProps} />}</div>
  }
}

export default Phone
