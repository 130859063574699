/* eslint-disable import/order */
import React from 'react'
import Immutable from 'immutable'
import { Select } from 'antd'
import { immutableSelector } from '@/utils'
import T from '@watchface/components/I18n'
import { InputNumber, ColorPicker } from '@watchface/components'

const { Option } = Select

class DrawPointer extends React.Component<any> {
  constructor(props: any) {
    super(props)

    this.state = {
      positions: Immutable.List()
    }
  }

  get imgKey() {
    const { parentKey, childKey } = this.props

    return `${parentKey}-${childKey}-polygon`
  }

  handlePointCountChange = (value: number) => {
    const { onRemove } = this.props
    this.changeAssets('pointCount', value)

    this.setState({
      positions: Immutable.List()
    })

    onRemove(this.imgKey)
  }

  handleChangePreview = (value: any) => {
    this.changeAssets('preview', value)
  }

  handlePositionsChange = (path: any[], value: any) => {
    const { parentKey, onDrawPolygonByPoints } = this.props
    const { positions } = this.state as any
    const newPos = positions.setIn(path, value)

    this.setState({
      positions: newPos
    })

    onDrawPolygonByPoints(parentKey, this.imgKey, newPos)
  }

  changeAssets = (key: string, value: any) => {
    const { parentKey, childKey, dialType, onAssetsChange } = this.props

    onAssetsChange([dialType, parentKey, 'children', childKey, key], value)
  }

  renderPositions(count: number) {
    const { watchConfig = Immutable.Map() } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)

    return [...new Array(count)].map((item, index) => (
      <div className="item">
        <div className="col">
          <div className="key">x</div>
          <InputNumber
            min={0}
            max={screenWidth}
            onChange={(value: any) => {
              this.handlePositionsChange([index, 'x'], value)
            }}
          />
        </div>
        <div className="col">
          <div className="key">y</div>
          <InputNumber
            min={0}
            max={screenHeight}
            onChange={(value: any) => {
              this.handlePositionsChange([index, 'y'], value)
            }}
          />
        </div>
      </div>
    ))
  }

  render() {
    const pointCounts: number[] = [3, 4, 5, 6, 7, 8]
    const { namePath = '-', childKey, assets = Immutable.Map() } = this.props
    const name = namePath.split('-')[1]
    const { pointCount = 8, preview = 0 } = immutableSelector(assets)

    return (
      <div className="control-container">
        <div className="name">
          <T id="pos_points_num" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={{ width: '304px' }}
              value={pointCount}
              onChange={this.handlePointCountChange}
            >
              {pointCounts.map((count: number) => (
                <Option value={count}>{count}</Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">{this.renderPositions(pointCount)}</div>
        <div className="name">
          <T id="style" />
        </div>
        <div className="content">
          <div className="item pick-color">
            <div className="col">
              <div className="key">
                <T id="color_val" />
              </div>
              <ColorPicker
                width={200}
                // color={value ? `#${value.slice(4)}` : ''}
                // onInputChange={this.handleColorInputChange}
                // onChangeComplete={this.handleColorChange}
              />
            </div>
          </div>
          <div className="item">
            <div className="col">
              <div className="key">
                <T id="line_width" />
              </div>
              <InputNumber min={0} style={{ width: 208 }} />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="preview_with_name" values={{ name: <T id={name} /> }} />
        </div>
        <div className="content">
          <div className="item">
            <InputNumber max={childKey === 'hour' ? 12 : 60} style={{ width: 310 }} min={0} value={preview} onChange={this.handleChangePreview} />
          </div>
        </div>
      </div>
    )
  }
}

export default DrawPointer
