import { Col, Divider, Input, Row } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

interface Props {
  properties: {
    text: { value: string }
  }
  addonAfter?: React.ReactNode
  className?: string
  onChange: (category: string, property: string, value: any) => void
}

const Text: React.FC<Props> = (props) => {
  const {
    properties: {
      text: { value: text }
    },
    addonAfter,
    className = '',
    onChange
  } = props
  const inputRef = useRef<any>(null)

  // workaround: 解决无法输入中文及输入过程中光标总移至文本末尾
  useEffect(() => {
    const inputEl = inputRef?.current?.input

    if (inputEl) {
      inputEl.value = text
      if (inputRef.current.state) {
        inputRef.current.state.value = text
      }
    }
  }, [text])

  return (
    <div className={classNames('meta-form meta-text', {
      [className]: !!className
    })}>
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={24}>
          <Input
            ref={inputRef}
            style={{ width: 240 }}
            maxLength={200}
            defaultValue={text}
            onChange={(e) => {
              onChange('text', 'text', e?.target?.value)
            }}
          />
          {addonAfter}
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Text
