/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { ALIGN_H, ALIGN_V } from '@watchface/constants'
import { VERTEX_DOT_LINE_STYLE } from '@watchface/constants/graph'
import { RADIO_STYLE, SELECT_STYLE } from '@watchface/constants/style'
import { getDefaultLangField, getOriginLang } from '@watchface/utils'
import { renderCells } from '@watchface/utils/render'
import { Radio, Select } from 'antd'
import Immutable from 'immutable'
import React, { PureComponent } from 'react'

const { Option, OptGroup } = Select
const noop = () => {} // eslint-disable-line

const LANG_MAPPER = {
  all: {
    label: <T id="thumbnail_all" />,
    field: 'all'
  },
  zh: {
    label: <T id="thumbnail_zh" />,
    field: 'zh'
  },
  'zh-Hant': {
    label: <T id="thumbnail_zh-hant" />,
    field: 'zh-Hant'
  },
  sc: {
    label: <T id="thumbnail_zh&zh-hant" />,
    field: 'zh'
  },
  en: {
    label: <T id="thumbnail_en" />,
    field: 'en'
  }
}
class TimeNumberComponentWithRect extends PureComponent {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: TimeNumberComponentWithRect,
      ...param
    })

    TimeNumberComponentWithRect.keyMap = Object.assign(TimeNumberComponentWithRect.keyMap, keyMap)
  }

  static getCellDatas = ({ parentKey, childKey, allData, data, dialType, constMap, allAssets, renderLayerByLang }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const images = TimeNumberComponentWithRect.getImages({
      parentKey,
      childKey,
      dialType,
      allData,
      allAssets,
      constMap,
      renderLayerByLang,
    })

    images.forEach((image) => {
      cells.imageEntity[image.key] = image
    })

    const vertex = TimeNumberComponentWithRect.getVertex({ data, childKey })

    if (vertex) {
      cells.vertexEntity[vertex.key] = vertex
    }

    return cells
  }

  static getImages = ({ parentKey, childKey, dialType, allData, allAssets, constMap, renderLayerByLang }) => {
    const after = constMap.getIn([childKey, 'after']) || Immutable.List()
    let updateKey = childKey

    for (let i = 0; i < after.size; i += 1) {
      const afterKey = after.get(i)
      const combin = allData.getIn([parentKey, 'children', afterKey, 'combin'])

      if (combin !== 'follow') break

      updateKey = afterKey
    }
    const imageArr = TimeNumberComponentWithRect.getImgArrAfterAlign({
      allData,
      allAssets,
      key: updateKey,
      parentKey,
      constMap,
      dialType,
      renderLayerByLang
    })

    const sepImage = TimeNumberComponentWithRect.getSepImage({ allData, parentKey, childKey, constMap })
    if (sepImage) {
      imageArr.push(imageArr)
    }
    return imageArr
  }

  static getSepImage = ({ allData, parentKey, childKey, constMap }) => {
    const showSeparatorPos = TimeNumberComponentWithRect.judgeShowSeparatorPos(allData, parentKey, childKey, constMap)
    if (showSeparatorPos) {
      const separator = allData.getIn([parentKey, 'children', childKey, 'separator']) || Immutable.Map()
      const { position = Immutable.Map(), image = Immutable.Map() } = immutableSelector(separator)
      const { x = 0, y = 0 } = position
      const url = image.get('url')

      if (url) {
        return {
          key: `${childKey}-separator`,
          texture: url,
          left: x,
          top: y
        }
      }
    }
    return null
  }

  static getVertex = ({ data, childKey }) => {
    const rectPath = ['text', 'image', 'rectangle']
    const { position = Immutable.Map(), width = 0, height = 0 } = immutableSelector(data.getIn(rectPath) || Immutable.Map())

    const { x = 0, y = 0 } = immutableSelector(position || Immutable.Map())
    // 如果当前是跟随的那么其实不应该返回 rect
    if (data.get('combin') === 'follow') {
      return null
    }
    return {
      key: `${childKey}-rect`,
      type: 'rect',
      left: x,
      top: y,
      width,
      height,
      fill: 'transparent',
      ...VERTEX_DOT_LINE_STYLE
    }
  }

  static getImgArrAfterAlign = ({ allData = Immutable.Map(), allAssets = Immutable.Map(), key, parentKey, constMap = Immutable.Map(), dialType, renderLayerByLang }) => {
    const data = TimeNumberComponentWithRect.getForefrontData(parentKey, key, allData, constMap)
    const rectangle = data.getIn(['text', 'image', 'rectangle']) || Immutable.Map()
    const rectAlign = data.getIn(['text', 'rect_align']) || Immutable.Map()
    const interval = data.getIn(['text', 'interval']) || 0
    const { horizontal = 'left', vertical = 'top' } = immutableSelector(rectAlign)
    const { width = 1, height = 1 } = immutableSelector(rectangle)

    const imageArr = TimeNumberComponentWithRect.getImageArr({
      allData,
      allAssets,
      key,
      parentKey,
      constMap,
      dialType,
      renderLayerByLang,
    })

    const existImageArr = imageArr
    const areaWidth = existImageArr.reduce((pre, curr, index) => {
      const { width = 0 } = immutableSelector(curr)
      const newInterval = index === existImageArr.size - 1 ? 0 : interval

      return pre + width + newInterval
    }, 0)
    const areaHeight = existImageArr.reduce((pre, curr) => {
      const { height = 0 } = immutableSelector(curr)

      return pre > height ? pre : height
    }, 0)

    let paddingLeft = 0
    let paddingTop = 0

    switch (horizontal) {
      case ALIGN_H.RIGHT:
        paddingLeft = width - areaWidth
        break
      case ALIGN_H.CENTER:
        paddingLeft = (width - areaWidth) / 2
        break
      case ALIGN_H.LEFT:
      default:
        paddingLeft = 0
    }

    switch (vertical) {
      case ALIGN_V.CENTER:
        paddingTop = (height - areaHeight) / 2
        break
      case ALIGN_V.BOTTOM:
        paddingTop = height - areaHeight
        break
      case ALIGN_V.TOP:
      default:
        paddingTop = 0
    }

    if (width < areaWidth) paddingLeft = 0
    if (height < areaHeight) paddingTop = 0

    return imageArr
      .map((item) => {
        const { left, top, url } = immutableSelector(item)

        return item.merge(
          Immutable.Map({
            left: left + paddingLeft,
            top: top + paddingTop,
            texture: url
          })
        )
      })
      .toJS()
  }

  // 获取最前面被跟随模块的数据
  static getForefrontData = (parentKey, childKey, allData = Immutable.Map(), constMap = Immutable.Map()) => {
    const data = allData.getIn([parentKey, 'children', childKey]) || Immutable.Map()
    const nowCombin = data.get('combin') || ''

    // 终止条件为当前的 data combin 为 single
    if (nowCombin !== 'follow') return data

    const before = constMap.getIn([childKey, 'before'])
    const beforeKey = before.get(0)

    return TimeNumberComponentWithRect.getForefrontData(parentKey, beforeKey, allData, constMap)
  }

  static getIndexFromNumber = (value, padding_zero) => {
    const [hour, minute] = `${value}`.split(':')
    if (padding_zero) {
      // 需要先将分钟补全 再补全前面
      const newHour = `${hour}`.padStart(2, '0')
      const newMinute = `${minute}`.padStart(2, '0')

      return `${newHour}:${newMinute}`.split('')
    }
    // 不需要补零的时候不用管整数部分
    return `${hour}:${minute}`.split('')
  }

  static getImageArr = ({ allData = Immutable.Map(), allAssets = Immutable.Map(), renderLayerByLang, key, parentKey, constMap = Immutable.Map(), dialType }) => {
    let imageArr = Immutable.List()
    const basePath = [parentKey, 'children', key]
    const assets = allAssets.getIn([dialType, ...basePath]) || Immutable.Map()
    const { languages = 'all' } = immutableSelector(assets)
    const defaultLang = getDefaultLangField(languages, LANG_MAPPER)
    const previewLang = renderLayerByLang || assets.get('previewLang') || defaultLang
    // 数值总位数
    const valueCount = 5
    const before = constMap.getIn([key, 'before'])
    const supportImage = constMap.getIn([key, 'supportImage'])
    const nowData = allData.getIn(basePath)
    let foreFrontData = nowData
    const combin = nowData.get('combin')

    if (Immutable.List.isList(before) && combin === 'follow') {
      const frontKey = before.get(0)

      before.some((beforeKey) => {
        const beforeCombin = allData.getIn([parentKey, 'children', beforeKey, 'combin'])

        if (beforeCombin !== 'follow') {
          foreFrontData = allData.getIn([parentKey, 'children', beforeKey])
          return true
        }
        return false
      })
      if (frontKey) {
        // 递归获取前面的图片
        const frontImageArr = TimeNumberComponentWithRect.getImageArr({
          allData,
          allAssets,
          key: frontKey,
          parentKey,
          constMap,
          dialType,
          renderLayerByLang,
        })
        imageArr = frontImageArr.concat(imageArr)
      }
    }

    // 获取当前 key 数据
    const { text: currentText = Immutable.Map(), separator = Immutable.Map() } = immutableSelector(nowData)
    const { padding_zero, image: currentImage = Immutable.Map() } = immutableSelector(currentText)
    const { unit_images: unitImages = Immutable.List() } = immutableSelector(currentImage)
    const separatorImage = separator.get('image')

    // 获取最终被跟随 key 数据
    const { text = Immutable.Map() } = immutableSelector(foreFrontData)
    const { image = Immutable.Map(), interval = 0, is_character = false } = immutableSelector(text)
    const isImage = supportImage && is_character
    const hour = assets.getIn(['num', 'hour']) || 0
    const minute = assets.getIn(['num', 'minute']) || 0
    const value = `${hour}:${minute}`
    const {
      font_images = Immutable.List(),
      follow_image: followImage = Immutable.Map(),
      rectangle = Immutable.Map(),
      point_image: pointImage = Immutable.Map()
    } = immutableSelector(image)
    const { position = Immutable.Map() } = immutableSelector(rectangle)
    const { x = 0, y = 0 } = immutableSelector(position)
    const showSeparatorPos = TimeNumberComponentWithRect.judgeShowSeparatorPos(allData, parentKey, key, constMap)
    let fontImagesIndex = 0

    if (isImage) {
      fontImagesIndex = font_images.findIndex((item) => item.get('language') === previewLang)
    }

    const { images: fontImages = Immutable.List() } = immutableSelector(fontImagesIndex === -1 ? Immutable.Map() : font_images.get(fontImagesIndex))
    const indexes = TimeNumberComponentWithRect.getIndexFromNumber(value, padding_zero)
    // 数字图片位数（包括小数点）
    const numCount = indexes.length > valueCount ? valueCount : indexes.length

    // 插入图标
    if (combin !== 'follow') {
      const { url } = immutableSelector(followImage)
      if (url) {
        imageArr = imageArr.push(
          followImage.merge(
            Immutable.Map({
              key: `${parentKey}-${key}-followIcon`,
              left: x,
              top: y,
              texture: url
            })
          )
        )
      }
    }

    // 插入数字图片
    for (let i = 0; i < numCount; i += 1) {
      const index = indexes[i]
      let img = Immutable.Map()

      if (index === ':') {
        img = pointImage || Immutable.Map()
      } else {
        img = fontImages.get(index) || Immutable.Map()
      }

      const beforeImg = imageArr.last()

      if (beforeImg) {
        const { left = 0, top = 0, width = 0 } = immutableSelector(beforeImg)

        img = img.set('left', left + width + interval).set('top', top)
      } else {
        img = img.set('left', x).set('top', y)
      }

      const { url } = immutableSelector(img)
      if (url) {
        img = img.set('key', `fontImage${i}`).set('texture', url)

        imageArr = imageArr.push(img)
      }

      // 插入单位图片
      if (unitImages) {
        const lastImage = imageArr.last() || Immutable.Map()

        const { left = 0, top = 0, width = 0 } = immutableSelector(lastImage)
        let unitImage = unitImages.getIn([0, 'image']) || Immutable.Map()

        if (unitImage.get('url')) {
          unitImage = unitImage.merge(
            Immutable.Map({
              key: `${parentKey}-${key}-unitImage`,
              left: left + width + interval,
              top,
              texture: unitImage.get('url')
            })
          )
          imageArr = imageArr.push(unitImage)
        }
      }

      // 插入间隔符图片
      if (!showSeparatorPos && Immutable.Map.isMap(separatorImage)) {
        const lastImage = imageArr.last() || Immutable.Map()
        const { left = 0, top = 0, width = 0 } = immutableSelector(lastImage)

        if (separatorImage.get('url')) {
          imageArr = imageArr.push(
            separatorImage.merge(
              Immutable.Map({
                key: `${key}-separator`,
                left: left + width + interval,
                top,
                texture: separatorImage.get('url')
              })
            )
          )
        }
      }
    }

    return imageArr
  }

  // 跟随及被跟随模块禁止隐藏
  static judgeShowSeparatorPos = (allData, parentKey, childKey, constMap) => {
    const isImmutableMap = Immutable.Map.isMap

    if (!isImmutableMap(allData) || !isImmutableMap(constMap)) return false

    const combin = allData.getIn([parentKey, 'children', childKey, 'combin'])
    const afterKey = constMap.getIn([childKey, 'after', 0])
    const afterCombin = allData.getIn([parentKey, 'children', afterKey, 'combin'])

    return !(combin === 'follow' || afterCombin === 'follow')
  }

  componentDidUpdate(preProps) {
    const { allData: preAllData, data: preData } = preProps
    const { allData, data, parentKey, childKey } = this.props
    const font_images_path = ['text', 'image', 'font_images']
    const combin_path = [parentKey, 'children', childKey, 'combin']
    const fontImages = data.getIn(font_images_path) || Immutable.List()
    const preFontImages = preData.getIn(font_images_path) || Immutable.List()
    const combin = allData.getIn(combin_path)
    const preCombin = preAllData.getIn(combin_path)
    const follow = preCombin !== combin && combin === 'follow'
    const isFontImagesEqual = this.compareFontImageList(preFontImages, fontImages)

    TimeNumberComponentWithRect.renderLayer(this.props)
    if (follow || !isFontImagesEqual) {
      const direct = combin === 'follow' ? 'before' : 'after'

      this.syncCombinFontImages(data, direct)
    }
  }

  compareFontImageList = (fontImages, newFontImages) => {
    const isImmutableList = Immutable.List.isList
    const isImmutableMap = Immutable.Map.isMap

    if (!isImmutableList(fontImages) || !isImmutableList(newFontImages)) return false
    if (fontImages.size !== newFontImages.size) return false

    try {
      return fontImages.some((fontImage) => {
        const { language } = immutableSelector(fontImage)
        const newFontImage = newFontImages.find((newFontImage) => newFontImage.get('language') === language)

        if (!newFontImage) return false
        if (!isImmutableMap(fontImage) || !isImmutableMap(newFontImage)) return false

        return Immutable.is(fontImage, newFontImage)
      })
    } catch (e) {
      console.log(e)
    }
  }

  handleChange = (path, prop, isAssetsValue, val) => {
    let value = val
    const {
      data,
      onChange = noop,
      assets = Immutable.Map(),
      onAssetsChange = noop,
      childKey,
      dialType,
      allData,
      parentKey,
      allAssets,
      changeCallback
    } = this.props
    let newData = data
    let newAssets = assets
    let newAllAssets = allAssets

    if (prop === 'is_character') {
      value = !!value
      newAssets = newAssets.setIn(['num', 'value'], value ? 1 : 0).set('alreadyImageKey', '')
      // 图片类型 和 （补零 和 间距 和 图片）互斥 清除数据
      newData = newData
        .setIn([...path, 'padding_zero'], false)
        .setIn([...path, 'interval'], 0)
        .setIn([...path, 'image', 'font_images'], Immutable.List())
    }

    newData = newData.setIn([...path, prop], value)

    const rootPath = [parentKey, 'children']

    if (isAssetsValue) {
      newAssets = newAssets.setIn([...path, prop], value)
    }

    if (prop === 'combin') {
      this.changeAssets('alreadyImageKey', '')

      const paths = [
        {
          path: ['text', 'image', 'position'],
          defaultValue: Immutable.Map({ x: 0, y: 0 })
        },
        {
          path: ['text', 'interval'],
          defaultValue: 0
        },
        {
          path: ['text', 'align'],
          defaultValue: 'left'
        },
        {
          path: ['text', 'is_character'],
          defaultValue: false
        }
      ]

      if (value === 'follow') {
        // 跟随时删除部分参数
        paths.forEach(({ path }) => {
          newData = newData.deleteIn(path)
        })
      } else {
        // 独立时恢复默认值
        paths.forEach(({ path, defaultValue }) => {
          newData = newData.setIn(path, defaultValue)
        })
        newData = newData.setIn(['text', 'image', 'font_images'], Immutable.List())
      }
    }

    newAllAssets = allAssets.setIn([dialType, ...rootPath, childKey], newAssets)

    onAssetsChange([dialType, ...rootPath, childKey], newAssets)
    onChange([...rootPath, childKey], newData)

    const newAllData = allData.setIn([...rootPath, childKey], newData)

    changeCallback && changeCallback({ allData: newAllData, allAssets: newAllAssets })
  }

  /**
   * 更新页面图片显示
   *
   * @param {Immutable.Map} newData
   * @param {Immutable.Map} newAllAssets
   */

  handleRadioChange = (e) => {
    const { value } = e.target

    this.resetFontImages()
    this.changeAssets('useImage', value)
    this.changeAssets('alreadyImageKey', '')
  }

  handleCheckChange = (path, prop, isAssetsValue, value, e) => {
    e.preventDefault()
    this.handleChange(path, prop, isAssetsValue, !value)
  }

  changeAssets = (key, value) => {
    const { parentKey, childKey, dialType, onAssetsChange } = this.props

    onAssetsChange([dialType, parentKey, 'children', childKey, key], value)
  }

  resetFontImages = () => {
    const { parentKey, childKey, data, onChange } = this.props
    const font_images_path = ['text', 'image', 'font_images']
    const newData = data.setIn(font_images_path, Immutable.List())

    onChange([parentKey, 'children', childKey], newData)
  }

  getFontImageArr = (fontImages) => {
    return fontImages
      .reduce((pre, fontImage) => {
        const { images = Immutable.List() } = immutableSelector(fontImage)
        return pre.concat(
          images.map((image) => {
            const { url, width, height, name } = immutableSelector(image)
            return {
              image: url,
              width,
              height,
              name
            }
          })
        )
      }, Immutable.List())
      .toJS()
  }

  handleChangeImage = (path, prop, isAssetsValue, img) => {
    if (img) {
      this.handleChange(path, prop, isAssetsValue, Immutable.fromJS({ url: img.image, width: img.width, height: img.height }))
    } else {
      this.handleChange(path, prop, isAssetsValue, Immutable.fromJS({ url: '', width: 0, height: 0 }))
    }
  }

  handleChangeInvalidImage = (path, prop, isAssetsValue, img) => {
    if (img) {
      this.handleChange(path, prop, isAssetsValue, img.image)
    } else {
      this.handleChange(path, prop, isAssetsValue, '')
    }
  }

  handlePunchChangeImage = (updateUploadedImages = true, image, lang = 'all') => {
    const {
      childKey,
      dialType,
      changeCallback,
      data,
      onChange = noop,
      parentKey,
      namePath,
      onAssetsChange,
      assets,
      allAssets,
      allData,
      constMap
    } = this.props
    const alreadyImageKey = assets.get('alreadyImageKey') || ''
    const font_images_path = ['text', 'image', 'font_images']
    const font_images = data.getIn(font_images_path) || Immutable.List()
    const imgArr = Immutable.fromJS(
      image.map((i) => {
        const { width, height, image, name } = i
        return {
          url: image,
          width,
          height,
          name
        }
      })
    )
    const originLangImagesIndex = font_images.findIndex((item) => item.get('language') === lang)
    let newData = data
    let newAllAssets = allAssets

    if (imgArr.size === 0) {
      newAllAssets = newAllAssets.setIn([dialType, parentKey, 'children', childKey, 'alreadyImageKey'], '')
    }

    if (updateUploadedImages) {
      const defaultImageKey = `${dialType}@_@${parentKey}-${childKey}`
      const keyPath = (alreadyImageKey || defaultImageKey).split('@_@')
      const basePath = ['uploadedImages', ...keyPath]
      let imageName = newAllAssets.getIn([...basePath, 'name']) || namePath

      if (imgArr.size === 0) imageName = namePath

      const newUploadData = Immutable.fromJS({
        name: imageName,
        images: imgArr
      })

      newAllAssets = newAllAssets.setIn(basePath, newUploadData)
    }

    if (originLangImagesIndex > -1) {
      font_images_path.push(originLangImagesIndex)
      newData = data.setIn(
        font_images_path,
        Immutable.fromJS({
          language: lang,
          images: imgArr
        })
      )
    } else {
      newData = data.setIn(
        font_images_path,
        Immutable.fromJS([
          ...font_images,
          {
            language: lang,
            images: imgArr
          }
        ])
      )
    }

    onChange([parentKey, 'children', childKey], newData)

    const newAllData = allData.setIn([parentKey, 'children', childKey], newData)

    changeCallback && changeCallback({ allData: newAllData, allAssets: newAllAssets, constMap })
    onAssetsChange([], newAllAssets)
  }

  /**
   *  同步跟随和被跟随者的图片 List 数据
   *
   *  @param {Immutable.Map} data
   *  @param {string} direction
   */
  syncCombinFontImages = (data, direction = 'after') => {
    const { allData, parentKey, childKey, constMap = Immutable.Map(), onChange } = this.props
    const arr = constMap.getIn([childKey, direction]) || Immutable.List()

    if (!arr.size) return

    const fontImagePath = ['text', 'image', 'font_images']

    if (direction === 'after') {
      try {
        const baseImages = data.getIn(fontImagePath)

        for (let i = 0; i < arr.size; i += 1) {
          const key = arr.get(i)
          const crtData = allData.getIn([parentKey, 'children', key])
          const combin = crtData.get('combin')

          if (combin === 'follow') {
            const newData = crtData.setIn(fontImagePath, baseImages)

            onChange([parentKey, 'children', key], newData)
          } else {
            break
          }
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        let i = 0
        for (; i < arr.size; i += 1) {
          const key = arr.get(i)
          const crtData = allData.getIn([parentKey, 'children', key])
          const combin = crtData.get('combin')

          if (combin !== 'follow') {
            break
          }
        }

        if (i < arr.size) {
          const baseData = allData.getIn([parentKey, 'children', arr.get(i)])
          const baseImages = baseData.getIn(fontImagePath)

          for (let j = i - 1; j >= 0; j -= 1) {
            const crtData = allData.getIn([parentKey, 'children', arr.get(j)])
            const newData = crtData.setIn(fontImagePath, baseImages)

            onChange([parentKey, 'children', arr.get(j)], newData)
          }

          const crtData = allData.getIn([parentKey, 'children', childKey])
          const newData = crtData.setIn(fontImagePath, baseImages)
          onChange([parentKey, 'children', childKey], newData)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  handleAlreadyImageChange = (key) => {
    const { allAssets, data, onChange, childKey, dialType, parentKey, onAssetsChange } = this.props
    const { uploadedImages = Immutable.Map() } = immutableSelector(allAssets)
    const keyPath = (key || '').split('@_@')
    const images = key ? uploadedImages.getIn([...keyPath, 'images']) : Immutable.List()
    const newData = data.setIn(
      ['text', 'image', 'font_images'],
      Immutable.fromJS([
        {
          language: 'all',
          images
        }
      ])
    )
    let newAllAssets = allAssets

    newAllAssets = newAllAssets.setIn([dialType, parentKey, 'children', childKey, 'alreadyImageKey'], key)

    onChange([parentKey, 'children', childKey], newData)
    onAssetsChange([], newAllAssets)
  }

  handleCropImageNameChange = (name) => {
    const { onAssetsChange, parentKey, childKey, assets, dialType } = this.props
    const defaultKey = `${dialType}@_@${parentKey}-${childKey}`
    const alreadyImageKey = assets.get('alreadyImageKey') || defaultKey
    const path = alreadyImageKey.split('@_@')

    onAssetsChange(['uploadedImages', ...path, 'name'], name)
  }

  renderAlreadyImage = () => {
    const { allAssets = Immutable.Map(), assets, dialType } = this.props
    const { uploadedImages = Immutable.Map() } = immutableSelector(allAssets)
    const alreadyImageKey = assets.get('alreadyImageKey') || ''
    const path = alreadyImageKey.split('@_@')
    const alreadyImageName = (uploadedImages || Immutable.Map()).getIn([...path, 'name'])
    const className = alreadyImageKey ? 'item item-clear-padding-bottom' : 'item'
    const isNormal = dialType === 'normal'
    let hasAlreadyImageKey = false

    const renderOptions = (dialType) => {
      const data = uploadedImages.get(dialType) || Immutable.Map()
      const options = data.filter((item) => (item.get('images') || Immutable.List()).size).keySeq()

      return options.map((key) => {
        const imagesKey = `${dialType}@_@${key}`
        if (imagesKey === alreadyImageKey) hasAlreadyImageKey = true
        const path = [dialType, key]
        const imagesName = uploadedImages.getIn([...path, 'name'])

        return (
          <Option key={imagesKey} value={imagesKey}>
            {imagesName}
          </Option>
        )
      })
    }

    const normalOptions = renderOptions('normal')
    const idleOptions = renderOptions('idle')
    const optGroup = []

    if (normalOptions.size) {
      optGroup.push(
        <OptGroup label={<T id="normal" />} key="normal">
          {normalOptions}
        </OptGroup>
      )
    }
    if (idleOptions.size) {
      optGroup.push(
        <OptGroup label={<T id="idle" />} key="idle">
          {idleOptions}
        </OptGroup>
      )
    }

    const order = isNormal ? [0, 1] : [1, 0]

    return (
      <>
        <div className="name">
          <T id="uploaded_image" />
        </div>
        <div className="content">
          <div className={className}>
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={hasAlreadyImageKey ? alreadyImageKey : alreadyImageName}
              allowClear={!!alreadyImageKey}
              onChange={this.handleAlreadyImageChange}
            >
              {order.map((idx) => optGroup[idx])}
            </Select>
          </div>
        </div>
      </>
    )
  }

  getShowValueMax = (valueCount, stepCount) => {
    if (valueCount) {
      const decimal = +(0.1 ** stepCount).toFixed(stepCount)
      return stepCount ? 10 ** (valueCount - stepCount - 1) - decimal : 10 ** valueCount - 1
    }

    return Infinity
  }

  getIsCharacter = (combin) => {
    const { parentKey, childKey, data = Immutable.Map(), allData = Immutable.Map(), constMap = Immutable.Map() } = this.props
    const isCharacterPath = ['text', 'is_character']
    const is_character = data.getIn(isCharacterPath) || false

    if (combin === 'single') return is_character

    const before = constMap.getIn([childKey, 'before']) || Immutable.List()

    if (!before.size) return is_character

    let i = 0
    for (; i < before.size; i += 1) {
      const beforeKey = before.get(i)
      const beforeCombin = allData.getIn([parentKey, 'children', beforeKey, 'combin'])

      if (beforeCombin && beforeCombin !== 'follow') break
    }

    if (i < before.size) {
      const beforeKey = before.get(i)

      return allData.getIn([parentKey, 'children', beforeKey, ...isCharacterPath])
    }

    return is_character
  }

  getFollowingData = (combin = 'single', path = [], isAssets, defaultValue) => {
    const {
      parentKey,
      childKey,
      assets = Immutable.Map(),
      data = Immutable.Map(),
      allData = Immutable.Map(),
      allAssets = Immutable.Map(),
      constMap = Immutable.Map()
    } = this.props

    const dataSource = isAssets ? assets : data
    const allDataSource = isAssets ? allAssets : allData
    const pathData = dataSource.getIn(path, defaultValue)

    if (combin === 'single') return pathData

    const before = constMap.getIn([childKey, 'before']) || Immutable.List()

    if (!before.size) return pathData

    let i = 0
    for (; i < before.size; i += 1) {
      const beforeKey = before.get(i)
      const beforeCombin = allData.getIn([parentKey, 'children', beforeKey, 'combin'])

      if (beforeCombin && beforeCombin !== 'follow') break
    }

    if (i < before.size) {
      const beforeKey = before.get(i)

      return allDataSource.getIn([parentKey, 'children', beforeKey, ...path], defaultValue)
    }

    return pathData
  }

  handleLangChange = (key, value) => {
    const { parentKey, childKey, data, onChange } = this.props
    let newData = data

    if (key === 'languages') {
      const font_images_path = ['text', 'image', 'font_images']
      newData = data.setIn(font_images_path, Immutable.List())

      onChange([parentKey, 'children', childKey], newData)
      this.changeAssets('previewLang', '')
    }

    this.changeAssets(key, value)
  }

  handleSepChange = (path, prop, value) => {
    const { onChange = noop, data, childKey, parentKey } = this.props
    const newData = data.setIn([...path, prop], value)
    const rootPath = [parentKey, 'children']

    onChange([...rootPath, childKey], newData)
  }

  handleChangeSepImage = (showSeparatorPos, img) => {
    if (showSeparatorPos) {
      const { parentKey, childKey, data, onChange = noop } = this.props
      const { image = '', width = 0, height = 0 } = img || {}
      const imgPath = ['separator', 'image']
      const newData = data.setIn(imgPath, Immutable.fromJS({ url: image, height, width }))

      onChange([parentKey, 'children', childKey], newData)
    } else {
      this.handleChangeImage(['separator'], 'image', false, img)
    }
  }

  renderUploadByLang = (languages) => {
    const { watchConfig, data } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const fontImages = data.getIn(['text', 'image', 'font_images']) || Immutable.List()

    return languages.split(',').map((lang) => {
      const { label, field } = LANG_MAPPER[lang] || {}
      const imagesObj = fontImages.find((images) => images.get('language') === field) || Immutable.Map()
      const images = imagesObj
        .get('images', Immutable.List())
        .toJS()
        .map(({ url, height, width, name }) => ({ image: url, height, width, name }))
      const key = `${languages}-${lang}`

      return (
        <div key={key}>
          <div className="name">{label}</div>
          <div className="content">
            <div className="item">
              <Upload
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={images}
                field={field}
                multiple
                onChange={(...args) => {
                  this.handlePunchChangeImage(false, ...args)
                }}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  renderMultipleLangPic() {
    const { assets, metas } = this.props
    const originLang = getOriginLang(metas)
    const { languages = originLang } = immutableSelector(assets)
    return (
      <div>
        {originLang === languages ? null : (
          <div>
            <div className="name">
              <T id="image" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  style={SELECT_STYLE}
                  value={languages}
                  disabled
                  onChange={(value) => {
                    this.handleLangChange('languages', value)
                  }}
                >
                  <Option value="all">
                    <T id="lang_all" />
                  </Option>
                  <Option value="zh,zh-Hant,en">
                    <T id="lang_zh" />、<T id="lang_zh-hant" />、<T id="lang_en" />
                  </Option>
                  <Option value="sc,en">
                    <T id="lang_zh&zh-hant" />、<T id="lang_en" />
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )}
        {this.renderUploadByLang(languages)}
      </div>
    )
  }

  renderRect = () => {
    const { watchConfig, data } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const rectangle = data.getIn(['text', 'image', 'rectangle']) || Immutable.Map()
    const { position = Immutable.Map(), width = 1, height = 1 } = immutableSelector(rectangle)
    const { x = 0, y = 0 } = immutableSelector(position || Immutable.Map())
    const rectPath = ['text', 'image', 'rectangle']

    return (
      <>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={x}
                onChange={(value) => {
                  this.handleChange([...rectPath, 'position'], 'x', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={y}
                onChange={(value) => {
                  this.handleChange([...rectPath, 'position'], 'y', false, value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="area_size" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">W</div>
              <InputNumber
                min={1}
                max={screenWidth}
                value={width}
                onChange={(value) => {
                  this.handleChange(rectPath, 'width', false, value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">H</div>
              <InputNumber
                min={1}
                max={screenHeight}
                value={height}
                onChange={(value) => {
                  this.handleChange(rectPath, 'height', false, value)
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    const {
      allAssets = Immutable.Map(),
      allData = Immutable.Map(),
      parentKey,
      childKey,
      data,
      dialType,
      namePath,
      constMap = Immutable.Map(),
      hiddenCombin,
      assets = Immutable.Map(),
      watchConfig,
      renderLayerByLang,
      invalidImageName = <T id="invalid_data" />,
      hideUnitImage = false,
      hideSeparatorImage = false,
      hasPaddingZero = false,
      hasFollowIcon = false
    } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const name = constMap.getIn([childKey, 'name']) || ''
    const { combin = 'single', text = Immutable.Map(), separator = Immutable.Map() } = immutableSelector(data)
    const is_character = this.getFollowingData(combin, ['text', 'is_character'], false, false)
    const supportImage = constMap.getIn([childKey, 'supportImage'])
    const isImage = !!(is_character && supportImage)
    const hasInvalidImage = constMap.getIn([childKey, 'hasInvalidImage'], true)
    const invalidImageRequired = constMap.getIn([childKey, 'invalidImageRequired'], false)
    const hour = assets.getIn(['num', 'hour']) || 0
    const minute = assets.getIn(['num', 'minute']) || 0
    const useImage = assets.get('useImage') || 'new'
    const disableFollow = assets.get('disableFollow') || false
    const { uploadedImages = Immutable.Map() } = immutableSelector(allAssets)
    const { rect_align = Immutable.Map(), image = Immutable.Map(), interval = 0, padding_zero } = immutableSelector(text)
    const { horizontal = 'left', vertical = 'top' } = immutableSelector(rect_align)
    const { image: sepImage, position: sepPosition } = immutableSelector(separator)
    const { url: sepUrl } = immutableSelector(sepImage)
    const { x: sepX, y: sepY } = immutableSelector(sepPosition)
    const {
      font_images: fontImages = Immutable.List(),
      unit_images: unitImages = Immutable.List(),
      point_image: pointImage = Immutable.Map(),
      follow_image: followIcon = Immutable.Map()
    } = immutableSelector(image)
    const { image: unitImage = Immutable.Map() } = immutableSelector((unitImages || Immutable.List()).get(0, Immutable.Map()))
    const { url: unitUrl } = immutableSelector(unitImage)
    const { url: pointUrl } = immutableSelector(pointImage)
    const { url: followIconUrl } = immutableSelector(followIcon)
    const fontImageArr = this.getFontImageArr(fontImages)
    const firstKey = constMap.keySeq().get(0)
    const invalidImagePath = ['text', 'image', 'invalid_image']
    const invalidImage = data.getIn(invalidImagePath, '')
    const alreadyImageKey = assets.get('alreadyImageKey') || ''
    const path = alreadyImageKey ? alreadyImageKey.split('@_@') : [dialType, `${parentKey}-${childKey}`]
    const cropImageName = (uploadedImages || Immutable.Map()).getIn([...path, 'name']) || namePath
    const languages = this.getFollowingData(combin, ['languages'], true, 'all')
    const defaultLang = getDefaultLangField(languages, LANG_MAPPER)
    const previewLang = renderLayerByLang || assets.get('previewLang') || defaultLang
    const showRemoveIcon = useImage === 'new' || alreadyImageKey === `${dialType}@_@${parentKey}-${childKey}`
    const showSeparatorPos = TimeNumberComponentWithRect.judgeShowSeparatorPos(allData, parentKey, childKey, constMap)

    return (
      <>
        {hiddenCombin ? null : (
          <>
            <div className="name">
              <T id="primary_secondary_relationship" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  value={combin}
                  style={SELECT_STYLE}
                  onChange={(value) => {
                    this.handleChange([], 'combin', false, value)
                  }}
                >
                  <Option value="single">
                    <T id="independent" />
                  </Option>
                  <Option value="follow" disabled={disableFollow || firstKey === childKey}>
                    <T id="follow" />
                  </Option>
                </Select>
              </div>
            </div>
          </>
        )}
        {invalidImageRequired || hasInvalidImage ? (
          <>
            <div className={invalidImageRequired ? 'name is-required' : 'name'}>{invalidImageName}</div>
            <div className="content">
              <div className="item">
                <div className="col col-v">
                  <Upload
                    className="item"
                    fileCountLimit={1}
                    maxWidth={screenWidth}
                    maxHeight={screenHeight}
                    fileList={invalidImage ? [{ image: invalidImage }] : []}
                    onChange={(image) => {
                      this.handleChangeInvalidImage(['text', 'image'], 'invalid_image', false, image)
                    }}
                  />
                  <T id="upload_image" />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {combin === 'single' && supportImage ? (
          <>
            <div className="name">
              <T id="display_method" />
            </div>
            <div className="content">
              <div className="item">
                <Select
                  dropdownClassName="watch-skin-select-dropdown"
                  value={is_character ? 1 : 0}
                  style={SELECT_STYLE}
                  onChange={(value) => {
                    this.handleChange(['text'], 'is_character', false, value)
                  }}
                >
                  <Option value={0} disabled={parentKey === 'weather'}>
                    <T id="number" />
                  </Option>
                  <Option value={1}>图片</Option>
                </Select>
              </div>
            </div>
          </>
        ) : null}
        {combin === 'single' && hasFollowIcon ? (
          <>
            <div className="name">
              <T id="icon" />
            </div>
            <div className="content">
              <div className="item">
                <div className="col col-v">
                  <Upload
                    fileCountLimit={1}
                    maxWidth={screenWidth}
                    maxHeight={screenHeight}
                    fileList={followIconUrl ? [{ image: followIconUrl }] : []}
                    help={
                      <div style={{ textAlign: 'center' }}>
                        <T id="upload_image" />
                      </div>
                    }
                    onChange={(image) => {
                      this.handleChangeImage(['text', 'image'], 'follow_image', false, image)
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {combin === undefined || combin === 'single' ? (
          <>
            {!is_character ? (
              <>
                <div className="name">
                  <T id="number_slice" />
                </div>
                <div className="content">
                  <div className="item">
                    <Radio.Group value={useImage} onChange={this.handleRadioChange}>
                      <Radio style={RADIO_STYLE} value="already">
                        <T id="use_uplod_img" />
                      </Radio>
                      <Radio style={RADIO_STYLE} value="new">
                        <T id="use_new_img" />
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <>
                  {useImage === 'new' ? (
                    <div className="name is-required">
                      <T id="upload_image" />
                    </div>
                  ) : (
                    this.renderAlreadyImage()
                  )}
                  {useImage === 'new' || fontImageArr.length > 0 ? (
                    <div className="content">
                      <div className="item">
                        <Upload
                          key={useImage === 'new' ? useImage : alreadyImageKey}
                          multiple
                          fileCountLimit={supportImage ? Infinity : 10}
                          maxWidth={screenWidth}
                          maxHeight={screenHeight}
                          fileList={fontImageArr}
                          field="all"
                          onChange={(...args) => {
                            this.handlePunchChangeImage(true, ...args)
                          }}
                          defineCropImageName
                          cropImageName={cropImageName}
                          showRemoveIcon={showRemoveIcon}
                          handleCropImageNameChange={this.handleCropImageNameChange}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              </>
            ) : null}
            {this.renderRect()}
            {isImage && this.renderMultipleLangPic()}
            {isImage ? null : (
              <>
                <div className="name">
                  <T id="alignment" />
                </div>
                <div className="content">
                  <div className="item">
                    <Select
                      dropdownClassName="watch-skin-select-dropdown"
                      value={horizontal}
                      style={SELECT_STYLE}
                      onChange={(value) => {
                        this.handleChange(['text', 'rect_align'], 'horizontal', false, value)
                      }}
                    >
                      <Option value={ALIGN_H.LEFT}>
                        <T id="align_left" />
                      </Option>
                      <Option value={ALIGN_H.CENTER}>
                        <T id="horizontal_center" />
                      </Option>
                      <Option value={ALIGN_H.RIGHT}>
                        <T id="align_right" />
                      </Option>
                    </Select>
                  </div>
                  <div className="item">
                    <Select
                      dropdownClassName="watch-skin-select-dropdown"
                      value={vertical}
                      style={SELECT_STYLE}
                      onChange={(value) => {
                        this.handleChange(['text', 'rect_align'], 'vertical', false, value)
                      }}
                    >
                      <Option value={ALIGN_V.TOP}>
                        <T id="align_top" />
                      </Option>
                      <Option value={ALIGN_V.CENTER}>
                        <T id="vertical_center" />
                      </Option>
                      <Option value={ALIGN_V.BOTTOM}>
                        <T id="align_bottom" />
                      </Option>
                    </Select>
                  </div>
                </div>
              </>
            )}
            {isImage ? null : (
              <>
                <div className="name">
                  <T id="space" />
                </div>
                <div className="content">
                  <div className="item">
                    <InputNumber
                      min={0}
                      value={interval}
                      style={SELECT_STYLE}
                      onChange={(value) => {
                        this.handleChange(['text'], 'interval', false, value)
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}
        {isImage || !hasPaddingZero ? null : (
          <>
            <div className="name">
              <T id="format" />
            </div>
            <div className="content">
              <div
                className="item"
                onClick={(e) => {
                  this.handleCheckChange(['text'], 'padding_zero', false, padding_zero, e)
                }}
              >
                <Radio checked={padding_zero} style={RADIO_STYLE}>
                  <T id="whether_padding_zero" />
                </Radio>
              </div>
            </div>
          </>
        )}
        {hideUnitImage ? null : (
          <>
            <div className="name">
              <T id="unit_image" />
            </div>
            <div className="content">
              <div className="item">
                <div className="col col-v">
                  <Upload
                    fileCountLimit={1}
                    maxWidth={screenWidth}
                    maxHeight={screenHeight}
                    fileList={unitUrl ? [{ image: unitUrl }] : []}
                    help={
                      <div style={{ textAlign: 'center' }}>
                        <T id="upload_image" />
                      </div>
                    }
                    onChange={(image) => {
                      this.handleChangeImage(['text', 'image', 'unit_images', 0], 'image', false, image)
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="name">
          <T id="colon_image" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={pointUrl ? [{ image: pointUrl }] : []}
                help={
                  <div style={{ textAlign: 'center' }}>
                    <T id="upload_image" />
                  </div>
                }
                onChange={(image) => {
                  this.handleChangeImage(['text', 'image'], 'point_image', false, image)
                }}
              />
            </div>
          </div>
        </div>
        {hideSeparatorImage ? null : (
          <>
            <div className="name">
              <T id="data_type_image" />
            </div>
            <div className="content">
              <div className="item">
                <Upload
                  fileCountLimit={1}
                  maxWidth={screenWidth}
                  maxHeight={screenHeight}
                  fileList={sepUrl ? [{ image: sepUrl }] : []}
                  help={
                    <div style={{ textAlign: 'center' }}>
                      <T id="upload_image" />
                    </div>
                  }
                  onChange={(image) => {
                    this.handleChangeSepImage(showSeparatorPos, image)
                  }}
                />
              </div>
            </div>
            {showSeparatorPos ? (
              <>
                <div className="content">
                  <div className="item">
                    <div className="col">
                      <div className="key">x</div>
                      <InputNumber
                        min={0}
                        max={screenWidth}
                        value={sepX}
                        onChange={(value) => {
                          this.handleSepChange(['separator', 'position'], 'x', value)
                        }}
                      />
                    </div>
                    <div className="col">
                      <div className="key">y</div>
                      <InputNumber
                        min={0}
                        max={screenHeight}
                        value={sepY}
                        onChange={(value) => {
                          this.handleSepChange(['separator', 'position'], 'y', value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}
        {isImage ? (
          <div className="content">
            <div className="item">
              <T id="preview_lang" />
            </div>
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={previewLang}
              onChange={(value) => {
                this.handleLangChange('previewLang', value)
              }}
            >
              {languages.split(',').map((lang) => {
                const { label, field } = LANG_MAPPER[lang] || {}

                return (
                  <Option key={field} value={field}>
                    {label}
                  </Option>
                )
              })}
            </Select>
          </div>
        ) : null}
        <div className="content">
          <div className="item">
            <T id="preview_with_name" values={{ name: <T id={name} /> }} />
          </div>
          <div className="item">
            <div className="col">
              <InputNumber
                min={0}
                max={23}
                value={hour}
                onChange={(value) => {
                  this.handleChange(['num'], 'hour', true, value)
                }}
              />
              <span>
                &nbsp;
                <T id="hr" />
              </span>
            </div>
            <div className="col">
              <InputNumber
                min={0}
                max={59}
                value={minute}
                onChange={(value) => {
                  this.handleChange(['num'], 'minute', true, value)
                }}
              />
              <span>
                &nbsp;
                <T id="min" />
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TimeNumberComponentWithRect
