import { stringify } from 'query-string'
import { environment, HuaMi } from '@/config/environment'
import requestCreater from '@/utils/request'
import { changeParams } from '@/utils/requestHelper'
import { Env } from '@/utils/getEnv'
import regExp from '@/config/regExp'
import { getApiHost, getUniversalLoginInfo } from '@/utils/universalLogin'
// import { URLSearchParamsHelper } from '@/utils/query-string';
/**
 * api接口方法定义规范
 * (params:{...query,...body,...header})=>{}
 *
 */

/**
 * 1\获取图片验证码
 * 2\校验图片验证码
 * 3\校验手机验证码
 * 4\账号系统：判断用户是否存在
 * 5\华米账号注册
 * 6\?不确定是什么注册,需要看文档确认
 * 7\清除密码
 * 8\重置密码
 * 9\注销登录
 */

// 注册流程：
// 1、校验手机或邮箱是否已存在（如已存在则提示是否登录）
// 2- phone、手机号没注册则校验验证码
// 2- email 用户注册
// 3、注册 Register

let accountHost = 'https://account.huami.com'
let apiUserHost = 'https://api-user.huami.com/'
if (Env === 'test' || Env === 'development') {
  accountHost = 'https://account-staging.huami.com'
  apiUserHost = 'https://api-user-staging.huami.com/'
}
export const ApiUserHost = apiUserHost
export const AccountHost = accountHost
const ApiUserRequest = requestCreater(apiUserHost)
const AccountRequest = requestCreater(AccountHost)

/**
 *
 * @name 登录第一步：获取token
 * @export
 * @params  (query:{},params:{},header:{})
 * @param {{
 *     country_code: string;
 *     password: string;
 *     name: string;
 *     client_id: string;
 *     redirect_uri: string;
 *     state: string;
 *     token: string;
 *     json_response: boolean;
 * }} {
 *     country_code= 'zh-CN',
 *     password,
 *     name,
 *     client_id= 'HuaMi',
 *     redirect_uri= 'https://s3-us-west-2.amazonaws.com/hm-registration/successsignin.html',
 *     state= 'REDIRECTION',
 *     token= 'access',
 *     json_response= true
 * }
 * @returns {Promise<any>}
 */
export function fetchTokens(params: {
  accountName: string
  country_code?: string
  password: string
  name?: string
  client_id?: string
  redirect_uri?: string
  state?: string
  token?: string
  json_response?: boolean
  app_name: string
}): Promise<any> {
  const {
    accountName,
    country_code = 'zh-CN',
    password,
    name,
    client_id = 'HuaMi',
    // 张凯说这个参数在json_response=true时,可以不填写
    redirect_uri = 'https://s3-us-west-2.amazonaws.com/hm-registration/successsignin.html',
    state = 'REDIRECTION',
    token = 'access',
    // 入参json_response=true时，httpcode=200，返回值在responsebody中以json格式返回；入参json_response=false，httpcode=303，返回值在Header中Location字段以redirect_uri（入参传入）拼接查询字符串格式返回
    json_response = true,
    app_name = 'com.huami.webapp'
  } = params
  // const {app_name = 'com.huami.webapp'} = header;

  // const Search = URLSearchParamsHelper()
  // const app_name = Search.get('app_name') || 'com.huami.webapp';

  return ApiUserRequest({
    url: `registrations/${accountName}/tokens`,
    method: 'POST',
    data: stringify({
      country_code,
      password,
      name,
      client_id,
      redirect_uri,
      state,
      token,
      json_response
    }),
    headers: {
      // app_name: 'com.huami.third.sdk',
      app_name,
      // Accept: 'application/json',
      'Accept-Language': 'zh',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 *
 * @name 登录第二步，客户端登录
 * @export
 * @param {{
 *     country_code: string;
 *     app_name: string;
 *     third_name: string;
 *     grant_type: string;
 *     code: string;
 *     device_id: string;
 *     device_model: string;
 *     app_version: string;
 *     dn: string;
 * }} {
 *     country_code= 'zh-CN',
 *     app_name= 'com.huami.webapp',
 *     third_name= 'huami',
 *     grant_type= 'access_token',
 *     code,
 *     device_id= '02:00:00:00:00:00',
 *     device_model= 'web',
 *     app_version= '4.3.0',
 *     dn= 'account.huami.com,api-user.huami.com,auth.huami.com,api-mifit.huami.com',
 * }
 * @returns {Promise<any>}
 */
export function fetchClientLoginV2(params: {
  app_name: string
  country_code?: string
  third_name?: string
  grant_type?: string
  code: string
  device_id?: string
  device_model?: string
  app_version?: string
  dn?: string
}): Promise<any> {
  const {
    country_code = 'zh-CN',
    app_name = 'com.huami.webapp',
    third_name = 'huami',
    grant_type = 'access_token',
    code,
    device_id = '02:00:00:00:00:00',
    device_model = 'web',
    app_version = '4.3.0',
    // dn = 'account.huami.com,api-user.huami.com,auth.huami.com,api-mifit.huami.com',
    dn = Env === 'production'
      ? 'account.huami.com,api-user.huami.com,auth.huami.com,api-mifit.huami.com,api-open.huami.com'
      : 'account-staging.huami.com,api-user-staging.huami.com,auth-staging.huami.com,api-mifit-staging.huami.com,api-open-staging.huami.com'
  } = params

  // const Search = URLSearchParamsHelper()
  // const app_name = Search.get('app_name') || 'com.huami.webapp';

  return AccountRequest({
    url: `v2/client/login`,
    method: 'POST',
    data: changeParams({
      country_code,
      app_name,
      third_name,
      grant_type,
      code,
      device_id,
      device_model,
      app_version,
      dn,
      allow_registration: false
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}

/**
 * @name 1\获取图片验证码
 * @description
 * @url https://api-user.huami.com/captcha/register?num=${uid(3)}
 * @method
 * @argument
 */
export function getCaptcha(): Promise<any> {
  return ApiUserRequest({
    url: `captcha/register?num=${123}`,
    method: 'GET'
  })
}

/**
 * @name 2\校验图片验证码
 * @description
 * @url https://api-user.huami.com/captcha/${type}?key=${key}&code=${code}
 * @method POST
 * @argument
 */
export function verifyCaptcha(type: string, key: string, code: string): Promise<any> {
  return ApiUserRequest({
    url: `captcha/${type}?key=${key}&code=${code}`,
    method: 'POST'
  })
}

/**
 *
 * @name 3\校验手机验证码
 * @description
 * @url https://api-user.huami.com/registrations/${encodeURIComponent('+86')}${username}/${codeType}/verify
 * @method POST
 * @export
 * @param {IPhoneCodeParams} params
 * @param {IPhoneCodeData} data
 * @returns {Promise<any>}
 */
export function verifyPhoneCode(params: { codeType: string; username: string; code: string }): Promise<any> {
  const { codeType, username, code } = params
  const data = changeParams({ code })
  return ApiUserRequest({
    url: `registrations/${username}/${codeType}/verify`,
    method: 'POST',
    data,
    headers: {
      Accept: 'application/json',
      'Accept-Language': 'zh',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    validateStatus(status: any) {
      if (status === 401) {
        return false
      }
      return true
    }
  })
}

/**
 * @name 4\账号系统：判断用户是否存在
 * @description 校验账号是否已存在: 返回 404 表示账号没有注册[检测到未注册的手机号并切注册地是中国大陆，会发送一条验证码]
 * @url https://api-user.huami.com/registrations/${isPhone ? encodeURIComponent('+86') : ''}${userAccount}
 * @method GET
 * @argument username: 手机号｜邮箱
 * @export
 * @returns {Promise<any>}
 */
export function hasAccount(params: { username: string; app_name: string }): Promise<any> {
  const { username, app_name } = params

  return ApiUserRequest({
    url: `registrations/${username}`,
    method: 'GET',
    headers: {
      app_name,
      'Accept-Language': navigator.language || 'zh-CN',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }
  })
}

/**
 * @name 5\华米账号注册
 * @description
 * @url https://api-user.huami.com/registrations/${isPhone ? encodeURIComponent('+86') : ''}${userAccount}
 * @method POST
 * @export
 * @param {*} data: 注册表单数据项
 * @returns {Promise<any>}
 */
export function userRegister(params: {
  password: string
  name: string
  account: string
  code: string
  app_name: string
  country_code?: string
  client_id?: string
  redirect_uri?: string
  state?: string
  token?: string
  json_response?: boolean
}): Promise<any> {
  const {
    app_name = 'com.huami.webapp',
    country_code = 'CN',
    password,
    name,
    account,
    code,
    client_id = 'HuaMi',
    redirect_uri = 'https://s3-us-west-2.amazonaws.com/hm-registration/successsignin.html',
    state = 'REDIRECTION',
    token = 'access',
    json_response = true
  } = params

  // const Search = URLSearchParamsHelper()
  // const app_name = Search.get('app_name') || 'com.huami.webapp';

  return ApiUserRequest({
    url: `registrations/${account}`,
    method: 'POST',
    data: stringify({
      app_name,
      country_code,
      password,
      name,
      code,
      client_id,
      redirect_uri,
      state,
      token,
      json_response
    }),
    headers: {
      // app_name: 'com.huami.webapp',
      app_name,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}

/**
 * @name 6\?不确定是什么注册,需要看文档确认,从名称上看，认为是客户端注册
 * @description
 * @url
 * @method
 * @export
 * @param {*} data
 * @returns {Promise<any>}
 */
export function clientRegister(params: {
  app_name: string
  country_code?: string
  code: string
  third_name?: string
  grant_type?: string
  device_id: string
  device_model?: string
  app_version?: string
  dn?: string
}): Promise<any> {
  const {
    app_name = 'com.huami.webapp',
    country_code = 'CN',
    code,
    third_name = 'huami',
    grant_type = 'access_token',
    device_id,
    device_model = 'web',
    app_version = '4.3.0',
    // dn = 'account.huami.com,api-user.huami.com,auth.huami.com,api-mifit.huami.com',
    dn = Env === 'production'
      ? 'account.huami.com,api-user.huami.com,auth.huami.com,api-mifit.huami.com,api-open.huami.com'
      : 'account-staging.huami.com,api-user-staging.huami.com,auth-staging.huami.com,api-mifit-staging.huami.com,api-open-staging.huami.com'
  } = params

  // const Search = URLSearchParamsHelper()
  // const app_name = Search.get('app_name') || 'com.huami.webapp';

  return AccountRequest({
    url: `v1/client/register`,
    method: 'POST',
    data: changeParams({
      app_name,
      country_code,
      code,
      third_name,
      grant_type,
      device_id,
      device_model,
      app_version,
      dn
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}

// 按手机号找回: 7,8
// 按邮箱找回: 8
// 邮箱找回密码：清除密码
// 手机号找回密码：清除密码、重置密码

/**
 *
 * @name 7\清除密码，判断用户是否存在，发送验证码
 * @description
 * @url https://api-user.huami.com/registrations/${username}/password
 * @method DELETE
 * @export
 * @param {IDeletePasswordParams} params
 * @param {IDeletePasswordData} data
 * @returns {Promise<any>}
 */
export function deletePassword(params: { username: string; app_name: string }): Promise<any> {
  const { username, app_name } = params
  // const Search = URLSearchParamsHelper()
  // const app_name = Search.get('app_name') || 'com.huami.webapp';
  return ApiUserRequest({
    url: `registrations/${username}/password`,
    method: 'DELETE',
    // data,
    headers: {
      // app_name: 'com.huami.webapp',
      app_name,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Accept-Language': navigator.language || 'zh-CN'
    }
  })
}

/**
 *
 * @name 8\重置密码
 * @description
 * @url https://api-user.huami.com/registrations/${username}/password
 * @method POST
 * @export
 * @param {IResetPasswordParams} params
 * @param {IResetPasswordData} data
 * @returns {Promise<any>}
 */
export function resetPassword(params: { app_name: string; username: string; newPassword: string; reset: string }): Promise<any> {
  // const Search = URLSearchParamsHelper()
  // const app_name = Search.get('app_name') || 'com.huami.webapp';

  const { username, newPassword, reset, app_name } = params

  return ApiUserRequest({
    url: `registrations/${username}/password`,
    method: 'POST',
    data: stringify({
      new: newPassword,
      reset
    }),
    headers: {
      // 'X-Request-Id': xRequestId(),
      // app_name: 'com.huami.webapp',
      app_name,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Accept-Language': navigator.language || 'zh-CN'
    }
  })
}

/**
 *
 * @name 9\注销登录
 * @description
 * @url https://account.huami.com/v1/client/logout
 * @method POST
 * @export
 * @param {*} data
 * @returns {Promise<any>}
 */
export function logout(params?: any): Promise<any> {
  return AccountRequest({
    url: `v1/client/logout`,
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function requestAuth(data: any): void {
  const {
    // country_code,
    state,
    access,
    // redirectUri,
    region,
    expiration,
    packageName = '',
    registration = '',
    appName
    // countryState
  } = data
  const isBell = packageName === 'com.huami.hearingaids'
  let callbackUrl = ''
  if (isBell) {
    if (registration.includes('+86')) {
      callbackUrl = environment.callbackUrl.replace('{thirdPartyName}', 'phone-bell')
    } else if (regExp.reg_email.test(registration)) {
      callbackUrl = environment.callbackUrl.replace('{thirdPartyName}', 'email-bell')
    }
  } else {
    callbackUrl = environment.callbackUrl.replace('{thirdPartyName}', 'huami')
  }

  // Cookies.set('redirect_uri', redirectUri)
  const parameter = `?appname=${appName}&state=${encodeURIComponent(state)}&region=${region}&access=${access}&expiration=${expiration}`
  // window.location.href = PRODUCTION === 'pro' ? `https://account.huami.com/v1/accounts/connect/huami/callback${param}` : `https://account-staging.huami.com/v1/accounts/connect/huami/callback${param}`
  // window.location.href = `https://account.huami.com/v1/accounts/connect/huami/callback${param}`
  window.location.href = `${callbackUrl}${parameter}`
}

export function requestWeixin(state: string): void {
  if (navigator.userAgent.toLowerCase().includes('micromessenger')) {
    window.location.href = `${HuaMi.Oauth.mp.action}?appid=${HuaMi.Oauth.mp.inputs.appid}&redirect_uri=${HuaMi.Oauth.mp.redirect_uri}&response_type=${
      HuaMi.Oauth.mp.inputs.response_type
    }&scope=${HuaMi.Oauth.mp.inputs.scope}&state=${encodeURIComponent(state)}`
  }
}

export function getUserRegisterInfo() {
  const host = getApiHost('account')
  const { userId, appToken } = getUniversalLoginInfo()
  const profileRequest = requestCreater(`https://${host}/admin/users/${userId}/profile`)

  if (!userId || !appToken) {
    return Promise.reject(new Error('fetch user profile error'))
  }

  return profileRequest({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${appToken}`,
    }
  }).then((res: any) => {
    if (res?.status === 200) {
      return res?.data
    }
    return Promise.reject(new Error('fetch user profile error'))
  })
}
