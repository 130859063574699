/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { ColorPicker, InputNumber, Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { SERIES } from '@watchface/constants'
import { SELECT_STYLE } from '@watchface/constants/style'
import { removeCells, renderCells } from '@watchface/utils/render'
import { Select } from 'antd'
import Immutable from 'immutable'
import React, { PureComponent } from 'react'
import { EDITABLE_BG } from './config'
import './index.scss'

const { Option } = Select
const noop = () => {} // eslint-disable-line
const zIndexMap = {
  bg: -2,
  color: -1,
  float: 0
}
class Background extends PureComponent {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: Background,
      ...param
    })
    Background.keyMap = Object.assign(Background.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: Background,
      ...param
    })
    Background.keyMap = keyMap
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      Background.renderLayer(this.props)
    }
  }

  static getCellDatas = ({ parentKey, childKey, data, dialType, watchConfig, assets }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }

    const { bg_type, type, float } = immutableSelector(data)
    const lastType = bg_type || type // bg_type 有值时取 bg_type，反之取 type。兼容已有数据

    // 息屏表盘背景仅支持图片形式
    if (dialType === 'idle') {
      const image = Background.getIdleImage({ parentKey, childKey, data, watchConfig })
      if (image) {
        cells.imageEntity[image.key] = image
      }
      return cells
    }

    if (lastType === 'image') {
      const image = Background.getBgImage({ parentKey, childKey, data, watchConfig })
      if (image) {
        cells.imageEntity[image.key] = image
      }
    } else if (lastType === 'color') {
      const vertext = Background.getBgVertex({ parentKey, childKey, data, watchConfig })
      cells.vertexEntity[vertext.key] = vertext
    } else if (lastType === 'editable_bg') {
      const arr = Background.getBgEditable({ parentKey, childKey, data, watchConfig, assets })
      if (arr.length) {
        arr.forEach((v) => {
          cells.imageEntity[v.key] = v
        })
      }
    }

    if (float) {
      const floatImage = Background.getFloatImage({ parentKey, childKey, data })
      if (floatImage) {
        cells.imageEntity[floatImage.key] = floatImage
      }
    }

    return cells
  }

  static getIdleImage = ({ data }) => {
    const { image = '', position = Immutable.Map() } = immutableSelector(data)
    const { x = 0, y = 0 } = immutableSelector(position)
    if (!image) {
      return null
    }
    return {
      key: 'image',
      texture: image,
      left: x,
      top: y,
      selectable: false
    }
  }

  static getBgVertex = ({ data, watchConfig }) => {
    const { color, radius = 0 } = immutableSelector(data)
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const newColor = color ? `#${color.slice(4)}` : ''
    const key = 'color'
    return {
      key,
      left: 0,
      top: 0,
      width: screenWidth,
      height: screenHeight,
      rx: radius,
      ry: radius,
      fill: color ? newColor : 'transparent',
      type: 'rect',
      zIndex: zIndexMap[key],
      selectable: false
    }
  }

  static getBgEditable = ({ data, assets }) => {
    const { editable_bg } = immutableSelector(data)
    const { previewBgNum = 1 } = immutableSelector(assets)
    const { tip_image, tip_position, bg_images } = immutableSelector(editable_bg)
    const arr = []
    if (bg_images.get(previewBgNum - 1)) {
      arr.push({
        key: 'editable_bg',
        texture: bg_images.get(previewBgNum - 1),
        left: 0,
        top: 0,
        selectable: false
      })
    }
    if (tip_image) {
      arr.push({
        key: 'editable_tip',
        texture: tip_image,
        left: tip_position.get('x'),
        top: tip_position.get('y'),
        selectable: false
      })
    }
    return arr
  }

  static getBgImage = ({ data }) => {
    const { image = '', position = Immutable.Map() } = immutableSelector(data)
    const { x = 0, y = 0 } = immutableSelector(position)
    const key = 'bg'
    if (!image) {
      return null
    }
    return {
      key,
      texture: image,
      left: x,
      top: y,
      zIndex: zIndexMap[key],
      selectable: false
    }
  }

  static getFloatImage = ({ data }) => {
    const { float } = immutableSelector(data)
    const { position: fPosition = Immutable.Map(), image: fImage = '' } = immutableSelector(float)
    const { x: fX = 0, y: fY = 0 } = immutableSelector(fPosition)
    const key = 'float'
    if (fImage) {
      return {
        key,
        texture: fImage,
        left: fX,
        top: fY,
        zIndex: zIndexMap[key],
        selectable: false
      }
    }
    return null
  }

  handleInsertImage = (path, img) => {
    const { data, parentKey, onChange = noop, prefixPath } = this.props
    let newData = data

    if (img && img.image) {
      newData = data.setIn(path, img.image)
    } else {
      newData = data.setIn(path, '')
    }
    onChange([...prefixPath, parentKey], newData)
  }

  handleTypeChange = (value) => {
    const { data, parentKey, onChange = noop, prefixPath, dialType, onAssetsChange, series, support, watchConfig } = this.props
    const { shape, radius = 0 } = immutableSelector(support)
    const { screenWidth } = immutableSelector(watchConfig)
    const borderRadius = shape === 'round' ? screenWidth / 2 : radius

    let newData = data
    if (series === SERIES.JS) {
      newData = data.set('bg_type', value) // 只需同步修改 bg_type, type 无需同步
      newData = newData.set('editable_bg', EDITABLE_BG)
    } else {
      newData = data.set('type', value)
    }
    newData = newData.set('radius', borderRadius)
    if (value === 'image') {
      newData = newData.set('color', '0xFFFFFFFF')
      onAssetsChange([dialType, ...prefixPath, parentKey, 'previewBgNum'], 1)
    } else if (value === 'color') {
      newData = newData.set('image', '')
      onAssetsChange([dialType, ...prefixPath, parentKey, 'previewBgNum'], 1)
    } else if (value === 'editable_bg') {
      newData = newData.set('image', '')
      newData = newData.set('color', '0xFFFFFFFF')
    }
    onChange([...prefixPath, parentKey], newData)
  }

  handleColorChange = (color) => {
    const value = color.hex
    const { parentKey, onChange = noop, data, prefixPath } = this.props
    // todo: 临时修改转为 ARGB 格式, 后续优化
    const newData = data.set('color', value ? `0xFF${value.slice(1)}` : '')
    onChange([...prefixPath, parentKey], newData)
  }

  handleColorInputChange = (value) => {
    this.handleColorChange({ hex: value })
  }

  handlePositionChange = (path, value) => {
    const { parentKey, data, onChange, prefixPath } = this.props
    let newData = data || Immutable.Map()
    newData = newData.setIn(path, value)
    onChange([...prefixPath, parentKey], newData)
  }

  handleCountChange = (count) => {
    const { parentKey, data = Immutable.Map(), onChange, prefixPath } = this.props
    const bgList = data.getIn(['editable_bg', 'bg_images']).setSize(count)
    const previewList = data.getIn(['editable_bg', 'preview_images']).setSize(count)
    const newData = data
      .setIn(['editable_bg', 'count'], count)
      .setIn(['editable_bg', 'bg_images'], bgList)
      .setIn(['editable_bg', 'preview_images'], previewList)
    onChange([...prefixPath, parentKey], newData)
  }

  handleBgChange = (img, type, index) => {
    const { parentKey, data = Immutable.Map(), onChange, prefixPath } = this.props
    const imageUrl = img && img.image ? img.image : ''
    const list = data.getIn(['editable_bg', type]).set(index, imageUrl)
    const newData = data.setIn(['editable_bg', type], list)
    onChange([...prefixPath, parentKey], newData)
  }

  handlePreivewPic = (value) => {
    const { parentKey, dialType, onAssetsChange, prefixPath } = this.props
    onAssetsChange([dialType, ...prefixPath, parentKey, 'previewBgNum'], value)
  }

  renderIdleBg = (shape, radius) => {
    const { data, watchConfig } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const image = data.get('image')

    return (
      <>
        <div className="name is-required">
          <T id="image_bg" />
        </div>
        <div className="content">
          <Upload
            className="item"
            fileCountLimit={1}
            hideSelectImageType
            maxWidth={screenWidth}
            maxHeight={screenHeight}
            fileList={image ? [{ image }] : []}
            shape={shape}
            onChange={(image) => {
              this.handleInsertImage(['image'], image)
            }}
            cropOption={{
              radius,
              targetSize: { width: Number(screenWidth), height: Number(screenHeight) }
            }}
          />
        </div>
      </>
    )
  }

  renderPosition = (posPath, position) => {
    const { watchConfig = Immutable.Map() } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { x = 0, y = 0 } = immutableSelector(position || Immutable.Map())

    return (
      <>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                onChange={(...args) => {
                  this.handlePositionChange([...posPath, 'position', 'x'], ...args)
                }}
                value={x}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                onChange={(...args) => {
                  this.handlePositionChange([...posPath, 'position', 'y'], ...args)
                }}
                value={y}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  renderFloat = (float, shape, radius) => {
    const { watchConfig = Immutable.Map() } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { position = Immutable.Map(), image } = immutableSelector(float || Immutable.Map())

    return (
      <>
        <div className="name">
          <T id="float_image" />
        </div>
        <div className="content">
          <div className="item">
            <Upload
              className="item"
              fileCountLimit={1}
              hideSelectImageType
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              fileList={image ? [{ image }] : []}
              shape={shape}
              onChange={(image) => {
                this.handleInsertImage(['float', 'image'], image)
              }}
              cropOption={{
                radius,
                targetSize: { width: Number(screenWidth), height: Number(screenHeight) }
              }}
            />
          </div>
          {this.renderPosition(['float'], position)}
        </div>
      </>
    )
  }

  renderImageBg = (shape, radius) => {
    const { data, watchConfig } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { bg_type, type } = immutableSelector(data || Immutable.Map())
    const lastType = bg_type || type // bg_type 有值时取 bg_type，反之取 type。兼容已有数据
    const value = data.get(lastType)

    return (
      <Upload
        className="item"
        fileCountLimit={1}
        hideSelectImageType
        maxWidth={screenWidth}
        maxHeight={screenHeight}
        fileList={value ? [{ image: value }] : []}
        shape={shape}
        onChange={(image) => {
          this.handleInsertImage(['image'], image)
        }}
        cropOption={{
          radius,
          targetSize: { width: Number(screenWidth), height: Number(screenHeight) }
        }}
      />
    )
  }

  renderSolidColorBg = () => {
    const { data } = this.props
    const { bg_type, type } = immutableSelector(data || Immutable.Map())
    const lastType = bg_type || type // bg_type 有值时取 bg_type，反之取 type。兼容已有数据

    const value = data.get(lastType)

    return (
      <>
        <div className="item pick-color">
          <div className="name">
            <T id="color_val" />
          </div>
          <ColorPicker
            width={200}
            color={value ? `#${value.slice(4)}` : ''}
            onInputChange={this.handleColorInputChange}
            onChangeComplete={this.handleColorChange}
          />
        </div>
      </>
    )
  }

  renderEditableBg = (shape, radius) => {
    const MAX_PIC_NUMBER = 10
    const { assets, data, watchConfig } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { editable_bg } = immutableSelector(data || Immutable.Map())
    const { bg_images = Immutable.List(), preview_images = Immutable.List(), fg_image, tip_image, tip_position, count = 2 } = immutableSelector(
      editable_bg
    )
    const { previewBgNum = 1 } = immutableSelector(assets || Immutable.Map())
    const { x, y } = immutableSelector(tip_position)
    const bgArr = Array.from({ length: count }, (v, i) => i)

    return (
      <>
        <div className="name">
          <T id="editable_bg_count" />
        </div>
        <div className="content">
          <div className="item system-upload-wrap">
            <InputNumber value={count} onChange={this.handleCountChange} min={2} max={MAX_PIC_NUMBER} />
          </div>
        </div>
        {/* bg start */}
        {bgArr.map((v, i) => {
          const bgImage = bg_images.get(i) ? [{ image: bg_images.get(i) }] : []
          const previewImage = preview_images.get(i) ? [{ image: preview_images.get(i) }] : []
          return (
            <React.Fragment key={v}>
              <div className="name">
                <T id="background" />
                {i + 1}
              </div>
              <div className="group">
                <div className="group-l">
                  <div className="group-t is-required">
                    <T id="editable_bg_up_bg" />
                  </div>
                  <div className="group-b">
                    <Upload
                      fileCountLimit={1}
                      hideSelectImageType
                      field="image"
                      maxWidth={screenWidth}
                      maxHeight={screenHeight}
                      fileList={bgImage}
                      shape={shape}
                      onChange={(img) => {
                        this.handleBgChange(img, 'bg_images', i)
                      }}
                      cropOption={{
                        radius,
                        targetSize: { width: Number(screenWidth), height: Number(screenHeight) }
                      }}
                    />
                  </div>
                </div>
                <div className="group-r">
                  <div className="group-t">
                    <T id="editable_bg_up_preview" />
                  </div>
                  <div className="group-b">
                    <Upload
                      fileCountLimit={1}
                      hideSelectImageType
                      field="image"
                      maxWidth={screenWidth}
                      maxHeight={screenHeight}
                      fileList={previewImage}
                      shape={shape}
                      onChange={(img) => {
                        this.handleBgChange(img, 'preview_images', i)
                      }}
                      cropOption={{
                        radius,
                        targetSize: { width: Number(screenWidth), height: Number(screenHeight) }
                      }}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
        {/* bg end */}
        {/* fg start */}
        <div className="name is-required">
          <T id="editable_bg_up_highlight" />
        </div>
        <div className="content">
          <div className="item">
            <Upload
              fileCountLimit={1}
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              fileList={fg_image ? [{ image: fg_image }] : []}
              onChange={(image) => {
                this.handleInsertImage(['editable_bg', 'fg_image'], image)
              }}
            />
          </div>
        </div>
        {/* fg end */}
        {/* tip start */}
        <div className="name is-required">
          <T id="editable_bg_tips_bg" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                fileList={tip_image ? [{ image: tip_image }] : []}
                onChange={(image) => {
                  this.handleInsertImage(['editable_bg', 'tip_image'], image)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={x}
                onChange={(value) => {
                  this.handlePositionChange(['editable_bg', 'tip_position', 'x'], value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={y}
                onChange={(value) => {
                  this.handlePositionChange(['editable_bg', 'tip_position', 'y'], value)
                }}
              />
            </div>
          </div>
        </div>
        {/* tip end */}
        {/* preview start */}
        <div className="name">
          <T id="preview" />
        </div>
        <div className="content">
          <div className="item">
            <InputNumber min={1} max={bg_images.size || 1} value={previewBgNum} onChange={this.handlePreivewPic} />
          </div>
        </div>
        {/* preview end */}
      </>
    )
  }

  render() {
    let renderBg = null
    const { data, dialType, series, support } = this.props
    const { float, position, type, bg_type } = immutableSelector(data || Immutable.Map())
    const { shape = 'square', radius = 0 } = immutableSelector(support)
    const lastType = bg_type || type // bg_type 有值时取 bg_type，反之取 type。兼容已有数据

    if (dialType === 'idle') {
      return this.renderIdleBg(shape, radius)
    }
    if (lastType === 'image') {
      if (position) {
        renderBg = (
          <>
            {this.renderImageBg(shape, radius)}
            {this.renderPosition([], position)}
          </>
        )
      } else {
        renderBg = this.renderImageBg(shape, radius)
      }
    } else if (lastType === 'color') {
      renderBg = this.renderSolidColorBg()
    } else if (lastType === 'editable_bg') {
      if (series === SERIES.JS) {
        renderBg = this.renderEditableBg(shape, radius)
      } else {
        renderBg = this.renderImageBg(shape, radius)
      }
    }
    return (
      <>
        <div className="name is-required">
          <T id="bg_style" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              value={lastType}
              style={SELECT_STYLE}
              onChange={(value) => {
                this.handleTypeChange(value)
              }}
            >
              <Option value="image">
                <T id="image_bg" />
              </Option>
              <Option value="color">
                <T id="solid_bg" />
              </Option>
              {series === SERIES.JS ? (
                <Option value="editable_bg">
                  <T id="editable_bg" />
                </Option>
              ) : null}
            </Select>
          </div>
          {renderBg}
        </div>
        {float ? this.renderFloat(float, shape, radius) : null}
      </>
    )
  }
}

Background.defaultProps = {
  prefixPath: [],
  onChange: noop
}

export default Background
