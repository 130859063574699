const TOP_BAR = [
  {
    name: 'background',
    icon: 'icon-tuceng-beijing',
    items: ['background', 'scale']
  },
  {
    name: 'time',
    icon: 'icon-tuceng-zhizhenshijian',
    items: ['analog_clock', 'digital_clock', 'progress_clock']
  },
  {
    name: 'date',
    icon: 'icon-tuceng-riqi',
    items: ['date', 'week']
  },
  {
    name: 'workout_health',
    icon: 'icon-tuceng-xinshuai',
    items: ['heart_rate', 'calorie', 'pai', 'step', 'distance']
  },
  {
    name: 'weather',
    icon: 'icon-tuceng-tianqi',
    items: ['weather', 'temperature', 'aqi', 'sun', 'humidity', 'uvi']
  },
  {
    name: 'system',
    icon: 'icon-tuceng-xitong',
    items: ['system', 'battery']
  },
  {
    name: 'communication',
    icon: 'icon-caidan-tongxun',
    items: ['message', 'phone']
  },
  {
    name: 'editable',
    icon: 'icon-caidan-kebianji',
    items: ['component']
  }
]

export default TOP_BAR
