/* eslint-disable import/order */
import React, { Component } from 'react'
import Immutable from 'immutable'
import { Radio, Select, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { immutableSelector } from '@/utils'
import { InputNumber, Upload } from '@watchface/components'
import { SELECT_STYLE } from '@watchface/constants/style'
import T from '@watchface/components/I18n'
import { TOOLTIP_COLOR } from '@watchface/constants'

import { renderCells } from '@watchface/utils/render'
import SelectList from './SelectList'

const { Option } = Select

class EditableComponent extends Component {
  static keyMap = {}

  constructor(props) {
    super(props)
    const { allData, parentKey } = props
    const path = parentKey.split('_')
    const component = immutableSelector(allData.getIn([...path]))
    const { select_list } = immutableSelector(
      component
    )
    this.state = {
      showSelectListVisible: !!select_list
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      EditableComponent.renderLayer(this.props)
    }
  }

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: EditableComponent,
      ...param
    })
    EditableComponent.keyMap = Object.assign(EditableComponent.keyMap, keyMap)
    // 在渲染可编辑组件时需要根据 预览的状态判断是否需要调用子组件的 renderLayer 方法
    const { parentKey, dialType, allAssets, allData, menu, CONFIG_MAP, keyPrefix, editableComponentKey } = param
    if (!CONFIG_MAP) return
    const { componentMap } = CONFIG_MAP
    const path = parentKey.split('_')
    const previewState = allAssets.getIn([dialType, ...path, 'previewState'])
    const previewValue = allAssets.getIn([dialType, ...path, 'value'])
    const checkedMenu = EditableComponent.getCheckedMenu({ menu, parentKey })
    if (previewState === 'previewComponent') {
      checkedMenu.forEach((item) => {
        const { key, children = Immutable.List() } = immutableSelector(item)
        children.forEach((child) => {
          const { key: childKey, checked } = immutableSelector(child)
          const { cls, constMap = Immutable.Map(), propMap = {} } = componentMap(dialType)[key]
          const prop = propMap[childKey] || {}
          if (checked && previewValue === key) {
            cls.renderLayer({
              ...param,
              data: allData.getIn([...path, 'props', key, 'children', childKey]),
              assets: allAssets.getIn([dialType, ...path, 'props', key, 'children', childKey]) || Immutable.Map(),
              childKey,
              parentKey: key,
              editableComponentKey,
              keyPrefix: `${keyPrefix}-${key}`,
              prefixPath: [...path, 'props'],
              constMap,
              ...prop
            })
          } else {
            cls.removeLayer({ ...param, childKey, parentKey: key, keyPrefix: `${keyPrefix}-${key}`, constMap, ...prop })
          }
        })
      })
    } else {
      checkedMenu.forEach((item) => {
        const { key, children = Immutable.List() } = immutableSelector(item)
        children.forEach((child) => {
          const { key: childKey } = immutableSelector(child)
          const { cls, propMap = {} } = componentMap(dialType)[key]
          const prop = propMap[childKey] || {}
          cls.removeLayer({ ...param, childKey, parentKey: editableComponentKey || key, keyPrefix: `${keyPrefix}-${key}`, ...prop })
        })
      })
    }
  }

  static getCheckedMenu = ({ menu, parentKey }) => {
    const item = menu.find((item) => item.get('key') === parentKey) || Immutable.Map()
    const children = item.get('children') || Immutable.List()
    return children.filter((child) => child.get('checked'))
  }

  static getCellDatas = ({ allData, allAssets, parentKey, dialType }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }

    const selectStateImage = EditableComponent.getSelectStateImage({ allData, allAssets, parentKey, dialType })
    if (selectStateImage) {
      cells.imageEntity[selectStateImage.key] = selectStateImage
    }

    const tagImage = EditableComponent.getTagImage({ allData, parentKey })

    if (tagImage) {
      cells.imageEntity[tagImage.key] = tagImage
    }
    // const
    const images = EditableComponent.getComponentPreviewImages({ allData, allAssets, parentKey, dialType })
    images.forEach((image) => {
      const { key } = image
      cells.imageEntity[key] = image
    })
    return cells
  }

  static getSelectStateImage = ({ allData, allAssets, parentKey, dialType }) => {
    const path = parentKey.split('_')
    const selectState = allAssets.getIn([dialType, ...path, 'selectState']) || 'select'
    const component = immutableSelector(allData.getIn([...path]))
    const { rectangle = Immutable.Map(), select_image, unselect_image } = immutableSelector(component)
    const { position } = immutableSelector(rectangle)
    const { x, y } = immutableSelector(position)

    if (selectState === 'hide') return null
    if (selectState === 'select') {
      if (select_image) {
        return {
          key: 'selectStateImage',
          texture: select_image,
          left: x,
          top: y
        }
      }
      return null
    }
    if (unselect_image) {
      return {
        key: 'selectStateImage',
        texture: unselect_image,
        left: x,
        top: y
      }
    }
    return null
  }

  static getTagImage = ({ allData, parentKey }) => {
    const path = parentKey.split('_')
    const component = immutableSelector(allData.getIn([...path]))
    const { text_tag = Immutable.Map() } = immutableSelector(component)
    const { position: textPosition, image: textImage } = immutableSelector(text_tag)
    const { x, y } = immutableSelector(textPosition)

    if (textImage) {
      return {
        key: 'tagImage',
        texture: textImage,
        left: x,
        top: y
      }
    }
    return null
  }

  static getComponentPreviewImages = ({ allData, allAssets, parentKey, dialType }) => {
    const path = parentKey.split('_')
    const component = immutableSelector(allData.getIn([...path]))
    const { rectangle = Immutable.Map(), props } = immutableSelector(component)
    const { position } = immutableSelector(rectangle)
    const { x, y } = immutableSelector(position)
    const value = allAssets.getIn([dialType, ...path, 'value'])
    const previewState = allAssets.getIn([dialType, ...path, 'previewState'])
    const images = []
    if (!value || previewState === 'previewComponent') return images
    const bg = props.getIn([value, 'bg'])
    const { image: bgImage, position: bgPosition } = immutableSelector(bg)
    const { x: bgPositionX, y: bgPositionY } = immutableSelector(bgPosition)
    if (bgImage) {
      images.push({
        texture: bgImage,
        key: 'bgImage',
        left: bgPositionX,
        top: bgPositionY
      })
    }

    const previewImage = props.getIn([value, 'previews', 0, 'image', 'url'])
    if (previewImage) {
      images.push({
        texture: previewImage,
        key: 'previewImage',
        left: x,
        top: y
      })
    }

    return images
  }

  // handleChangeChildProps = (childPath, item) => {
  //     const [ parent, child ] = childPath
  //     const { parentKey, onChange, allData, layerConfig } = this.props
  //     const path = parentKey.split('_')
  //     const { checked } = immutableSelector(item)
  //     let newAllData = allData
  //     if (checked) {
  //         newAllData = newAllData.setIn([...path, 'props', parent, 'children', child], layerConfig.getIn([parent, 'children', child]))
  //     } else {
  //         newAllData = newAllData.deleteIn([...path, 'props', parent, 'children', child])
  //     }
  //     onChange([], newAllData)
  // }

  handleChangePreview = (item) => {
    const { key, checked } = immutableSelector(item)
    const { parentKey, onChange, allData } = this.props
    const path = parentKey.split('_')
    let newAllData = allData
    if (checked) {
      newAllData = newAllData.setIn(
        [...path, 'props', key],
        Immutable.fromJS({
          previews: [
            {
              language: 'all',
              image: {
                url: '',
                width: 0,
                height: 0,
                name: ''
              }
            }
          ]
          // bg: {
          //     position: {
          //         x: 0,
          //         y: 0
          //     },
          //     image: ''
          // },
        })
      )
    } else {
      newAllData = newAllData.deleteIn([...path, 'props', key])
    }

    onChange([], newAllData)
  }

  handleInsertImage = (img, option) => {
    const { onChange, allData, parentKey } = this.props
    let newAllData = allData
    const path = parentKey.split('_')
    if (img && img.image) {
      newAllData = newAllData.setIn([...path, ...option], img.image)
    } else {
      newAllData = newAllData.setIn([...path, ...option], '')
    }
    onChange([], newAllData)
  }

  handleInsertPreviewImage = (img, key) => {
    const { onChange, allData, parentKey } = this.props
    let newAllData = allData
    const path = parentKey.split('_')

    if (img && img.image) {
      newAllData = newAllData.setIn([...path, 'props', key, 'previews', 0, 'image'], {
        url: img.image,
        name: '',
        width: img.width,
        height: img.height
      })
    } else {
      newAllData = newAllData.setIn([...path, 'props', key, 'previews', 0, 'image'], { url: '', name: '', width: 0, height: 0 })
    }
    onChange([], newAllData)
  }

  handleChange = (valuePath, value) => {
    const { onChange, allData, parentKey } = this.props
    let newAllData = allData
    const path = parentKey.split('_')
    newAllData = newAllData.setIn([...path, ...valuePath], value)
    onChange([], newAllData)
  }

  handleAssetsChange = (changePath, value) => {
    const { onAssetsChange, allAssets, dialType, parentKey } = this.props
    let newAssets = allAssets

    const path = parentKey.split('_')
    newAssets = newAssets.setIn([dialType, ...path, ...changePath], value)
    onAssetsChange([], newAssets)
  }

  getOptions = () => {
    const { menu, parentKey } = this.props
    const componentMenu = menu.find((item) => item.get('key').includes(parentKey))
    if (!componentMenu) return []
    return componentMenu
      .get('children')
      .filter((item) => item.get('checked') === true)
      .toJS()
  }

  handleShowSelectListVisibleChange = (e) => {
    const visible = e.target.value
    const { onChange, allData, parentKey } = this.props
    let newAllData = allData
    const path = parentKey.split('_')
    const selectListPath = [...path, 'select_list']
    const defaultConfig = Immutable.Map({
      title_font_size: 34,
      title_align_h: 'center',
      list_item_vspace: 8,
      list_tips_text_font_size: 32,
      list_tips_text_align_h: 'left',
    })

    if (visible) {
      newAllData = newAllData.setIn(selectListPath, defaultConfig)
    } else {
      newAllData = newAllData.deleteIn(selectListPath)
    }

    onChange([], newAllData)

    this.setState({
      showSelectListVisible: visible
    })
  }

  render() {
    const { showSelectListVisible } = this.state
    const { allData, allAssets, watchConfig, parentKey, dialType } = this.props
    const path = parentKey.split('_')
    const options = this.getOptions()
    const value = allAssets.getIn([dialType, ...path, 'value']) || (options[0] && options[0].value)
    const selectState = allAssets.getIn([dialType, ...path, 'selectState']) || 'select'
    const previewState = allAssets.getIn([dialType, ...path, 'previewState']) || 'previewImage'
    const defaultShow = allAssets.getIn([dialType, ...path, 'defaultShow'])
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const component = immutableSelector(allData.getIn([...path]))
    const { rectangle = Immutable.Map(), select_image, select_list, text_display_width, text_margin = 0, text_tag = Immutable.Map(), unselect_image, props } = immutableSelector(
      component
    )
    const { position, width, height } = immutableSelector(rectangle)
    const { x, y } = immutableSelector(position)
    const { position: textPosition, image: textImage } = immutableSelector(text_tag)
    const { x: textX, y: textY } = immutableSelector(textPosition)

    return (
      <>
        <div className="name">
          <T id="selected_image" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                key={select_image}
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                field={['select_image']}
                fileList={select_image ? [{ image: select_image }] : []}
                onChange={this.handleInsertImage}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="not_selected_image" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                field={['unselect_image']}
                fileList={unselect_image ? [{ image: unselect_image }] : []}
                onChange={this.handleInsertImage}
              />
            </div>
          </div>
        </div>
        <div className="item">
          <T id="status_preview" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={selectState}
              onChange={(value) => {
                this.handleAssetsChange(['selectState'], value)
              }}
            >
              <Option key="select" value="select">
                <T id="selected" />
              </Option>
              <Option key="unselect" value="unselect">
                <T id="not_selected" />
              </Option>
              <Option key="hide" value="hide">
                <T id="hide" />
              </Option>
            </Select>
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={x}
                onChange={(value) => {
                  this.handleChange(['rectangle', 'position', 'x'], value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={y}
                onChange={(value) => {
                  this.handleChange(['rectangle', 'position', 'y'], value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="area_size" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">W</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={width}
                onChange={(value) => {
                  this.handleChange(['rectangle', 'width'], value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">H</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={height}
                onChange={(value) => {
                  this.handleChange(['rectangle', 'height'], value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="label_image" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col col-v">
              <Upload
                key={textImage}
                fileCountLimit={1}
                maxWidth={screenWidth}
                maxHeight={screenHeight}
                field={['text_tag', 'image']}
                fileList={textImage ? [{ image: textImage }] : []}
                onChange={this.handleInsertImage}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">x</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={textX}
                onChange={(value) => {
                  this.handleChange(['text_tag', 'position', 'x'], value)
                }}
              />
            </div>
            <div className="col">
              <div className="key">y</div>
              <InputNumber
                min={0}
                max={screenHeight}
                value={textY}
                onChange={(value) => {
                  this.handleChange(['text_tag', 'position', 'y'], value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="dispaly_width" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <div className="key">W</div>
              <InputNumber
                min={0}
                max={screenWidth}
                value={text_display_width}
                onChange={(value) => {
                  this.handleChange(['text_display_width'], value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <T id="margin" />
        </div>
        <div className="content">
          <div className="item">
            <div className="col">
              <InputNumber
                min={0}
                max={screenWidth}
                value={text_margin}
                onChange={(value) => {
                  this.handleChange(['text_margin'], value)
                }}
              />
            </div>
          </div>
        </div>
        {options.map((option) => {
          const { name, key } = option
          const item = props.get(key) || Immutable.Map()
          const { previews = Immutable.Map() } = immutableSelector(item)
          const previewImage = previews.getIn([0, 'image', 'url']) || ''

          return (
            <div key={key}>
              <div className="name">
                <T id="preview_image_with_name" values={{ name: <T id={name} /> }} />
              </div>
              <div className="content">
                <div className="item">
                  <div className="col col-v">
                    <Upload
                      key={previewImage}
                      fileCountLimit={1}
                      maxWidth={screenWidth}
                      maxHeight={screenHeight}
                      field={key}
                      fileList={previewImage ? [{ image: previewImage }] : []}
                      onChange={this.handleInsertPreviewImage}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <div className="item">
          <T id="preview_component" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={options.length && options.find((item) => item.key === value) ? value : undefined}
              onChange={(value) => {
                this.handleAssetsChange(['value'], value)
              }}
            >
              {options.map((item) => {
                const { name, key } = item
                return (
                  <Option key={key} value={key}>
                    <T id={name} />
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
        <div className="item">
          <T id="preview_status" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={previewState}
              onChange={(value) => {
                this.handleAssetsChange(['previewState'], value)
              }}
            >
              <Option key="previewImage" value="previewImage">
                <T id="preview_image" />
              </Option>
              <Option key="previewComponent" value="previewComponent">
                <T id="preview_component" />
              </Option>
            </Select>
          </div>
        </div>
        <div className="item">
          <T id="config_select_list" /><Tooltip title={<p><T id="select_list_tip"/></p>} color={TOOLTIP_COLOR}><QuestionCircleOutlined style={{ marginLeft: '6px' }}/></Tooltip>
        </div>
        <div className="content">
          <div className="item">
            <Radio.Group
              onChange={this.handleShowSelectListVisibleChange}
              value={showSelectListVisible}
            >
              <Radio value>
                <T id="yes" />
              </Radio>
              <Radio value={false}>
                <T id="no" />
              </Radio>
            </Radio.Group>
          </div>
        </div>
        {showSelectListVisible ? <SelectList data={select_list} onChange={this.handleChange}/> : null}
        <div className="item">
          <T id="default_show" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              style={SELECT_STYLE}
              value={options.length && options.find((item) => item.key === defaultShow) ? defaultShow : undefined}
              onChange={(value) => {
                this.handleAssetsChange(['defaultShow'], value)
              }}
            >
              {options.map((item) => {
                const { name, key } = item
                return (
                  <Option key={key} value={key}>
                    <T id={name} />
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      </>
    )
  }
}

export default EditableComponent
