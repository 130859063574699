/* eslint-disable import/order */
import { isHitKey } from '@watchface/utils'
import { immutableSelector } from '@/utils'
import { Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import constants, { MOVE_IMAGE, SERIES } from '@watchface/constants'
import { SELECT_STYLE } from '@watchface/constants/style'
import { removeCells, renderCells } from '@watchface/utils/render'
import { Button, InputNumber, Select, Alert } from 'antd'
import Immutable from 'immutable'
import PubSub from 'pubsub-js'
import React, { PureComponent } from 'react'
import { v4 as uuidv4 } from 'uuid'

const { Option } = Select

class PictureProgress extends PureComponent {
  static keyMap = {}

  static imgCellKey = uuidv4().replace(/-/g, '_')

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: PictureProgress,
      ...param
    })
    PictureProgress.keyMap = Object.assign(PictureProgress.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: PictureProgress,
      ...param
    })
    PictureProgress.keyMap = keyMap
  }

  removeAllButton = (
    <Button className="upload-btn" onClick={() => this.handleUpload([])}>
      Remove All
    </Button>
  )

  componentDidMount() {
    PubSub.subscribe(MOVE_IMAGE, (eventName, { left, top, key, texture }) => {
      const { data, parentKey, childKey, editableComponentKey = '', dialType } = this.props

      if (!isHitKey(key, parentKey, childKey, editableComponentKey, dialType)) return

      const { images = Immutable.List() } = immutableSelector(data)
      const index = images.findIndex((url) => texture === url)

      if (index === -1) return

      this.handlePosChange(index, { x: left, y: top }, images.size)
    })
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props

    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (!hidden) {
        PictureProgress.renderLayer(this.props)
      } else {
        PictureProgress.removeLayer(this.props)
      }
    }
  }

  static getCellDatas = ({ parentKey, childKey, data, assets }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const images = PictureProgress.getImages({ parentKey, childKey, data, assets })
    images.forEach((image) => {
      cells.imageEntity[image.key] = image
    })
    return cells
  }

  static getImages = ({ data, assets }) => {
    const { positions = Immutable.List(), images = Immutable.Map(), is_continuance } = immutableSelector(data)
    const { isDependent, previewPicNum = 1 } = immutableSelector(assets)
    const imgs = []
    const cellKey = PictureProgress.imgCellKey

    if (!isDependent) {
      const index = previewPicNum - 1
      const image = index >= 0 ? images.get(index) : ''
      const x = positions.getIn([0, 'x'], 0)
      const y = positions.getIn([0, 'y'], 0)

      if (image) {
        imgs.push({ key: cellKey, texture: image, left: x, top: y })
      }
    } else if (is_continuance) {
      images.forEach((image, index) => {
        const x = positions.getIn([index, 'x'], 0)
        const y = positions.getIn([index, 'y'], 0)
        imgs.push({ key: cellKey, texture: image, left: x, top: y })
      })
    } else {
      const image = images.get(0)
      const x = positions.getIn([0, 'x'], 0)
      const y = positions.getIn([0, 'y'], 0)

      if (image) {
        imgs.push({ key: cellKey, texture: image, left: x, top: y })
      }
    }
    return imgs
  }

  get showIsDependent() {
    const { series } = this.props
    const seriesConstants = constants[series] || {}
    const { showIsDependent } = seriesConstants

    return showIsDependent
  }

  get isDependent() {
    const { assets = Immutable.Map(), data = Immutable.Map(), fixedProps } = this.props
    const { is_continuance: fixedIsContinue } = fixedProps
    const isDependent = assets.get('isDependent', false)
    const isContinuance = typeof fixedIsContinue === 'undefined' ? data.get('is_continuance', true) : fixedIsContinue

    return this.showIsDependent ? isDependent : isContinuance
  }

  handleChange = (path = [], value) => {
    const { childKey, parentKey, data = Immutable.Map(), onChange, prefixPath } = this.props
    let newData = data

    if (path[0] === 'is_continuance') {
      const { images = Immutable.List() } = immutableSelector(data)

      if (images.size > 1) {
        const positions = images.map(() =>
          Immutable.Map({
            x: 0,
            y: 0
          })
        )

        newData = newData.setIn(['positions'], positions)
      }

      if (!this.showIsDependent) {
        this.initPosShow(this.isDependent)
      }
    }

    newData = newData.setIn(path, value)
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleLevelChange = (value) => {
    if (!/^-?\d+$/.test(value)) return

    const { parentKey, childKey, dialType, data = Immutable.Map(), onAssetsChange, prefixPath } = this.props
    const { images = Immutable.List() } = immutableSelector(data)
    const newImages = images.slice(0, value)

    this.handleChange(['images'], newImages)

    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'level'], value)
  }

  handleUpload = (img) => {
    let images = img

    if (!images) images = []

    const { parentKey, childKey, dialType, prefixPath, data = Immutable.Map(), assets = Immutable.Map(), onAssetsChange } = this.props
    const { level = 1 } = immutableSelector(assets)

    if (level === 1 && !Array.isArray(images)) images = [images]

    const newImgs = images.map((img) => img.image)
    const newImgsNames = images.map((img) => img.name)

    if (!this.isDependent) {
      const { positions = Immutable.List() } = immutableSelector(data)
      const position = positions.get(0) || Immutable.Map()

      const newPositions = Immutable.List(newImgs).map(() => position)
      this.handleChange(['positions'], newPositions)
    }

    this.handleChange(['images'], Immutable.List(newImgs))
    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'images_names'], Immutable.List(newImgsNames))
  }

  handlePosChange = (index, posObj, imgCount) => {
    const keys = Object.keys(posObj)

    for (let i = 0; i < keys.length; i += 1) {
      if (!/^-?\d+$/.test(posObj[keys[i]])) return
    }

    const { data = Immutable.Map() } = this.props
    let positions = data.get('positions') || Immutable.List()

    const updatePos = (idx) => {
      if (!Immutable.Map.isMap(positions.get(idx))) {
        positions = positions.set(idx, Immutable.Map())
      }

      const index = this.isDependent ? idx : 0
      let position = positions.get(index) || Immutable.Map()

      if ('x' in posObj) {
        position = position.set('x', posObj.x || 0)
      }

      if ('y' in posObj) {
        position = position.set('y', posObj.y || 0)
      }

      positions = positions.set(idx, position)
    }

    if (!this.isDependent) {
      for (let i = 0; i < imgCount; i += 1) {
        updatePos(i)
      }
    } else {
      updatePos(index)
    }
    this.handleChange(['positions'], positions)
  }

  handleRemove = (removeIndex) => {
    const { data = Immutable.Map(), assets = Immutable.Map(), dialType, prefixPath, parentKey, childKey, onAssetsChange } = this.props
    const { positions = Immutable.List() } = immutableSelector(data)
    const { images_names = Immutable.List() } = immutableSelector(assets)
    const newPositions = positions.splice(removeIndex, 1)
    const newImgsNames = images_names.splice(removeIndex, 1)

    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'images_names'], Immutable.List(newImgsNames))
    this.handleChange(['positions'], newPositions)
  }

  initPosShow = (isDependent) => {
    const { parentKey, childKey, dialType, data = Immutable.Map(), onAssetsChange, prefixPath } = this.props
    const { positions = Immutable.List() } = immutableSelector(data)

    // 初始化坐标及图片位置
    const newPositions = positions.map(() => Immutable.Map({ x: 0, y: 0 }))
    this.handleChange(['positions'], newPositions)
    if (this.showIsDependent) {
      onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'isDependent'], isDependent)
    }
  }

  handlePreivewPic = (value) => {
    const { parentKey, childKey, dialType, onAssetsChange, prefixPath } = this.props

    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'previewPicNum'], value)
  }

  renderLevel = (level) => {
    const { fixedProps = {}, imageCountMax = 30 } = this.props
    const fixedLevel = 'level' in fixedProps

    return (
      <>
        <div>
          <T id="image_count" />
        </div>
        <div className="content">
          <div className="item system-upload-wrap">
            {fixedLevel ? level : <InputNumber value={level} onChange={this.handleLevelChange} min={1} max={imageCountMax} />}
          </div>
        </div>
      </>
    )
  }

  renderPositions = (posCount, imgCount) => {
    const arr = [...new Array(posCount).keys()]
    const { data = Immutable.Map(), watchConfig = Immutable.Map() } = this.props
    const { positions = Immutable.List() } = immutableSelector(data)
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)

    return arr.map((item, index) => {
      const x = positions.getIn([index, 'x'], 0)
      const y = positions.getIn([index, 'y'], 0)
      return (
        <div className="item" key={item}>
          <div className="col">
            <div className="key">x</div>
            <InputNumber
              min={0}
              max={screenWidth}
              value={x}
              onChange={(value) => {
                this.handlePosChange(index, { x: value }, imgCount)
              }}
            />
          </div>
          <div className="col">
            <div className="key">y</div>
            <InputNumber
              min={0}
              max={screenHeight}
              value={y}
              onChange={(value) => {
                this.handlePosChange(index, { y: value }, imgCount)
              }}
            />
          </div>
        </div>
      )
    })
  }

  render() {
    const { parentKey, assets, data = Immutable.Map(), watchConfig, fixedProps = {}, showNamingRule, handleWatchNamingRule, series } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const { images = Immutable.List(), is_continuance = true } = immutableSelector(data)
    const { level = images.size || 1, images_names = Immutable.List(), previewPicNum = 1 } = immutableSelector(assets)
    const newLevel = fixedProps.level || Number(level)
    const fixedIsDependent = 'isDependent' in fixedProps
    const fixedIsContinue = 'is_continuance' in fixedProps
    const imageFileList = []

    images.some((image, index) => {
      const imageName = (images_names || Immutable.List()).get(index)

      // if (!imageName) return true
      imageFileList.push({
        image,
        name: imageName || `${index}.png`
      })

      return false
    })

    return (
      <div className="control-container">
        {parentKey === 'battery' && series === SERIES.JS ? <div style={{ marginBottom: '10px' }}>
          <Alert message={<T id="low_battery_progress_tip" />} type="warning" />
        </div> : null}
        {this.renderLevel(newLevel)}
        <div className="name">
          <T id="display_method" />
        </div>
        <div className="content">
          <div className="item">
            <Select
              dropdownClassName="watch-skin-select-dropdown"
              defaultValue={String(+(fixedIsContinue ? fixedProps.is_continuance : is_continuance))}
              onChange={(value) => {
                this.handleChange(['is_continuance'], Boolean(+value))
              }}
              style={SELECT_STYLE}
              disabled={fixedIsContinue}
            >
              <Option value="1">
                <T id="continuous_display" />
              </Option>
              <Option value="0">
                <T id="single_display" />
              </Option>
            </Select>
          </div>
        </div>
        <div className="name is-required">
          <T id="upload_image" />
          {showNamingRule ? (
            // eslint-disable-next-line
            <a style={{ marginLeft: '20px' }} onClick={handleWatchNamingRule}>
              <T id="naming_rule" />
              &gt;
            </a>
          ) : null}
        </div>
        <div className="content">
          <div className="item">
            <Upload
              fileList={imageFileList.length === images.size ? imageFileList : []}
              fileCountLimit={newLevel || Number.MAX_SAFE_INTEGER}
              field="image"
              multiple
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              onChange={this.handleUpload}
              onRemove={this.handleRemove}
              removeAllButton={this.removeAllButton}
            />
          </div>
        </div>
        <div className="name">
          <T id="position" />
        </div>
        {this.showIsDependent ? (
          <div className="content">
            <div className="item">
              <Select
                dropdownClassName="watch-skin-select-dropdown"
                defaultValue={fixedIsDependent ? String(+fixedProps.isDependent) : String(+this.isDependent)}
                disabled={fixedIsDependent}
                onChange={(value) => {
                  this.initPosShow(Boolean(+value))
                }}
                style={SELECT_STYLE}
              >
                <Option value="1">
                  <T id="independent" />
                </Option>
                <Option value="0">
                  <T id="uniform" />
                </Option>
              </Select>
            </div>
          </div>
        ) : null}
        <div className="content">{this.renderPositions(this.isDependent ? imageFileList.length || 1 : 1, imageFileList.length)}</div>
        {this.isDependent ? null : (
          <>
            <div className="name">
              <T id="preview" />
            </div>
            <div className="content">
              <div className="item">
                <InputNumber min={1} max={imageFileList.length || 1} value={previewPicNum} onChange={this.handlePreivewPic} />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

PictureProgress.defaultProps = {
  fixedProps: {},
  showNamingRule: false,
  imageCountMax: 30,
  handleWatchNamingRule: () => {}, // eslint-disable-line
  prefixPath: []
}

export default PictureProgress
