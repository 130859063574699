import { DIAL_TYPE, JSON2_SUPPORTED_KEY, TYPE_TO_ICON } from './constants'

type LangItem = 'en' | 'zh' | 'zh-Hant' | 'all'

/**
 *
 * @param key text, etc.
 * @param widget widget data
 * @returns new menu data
 */
const getMenuFromWidget = (key: string, widget: any) => {
  const menu: any = {
    key: '',
    name: '',
    icon: '',
    expand: false,
    hide: false,
    canClick: true,
    visibleOnAod: false,
    order: ''
  }
  const { id, name } = widget
  const menuKey = `${key}_${id}`
  const icon = TYPE_TO_ICON[key]
  const hide = !widget.visible
  // json 2.0 divided Aod and normal menu
  menu.visibleOnAod = widget.visibleOnAod
  menu.key = menuKey
  menu.name = name
  menu.icon = icon
  menu.hide = hide
  if (Object.hasOwnProperty.call(widget, 'children')) {
    const { children = [] } = widget
    const childrenMenu: Array<any> = []
    children.forEach((item: any) => {
      const { id, name, type } = item
      childrenMenu.push({
        name,
        key: `${type}_${id}`,
        icon: TYPE_TO_ICON[type],
        checked: item.visible
      })
    })
    menu.children = childrenMenu
  }
  return menu
}

const getKeyPathFromMenu = (menukey: string, menuArr: any[]) => {
  for (const menu of menuArr) {
    const parentKey = menu.key
    if (parentKey === menukey) {
      return [parentKey]
    }
    if (menu?.children?.length) {
      for (const subMenu of menu?.children) {
        if (subMenu.key === menukey) {
          return [parentKey, subMenu.key]
        }
      }
    }
  }
  return []
}

const checkIfValidUUID = (str: string) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(str)
}

const isWidgetMenuKey = (str: string) => {
  const [key, id] = str.split('_')
  return JSON2_SUPPORTED_KEY.includes(key) && checkIfValidUUID(id)
}

const isWidgetFabricKey = (str: string) => {
  const [dialType, key, id] = str.split('_')
  return (dialType === DIAL_TYPE.NORMAL || dialType === DIAL_TYPE.IDLE) && JSON2_SUPPORTED_KEY.includes(key) && checkIfValidUUID(id)
}

const getKeyOrIdFromStr = (str: string) => {
  if (isWidgetFabricKey(str)) {
    const [dialType, key, id] = str.split('_')
    return { dialType, key, id }
  }
  if (isWidgetMenuKey(str)) {
    const [key, id] = str.split('_')
    return { key, id }
  }
  return {
    key: '',
    id: ''
  }
}

const filterWidgetCategoryImageByLang = (widget: any, langList: LangItem[]) => {
  const categories = widget.get('categories')
  if (!categories) {
    return widget
  }
  const newCategories = categories.map((category: any) => {
    const type = category.get('type')
    if (type === 'image' || type === 'imageGroup') {
      const existedValue = category.getIn(['properties', 'image', 'value'], [])
      if (existedValue.size === 1 && existedValue.getIn([0, 'lang']) === 'all') {
        return category
      }
      const newValue = existedValue.filter((item: any) => langList.includes(item.get('lang')))
      return category.setIn(['properties', 'image', 'value'], newValue)
    }
    return category
  })
  return widget.set('categories', newCategories)
}

export { getMenuFromWidget, isWidgetMenuKey, isWidgetFabricKey, getKeyOrIdFromStr, filterWidgetCategoryImageByLang, getKeyPathFromMenu }
