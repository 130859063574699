const str = `import './shared/device-polyfill'

App({
  globalData: {},
  onCreate(options) {},
  onDestroy(options) {},
  onError(error) {},
  onPageNotFound(obj) {},
  onUnhandledRejection(obj) {},
})
`

export default str
