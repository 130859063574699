/* eslint-disable import/order */
import { immutableSelector } from '@/utils/index'
import { isWidgetMenuKey } from '@watchface/components/Meta'
import { INITIAL_VERSION, SERIES, ZEPP_DOC_LINKS, TAGS, APPID_START_NUM_ZEPP_OS } from '@watchface/constants'
import apiHost from '@watchface/request/domains/common'
import { getUniversalLoginInfo } from '@/utils/universalLogin'
import { fabric } from 'fabric'
import Immutable from 'immutable'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { groupBy } from 'lodash'

import { imgUrlToBlob } from './img'

export { default as analytics } from './analytics'

export const getDefaultLangField = (languages = '', LANG_MAPPER) => {
  const langArr = languages.split(',')
  const firstLang = langArr[0]
  const { field = '' } = LANG_MAPPER[firstLang] || {}

  return field
}

export const getOriginLang = (metas) => {
  let originLang = metas.get('multi_language').reduce((pre, item) => {
    return pre === '' ? item.get('language') : `${pre},${item.get('language')}`
  }, '')
  originLang = originLang.replace('zh,en', 'sc,en')

  return originLang
}

export const hex2rgb = (hex) => {
  let newHex = hex
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

  newHex = newHex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

export const getDeviceType = (series) => {
  const seriesMap = {
    [SERIES.COMO]: 'lite',
    [SERIES.NESS]: 'lite',
    [SERIES.GT2]: 'gt2',
    [SERIES.JS]: 'Zepp OS'
  }

  return seriesMap[series]
}

// groupBy gt2、gt3、lite
export const getGroupedDevices = (supportDevices) => {
  return groupBy(supportDevices, ({ series }) => getDeviceType(series))
}

export const handleApiDomain = (domains = []) => {
  domains.forEach((domainsItem) => {
    const curHost = domainsItem?.cnames?.[0] || ''
    const curDefaultHost = domainsItem?.host || ''
    if (curHost.includes('api-mifit')) {
      localStorage.setItem('wf_baseUrl', curHost || curDefaultHost)
    }
  })
}
export const convertToDegree = (num) => num * ((Math.PI * 2) / 360)
export const convertToAngle = (degree) => degree / ((Math.PI * 2) / 360)
/**
 * 获取旋转后坐标
 * @param { Object } pos - 图片左上角坐标
 * @param { Object } originPos 图片旋转点坐标（绝对坐标，不是相对于图片）
 * @param { number } degree 旋转角度
 * @returns
 */
export const getRotatedPoint = (pos = {}, originPos = {}, degree = 0) => {
  const { x, y } = pos
  const { x: originX, y: originY } = originPos
  const point = new fabric.Point(x, y)
  const origin = new fabric.Point(originX, originY)
  const radians = fabric.util.degreesToRadians(degree)
  const { x: rotatePointX, y: rotatePointY } = fabric.util.rotatePoint(point, origin, radians)

  return {
    x: +Number(rotatePointX).toFixed(2),
    y: +Number(rotatePointY).toFixed(2)
  }
}

export const getDialNameLenByLang = () => {
  const DIAL_NAME_CN_LENGTH = 30
  const DIAL_NAME_EN_LENGTH = 30
  const LANG = window.localStorage.getItem('watchface_lang')
  if (LANG.includes('zh')) {
    return DIAL_NAME_CN_LENGTH
  }
  return DIAL_NAME_EN_LENGTH
}

export const handleBrowserLang = () => {
  // getLocaleLanguage 方法多语言 sdk 提供
  const { getLocaleLanguage, navigator } = window
  const { currentLang: sdkLang } = getLocaleLanguage(navigator, 'en')
  const storageLang = localStorage.getItem('watchface_lang')
  const lang = storageLang || sdkLang
  const isCN = lang === 'zh' || lang === 'zh-cn'
  return { lang, isCN }
}

/**
 *
 * @param {*} type 参考 ZEPP_DOC_LINKS 对象 Key
 * @returns
 */
export const getZeppDocLink = (type) => {
  const { isCN } = handleBrowserLang()
  const docLink = ZEPP_DOC_LINKS[type]
  const link = `${apiHost.zeppOsDoc}${docLink}`
  const cnLink = `${apiHost.zeppOsDoc}zh-cn/${docLink}`
  return isCN ? cnLink : link
}

export const getImageInfo = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image()

    image.src = url

    image.onload = () => {
      resolve(image)
    }

    image.onerror = reject
  })
}

export const dealConfigForApi = (configData, dialType, assetData) => {
  let newAsset = assetData
  const { config, menu } = immutableSelector(configData)
  let newConfig = Immutable.Map()
  // 隐藏的 key 数组
  let hideKeys = Immutable.List()
  // 设置配置顺序
  let order = Immutable.List()
  const sortedMenu = menu.filter((item) => !!item.get('key')).sort((a, b) => a.get('order') - b.get('order'))

  sortedMenu.forEach((item) => {
    const key = item.get('key')
    order = order.push(key)
  })

  // 将 mask 置于 component 前
  const componentIndex = order.findLastIndex((item) => item.startsWith('component_'))
  if (componentIndex > -1) {
    const originMaskIndex = order.indexOf('mask')
    const newMaskIndex = originMaskIndex > componentIndex ? originMaskIndex + 1 : originMaskIndex
    order = order.splice(componentIndex + 1, 0, 'mask').splice(newMaskIndex, 1)
  }

  newConfig = newConfig.set('order', order)
  const component = {
    cover: '',
    mask: '',
    items: []
  }
  let hasComponent = false
  // 发送数据前进行处理
  sortedMenu.forEach((m) => {
    const { key, hide, children = Immutable.List(), isTemporary } = immutableSelector(m)

    if (isTemporary || isWidgetMenuKey(key)) return

    if (hide) {
      hideKeys = hideKeys.push(key)
    }
    // 对 key 为 mask 和 以 component 开头，进行特殊处理
    if (key === 'mask') {
      hasComponent = true
      component.cover = config.getIn([key, 'cover'])
      component.mask = config.getIn([key, 'mask'])
    } else if (key.startsWith('component')) {
      const path = key.split('_')
      const defaultShow = newAsset.getIn([dialType, ...path, 'defaultShow'])

      let componentConfig = config.getIn([...path])
      let propsArr = Immutable.List()

      const props = componentConfig.get('props')

      props.keySeq().forEach((key) => {
        const menuItem = children.find((child) => child.get('key') === key)
        const { checked } = immutableSelector(menuItem)

        if (checked) {
          let newProps = Immutable.Map()
          newProps = newProps.set('bg', props.getIn([key, 'bg']))
          newProps = newProps.set('previews', props.getIn([key, 'previews']))
          const itemProps = props.get(key)
          const { children: comChildren } = immutableSelector(itemProps)
          if (comChildren) {
            comChildren.keySeq().forEach((childKey) => {
              const subMenuItem = menuItem.get('children').find((item) => item.get('key') === childKey)
              const { checked: subChecked } = immutableSelector(subMenuItem)
              if (subChecked) {
                newProps = newProps.setIn([key, childKey], comChildren.get(childKey))
              }
            })
          } else {
            newProps = newProps.set(key, itemProps)
          }
          if (defaultShow === key) {
            propsArr = propsArr.unshift(newProps)
          } else {
            propsArr = propsArr.push(newProps)
          }
        }
      })

      componentConfig = componentConfig.set('props', propsArr)
      component.items.push(componentConfig)
    } else {
      newConfig = newConfig.set(key, config.get(key))
      children.forEach((c) => {
        const { key: childKey, checked } = immutableSelector(c)
        if (!checked) {
          newAsset = newAsset.deleteIn([dialType, key, 'children', childKey])
          newConfig = newConfig.deleteIn([key, 'children'])
          return
        }
        if (childKey === 'ampm') {
          newConfig = newConfig.setIn([key, 'am'], config.getIn([key, 'children', 'am']))
          newConfig = newConfig.setIn([key, 'pm'], config.getIn([key, 'children', 'pm']))
          newConfig = newConfig.deleteIn([key, 'children'])
        } else {
          newConfig = newConfig.setIn([key, childKey], config.getIn([key, 'children', childKey]))
          newConfig = newConfig.deleteIn([key, 'children'])
        }
      })
    }
  })

  newConfig = newConfig.set('hide', hideKeys)

  if (hasComponent) {
    newConfig = newConfig.set('component', component)
  }

  return [newConfig, newAsset]
}

/**
 * https://huami.feishu.cn/wiki/wikcn9mCi8GHzS85algfceUJfJg#lJxaSj
 * @param {number} min
 * @param {number} max
 * @returns
 */
export const getRandomAppid = (min = 20000, max = 30000) => {
  const minInt = Math.ceil(min)
  const maxInt = Math.floor(max)
  return Math.floor(Math.random() * (maxInt - minInt - 1) + minInt + 1) // The maximum and the minimum are all exclusive
}

/**
 * 禁用图片浏览器缓存，解决图片跨域问题
 *
 * @param {string} url
 * @param {string} query
 * @returns
 */
export const processImageUrl = (url = '', query = '') => {
  // 判断是否为 dataURL, dataURL 添加 query 资源会找不到
  if (url?.startsWith('data:')) return url

  const delimiter = url.indexOf('?') > -1 ? '&' : '?'

  return `${url}${delimiter}${query}`
}

export const handleOldEditableBg = (data) => {
  const order = data.getIn(['config', 'order'], Immutable.List())
  const normalEditableBg = data.getIn(['config', 'assets', 'normal', 'editable_bg'], Immutable.Map())
  const extraEditableBg = data.getIn(['config', 'assets', 'normal', 'extraConfig', 'editable_bg'], Immutable.Map())
  const index = order.findIndex((v) => v === 'editable_bg')
  if (index === -1) {
    return data
  }
  const newOrder = order.set(index, 'background')
  const background = Immutable.fromJS({
    type: 'image',
    color: '0xFFFFFFFF'
  })
  const newNormalBg = Immutable.fromJS({
    background: {
      previewBgNum: normalEditableBg.getIn(['children', 'bg', 'previewPicNum'], 1)
    }
  })
  const newExtraBg = Immutable.fromJS({
    background: {
      bg_type: 'editable_bg',
      editable_bg: {
        bg_images: extraEditableBg.getIn(['bg', 'bg_images'], []),
        count: extraEditableBg.getIn(['bg', 'count'], 2),
        fg_image: extraEditableBg.getIn(['bg', 'fg_images', 0], ''),
        preview_images: extraEditableBg.getIn(['bg', 'preview_images'], []),
        tip_image: extraEditableBg.getIn(['tips', 'image'], ''),
        tip_position: {
          x: extraEditableBg.getIn(['tips', 'position', 'x'], 0),
          y: extraEditableBg.getIn(['tips', 'position', 'y'], 0)
        }
      },
      radius: 0
    }
  })

  let newData = data
    .deleteIn(['config', 'order'])
    .deleteIn(['config', 'assets', 'normal', 'editable_bg'])
    .deleteIn(['config', 'assets', 'normal', 'extraConfig', 'editable_bg'])

  newData = newData.setIn(['config', 'order'], newOrder)
  newData = newData.setIn(['config', 'background'], background)
  newData = newData.setIn(['config', 'assets', 'normal'], newNormalBg)
  newData = newData.setIn(['config', 'assets', 'normal', 'extraConfig'], newExtraBg)

  return newData
}

export const handleOldLunarData = (data) => {
  const lunar = data.getIn(['config', 'lunar'])

  // 没有农历模块或者是最新数据，直接返回
  if (!lunar || lunar?.size <= 2) return data

  let newLunar = Immutable.fromJS({
    lunar_date: lunar.delete('show_festival_Term').set('display_type', 1)
  })

  const { show_festival_Term } = immutableSelector(lunar)

  if (show_festival_Term === true) {
    newLunar = newLunar.set('festival_or_solar_term', lunar.delete('show_festival_Term').set('display_type', 0))
  }

  return data.setIn(['config', 'lunar'], newLunar)
}

export const handleOldTemperatureUnitData = (data) => {
  let newData = data

  const config = data.get('config')
  const idleConfig = config.get('idle_config')

  if (!Immutable.Map.isMap(config) || !Immutable.Map.isMap(idleConfig)) return newData

  let asset = config.get('assets')
  let normalTemperature = config.get('temperature')
  let idleTemperature = idleConfig.get('temperature')

  const defaultUnitImage = Immutable.fromJS([
    {
      language: 'all',
      image: {}
    }
  ])

  const removeErrorUnit = (temperature, dialType, key) => {
    let newTemperature = temperature

    if (newTemperature.has(key)) {
      const assetUnitPath = [dialType, 'temperature', 'children', key, 'unit']
      const unit = asset.getIn(assetUnitPath, 'all')
      const unitImagesPath = [key, 'text', 'image', 'unit_images']
      const unitImages = newTemperature.getIn(unitImagesPath)

      if (unit === 'all' && unitImages?.size === 1) return newTemperature

      const zhUnitImage = unitImages.find(item => item.get('language') === 'zh')
      const newUnitImage = zhUnitImage ? Immutable.fromJS([zhUnitImage.set('language', 'all')]) : defaultUnitImage

      newTemperature = newTemperature.setIn(unitImagesPath, newUnitImage)

      asset = asset.setIn(assetUnitPath, 'all')
    }
    return newTemperature
  }

  if (normalTemperature) {
    ;['current', 'high', 'low'].forEach((key) => {
      normalTemperature = removeErrorUnit(normalTemperature, 'normal', key)
    })
    newData = newData.setIn(['config', 'temperature'], normalTemperature)
  }

  if (idleTemperature) {
    ;['current', 'high', 'low'].forEach((key) => {
      idleTemperature = removeErrorUnit(idleTemperature, 'idle', key)
    })
    newData = newData.setIn(['config', 'idle_config', 'temperature'], idleTemperature)
  }

  return newData.setIn(['config', 'assets'], asset)
}


export const handleLangData = (data) => {
  let newData = data
  const isZeppOS = data.getIn(['support', 'series']) === SERIES.JS
  const pathOfMultiLang = ['config', 'metas', 'multi_language']
  const pathOfFontImage = ['text', 'image', 'font_images']
  const multiLang = newData.getIn(pathOfMultiLang) || Immutable.List()
  const allLangMeta = multiLang.find(item => item.get('language') === 'all')
  const hasAllLang = !!allLangMeta

  const handleMultiLangConfig = (config) => {
    if (!config) return

    let newConf = config

    function mapAllImageToOtherLangs(confData) {
      let fontImage = confData.getIn(pathOfFontImage)

      if (!fontImage) return confData

      const allLangImages = fontImage.find(item => item.get('language') === 'all')

      if (!allLangImages) return confData

      fontImage = Immutable.List([
        allLangImages.set('language', 'en'),
        allLangImages.set('language', 'zh'),
        allLangImages.set('language', 'zh-Hant')
      ])

      return confData.setIn(pathOfFontImage, fontImage)
    }

    function rmUnusedLangs(confData) {
      if (isZeppOS) return confData
      let fontImage = confData.getIn(pathOfFontImage)
      fontImage = fontImage.filter(item => multiLang.map(item => item.get('language')).includes(item.get('language')))

      return confData.setIn(pathOfFontImage, fontImage)
    }

    const needHandleMultiLang = [
      {
        path: ['date', 'month'],
        handler: (confData) => {
          const isPicMode = confData.getIn(['text', 'is_character'])
          // 图片展示
          if(isPicMode) {
            return mapAllImageToOtherLangs(rmUnusedLangs(confData))
          }
          return confData
        }
      },
      {
        path: ['week', 'text'],
        handler: (confData) => {
          return mapAllImageToOtherLangs(rmUnusedLangs(confData))
        }
      }
    ]

    needHandleMultiLang.forEach(({ path, handler }) => {
      const confData  = newConf.getIn(path)

      // 没有多语言数据项
      if (!confData) return

      newConf = newConf.setIn(path, handler(confData))
    })

    return newConf
  }

  if (hasAllLang) {
    const { desc, image, name } = immutableSelector(allLangMeta)
    const currentSupportLang = ['en', 'zh', 'zh-Hant']
    const newMultiLang = currentSupportLang.reduce((result, currLang) => result.push(Immutable.Map({
      desc,
      image,
      name,
      language: currLang,
    })), Immutable.List())

    newData = newData.setIn(pathOfMultiLang, newMultiLang)
  }

  let config = newData.get('config')
  const idleConfig = config.get('idle_config')

  if (idleConfig) {
    config = config.set('idle_config', handleMultiLangConfig(idleConfig))
  }

  newData = newData.set('config', handleMultiLangConfig(config))

  if (hasAllLang) {
    return newData.setIn(['config', 'assets', 'fromAllLang'], true)
  }
  return newData
}

export const compatibleWithOldData = (data) => {
  try {
    let newData = data

    newData = handleOldLunarData(newData)
    newData = handleOldTemperatureUnitData(newData)
    newData = handleLangData(newData)

    return newData
  } catch (e) {
    console.log('error in compatibleWithOldData', e)
    return data
  }
}

/**
 * 执行版本号加1操作
 * @param {string} version
 * @returns
 */
export const versionPlusOne = (version = INITIAL_VERSION) => {
  const [h0, t0, o0] = version.split('.')
  let num = Number(h0.padStart(2, '0') + t0.padStart(2, '0') + o0.padStart(2, '0'))
  num += 1
  const str = num.toString()
  const h1 = Number(str.slice(0, -4))
  const t1 = Number(str.slice(-4, -2))
  const o1 = Number(str.slice(-2))
  return `${h1}.${t1}.${o1}`
}

// 获取二级域名 second-level domain
export const getSLD = () => {
  const domain = window.location.hostname
  const sld = domain.split('.').reverse()[1]

  return sld || 'zepp'
}

export function getCname(partOfHostForFilter) {
  const loginInfo = getUniversalLoginInfo()
  if (loginInfo) {
    const obj = loginInfo.domains.filter((o) => {
      return o.host.indexOf(partOfHostForFilter) > -1
    })
    if (obj[0]) return obj[0].cnames[0]
  }
  return ''
}

export const getConfigJson = () => {
  const rawData = sessionStorage.getItem('wf_raw_data') || '{}'
  const data = JSON.parse(rawData)
  const { config = { assets: {} }, support, deviceInfo } = data
  const {
    assets: { appId = getRandomAppid(), appVersion = INITIAL_VERSION }
  } = config
  return { config, support, appId, appVersion, deviceInfo }
}

export const isValidTag = (tag) => {
  return TAGS.some((tagItem) => tagItem.experssion === tag)
}

export const getCursorPosition = (inputEle) => {
  let cursorIndex = -1
  // IE Support
  if (document.selection) {
    const range= document.selection.createRange()
    range.moveStart('character', -inputEle.value.length)
    cursorIndex = range.text.length
  } else if (inputEle.selectionStart || inputEle.selectionStart === 0) {
    cursorIndex = inputEle.selectionStart
  }
  return cursorIndex
}

export const genZabFileName = (fileName, appId, appVersion) => appId >= APPID_START_NUM_ZEPP_OS ? `${appId}-${fileName}-${appVersion}.zab` : `${appId}-${fileName}.zab`

/**
 * 判断 zepp os appId 是否小于起始 appId
 * @param {*} appId
 * @returns
 */
export const osAppIdIsLtStartingAppId  = (appId) => appId - APPID_START_NUM_ZEPP_OS < 0

/**
 * 将远程文件打包进 zip 包
 * @param {*} urls 文件 cdn 地址
 * @param {*} names 文件名数组
 * @param {*} zipFilename zip 文件名
 */
 export const zipRemoteFiles = (zipFilename, urls, names = []) => {
  if (!urls.length) return
  const zip = new JSZip()
  const urlToPromise = (url) => imgUrlToBlob(url)
  urls.forEach((url, index) => {
    const filename = names[index] || `${index}.png`

    zip.file(filename, urlToPromise(url))
  })
  zip.generateAsync({ type:'blob' }).then((content) => {
    saveAs(content, zipFilename)
  })
}

export const isHitKey = (key, parentKey, childKey, editableComponentKey, dialType) => {
  const [key0, key1, key2, key3] = key.split('-')
  const [targetEditableComponentKey, targetParentKey, targetChildKey] = editableComponentKey ? [key1, key2, key3] : ['', key1, key2]

  // 命中当前组件
  const hitedKey = key0 === dialType && editableComponentKey === targetEditableComponentKey && parentKey === targetParentKey && childKey === targetChildKey

  return hitedKey
}

export const pathAffectZoomFlag = (dataArr = []) => {
  return dataArr.some(({ path = [] }) => path.includes('config'))
}

export const hasEditComponentInConfig = (normalData) => {
  const { menu = Immutable.List() } = immutableSelector(normalData)

  if (!menu.size) return false

  const componentExceptHide = menu.reduce((components, curr) => {
    if (!curr.get('hide')) {
      components.push(curr.get('key'))
    }
    return components
  }, [])

  return componentExceptHide.some(item => item.startsWith('component_'))
}

export const includeNonLeftAlignment = (config) => {
  const regex = /"align":("center"|"right")/g

  return regex.test(JSON.stringify(config))
}


export const getFileName = (url) => url.replace(/.*\//, '')

export const download = (url, fileName) => {
  if (!url) return
  try {
    saveAs(url, fileName || getFileName(url))
  } catch (e) {
    console.log(e)
  }
}

export const readFileAsUnit8Array = (file) => {
  return new Promise((resolve) => {
    const readerArrayBuffer = new FileReader()

    readerArrayBuffer.onload = () => {
      const unit8array = new Uint8Array(readerArrayBuffer.result)
      resolve(unit8array)
    }

    readerArrayBuffer.onerror = () => {
      resolve(null)
    }

    readerArrayBuffer.readAsArrayBuffer(file)
  })
}
