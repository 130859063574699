import {DATA_CHILD_KEY_ORDER_MAP} from '@watchface/constants'
import {isValidTag} from '@watchface/utils'
import {getKeyOrIdFromStr, isWidgetMenuKey} from '@watchface/components/Meta'
import Immutable from 'immutable'
import Mustache from 'mustache'
import {
  ALIGN_MAP,
  ANIM_STATUS_MAP,
  DATA_TYPE_MAP,
  DATE_TYPE_MAP,
  EDIT_TYPE_MAP,
  SENSOR_TAG_MAP,
  SHOW_LEVEL_MAP,
  SYSTEM_STATUS_MAP
} from './constants'
import {appJson, jsCodeMap, sensorMap, widgetMap} from './templates'
import {
  addIndentation,
  getDateReplace,
  getDialType,
  getKeyPath,
  getSensorVariable,
  parseBool2Number,
  parseCombin,
  removeIndentation,
  tagsDateCount
} from './utils'

const { createSensorTemp, sensorTemp, timerTemp } = sensorMap
let convertAppJson = '' // 转换后的 app.json
let convertAppName = '' // 获取的 app name
let convertImgMap = {} // 图片资源
let convertIndexJs = '' // 转换后的固件协议 index.js
let convertTargetName = ''

class BuildProcess {
  pathMapper = {}

  editId = 0

  editList = new Set()

  constructor(config, support, appId, appVersion, deviceInfo) {
    this.config = config
    this.support = support
    this.appId = appId
    this.appVersion = appVersion
    this.deviceInfo = deviceInfo
  }

  beforeBuild() {
    this.init()

    const immutableData = Immutable.fromJS(this.config) || Immutable.Map()
    const delKeys = ['idle_config', 'order', 'hide', 'metas', 'assets']
    const config_os = immutableData.get('config_os') || Immutable.Map()

    this.metas = immutableData.get('metas') || Immutable.Map()

    this.watchfaceLang = this.metas.get('multi_language').reduce((pre, item) => {
      pre.push(item.get('language'))
      return pre
    }, [])

    // 是否为通用语言
    this.isAll = this.watchfaceLang.join('') === 'all'

    this.normal = {
      hide: immutableData.get('hide') || Immutable.List(),
      order: immutableData.get('order') || Immutable.List(),
      config: immutableData.deleteAll(delKeys)
    }

    this.idle = {
      hide: immutableData.getIn(['idle_config', 'hide']) || Immutable.List(),
      order: immutableData.getIn(['idle_config', 'order']) || Immutable.List(),
      config: (immutableData.get('idle_config') || Immutable.Map()).deleteAll(delKeys).set('config_os', config_os)
    }

    this.assets = immutableData.get('assets') || Immutable.Map()
  }

  build(dialType) {
    // build type
    let { order } = this[dialType]
    const { hide, config } = this[dialType]
    // move hide module
    order = order.filter((orderKey) => !hide.includes(orderKey))
    const editComponentCount = order.filter((orderKey) => orderKey.replace(/_\d+/, '') === 'component').size
    let cover = ''
    let mask = ''
    const { screen_resolution } = this.support
    const [screenW = 0, screenH = 0] = screen_resolution.split('*') || []

    if (editComponentCount > 0) {
      const component = config.get('component')
      cover = component.get('cover')
      mask = component.get('mask')
    }

    let editComponentRenderCount = 0

    // build by order
    order.forEach((moduleKey) => {
      const prefix = `${dialType}$_$${moduleKey}`
      const isEditComponent = moduleKey.replace(/_\d+/, '') === 'component'

      if (isEditComponent) {
        const [componentKey, indexKey] = moduleKey.split('_')

        this.travelConfig({
          moduleKey: 'component',
          moduleData: config.getIn([componentKey, 'items', indexKey]),
          prefix
        })

        editComponentRenderCount += 1

        // 可编辑组件绘制完成
        if (cover && editComponentRenderCount === editComponentCount && !this.editList.has('editableBg')) {
          const widgetName = 'WATCHFACE_EDIT_MASK'
          this.generateWidgetCode(widgetName, {
            variable: `${dialType}$_$component$_$cover`,
            cover: this.mapImgUrl(cover),
            width: screenW,
            height: screenH
          })
        }
      } else if (isWidgetMenuKey(moduleKey)) {
        const { key, id } = getKeyOrIdFromStr(moduleKey)
        const realKey = `config_os_${key}`
        const moduleData = config.getIn(['config_os', 'scene', 'widgets', id], null)
        if (!moduleData?.get('visible')) return
        this.travelConfig({
          moduleKey: realKey,
          moduleData,
          prefix
        })
      } else {
        this.travelConfig({
          moduleKey,
          moduleData: config.get(moduleKey),
          prefix
        })
      }
    })

    // 置于最上层渲染
    if (mask) {
      const widgetName = 'WATCHFACE_EDIT_FG_MASK'

      this.generateWidgetCode(widgetName, {
        variable: `${dialType}$_$component$_$mask`,
        mask: this.mapImgUrl(mask),
        width: screenW,
        height: screenH
      })
    }
  }

  buildAppJson() {
    const multiLanguage = this.metas.get('multi_language') || Immutable.List()
    const fromZoom = this.assets.get('fromZoom')
    const isHighCost = this.normal.order.includes('animation') && !this.normal.hide.includes('animation') ? 1 : 0
    const dialInfo = {}

    multiLanguage.reduce((pre, curr) => {
      const preview = this.mapImgUrl(curr.get('image'))
      const language = curr.get('language')

      // eslint-disable-next-line no-param-reassign
      pre[language] = {
        preview,
        name: curr.get('name'),
        desc: curr.get('desc')
      }

      return pre
    }, dialInfo)
    const { hide: normalHide, order: normalOrder, config: normalConfig } = this.normal
    const { hide: idleHide, order: idleOrder } = this.idle
    const normalShowComponent = normalOrder.filter((orderKey) => !normalHide.includes(orderKey))
    const editComponent = normalShowComponent.find((item) => item.startsWith('component') || item.startsWith('editable_bg'))
    if (editComponent) {
      this.editList.add('editableComponent')
    }
    if (normalShowComponent.includes('background') && normalConfig.getIn(['background', 'bg_type']) === 'editable_bg') {
      // for editable bg
      this.editList.add('editableBg')
    }
    const idleShowComponent = idleOrder.filter((orderKey) => !idleHide.includes(orderKey))

    this.generateJsonFile({
      previewEN: dialInfo.en?.preview,
      previewZH: dialInfo.zh?.preview,
      previewTC: dialInfo['zh-Hant']?.preview,
      previewDefault: (dialInfo.all || dialInfo.en || dialInfo.zh || dialInfo['zh-Hant'])?.preview,
      previewAll: dialInfo.all?.preview,
      appId: this.appId,
      appVersion: this.appVersion,
      appNameEN: dialInfo.en?.name,
      appNameZH: dialInfo.zh?.name,
      appNameTC: dialInfo['zh-Hant']?.name,
      appName: multiLanguage.getIn([0, 'name']),
      editable: this.editList.size ? 1 : 0,
      fromZoom,
      idleScreen: idleShowComponent.size > 0 ? 1 : 0,
      isHighCost
    })
  }

  afterBuild() {
    this.generateSensorCode()
    this.generateWidgetCodeFinal()
    // genergate js file
    const outputTemp = jsCodeMap.WATCHFACE_INDEX
    const outputJs = Mustache.render(outputTemp, {
      variableDeclaration: this.variableDeclaration,
      widgetCode: this.widgetCode,
      showOnPageShowWidget: this.onPageShowCode.length > 0,
      onPageShowCode: this.onPageShowCode
    })
    console.log(outputJs)
    convertIndexJs = outputJs
    convertImgMap = this.pathMapper

    this.init()
  }

  run() {
    this.beforeBuild()

    this.buildAppJson()

    this.build('normal')
    this.build('idle')

    this.afterBuild()
  }

  init() {
    this.namingSeed = 0

    this.normal = {
      hide: Immutable.List(),
      order: Immutable.List(),
      config: Immutable.Map()
    }

    this.idle = {
      hide: Immutable.List(),
      order: Immutable.List(),
      config: Immutable.Map()
    }

    this.variableDeclaration = []
    this.sensorCodeMap = {}
    this.widgetCode = []
    this.widgetCodeFinal = []
    this.onPageShowCode = []
  }

  mapImgUrl = (url, prefix = '', index = 0) => {
    if (!url) return ''
    if (this.pathMapper[url]) return this.pathMapper[url]
    if (prefix) {
      // just for animation module
      const imageName = `${prefix}_${index}.png`
      this.pathMapper[url] = imageName
      return this.pathMapper[url]
    }
    this.namingSeed += 1
    const imageName = `${this.namingSeed}.png`
    this.pathMapper[url] = imageName
    return this.pathMapper[url]
  }

  getFontImage(font_images = Immutable.List(), lang = 'all', checkLang = false) {
    if (checkLang && !this.isAll && !this.watchfaceLang.includes(lang)) return ''
    if (!Immutable.List.isList(font_images)) return ''

    const currentLangFontImage = font_images.find((item) => item.get('language') === lang) || Immutable.Map()
    const images = currentLangFontImage.get('images')

    if (!Immutable.List.isList(images) || images.size < 1) return ''

    return JSON.stringify(images.map((image) => this.mapImgUrl(image.get('url'))))
  }

  getUnitImage(unit_images = Immutable.List(), lang = 'all', checkLang = false) {
    if (checkLang && !this.isAll && !this.watchfaceLang.includes(lang)) return ''
    if (!Immutable.List.isList(unit_images)) return ''

    const currentLangUnitImages = unit_images.find((item) => item.get('language') === lang) || Immutable.Map()

    if (!Immutable.Map.isMap(currentLangUnitImages)) return ''

    return this.mapImgUrl(currentLangUnitImages.getIn(['image', 'url']))
  }

  getAnimeImage(images = Immutable.List(), prefix) {
    if (!Immutable.List.isList(images) || images.size < 1) return ''
    return JSON.stringify(images.map((image, index) => this.mapImgUrl(image, prefix, index)))
  }

  travelConfig({ moduleKey, moduleData, prefix: oldPrefix, followKey = '' }) {
    // 不要使用 modleKey 判断
    const isComponent = /component_\d+/.test(oldPrefix)
    const prefix = isComponent ? oldPrefix.replace(/(.*)(\$_\$component_\d+)(.*)/, '$1$3$2') : oldPrefix
    const dialType = getDialType(prefix)
    // const limit = isComponent ? 3 : 2

    // 限制层级
    // if (keyPath.length > limit) return
    if (!Immutable.Map.isMap(moduleData)) return

    switch (moduleKey) {
      // -- start of patentKey --
      case 'scale': {
        const { screen_resolution } = this.support
        const [width = 0, height = 0] = screen_resolution.split('*') || []
        const src = moduleData.get('image')

        this.generateWidgetCode('IMG', {
          variable: `${prefix}$_$scale`,
          x: 0,
          y: 0,
          width,
          height,
          src: this.mapImgUrl(src),
          showLevel: SHOW_LEVEL_MAP[dialType]
        })

        break
      }
      case 'idle_background':
      case 'background': {
        const { screen_resolution } = this.support
        const [width = 0, height = 0] = screen_resolution.split('*') || []

        const type = moduleData.get('bg_type') ? moduleData.get('bg_type', 'image') : moduleData.get('type', 'image')

        if (type === 'editable_bg') {
          const widgetName = 'WATCHFACE_EDIT_BG'
          const variable = `${prefix}$_$edit_bg`
          const {
            editable_bg: { count, bg_images, preview_images, fg_image, tip_image, tip_position: position }
          } = moduleData.toJS()
          const config = []

          const tips = {
            x: position.x,
            y: position.y,
            image: this.mapImgUrl(tip_image)
          }

          const bg = {
            x: 0,
            y: 0,
            count,
            fg: this.mapImgUrl(fg_image)
          }

          if (bg_images.length >= 1) {
            bg_images.forEach((image, index) => {
              const path = image ? this.mapImgUrl(image) : ''
              if (path) {
                const preview = preview_images[index] ? this.mapImgUrl(preview_images[index]) : path
                config.push({
                  id: index + 1,
                  path,
                  preview
                })
              }
            })
          }

          bg.count = config.length // count 以实际配置背景长度为准

          this.editId += 1
          this.generateWidgetCode(widgetName, {
            variable,
            editId: this.editId,
            tips,
            bg,
            config: JSON.stringify(config),
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        } else {
          const src = moduleData.get('image')
          const color = moduleData.get('color')
          const radius = moduleData.get('radius')

          this.generateWidgetCode(type === 'image' ? 'IMG' : 'FILL_RECT', {
            variable: `${prefix}$_$bg`,
            x: 0,
            y: 0,
            width,
            height,
            radius,
            src: type === 'image' ? this.mapImgUrl(src) : '',
            color: type === 'image' ? '' : color,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        }

        break
      }
      case 'analog_clock': {
        const data = []

        moduleData.forEach((subModuleData, subModuleKey) => {
          const center_position = subModuleData.get('center_position').toJS()
          const cover = subModuleData.get('cover').toJS()
          const pointer = subModuleData.get('pointer').toJS()

          if (cover.image) {
            cover.image = this.mapImgUrl(cover.image)
          }

          if (pointer.image) {
            pointer.image = this.mapImgUrl(pointer.image)
          }

          data.push({
            key: subModuleKey,
            center_position,
            cover,
            pointer
          })
        })

        this.generateWidgetCode('TIME_POINTER', {
          variable: `${prefix}$_$time_pointer`,
          data,
          showLevel: SHOW_LEVEL_MAP[dialType]
        })

        break
      }
      case 'lunar': {
        const { lunar_date, festival_or_solar_term } = moduleData.toJS()

        const getLunarData = (data) => {
          const {
            color,
            font_size,
            rect_align: { horizontal, vertical },
            rectangle: { width, height, position },
            display_type
          } = data

          return {
            color,
            font_size,
            horizontal,
            vertical,
            width,
            height,
            position,
            display_type
          }
        }

        let variable = ''
        let variableForFestival = ''

        if (festival_or_solar_term) {
          const { display_type } = festival_or_solar_term

          if (Number(display_type) === 1) {
            const { color, font_size, horizontal, vertical, width, height, position } = getLunarData(festival_or_solar_term)

            variableForFestival = `${prefix}$_$festival_or_solar_term`

            this.generateWidgetCode('TEXT', {
              variable: variableForFestival,
              x: position?.x || 0,
              y: position?.y || 0,
              width,
              height,
              color,
              textSize: font_size,
              alignH: ALIGN_MAP[horizontal],
              alignV: ALIGN_MAP[vertical],
              showLevel: SHOW_LEVEL_MAP[dialType]
            })
          }
        }

        if (lunar_date) {
          const { color, font_size, horizontal, vertical, width, height, position } = getLunarData(lunar_date)

          variable = `${prefix}$_$lunar_date`

          this.generateWidgetCode('TEXT', {
            variable,
            x: position?.x || 0,
            y: position?.y || 0,
            width,
            height,
            color,
            textSize: font_size,
            alignH: ALIGN_MAP[horizontal],
            alignV: ALIGN_MAP[vertical],
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        }

        const variableForJsTime = getSensorVariable('TIME')

        this.addToVariableDeclaration(variableForJsTime)
        this.addToVariableDeclaration('lunarText')
        this.addToVariableDeclaration('lunar_month')
        this.addToVariableDeclaration('lunar_day')
        this.addToVariableDeclaration('festival')

        this.generateJsCode('lunarShow', {
          variable,
          variableForFestival,
          variableForJsTime,
          showLunarWithFestival: !!festival_or_solar_term && !variableForFestival
        })

        this.addPageShowCode('getLunarText();')
        break
      }
      case 'date': {
        const imgData = []
        const fontData = []
        const separators = []

        moduleData.forEach((subModuleData, subModuleKey) => {
          const font_images = subModuleData.getIn(['text', 'image', 'font_images'])
          const unit_images = subModuleData.getIn(['text', 'image', 'unit_images'])
          const is_character = subModuleData.getIn(['text', 'is_character'], false)
          const isAll = !is_character || this.isAll

          const imageArrALL = this.getFontImage(font_images, 'all')
          const imageArrSC = this.getFontImage(font_images, 'zh', is_character)
          const imageArrTC = this.getFontImage(font_images, 'zh-Hant', is_character)
          const imageArrEN = this.getFontImage(font_images, 'en', is_character)
          const unitImageALL = this.getUnitImage(unit_images, 'all')
          const unitImageSC = this.getUnitImage(unit_images, 'zh')
          const unitImageTC = this.getUnitImage(unit_images, 'zh-Hant')
          const unitImageEN = this.getUnitImage(unit_images, 'en')
          const system_font = subModuleData.getIn(['text', 'image', 'system_font'])
          const separator = subModuleData.get('separator')
          const hasNoImageToShow = !(imageArrALL || imageArrSC || imageArrTC || imageArrEN)
          if (hasNoImageToShow) return

          let position = Immutable.Map()
          let align = 'left'

          if (subModuleData.hasIn(['text', 'image', 'rectangle'])) {
            position = subModuleData.getIn(['text', 'image', 'rectangle', 'position']) || Immutable.Map()
            align = subModuleData.getIn(['text', 'rect_align', 'horizontal'])
          } else {
            position = subModuleData.getIn(['text', 'image', 'position']) || Immutable.Map()
            align = subModuleData.getIn(['text', 'align'])
          }

          const data = {
            key: subModuleKey,
            position: position.toJS(),
            interval: subModuleData.getIn(['text', 'interval'], 0),
            align: ALIGN_MAP[align],
            padding_zero: parseBool2Number(subModuleData.getIn(['text', 'padding_zero'])),
            follow: parseCombin(subModuleData.get('combin')),
            unitSC: unitImageSC || unitImageALL,
            unitTC: unitImageTC || unitImageALL,
            unitEN: unitImageEN || unitImageALL,
            is_character,
            showFollow: subModuleKey !== 'year'
          }

          if (Immutable.Map.isMap(separator)) {
            separators.push({
              separator,
              variable: `${prefix}$_$${subModuleKey}$_$separator_img`
            })
          }

          if (system_font) {
            Object.assign(data, {
              system_font: system_font && system_font.toJS(),
              fontSize: system_font.get('font_size'),
              color: system_font.get('color')
            })
            fontData.push(data)
          } else {
            Object.assign(data, {
              imageArraySC: isAll ? imageArrALL : imageArrSC,
              imageArrayTC: isAll ? imageArrALL : imageArrTC,
              imageArrayEN: isAll ? imageArrALL : imageArrEN,
            })
            imgData.push(data)
          }
        })

        if (imgData.length) {
          this.generateWidgetCode('IMG_DATE', {
            variable: `${prefix}$_$img_date`,
            data: imgData,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        }

        if (fontData.length) {
          this.generateWidgetCode('TEXT_DATE', {
            variable: `${prefix}$_$text_date`,
            data: fontData,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        }

        if (separators.length) {
          separators.forEach(({ separator, variable }) => this.handleSeparator(separator, variable))
        }

        break
      }
      case 'week': {
        moduleData.forEach((subModuleData, subModuleKey) => {
          const newPrefix = `${prefix}$_$${subModuleKey}`

          if (subModuleKey === 'text') {
            const font_images = subModuleData.getIn(['text', 'image', 'font_images'])
            const separator = subModuleData.get('separator')
            const position = (subModuleData.getIn(['text', 'image', 'position']) || Immutable.Map()).toJS()
            const weekALL = this.getFontImage(font_images, 'all')
            const weekEN = this.getFontImage(font_images, 'en', true)
            const weekTC = this.getFontImage(font_images, 'zh-Hant', true)
            const weekSC = this.getFontImage(font_images, 'zh', true)

            this.generateWidgetCode('IMG_WEEK', {
              variable: `${prefix}$_$week`,
              position,
              weekArrayEN: this.isAll ? weekALL : weekEN,
              weekArrayTC: this.isAll ? weekALL : weekTC,
              weekArraySC: this.isAll ? weekALL : weekSC,
              showLevel: SHOW_LEVEL_MAP[dialType]
            })

            this.handleSeparator(separator, `${prefix}$_$separator_img`)

            return
          }

          this.travelConfig({
            moduleKey: subModuleKey,
            moduleData: subModuleData,
            prefix: newPrefix
          })
        })

        break
      }
      case 'digital_clock': {
        const imgTimeData = []
        const fontTimeData = []
        const separators = []
        const ampmData = []

        moduleData.forEach((subModuleData, subModuleKey) => {
          const subModuleKeys = ['hour', 'minute', 'second', 'am', 'pm']

          if (subModuleKeys.indexOf(subModuleKey) === -1) return

          const image = subModuleData.getIn(['text', 'image']) || Immutable.Map()
          const font_images = image.get('font_images')
          const system_font = image.get('system_font')
          const separator = subModuleData.get('separator')

          if (Immutable.Map.isMap(separator)) {
            separators.push({
              separator,
              variable: `${prefix}$_$${subModuleKey}$_$separator_img`
            })
          }

          if (subModuleKey === 'am' || subModuleKey === 'pm') {
            const position = subModuleData.get('position')
            const images = subModuleData.get('images')
            const imageSC = this.getUnitImage(images, 'zh')
            const imageEN = this.getUnitImage(images, 'en')

            ampmData.push({
              key: subModuleKey,
              position: position.toJS(),
              imageSC,
              imageEN
            })
          } else {
            const follow = parseCombin(subModuleData.get('combin'))
            const align = subModuleData.getIn(['text', 'align'], 'left')
            const padding_zero = parseBool2Number(subModuleData.getIn(['text', 'padding_zero']))
            const interval = subModuleData.getIn(['text', 'interval'], 0)
            const unit_images = image.get('unit_images')
            const position = (image.has('rectangle') ? image.getIn(['rectangle', 'position']) : image.get('position')) || Immutable.Map()
            const imageArray = this.getFontImage(font_images, 'all')
            const unitALL = this.getUnitImage(unit_images, 'all')
            const unitSC = this.getUnitImage(unit_images, 'zh')
            const unitTC = this.getUnitImage(unit_images, 'zh-Hant')
            const unitEN = this.getUnitImage(unit_images, 'en')

            if (font_images) {
              imgTimeData.push({
                key: subModuleKey,
                padding_zero,
                position: position.toJS(),
                imageArray,
                interval,
                unitSC: unitSC || unitALL,
                unitTC: unitTC || unitALL,
                unitEN: unitEN || unitALL,
                align: ALIGN_MAP[align],
                follow,
                addTimeVal: align === 'center',
                timeVal: `new Date().get${subModuleKey.replace(subModuleKey[0], subModuleKey[0].toUpperCase())}s()`,
                showFollow: subModuleKey !== 'hour'
              })
            } else if (system_font) {
              fontTimeData.push({
                key: subModuleKey,
                padding_zero,
                position: position.toJS(),
                interval,
                color: system_font.get('color'),
                fontSize: system_font.get('font_size'),
                unitSC: unitSC || unitALL,
                unitTC: unitTC || unitALL,
                unitEN: unitEN || unitALL,
                align: ALIGN_MAP[align],
                follow,
                addTimeVal: align === 'center',
                timeVal: `new Date().get${subModuleKey.replace(subModuleKey[0], subModuleKey[0].toUpperCase())}s()`,
                showFollow: subModuleKey !== 'hour'
              })
            }
          }
        })

        if (imgTimeData.length > 0) {
          this.generateWidgetCode('IMG_TIME', {
            variable: `${prefix}$_$img_time`,
            timeData: imgTimeData,
            ampmData,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        }

        if (fontTimeData.length > 0) {
          this.generateWidgetCode('TEXT_TIME', {
            variable: `${prefix}$_$text_time`,
            timeData: fontTimeData,
            ampmData: imgTimeData.length ? [] : ampmData,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
          this.generateWidgetCode('IMG_TIME', {
            variable: `${prefix}$_$img_time`,
            ampmData,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        }

        if (separators.length) {
          separators.forEach(({ separator, variable }) => this.handleSeparator(separator, variable))
        }

        break
      }
      case 'system': {
        moduleData.forEach((subModuleData, subModuleKey) => {
          this.travelConfig({
            moduleKey: subModuleKey,
            moduleData: subModuleData,
            prefix: `${prefix}$_$${subModuleKey}`
          })
        })
        break
      }
      case 'temperature': {
        // 当前仅温度高低温需要排序处理，若更多模块需要处理，跟随模块排序逻辑后续可抽离
        // ---- start order ----
        const lowCombin = moduleData.getIn(['low', 'combin'])
        const highCombin = moduleData.getIn(['high', 'combin'])
        let delKeys = []

        if (lowCombin === 'follow' || highCombin === 'follow') {
          delKeys = ['low', 'high'] // 若高低温跟随，先进行处理，后处理其他模块

          // 被跟随的 widget 在 JS 代码中先定义，依照此规则进行排序，被跟随的模块在前，先被处理
          const orderedCombinKeys = lowCombin === 'follow' ? ['high', 'low'] : ['low', 'high']

          orderedCombinKeys.forEach((subModuleKey, index) => {
            const subModuleData = moduleData.get(subModuleKey) || Immutable.Map()
            const newPrefix = `${prefix}$_$${subModuleKey}`
            const followKey = orderedCombinKeys[index - 1] || ''

            // 剩余逻辑交由子递归处理
            this.travelConfig({
              moduleKey: subModuleKey,
              moduleData: subModuleData,
              prefix: newPrefix,
              followKey
            })
          })
        }
        // ---- end order ----

        // 处理 delKeys 以外的其他模块
        moduleData.deleteAll(delKeys).forEach((subModuleData, subModuleKey) => {
          const newPrefix = `${prefix}$_$${subModuleKey}`

          this.travelConfig({
            moduleKey: subModuleKey,
            moduleData: subModuleData,
            prefix: newPrefix
          })
        })

        break
      }
      case 'component': {
        const props = moduleData.get('props')
        const rectangle = moduleData.get('rectangle') || Immutable.Map()
        const {
          position: { x: rectangleX = 0, y: rectangleY = 0 },
          width: rectangleW = 0,
          height: rectangleH = 0
        } = rectangle.toJS()
        const textDisplayWidth = moduleData.get('text_display_width') || 0
        const textMargin = moduleData.get('text_margin') || 0
        const selectList = moduleData.get('select_list')
        const selectImage = this.mapImgUrl(moduleData.get('select_image'))
        const unselectImage = this.mapImgUrl(moduleData.get('unselect_image'))
        const textTagImage = this.mapImgUrl(moduleData.getIn(['text_tag', 'image']))
        const textTagPosition = moduleData.getIn(['text_tag', 'position']) || Immutable.Map()
        const optionalTypes = []
        const editGroupVariable = `${prefix}$_$${moduleKey}`
        let modulesData = Immutable.Map()

        props.forEach((prop) => {
          const previews = prop.get('previews')
          // 仅留下模块数据
          const restData = prop.deleteAll(['bg', 'previews'])
          const componentModuleKey = restData.keySeq().get(0)
          const type = EDIT_TYPE_MAP[componentModuleKey]

          if (!type) return

          modulesData = modulesData.merge(restData)

          const previewALL = this.getUnitImage(previews, 'all')

          optionalTypes.push({
            type,
            preview: previewALL
          })
        })

        const relativeTagX = textTagPosition.get('x', 0) - rectangleX
        const relativeTagY = textTagPosition.get('y', 0) - rectangleY
        this.editId += 1
        this.generateWidgetCode('WATCHFACE_EDIT_GROUP', {
          variable: editGroupVariable,
          editId: this.editId,
          optionalTypes: JSON.stringify(optionalTypes).replace(/"(hmUI\.edit_type.\w+,?)"/g, '$1'),
          rectangle: {
            x: rectangleX,
            y: rectangleY,
            width: rectangleW,
            height: rectangleH
          },
          selectImage,
          unselectImage,
          selectList: Immutable.Map.isMap(selectList) ? {
            ...(selectList.toJS() || {}),
            title_align_h: ALIGN_MAP[selectList.get('title_align_h')],
            list_tips_text_align_h: ALIGN_MAP[selectList.get('list_tips_text_align_h')]
          } : undefined,
          defaultType: (optionalTypes[0] || {}).type || '',
          textTag: {
            x: relativeTagX,
            y: relativeTagY,
            image: textTagImage
          },
          textDisplayWidth,
          textMargin,
          count: optionalTypes.length || 0
        })

        const drawModule = (componentModuleKey) => {
          const tempWidgetCode = [...this.widgetCode]

          this.travelConfig({
            moduleKey: componentModuleKey,
            moduleData: modulesData.get(componentModuleKey),
            prefix: `${prefix}$_$${componentModuleKey}`
          })

          const diffWidgetCode = this.widgetCode.slice(tempWidgetCode.length)

          // 还原
          this.widgetCode = tempWidgetCode

          return diffWidgetCode.map((codeStr) => addIndentation(codeStr, 4))
        }

        const editTypeReverseMap = Object.keys(EDIT_TYPE_MAP).reduce((pre, curr) => {
          const editType = EDIT_TYPE_MAP[curr]
          const type = editType.split('.')[2]

          // eslint-disable-next-line no-param-reassign
          pre[type] = curr

          return pre
        }, {})

        const drawFuncs = Object.keys(editTypeReverseMap).map((editType) => ({
          editType,
          funcCodes: drawModule(editTypeReverseMap[editType])
        }))

        this.generateJsCode('drawEditWidget', {
          variable: 'editType',
          editGroupVariable,
          drawFuncs
        })

        break
      }
      // -- end of patentKey --
      // -- start of childKey --
      case 'low':
      case 'high':
      case 'current':
      case 'target':
      case 'weekly':
      case 'daily':
      case 'text': {
        const follow = moduleData.get('combin') === 'follow'
        const font_images = moduleData.getIn(['text', 'image', 'font_images'])
        const unit_images = moduleData.getIn(['text', 'image', 'unit_images'])
        const system_font = moduleData.getIn(['text', 'image', 'system_font'])
        const separator = moduleData.get('separator')
        const imperial_unit_images = moduleData.getIn(['text', 'image', 'imperial_unit_images'])
        const padding_zero = !!moduleData.getIn(['text', 'padding_zero'])
        const imageArray = this.getFontImage(font_images, 'all')
        let position = Immutable.Map()
        let align = 'left'
        let w = 0
        let h = 0
        if (moduleData.hasIn(['text', 'image', 'rectangle'])) {
          position = moduleData.getIn(['text', 'image', 'rectangle', 'position'])
          align = moduleData.getIn(['text', 'rect_align', 'horizontal'])
          w = moduleData.getIn(['text', 'image', 'rectangle', 'width'])
          h = moduleData.getIn(['text', 'image', 'rectangle', 'height'])
        } else {
          position = moduleData.getIn(['text', 'image', 'position'])
          align = moduleData.getIn(['text', 'align'])
        }
        const interval = moduleData.getIn(['text', 'interval'], 0)
        const is_character = moduleData.getIn(['text', 'is_character'], false)
        const keyPath = getKeyPath(prefix)
        const parentKey = keyPath[0]
        const invalidImage = this.mapImgUrl(moduleData.getIn(['text', 'image', 'invalid_image']))
        const negativeImage = this.mapImgUrl(moduleData.getIn(['text', 'image', 'negative_image', 'url']))
        const dotImage = this.mapImgUrl(moduleData.getIn(['text', 'image', 'point_image', 'url']))
        const unitALL = this.getUnitImage(unit_images, 'all')
        const unitSC = this.getUnitImage(unit_images, 'zh')
        const unitTC = this.getUnitImage(unit_images, 'zh-Hant')
        const unitEN = this.getUnitImage(unit_images, 'en')
        const imperialUnitALL = this.getUnitImage(imperial_unit_images, 'all')
        const imperialUnitSC = this.getUnitImage(imperial_unit_images, 'zh')
        const imperialUnitTC = this.getUnitImage(imperial_unit_images, 'zh-Hant')
        const imperialUnitEN = this.getUnitImage(imperial_unit_images, 'en')
        const type = DATA_TYPE_MAP[parentKey] || DATA_TYPE_MAP[keyPath.join('_')]
        const showPosition = !follow || parentKey === 'temperature'

        if (!type) {
          console.warn(`can not find ${parentKey} or ${keyPath.join('_')} in DATA_TYPE`)
          break
        }

        const widgetName = system_font ? 'TEXT_FONT' : 'TEXT_IMG'
        const variable = `${prefix}$_$${widgetName.toLocaleLowerCase()}`
        const data = {
          variable,
          x: position?.get('x', 0) || 0,
          y: position?.get('y', 0) || 0,
          type,
          interval,
          align: ALIGN_MAP[align],
          padding_zero,
          unitSC: unitSC || unitALL,
          unitTC: unitTC || unitALL,
          unitEN: unitEN || unitALL,
          imperialUnitSC: imperialUnitSC || imperialUnitALL,
          imperialUnitTC: imperialUnitTC || imperialUnitALL,
          imperialUnitEN: imperialUnitEN || imperialUnitALL,
          is_character,
          invalidImage,
          dotImage,
          negativeImage,
          showPosition,
          showLevel: SHOW_LEVEL_MAP[dialType]
        }

        if (w && h) {
          Object.assign(data, {
            w,
            h
          })
        }

        if (system_font) {
          Object.assign(data, {
            color: system_font.get('color'),
            fontSize: system_font.get('font_size')
          })
        } else {
          data.imageArray = imageArray
        }

        if (!imageArray) break

        this.generateWidgetCode(widgetName, data)
        this.handleSeparator(separator, `${prefix}$_$separator_img`)

        if (follow && followKey) {
          const moduleFollowing = variable
          const moduleBeFollowed = this.getVariable(`${parentKey}$_$${followKey}`)

          if (!(moduleFollowing && moduleBeFollowed)) break

          this.generateJsCode('layoutChange', {
            moduleFollowing,
            moduleBeFollowed
          })
        }

        break
      }
      case 'jumpable': {
        const { height, width, position, image } = moduleData.toJS()
        const widgetName = 'IMG_CLICK'
        const keyPath = getKeyPath(prefix)
        const parentKey = keyPath[0]
        let type = DATA_TYPE_MAP[`${parentKey}_current`] || DATA_TYPE_MAP[parentKey]

        if (parentKey === 'pai') {
          type = DATA_TYPE_MAP[`${parentKey}_weekly`]
        }

        if (!type) {
          console.warn(`type not find, path: ${keyPath}`)
          break
        }

        if (!isComponent) {
          this.widgetCodeFinal.push({name:widgetName, data:{
            variable: `${prefix}$_$${widgetName.toLocaleLowerCase()}`,
            width,
            height,
            position,
            image: this.mapImgUrl(image),
            type,
            showLevel: SHOW_LEVEL_MAP[dialType]
          }})
        } else {
          this.generateWidgetCode(widgetName, {
            variable: `${prefix}$_$${widgetName.toLocaleLowerCase()}`,
            width,
            height,
            position,
            image: this.mapImgUrl(image),
            type,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        }

        break
      }
      case 'clock':
      case 'disconnect':
      case 'dnd':
      case 'lock':
      case 'icon':
        // eslint-disable-next-line no-case-declarations
        const {
          image,
          position: { x = 0, y = 0 }
        } = moduleData.toJS()
        // eslint-disable-next-line no-case-declarations
        let widgetName = 'IMG'
        // eslint-disable-next-line no-case-declarations
        const keyPath = getKeyPath(prefix)
        // eslint-disable-next-line no-case-declarations
        const parentKey = keyPath[0]
        // eslint-disable-next-line no-case-declarations
        const data = {
          variable: `${prefix}$_$${widgetName.toLocaleLowerCase()}`,
          x,
          y,
          src: this.mapImgUrl(image),
          showLevel: SHOW_LEVEL_MAP[dialType]
        }

        if (parentKey === 'system') {
          const statusType = SYSTEM_STATUS_MAP[moduleKey]

          if (!statusType) {
            console.warn(`system status type not find, path: ${keyPath}`)

            break
          }

          widgetName = 'IMG_STATUS'

          data.type = statusType
        }

        this.generateWidgetCode(widgetName, data)

        break
      case 'image_progress': {
        const isContinuance = moduleData.get('is_continuance')
        const images = moduleData.get('images') || Immutable.List()
        const positions = moduleData.get('positions') || Immutable.List()
        const widgetName = isContinuance ? 'IMG_PROGRESS' : 'IMG_LEVEL'
        const keyPath = getKeyPath(prefix)
        const parentKey = keyPath[0]
        let type = DATA_TYPE_MAP[`${parentKey}_current`] || DATA_TYPE_MAP[parentKey]
        if (parentKey === 'pai') {
          type = DATA_TYPE_MAP[`${parentKey}_weekly`]
        }
        if (!type) {
          console.warn(`type not find, path: ${keyPath}`)
          break
        }

        this.generateWidgetCode(widgetName, {
          variable: `${prefix}$_$${widgetName.toLocaleLowerCase()}`,
          x: isContinuance ? JSON.stringify(positions.map((item) => item.get('x'))) : positions.getIn([0, 'x']) || 0,
          y: isContinuance ? JSON.stringify(positions.map((item) => item.get('y'))) : positions.getIn([0, 'y']) || 0,
          imgArray: JSON.stringify(images.map((image) => this.mapImgUrl(image))),
          imgLength: images.size,
          type,
          showLevel: SHOW_LEVEL_MAP[dialType]
        })

        break
      }
      case 'progress_clock':
        // 依赖固件端 JS 协议，目前暂未支持
        break
      case 'progress': {
        const keyPath = getKeyPath(prefix)
        const parentKey = keyPath[0]
        const { circle, progress_render_type, progress_color, width, bg = '' } = moduleData.toJS()
        const { position = {}, angle = {}, radius } = circle
        const isColorRenderType = progress_render_type === 'color'
        const widgetName = isColorRenderType ? 'ARC_PROGRESS' : ''

        let type = DATA_TYPE_MAP[`${parentKey}_current`] || DATA_TYPE_MAP[parentKey]

        if (parentKey === 'pai') {
          type = DATA_TYPE_MAP[`${parentKey}_weekly`]
        }

        if (!type) {
          console.log(`type not find, path: ${keyPath}`)
          break
        }

        if (!isColorRenderType) {
          // 依赖固件端 JS 协议，目前暂未支持
          break
        }

        this.generateWidgetCode(widgetName, {
          variable: `${prefix}$_$${widgetName.toLocaleLowerCase()}`,
          centerX: position.x,
          centerY: position.y,
          radius,
          startAngle: angle.start,
          endAngle: angle.end,
          color: progress_color,
          lineWidth: width,
          bg: this.mapImgUrl(bg),
          type,
          showLevel: SHOW_LEVEL_MAP[dialType]
        })

        break
      }
      case 'pointer_progress': {
        const { angle, center_position, cover = {}, pointer = {}, scale = {} } = moduleData.toJS()
        const keyPath = getKeyPath(prefix)
        const parentKey = keyPath[0]
        const { position } = scale
        const scaleImages = moduleData.getIn(['scale', 'images'])
        const scaleALL = this.getUnitImage(scaleImages, 'all')
        const scaleSC = this.getUnitImage(scaleImages, 'zh')
        const scaleTC = this.getUnitImage(scaleImages, 'zh-Hant')
        const scaleEN = this.getUnitImage(scaleImages, 'en')
        const showScale = scaleALL || scaleSC || scaleTC || scaleEN
        const dateParent = ['month', 'day', 'week']
        const isDatePointer = dateParent.indexOf(parentKey) > -1
        const widgetName = isDatePointer ? 'DATE_POINTER' : 'IMG_POINTER'
        let type = isDatePointer ? DATE_TYPE_MAP[parentKey] : DATA_TYPE_MAP[`${parentKey}_current`] || DATA_TYPE_MAP[parentKey]

        if (parentKey === 'pai') {
          type = DATA_TYPE_MAP[`${parentKey}_weekly`]
        }

        if (!type) {
          console.warn(`type not find, path: ${keyPath}`)
          break
        }

        if (cover.image) {
          cover.image = this.mapImgUrl(cover.image)
        }

        if (pointer.image) {
          pointer.image = this.mapImgUrl(pointer.image)
        }

        this.generateWidgetCode(widgetName, {
          variable: `${prefix}$_$${widgetName.toLocaleLowerCase()}`,
          angle,
          center_position,
          cover,
          pointer,
          type,
          scale: {
            showScale,
            position,
            scaleSC: this.isAll ? scaleALL : scaleSC,
            scaleTC: this.isAll ? scaleALL : scaleTC,
            scaleEN: this.isAll ? scaleALL : scaleEN
          },
          showLevel: SHOW_LEVEL_MAP[dialType]
        })

        break
      }
      // for animation module
      case 'animation': {
        moduleData.forEach((subModuleData, subModuleKey) => {
          const newPrefix = `${prefix}$_$${subModuleKey}`
          const position = subModuleData.get('position').toJS()
          // default frame interval is 1000ms
          const frameInterval = subModuleData.get('frame_interval') || 1000
          const fps = Math.floor((1 / frameInterval) * 1000)
          const size = subModuleData.get('images').size || 0
          const count = Number(subModuleData.get('play_times', 0))
          // default animation image prefix
          const randomKey = Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5)
          const imgPrefix = `${subModuleKey}_anim_${randomKey}`
          this.getAnimeImage(subModuleData.get('images'), imgPrefix)
          this.generateWidgetCode('IMG_ANIM', {
            variable: newPrefix,
            position,
            prefix: imgPrefix,
            ext: 'png',
            fps,
            size,
            count: count === 255 ? 0 : count,
            replayOnRestart: count === 1,
            status: ANIM_STATUS_MAP.start,
            showLevel: SHOW_LEVEL_MAP[dialType]
          })
        })
        break
      }
      // for editable background
      case 'editable_bg': {
        break
      }
      case 'config_os_text': {
        const { screen_resolution } = this.support
        const [screenWidth = 480, screenHeight = 480] = screen_resolution.split('*') || []
        const { categories } = moduleData.toJS()
        const { placement, dimension, alignment, text, color, textAppearance } = categories
        const {
          x: { value: x },
          y: { value: y }
        } = placement.properties
        const {
          width: { value: width },
          height: { value: height }
        } = dimension.properties
        // 兼容无 alignment 属性的旧数据
        const alignH = alignment?.properties?.alignH?.value || ALIGN_MAP.left
        const alignV = alignment?.properties?.alignV?.value || ALIGN_MAP.top
        const {
          text: { value: experssion }
        } = text.properties
        const {
          color: { value: newColor }
        } = color.properties
        const {
          textSize: { value: textSize }
        } = textAppearance.properties
        const textWidgetVariable = prefix.replace(/-/g, '')

        this.generateWidgetCode('TEXT', {
          variable: textWidgetVariable,
          x: x || 0,
          y: y || 0,
          width: alignment ? width : screenWidth,
          height: alignment ? height : screenHeight,
          color: newColor,
          textSize,
          textStyle: 'hmUI.text_style.NONE',
          text: experssion,
          alignH,
          alignV,
          showLevel: SHOW_LEVEL_MAP[dialType]
        })

        const tags = experssion.match(/\[.*?\]/g)

        const getCode = (experssion) => {
          return `
            ${textWidgetVariable}.setProperty(hmUI.prop.MORE, { text: \`${experssion.replace(/\[.*?\]/g, (tag) => {
            if (isValidTag(tag)) {
              const {dataField, sensorId, leadingZero, valueCount, formatter} = SENSOR_TAG_MAP[tag] || {}
              if (!sensorId) {
                console.error(`SENSOR_TAG_MAP lack ${tag} config`)
                return
              }

              const variable = getSensorVariable(sensorId)
              const replaceValue = leadingZero ? `String(${variable}.${dataField}).padStart(${valueCount}, '0')` : `${variable}.${dataField}`
              if (formatter) {
                this.addPageShowCode(`const ${tag.slice(1, tag.length - 1)} = ${formatter}`)
                return `$\{${tag.slice(1, tag.length - 1)}(${`${variable}.${dataField}`})}`
              }

              return `$\{${replaceValue}}`
            }
            return tag
          })}\` });
          `
        }
        let code = getCode(experssion)
        if (tagsDateCount(tags) > 0) {
          const genCodeFunc = (format) => {
            return  getCode(experssion.replace(/\[.*?\]/g, (tag) => {
              return getDateReplace(tags, format)[tag] || tag
            }))
          }
          this.addToVariableDeclaration('dateFormat')
          this.addToVariableDeclaration('dateFormatMap')
          code = `
            dateFormat = hmSetting.getDateFormat();
            dateFormatMap = [() => {${genCodeFunc(0)}}, () => {${genCodeFunc(1)}}, () => {${genCodeFunc(2)}}]
            dateFormatMap[dateFormat]()
          `
        }
        this.addPageShowCode(code)
        tags?.forEach(tag => {
          if (!isValidTag(tag)) return

          const { sensorId, eventName, formatter } = SENSOR_TAG_MAP[tag] || {}

          if (!sensorId) {
            console.error(`SENSOR_TAG_MAP lack ${tag} config`)
            return
          }
          if (formatter) {
            this.addSensorHandleCode(sensorId, eventName, `const ${tag.slice(1, tag.length - 1)} = ${formatter}`)
          }
          this.addSensorHandleCode(sensorId, eventName, code)
        })
        break
      }
      case 'config_os_image': {
        const { categories } = moduleData.toJS()
        const { placement, image } = categories
        const {
          x: { value: x },
          y: { value: y },
          image: { value: imageArr }
        } = { ...placement.properties, ...image.properties }

        let url = imageArr?.[0]?.value || ''

        if (!(typeof url === 'string')) {
          url = url.image
        }
        if (!url) return

        this.generateWidgetCode('IMG', {
          variable: prefix.replace(/-/g, ''),
          x: x || 0,
          y: y || 0,
          src: this.mapImgUrl(url),
          showLevel: SHOW_LEVEL_MAP[dialType]
        })

        break
      }
      // -- end of childKey --
      default: {
        const childKey = moduleData.reduce((pre, subModuleData, subModuleKey) => {
          pre.push(subModuleKey)
          return pre
        }, [])
        const orderedChildKey = childKey.sort((key1, key2) => (DATA_CHILD_KEY_ORDER_MAP[key1] || 0) - (DATA_CHILD_KEY_ORDER_MAP[key2] || 0))

        orderedChildKey.forEach((subModuleKey) => {
          const subModuleData = moduleData.get(subModuleKey)

          const newPrefix = `${prefix}$_$${subModuleKey}`

          this.travelConfig({
            moduleKey: subModuleKey,
            moduleData: subModuleData,
            prefix: newPrefix
          })
        })

        break
      }
    }
  }

  generateWidgetCodeFinal = () => {
    this.widgetCodeFinal.forEach((widgetObj) => {
      this.generateWidgetCode(widgetObj.name, widgetObj.data)
    })
  }

  handleSeparator = (separator, variable) => {
    if (!Immutable.Map.isMap(separator)) return

    const dialType = getDialType(variable)
    const { image = {}, position = {} } = separator.toJS()
    const { x = 0, y = 0 } = position
    const { url = '', width = 0, height = 0 } = image

    if (!url) return

    this.generateWidgetCode('IMG', {
      variable,
      x,
      y,
      width,
      height,
      src: this.mapImgUrl(url),
      showLevel: SHOW_LEVEL_MAP[dialType]
    })
  }

  getVariable(uniqKey) {
    const variableDeclarationStr = this.variableDeclaration.find((item) => item.indexOf(uniqKey) > -1) || ''
    return variableDeclarationStr.replace(/let\s+(.*)\s+=.*/, '$1')
  }

  generateWidgetCode(widgetName, widgetData) {
    try {
      const widget = addIndentation(widgetMap[widgetName], 8)
      const { variable } = widgetData
      this.addToVariableDeclaration(variable)
      this.widgetCode.push(Mustache.render(widget, widgetData))
    } catch (error) {
      console.error('generateWidgetCode', error)
    }
  }

  generateJsCode(fileName, data) {
    try {
      const codeTemp = addIndentation(jsCodeMap[fileName])
      const code = Mustache.render(codeTemp, data)
      this.widgetCode.push(code)
    } catch (error) {
      console.error('generateJsCode', error)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  generateJsonFile(data) {
    const {
      appId,
      appVersion,
      appName,
      appNameEN,
      appNameZH,
      appNameTC,
      previewEN,
      previewZH,
      previewTC,
      previewDefault,
      previewAll,
      editable,
      idleScreen,
      isHighCost,
      fromZoom = false,
    } = data

    const { name, screen_resolution } = this.support
    const targetNameList = name.split(',').map((item) => item.toLocaleLowerCase().replace(/\s+|\*/g, '-'))
    const deviceSource = this.deviceInfo.map((item) => {
      const { productName: name, deviceSource } = item
      return { name, deviceSource }
    })
    const targetName = targetNameList[0] ?? ''
    const screenWidth = screen_resolution.split('*')[0] || ''

    const [h, t, o] = appVersion.split('.')
    const appCode = Number(h.padStart(2, '0') + t.padStart(2, '0') + o.padStart(2, '0')) - 9999 // 保持 '1.0.0' 对应 1，'1.0.1' 对应 2

    const jsonTemp = removeIndentation(appJson, 4)
    const jsonStr = Mustache.render(jsonTemp, {
      appId,
      appVersion,
      appCode,
      appName,
      appNameEN: appNameEN || appName,
      appNameZH: appNameZH || appName,
      appNameTC: appNameTC || appName,
      previewEN: previewEN || previewAll,
      previewZH: previewZH || previewAll,
      previewTC: previewTC || previewAll,
      previewDefault: previewDefault || previewEN || previewZH || previewTC,
      editable,
      idleScreen,
      fromZoom,
      targetName,
      screenWidth,
      isHighCost,
      deviceSource: JSON.stringify(deviceSource)
    })

    convertTargetName = targetName
    convertAppJson = jsonStr
    convertAppName = appName
  }

  addToVariableDeclaration(variable) {
    if (!variable) return

    const declaration = `let ${variable} = ''`

    if (this.variableDeclaration.includes(declaration)) {
      return
    }

    this.variableDeclaration.push(declaration)
  }

  addSensorHandleCode(sensorId, eventName, code) {
    const uniqKey = eventName ? `${sensorId}-${eventName}` : sensorId
    if (!sensorId || !code) return
    if (!this.sensorCodeMap[uniqKey]) {
      this.sensorCodeMap[uniqKey] = []
    }
    if (this.sensorCodeMap[uniqKey].indexOf(code) > -1) return
    this.sensorCodeMap[uniqKey].push(code)
  }

  addPageShowCode = (code) => {
    if (!code || this.onPageShowCode.includes(code)) return

    this.onPageShowCode.push(code)
  }

  generateSensorCode() {
    const uniqKeys = Object.keys(this.sensorCodeMap)
    const sensorVariables = uniqKeys.reduce((pre, uniqKey) => {
      const [sensorId] = uniqKey.split('-')
      const sensorVariable = getSensorVariable(sensorId)
      this.addToVariableDeclaration(sensorVariable)

      if (!pre.find(item => item?.sensorId === sensorId)) {
        pre.push({
          sensorId,
          sensorVariable,
        })
      }

      return pre
    }, [])

    const createSensorCode = Mustache.render(createSensorTemp, { sensorVariables })
    const sensorHandleCode = []
    const timerHandleCode = []

    uniqKeys.forEach(uniqKey => {
      const [sensorId, eventName] = uniqKey.split('-')
      const code = this.sensorCodeMap[uniqKey]
      const sensorVariable = getSensorVariable(sensorId)
      const sensorTag = Object.keys(SENSOR_TAG_MAP).find(key => SENSOR_TAG_MAP[key]?.sensorId === sensorId)
      const { isCommonEventSensor } = SENSOR_TAG_MAP[sensorTag] || {}

      const sensorCode = eventName && eventName !== 'SECONDCHANGE' ? Mustache.render(sensorTemp, {
        eventType: isCommonEventSensor ? 'hmSensor' : sensorVariable,
        eventName,
        sensorVariable,
        sensorHandle: code
      }) : ''

      let timerCode = ''
      if (eventName === 'SECONDCHANGE') {
        timerCode = Mustache.render(timerTemp, {
          eventName,
          option: sensorVariable,
          timerHandle: code
        })
      }

      sensorCode && sensorHandleCode.push(sensorCode)
      timerCode && timerHandleCode.push(timerCode)
    })

    this.widgetCode.push(createSensorCode)
    this.widgetCode.push(sensorHandleCode)
    this.widgetCode.push(timerHandleCode)
  }
}

const generateFolderData = (config, support, appId, appVersion, deviceInfo) => {
  new BuildProcess(config, support, appId, appVersion, deviceInfo).run()
  return {
    convertAppJson,
    convertAppName,
    convertImgMap,
    convertIndexJs,
    convertTargetName
  }
}

export default generateFolderData
