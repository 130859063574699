import ANIMATION from '@watchface/pages/DesignTool/NonZeppOs/components/FrameAnimationComponent/config'
import ICON from '@watchface/pages/DesignTool/NonZeppOs/components/IconComponent/config'
import JUMPABLE from '@watchface/pages/DesignTool/NonZeppOs/components/JumpHotAreaComponent/config'
import PIC from '@watchface/pages/DesignTool/NonZeppOs/components/PicComponent/config'
import AMPM from '@watchface/pages/DesignTool/NonZeppOs/components/AmPm/config'
import ANALOG_CLOCK_ITEM from '@watchface/pages/DesignTool/NonZeppOs/components/AnalogClockComponent/config'
import createBackgroundData from '@watchface/pages/DesignTool/NonZeppOs/components/BackgroundComponent/config'
import createNumberData from '@watchface/pages/DesignTool/NonZeppOs/components/NumberComponent/config'
import IMAGE_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PictureProgress/config'
import POINTER_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PointerProgressComponent/config'
import Immutable from 'immutable'


// 指针时间
const ANALOG_CLOCK = {
  children: {
    hour: ANALOG_CLOCK_ITEM,
    minute: ANALOG_CLOCK_ITEM,
    second: ANALOG_CLOCK_ITEM
  }
}

const DIGITAL_CLOCK = {
  children: {
    hour: createNumberData({ hasReact: true, isPaddingZero: true }),
    minute: createNumberData({ hasReact: true, isPaddingZero: true }),
    second: createNumberData({ hasReact: true, isPaddingZero: true }),
    am: AMPM,
    pm: AMPM
  },
  follow_time: false,
  align: 'left'
}

const ALARM_CLOCK = {
  children: {
    hour: createNumberData({ hasReact: true, isPaddingZero: true }),
    minute: createNumberData({ hasReact: true, isPaddingZero: true }),
    am: AMPM,
    pm: AMPM,
    icon: ICON,
    no_icon: ICON,
    jumpable: JUMPABLE
  },
  follow_time: false,
  align: 'left'
}

const PAI = {
  children: {
    weekly: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const AQI = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const HUMIDITY = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const UVI = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const BATTERY = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const HEART_RATE = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const CALORIE = {
  children: {
    current: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const WEATHER = {
  children: {
    image_progress: IMAGE_PROGRESS
  }
}

const TEMERATURE = {
  children: {
    current: createNumberData({ hasReact: true }),
    high: createNumberData({ hasReact: true }),
    low: createNumberData({ hasReact: true }),
    jumpable: JUMPABLE
  }
}

const SUNRISE = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const SUNSET = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const SYSTEM = {
  children: {
    dnd: ICON,
    disconnect: ICON,
    lock: ICON,
    clock: ICON
  }
}

const DATE = {
  children: {
    year: createNumberData({ hasReact: true }),
    month: createNumberData({ hasReact: true, isPaddingZero: true }),
    day: createNumberData({ hasReact: true, isPaddingZero: true })
  }
}

const STEP = {
  children: {
    current: createNumberData({ hasReact: true }),
    pointer_progress: POINTER_PROGRESS,
    target: createNumberData({ hasReact: true }),
    icon: ICON,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const DISTANCE = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    jumpable: JUMPABLE
  }
}

const WEEK = {
  children: {
    text: PIC,
    pointer_progress: POINTER_PROGRESS
  }
}

const STAND = {
  children: {
    text: createNumberData({ hasReact: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const ANIMATIONDATA = {
  children: {
    first: ANIMATION,
    second: ANIMATION,
    third: ANIMATION
  }
}

const SHORTCUT = {
  children: {
    music: JUMPABLE,
    woman_health: JUMPABLE,
    count_down: JUMPABLE,
    stop_watch: JUMPABLE,
    pomodoro: JUMPABLE,
    sport: JUMPABLE,
    voice: JUMPABLE
  }
}

const LAYER_CONFIG = Immutable.fromJS({
  background: createBackgroundData({ isIdle: false, canSetPosition: true, hasFloat: true }),
  analog_clock: ANALOG_CLOCK,
  digital_clock: DIGITAL_CLOCK,
  alarm_clock: ALARM_CLOCK,
  pai: PAI,
  aqi: AQI,
  humidity: HUMIDITY,
  uvi: UVI,
  battery: BATTERY,
  heart_rate: HEART_RATE,
  calorie: CALORIE,
  weather: WEATHER,
  temperature: TEMERATURE,
  sunrise: SUNRISE,
  sunset: SUNSET,
  system: SYSTEM,
  date: DATE,
  step: STEP,
  distance: DISTANCE,
  week: WEEK,
  animation: ANIMATIONDATA,
  stand: STAND,
  shortcut: SHORTCUT
})

export default LAYER_CONFIG
