import React, { useRef, useState } from 'react'
import { Modal, Input, Button } from 'antd'
import { TAGS } from '@watchface/constants'
import { getCursorPosition } from '@watchface/utils'

import './index.scss'

interface IProps {
  visible: boolean
  textValue: string
  onClose: () => void
  onChange: (...args: any) => void
}

const TagModal: React.FC<IProps> = (props) => {
  const { textValue, visible, onClose, onChange } = props
  const inputEle = useRef(null)
  const [tagInputVal, setTagInputVal] = useState(textValue)
  const handleTextChange = (textValue: string) => {
    setTagInputVal(textValue)
    onChange('text', 'text', textValue)
  }
  const handleExpressionClick = (tag: string) => {
    const cursorPos = getCursorPosition((inputEle?.current as any)?.input)
    const expression = cursorPos === -1 ? `${tagInputVal}${tag}` : `${tagInputVal.slice(0, cursorPos)}${tag}${tagInputVal.slice(cursorPos)}`
    setTagInputVal(expression)
  }

  return <Modal
    className="tag-select-modal"
    visible={visible}
    closable={false}
    footer={null}
    centered
  >
    <div className="text-tag-input">
      <Input ref={inputEle} value={tagInputVal} onChange={(e) => setTagInputVal(e?.target?.value)} />
      <Button onClick={() => {
        handleTextChange(tagInputVal)
        onClose()
      }}>OK</Button>
    </div>
    <ul className="text-tag-list">
      <li className="text-tag-list-header">
        <span>[Tag]</span>
        <span>Description</span>
        <span>Example</span>
      </li>
      {
        TAGS.map((tagItem: any) => (
          <li
            key={tagItem.key}
            className="text-tag-list-item"
          >
            <span className="tag-experssion" onClick={() => handleExpressionClick(tagItem.experssion)}>{tagItem.experssion}</span>
            <span>{tagItem.description}</span>
            <span>{tagItem.example}</span>
          </li>
        ))
      }
    </ul>
  </Modal>
}

export default TagModal
