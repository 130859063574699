import { Col, Divider, Row, Select } from 'antd'
import * as React from 'react'

const { Option } = Select

interface Props {
  properties: {
    bgType: { value: string }
  }
  onChange: (category: string, property: string, value: any) => void
}

const BackgroundType: React.FC<Props> = (props) => {
  const {
    properties: {
      bgType: { value: bgType }
    },
    onChange
  } = props
  return (
    <div className="meta-form meta-backgroundType">
      <Row justify="space-around" align="middle">
        <Col span={6}>背景类型</Col>
        <Col span={18}>
          <Select
            defaultValue={bgType}
            style={{ width: 120 }}
            onChange={(value) => {
              onChange('backgroundType', 'bgType', value)
            }}
          >
            <Option value="imageBackground">图片背景</Option>
            <Option value="solidBackground">纯色背景</Option>
            <Option value="editBackground">可编辑背景</Option>
          </Select>
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default BackgroundType
