/**
 * text widget data
 */
const IMG = {
  name: 'Image',
  type: 'image',
  visible: true,
  visibleOnAod: false,
  canClick: true,
  categories: {
    name: {
      type: 'name',
      display: 'Name',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        name: {
          value: 'Image'
        }
      }
    },
    image: {
      type: 'image',
      display: 'Image',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        image: {
          value: [
            {
              lang: 'all',
              value: ''
            }
          ]
        }
      }
    },
    placement: {
      type: 'placement',
      display: 'Placement',
      isExpanded: true,
      enabled: true,
      visibility: true,
      properties: {
        x: { value: 0 },
        y: { value: 0 }
      }
    }
  }
}

const orderedCategoriesKey = Object.keys(IMG.categories)

/**
 * 处理单个图片
 * @param {object} itemImage 图片对象
 * @param {boolean} needI18n 是否支持多语言配置
 * @returns
 */
const getImage = (itemImage, needI18n = false) => {
  let en_value = ''
  let sc_value = ''
  let tc_value = ''
  let all_value = ''

  if (!itemImage) {
    if (needI18n) {
      return { en_value, sc_value, tc_value }
    }
    return all_value
  }

  const {
    image: { value: imgList }
  } = itemImage.properties

  imgList.forEach((item) => {
    const { lang, value } = item
    if (lang === 'en') {
      en_value = value
    } else if (lang === 'zh') {
      sc_value = value
    } else if (lang === 'zh-Hant') {
      tc_value = value
    } else if (lang === 'all') {
      all_value = value
    }
  })
  if (needI18n) {
    return { en_value, sc_value, tc_value }
  }
  return all_value
}

/**
 * get fabric object from widget data
 * @param {object} item widget data
 * @returns
 */
const convertJson2FabricImage = (item) => {
  const { categories } = item
  const { placement, image } = categories
  const {
    x: { value: left },
    y: { value: top }
  } = placement.properties

  const imageSrc = getImage(image, false)

  const src = typeof imageSrc === 'string' ? imageSrc : imageSrc.image

  return { left, top, texture: src, selectable: true }
}

export { IMG as default, convertJson2FabricImage, orderedCategoriesKey }
