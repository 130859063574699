import { APP_NAME_EXIST, SERIES, ZOOM_STATUS_FAILED, ZOOM_STATUS_SUCCESS } from '@watchface/constants'
import * as api from '@watchface/request/api'
import { CHANGE_ASSET, EXPORT_FAILED, EXPORT_START, EXPORT_STOP, EXPORT_SUCCESS, SAVE_DESIGNER, ZOOM_FAILED, ZOOM_START, ZOOM_STOP, ZOOM_SUCCESS } from '@watchface/store/actionTypes'
import { message } from 'antd'
import Immutable from 'immutable'
import { call, delay, put, race, select, take, all, fork } from 'redux-saga/effects'

function* pollSagaForExport(taskId: number, data: any) {
  const { pollInterval, resolve, reject } = data
  while (true) {
    try {
      const result: any = yield call(api.getTaskDetail, taskId)

      if (result?.error?.name === '400') {
        yield put({
          type: EXPORT_STOP,
          data: result?.error?.responseMsg
        })
      }

      if (result.status === 'finished') {
        yield put({
          type: EXPORT_SUCCESS,
          data: result
        })
        resolve(result)
        return
      }
      if (result.status === 'failed') {
        yield put({
          type: EXPORT_FAILED,
          data: result
        })
        reject(result)
        return
      }

      yield delay(pollInterval)
    } catch (err) {
      yield put({
        type: EXPORT_STOP,
        data: err
      })
      reject(err)
    }
  }
}

function* watchExport() {
  while (true) {
    const { data } = yield take(EXPORT_START)
    const { dialType } = data

    // 将缩放和导出组件拆开后不需要再终止
    yield put({
      type: ZOOM_STOP,
    })

    if (dialType !== 'js') {
      yield put({
        type: SAVE_DESIGNER,
        data: {}
      })
    }

    try {
      const { error, id, appid  } = yield call(api.createExportTask, data.dialId, data.dialType, data.no_simulator, data.app_name)
      if (error?.name === '400' && error?.responseMsg === 'app name exist') {
        yield put({
          type: EXPORT_FAILED,
          data: APP_NAME_EXIST
        })
        data?.reject({ message: APP_NAME_EXIST })
        yield put({
          type: EXPORT_STOP,
          data: APP_NAME_EXIST
        })
      } else {
        if (appid) {
          yield put({
            type: CHANGE_ASSET,
            data: {
              key: 'appId',
              value: appid,
            }
          })
        }
        yield race([
          call(pollSagaForExport, id, data),
          take(EXPORT_STOP)
        ])
      }
    } catch (e) {
      const state = yield select()
      const i18n = state.getIn(['watchFace', 'i18n', 'locale'], Immutable.Map())
      message.error(i18n.get('export_api_fail'))
      console.log(e)
    }
  }
}

function* pollSagaForZoom(code: string) {
  while(true) {
    try {
      const result = yield call(api.getZoomResultRequest, code)
      const { status, message, error, list } = result

      if (error?.name === '400') {
        yield put({
          type: ZOOM_FAILED,
          data: error?.responseMsg
        })
        yield put({
          type: ZOOM_STOP,
        })
      }
      if (status === ZOOM_STATUS_SUCCESS) {
        if (Array.isArray(list)) {
          yield all(list.map((item: any) => {
            const { config = {}, support = {} } = item || {}
            const isZeppOS = support.series === SERIES.JS

            if (config.assets) {
              config.assets.fromZoom = true
            }

            // 去除缩略图，临时解决缩放后的缩略图因缩放比例精度造成的尺寸不准确问题
            try {
              const multi_language = config.metas.multi_language.map((item: any) => {
                return {
                  ...item,
                  image: ''
                }
              })

              config.metas.multi_language = multi_language
            } catch(e) {
              console.log(e)
            }

            return call(api.createUserDialApi, support.id, config, isZeppOS)
          }))
        }
        // 缩放成功
        yield put({
          type: ZOOM_SUCCESS,
        })
        yield put({
          type: ZOOM_STOP,
          data: message
        })
      }

      if (status === ZOOM_STATUS_FAILED) {
        // 缩放失败
        yield put({
          type: ZOOM_FAILED,
          data: message
        })
        yield put({
          type: ZOOM_STOP,
          data: message
        })
      }

      yield delay(1000) // 1s 轮询一次
    } catch (err: any) {
      yield put({
        type: ZOOM_FAILED,
        data: err?.message,
      })
      yield put({
        type: ZOOM_STOP,
      })
    }
  }
}

function* watchZoom() {
  while (true) {
    const { data } = yield take(ZOOM_START)
    // 将缩放和导出组件拆开后不需要再终止
    yield put({
      type: EXPORT_STOP,
    })
    // yield put({
    //   type: SAVE_DESIGNER,
    //   data: {}
    // })

    try {
      const { error, code } = yield call(api.zoomDesignerRequest, data.dialId, data.supportIds)
      if (error?.name === '400') {
        yield put({
          type: ZOOM_STOP,
        })
        yield put({
          type: EXPORT_FAILED,
          data: error?.responseMsg
        })
      } else {
        yield race([
          call(pollSagaForZoom, code),
          take(ZOOM_STOP)
        ])
      }
    } catch (e: any) {
      yield put({
        type: ZOOM_STOP,
      })
      yield put({
        type: ZOOM_FAILED,
        data: e?.message
      })
    }
  }
}


export default function* pollSagaWatch() {
  yield all([fork(watchExport), fork(watchZoom)])
}
