import { getAuthInfo } from '@watchface/request/api'
import apiHost from '@watchface/request/domains/common'
import { handleBrowserLang } from '@watchface/utils'
import { Modal, Spin } from 'antd'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import './index.scss'

interface ServerResponse {
  examineType: number
  userId: string
}

const Unauthorized = (props: { login: any; isChinaZone: boolean | undefined }): JSX.Element => {
  const { login, isChinaZone } = props
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)
  const routePath = searchParams.get('routePath')

  useEffect(() => {
    if (isChinaZone === undefined) return

    const token = Cookies.get('apptoken') || ''
    const { isCN } = handleBrowserLang()

    async function fetchData() {
      const examineType = localStorage.getItem('wf_examineType')

      if (isChinaZone && (examineType === null || examineType === '-1')) {
        let response: ServerResponse
        try {
          response = await getAuthInfo()
        } catch (error) {
          response = {
            examineType: -1,
            userId: ''
          }
        }
        const { examineType } = response || {
          examineType: -1,
          userId: ''
        }
        localStorage.setItem('wf_examineType', JSON.stringify(examineType))
      }
      login(routePath)
    }
    if (token) {
      fetchData()
    } else {
      Modal.warning({
        title: isCN ? '未登录华米账号' : 'Not logged into Zepp account',
        okText: isCN ? '去登录' : 'Login',
        onOk: () => {
          window.location.href = `${apiHost.universalLogin}index.html#/login?project_name=watchface&project_redirect_uri=${window.encodeURIComponent(
            window.location.href
          )}&platform_app=com.huami.webapp&specify_lang=en`
        }
      })
    }
  }, [isChinaZone, login, routePath])
  return (
    <div className="unauthorized">
      <Spin tip="Authorizing..." />
    </div>
  )
}

export default Unauthorized
