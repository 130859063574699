/* eslint-disable import/order */
import { immutableSelector } from '@/utils'
import { Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { removeCells, renderCells } from '@watchface/utils/render'
import { InputNumber } from 'antd'
import Immutable from 'immutable'
import React, { PureComponent } from 'react'

class BackgroundGroupComponent extends PureComponent {
  static keyMap = {}

  static renderLayer = (param) => {
    const keyMap = renderCells({
      com: BackgroundGroupComponent,
      ...param
    })
    BackgroundGroupComponent.keyMap = Object.assign(BackgroundGroupComponent.keyMap, keyMap)
  }

  static removeLayer = (param) => {
    const keyMap = removeCells({
      com: BackgroundGroupComponent,
      ...param
    })
    BackgroundGroupComponent.keyMap = keyMap
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props

    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (!hidden) {
        BackgroundGroupComponent.renderLayer(this.props)
      } else {
        BackgroundGroupComponent.removeLayer(this.props)
      }
    }
  }

  static getCellDatas = ({ parentKey, childKey, data, assets }) => {
    const cells = {
      imageEntity: {},
      vertexEntity: {}
    }
    const images = BackgroundGroupComponent.getImages({ parentKey, childKey, data, assets })
    images.forEach((image) => {
      cells.imageEntity[image.key] = image
    })
    return cells
  }

  static getImages = ({ data, assets }) => {
    const { positions = Immutable.List(), bg_images = Immutable.Map() } = immutableSelector(data)
    const { previewPicNum = 1 } = immutableSelector(assets)
    const imgs = []
    const index = previewPicNum - 1
    const image = index >= 0 ? bg_images.get(index) : ''
    const x = positions.getIn([0, 'x'], 0)
    const y = positions.getIn([0, 'y'], 0)
    const imgCellKey = `images_${index}`

    if (image) {
      imgs.push({ key: imgCellKey, texture: image, left: x, top: y, selectable: false })
    }
    return imgs
  }

  handleChange = (path = [], value) => {
    const { childKey, parentKey, data = Immutable.Map(), onChange, prefixPath } = this.props

    let newData = data

    newData = newData.setIn(path, value)
    onChange([...prefixPath, parentKey, 'children', childKey], newData)
  }

  handleCountChange = (value) => {
    if (!/^-?\d+$/.test(value)) return
    const { parentKey, childKey, dialType, data = Immutable.Map(), onAssetsChange, prefixPath } = this.props
    const { bg_images = Immutable.List(), preview_images = Immutable.List() } = immutableSelector(data)
    this.handleChange(['bg_images'], bg_images.slice(0, value))
    this.handleChange(['preview_images'], preview_images.slice(0, value))
    this.handleChange(['count'], value)
    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'count'], value)
  }

  /**
   *
   * @param {*} images [{}] || {}
   * @param {*} type string bg_images, preview_images, fg_images
   */
  handleUpload = (images = [], type) => {
    const { parentKey, childKey, dialType, prefixPath, onAssetsChange } = this.props

    if (Array.isArray(images)) {
      const newImgs = images.map((images) => images.image)
      const newImgsNames = images.map((images) => images.name)
      this.handleChange([type], Immutable.List(newImgs))
      onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, `${type}_names`], Immutable.List(newImgsNames))
    } else {
      const { image = '', name = '' } = images
      this.handleChange([type], Immutable.List([image]))
      onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, `${type}_names`], Immutable.List([name]))
    }
  }

  /**
   *
   * @param {*} removeIndex number
   * @param {*} type string bg_images, preview_images, fg_images
   */
  handleRemove = (removeIndex, type) => {
    const { assets = Immutable.Map(), dialType, prefixPath, parentKey, childKey, onAssetsChange } = this.props
    const { bg_images_names = Immutable.List(), preview_images_names = Immutable.List(), fg_images_names = Immutable.List() } = immutableSelector(
      assets
    )
    const typeMap = {
      bg_images: bg_images_names,
      preview_images: preview_images_names,
      fg_images: fg_images_names
    }

    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, `${type}_names`], Immutable.List(typeMap[type].splice(removeIndex, 1)))
  }

  handlePosChange = (index, posObj, imgCount) => {
    const keys = Object.keys(posObj)

    for (let i = 0; i < keys.length; i += 1) {
      if (!/^-?\d+$/.test(posObj[keys[i]])) return
    }

    const { data = Immutable.Map() } = this.props
    let positions = data.get('positions') || Immutable.List()

    const updatePos = (idx) => {
      if (!Immutable.Map.isMap(positions.get(idx))) {
        positions = positions.set(idx, Immutable.Map())
      }

      const index = this.isDependent ? idx : 0
      let position = positions.get(index) || Immutable.Map()

      if ('x' in posObj) {
        position = position.set('x', posObj.x || 0)
      }

      if ('y' in posObj) {
        position = position.set('y', posObj.y || 0)
      }

      positions = positions.set(idx, position)
    }

    if (!this.isDependent) {
      for (let i = 0; i < imgCount; i += 1) {
        updatePos(i)
      }
    } else {
      updatePos(index)
    }
    this.handleChange(['positions'], positions)
  }

  handlePreivewPic = (value) => {
    const { parentKey, childKey, dialType, onAssetsChange, prefixPath } = this.props

    onAssetsChange([dialType, ...prefixPath, parentKey, 'children', childKey, 'previewPicNum'], value)
  }

  render() {
    const MAX_PIC_NUMBER = 30
    const { assets, data = Immutable.Map(), watchConfig } = this.props
    const { screenWidth, screenHeight } = immutableSelector(watchConfig)
    const {
      bg_images_names = Immutable.List(),
      preview_images_names = Immutable.List(),
      fg_images_names = Immutable.List(),
      previewPicNum = 1
    } = immutableSelector(assets)
    const { bg_images = Immutable.List(), preview_images = Immutable.List(), fg_images = Immutable.List() } = immutableSelector(data)

    let { count = 1 } = immutableSelector(data)

    if (bg_images_names.size) {
      // 以背景图的数量为准
      count = bg_images_names.size
    }

    const bgImgList = []
    const previewImgList = []
    const fgImgList = []

    bg_images.forEach((image, index) => {
      const imageName = (bg_images_names || Immutable.List()).get(index)
      if (!imageName) return
      bgImgList.push({
        image,
        name: imageName
      })
    })
    preview_images.forEach((image, index) => {
      const imageName = (preview_images_names || Immutable.List()).get(index)
      if (!imageName) return
      previewImgList.push({
        image,
        name: imageName
      })
    })
    fg_images.forEach((image, index) => {
      const imageName = (fg_images_names || Immutable.List()).get(index)
      if (!imageName) return
      fgImgList.push({
        image,
        name: imageName
      })
    })

    console.log('BackgroundGroupComponent', { data: data.toJS(), assets: assets.toJS() })

    return (
      <div className="control-container">
        <div className="name">
          <T id="editable_bg_count" />
        </div>
        <div className="content">
          <div className="item system-upload-wrap">
            <InputNumber value={count} onChange={this.handleCountChange} min={1} max={MAX_PIC_NUMBER} />
          </div>
        </div>
        <div className="name is-required">
          <T id="editable_bg_up_bg" />
        </div>
        <div className="content">
          <div className="item">
            <Upload
              fileList={bgImgList.length === bg_images.size ? bgImgList : []}
              fileCountLimit={count || MAX_PIC_NUMBER}
              field="image"
              multiple
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              onChange={(images) => {
                this.handleUpload(images, 'bg_images')
              }}
              onRemove={(removeIndex) => {
                this.handleRemove(removeIndex, 'bg_images')
              }}
            />
          </div>
        </div>
        <div className="name is-required">
          <T id="editable_bg_up_preview" />
        </div>
        <div className="content">
          <div className="item">
            <Upload
              fileList={previewImgList.length === preview_images.size ? previewImgList : []}
              fileCountLimit={count || MAX_PIC_NUMBER}
              field="image"
              multiple
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              onChange={(images) => {
                this.handleUpload(images, 'preview_images')
              }}
              onRemove={(removeIndex) => {
                this.handleRemove(removeIndex, 'preview_images')
              }}
            />
          </div>
        </div>
        <div className="name is-required">
          <T id="editable_bg_up_highlight" />
        </div>
        <div className="content">
          <div className="item">
            <Upload
              fileList={fgImgList}
              fileCountLimit={1}
              maxWidth={screenWidth}
              maxHeight={screenHeight}
              onChange={(images) => {
                this.handleUpload(images, 'fg_images')
              }}
              onRemove={(removeIndex) => {
                this.handleRemove(removeIndex, 'fg_images')
              }}
            />
          </div>
        </div>
        <div className="name">
          <T id="preview" />
        </div>
        <div className="content">
          <div className="item">
            <InputNumber min={1} max={bgImgList.length || 1} value={previewPicNum} onChange={this.handlePreivewPic} />
          </div>
        </div>
      </div>
    )
  }
}

BackgroundGroupComponent.defaultProps = {
  fixedProps: {},
  showNamingRule: false,
  imageCountMax: 30,
  handleWatchNamingRule: () => {}, // eslint-disable-line
  prefixPath: []
}

export default BackgroundGroupComponent
