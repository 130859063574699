const POINTER_PROGRESS = {
  center_position: {
    x: 0,
    y: 0
  },
  scale: {
    position: {
      x: 0,
      y: 0
    },
    images: [
      {
        language: 'all',
        image: {
          url: '',
          width: 0,
          height: 0
        }
      }
    ]
  },
  pointer: {
    position: {
      x: 0,
      y: 0
    },
    image: ''
  },
  cover: {
    position: {
      x: 0,
      y: 0
    },
    image: ''
  },
  angle: {
    start: 0,
    end: 360
  }
}

export default POINTER_PROGRESS
