// 用于校验图片参数
function getCheckConfigs() {
  /**
   * text 格式图片校验
   *
   * @param {boolean} hasCombin - 是否有独立跟随
   */
  const textCheck = (hasCombin = false, checkInvalidImage = false) => {
    const checkArr = hasCombin
      ? [
          {
            fieldPath: ['text', 'image', 'font_images', 0, 'images'],
            isList: true,
            // workaround 这块结构待优化以适应多条件组合场景
            when: [
              {
                fieldPath: ['combin'],
                value: 'single'
              },
              {
                fieldPath: ['text', 'image', 'system_font'],
                value: undefined // 当该路径无值时进行校验
              }
            ]
          }
        ]
      : [
          {
            fieldPath: ['text', 'image', 'font_images', 0, 'images'],
            isList: true,
            when: {
              fieldPath: ['text', 'image', 'system_font'],
              value: undefined // 当该路径无值时进行校验
            }
          }
        ]
    if (checkInvalidImage) {
      checkArr.push({
        fieldPath: ['text', 'image', 'invalid_image']
      })
    }

    return checkArr
  }

  const progressCheck = [
    {
      fieldPath: ['progress_image'],
      when: {
        fieldPath: ['progress_render_type'],
        value: 'image'
      }
    }
  ]

  const pointerProgressCheck = [
    {
      fieldPath: ['pointer', 'image']
    }
  ]

  const checkConfigs = {
    common: {
      // text
      current: textCheck(),
      target: textCheck(),
      text: textCheck(),
      // 图标
      icon: [
        {
          fieldPath: ['image']
        }
      ],
      // 常规进度
      progress: progressCheck,
      pointer_progress: pointerProgressCheck,
      image_progress: [
        {
          fieldPath: ['images'],
          isList: true
        }
      ],
      jumpable: [
        {
          fieldPath: ['image']
        }
      ]
    },
    date: {
      year: textCheck(),
      month: textCheck(true),
      day: textCheck(true)
    },
    week: {
      text: [
        {
          fieldPath: ['text', 'image', 'font_images', 0, 'images']
        }
      ]
    },
    progress_clock: {
      hour: progressCheck,
      minute: progressCheck,
      second: progressCheck
    },
    digital_clock: {
      hour: textCheck(),
      minute: textCheck(true),
      second: textCheck(true),
      am: [
        {
          fieldPath: ['images', '0', 'image', 'url']
        },
        {
          fieldPath: ['images', '1', 'image', 'url']
        }
      ],
      pm: [
        {
          fieldPath: ['images', '0', 'image', 'url']
        },
        {
          fieldPath: ['images', '1', 'image', 'url']
        }
      ]
    },
    heart_rate: {
      text: textCheck(false, true)
    },
    distance: {
      text: [
        {
          fieldPath: ['text', 'image', 'point_image', 'url']
        }
      ]
    },
    system: {
      dnd: [
        {
          fieldPath: ['image']
        }
      ],
      lock: [
        {
          fieldPath: ['image']
        }
      ],
      clock: [
        {
          fieldPath: ['image']
        }
      ],
      disconnect: [
        {
          fieldPath: ['image']
        }
      ]
    },
    background: [
      {
        fieldPath: ['image'],
        when: {
          fieldPath: ['type'],
          value: 'image'
        }
      }
    ],
    scale: [
      {
        fieldPath: ['image']
      }
    ],
    analog_clock: {
      hour: [
        {
          fieldPath: ['pointer', 'image']
        }
      ],
      minute: [
        {
          fieldPath: ['pointer', 'image']
        }
      ],
      second: [
        {
          fieldPath: ['pointer', 'image']
        }
      ]
    },
    weather: {
      image_progress: [
        {
          fieldPath: ['images'],
          listSize: 29,
          isList: true
        }
      ]
    },
    temperature: {
      current: textCheck(false, true).concat([
        {
          fieldPath: ['text', 'image', 'negative_image', 'url']
        }
      ]),
      high: textCheck(false, true).concat([
        {
          fieldPath: ['text', 'image', 'negative_image', 'url']
        }
      ]),
      low: textCheck(true, true).concat([
        {
          fieldPath: ['text', 'image', 'negative_image', 'url']
        }
      ])
    },
    sun: {
      current: textCheck(false, true),
      high: textCheck(false, true),
      low: textCheck(false, true),
      image_progress: [
        {
          fieldPath: ['images'],
          listSize: 2,
          isList: true
        }
      ]
    },
    pai: {
      weekly: textCheck()
    },
    aqi: {
      text: textCheck(false, true),
      image_progress: [
        {
          fieldPath: ['images'],
          listSize: 6,
          isList: true
        }
      ]
    },
    uvi: {
      text: textCheck(false, true),
      image_progress: [
        {
          fieldPath: ['images'],
          listSize: 5,
          isList: true
        }
      ]
    },
    humidity: {
      text: textCheck(false, true)
    },
    mask: [
      {
        fieldPath: ['cover']
      },
      {
        fieldPath: ['mask']
      }
    ]
  }

  return checkConfigs
}

export default getCheckConfigs
