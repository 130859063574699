export default `
    {{ variable }} = hmUI.createWidget(hmUI.widget.WATCHFACE_EDIT_GROUP, {
      edit_id: {{ editId }},
      x: {{ rectangle.x }},
      y: {{ rectangle.y }},
      w: {{ rectangle.width }},
      h: {{ rectangle.height }},
      {{ #selectImage }}
      select_image: '{{{ selectImage }}}',
      {{ /selectImage }}
      {{ #unselectImage }}
      un_select_image: '{{{ unselectImage }}}',
      {{ /unselectImage }}
      default_type: {{#defaultType}}{{ defaultType }}{{/defaultType}}{{^defaultType}}''{{/defaultType}},
      optional_types: {{{ optionalTypes }}},
      count: {{ count }},
      {{#selectList}}
      select_list: {
        title_font_size: {{selectList.title_font_size}} ,
        title_align_h: {{selectList.title_align_h}},
        list_item_vspace: {{selectList.list_item_vspace}},
        list_tips_text_font_size: {{selectList.list_tips_text_font_size}},
        list_tips_text_align_h: {{selectList.list_tips_text_align_h}}
      },
      {{/selectList}}
      {{ #textTag.image }}
      tips_BG: '{{{ textTag.image }}}',
      {{ /textTag.image }}
      tips_x: {{ textTag.x }},
      tips_y: {{ textTag.y }},
      tips_width: {{ textDisplayWidth }},
      tips_margin: {{ textMargin }}
    });
  `
