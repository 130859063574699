import T from '@watchface/components/I18n'
import { Button, Modal } from 'antd'
import * as React from 'react'
import { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './canvasUtils'

interface MediaSize {
  width: number
  height: number
  naturalWidth: number
  naturalHeight: number
}

interface Crop {
  x: number
  y: number
}

interface CroppedArea {
  x: number
  y: number
  width: number
  height: number
}

interface ImageSize {
  width: number
  height: number
}

interface ImageCropModalProps {
  src: string
  isVisible: boolean
  imageSize: ImageSize
  targetImageSize: ImageSize
  imageShape: 'rect' | 'round'
  radius: number
  onOk: (...args: any) => void
  onCancel: (...args: any) => void
}

const ImageCropModal: React.FunctionComponent<ImageCropModalProps> = (props) => {
  const { src, isVisible, onCancel, onOk, imageSize, targetImageSize, imageShape, radius } = props
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<number>(1)
  const [minZoom, setMinZoom] = useState<number>(1)

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedArea>()
  const [isErrorSize, setIsErrorSize] = useState<boolean>(false)

  const onMediaLoaded = (mediaSize: MediaSize) => {
    const { width, naturalWidth, naturalHeight } = mediaSize
    setZoom(naturalWidth / width)
    if (naturalWidth < imageSize.width || naturalHeight < imageSize.height) {
      setIsErrorSize(true)
    } else {
      setIsErrorSize(false)
    }
    const widthZoom = ((imageSize.width / naturalWidth) * naturalHeight) / 500
    const heightZoom = ((imageSize.height / naturalHeight) * naturalWidth) / 500
    const finalZoom = Math.max(widthZoom, heightZoom, 1)
    setMinZoom(finalZoom)
  }

  const onCropChange = (crop: Crop) => {
    setCrop(crop)
  }

  const onZoomChange = (zoom: number) => {
    setZoom(zoom)
  }

  const onCropComplete = useCallback((croppedArea: CroppedArea, croppedAreaPixels: CroppedArea) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(src, croppedAreaPixels, targetImageSize, imageShape, radius)
      onOk(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [src, croppedAreaPixels, imageShape, targetImageSize, radius, onOk])

  const onClose = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    // 548 用于计算 minZoom 不可随意更改
    <Modal className="image-crop-modal" width={548} visible={isVisible} closable={false} footer={null}>
      {src && (
        <div className="modal-crop">
          <Cropper
            style={radius ? { cropAreaStyle: { borderRadius: radius } } : {}}
            image={src}
            crop={crop}
            cropSize={imageSize}
            cropShape={imageShape}
            aspect={imageSize.width / imageSize.height}
            zoom={zoom}
            minZoom={minZoom}
            zoomSpeed={0.3}
            onMediaLoaded={onMediaLoaded}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </div>
      )}
      <div className="modal-main">
        {isErrorSize ? (
          <span className="modal-main-error">
            <T id="crop_error" />
          </span>
        ) : (
          <span>
            <T id="crop_size" />
            {imageSize.width} * {imageSize.height}
          </span>
        )}
      </div>
      <div className="modal-footer">
        <Button onClick={onClose}>
          <T id="cancel" />
        </Button>
        <Button type="primary" onClick={showCroppedImage}>
          <T id="confirm" />
        </Button>
      </div>
    </Modal>
  )
}

export default ImageCropModal
