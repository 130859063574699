export default `
    {{{ variable }}} = hmUI.createWidget(hmUI.widget.IMG_DATE, {
      {{ #data }}
      {{ ^follow }}
      {{ key }}_startX: {{ position.x }},
      {{ key }}_startY: {{ position.y }},
      {{ /follow }}
      {{ #unitSC }}
      {{ key }}_unit_sc: '{{{ unitSC }}}',
      {{ /unitSC }}
      {{ #unitTC }}
      {{ key }}_unit_tc: '{{{ unitTC }}}',
      {{ /unitTC }}
      {{ #unitEN }}
      {{ key }}_unit_en: '{{{ unitEN }}}',
      {{ /unitEN }}
      {{ ^follow }}
      {{ key }}_align: {{ align }},
      {{ /follow }}
      {{ key }}_zero: {{ padding_zero }},
      {{ #showFollow }}
      {{ key }}_follow: {{ follow }},
      {{ /showFollow }}
      {{ ^follow }}
      {{ key }}_space: {{ interval }},
      {{ /follow }}
      {{ key }}_color: {{ color }},
      {{ key }}_font_size: {{ fontSize }},
      {{ /data }}
      enable: false,
      show_level: {{ showLevel }},
    });
  `
