export default `
    {{{ variable }}} = hmUI.createWidget(hmUI.widget.IMG_POINTER, {
      src: '{{{ pointer.image }}}',
      center_x: {{ center_position.x }},
      center_y: {{ center_position.y }},
      x: {{ pointer.position.x }},
      y: {{ pointer.position.y }},
      type: {{{ type }}},
      start_angle: {{ angle.start }},
      end_angle: {{ angle.end }},
      {{ #cover.image }}
      cover_path: '{{{ cover.image }}}',
      {{ /cover.image }}
      cover_x: {{ cover.position.x }},
      cover_y: {{ cover.position.y }},
      {{ #scale.showScale }}
      scale_x: {{ scale.position.x }},
      scale_y: {{ scale.position.y }},
      {{ #scale.scaleSC }}
      scale_sc: '{{{ scale.scaleSC }}}',
      {{ /scale.scaleSC }}
      {{ #scale.scaleTC }}
      scale_tc: '{{{ scale.scaleTC }}}',
      {{ /scale.scaleTC }}
      {{ #scale.scaleEN }}
      scale_en: '{{{ scale.scaleEN }}}',
      {{ /scale.scaleEN }}
      {{ /scale.showScale }}
      show_level: {{ showLevel }},
    });
  `
