import AMPM from '@watchface/pages/DesignTool/NonZeppOs/components/AmPm/config'
import ANALOG_CLOCK_ITEM from '@watchface/pages/DesignTool/NonZeppOs/components/AnalogClockComponent/config'
import createBackgroundData from '@watchface/pages/DesignTool/NonZeppOs/components/BackgroundComponent/config'
import ICON from '@watchface/pages/DesignTool/NonZeppOs/components/IconComponent/config'
import JUMPABLE from '@watchface/pages/DesignTool/NonZeppOs/components/JumpHotAreaComponent/config'
import createNumberData from '@watchface/pages/DesignTool/NonZeppOs/components/NumberComponent/config'
import PIC from '@watchface/pages/DesignTool/NonZeppOs/components/PicComponent/config'
import IMAGE_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PictureProgress/config'
import POINTER_PROGRESS from '@watchface/pages/DesignTool/NonZeppOs/components/PointerProgressComponent/config'
import getProgress from '@watchface/pages/DesignTool/NonZeppOs/components/ProgressComponent/config'
import TEXT from '@watchface/pages/DesignTool/NonZeppOs/components/TextComponent/config'
import IMAGE from '@watchface/pages/DesignTool/NonZeppOs/ZeppOsComponents/ImageComponent/config'
import Immutable from 'immutable'
import EXTRA_CONFIG from './extraConfig'

// 指针时间
const ANALOG_CLOCK = {
  children: {
    hour: ANALOG_CLOCK_ITEM,
    minute: ANALOG_CLOCK_ITEM
  }
}
const DIGITAL_CLOCK = {
  children: {
    hour: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    minute: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    am: AMPM,
    pm: AMPM
  },
  follow_time: false,
  align: 'left'
}

// const PROGRESS_CLOCK = {
//     children: {
//         hour: getProgress(),
//         minute: getProgress(),
//     },
// }

const PAI = {
  children: {
    weekly: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const AQI = {
  children: {
    text: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}

const HUMIDITY = {
  children: {
    text: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}

const UVI = {
  children: {
    text: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}

const ALTIMETER = {
  children: {
    text: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}

const BATTERY = {
  children: {
    text: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}

const HEART_RATE = {
  children: {
    text: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}
const CALORIE = {
  children: {
    current: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}

const WEATHER = {
  children: {
    image_progress: IMAGE_PROGRESS
  }
}

const TEMPERATURE = {
  children: {
    current: createNumberData({ hasSystemFont: true, hasReact: false }),
    high: createNumberData({ hasSystemFont: true, hasReact: false }),
    low: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress()
  }
}

const SUN = {
  children: {
    current: createNumberData({ hasSystemFont: true, hasReact: false }),
    high: createNumberData({ hasSystemFont: true, hasReact: false }),
    low: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress(),
    image_progress: { ...IMAGE_PROGRESS, is_continuance: true }
  }
}

const SYSTEM = {
  children: {
    dnd: ICON,
    disconnect: ICON,
    lock: ICON,
    clock: ICON
  }
}

const DATE = {
  children: {
    year: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    month: createNumberData({ hasSystemFont: true, isPaddingZero: true }),
    day: createNumberData({ hasSystemFont: true, isPaddingZero: true })
  }
}

const STEP = {
  children: {
    current: createNumberData({ hasSystemFont: true, hasReact: false }),
    pointer_progress: POINTER_PROGRESS,
    target: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON,
    progress: getProgress(),
    image_progress: IMAGE_PROGRESS
  }
}

const DISTANCE = {
  children: {
    text: createNumberData({ hasSystemFont: true, hasReact: false }),
    icon: ICON
  }
}

const WEEK = {
  children: {
    text: PIC,
    pointer_progress: POINTER_PROGRESS,
    progress: getProgress()
  }
}

const MESSAGE = {
  children: {
    icon: ICON,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const PHONE = {
  children: {
    icon: ICON,
    jumpable: JUMPABLE
  }
}

const STAND = {
  children: {
    text: createNumberData({ hasSystemFont: true }),
    icon: ICON,
    pointer_progress: POINTER_PROGRESS,
    image_progress: IMAGE_PROGRESS,
    jumpable: JUMPABLE
  }
}

const LAYER_CONFIG = Immutable.fromJS({
  // 息屏表盘背景结构不同于正常表盘背景结构，只传图片
  idle_background: createBackgroundData({ isIdle: true }),
  analog_clock: ANALOG_CLOCK,
  image: { ...IMAGE, visibleOnAod: true },
  text: { ...TEXT, visibleOnAod: true },
  digital_clock: DIGITAL_CLOCK,
  // progress_clock: PROGRESS_CLOCK,
  pai: PAI,
  aqi: AQI,
  humidity: HUMIDITY,
  uvi: UVI,
  altimeter: ALTIMETER,
  battery: BATTERY,
  heart_rate: HEART_RATE,
  stand: STAND,
  calorie: CALORIE,
  weather: WEATHER,
  temperature: TEMPERATURE,
  sun: SUN,
  system: SYSTEM,
  date: DATE,
  step: STEP,
  distance: DISTANCE,
  week: WEEK,
  message: MESSAGE,
  phone: PHONE
})

export default LAYER_CONFIG.mergeDeep(EXTRA_CONFIG.delete('newFieldOrModulePaths'))
