import { fromJS, List, Map } from 'immutable'
import {
  ADD_NEW_WIDGET,
  ADD_WIDGET_CHILD,
  INIT_THE_WIDGET_BY_API,
  REMOVE_THE_WIDGET,
  SET_ACTIVE_WIDGET,
  UPDATE_CATEGORY_ENABLE,
  UPDATE_CATEGORY_EXPAND,
  UPDATE_CATEGORY_PROPERTY_ACTION,
  UPDATE_CATEGORY_VISIBLE,
  UPDATE_WIDGET_NAME,
  UPDATE_WIDGET_VISIBLE
} from '../actionTypes/index'

const initialState: any = fromJS({
  widgets: {},
  activeWidget: ''
})

const reducer = (state = initialState, action: { type: string; payload: any }) => {
  const { type, payload } = action
  switch (type) {
    case INIT_THE_WIDGET_BY_API: {
      const { activeWidget, id, widgets } = payload
      return state.set('activeWidget', activeWidget).set('id', id).set('widgets', widgets)
    }
    case ADD_NEW_WIDGET: {
      const id = payload.get('id')
      return state.set('activeWidget', id).setIn(['widgets', id], payload)
    }
    case REMOVE_THE_WIDGET: {
      const id = payload
      let newState = state
      // remove all children
      if (state.getIn(['widgets', id]).has('children')) {
        state.getIn(['widgets', id, 'children']).forEach((id: string) => {
          newState = newState.deleteIn(['widgets', id])
        })
      }
      newState = newState.deleteIn(['widgets', id])
      if (newState.get('widgets').size === 0) {
        newState = newState.set('widgets', Map())
      }
      if (state.get('activeWidget') === id) {
        newState = newState.set('activeWidget', '')
      }
      return newState
    }
    case SET_ACTIVE_WIDGET: {
      return state.set('activeWidget', payload)
    }
    case ADD_WIDGET_CHILD: {
      const { parentId, children } = payload
      const id = children.get('id')
      let newState = state.setIn(['widgets', id], children)
      const newChildren = newState.getIn(['widgets', parentId, 'children'], List()).push(id)
      newState = newState.set('activeWidget', id).setIn(['widgets', parentId, 'children'], newChildren)
      return newState
    }
    case UPDATE_WIDGET_NAME: {
      const { id, name } = payload
      return state.setIn(['widgets', id, 'name'], name)
    }
    case UPDATE_WIDGET_VISIBLE: {
      const { id, visible } = payload
      return state.setIn(['widgets', id, 'visible'], visible)
    }
    case UPDATE_CATEGORY_EXPAND: {
      const { id, categoryKey, value } = payload
      return state.setIn(['widgets', id, 'categories', categoryKey, 'isExpanded'], value)
    }
    case UPDATE_CATEGORY_ENABLE: {
      const { id, categoryKey, value } = payload
      return state.setIn(['widgets', id, 'categories', categoryKey, 'enabled'], value)
    }
    case UPDATE_CATEGORY_VISIBLE: {
      const { id, categoryKey, value } = payload
      return state.setIn(['widgets', id, 'categories', categoryKey, 'visibility'], value)
    }
    case UPDATE_CATEGORY_PROPERTY_ACTION: {
      const { id, categoryKey, propertyKey, value } = payload
      return state.setIn(['widgets', id, 'categories', categoryKey, 'properties', propertyKey, 'value'], value)
    }
    default:
      return state
  }
}

export default reducer
