import { Col, Divider, InputNumber, Radio, Row, Switch } from 'antd'
import * as React from 'react'
import { ALIGN_MAP } from '../../ExportZip/convert/constants'

interface Props {
  properties: {
    alignH: { value: string; visibility: boolean; disabled: boolean }
    alignV: { value: string; visibility: boolean; disabled: boolean }
    space: { value: number; visibility: boolean; disabled: boolean }
    padding: { value: boolean; visibility: boolean; disabled: boolean }
    follow: { value: boolean; visibility: boolean; disabled: boolean }
  }
  onChange: (category: string, property: string, value: any) => void
}

const Alignment: React.FC<Props> = (props) => {
  const {
    properties: {
      alignH: { value: alignH, visibility: showAlignH, disabled: disableAlignH } = {
        value: '',
        visibility: false,
        disabled: false
      },
      alignV: { value: alignV, visibility: showAlignV, disabled: disableAlignV } = {
        value: '',
        visibility: false,
        disabled: false
      },
      space: { value: space, visibility: showSpace, disabled: disableSpace } = { value: 0, visibility: false, disabled: false },
      padding: { value: padding, visibility: showPadding, disabled: disablePadding } = {
        value: true,
        visibility: false,
        disabled: false
      },
      follow: { value: follow, visibility: showFollow, disabled: disableFollow } = {
        value: false,
        visibility: false,
        disabled: false
      }
    },
    onChange
  } = props

  return (
    <div className="meta-form meta-alignment">
      {showAlignH ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={8}>Horizontal align</Col>
          <Col span={16}>
            <Radio.Group disabled={disableAlignH} onChange={(e) => onChange('alignment', 'alignH', e?.target?.value)} defaultValue={alignH}>
              <Radio value={ALIGN_MAP.left}>Left</Radio>
              <Radio value={ALIGN_MAP.hcenter}>Center</Radio>
              <Radio value={ALIGN_MAP.right}>Right</Radio>
            </Radio.Group>
          </Col>
        </Row>
      ) : null}
      {showAlignV ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={8}>Vertical align</Col>
          <Col span={16}>
            <Radio.Group disabled={disableAlignV} onChange={(e) => onChange('alignment', 'alignV', e?.target?.value)} defaultValue={alignV}>
              <Radio value={ALIGN_MAP.top}>Top</Radio>
              <Radio value={ALIGN_MAP.vcenter}>Center</Radio>
              <Radio value={ALIGN_MAP.bottom}>Bottom</Radio>
            </Radio.Group>
          </Col>
        </Row>
      ) : null}
      {showSpace ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={6}>Spacing</Col>
          <Col span={18}>
            <InputNumber
              disabled={disableSpace}
              min={0}
              max={100}
              defaultValue={space}
              onChange={(value) => {
                onChange('alignment', 'space', value)
              }}
            />
          </Col>
        </Row>
      ) : null}
      {showPadding ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={6}>Padding zero</Col>
          <Col span={18}>
            <Switch
              disabled={disablePadding}
              defaultChecked={padding}
              onChange={(value) => {
                onChange('alignment', 'padding', value)
              }}
            />
          </Col>
        </Row>
      ) : null}
      {showFollow ? (
        <Row gutter={[0, 24]} justify="space-around" align="middle">
          <Col span={6}>Following</Col>
          <Col span={18}>
            <Switch
              disabled={disableFollow}
              defaultChecked={follow}
              onChange={(value) => {
                onChange('alignment', 'follow', value)
              }}
            />
          </Col>
        </Row>
      ) : null}
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Alignment
