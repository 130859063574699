// ---------------------- common -------------------
export const EXPORT_WATCHSKIN = 'EXPORT_WATCHSKIN'
export const ZOOM_WATCHSKIN = 'ZOOM_WATCHSKIN'
export const MOVE_IMAGE = 'MOVE_IMAGE'
export const SIMULATOR = 'SIMULATOR'
export const MENU_ICON_COLOR = 'rgba(153, 153, 153, 1)'
export const LAYER_ICON_COLOR = 'rgba(216, 216, 216, 1)'
export const LAYER_ICON_HIGHLIGHT_COLOR = 'rgba(255, 255, 255, 1)'
export const EXPORT_POLL_INTERVAL = 1000 // 导出表盘轮询时间 ms
export const DOWNLOAD_WATCHSKIN = 'DOWNLOAD_WATCHSKIN'
// 每次更新数据间隔一分钟进行一次数据保存, 一分钟内再次更新数据则重新开始计时
export const AUTO_SAVE_INTERVAL = 1000 * 60 // 1 分钟
export const IMAGE_RETRY_DURATION = 1000 * 10 // 10s
export const SAVE_TYPE = {
  MANUAL: 'manual',
  AUTO: 'auto',
  MODIFY: 'modify'
}
// react dnd item type
export const DND_LAYER = 'layer'
export const DND_LAYER_ITEM = 'layerItem'

// 手表系列
export const SERIES = {
  COMO: 'como',
  NESS: 'ness',
  GT2: 'gt2',
  JS: 'js'
}

// ---------------------- 区分设备 ----------------------
export default {
  como: {
    // 是否支持息屏表盘
    supportIdleScreen: true,
    // 支持选择图片类型： 实心、渐变、实心+透明
    enableSelectImageType: true
  },
  ness: {
    // 支持选择图片类型： 实心、渐变、实心+透明
    enableSelectImageType: true
  },
  gt2: {
    // 控制图片进度组件是否显示 独立 & 统一， 默认 false
    showIsDependent: true
  }
}

// 表盘列表
export const DIAL_PAGE_SIZE = 10
// 多语言
export const WATCHFACE_LANG = 'watchface_lang'
export const SUPPORT_LANGS = [
  {
    label: 'English',
    field: 'en'
  },
  {
    label: '中文',
    field: 'zh'
  }
]

export const TOOLTIP_COLOR = '#494A4D'

export const APP_NAME_EXIST = 'APP_NAME_EXIST'

export const ZEPP_DOC_LINKS = {
  overview: 'docs/guides/tools/watchface/overview/',
  scanCode: 'docs/guides/tools/watchface/guides/scanCode/',
  background: 'docs/guides/tools/watchface/guides/background/',
  simulator: 'docs/guides/tools/simulator/index/',
  simulatorDownload: 'docs/guides/tools/simulator/download/',
  deviceList: 'docs/reference/related-resources/device-list/'
}

export const ALIGN_H = {
  LEFT: 'left',
  CENTER: 'hcenter',
  RIGHT: 'right'
}

export const ALIGN_V = {
  TOP: 'top',
  CENTER: 'vcenter',
  BOTTOM: 'bottom'
}

export const KEYBOARD = {
  LEFT: 'left',
  UP: 'up',
  RIGHT: 'right',
  DOWN: 'down'
}

// 数据组件子模块层级定义
export const DATA_CHILD_KEY_ORDER_MAP = {
  jumpable: 10,
  pointer_progress: 9,
  icon: 8,
  text: 8,
  daily: 8,
  weekly: 8,
  current: 8,
  high: 8,
  low: 8,
  progress: 7,
  image_progress: 7,
}

export const TO_BE_ONLINE_MENU_ITEM = []

export const INITIAL_VERSION = '1.0.0'

export const TAGS = [{
  key: 'battery',
  defaultValue: 92,
  experssion: '[BATT_PER]',
  description: 'Current battery percentage',
  example: 92,
}, {
  key: 'heartrate',
  defaultValue: 68,
  experssion: '[HR]',
  description: 'Heart Rate',
  example: 68,
}, {
  key: 'heartrate_z',
  defaultValue: '068',
  experssion: '[HR_Z]',
  description: 'Heart Rate(leading zero)',
  example: '068',
}, {
  key: 'calorie',
  defaultValue: 240,
  experssion: '[CAL]',
  description: 'Calorie',
  example: 240,
}, {
  key: 'step',
  defaultValue: 3000,
  experssion: '[SC]',
  description: 'Step Count',
  example: 3000,
}, {
  key: 'year',
  defaultValue: '2022',
  experssion: '[YEAR]',
  description: 'Year',
  example: '2022',
}, {
  key: 'month',
  defaultValue: '6',
  experssion: '[MON]',
  description: 'Month in year',
  example: '6',
}, {
  key: 'month_z',
  defaultValue: '06',
  experssion: '[MON_Z]',
  description: 'Month in year(leading zero)',
  example: '06',
}, {
  key: 'day',
  defaultValue: '6',
  experssion: '[DAY]',
  description: 'Day in month',
  example: '6',
}, {
  key: 'day_z',
  defaultValue: '06',
  experssion: '[DAY_Z]',
  description: 'Day in month(leading zero)',
  example: '06',
}, {
  key: 'hour_24',
  defaultValue: '9',
  experssion: '[HOUR_24]',
  description: 'Hour(1-24) in day',
  example: '9',
}, {
  key: 'hour_24_z',
  defaultValue: '09',
  experssion: '[HOUR_24_Z]',
  description: 'Hour(1-24) in day(leading zero)',
  example: '09',
}, {
  key: 'minute',
  defaultValue: '20',
  experssion: '[MIN]',
  description: 'Minute in hour',
  example: '20',
}, {
  key: 'minute_z',
  defaultValue: '05',
  experssion: '[MIN_Z]',
  description: 'Minute in hour(leading zero)',
  example: '05',
}, {
  key: 'second',
  defaultValue: '2',
  experssion: '[SEC]',
  description: 'Second in minute',
  example: '2',
}, {
  key: 'second_z',
  defaultValue: '06',
  experssion: '[SEC_Z]',
  description: 'Second in minute(leading zero)',
  example: '06',
}, {
  key: 'week_f',
  defaultValue: 'Wednesday',
  experssion: '[WEEK_EN_F]',
  description: 'Day of week(full text)',
  example: 'Wednesday',
}, {
  key: 'week_s',
  defaultValue: 'Wed',
  experssion: '[WEEK_EN_S]',
  description: 'Day of week(abbreviated text)',
  example: 'Wed',
}
]
export const DEFAULT_LANGUAGE_SELECT = 'en'
export const ZOOM_STATUS_DOING = 1
export const ZOOM_STATUS_SUCCESS = 3
export const ZOOM_STATUS_FAILED = 2

export const APPID_START_NUM_ZEPP_OS = 1000000
