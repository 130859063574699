import ImageComponent from '@watchface/pages/DesignTool/NonZeppOs/ZeppOsComponents/ImageComponent'
import { noop } from 'lodash'
import React, { PureComponent } from 'react'

class Image extends PureComponent {
  static removeLayer = () => noop

  render() {
    return <ImageComponent {...this.props} />
  }
}

export default Image
