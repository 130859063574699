import { SERIES } from '@watchface/constants'
import Classnames from 'classnames'
import React, { PureComponent } from 'react'
import { NumberComponent, NumberComponentWithRect } from '../../components'

class Date extends PureComponent {
  static renderLayer = ({ hasRectArea, ...args }) => {
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent

    NumComponent.renderLayer({ ...args })
  }

  static removeLayer = ({ hasRectArea, ...args }) => {
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent
    NumComponent.removeLayer({ ...args })
  }

  render() {
    const { allAssets, assets, parentKey, childKey, propMap, series, dialType } = this.props
    const prop = propMap[childKey] || {}
    const { hasRectArea } = prop
    const cls = Classnames(['date-container', 'control-container'])
    const NumComponent = hasRectArea ? NumberComponentWithRect : NumberComponent
    const isComoNess = series === SERIES.COMO || series === SERIES.NESS
    let newAssets = assets
    if (isComoNess && dialType === 'idle') {
      // 息屏没有年，月禁止跟随
      if (childKey === 'month') {
        newAssets = newAssets.set('disableFollow', true)
      }
    }

    const allProps = { ...this.props, ...prop }

    return (
      <div className={cls} key={childKey}>
        <NumComponent
          ref={(element) => {
            this.numberCom = element
          }}
          {...allProps}
          allAssets={allAssets.setIn([dialType, parentKey, 'children', childKey], assets)}
          assets={newAssets}
        />
      </div>
    )
  }
}

export default Date
