import PIC from '@watchface/pages/DesignTool/NonZeppOs/components/PicComponent/config'
import AMPM from '@watchface/pages/DesignTool/NonZeppOs/components/AmPm/config'
import ANALOG_CLOCK_ITEM from '@watchface/pages/DesignTool/NonZeppOs/components/AnalogClockComponent/config'
import createNumberData from '@watchface/pages/DesignTool/NonZeppOs/components/NumberComponent/config'
import Immutable from 'immutable'


// 指针时间
const ANALOG_CLOCK = {
  children: {
    hour: ANALOG_CLOCK_ITEM,
    minute: ANALOG_CLOCK_ITEM
  }
}

const DIGITAL_CLOCK = {
  children: {
    hour: createNumberData({ hasReact: true, isPaddingZero: true }),
    minute: createNumberData({ hasReact: true, isPaddingZero: true }),
    am: AMPM,
    pm: AMPM
  },
  follow_time: false,
  align: 'left'
}

const DATE = {
  children: {
    month: createNumberData({ hasReact: true, isPaddingZero: true }),
    day: createNumberData({ hasReact: true, isPaddingZero: true })
  }
}

const STEP = {
  children: {
    current: createNumberData({ hasReact: true })
  }
}

const WEEK = {
  children: {
    text: PIC
  }
}

const LAYER_CONFIG = Immutable.fromJS({
  analog_clock: ANALOG_CLOCK,
  digital_clock: DIGITAL_CLOCK,
  date: DATE,
  step: STEP,
  week: WEEK
})

export default LAYER_CONFIG
