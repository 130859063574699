// 用于校验图片参数
function getCheckConfigs() {
  /**
   * text 格式图片校验
   *
   * @param {boolean} hasCombin - 是否有独立跟随
   */
  const textCheck = (hasCombin = false, checkInvalidImage = false) => {
    const checkArr = hasCombin
      ? [
          {
            fieldPath: ['text', 'image', 'font_images', 0, 'images'],
            isList: true,
            // workaround 这块结构待优化以适应多条件组合场景
            when: [
              {
                fieldPath: ['combin'],
                value: 'single'
              },
              {
                fieldPath: ['text', 'image', 'system_font'],
                value: undefined // 当该路径无值时进行校验
              }
            ]
          }
        ]
      : [
          {
            fieldPath: ['text', 'image', 'font_images', 0, 'images'],
            isList: true,
            when: {
              fieldPath: ['text', 'image', 'system_font'],
              value: undefined // 当该路径无值时进行校验
            }
          }
        ]
    if (checkInvalidImage) {
      checkArr.push({
        fieldPath: ['text', 'image', 'invalid_image']
      })
    }

    return checkArr
  }

  const checkConfigs = {
    common: {
      // text
      current: textCheck(),
      target: textCheck(),
      text: textCheck()
    },
    date: {
      month: textCheck(true),
      day: textCheck(true)
    },
    week: {
      text: [
        {
          fieldPath: ['text', 'image', 'font_images', 0, 'images']
        }
      ]
    },
    digital_clock: {
      hour: textCheck(),
      minute: textCheck(true),
      am: [
        {
          fieldPath: ['images', '0', 'image', 'url']
        },
        {
          fieldPath: ['images', '1', 'image', 'url']
        }
      ],
      pm: [
        {
          fieldPath: ['images', '0', 'image', 'url']
        },
        {
          fieldPath: ['images', '1', 'image', 'url']
        }
      ]
    },
    analog_clock: {
      hour: [
        {
          fieldPath: ['pointer', 'image']
        }
      ],
      minute: [
        {
          fieldPath: ['pointer', 'image']
        }
      ]
    }
  }

  return checkConfigs
}

export default getCheckConfigs
