import React from 'react'
import { Route, Redirect } from 'react-router-dom'

interface GuardedRouteProps {
  component: any
  auth: boolean
  path: string
}

const GuardedRoute = ({ component: Component, auth, ...rest }: GuardedRouteProps): JSX.Element => {
  const { location: { pathname } } = rest as any || {}

  return <Route {...rest} render={(props) => (auth === true ? <Component {...props} /> : <Redirect to={`/unauthorized?routePath=${pathname}`} />)} />
}

export default GuardedRoute
