/* eslint-disable  prefer-promise-reject-errors */
import axios, { Method, AxiosResponse } from 'axios'
import { message, Modal } from 'antd'
import { handleConsoleRedirect } from '@openplatform/utils/index'

export interface IParams {
  method?: Method
  headers?: any
  baseURL?: string
  params?: any
  data?: any | string
}

function getLang() {
  const { getLocaleLanguage } = window as any
  const { lang } = getLocaleLanguage(window.navigator, '')
  const storageLang = localStorage.getItem('curLang')
  return storageLang || lang
}

function checkStatus(response: AxiosResponse) {
  let notAuth = ''
  let fail = ''
  let serviceError = ''
  if (getLang() === 'en') {
    notAuth = 'Please complete your developer certification first'
    fail = 'Request error'
    serviceError = 'Server error'
  } else {
    notAuth = '请先完成开发者认证'
    fail = '请求错误'
    serviceError = '服务器错误'
  }

  const { status, data } = response
  if (status >= 200 && status < 300) return data || { success: true }
  // 根据status做不同处理
  let error: Error
  if (status === 401 || status === 403) {
    error = new Error(notAuth)
  } else if (status >= 400 && status < 500) {
    error = new Error(fail)
  } else {
    error = new Error(serviceError)
  }
  error.name = status.toString()
  if (status !== 404) throw error
}

// 接口请求SDK
export default function request(url: string, params: IParams = {}, option = { needCatch: true, needVerifyToken: false, needVerifyExamine: false }) {
  const { needCatch, needVerifyToken, needVerifyExamine } = option
  const apptoken = localStorage.getItem('apptoken') || ''
  const examineType = localStorage.getItem('examineType') || ''
  if (needVerifyToken) {
    if (!apptoken) {
      Modal.warning({
        title: getLang() === 'zh' ? '未登录华米账号' : 'Not logged into Zepp account',
        okText: getLang() === 'zh' ? '去登录' : 'Login',
        onOk: () => {
          handleConsoleRedirect()
        }
      })
      return Promise.reject()
    }
    if (needVerifyExamine && Number(examineType) !== 2) {
      Modal.warning({
        title: getLang() === 'zh' ? '未完成华米开发者认证' : 'Need To Sign Up As a Developer',
        okText: getLang() === 'zh' ? '去认证' : 'To Sign Up',
        onOk: () => {
          window.location.href = `/#/user/register/auth/enterprise?lang=${getLang() === 'zh' ? 'zh' : 'en'}`
        }
      })
      return Promise.reject()
    }
  }
  const defaultHeaders = {
    apptoken
  }
  const headers = params.headers ? params.headers : {}

  const req = axios({
    method: params.method || 'get',
    url,
    headers: { ...defaultHeaders, ...headers },
    params: params.params,
    data: params.data,
    validateStatus() {
      return true
    }
  })
  if (needCatch) {
    return req.then(checkStatus).catch((err: Error) => {
      // eslint-disable-next-line no-useless-return
      if (err.name === '401') return { error: err }
      message.error(err.message)
    })
  }
  return req.then((response) => {
    return new Promise((resolve, reject) => {
      const { status, data } = response
      if (status >= 200 && status < 400) {
        resolve(data)
      } else {
        reject({ ...data, status })
      }
    })
  })
}
