import React from 'react'
import ClassNames from 'classnames'
import { Cascader } from 'antd'

import './index.scss'

interface Option {
  value: string
  label: string
  children?: Option[]
}

interface CascaderPickerProps {
  options: Option[]
  onChangeComplete: (value: (string | number)[], selectedOptions?: any[]) => void
  className?: string
  defaultValue?: (string | number)[]
  allowClear?: boolean
}

const CascaderPicker = (props: CascaderPickerProps): JSX.Element | null => {
  const { options, defaultValue, className, allowClear } = props
  const cls = ClassNames([className, 'cascader-pick-container'])
  const handleValueChange = (value: any) => {
    const { onChangeComplete } = props
    onChangeComplete(value)
  }

  return (
    <Cascader
      className={cls}
      expandTrigger="hover"
      placeholder=""
      defaultValue={defaultValue}
      allowClear={allowClear}
      options={options}
      onChange={handleValueChange}
    />
  )
}

export default CascaderPicker
