export default `
  {{ variable }} = hmUI.createWidget(hmUI.widget.TEXT, {
    x: {{ x }},
    y: {{ y }},
    w: {{ width }},
    h: {{ height }},
    text: '{{{ text }}}',
    {{ #color }}
    color: '{{{ color }}}',
    {{ /color }}
    {{ #textSize }}
    text_size: {{ textSize }},
    {{ /textSize }}
    {{ #textStyle }}
    text_style: {{ textStyle }},
    {{ /textStyle }}
    {{ #alignH }}
    align_h: {{ alignH }},
    {{ /alignH }}
    {{ #alignV }}
    align_v: {{ alignV }},
    {{ /alignV }}
    show_level: {{ showLevel }}
  })
`
