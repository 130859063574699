import React, { useState } from 'react'
import Text from '@watchface/components/Meta/Text'
import { Button } from 'antd'
import TagModal from './TagModal'

interface IProps {
  properties: any
  onChange: (...args: any) => void
}

const TextInputWithTag:React.FC<IProps> = (props) => {
  const [tagModalVisible, setTagModalVisible] = useState(false)

  const { properties, onChange } = props
  const { text: { value } } = properties
  const tagButton = <Button
    className="text-tag"
    size="small"
    ghost
    onClick={() => setTagModalVisible(!tagModalVisible)}
  >
    Tag
  </Button>

  return <>
    <Text
      className="text-input-with-tag"
      properties={properties}
      addonAfter={tagButton}
      onChange={onChange}
    />
    <TagModal
      key={String(tagModalVisible)}
      visible={tagModalVisible}
      textValue={value}
      onClose={() => { setTagModalVisible(false) }}
      onChange={onChange}
    />
  </>
}

export default TextInputWithTag
