interface endShapeParam {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fillColor: string
  zIndex: number
}
export function getEndTriangle(param: endShapeParam): any[] {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fillColor, zIndex } = param
  const triangles = []
  let startTriangle = {}
  const strokeWidth = outerRadius - innerRadius
  const startTriangleX = cx + Math.cos(Math.PI * (startAngle / 180)) * outerRadius
  const startTriangleY = cy + Math.sin(Math.PI * (startAngle / 180)) * outerRadius

  startTriangle = {
    left: startTriangleX,
    top: startTriangleY,
    width: strokeWidth,
    height: strokeWidth,
    angle: -(180 - startAngle),
    fill: fillColor,
    key: `trianglestart`,
    type: 'triangle'
  }

  triangles.push(startTriangle)

  const endTriangleX = cx + Math.cos(Math.PI * (endAngle / 180)) * outerRadius - Math.cos(Math.PI * (endAngle / 180)) * strokeWidth
  const endTriangleY = cy + Math.sin(Math.PI * (endAngle / 180)) * outerRadius - Math.sin(Math.PI * (endAngle / 180)) * strokeWidth

  let endTriangle = {}
  endTriangle = {
    left: endTriangleX,
    top: endTriangleY,
    width: strokeWidth,
    height: endTriangleY,
    angle: endAngle,
    fill: fillColor,
    key: `triangleend`,
    type: 'triangle',
    zIndex
  }

  triangles.push(endTriangle)

  return triangles
}

export function getEndArc(param: endShapeParam): any[] {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fillColor, zIndex } = param
  const arcs = []
  let startArc = {}
  let endArc = {}

  const radius = innerRadius + (outerRadius - innerRadius) / 2
  const arcRadius = (outerRadius - innerRadius) / 2
  const startArcX = cx + Math.cos(Math.PI * (startAngle / 180)) * radius
  const startArcY = cy + Math.sin(Math.PI * (startAngle / 180)) * radius

  startArc = {
    pos: [startArcX, startArcY],
    startAngle: startAngle + 180,
    endAngle: startAngle,
    radius: arcRadius,
    fillColor,
    key: `arcstart`,
    type: 'circle',
    zIndex
  }
  arcs.push(startArc)

  const endArcX = cx + Math.cos(Math.PI * (180 - endAngle / 180)) * radius
  const endArcY = cy - Math.sin(Math.PI * (180 - endAngle / 180)) * radius

  endArc = {
    pos: [endArcX, endArcY],
    startAngle: endAngle,
    endAngle: endAngle + 180,
    radius: arcRadius,
    fillColor,
    key: `arcend`,
    type: 'circle'
  }
  arcs.push(endArc)

  return arcs
}

export function getEndShape(cap: number): [] {
  const map: {
    [index: string]: any
  } = {
    // 圆弧
    0: 'round',
    // 三角
    90: 'triangle',
    // 平角
    180: 'butt'
  }
  return map[cap] || 'round'
}
