import Immutable from 'immutable'
import { EXPORT_FAILED, EXPORT_START, EXPORT_SUCCESS } from '../actionTypes'

const initialState: any = Immutable.fromJS({
  exportResult: {}
})

const reducer = (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case EXPORT_START:
      return initialState
    case EXPORT_SUCCESS:
    case EXPORT_FAILED:
      return state.set('exportResult', Immutable.fromJS(data))
    default:
      return state
  }
}

export default reducer
