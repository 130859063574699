import NORMAL_MENU from './normal/menu'
import IDLE_MENU from './idle/menu'

import NORMAL_TOP_BAR from './normal/topBar'
import IDLE_TOP_BAR from './idle/topBar'

import NORMAL_LAYER_CONFIG from './normal/layerConfig'
import IDLE_LAYER_CONFIG from './idle/layerConfig'

import NORMAL_GET_CHECK_CONFIGS from './normal/getCheckConfigs'
import IDLE_GET_CHECK_CONFIGS from './idle/getCheckConfigs'

import componentMap from './componentMap'

// 天气命名规则， 格式为（文件名: 天气）
const weatherNamingMap = {
  0: 'unknow_weather',
  1: 'cloudy',
  2: 'shower',
  3: 'snow_shower',
  4: 'sunny',
  5: 'overcast',
  6: 'light_rain',
  7: 'light_snow',
  8: 'moderate_rain',
  9: 'moderate_snow',
  10: 'heavy_snow',
  11: 'heavy_rain',
  12: 'sandstorm',
  13: 'sleet',
  14: 'fog',
  15: 'haze',
  16: 'thundershower',
  17: 'snowstorm',
  18: 'dust',
  19: 'extraordinary_rainstorm',
  20: 'freezing_rain',
  21: 'thundershowers_with_hail',
  22: 'heavy_rainstom',
  23: 'sand_blowing',
  24: 'strong_sandstom',
  25: 'rainstorm'
}

export default {
  weatherNamingMap,
  componentMap,
  normal: {
    layerConfig: NORMAL_LAYER_CONFIG,
    menu: NORMAL_MENU,
    topBar: NORMAL_TOP_BAR,
    getCheckConfigs: NORMAL_GET_CHECK_CONFIGS
  },
  idle: {
    layerConfig: IDLE_LAYER_CONFIG,
    menu: IDLE_MENU,
    topBar: IDLE_TOP_BAR,
    getCheckConfigs: IDLE_GET_CHECK_CONFIGS
  }
}
