import { Upload } from '@watchface/components'
import T from '@watchface/components/I18n'
import { Col, Divider, Row, Tabs } from 'antd'
import * as React from 'react'

const { TabPane } = Tabs

interface imageItem {
  lang: 'zh' | 'zh-Hant' | 'en' | 'all'
  value: string
}

interface Props {
  screenWidth: number
  screenHeight: number
  properties: {
    image: { value: imageItem[] }
  }
  categoryKey: string
  onChange: (category: string, property: string, value: any) => void
}

const languageMap = {
  zh: <T id="lang_zh" />,
  'zh-Hant': <T id="lang_zh-hant" />,
  en: <T id="lang_en" />,
  all: <T id="lang_all" />
}

const Image: React.FC<Props> = (props) => {
  const {
    screenWidth,
    screenHeight,
    properties: {
      image: { value: imageArr = [] }
    },
    categoryKey,
    onChange
  } = props

  const handleChange = (imageObj: any, changedLang: string) => {
    const newImageArr = imageArr.map(({ lang, value }) => {
      if (lang === changedLang) {
        return {
          lang,
          value: imageObj || ''
        }
      }
      return {
        lang,
        value
      }
    })
    onChange(categoryKey, 'image', newImageArr)
  }

  const renderUpload = (value: any, lang = 'all') => {
    let newValue = value
    if (typeof newValue === 'string' && newValue) {
      newValue = { image: newValue }
    }
    return (
      <Row gutter={[0, 24]} justify="space-around" align="middle">
        <Col span={24}>
          <Upload
            fileCountLimit={1}
            maxWidth={screenWidth}
            maxHeight={screenHeight}
            fileList={newValue ? [newValue] : []}
            onChange={(imageObj: any) => handleChange(imageObj, lang)}
          />
        </Col>
      </Row>
    )
  }

  return (
    <div className="meta-form meta-image">
      {imageArr.length === 1 ? (
        renderUpload(imageArr[0]?.value)
      ) : (
        <Tabs defaultActiveKey={imageArr[0].lang} centered>
          {imageArr.map(({ lang, value }) => (
            <TabPane tab={languageMap[lang]} key={lang}>
              {renderUpload(value, lang)}
            </TabPane>
          ))}
        </Tabs>
      )}
      <Divider style={{ margin: '24px 0 0' }} />
    </div>
  )
}

export default Image
